import React from 'react';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import Button from './Button';

const ModelsRow = ({ title, onButtonClick, onDetailView }) => {
  return(
    <div className="dashboard-row models-row">
      <div className="dashboard-column col-1">
        <span className="icon"><DescriptionOutlinedIcon /></span>
        <div className="dashboard-column__main" onClick={onDetailView}>
          <p className="title">{title}</p>
        </div>
      </div>
      <div className="dashboard-column show-on-hover">
        <Button text="Créer un document" onButtonClick={onButtonClick} white />
      </div>
    </div>
  );
}

export default ModelsRow;