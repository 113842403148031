import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import DocumentRow from '../UI/DocumentRow';
import TemplateRow from '../UI/TemplateRow';
import FolderRow from '../UI/FolderRow';
import DashboardViewHead from './DashboardViewHead';
import SignaturesRow from '../UI/SignaturesRow';
import Loader from '../UI/Loader';
import Alert from '../UI/Alert';

import { DocumentsContext } from '../../context/documents/documentsState';

const DashboardView = ({ data, columns, view, hideCreateAndUser, onSearch, onSidebarOpen, hideMoveBtn, viewTitle, onSort, selected, onSetSelected, onTemplateDelete, onTemplateToggleHidden, hideDuplicateBtn, hideArchiveBtn, hideDownloadBtn, onMoveToFolder, onDownload, onDelete, onTemplateEdit, searchValue, onSetSearch, onRestore, onArchive, removeSearchClose, onSetRemoveSearchClose, onSearchFormClear, signatureStatuses, statusLoading, onPreviewDoc, onDownloadSingle, onDeleteSingle, onSingleDocumentDuplicate, onSingleDocumentDelete, onSingleDocumentArchive, onSingleDocumentDownload, onSingleDocumentDownloadData, onSingleDocumentRestore, onTemplateDownload, setViewTitle, onOpenFolderModal, rowDraggable, breadcrumbs, onFilterTemplatesByFolders, onFolderCreate, onFolderEdit, onFolderDelete, onFolderMove, onFolderClick, onMoveSelectedResources, dataLoaded = true, onMoveDocumentToFolder, onDeletePermanently, onStatusClick, headerNotFixed, dashboardView, showSectionsBtn, onSectionsOpen, onlyAdminEdit, onInjectModalOpen, onSingleDocumentInject, showSupportMenu, onTemplateShare }) => {
  const [allChecked, setAllChecked] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { currentFilter, getTemplateById } = useContext(DocumentsContext);
  const [showNoTemplateAlert, setShowNoTemplateAlert] = useState(false)
  const history = useHistory();

  useEffect(() => {
    if(selected.length === 0) {
      setAllChecked(false);
    }
  }, [selected]);

  const allCheckedChangeHandler = (e) => {
    if(e.target.checked && selected.length === 0) {
      setAllChecked(true);
      onSetSelected(data);
    } 
    
    if(!e.target.checked) {
      setAllChecked(false);
      onSetSelected([]);
    }
  }

  const documentChangeHandler = (doc, e) => {
    if(e.target.checked && selected.filter(obj => obj.id === doc.id).length === 0) {
      onSetSelected(prev => [...prev, { ...doc }]);
      if(selected.length === 0) {
        setAllChecked(true);
      }
    }else if(!e.target.checked && selected.filter(obj => obj.id === doc.id).length === 1){
      onSetSelected(prev => [...prev].filter(obj => obj.id !== doc.id));
      if(selected.length === 1) {
        setAllChecked(false);
      }
    }
  }

  const showDropdownClickHandler = e => {
    e.preventDefault();
    setShowDropdown(!showDropdown);
  }

  const openDocument = (document) => () => {
    const docTemplate = getTemplateById(document.template)
    if(Object.keys(docTemplate).length === 0) {
      return setShowNoTemplateAlert(true)
    }

    history.push({
      pathname: `/document-detail/${document.id}`
    })

  }

  const createDocument = (template) => () => {
    history.push({
      pathname: `/template/${template.id}`,
      state: { templateId: template.id }
    })
  }
  
  return(
    <div className="dashboard-view">
      <DashboardViewHead 
        title={viewTitle ? viewTitle : 'Tous'}
        onSetSearch={onSetSearch}
        searchValue={searchValue}
        selectedDocuments={selected}
        allChecked={allChecked}
        onAllCheckedChange={allCheckedChangeHandler}
        showDropdown={showDropdown}
        onDropdownTrigger={showDropdownClickHandler}
        setShowDropdown={setShowDropdown}
        columns={columns}
        hideCreateAndUser={hideCreateAndUser}
        onSearch={onSearch}
        onSidebarOpen={onSidebarOpen}
        hideMoveBtn={hideMoveBtn}
        hideDuplicateBtn={hideDuplicateBtn}
        hideArchiveBtn={hideArchiveBtn}
        hideDownloadBtn={hideDownloadBtn}
        onSort={onSort}
        onDownload={onDownload}
        onMoveToFolder={onMoveToFolder}
        onDelete={onDelete}
        onRestore={onRestore}
        onArchive={onArchive}
        removeSearchClose={removeSearchClose}
        onSetRemoveSearchClose={onSetRemoveSearchClose}
        onSearchFormClear={onSearchFormClear}
        onSetSelected={onSetSelected}
        view={view}
        setViewTitle={setViewTitle}
        breadcrumbs={breadcrumbs}
        onFilterTemplatesByFolders={onFilterTemplatesByFolders}
        onFolderCreate={onFolderCreate}
        onFolderEdit={onFolderEdit}
        onFolderDelete={onFolderDelete}
        onFolderMove={onFolderMove}
        onMoveSelectedResources={onMoveSelectedResources}
        onDeletePermanently={onDeletePermanently}
        notFixed={headerNotFixed}
        dashboardView={dashboardView}
        showSectionsBtn={showSectionsBtn}
        onSectionsOpen={onSectionsOpen}
        onInjectModalOpen={onInjectModalOpen}
        showSupportMenu={showSupportMenu}
      />
      <div className={headerNotFixed ? "dashboard-view__body no-padding-top" : "dashboard-view__body"}>
        {dataLoaded && data.length > 0 ? data.map(doc => {
          let Component;
          if(view === "documents") {
            if(doc.folderRow) {
              Component = FolderRow;
            }else {
              Component = DocumentRow;
            }
          }else if(view === "templates") {
            if(doc.folderRow) {
              Component = FolderRow;
            }else {
              Component = TemplateRow;
            }
          }else if(view === "signatures") {
            Component = SignaturesRow;
          }
          return <Component 
            key={doc.id}
            selectedDocuments={selected}
            onCheckboxChange={(e) => documentChangeHandler(doc, e)}
            doc={doc}
            onDetailView={view === "documents" ? openDocument(doc) : view === "signatures" ? () => onPreviewDoc(doc) : () => {}}
            onButtonClick={createDocument(doc)}
            onTemplateDelete={onTemplateDelete}
            onTemplateEdit={onTemplateEdit}
            status={view === "signatures" ? doc.status : 'N/A'}
            statusLoading={view === "signatures" ? statusLoading : false}
            onDownload={onDownloadSingle}
            onDelete={onDeleteSingle}
            onSingleDocumentDuplicate={onSingleDocumentDuplicate}
            onSingleDocumentDelete={onSingleDocumentDelete}
            onSingleDocumentArchive={onSingleDocumentArchive}
            onSingleDocumentDownload={onSingleDocumentDownload}
            onSingleDocumentDownloadData={onSingleDocumentDownloadData}
            onSingleDocumentRestore={onSingleDocumentRestore}
            onTemplateDownload={onTemplateDownload}
            onTemplateShare={onTemplateShare}
            onOpenFolderModal={onOpenFolderModal}
            onMoveToFolder={onMoveToFolder}
            draggable={rowDraggable}
            onFolderClick={onFolderClick}
            onMoveDocumentToFolder={onMoveDocumentToFolder}
            onlyAdminEdit={onlyAdminEdit}
            onStatusClick={onStatusClick}
            onSingleDocumentInject={onSingleDocumentInject}
            view={view}
            onTemplateToggleHidden={onTemplateToggleHidden}
          />
        })
        : dataLoaded 
          ? <div className="no-data-to-show">
              <h3>{view === 'signatures' ? 'Pas de signatures' : 'Ce dossier est vide'}</h3>
            </div>
          :
            <div className="loader-wrapper">
              <Loader small normal primary />
            </div>
        }
      </div>

      {showNoTemplateAlert && <Alert 
        text="Ce document n'a pas de modèle !" 
        onClose={() => setShowNoTemplateAlert(false)} 
        showOk={true}
      />}
    </div>
  );
}

DashboardView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  view: PropTypes.string,
  hideCreateAndUser: PropTypes.bool,
  onSearch: PropTypes.func
}

export default DashboardView;