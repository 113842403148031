import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ClickAwayListener } from '@material-ui/core'

import Checkbox from './Checkbox';
import IconButton from './IconButton';
import CustomTooltip from './CustomTooltip';
import Status from './Status';
import LetterCircle from './LetterCircle';
import Modal from './Modal'
import Button from './Button'
import ResponseLoader from './ResponseLoader'
import { get_signature_status, get_signature_document, push_to_immocloud } from '../../services/firestore';
import { SignatureContext } from '../../context/signatures/signaturesState';
import { NotificationContext } from '../../context/notifications/notificationState';
import { UserContext, LoaderContext } from '../../context';
import { availableOn } from '../../utils';

const SignaturesRow = ({ selectedDocuments, doc, onCheckboxChange, onDetailView, status, statusLoading, onDownload, onDelete, onStatusClick }) => {
  const { updateSignature } = useContext(SignatureContext);
  const { setNotification } = useContext(NotificationContext);
  const { partner, immocloudConfig } = useContext(UserContext)
  const { setLoading } = useContext(LoaderContext)
  const [showDropdown, setShowDropdown] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const [showButtonsModal, setShowButtonsModal] = useState(false)
  const [uploading, setUploading] = useState(false)
  const dropdownEl = useRef();

  // On refresh icon click
  const refreshStatusHandler = async () => {
    setRefreshing(true);
    setLoading(true)
    try {
      const res = await get_signature_status({ PACKAGE_ID: [doc.package_id] });
      if(res.response.status) {
        const s = {...doc};
        await updateSignature(s.id, { status: res.response.data[0].status_provider, status_updated_at: Date.now() });
      }else {
        const message = res.response?.message && Array.isArray(res.response?.message) ? res.response.message[0] : res.response?.message || "Aucune donnée reçue"
        throw new Error(message)
      }
      setRefreshing(false);
      setRefreshed(true);
    } catch (err) {
      console.log(err);
      setRefreshing(false);
      const errorMessage = err.message || ""
      setNotification({ msg: `Erreur API Onespan${errorMessage ? ` : ${errorMessage}` : ''}`, type: 'danger' })
    } finally {
      setLoading(false)
    }
  }

  // On title click
  const onTitleClick = () => {
    if(availableOn(['development'])) {
      if(partner === 'jurisur') {
        setShowButtonsModal(true)
        return 
      }

      onDetailView()
    }else {
      onDetailView()
    }
  }

  // On preview btn click
  const onPreviewBtnClick = () => {
    setShowButtonsModal(false)
    onDetailView()
  }

  // On upload btn click
  const onUploadBtnClick = async () => {
    setUploading(true)
    setShowButtonsModal(false)
    const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id })
    if(res.response && res.response.status) {
      const fileBase64 = res.response.data 
      const response = await push_to_immocloud(fileBase64, `${doc.title}.pdf`, immocloudConfig.site_id, immocloudConfig.handle, immocloudConfig.id)

      if(response.error) {
        let message = ''
        if(response.error.error && response.error.error.product_id) {
          message = 'Aucun bien lié à ce document. Le document doit être créé depuis le logiciel Immofacile'
        } else if(response.error.error) {
          for(let key in response.error.error) {
            if(typeof response.error.error[key] === 'object') {
              for(let i in response.error.error[key]) {
                if(message) {
                  message += '\n'
                }
                message += response.error.error[key][i]
              }
            } else if(typeof response.error.error[key] === 'string') {
              if(message) {
                message += '\n'
              }
              message += response.error.error[key]
            }
          }
        }
        setNotification({ msg: message, type: 'danger' })
      } else {
        setNotification({ msg: "Le document a été téléchargé avec succès", type: 'danger' })
      }
    }else {
      const errorMessage = res.response?.message && Array.isArray(res.response.message) ? res.response.message.join(", ") : res.response?.message || ""
      setNotification({ msg: `Erreur API Onespan${errorMessage ? ` : ${errorMessage}` : ''}`, type: 'danger' })
    }
    setUploading(false)
  }

  // refresh icon class
  let refreshIconClass = 'refresh-icon';

  if(refreshing) {
    refreshIconClass = 'refresh-icon refreshing';
  }
  if(refreshed) {
    refreshIconClass = 'refresh-icon refreshed';
  }

  return(
    <div className={[...selectedDocuments].filter(obj => obj.id === doc.id).length > 0 ? "dashboard-row signatures-row active" : "dashboard-row signatures-row"}>
      <div className="dashboard-column col-1">
        <Checkbox 
          onChange={onCheckboxChange} 
          checked={[...selectedDocuments].filter(obj => obj.id === doc.id).length > 0} 
        />
        <span className="icon"><DescriptionOutlinedIcon /></span>
        <div className="dashboard-column__main" onClick={onTitleClick}>
          <p className="title">{doc.title}</p>
        </div>
      </div>
      <div className="dashboard-column col-2 signatures-col-2" data-label="Destinataires:">
        {doc.recipients?.map((r, idx) => (
          <LetterCircle key={idx} data={r.email} />
        ))}
      </div>
      <div className="dashboard-column col-3 signatures-col-3" data-label="Envoyé par:">{<LetterCircle data={doc.sentBy} />}</div>
      <div className="dashboard-column col-4 signatures-col-4 small" data-label="Créé le:">
        {moment(doc.createdAt).format('DD MMM YYYY (HH') + 'h' + moment(doc.createdAt).format('mm)')}
      </div>
      <div className="dashboard-column col-5 signatures-col-5 center" data-label="Statut:">
        <Status type={status} onClick={() => onStatusClick(doc)} />
        {(doc.status === 'SIGNING_PENDING' || doc.status === 'COMPLETED') ? doc.status_updated_at ? 
        <CustomTooltip 
          content={`Dernier statut vérifié le ${moment(doc.status_updated_at).format('DD MMM YYYY à HH[h]mm')}`} 
          position="top-right" 
          style={{ whiteSpace: 'nowrap' }}
        >
          <div className={refreshIconClass} onClick={refreshStatusHandler}>
            <RefreshIcon />
          </div>
        </CustomTooltip> 
          : 
        <div className={refreshIconClass} onClick={refreshStatusHandler}>
          <RefreshIcon />
        </div>
        : null}
      </div>
      <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
        <div className="more">
          <IconButton icon={<MoreVertIcon />} onButtonClick={() => setShowDropdown(!showDropdown)} />
          {showDropdown && <ul className="dropdown" ref={dropdownEl}>
            <li><a href="/#" onClick={(e) => onDownload(e, doc)} className="rotate-180"><PublishIcon /> <span>Télécharger</span></a></li>
            <li><a href="/#" onClick={(e) => onDelete(e, doc)}><DeleteIcon /> <span>Supprimer</span></a> </li>
          </ul>}
        </div>
      </ClickAwayListener>

      {showButtonsModal && <Modal onClose={() => setShowButtonsModal(false)} medium>
        <div className="buttons-wrapper">
          <Button primaryLight medium text="Aperçu" onButtonClick={onPreviewBtnClick} />
          <Button primaryLight medium text="Envoyer vers l'Immocloud" onButtonClick={onUploadBtnClick} />
        </div>
      </Modal>}
      {uploading && <ResponseLoader text="Téléchargement en cours" />}
    </div>
  );
}

SignaturesRow.propTypes = {
  selectedDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  doc: PropTypes.object.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onDetailView: PropTypes.func
}

export default SignaturesRow;