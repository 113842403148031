const Associate = () => {
    return (
        <span className="svg-icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 2.25H2.25V4.5C3.495 4.5 4.5 3.495 4.5 2.25ZM10.5 2.25H9C9 5.9775 5.9775 9 2.25 9V10.5C6.81 10.5 10.5 6.8025 10.5 2.25ZM7.5 2.25H6C6 4.32 4.32 6 2.25 6V7.5C5.1525 7.5 7.5 5.1525 7.5 2.25ZM7.5 15.75H9C9 12.0225 12.0225 9 15.75 9V7.5C11.1975 7.5 7.5 11.1975 7.5 15.75ZM13.5 15.75H15.75V13.5C14.505 13.5 13.5 14.505 13.5 15.75ZM10.5 15.75H12C12 13.68 13.68 12 15.75 12V10.5C12.8475 10.5 10.5 12.8475 10.5 15.75Z" fill="#482982" />
            </svg>
        </span>
    )
}

export default Associate