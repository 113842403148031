import axios from 'axios'
import firebase from './firebase'
import { urlSuffixForEnvironment } from '../utils'
import config from '../config.json'
import { FUNCTIONS_BASE_URL, FUNCTIONS_BASE_URL_LOCAL } from '../constants'

const baseUrl = config.useLocalAuth ? FUNCTIONS_BASE_URL_LOCAL : FUNCTIONS_BASE_URL

const signInWithJwtToken = async (token) => {
  try {
    let response = await axios.get(`${baseUrl}/jwt_auth${urlSuffixForEnvironment(config.environment)}?token=${token}`)
    let data = response.data
    if(data.authToken) {
      // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION) // TODO check if this is required
      let userCredential = await firebase.auth().signInWithCustomToken(data.authToken)
      return { ...data, user: userCredential.user }
    } else {
      return data
    }
  } catch(err) {
    if(err.response && err.response.data) {
      return err.response.data
    } else {
      return { error: err }
    }
  }
}

const currentUser = () => {
  if(!firebase.auth().currentUser) {
    return null
  } else {
    return firebase.auth().currentUser
  }
}

export {
  signInWithJwtToken,
  currentUser
}