const SentimentNeutral = () => {
    return (
        <span className="svg-icon">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0026 23.3335H25.0026V25.8335H15.0026V23.3335Z" />
                <path d="M25.8359 18.3335C27.2167 18.3335 28.3359 17.2142 28.3359 15.8335C28.3359 14.4528 27.2167 13.3335 25.8359 13.3335C24.4552 13.3335 23.3359 14.4528 23.3359 15.8335C23.3359 17.2142 24.4552 18.3335 25.8359 18.3335Z" />
                <path d="M14.1693 18.3335C15.55 18.3335 16.6693 17.2142 16.6693 15.8335C16.6693 14.4528 15.55 13.3335 14.1693 13.3335C12.7886 13.3335 11.6693 14.4528 11.6693 15.8335C11.6693 17.2142 12.7886 18.3335 14.1693 18.3335Z" />
                <path d="M19.9859 3.3335C10.7859 3.3335 3.33594 10.8002 3.33594 20.0002C3.33594 29.2002 10.7859 36.6668 19.9859 36.6668C29.2026 36.6668 36.6693 29.2002 36.6693 20.0002C36.6693 10.8002 29.2026 3.3335 19.9859 3.3335ZM20.0026 33.3335C12.6359 33.3335 6.66927 27.3668 6.66927 20.0002C6.66927 12.6335 12.6359 6.66683 20.0026 6.66683C27.3693 6.66683 33.3359 12.6335 33.3359 20.0002C33.3359 27.3668 27.3693 33.3335 20.0026 33.3335Z" />
            </svg>
        </span>
    )
}

export default SentimentNeutral