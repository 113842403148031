import axios from 'axios'
import { urlSuffixForEnvironment } from '../utils'
import firebase from './firebase'
import config from '../config.json'

// const baseUrl = `http://localhost:5001/jurisur-be/europe-west1/api${urlSuffixForEnvironment(config.environment)}`
const baseUrl = `https://europe-west1-jurisur-be.cloudfunctions.net/api${urlSuffixForEnvironment(config.environment)}`

const log_event = async (events, partner) => {
  if(!events) {
    return
  }  
  return await post_request(`${baseUrl}/log_event`, { events, partner })
}

const post_request = async (url, data) => {
  const token = await firebase.auth().currentUser.getIdToken()
  let response = await axios.post(`${url}?id_token=${token}`, data, {
    headers: {
      "Content-Type": "application/json"
    }
  })
  if(response.data.error) {
    console.log("firestore error")
    console.log(response.data.error)
    return response.data
  } 
  return response.data
}

export {
  log_event
}