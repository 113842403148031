import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CachedOutlined,
  DescriptionOutlined,
  InsertDriveFileOutlined,
  SearchOutlined,
  Folder,
} from '@material-ui/icons';

import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Loader from '../../UI/Loader';
import { sortArrayOfObjects } from '../../../utils';
import { FolderContext } from '../../../context/folders/folderState';
import { DocumentsContext } from '../../../context/documents/documentsState';

const InjectModal = ({ onClose, doc, template, allVariables, refresh }) => {
  const { folders, foldersFetched } = useContext(FolderContext);
  const { templates, templatesLoaded } = useContext(DocumentsContext);
  const [templatesArr, setTemplatesArr] = useState([]);
  const [filteredArr, setFilteredArr] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedFolder, setSelectedFolder] = useState('root');
  const [numOfTmpltsInFolder, setNumOfTmpltsInFolder] = useState({});
  const [isReady, setIsReady] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(templates).length > 0 && foldersFetched && !isReady) {
      let arr = [];
      const allVars = allVariables(
        JSON.parse(JSON.stringify(template)),
        false,
        true
      );
      const values = [...new Set([...allVars])];
      // const exclude = ['manufacturers_name', 'manufacturers_contact_firstname', 'manufacturers_contact_name', 'adresse', 'postal_code', 'city', 'telephone', 'email', 'manufacturers_description', 'admin_fonction']
      let tmpltsByFolders = {};

      folders.forEach((f) => {
        tmpltsByFolders[f.id] = 0;
      });

      for (let key in templates) {
        const tmpl = templates[key];
        let sameVars = [];
        let allDocVars = allVariables(
          JSON.parse(JSON.stringify(tmpl)),
          false,
          true
        );
        const dValues = [...new Set(allDocVars)];
        for (let i = 0; i < dValues.length; i++) {
          const vKey = dValues[i];
          if (values.includes(vKey)) sameVars.push(vKey);
        }
        arr.push({ id: key, vars: sameVars, ...tmpl });
        if (tmpl.folderId && tmpl.folderId.length) {
          tmpltsByFolders[tmpl.folderId[0]] += 1;
        }
      }
      let filtered = arr.filter((d) => !d.folderId || d.folderId.length === 0);
      tmpltsByFolders['root'] = filtered.length;
      setNumOfTmpltsInFolder(tmpltsByFolders);
      setTemplatesArr(sortArrayOfObjects(arr, 'name'));
      setFilteredArr(sortArrayOfObjects(filtered, 'name'));
      setIsReady(true);
    }
  }, [
    templates,
    allVariables,
    template,
    isReady,
    templatesLoaded,
    foldersFetched,
    folders,
  ]);

  // On search change
  const searchChangeHandler = (e) => {
    const { value } = e.target;
    setSearch(value);
    filterBySearch(value, templatesArr, selectedFolder);
  };

  // Filter by search
  const filterBySearch = (value, dcmntsArr, folder) => {
    let arr;
    let filtered;
    if (folder === 'root') {
      arr = dcmntsArr.filter((d) => !d.folderId || d.folderId.length === 0);
    } else {
      arr = dcmntsArr.filter((d) => d.folderId && d.folderId.includes(folder));
    }
    filtered = arr;
    if (value.trim() !== '') {
      filtered = arr.filter((d) =>
        d.name.toLowerCase().includes(value.trim().toLowerCase())
      );
    }
    setFilteredArr(filtered);
  };

  // On template click
  const tmplClickHandler = (tmpl) => {
    let values = { ...doc.values };
    let tmplValues = {};
    for (let key in values) {
      tmplValues[key] = values[key];
    }
    onClose();
    history.replace({
      pathname: `/template/${tmpl.id}`,
      state: { tmplValues, refreshTemplate: refresh },
    });
  };

  // On folder click
  const folderClickHandler = (f) => {
    if (f) {
      let filtered = templatesArr.filter(
        (d) => d.folderId && d.folderId.includes(f.id)
      );
      setSelectedFolder(f.id);
      filterBySearch(search, filtered, f.id);
      return;
    }
    let filtered = templatesArr.filter(
      (d) => !d.folderId || d.folderId.length === 0
    );
    setSelectedFolder('root');
    filterBySearch(search, filtered, 'root');
  };

  return (
    <Modal onClose={onClose}>
      <div className="inject-modal">
        <div className="inject-modal__head">
          <h2>
            <CachedOutlined /> Injecter dans un nouveau document
          </h2>
        </div>
        <div className="inject-modal__search">
          <Input
            iconEl={<SearchOutlined />}
            value={search}
            onChange={searchChangeHandler}
            placeholder="Entrez les premières lettres d'un document"
            formEl
          />
        </div>
        {!isReady ? (
          <div className="loader-wrapper loader-wrapper--2">
            <Loader primary small normal />
          </div>
        ) : (
          <div className="inject-modal__body">
            <div className="inject-modal__body_left">
              <h6>DOSSIERS</h6>
              <ul>
                <li
                  className={`${selectedFolder === 'root' ? 'active' : ''}`}
                  onClick={() => folderClickHandler(null)}
                >
                  <div
                    className={`${
                      selectedFolder === 'root' ? 'main active' : 'main'
                    }`}
                  >
                    <Folder /> Dossier racine{' '}
                    <span className="num">{numOfTmpltsInFolder.root}</span>
                  </div>
                </li>
                {foldersFetched ? (
                  folders.map((f, i) =>
                    !f.parentFolder ? (
                      <FolderItem
                        key={i}
                        f={f}
                        selectedFolder={selectedFolder}
                        folderClickHandler={folderClickHandler}
                        folders={folders}
                        numOfTmpltsInFolder={numOfTmpltsInFolder}
                      />
                    ) : null
                  )
                ) : (
                  <div className="loader-wrapper loader-wrapper--3">
                    <Loader primary small normal />
                  </div>
                )}
              </ul>
            </div>
            <div className="inject-modal__body_right">
              <ul className="documents-list">
                {filteredArr.map((tmpl, i) => (
                  <li key={i} onClick={() => tmplClickHandler(tmpl)}>
                    <span className="icon">
                      <InsertDriveFileOutlined />
                    </span>
                    <span className="icon hover">
                      <DescriptionOutlined />
                    </span>
                    {tmpl.name}
                  </li>
                ))}
              </ul>
              {filteredArr.length === 0 && (
                <p className="no-data">Aucun document dans ce dossier</p>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const FolderItem = ({
  f,
  selectedFolder,
  folderClickHandler,
  folders,
  numOfTmpltsInFolder,
}) => {
  const subfolders = [...folders].filter((fol) => fol.parentFolder === f.id);

  return (
    <li>
      <div
        className={`${selectedFolder === f.id ? 'main active' : 'main'}`}
        onClick={() => folderClickHandler(f)}
      >
        <Folder /> {f.name}{' '}
        <span className="num">{numOfTmpltsInFolder[f.id]}</span>
      </div>
      {subfolders.length > 0 && (
        <ul className="subfolder">
          {subfolders.map((fol, j) => (
            <FolderItem
              key={j}
              f={fol}
              selectedFolder={selectedFolder}
              folderClickHandler={folderClickHandler}
              folders={folders}
              numOfTmpltsInFolder={numOfTmpltsInFolder}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default InjectModal;
