import React from 'react'
import { CheckCircle } from '../../../assets/icons'

const SenderModalContentIdentityVerificationSuccess = ({ onClose }) => {

  return (
    <div className=''>
      <div className='folder-modal-v2__heading'>
        <h2 className='modal-title'>Associer un compte expéditeur AR24</h2>
        <ul className='flex flex-row justify-end gap-2'>
          <li>
            <button
              className='btn btn--medium btn--transparent'
              onClick={onClose}
            >
              Fermer
            </button>
          </li>
        </ul>
      </div>
      <div className='folder-modal-v2__content'>
        <div className='sent-successful'>
          <CheckCircle />
          Votre demande a bien été transmise
        </div>
        <ul className='modal-list'>
          <li className='modal-list-item'>
            <p>
              Vous recevrez sous 3 jours ouvrés votre <b>identifiant LRE</b> contenant votre <b>clé OTP</b>.
            </p>
          </li>
        </ul>
      </div>

    </div>
  )
}

export default SenderModalContentIdentityVerificationSuccess