import React, { useState, useContext, useEffect } from 'react' 
import { useParams } from 'react-router-dom'
import draftToHtml from 'draftjs-to-html'

import Loader from '../../UI/Loader'
import SupportPageLayout from '../../layouts/SupportPageLayout'
import { HelpContext } from '../../../context'
import { increment_article_read_count } from '../../../services/support'
import { MainLayout } from '../../../layouts'

const HelpArticle = ({v2}) => {
  const { articles, articlesFetched, selectedCategory, setSelectedCategory } = useContext(HelpContext)
  const { id } = useParams()
  const [article, setArticle] = useState({})
  const [articleDoesNotExist, setArticleDoesNotExist] = useState(false)

  // Update read count when article is opened
  useEffect(() => {
    increment_article_read_count(id)
  }, [id])

  // Set article from id
  useEffect(() => {
    if(articlesFetched) {
      if(articles[id]) {
        setArticle(articles[id])
        if(!selectedCategory) {
          setSelectedCategory(articles[id].category)
        }
      }else {
        setArticleDoesNotExist(true)
      }
    }
  }, [id, articles, articlesFetched, selectedCategory, setSelectedCategory])

  return (
    <MainLayout className="page-help" pageTitle="Nouveautés">
      <SupportPageLayout withSidebar showBackButton v2={v2}>
        {!articlesFetched 
          ? <Loader normal small primary />
          : Object.keys(article).length > 0 && 
          <div className="help-article">
            <div className="help-article__head">
              <h2>{article.title?.en}</h2>
            </div>
            <div className="help-article__body">
              <div dangerouslySetInnerHTML={{ __html: draftToHtml(article.content.en) }}></div>
            </div>
          </div>
        }
        {articleDoesNotExist &&
          <div className="support-page__no-data">
            <p>{`L'article avec l'id(${id}) n'existe pas.`}</p>
          </div>
        }
      </SupportPageLayout>
    </MainLayout>
  )
}

export default HelpArticle