import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { UserSolid, Building, BgImage } from '../assets/icons'
import { Input, Textarea, DateInput, Select, Option } from '../components/ui_new'
import { SettingsLayout } from '../layouts'
import { UserContext } from '../context'
import { FEATURE, dateValueFormat, isFeatureEnabled } from '../utils'

const agencyDetailsFields = [
  { name: 'manufacturers_name', label: "Nom agence", type: 'string', size: 'full' },
  // { name: 'manufacturers_logo', label: "Logo de l'agence", type: 'image' },
  // { name: 'manufacturers_image', label: "Lien vers l'image de l'agence", type: 'image' },
  { name: 'adresse', label: "Adresse de l'agence", type: 'string', size: 'full' },
  { name: 'postal_code', label: "Code postal de l'agence", type: 'string' },
  { name: 'city', label: "Ville de l'agence", type: 'string' },
  { name: 'telephone', label: "Téléphone de l’agence", type: 'string' },
  { name: 'email', label: "Mail de l'agence", type: 'string' },
  { name: 'fax', label: "Fax de l'agence", type: 'string' },
  { name: 'email_location', label: "Email contact pour la location", type: 'string' },
]

const agencyContactFields = [
  { name: 'manufacturers_gender', label: "Genre du contact agence", type: 'string' },
  { name: 'manufacturers_contact_firstname', label: "Prénom du contact agence", type: 'string' },
  { name: 'manufacturers_contact_name', label: "Nom du contact agence", type: 'string' },
  { name: 'manufacturers_contact_telephone', label: "Téléphone du contact", type: 'string' },
  { name: 'manufacturers_contact_email', label: "Adresse e-mail du contact", type: 'string' },
  { name: 'manufacturers_fonction', label: "Fonction du contact agence", type: 'string' },
  { name: 'email_location', label: "Email contact pour la location", type: 'string' },
]

const kbisFields = [
  { name: 'statut_name', label: "Raison sociale agence", type: 'string' },
  { name: 'adresse_siege', label: "Adresse de la raison sociale", type: 'string' },
  { name: 'cp_siege', label: "Code postal de la raison sociale", type: 'string' },
  { name: 'ville_siege', label: "Ville de la raison sociale", type: 'string' },
  { name: 'forme', label: "Forme de la raison sociale", type: 'string' },
  { name: 'capital', label: "Capital de la raison sociale", type: 'string' },
  { name: 'code_ape', label: "Code APE ", type: 'string' },
  { name: 'tva_intra', label: "N° TVA Intra communautaire", type: 'string' },
  { name: 'no_rc', label: "SIRET agence (14 chiffres)", type: 'string' },
  { name: 'rcs', label: "RCS de l'agence", type: 'string' },
  { name: 'ville_rcs', label: "Ville RCS de l'agence", type: 'string' },
]

const escrowAccountFields = [
  { name: 'compte_sequestre', label: "Numéro compte séquestre de l’agence", type: 'string', size: 'full' },
  { name: 'banque_sequestre', label: "Nom de la banque", type: 'string' },
  { name: 'detention_fonds', label: "Détention de fonds (séquestre)", type: 'string' },
]

const proffesionalTransactionCardFields = [
  { name: 'mention_carte_pro', label: "Mention carte pro transaction", type: 'string' },
  { name: 'no_carte_pro', label: "N° carte pro transaction", type: 'string' },
  { name: 'detenteur_carte_pro', label: "Détenteur de la carte pro transaction", type: 'string', size: 'full' },
  { type: 'line', size: 'full' },
  { name: 'delivree_par_prefecture', label: "Carte pro transaction délivrée par (CCI/préfecture)", type: 'string', size: 'full' },
  { name: 'adr_prefecture_carte_pro', label: "Adresse CCI/Préfecture", type: 'string' },
  { name: 'cp_prefecture_carte_pro', label: "Code postal CCI/Préfecture", type: 'string' },
  { name: 'ville_prefecture_carte_pro', label: "Ville CCI/Préfecture", type: 'string' },
  { name: 'date_de_creation', label: "Date de création de la carte pro", type: 'date' },
  { type: 'line', size: 'full' },
  // { name: 'caisse', label: "Nom de la caisse de garantie carte pro transaction", type: 'string' },
  { name: 'no_caisse', label: "N° caisse de garantie carte pro", type: 'string' },
  { name: 'montant_caisse', label: "Montant caisse de garantie", type: 'string' },
  { name: 'adr_caisse', label: "Adresse caisse de garantie carte pro transaction", type: 'string', size: 'full' },
  { name: '', label: "Code postal caisse de garantie", type: 'string' }, // TODO-Martin: please check name property
  { name: '', label: "Ville caisse de garantie", type: 'string' } // TODO-Martin: please check name property
]

const proffesionalManagementCardFields = [
  { name: 'mention_carte_pro_gestion', label: "Mention carte pro gestion", type: 'string' },
  { name: 'no_carte_pro_gestion', label: "N° carte pro gestion", type: 'string' },
  { name: 'detenteur_carte_pro_gestion', label: "Détenteur de la carte pro gestion", type: 'string', size: 'full' },
  { type: 'line', size: 'full' },
  { name: 'delivree_par_prefecture_gestion', label: "Carte pro gestion délivrée par (CCI/préfecture)", type: 'string', size: 'full' },
  { name: 'adr_prefecture_carte_pro_gestion', label: "Adresse CCI/Préfecture", type: 'string' },
  { name: 'cp_prefecture_carte_pro_gestion', label: "Code postal CCI/Préfecture", type: 'string' },
  { name: 'ville_prefecture_carte_pro_gestion', label: "Ville CCI/Préfecture ", type: 'string' },
  { name: 'date_de_creation_gestion', label: "Date de création de la carte pro", type: 'date' },
  { type: 'line', size: 'full' },
  // { name: 'caisse_gestion', label: "Nom de la caisse de garantie carte pro gestion", type: 'string' },
  { name: 'no_caisse_gestion', label: "N° caisse de garantie carte pro", type: 'string' },
  { name: 'montant_caisse_gestion', label: "Montant caisse de garantie", type: 'string' },
  { name: 'adr_caisse_gestion', label: "Adresse caisse de garantie carte pro gestion", type: 'string', size: 'full' },
  { name: '', label: "Code postal caisse de garantie", type: 'string' }, // TODO-Martin: please check name property
  { name: '', label: "Ville caisse de garantie", type: 'string' } // TODO-Martin: please check name property
]

const proffesionalCardForTrusteesFields = [
  { name: 'mention_carte_pro_syndic', label: "Mention carte pro syndic", type: 'string' },
  { name: 'no_carte_pro_syndic', label: "N° carte pro syndic", type: 'string' },
  { name: 'detenteur_carte_pro_syndic', label: "Détenteur de la carte pro syndic", type: 'string', size: 'full' },
  { type: 'line', size: 'full' },
  { name: 'delivree_par_prefecture_syndic', label: "Carte pro syndic délivrée par (CCI/préfecture)", type: 'string', size: 'full' },
  { name: 'adr_prefecture_carte_pro_syndic', label: "Adresse CCI/Préfecture", type: 'string' },
  { name: 'cp_prefecture_carte_pro_syndic', label: "Code postal CCI/Préfecture", type: 'string' },
  { name: 'ville_prefecture_carte_pro_syndic', label: "Ville CCI/Préfecture", type: 'string' },
  { name: 'date_de_creation_syndic', label: "Date de création de la carte pro", type: 'date' },
  { type: 'line', size: 'full' },
  { name: 'caisse_syndic', label: "N° caisse de garantie carte pro", type: 'string' },
  // { name: 'no_caisse_syndic', label: "N° caisse de garantie carte pro syndic", type: 'string' },
  { name: 'montant_caisse_syndic', label: "Montant caisse de garantie", type: 'string' },
  { name: 'adr_caisse_syndic', label: "Adresse caisse de garantie carte pro syndic", type: 'string', size: 'full' },
  { name: '', label: "Code postal caisse de garantie", type: 'string' }, // TODO-Martin: please check name property
  { name: '', label: "Ville caisse de garantie", type: 'string' } // TODO-Martin: please check name property
]

const proffesionalLiabilityInsuranceFields = [
  { name: 'rcp', label: "RCP agence", type: 'string' },
  { name: 'rcp_organisme', label: "Nom assureur rcp agence", type: 'string' },
  { name: 'rcp_adresse', label: "Adresse assureur rcp agence", type: 'string' },
]

const bankingIntermediaryFields = [
  { name: 'numero_orias', label: "N° Orias", type: 'string' },
  { name: 'type_mandataire', label: "Type de mandataire Orias", type: 'string' },
]

const dpoFields = [
  { name: 'dpo', label: "Nom de DPO", type: 'string' },
]

const consumerMediatorFields = [
  { name: 'mediateur_nom', label: "Nom du médiateur de la consomation", type: 'string' },
  { name: 'mediateur_adresse', label: "Adresse du médiateur de la consomation", type: 'string' },
  { name: 'mediateur_date_obtention_label', label: "Date d'obtention du label médiateur", type: 'date' },
  { name: 'mediateur_site_internet', label: "Site internet médiateur", type: 'string' },
]

const agencyPresentationFields = [
  { name: 'horaires', label: "Horaires d'ouverture", type: 'string' },
  { name: 'jours_horaires', label: "Jours d'ouverture", type: 'string' },
  { name: 'manufacturers_description', label: "Description de l'agence", type: 'textbox', size: 'full' },
  { name: 'manufacturers_url', label: "Url site internet", type: 'string', size: 'full' },
  { name: 'manufacturers_url_like', label: "Url compte Facebook", type: 'string', size: 'full' },
  { name: 'manufacturers_url_twitter', label: "Url compte Twitter", type: 'string', size: 'full' },
  { name: 'manufacturers_url_googleplus', label: "Url compte Google Plus", type: 'string', size: 'full' },
  { name: 'manufacturers_url_linkedin', label: "Url compte Linkedin", type: 'string', size: 'full' },
  { name: 'manufacturers_url_youtube', label: "Url compte Youtube", type: 'string', size: 'full' },
  { name: 'manufacturers_url_pinterest', label: "Url compte Pinterest", type: 'string', size: 'full' },
  { name: 'manufacturers_url_instagram', label: "Url compte Instagram", type: 'string', size: 'full' },
]

const newtworkMembershipFields = [
  { name: 'secteur', label: "SQH - Nom du secteur", type: 'string' },
  { name: 'filiale', label: "SQH - Nom de la filiale", type: 'string' },
  { name: 'holding', label: "SQH - Nom du holding", type: 'string' },
  { name: 'reseau', label: "SQH - Nom du réseau", type: 'string' },
  { name: 'legal_mention', label: "SQH - Mentions légales de l'agence", type: 'string' },
  { name: 'nom_caisse_regionale', label: "SQH - Nom de la caisse régionale", type: 'string' },
]

const SettingsAgency = () => {
  const { user, partner, signInResult } = useContext(UserContext)
  const [activeTab, setAciveTab] = useState(0);

  const [currentAgency, setCurrentAgency] = useState(null)

  useEffect(() => {
    if (user?.agencies && user.agencies.length > 0) {
      const mainAgency = user.agencies.find(a => a.manufacturers_id === user.manufacturer_id) || user.agencies[0]
      setCurrentAgency(mainAgency)
    } else if (user.manufacturer) {
      setCurrentAgency(user.manufacturer)
    } else if (signInResult?.agency) {
      setCurrentAgency(signInResult.agency)
    } else {
      setCurrentAgency(null)
    }
  }, [user, signInResult])

  // Render fields helper
  const renderFieldsHelper = (fields) => {
    return (
      <>
        {fields.map((field, idx) => {
          let value = ''
          if (currentAgency?.[field.name]) {
            value = currentAgency[field.name]
          }
          return (
            <div key={idx} className={`agency-field mb-6 ${field.size === 'full' ? 'w-full' : 'w-1/2'}`}>
              {field.type === 'string' && (
                <Input
                  label={field.label}
                  value={value}
                  displayNC={value.length === 0}
                  disabled
                />
              )}
              {field.type === 'textbox' && (
                <Textarea
                  label={field.label}
                  value={value}
                  displayNC={value.length === 0}
                  disabled
                  className={'w-full'}
                />
              )}
              {field.type === 'image' && (
                <div className="agency-field__image">
                  <p className="agency-field__label">{field.label}</p>
                  <img src={value} alt="" />
                </div>
              )}
              {field.type === 'date' && (
                <DateInput
                  disabled={true}
                  selected={!value ? '' : new Date(moment(value, dateValueFormat).valueOf())}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                  label={field.label}
                />
              )}
              {field.type === 'line' && (
                <div className='separator-line' />
              )}
            </div>
          )
        })}
      </>
    )
  }

  // Agency contact details
  const renderAgencyContactDetails = () => {
    return (
      <section className="agency-section">
        <h3>Coordonnées de l'agence</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(agencyDetailsFields)}
        </div>
      </section>
    )
  }

  // Agency contact
  const renderAgencyContact = () => {
    return (
      <section className="agency-section">
        <h3>Contact agence</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(agencyContactFields)}
        </div>
      </section>
    )
  }

  // KBIS
  const renderKBIS = () => {
    return (
      <section className="agency-section">
        <h3>KBIS</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(kbisFields, 'information_legal')}
        </div>
      </section>
    )
  }

  // Escrow account
  const renderEscrowAccount = () => {
    return (
      <section className="agency-section">
        <h3>Compte séquestre</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(escrowAccountFields, 'information_legal')}
        </div>
      </section>
    )
  }

  // Proffesional transaction card
  const renderProffesionalTransactionCard = () => {
    return (
      <>
        <h3>Carte professionnelle transaction</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(proffesionalTransactionCardFields, 'information_legal')}
        </div>
      </>
    )
  }

  // Proffesional management card
  const renderProffesionalManagementCard = () => {
    return (
      <>
        <h3>Carte professionnelle gestion</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(proffesionalManagementCardFields, 'information_legal')}
        </div>
      </>
    )
  }

  // Professional card for trustees
  const renderProffesionalCardForTrustees = () => {
    return (
      <>
        <h3>Carte professionnelle syndic</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(proffesionalCardForTrusteesFields, 'information_legal')}
        </div>
      </>
    )
  }

  // Professional liability insurance
  const renderProffesionalLiabilityInsurance = () => {
    return (
      <section className="agency-section">
        <h3>Responsabilité civile professionnelle (RCP)</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(proffesionalLiabilityInsuranceFields, 'information_legal')}
        </div>
      </section>
    )
  }

  // Banking intermediary
  const renderBankingIntermediary = () => {
    return (
      <section className="agency-section">
        <h3>Intermédiaire en opération bancaire</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(bankingIntermediaryFields, 'information_legal')}
        </div>
      </section>
    )
  }

  // DPO
  const renderDPO = () => {
    return (
      <section className="agency-section">
        <h3>DPO</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(dpoFields, 'information_legal')}
        </div>
      </section>
    )
  }

  // Consumer mediator
  const renderConsumerMediator = () => {
    return (
      <section className="agency-section">
        <h3>Médiateur de la consommation</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(consumerMediatorFields, 'mediateur')}
        </div>
      </section>
    )
  }

  // Agency presentation
  const renderAgencyPresentation = () => {
    return (
      <section className="agency-section">
        <h3>Présentation de l'agence</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(agencyPresentationFields, 'social_links')}
        </div>
      </section>
    )
  }

  // Network membership
  const renderNetworkMembership = () => {
    return (
      <section className="agency-section">
        <h3>Appartenance réseau</h3>
        <div className="agency-fields-wrapper-v2">
          {renderFieldsHelper(newtworkMembershipFields, 'information_legal')}
        </div>
      </section>
    )
  }

  // Render fields
  const renderFields = () => {
    return (
      <div className="agency-sections">
        {renderAgencyContactDetails()}
        {/* {renderAgencyContact()} */}
        {renderKBIS()}
        {renderEscrowAccount()}
        {renderProffesionalTransactionCard()}
        {renderProffesionalManagementCard()}
        {renderProffesionalCardForTrustees()}
        {renderProffesionalLiabilityInsurance()}
        {renderBankingIntermediary()}
        {renderDPO()}
        {renderConsumerMediator()}
        {renderAgencyPresentation()}

        {partner === 'squarehabitat' && (
          <>
            {renderNetworkMembership()}
          </>
        )}
      </div>
    )
  }

  const handleSwitchAgency = (agencyId) => {
    const agency = (user?.agencies || []).find(a => a.manufacturers_id === agencyId)
    setCurrentAgency(agency)
  }

  return (
    <SettingsLayout pageTitle="Agences">
      {user?.agencies && user.agencies.length > 1 &&
        <div className='agency-select'>
          <Select onChange={handleSwitchAgency} selected={currentAgency?.manufacturers_id} type="filled">
            {user.agencies.map((agency, idx) => (
              <Option key={idx} value={agency.manufacturers_id}>{agency.manufacturers_name}</Option>
            ))}
          </Select>
        </div>
      }

      <div className="settings-profile">
        <div className='info-box mb-6'>
          Les informations affichées ici sont reprises de votre logiciel métier.
        </div>
        <div className="body">
          {!signInResult && <div className="loader-v2"></div>}
          {signInResult && <div className='grid xl:grid-cols-3 gap-6'>
            <div>
              {renderAgencyContactDetails()}
              {renderAgencyPresentation()}
              {renderBankingIntermediary()}
              {renderDPO()}
              {renderConsumerMediator()}

            </div>
            <div>
              {renderKBIS()}
              {renderEscrowAccount()}
              {renderProffesionalLiabilityInsurance()}
              {partner === 'squarehabitat' && (
                <>
                  {renderNetworkMembership()}
                </>
              )}
            </div>
            <div>

              <section className="agency-section">
                <h3>Carte professionnelle</h3>
                <div className=''>
                  <div className='settings-profile__tabs flex w-full mb-4'>
                    <div className={`w-1/3 ${activeTab === 0 ? 'active' : ''}`} onClick={() => setAciveTab(0)}>Transaction</div>
                    <div className={`w-1/3 ${activeTab === 1 ? 'active' : ''}`} onClick={() => setAciveTab(1)}>Gestion</div>
                    <div className={`w-1/3 ${activeTab === 2 ? 'active' : ''}`} onClick={() => setAciveTab(2)}>Syndic</div>
                  </div>
                  {activeTab === 0 && renderProffesionalTransactionCard()}
                  {activeTab === 1 && renderProffesionalManagementCard()}
                  {activeTab === 2 && renderProffesionalCardForTrustees()}
                </div>
              </section>
            </div>
          </div>}
        </div>
      </div>
    </SettingsLayout>
  )
}

export default SettingsAgency