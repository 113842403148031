import React, { createContext, useReducer, useState } from 'react';

import folderReducer from './folderReducer';
import { GET_FOLDERS, GET_STANDARD_TEMPLATES_FOLDERS, SET_FOLDERS, SET_FOLDERS_LOADING, SET_FOLDERS_ORDER, SET_STANDARD_TEMPLATES_FOLDERS, SET_STANDARD_TEMPLATES_FOLDERS_LOADING } from '../types';
import { fetch_folders, create_folder, update_folder, delete_folder, fetch_standard_templates_folders, create_standard_templates_folder, update_standard_templates_folder, delete_standard_templates_folder, fetch_folders_order, update_folders_order } from '../../services/firestore';
import { sortArrayOfObjects } from '../../utils'

export const FolderContext = createContext();

const FolderState = ({ children }) => {
  const initialState = {
    loading: true,
    folders: [],
    foldersFetched: false,
    standardTemplatesFolders: [],
    standardTemplatesFoldersFetched: false,
    standardTemplatesFoldersLoading: true,
    foldersOrderMap: {}
  }

  const [parentFolder, setParentFolder] = useState(null);
  const [folderToMove, setFolderToMove] = useState(null);
  const [folderBreadcrumbs, setFolderBreadcrumbs] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [currentlyActiveFolder, setCurrentlyActiveFolder] = useState(null);
  const [state, dispatch] = useReducer(folderReducer, initialState);
  // console.log('PARENT FOLDER:', parentFolder);

  const fetchFoldersOrder = async () => {
    try {
      const res = await fetch_folders_order();
      console.log('FOLDERS ORDER:', res)
      dispatch({
        type: SET_FOLDERS_ORDER,
        payload: res
      });
    }catch(err) {
      console.log(err);
    }
  }

  const updateFoldersOrder = async (type, newOrder) => {
    try {
      await update_folders_order(type, newOrder);
      const foldersOrderMap = {...state.foldersOrderMap};
      foldersOrderMap[type] = newOrder;
      dispatch({
        type: SET_FOLDERS_ORDER,
        payload: foldersOrderMap
      });
    }catch(err) {
      console.log(err);
    }
  }


  // Fetch folder
  const fetchFolders = async () => {
    try {
      const res = await fetch_folders();
      const arr = [];
      for(let id in res) {
        const folder = {...res[id]};
        folder.id = id;
        folder.folderRow = true;
        arr.push(folder);
      }
      const sortedArr = [...arr].sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      dispatch({
        type: GET_FOLDERS,
        payload: sortedArr
      });
    }catch(err) {
      console.log(err);
    }
  }

  // Create folder
  const createFolder = async (data) => {
    setFoldersLoading(true)
    try {
      const res = await create_folder(data)
      const newFolder = {...data, id: res.folderId, meta: { created: Date.now(), updated: Date.now() }}
      const folders = [...state.folders, newFolder]
      setFolders(sortArrayOfObjects(folders, 'name', 'asc'))
      setFoldersLoading(false)
    }catch(err) {
      console.log(err);
      setFoldersLoading(false); 
    }
  }

  // Set folders
  const setFolders = (value) => {
    dispatch({
      type: SET_FOLDERS,
      payload: value
    });
  }

  // Update folder
  const updateFolder = async (data, folderId, single = true, fetch = false) => {
    setFoldersLoading(true);
    try {
      await update_folder(data, folderId);
      if(single) {
        const folders = [...state.folders];
        let foundFolder = folders.find(f => f.id === folderId);
        foundFolder = {...foundFolder, ...data};
        const updatedFolders = folders.map(f => f.id === folderId ? foundFolder : f);
        setFolders(updatedFolders);
      }
      // if(!fetch) {
      //   await fetchFolders();
      // }
      setFoldersLoading(false);
    }catch(err) {
      console.log(err);
      setFoldersLoading(false); 
    }
  }

  // Update multiple folders
  const updateMultipleFolders = (arr) => {
    const folders = [...state.folders];
    const updatedFolders = [];
    arr.forEach(f => {
      const foundFolder = folders.find(fol => fol.id === f.id);
      let updatedFolder = {};
      if(foundFolder) {
        updatedFolder = {...foundFolder, ...f.data};
        updatedFolders.push(updatedFolder);
      }
    });
    const f = [...folders].map(fol => {
      const foundF = updatedFolders.find(f => f.id === fol.id);
      if(foundF) {
        return foundF;
      }else {
        return fol;
      }
    });
    setFolders(f);
  }

  // Delete folder
  const deleteFolder = async (folderId, fetch = false) => {
    setFoldersLoading(true);
    try {
      await delete_folder(folderId)
      const folders = [...state.folders]
      const updated = folders.filter(f => f.id !== folderId)
      setFolders(sortArrayOfObjects(updated, 'name', 'asc'))
      // if(!fetch) {
      //   await fetchFolders();
      // }
      setFoldersLoading(false);
    }catch(err) {
      console.log(err);
      setFoldersLoading(false); 
    }
  }

  // Set loading
  const setFoldersLoading = (value) => {
    dispatch({
      type: SET_FOLDERS_LOADING,
      payload: value
    });
  }

  
  const fetchStandardTemplatesFolders = async () => {
    try {
      const res = await fetch_standard_templates_folders();
      const arr = [];
      for(let id in res) {
        const folder = {...res[id]};
        folder.id = id;
        folder.folderRow = true;
        arr.push(folder);
      }
      const sortedArr = [...arr].sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      dispatch({
        type: GET_STANDARD_TEMPLATES_FOLDERS,
        payload: sortedArr
      });
    }catch(err) {
      console.log(err);
    }
  }

  const setStandardTemplatesFolders = (value) => {
    dispatch({
      type: SET_STANDARD_TEMPLATES_FOLDERS,
      payload: value
    });
  }

  const createStandardTemplatesFolder = async (data) => {
    setStandardTemplatesFoldersLoading(true)
    try {
      const res = await create_standard_templates_folder(data)
      const newFolder = {...data, id: res.folderId, meta: { created: Date.now(), updated: Date.now() }}
      const folders = [...state.standardTemplatesFolders, newFolder]
      setStandardTemplatesFolders(sortArrayOfObjects(folders, 'name', 'asc'))
      setStandardTemplatesFoldersLoading(false)
    }catch(err) {
      console.log(err);
      setStandardTemplatesFoldersLoading(false); 
    }
  }

  const updateStandardTemplatesFolder = async (data, folderId, single = true, fetch = false) => {
    setStandardTemplatesFoldersLoading(true);
    try {
      await update_standard_templates_folder(data, folderId);
      if(single) {
        const folders = [...state.standardTemplatesFolders];
        let foundFolder = folders.find(f => f.id === folderId);
        foundFolder = {...foundFolder, ...data};
        const updatedFolders = folders.map(f => f.id === folderId ? foundFolder : f);
        setStandardTemplatesFolders(updatedFolders);
      }
      setStandardTemplatesFoldersLoading(false);
    }catch(err) {
      console.log(err);
      setStandardTemplatesFoldersLoading(false); 
    }
  }

  const deleteStandardTemplatesFolder = async (folderId, fetch = false) => {
    setStandardTemplatesFoldersLoading(true);
    try {
      await delete_standard_templates_folder(folderId)
      const folders = [...state.standardTemplatesFolders]
      const updated = folders.filter(f => f.id !== folderId)
      setStandardTemplatesFolders(sortArrayOfObjects(updated, 'name', 'asc'))
      setStandardTemplatesFoldersLoading(false);
    }catch(err) {
      console.log(err);
      setStandardTemplatesFoldersLoading(false); 
    }
  }

  const setStandardTemplatesFoldersLoading = (value) => {
    dispatch({
      type: SET_STANDARD_TEMPLATES_FOLDERS_LOADING,
      payload: value
    });
  }

  return <FolderContext.Provider value={{
    folders: state.folders,
    foldersLoading: state.loading,
    foldersFetched: state.foldersFetched,
    parentFolder,
    folderToMove,
    folderBreadcrumbs,
    selectedFilter,
    currentlyActiveFolder,
    standardTemplatesFolders: state.standardTemplatesFolders,
    standardTemplatesFoldersFetched: state.standardTemplatesFoldersFetched,
    standardTemplatesFoldersLoading: state.standardTemplatesFoldersLoading,
    foldersOrderMap: state.foldersOrderMap,
    setFoldersLoading,
    fetchFoldersOrder,
    updateFoldersOrder,
    fetchFolders,
    createFolder,
    updateFolder,
    deleteFolder,
    setParentFolder,
    setFolderToMove,
    setFolderBreadcrumbs,
    setSelectedFilter,
    updateMultipleFolders,
    setCurrentlyActiveFolder,
    fetchStandardTemplatesFolders,
    setStandardTemplatesFolders,
    createStandardTemplatesFolder,
    updateStandardTemplatesFolder,
    deleteStandardTemplatesFolder,
    setStandardTemplatesFoldersLoading
  }}>
    {children}
  </FolderContext.Provider>
}

export default FolderState;