import React, { createContext, useReducer, useState } from 'react'

import { SET_ATTACHMENTS_ORDER, SET_DEFAULT_ATTACHMENTS } from '../types'
import attachmentsReducer from './attachmentsReducer'
import { create_default_attachment, delete_default_attachment, fetch_default_attachments, fetch_items_order, update_default_attachment, update_items_order } from '../../services/firestore'
import { upload_file_to_storage } from '../../services/storage'

export const AttachmentsContext = createContext()

const AttachmentsState = ({ children }) => {
  const initialState = {
    defaultAttachments: {},
    defaultAttachmentsOrder: [],
  }
  const [state, dispatch] = useReducer(attachmentsReducer, initialState)

  const [prefilledAttachmentVariable, setPrefilledAttachmentVariable] = useState(null)

  const fetchDefaultAttachments = async () => {
    const attachments = await fetch_default_attachments()
    dispatch({
      type: SET_DEFAULT_ATTACHMENTS,
      payload: attachments,
    })
  }

  const createDefaultAttachment = async (data) => {
    console.log("create default attachment", data)
    const fileBase64 = data.data.split(',')[1]
    const uploadResponse = await upload_file_to_storage(fileBase64, `${data.name}_${Date.now()}`, 'default_attachments', data.type)
    console.log("storageResponse", uploadResponse)

    const attachmentData = {
      name: data.name,
      size: data.size,
      type: data.type,
      format: data.format,
      url: uploadResponse.url,
      path: uploadResponse.path,
      meta: {
        created: Date.now(),
        updated: Date.now(),
      }
    }
    const response = await create_default_attachment(attachmentData)
    const updatedAttachments = {...state.defaultAttachments}
    updatedAttachments[response.id] = attachmentData
    dispatch({
      type: SET_DEFAULT_ATTACHMENTS,
      payload: updatedAttachments,
    })
  }

  const createMultipleDefaultAttachments = async (filesData) => {
    console.log("create multiple default attachments", filesData)
    const updatedAttachments = {...state.defaultAttachments}
    for(const fileData of filesData) {
      const fileBase64 = fileData.data.split(',')[1]
      const uploadResponse = await upload_file_to_storage(fileBase64, `${fileData.name}_${Date.now()}`, 'default_attachments', fileData.type)
      console.log("storageResponse", uploadResponse)
      const attachmentData = {
        name: fileData.name,
        size: fileData.size,
        type: fileData.type,
        format: fileData.format,
        url: uploadResponse.url,
        path: uploadResponse.path,
        meta: {
          created: Date.now(),
          updated: Date.now(),
        }
      }
      const response = await create_default_attachment(attachmentData)
      updatedAttachments[response.id] = attachmentData      
    }
    dispatch({
      type: SET_DEFAULT_ATTACHMENTS,
      payload: updatedAttachments,
    })
  }

  const updateDefaultAttachment = async (id, data) => {
    console.log("update default attachment", id, data)
    // const attachmentUpdates = {
    //   name: data.name,
    // }
    if(data.meta) {
      data.meta.updated = Date.now()
    }
    const response = await update_default_attachment(id, data)
    if(!response.success) {
      console.error("Error updating attachment", response)
      return
    }
    const updatedAttachments = {...state.defaultAttachments}
    updatedAttachments[id] = {...updatedAttachments[id], ...data}
    dispatch({
      type: SET_DEFAULT_ATTACHMENTS,
      payload: updatedAttachments,
    })
  }

  const deleteDefaultAttachment = async (id) => {
    console.log("delete default attachment", id)
    const response = await delete_default_attachment(id)
    if(!response || response.error) {
      console.error("Error deleting attachment", response)
      return
    }
    const updatedAttachments = {...state.defaultAttachments}
    delete updatedAttachments[id]
    dispatch({
      type: SET_DEFAULT_ATTACHMENTS,
      payload: updatedAttachments,
    })
  }

  const fetchDefaultAttachmentsOrder = async () => {
    try {
      const res = await fetch_items_order();
      console.log('Items ORDER:', res)
      dispatch({
        type: SET_ATTACHMENTS_ORDER,
        payload: (res.default_attachments || [])
      });
    }catch(err) {
      console.log(err);
    }
  }

  const updateDefaultAttachmentsOrder = async (newOrder) => {
    try {
      await update_items_order('default_attachments', newOrder);
      dispatch({
        type: SET_ATTACHMENTS_ORDER,
        payload: newOrder
      });
    }catch(err) {
      console.log(err);
    }
  }

  return <AttachmentsContext.Provider value={{
    defaultAttachments: state.defaultAttachments,
    defaultAttachmentsOrder: state.defaultAttachmentsOrder,
    prefilledAttachmentVariable,
    fetchDefaultAttachments,
    createDefaultAttachment,
    createMultipleDefaultAttachments,
    updateDefaultAttachment,
    deleteDefaultAttachment,
    fetchDefaultAttachmentsOrder,
    updateDefaultAttachmentsOrder,
    setPrefilledAttachmentVariable,
  }}>
    {children}
  </AttachmentsContext.Provider>
}

export default AttachmentsState
