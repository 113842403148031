import { useContext, useCallback } from 'react'
import { PDFDocument } from 'pdf-lib'
import moment from 'moment'

import { SignatureContext, UserContext, DocumentsContext, NotificationContext } from '../context'
import { get_signatures_credits, get_file } from '../services/firestore'
import { convert_attachment } from '../services/lawstudioApi'
import { sendSignatureRequest } from '../services/functions'
import { validateEmail, validateMobileNumber } from '../helpers/validate'
import { fetch_document_data } from '../helpers/documents'
import { base64toBlob, readFileAsync } from '../utils'

const useDocumentSignActions = (template, doc) => {
  const { addSignature } = useContext(SignatureContext)
  const { isNotSQHorCAIpartner, user, agency, partner } = useContext(UserContext)
  const { updateDocument } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)

  // Fetch credits
  const fetchCredits = async () => {
    try {
      const res = await get_signatures_credits()
      let credits = 0
      if(res.authorizations?.tokens && Array.isArray(res.authorizations.tokens) && res.authorizations.tokens.length > 0) {
        res.authorizations.tokens.forEach(item => {
          if(item.status === 'active') {
            credits += item.tokens
          }
        })
      }
      return credits
    } catch (err) {
      console.log(err)
    }
  }

  // Validate form
  const validateForm = ({ requestFailed, setRequestFailed, signatureErrors, setSignatureErrors, emmeteur, setEmmeteurError, customers, customSigners, signers, setGeneralError, setCustomSigners, setCustomers, setSigners, adminSigners, setAdminSigners, fieldsPositions, setShowSendRequestAlert }) => {

    // Remove error message
    if(requestFailed) {
      setRequestFailed(false)
    }
    if(signatureErrors.length > 0) {
      setSignatureErrors([])
    }

    // Validation
    let errors = {}

    if(emmeteur.trim() === '') {
      setEmmeteurError('Ce champ est obligatoire')
      errors.emmeteur = true
    }else if(!validateEmail(emmeteur.trim())){
      setEmmeteurError('Adresse email non valide')
      errors.emmeteur = true
    }else {
      setEmmeteurError('')
      delete errors.emmeteur
    }
    let noUsersCheck = 
    isNotSQHorCAIpartner 
      ? customers.length === 0 && customSigners.length === 0 
      : signers.length === 0
  
    if(noUsersCheck) {
      errors.general = true
      setGeneralError('Veuillez ajouter des signataires (contacts)')
    }else{
      setGeneralError('')
      if(errors.general) {
        delete errors.general
      }
    }

    if(isNotSQHorCAIpartner) {
      const cS = []
      customSigners.forEach((signer) => {
        if(signer.email.value?.trim() === '' || signer.name.value?.trim() === '' || signer.lastname.value?.trim() === '') {
          signer.error = 'Tous les champs sont obligatoires'
          errors[signer.id] = true
        }else if(!validateEmail(signer.email.value.trim())) {
          signer.error = 'Adresse email non valide'
          errors[signer.id] = true
        }
        else {
          signer.error = ''
          delete errors[signer.id]
        }
        cS.push(signer)
      })
      setCustomSigners(cS)
    }

    if(isNotSQHorCAIpartner) {
      const csmrs = []
      customers.forEach((signer) => {
        if(signer.email.value?.trim() === '' || signer.name.value?.trim() === '' || signer.lastname.value?.trim() === '' || signer.mobile.value?.trim() === '') {
          signer.error = 'Tous les champs sont obligatoires'
          errors[signer.id] = true
        }else if(!validateEmail(signer.email.value.trim())) {
          signer.error = 'Adresse email non valide'
          errors[signer.id] = true
        }else if(signer.mobile.value && signer.mobile.value.trim() !== '' && !validateMobileNumber(signer.mobile.value.trim())){
          signer.error = "Le numéro de téléphone doit comporter l'indicatif du pays (ex. +33)"
          errors[signer.id] = true
        }else {
          signer.error = ''
          delete errors[signer.id]
        }
        csmrs.push(signer)
      })
      setCustomers(csmrs)
    }

    if(!isNotSQHorCAIpartner){
      const s = []
      signers.forEach((signer) => {
        if(signer.email.value?.trim() === '' || signer.name.value?.trim() === '' || signer.lastname.value?.trim() === '' || signer.mobile.value?.trim() === '') {
          signer.error = 'Tous les champs sont obligatoires'
          errors[signer.id] = true
        }else if(!validateEmail(signer.email.value?.trim())) {
          signer.error = 'Adresse email non valide'
          errors[signer.id] = true
        }else if(signer.mobile.value && signer.mobile.value.trim() !== '' && !validateMobileNumber(signer.mobile.value.trim())){
          signer.error = "Le numéro de téléphone doit comporter l'indicatif du pays (ex. +33)"
          errors[signer.id] = true
        }else {
          signer.error = ''
          delete errors[signer.id]
        }
        s.push(signer)
      })
      setSigners(s)
    }

    const sA = []
    adminSigners.forEach((signer) => {
      if(signer.email.value?.trim() === '' || signer.name.value?.trim() === '' || signer.lastname.value?.trim() === '' || signer.mobile.value?.trim() === '') {
        signer.error = 'Tous les champs sont obligatoires'
        errors[signer.id] = true
      }else if(!validateEmail(signer.email.value?.trim())) {
        signer.error = 'Adresse email non valide'
        errors[signer.id] = true
      }else if(signer.mobile.value && signer.mobile.value.trim() !== '' && !validateMobileNumber(signer.mobile.value.trim())){
        signer.error = "Le numéro de téléphone doit comporter l'indicatif du pays (ex. +33)"
        errors[signer.id] = true
      }else {
        signer.error = ''
        delete errors[signer.id]
      }
      sA.push(signer)
    })
    setAdminSigners(sA)

    fieldsPositions.forEach(f => {
      if(f.label && f.label.value === '') {
        f.error = 'Label cannot be empty'
        errors[f.id] = true
      }else {
        f.error = ''
        delete errors[f.id]
      }
    })

    // If no errors open alert
    if(Object.keys(errors).length === 0) {
      setShowSendRequestAlert(true)
    }
  }

  // Submit form
  const submitForm = async ({ setShowSendRequestAlert, signaturesBox, setShowResponseLoader, mergedFilesBase64, attachments, fieldsPositions, customSigners, customers, adminSigners, signers, sendInOrder, orderedSignatairesArr, emmeteur, documentName, docId, setRequestSent, setUrlToDoc, setSuccessMessage, setRequestFailed, setSignatureErrors }) => {
    setShowSendRequestAlert(false)

    let boxesArr = []
    signaturesBox.boxes.forEach((b, idx) => {
      if(idx === 0) {
        boxesArr.push({
          PAGE: signaturesBox.page + 1,
          WIDTH: signaturesBox.boxW,
          HEIGHT: signaturesBox.boxH,
          TOP: signaturesBox.top,
          LEFT: signaturesBox.left,
          signerId: b.parentId
        })
      }else {
        let perRow = 2
        let currentRow = Math.ceil((idx / perRow) + 0.1)
        let posInRow = idx % 2 === 0 ? 'left' : 'right'
        boxesArr.push({
          PAGE: signaturesBox.page + 1,
          WIDTH: signaturesBox.boxW,
          HEIGHT: signaturesBox.boxH,
          TOP: currentRow === 1 ? signaturesBox.top : signaturesBox.top + (signaturesBox.boxH * (currentRow - 1)) + signaturesBox.mV * (currentRow - 1),
          LEFT: posInRow === 'left' ? signaturesBox.left : signaturesBox.left + signaturesBox.boxW + signaturesBox.mH,
          signerId: b.parentId
        })
      }
    })

    try {
      setShowResponseLoader(true)
      let base64Merged
      let attachmentsToSign = []
      let pdfBase64 
      if(!mergedFilesBase64) {
        pdfBase64 = await fetch_document_data(template, doc, 'pdf', agency, user, {}, partner)
        const blob = base64toBlob(pdfBase64, 'application/pdf')
        const filesToMergeArr = [blob]

        // If attachments are selected merge pdfs
        if(attachments) {
          for(let i = 0; i < attachments.length; i++) {
            if(attachments[i].sign) {
              attachmentsToSign.push(attachments[i])
              let res
              if(attachments[i].format === 'pdf') {
                res = await get_file({ url: attachments[i].url })
              }else {
                res = await convert_attachment(attachments[i].url)
              }
              const blob = base64toBlob(res.data, 'application/pdf')
              filesToMergeArr.push(blob)
            }
          }
        }

        const mergedPdf = await PDFDocument.create()
        for(let i = 0; i < filesToMergeArr.length; i++) {
          const bytes = await readFileAsync(filesToMergeArr[i])
          const pdf = await PDFDocument.load(bytes)
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
          copiedPages.forEach((page) => mergedPdf.addPage(page))
        }
        await mergedPdf.save()
        base64Merged = await mergedPdf.saveAsBase64()
      }
      // const pdfDoc = await PDFDocument.load(mergedFilesBase64 ? mergedFilesBase64 : base64Merged)

      const fieldsArr = []
      fieldsPositions.forEach((f) => {
        if(f.label && Object.keys(f.label).length > 0) {
          fieldsArr.push({ 
            PAGE: +f.label.page + 1,
            TOP: Math.floor(+f.label.top),
            LEFT: Math.floor(+f.label.left),
            WIDTH: Math.floor(+f.label.width),
            HEIGHT: Math.floor(+f.label.height),
            TYPE: 'LABEL',
            LIBELLE: f.label.value
          })
        }

        if(f.type === 'checkbox') {
          fieldsArr.push({ 
            PAGE: +f.page + 1,
            TOP: Math.floor(+f.top),
            LEFT: Math.floor(+f.left),
            WIDTH: 20,
            HEIGHT: 20,
            TYPE: 'CHECKBOX',
            REQUIS: f.required
          })
        }
      })

      const sArr = isNotSQHorCAIpartner ? [...customSigners, ...customers, ...adminSigners] :  [...signers, ...adminSigners]
      
      const signataireArr = !sendInOrder ? sArr.map(signer => {
        const position = boxesArr.find(b => b.signerId === signer.id)
        let signaturePos = {
          PAGE: position.PAGE ,
          WIDTH: Math.floor(position.WIDTH),
          HEIGHT: Math.floor(position.HEIGHT),
          TOP: Math.floor(position.TOP),
          LEFT: Math.floor(position.LEFT),
        }
        
        return {
          NOM: signer.name.value,
          PRENOM: signer.lastname.value,
          EMAIL: signer.email.value,
          MOBILE: signer.mobile.value,
          SIGNATURE: [signaturePos],
          type: signer.type,
          id: signer.id
      }}) : [...orderedSignatairesArr].map(signer => {
        const position = boxesArr.find(b => b.signerId === signer.id)
        let signaturePos = {
          PAGE: position.PAGE ,
          WIDTH: Math.floor(position.WIDTH),
          HEIGHT: Math.floor(position.HEIGHT),
          TOP: Math.floor(position.TOP),
          LEFT: Math.floor(position.LEFT),
        }
        return {
          NOM: signer.name.value,
          PRENOM: signer.lastname.value,
          EMAIL: signer.email.value,
          MOBILE: signer.mobile.value,
          SIGNATURE: [signaturePos],
          type: signer.type,
          id: signer.id
      }})

      const date_limit = moment().add(7, 'days').toISOString()

      console.log({
        EMETTEUR: emmeteur.trim(),
        PDF_NOM: `${documentName}.pdf`,
        // PDF_DATA: mergedFilesBase64 ? mergedFilesBase64 : base64Merged,
        SIGNATAIRE: signataireArr.map(s => ({ NOM: s.NOM, PRENOM: s.PRENOM, EMAIL: s.EMAIL, MOBILE: s.MOBILE, SIGNATURE: s.SIGNATURE })),
        SEQUENTIEL: sendInOrder,
        CHAMPS: fieldsArr,
        DATE_LIMITE: date_limit
      })

      if(!pdfBase64) {
        pdfBase64 = await fetch_document_data(template, doc, 'pdf', agency, user, {}, partner)
        // If attachments are selected send them with request
        if(attachments) {
          for(let i = 0; i < attachments.length; i++) {
            if(attachments[i].sign) {
              attachmentsToSign.push(attachments[i])
            }
          }
        }
      }

      const data = await sendSignatureRequest({
        EMETTEUR: emmeteur.trim(),
        PDF_NOM: `${documentName}.pdf`,
        // PDF_DATA: mergedFilesBase64 ? mergedFilesBase64 : base64Merged,
        documentData: pdfBase64, 
        attachmentsToSign,
        SIGNATAIRE: signataireArr.map(s => ({ NOM: s.NOM, PRENOM: s.PRENOM, EMAIL: s.EMAIL, MOBILE: s.MOBILE, SIGNATURE: s.SIGNATURE })),
        SEQUENTIEL: sendInOrder,
        CHAMPS: fieldsArr,
        DATE_LIMITE: date_limit
      })
      // console.log(data)

      if(data.success && data.response.status) {
        // Add signature to database
        const recipientsArr = [...signataireArr].map(s => ({ name: s.NOM, lastname: s.PRENOM, email: s.EMAIL, mobile: s.MOBILE, type: s.type, id: s.id }))
        const signatureData = {
          title: documentName,
          recipients: recipientsArr,
          sentBy: emmeteur.trim(),
          createdAt: Date.now(),
          package_id: data.response.data.package_id,
          document_id: data.response.data.document_id,
          members_links: data.response.data.members_links,
          doc_id: docId,
          status: 'SIGNING_PENDING',
          date: date_limit,
          status_updated_at: Date.now()
        }
        await addSignature(signatureData)

        // Save recipients to doc db to prefill data next time signature modal is opened
        await updateDocument({ signatureRecipients: recipientsArr }, doc)

        setRequestSent(true)
        setUrlToDoc(data.response.data.members_links[0].url)
        setSuccessMessage('Document envoyé')
        setShowResponseLoader(false)
        setShowSendRequestAlert(false)
      }else {
        // error
        // console.log(data)
        setRequestFailed(true)
        if(data.response?.message && data.response?.message.includes('Validation Error - Invalid position. Object placed outside of document page boundary.')) {
          setSignatureErrors(['Erreur de validation : position invalide. Objet placé en dehors des limites de la page du document'])
        }else if(data.response?.message && data.response?.message.includes('This field was not expected')){
          setSignatureErrors(['Une erreur est survenue avec le modèle de signature'])
        }else {
          if(data.response) {
            if(Array.isArray(data.response.message)) {
              setSignatureErrors(data.response.message)
            }else {
              setSignatureErrors([data.response.message])
            }
          } else if(data.error?.message) {
            if(data.error.message.toLowerCase().includes("login failed")) {
              setSignatureErrors(["L'API d'Immofacile a fourni des identifiants de signature invalides pour votre agence"])
            } else {
              setSignatureErrors([data.error.message])
            }
          } else {
            setSignatureErrors(['Quelque chose a mal tourné !'])
          }
        }
        setShowResponseLoader(false)
      }
    }catch(err) {
      console.log(err)
      setRequestFailed(true)
      setSignatureErrors(['Une erreur est survenue, merci de réessayer'])
      setShowResponseLoader(false)
    }
  }

  // Load pdf 
  const loadPdf = useCallback(async ({ attachments, setMergedFilesBase64, setShowPlaceSignatureInterface, setShowResponseLoader }, openModal = true) => {
    try {
      const pdfBase64 = await fetch_document_data(template, doc, 'pdf', agency, user, {}, partner)
      const blob = base64toBlob(pdfBase64, 'application/pdf')
      const filesToMergeArr = [blob]
      let base64Merged = pdfBase64

      let numOfPages
      
      // If attachments are selected merge pdfs
      if(attachments) {
        for(let i = 0; i < attachments.length; i++) {
          if(attachments[i].sign) {
            let res
            if(attachments[i].format === 'pdf') {
              res = await get_file({ url: attachments[i].url })
            }else {
              res = await convert_attachment(attachments[i].url)
            }
            const blob = base64toBlob(res.data, 'application/pdf')
            filesToMergeArr.push(blob)
          }
        }
        // console.log(filesToMergeArr)
        const mergedPdf = await PDFDocument.create()
        for(let i = 0; i < filesToMergeArr.length; i++) {
          const bytes = await readFileAsync(filesToMergeArr[i])
          const pdf = await PDFDocument.load(bytes)
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
          copiedPages.forEach((page) => mergedPdf.addPage(page))
          numOfPages = copiedPages.length
        }
        await mergedPdf.save()
        base64Merged = await mergedPdf.saveAsBase64()
        setMergedFilesBase64(base64Merged)
        const pdf = await PDFDocument.load(base64Merged)
        const pages = await pdf.copyPages(pdf, pdf.getPageIndices())
        numOfPages = pages.length
      } else {
        const pdf = await PDFDocument.load(base64Merged)
        const pages = await pdf.copyPages(pdf, pdf.getPageIndices())
        numOfPages = pages.length
      }
      
      if(openModal) {
        setShowPlaceSignatureInterface(true)
      }
      setShowResponseLoader(false)
      return { pages: numOfPages }
    } catch (err) {
      console.log(err)
      setShowResponseLoader(false)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    }
  }, [setNotification, agency, doc, template, user])

  return {
    fetchCredits,
    validateForm,
    submitForm,
    loadPdf,
  }
}

export default useDocumentSignActions