import { convertStringToValidId } from "../utils/convertStringToValidId"

// Workaround for make page number right aligned:
const includesPageWord = (sentence) => {
  return sentence.toLowerCase().includes('page') && sentence.toLowerCase().includes('sur')
}

const handleKeyDown = (e) => {
  // prevent newlines
  if (e.keyCode === 13) {
    e.preventDefault()
  }
  // prevent tab
  if (e.keyCode === 9) {
    e.preventDefault()
  }
}



const VariableText = ({ type, item, index, isContentEditable, onInput = () => { }, onBlur = () => { } }) => {
  const editable = isContentEditable && item.trim() !== ''
  return type.includes('heading')
    ? <span contentEditable={editable} onKeyDown={handleKeyDown} onInput={onInput} onBlur={onBlur} suppressContentEditableWarning={true} className='doc-variable-el' data-id={`section-${convertStringToValidId(item)}`}>{item}</span>
    : item ? (<span><span contentEditable={editable} onKeyDown={handleKeyDown} onInput={onInput} onBlur={onBlur} suppressContentEditableWarning={true} className={`${includesPageWord(item) && 'page-number'}`}>{item}</span><span className="raw-text-space"></span></span>) : null
}

// TODO - implement content edition with memoized component

export default VariableText

// export default memo(VariableText, (prevProps, nextProps) => {
//   if (prevProps.item === nextProps.item && prevProps.isContentEditable === nextProps.isContentEditable) return true
//   return false
// })