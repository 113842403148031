import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({ label, name, id, value, onChange, placeholder, error, dashboardModal, editMode, className, disabled }) => {
  let cssClasses = 'textarea';

  if(dashboardModal) {
    cssClasses += ' textarea--dashboard-modal';
  }
  if(editMode) {
    cssClasses += ' textarea--edit-mode';
  }
  if(className) {
    cssClasses += ` ${className}`;
  }

  return(
    <div className={cssClasses}>
      {label && <label htmlFor={id ? id : name} className="textarea__label">{label}</label>}
      <textarea 
        name={name} 
        id={id ? id : name} 
        className="textarea__field"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      ></textarea>
      {error && <div className="textarea__error">{error}</div>}
    </div>
  );
}

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  dashboardModal: PropTypes.bool
}

export default Textarea;