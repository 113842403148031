import React, { Fragment, memo } from 'react'
import { ClickAwayListener } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import CustomTooltip from '../../../UI/CustomTooltip'
import IconButton from '../../../UI/IconButton'
import Input from '../../../UI/Input'
import SignerFields from './SignerFields'
import Loader from '../../../UI/Loader'

const Contacts = memo(({ title, noDataText, type, setShowDropdown = () => {}, showDropdown, search, searchChangeHandler, filteredContacts, addContactsToSigners, signers, verifiedContactsDropdownOptions, verifiedContactsChangeHandler, changeValueHandler, editSignaturePositionHandler, deletePositionHandler, placeSignatureHandler, deleteUser, addNewUser, useCustomPlacementTool = false, loadingVerifiedCustomers, verifiedCustomersSearched, onSetVerifiedCustomersSearched }) => {
  // console.log('render contacts***')

  // On click away
  const handleClickAway = () => {
    setShowDropdown(false)
    onSetVerifiedCustomersSearched && onSetVerifiedCustomersSearched(false)
  }

  return (
    <Fragment>
      <div className="form__subtitle">
        {title}
        {type === 'manual' 
          ?
            <CustomTooltip content="Ajouter un destinataire">
              <div>
                <IconButton 
                  onButtonClick={addNewUser}
                  icon={<PersonAddIcon />}
                  lightGray
                />
              </div>
            </CustomTooltip>
          :
            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <CustomTooltip content="Ajouter un destinataire">
                  <div>
                    <IconButton 
                      onButtonClick={() => setShowDropdown(!showDropdown)}
                      icon={<PersonAddIcon />}
                      lightGray
                      type="button"
                    />
                  </div>
                </CustomTooltip>
                {showDropdown && 
                  <div className="dropdown-el default-dropdown users-dropdown">
                    <Input value={search} onChange={(e) => searchChangeHandler(e)} />
                    {type === 'customers' && loadingVerifiedCustomers && (
                      <Loader primary small normal />
                    )}
                    {type !== 'customers' && filteredContacts.length === 0 && <p className="no-data">{noDataText}</p>}
                    {type === 'customers' && filteredContacts.length === 0 && !loadingVerifiedCustomers && <p className="no-data">{verifiedCustomersSearched ? noDataText : 'Veuillez effectuer une recherche'}</p>}
                    {type === 'customers' && filteredContacts.length > 199 && !loadingVerifiedCustomers && <p className="no-data">{`Veuillez affiner votre recherche`}</p>}
                    {filteredContacts.length > 0 && filteredContacts.map((a, i) => (
                      <p key={i} onClick={() => addContactsToSigners(a)}>{a.name?.value} {a.lastname?.value}</p>
                    ))}
                  </div>
                }
              </div>
            </ClickAwayListener>
        }
      </div>
      {signers.map((signer, index) => <SignerFields 
        key={index} 
        type={type} 
        signer={signer} 
        index={index} 
        verifiedContactsDropdownOptions={verifiedContactsDropdownOptions} 
        verifiedContactsChangeHandler={verifiedContactsChangeHandler} 
        changeValueHandler={changeValueHandler} 
        editSignaturePositionHandler={editSignaturePositionHandler} 
        deletePositionHandler={deletePositionHandler} 
        placeSignatureHandler={placeSignatureHandler} 
        deleteUser={deleteUser} 
        useCustomPlacementTool={useCustomPlacementTool} 
      />)}
    </Fragment>
  )
})

export default Contacts