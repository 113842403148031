import { GET_FOLDERS, SET_FOLDERS_LOADING, SET_FOLDERS, GET_STANDARD_TEMPLATES_FOLDERS, SET_STANDARD_TEMPLATES_FOLDERS, SET_STANDARD_TEMPLATES_FOLDERS_LOADING, SET_FOLDERS_ORDER } from '../types'

const reducer = (state, action) => {
  switch(action.type) {
    case GET_FOLDERS:
      return {
        ...state,
        folders: action.payload,
        foldersFetched: true,
        loading: false
      }
    case SET_FOLDERS:
      return {
        ...state,
        folders: action.payload
      }
    case SET_FOLDERS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case GET_STANDARD_TEMPLATES_FOLDERS:
      return {
        ...state,
        standardTemplatesFolders: action.payload,
        standardTemplatesFoldersFetched: true,
        standardTemplatesFoldersLoading: false
      }
    case SET_STANDARD_TEMPLATES_FOLDERS:
      return {
        ...state,
        standardTemplatesFolders: action.payload
      }
    case SET_STANDARD_TEMPLATES_FOLDERS_LOADING:
      return {
        ...state,
        standardTemplatesFoldersLoading: action.payload
      }
    case SET_FOLDERS_ORDER:
      return {
        ...state,
        foldersOrderMap: action.payload
      }
    default:
      return state;
  }
}

export default reducer
