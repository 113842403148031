import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Loader from '../../UI/Loader'
import SupportPageLayout from '../../layouts/SupportPageLayout'
import SearchForm from '../../sections/support/Search'
import MostPopularArticles from '../../sections/support/MostPopularArticles'
import { HelpContext, UserContext } from '../../../context'
import { MainLayout } from '../../../layouts'

const HelpPage = ({v2}) => {
  const { articlesFetched, articlesArr, categoriesFetched, categoriesArr, setSelectedCategory } = useContext(HelpContext)
  const { user } = useContext(UserContext)
  const [showCategories, setShowCategories] = useState(false)
  const [categoriesWithNumOfArticles, setCategoriesWithNumOfArticles] = useState([])
  const history = useHistory()

  // Show categories if articles and categories are fetched and add numberOfArticles property to each category
  useEffect(() => {
    if(articlesFetched && categoriesFetched && !showCategories) {
      let articlesByCategory = {}
      articlesArr.forEach((article) => {
        if(articlesByCategory[article.category]) {
          articlesByCategory[article.category] += 1
        }else {
          articlesByCategory[article.category] = 1
        }
      })
      let newCategoriesArr = categoriesArr.map((category) => {
        category.numberOfArticles = articlesByCategory[category.id] || 0
        return category
      })
      setCategoriesWithNumOfArticles(newCategoriesArr)
      setShowCategories(true)
    }
  }, [articlesFetched, categoriesFetched, articlesArr, categoriesArr, showCategories, user])

  // Category click handler
  const categoryClickHandler = (id) => {
    history.push(`/help-category/${id}`)
    setSelectedCategory(id)
  }

  return (
    <MainLayout className="page-help" pageTitle="Nouveautés">
      <SupportPageLayout v2={v2}>
        <div className="help-search">
          <SearchForm />
        </div>
        <MostPopularArticles articles={articlesArr} />
        {!articlesFetched && !categoriesFetched && <div className="loader-wrapper loader-wrapper--2"><Loader normal small primary /></div>}
        <div className="help-categories">
          {showCategories && categoriesWithNumOfArticles.map((category, idx) => {
            if(category.id !== 'whats_new') {
              return (
                <div className="help-categories__box" key={idx} onClick={() => categoryClickHandler(category.id)}>
                  <h3>{category.title.en}</h3>
                  <p>{category.numberOfArticles} {category.numberOfArticles === 1 ? 'article' : 'articles'}</p>
                </div>
              )
            }
            return null
          })}
        </div>
      </SupportPageLayout>
    </MainLayout>
  )
}

export default HelpPage