import ReactDOM from 'react-dom'

const ResponseLoader = ({ text, className }) => {
  const content = (
    <div className={`response-loader-v2 ${className}`}>
      <div className="response-loader-v2__content">
        {text && <p className="response-loader-v2__text">{text}</p>}
        <div className="loader-v2"></div>
      </div>
    </div>
  )

  return ReactDOM.createPortal(content, document.getElementById('page-loader'))
}

export default ResponseLoader