import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import RestoreIcon from '@material-ui/icons/Restore';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import DownloadDataIcon from '@material-ui/icons/ListAlt';
import { ClickAwayListener } from '@material-ui/core'

import IconButton from '../../UI/IconButton';

const MoreActions = ({ synthesisClickHandler, historyClickHandler, duplicateDocumentHandler, onShowDeleteAlert, downloadDataClickHandler }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return(
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div className="document-detail__head_more-actions">
        <IconButton icon={<MoreHorizIcon />} onButtonClick={() => setShowDropdown(!showDropdown)} fixedSmall light />
        {showDropdown && <div className="dropdown-el dropdown-actions">
          <ul>
            <li onClick={() => { setShowDropdown(false); synthesisClickHandler(); }}><i className="custom-icon-article-outlined"></i> Synthèse</li>
            <li onClick={() => { setShowDropdown(false); historyClickHandler(); }}><RestoreIcon /> Historique</li>
            <li onClick={(e) => { setShowDropdown(false); duplicateDocumentHandler(e); }}><FileCopyIcon /> Dupliquer</li>
            <li onClick={() => { setShowDropdown(false); downloadDataClickHandler(); }}><DownloadDataIcon /> Télécharger les données</li>
            <li onClick={() => { setShowDropdown(false); onShowDeleteAlert(); }}><DeleteIcon /> Supprimer</li>
          </ul>
        </div>}
      </div>
    </ClickAwayListener>
  );
}

export default MoreActions;