import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { RegisteredMailContext } from '../context'
import RegistredMailLayout from '../layouts/RegisteredMailLayout'
import moment from 'moment'
import { Person } from '@material-ui/icons'
import { AR24_DATE_FORMAT } from '../constants'

const RegisteredMailSender = () => {
  const [filteredRegisteredMail, setFilteredRegisteredMail] = useState([])
  const [refreshingStatus, setRefreshingStatus] = useState(false)
  const [allRecipients, setAllRecipients] = useState({});
  const {
    ar24Mail,
    ar24Sender,
    ar24SenderFetched,
    showSenderModal,
    fetchAr24Sender,
    ar24SenderEvents,
    ar24MailFilter
  } = useContext(RegisteredMailContext);

  useEffect(() => {
    let filtered = [...ar24SenderEvents]
    if (ar24MailFilter.agenceSender) {
      if (ar24MailFilter.agenceSender !== 'all') {
        filtered = filtered.filter(mail => mail.created_by.agency_id === ar24MailFilter.agenceSender)
      }
    }
    if (ar24MailFilter.dateAfter) {
      const afterTimestamp = ar24MailFilter.dateAfter.getTime()
      filtered = filtered.filter(mail => moment(mail.meta.created, AR24_DATE_FORMAT).valueOf() >= afterTimestamp)
    }
    if (ar24MailFilter.dateBefore) {
      const beforeTimestamp = ar24MailFilter.dateBefore.getTime()
      filtered = filtered.filter(mail => moment(mail.meta.created, AR24_DATE_FORMAT).valueOf() <= beforeTimestamp)
    }
    if (ar24MailFilter.search) {
      const search = ar24MailFilter.search.toLowerCase()
      filtered = filtered.filter(mail => {
        return mail.to_firstname?.toLowerCase().includes(search) ||
          mail.to_lastname?.toLowerCase().includes(search) ||
          mail.to_email?.toLowerCase().includes(search) ||
          mail.from_email?.toLowerCase().includes(search) ||
          mail.from_name?.toLowerCase().includes(search) ||
          mail.sent_by?.admin_name?.toLowerCase().includes(search)
      })
    }
    if (ar24MailFilter.date_after) {
      const afterTimestamp = ar24MailFilter.date_after.getTime()
      filtered = filtered.filter(mail => moment(mail.meta.created, AR24_DATE_FORMAT).valueOf() >= afterTimestamp)
    }
    if (ar24MailFilter.date_before) {
      const beforeTimestamp = ar24MailFilter.date_before.getTime()
      filtered = filtered.filter(mail => moment(mail.meta.created, AR24_DATE_FORMAT).valueOf() <= beforeTimestamp)
    }

    setFilteredRegisteredMail(filtered)
  }, [ar24Mail, ar24MailFilter])
  // useEffect(() => {
  //   const evnts = []

  //   setEvents(evnts)
  // }, [ar24Sender])

  useEffect(() => {
    if (!ar24SenderEvents) {
      return
    }
    let recipients = {}
    for (let mail of ar24SenderEvents.filter(mail => mail?.created_by?.agency_id)) {
      let recipientLabel = `${mail.created_by.agency_name}`
      recipients[mail.created_by.admin_name] = recipientLabel
    }
    setAllRecipients({ ...recipients })
  }, [ar24SenderEvents])

  const onModifySender = () => {
    showSenderModal('modify')
  }

  const onCreateSender = () => {
    showSenderModal('add')
  }

  const labelForEvent = (type) => {
    if (type === 'user-create') {
      return 'Création de compte'
    } else if (type === 'user-associate') {
      return 'Association de compte'
    } else if (type === 'user-update') {
      return 'Mise à jour des informations'
    } else if (type === 'identity-verification-request') {
      return 'Commande identifiant LRE'
    } else {
      return type
    }
  }


  const handleStatusRefreshClick = async () => {
    setRefreshingStatus(true)
    await fetchAr24Sender()
    setRefreshingStatus(false)
  }

  return (
    <RegistredMailLayout
      onCreateSender={onCreateSender}
      sidebarSection="sender"
      onModifySender={onModifySender}
      onRefresh={handleStatusRefreshClick}
      refreshingStatus={refreshingStatus}
      handleStatusRefreshClick={handleStatusRefreshClick}
      recipients={allRecipients}
    >
      <div className="registered-mail-sent">
        <div className="registered-mail__box">
          {!ar24SenderFetched ? (
            <div className="loader-wrapper">
              <div className="loader-v2"></div>
            </div>
          ) : !ar24Sender ? (
            <div className='mt-20'>
              <p className='text-left'>Aucun compte expéditeur n'a été ajouté.</p>
              <button
                className='btn item-btn mt-20'
                onClick={onCreateSender}
              >
                Ajouter un compte AR24
              </button>
            </div>
          ) : (
            <>
              {/* EVENTS TABLE */}
              <div className="registered-mail-table sender">
                <div className="registered-mail-table__head">
                  <div className="registered-mail-table-row">
                    <div className="registered-mail-table-column">
                      <p>Type</p>
                    </div>
                    <div className="registered-mail-table-column">
                      <p>Date</p>
                    </div>
                    <div className="registered-mail-table-column">
                      <p>Prénom expéditeur</p>
                    </div>
                    <div className="registered-mail-table-column">
                      <p>Nom expéditeur</p>
                    </div>
                    <div className="registered-mail-table-column">
                      <p>Email expéditeur</p>
                    </div>
                    <div className="registered-mail-table-column">
                      <p>Fait par</p>
                    </div>
                    <div className="registered-mail-table-column">
                      <p>Montant</p>
                    </div>
                  </div>
                </div>
                <div className="registered-mail-table__body">
                  {filteredRegisteredMail.map((event, index) => (
                    <div className={`registered-mail-table-row`} key={index}>
                      <div className="registered-mail-table-column">
                        <p>{labelForEvent(event.type)}</p>
                      </div>
                      <div className="registered-mail-table-column">
                        <div className="line-clamp-2">
                          <p>{moment(event.meta.created).format('DD/MM/YYYY')}</p>
                          <p className="time mt-1">{moment(event.meta.created).format('HH:mm')}</p>
                        </div>
                      </div>
                      <div className="registered-mail-table-column">
                        <p>{ar24Sender?.firstname}</p>
                      </div>
                      <div className="registered-mail-table-column">
                        <p>{ar24Sender?.lastname || ''}</p>
                      </div>
                      <div className="registered-mail-table-column">
                        <p>{ar24Sender?.email}</p>
                      </div>
                      <div className="registered-mail-table-column made-by-column">
                        <div className="line-clamp-2">
                          <p><Person fontSize='small' /><span>{event.created_by?.admin_name}</span></p>
                          <p className='agency-name'>({event.created_by?.agency_name})</p>
                        </div>
                      </div>
                      <div className="registered-mail-table-column">
                        <p> {(event.price || 0).toFixed(2)}€ HT</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>

            // SENDER TABLE
            // <table>
            //   <thead>
            //     <tr>
            //       <th>Prénom expéditeur</th>
            //       <th>Nom expéditeur</th>
            //       <th>Email expéditeur</th>
            //       <th>Statut du compte</th>
            //       <th>Date de création / association</th>
            //       <th>Fait par</th>
            //       <th>Type</th>
            //       <th>Montant</th>
            //       <th>Actions</th>
            //     </tr>
            //   </thead>
            //   <tbody>

            //     {ar24Sender && (
            //       <tr>
            //         <td>{ar24Sender.firstname}</td>
            //         <td>{ar24Sender.lastname}</td>
            //         <td>{ar24Sender.email}</td>
            //         <td>
            //           <div className='flex flex-row align-center justify-center'>
            //             <div className={`status-value ${ar24Sender.status}`}>{statusLabelForAr24Sender(ar24Sender) || ar24Sender?.status}</div>
            //             <button className={`icon-btn icon-btn--transparent refresh-status ${refreshingStatus ? 'refreshing' : ''}`} onClick={handleStatusRefreshClick}><Redo /></button>
            //           </div>
            //         </td>
            //         <td>{moment(ar24Sender.meta.created).format('DD/MM/YYYY')}</td>
            //         <td>{ar24Sender.created_by?.admin_name}<br/>({ar24Sender.created_by?.agency_name})</td>
            //         <td>{labelForCreationType(ar24Sender.creation_type)}</td>
            //         <td>{(ar24Sender.amount_spent || 0).toFixed(2)}€ HT</td>
            //         <td>
            //           <button
            //             className='btn'
            //             type='button'
            //             onClick={() => onModifySender(ar24Sender)}>
            //             Modifier
            //           </button>
            //         </td>
            //       </tr>
            //     )
            //     }
            //   </tbody>
            // </table>
          )}
        </div>
      </div>
    </RegistredMailLayout>
  )
}

export default RegisteredMailSender