const EditNote = () => {
  return (
    <span className="svg-icon">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.20312 6.375H10.4531V7.875H2.20312V6.375ZM2.20312 4.875H10.4531V3.375H2.20312V4.875ZM2.20312 10.875H7.45312V9.375H2.20312V10.875ZM13.4606 8.5275L13.9931 7.995C14.2856 7.7025 14.7581 7.7025 15.0506 7.995L15.5831 8.5275C15.8756 8.82 15.8756 9.2925 15.5831 9.585L15.0506 10.1175L13.4606 8.5275ZM12.9281 9.06L8.95312 13.035V14.625H10.5431L14.5181 10.65L12.9281 9.06Z"/>
      </svg>
    </span>
  )
}

export default EditNote