import { useContext } from 'react';
import { NavLink } from 'react-router-dom'

import { UserSolid, BgImage } from '../../assets/icons'
import { UserContext } from '../../context';
import { FEATURE, isFeatureEnabled } from '../../utils';
import { Business, PersonOutline } from '@material-ui/icons';
import { ViewComfyOutlined } from '@mui/icons-material';

const SettingsContentSidebar = () => {

  const { user, partner } = useContext(UserContext);

  return (
    <div className="settings-content__sidebar u-custom-scrollbar">
      <div className="settings-content__sidebar_head">
        <h1><span>Paramètres</span></h1>
      </div>
      <div className="settings-content__nav">
        <ul>
          <li><NavLink to="/settings/profile"><PersonOutline fontSize='medium' /> Profil</NavLink></li>
          <li><NavLink to="/settings/agency"><Business fontSize='medium' /> Agences</NavLink></li>
          {isFeatureEnabled(FEATURE.COVER_PAGE_SETTINGS, [], partner, user) && <li><NavLink to="/settings/cover-page"><ViewComfyOutlined fontSize='medium' /> Mise en page</NavLink></li>}
        </ul>
      </div>
    </div>
  )
}

export default SettingsContentSidebar