import { useContext, useState, useEffect } from 'react'

import { Select, Option, DateInput } from '../ui_new'
import { STATUS_LABELS_SHORT } from '../../constants'
import { DocumentsContext, UserContext } from '../../context'
import { useFoldersActions, useSingleDocumentActions } from '../../hooks'
import { FEATURE, isFeatureAuthorized, isFeatureEnabled, manufacturerProperty } from '../../utils'
import { MoreVert } from '@material-ui/icons'
import { ChevronLeft, Sort2 } from '../../assets/icons'
import { CalendarToday, Domain, ChevronRight, RadioButtonChecked } from '@material-ui/icons'
import moment from 'moment'
import { CalendarMonthOutlined } from '@mui/icons-material'
import { ClickAwayListener } from '@mui/material'

const StandardTemplatesFilters = ({ onSetSelectedFilters, selectedFilters, onChangeCurrent, onSort, activeSort, activeOrder, view }) => {

  const { userClaims } = useContext(UserContext)

  const [dateAfter, setDateAfter] = useState(selectedFilters.date_after)
  const [dateBefore, setDateBefore] = useState(selectedFilters.date_before)
  const [showFilters, setShowFilters] = useState(false)
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(-1);

  // On date change - range
  const handleDateRangeChange = (date) => {
    const rangeStartDate = date[0]
    const rangeEndDate = date[1]
    if (!rangeStartDate || !rangeEndDate) {
      return;
    }
    setDateAfter(rangeStartDate)
    setDateBefore(rangeEndDate)
    onSetSelectedFilters(prev => ({
      ...prev,
      date_after: rangeStartDate,
      date_before: rangeEndDate
    }))
  }


  // On date after clear
  const handleDateAfterClear = () => {
    setDateAfter('')
    setDateBefore('')
    onSetSelectedFilters(prev => ({ ...prev, date_after: '', date_before: '' }))
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const displayDate = () => {
    if (dateAfter && dateBefore) {
      return moment(dateAfter).format('MM/YYYY') + ' - ' + moment(dateBefore).format('MM/YYYY');
    }
    return '-'
  }

  let sortOptions = {
    'name': { label: 'Nom', asc: 'A > Z', desc: 'Z > A' }
  };

  if (view === "templates") {
    if (isFeatureAuthorized({ userClaims, rule: "any_admin" })) {
      sortOptions['hidden'] = { label: 'Statut', asc: 'A > Z', desc: 'Z > A' }
    }
  }

  sortOptions["meta.created"] = { label: 'Date de création', asc: '+ ancienne', desc: ' + récente' }
  sortOptions["meta.updated"] = { label: 'Date de modification', asc: '+ ancienne', desc: '+ récente' }

  return (
    <>
      <div className='filters-section-parent'>
        <button className='filters-section-toggle' onClick={toggleFilters}>
          <MoreVert />
        </button>
        <section className={`filters-section ${showFilters && 'show'}`}>
          {displayFilter !== -1 && <button onClick={() => { setDisplayFilter(-1) }} className='back-btn'>
            <ChevronLeft /> Retour
          </button>}
          {displayFilter === -1 && <ul className='mobile-filters-list'>
            <li onClick={() => { setDisplayFilter(0) }}>
              <span><CalendarMonthOutlined /> {displayDate()} </span>
              <ChevronRight />
            </li>
          </ul>}
          <div className={`filters-section__item ${displayFilter === 0 ? 'show' : ''}`}>
            <DateInput
              selectsRange={true}
              placeholder="Créé après - Créé avant"
              onChange={handleDateRangeChange}
              onChangeRaw={(e) => e.preventDefault()}
              isClearable={dateAfter && dateBefore}
              onClearableClick={handleDateAfterClear}
              // selected={[dateAfter, dateBefore]}
              minDate={dateAfter}
              maxDate={dateBefore}
              expandable={true}
            />
          </div>

        </section>
        <ClickAwayListener onClickAway={() => {
          console.log("sorter click away")
          setShowSortDropdown(false)
        }
        }>
          <div className="relative">
            <div className={`filters-section-sort !h-8 !max-w-full select-v2 select-v2--filled position-relative ${showSortDropdown && 'active'}`} >
              <div className="flex md:px-3 h-8 items-center gap-2" onClick={() => setShowSortDropdown(!showSortDropdown)}>
                <div className="flex gap-2">
                  <Sort2 />
                  <span className='sort-label sm-d-none'>
                    {sortOptions[activeSort.activeItem]?.label} {sortOptions[activeSort.activeItem]?.[activeSort[activeSort.activeItem]]}
                  </span>
                </div>
                <span className='chevron-icon'><ChevronRight fontSize='small' /></span>
              </div>
            </div>
            {showSortDropdown && <div className='sort-dropdown select-v2__body_dropdown align-right'>
              {Object.entries(sortOptions).map((option, index) => (
                <div key={index} className={`dropdown-item`} >
                  <p> {option[1].label} <ChevronRight fontSize={'small'} /> </p>
                  <ul>
                    <li className={`dropdown-item ${activeSort[option[0]] === "asc" ? 'active' : ''}`} onClick={() => {
                      onSort(option[0], 'asc');
                      setShowSortDropdown(false)
                    }}>
                      {option[1].asc}
                    </li>
                    <li className={`dropdown-item ${activeSort[option[0]] === "desc" ? 'active' : ''}`} onClick={() => {
                      onSort(option[0], 'desc');
                      setShowSortDropdown(false)

                    }}>
                      {option[1].desc}
                    </li>
                  </ul>
                </div>
              ))}
            </div>}
          </div>
        </ClickAwayListener>

      </div>
    </>

  )
}

export default StandardTemplatesFilters