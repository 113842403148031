import { useContext, useEffect, useState } from 'react'

import { Checkbox, CustomTooltip } from '../ui_new'
import { UserContext } from '../../context'
import { FEATURE, areSectionConditionsMet, isCoverPageVariable } from '../../utils'
import { SingleDocumentVariablesSection } from './'
import { useVariablesActions } from '../../hooks'
import { Duplicate, Delete } from '../../assets/icons'
import { AddCircleOutline } from '@mui/icons-material'

const SingleDocumentVariables = ({
    documentValues,
    onValuesChange,
    useCustomCover,
    onCustomCoverChange,
    templateData,
    onAgencyModalOpen,
    onNotariesModalOpen,
    documentCheckboxValues,
    onCheckboxValueChange,
    isContentEditable,
    onSectionContentChanged,
    onSetDocumentAttachments,
    templateMetaSections,
    attachments,
    defaultAttachments,
    linesAdded,
    docId
}) => {

    const { duplicatableValuesArray } = useVariablesActions({ values: documentValues })



    const duplicateSection = (repSectionIds, repIndices) => {
        const repeatableSectionIds = [...repSectionIds]
        const repetitionIndices = repIndices ? [...repIndices] : undefined
        // console.log('duplicateSection', { repeatableSectionIds: JSON.stringify(repeatableSectionIds), repetitionIndices: JSON.stringify(repetitionIndices) })
        if (repeatableSectionIds) {
            const rootLevelRepeatableId = repeatableSectionIds.splice(0, 1)[0]
            // console.log('rootLevelRepeatableId', rootLevelRepeatableId)
            const rootLevelValue = duplicatableValuesArray([rootLevelRepeatableId], [])

            let val = rootLevelValue

            while (repeatableSectionIds.length > 0) {
                val = val[repetitionIndices.splice(0, 1)[0]]
                if (!val) {
                    throw new Error('Invalid repetition index')
                }
                if (!val[repeatableSectionIds[0]]) {
                    val[repeatableSectionIds[0]] = [{}]
                }
                val = val[repeatableSectionIds.splice(0, 1)[0]]
            }
            val.push({})

            // console.log("update", rootLevelRepeatableId, rootLevelValue)

            onValuesChange(rootLevelRepeatableId, [...rootLevelValue])
        }
        // let vals = documentValues[repeatableSectionId]
        // if (!vals) {
        //     vals = [{}]
        // }

        // vals.push({})
        // onValuesChange(repeatableSectionId, vals)
    }

    // Check if variables contain only customer
    const variablesContainOnlyCustomer = (variables) => {
        return (
            variables &&
            variables.length === 1 &&
            variables[0].variable === 'co_customers_id'
        )
    }

    const removeRepetition = (repSectionIds, repIndices) => {
        const repeatableSectionIds = [...repSectionIds]
        const repetitionIndices = [...repIndices]
        const rootLevelRepeatableId = repeatableSectionIds[0]
        const rootLevelValue = duplicatableValuesArray([rootLevelRepeatableId], [])
        let val = rootLevelValue
        for (let i = 1; i < repeatableSectionIds.length; i++) {
            val = val[repetitionIndices[i - 1]]
            val = val[repeatableSectionIds[i]]
            if (!val) {
                throw new Error('Invalid repetition index')
            }
        }
        val.splice(repIndices[repIndices.length - 1], 1)

        onValuesChange(rootLevelRepeatableId, [...rootLevelValue])
    }

    const shouldShowDuplicationControls = (metaBlock, repetitionIndices) => {
        // console.log('shouldShowDuplicationControls', { metaBlock, repetitionIndices })
        const shouldShow = areMetaSectionConditionsMet(metaBlock, repetitionIndices)
        // console.log('shouldShowDuplicationControls', { shouldShow })
        return shouldShow
    }

    const areMetaSectionConditionsMet = (metaBlock, repetitionIndices) => {
        const valuesArray = duplicatableValuesArray(metaBlock.repeatableIds, repetitionIndices)
        // console.log('areMetaSectionConditionsMet', { metaBlock, repetitionIndices, valuesArray })
        const sectionsArray = metaBlock.sections
        for (let i = 0; i < sectionsArray.length; i++) {
            const section = sectionsArray[i]
            if (section.isMetaSection) {
                if (areMetaSectionConditionsMet(section, repetitionIndices)) {
                    return true
                } else {
                    continue
                }
            }
            for (let j = 0; j < valuesArray.length; j++) {
                const shouldRender = areSectionConditionsMet({ ...section, repeatableSectionId: metaBlock.repeatableId, repeatableIndex: j, repeatableIds: metaBlock.repeatableIds, repetitionIndices }, documentValues)
                if (shouldRender) {
                    return true
                }
            }
        }
        return false
    }

    const shouldShowDuplicateButton = (metaBlock, repetitionIndices) => {
        return shouldShowDuplicationControls(metaBlock, repetitionIndices)
    }

    const shouldShowDeleteDuplicationButton = (metaBlock, repetitionIndices) => {
        if (!repetitionIndices || repetitionIndices[repetitionIndices.length - 1] === 0) {
            return false
        }
        return shouldShowDuplicationControls(metaBlock, [...(repetitionIndices || [])])
    }

    const CONDITIONAL_SECTIONS_EDITABLE = true

    const isSectionEditable = (section, contentEditable) => {
        if (!CONDITIONAL_SECTIONS_EDITABLE) {
            return !(Boolean(section.condition) || section.conditions?.length > 0) && contentEditable
        }
        return contentEditable
    }


    return (
        <div className="single-document-variables">
            <div className="single-document-variables__body">
                {templateMetaSections?.map((metaBlock, metaBlockIndex) =>
                    <MetaSection
                        key={`meta_block_${metaBlockIndex}`}
                        metaBlock={metaBlock}
                        metaBlockIndex={metaBlockIndex}
                        documentValues={documentValues}
                        onValuesChange={onValuesChange}
                        documentCheckboxValues={documentCheckboxValues}
                        onCheckboxValueChange={onCheckboxValueChange}
                        onAgencyModalOpen={onAgencyModalOpen}
                        onNotariesModalOpen={onNotariesModalOpen}
                        isContentEditable={isContentEditable}
                        onSectionContentChanged={onSectionContentChanged}
                        onSetDocumentAttachments={onSetDocumentAttachments}
                        onRemoveRepetition={removeRepetition}
                        isSectionEditable={isSectionEditable}
                        // shouldShowDuplicationControls={shouldShowDuplicationControls}
                        duplicateSection={duplicateSection}
                        duplicatableValuesArray={duplicatableValuesArray}
                        shouldShowDuplicateButton={shouldShowDuplicateButton}
                        shouldShowDeleteDuplicationButton={shouldShowDeleteDuplicationButton}
                        linesAdded={linesAdded}
                        documentAttachments={attachments}
                        defaultAttachments={defaultAttachments}
                        docId={docId}
                    />
                )}
            </div>
            {templateData?.footer && (
                <div className="single-document-variables__foot">
                    <h4>
                        <span>Pied de page</span>
                    </h4>
                    <SingleDocumentVariablesSection
                        values={documentValues}
                        onValuesChange={onValuesChange}
                        documentCheckboxValues={documentCheckboxValues}
                        onCheckboxValueChange={onCheckboxValueChange}
                        sections={[templateData.footer]}
                        type={templateData.footer.type || 'text'}
                        content={templateData.footer.content}
                        items={templateData.footer.items || []}
                        question={templateData.footer.question}
                        variable={templateData.footer.variable}
                        variables={templateData.footer.variables}
                        data_type={templateData.footer.data_type}
                        options={templateData.footer.options}
                        condition={templateData.footer.condition}
                        conditions={templateData.footer.conditions}
                        tooltip={templateData.footer.tooltip}
                        locked={templateData.footer.locked}
                        display_name={templateData.footer.display_name}
                        index={'footer'}
                        varIndex={''}
                        repeatableIds={[]}
                        onAgencyModalOpen={onAgencyModalOpen}
                        onNotariesModalOpen={onNotariesModalOpen}
                        linesAdded={linesAdded}
                        isContentEditable={false}
                        onSectionContentChanged={onSectionContentChanged}
                        onSetDocumentAttachments={onSetDocumentAttachments}
                        documentAttachments={attachments}
                        defaultAttachments={defaultAttachments}
                        docId={docId}
                    />
                </div>
            )}
        </div>
    )
}

const MetaSection = ({
    metaBlock,
    metaBlockIndex,
    documentValues,
    onValuesChange,
    documentCheckboxValues,
    onCheckboxValueChange,
    onAgencyModalOpen,
    onNotariesModalOpen,
    linesAdded,
    isContentEditable,
    onSectionContentChanged,
    onSetDocumentAttachments,
    onRemoveRepetition,
    isSectionEditable,
    duplicateSection,
    duplicatableValuesArray,
    repetitionIndices,
    shouldShowDuplicateButton,
    shouldShowDeleteDuplicationButton,
    documentAttachments,
    defaultAttachments,
    docId
}) => {
    if (metaBlock.repeatableIds.length === 0) {
        return metaBlock.sections.map((section, sectionIndex) => {
            const sectionKey = section.idx || `section-${section.index || sectionIndex}`
            if (section.isMetaSection) {
                return (
                    <MetaSection
                        key={sectionKey}
                        metaBlock={section}
                        metaBlockIndex={sectionIndex}
                        documentValues={documentValues}
                        onValuesChange={onValuesChange}
                        documentCheckboxValues={documentCheckboxValues}
                        onCheckboxValueChange={onCheckboxValueChange}
                        onAgencyModalOpen={onAgencyModalOpen}
                        onNotariesModalOpen={onNotariesModalOpen}
                        linesAdded={linesAdded}
                        isContentEditable={isContentEditable}
                        onSectionContentChanged={onSectionContentChanged}
                        onSetDocumentAttachments={onSetDocumentAttachments}
                        onRemoveRepetition={onRemoveRepetition}
                        isSectionEditable={isSectionEditable}
                        duplicateSection={duplicateSection}
                        duplicatableValuesArray={duplicatableValuesArray}
                        repetitionIndices={repetitionIndices}
                        shouldShowDuplicateButton={shouldShowDuplicateButton}
                        shouldShowDeleteDuplicationButton={shouldShowDeleteDuplicationButton}
                        documentAttachments={documentAttachments}
                        defaultAttachments={defaultAttachments}
                        docId={docId}
                    />
                )
            }
            return (
                <SingleDocumentVariablesSection
                    key={sectionKey}
                    values={documentValues}
                    onValuesChange={onValuesChange}
                    documentCheckboxValues={documentCheckboxValues}
                    onCheckboxValueChange={onCheckboxValueChange}
                    sections={section.sections}
                    type={section.type}
                    content={section.content}
                    items={section.items}
                    question={section.question}
                    variable={section.variable}
                    variables={section.variables}
                    data_type={section.data_type}
                    options={section.options}
                    condition={section.condition}
                    conditions={section.conditions}
                    tooltip={section.tooltip}
                    index={section.index}
                    varIndex={section.idx}
                    head={section.head}
                    row={section.row}
                    repeatableRowId={section.repeatableRowId}
                    rows={section.rows}
                    locked={section.locked}
                    onAgencyModalOpen={onAgencyModalOpen}
                    onNotariesModalOpen={onNotariesModalOpen}
                    linesAdded={linesAdded}
                    rowsLocked={section.rowsLocked}
                    isContentEditable={isSectionEditable(section, isContentEditable)}
                    onSectionContentChanged={onSectionContentChanged}
                    onSetDocumentAttachments={onSetDocumentAttachments}
                    documentAttachments={documentAttachments}
                    defaultAttachments={defaultAttachments}
                    sectionIdx={section.idx}
                    display_name={section.display_name}
                    docId={docId}
                />
            )
        })
    } else {
        return (
            <div key={`repeatable_block_${metaBlockIndex}`} className='position-relative px-4'>
                {shouldShowDuplicateButton(metaBlock, repetitionIndices) &&
                    <button onClick={() => duplicateSection(metaBlock.repeatableIds, repetitionIndices)} className="duplicate-button button button--outline-primary-light rounded-sm ">
                        <Duplicate  />
                    </button>
                }
                {duplicatableValuesArray(metaBlock.repeatableIds, repetitionIndices).map((item, repetitionIndex) => {
                    return (
                        <div key={`repeatable_block_${metaBlockIndex}_${repetitionIndex}`}
                            className={`position-relative ${shouldShowDuplicateButton(metaBlock, repetitionIndices) ? (repetitionIndex === 0 ? 'duplicate-border' : 'delete-border') : 'no-border'}`}>
                            {metaBlock.sections.map((section, sectionIndex) => {
                                const sectionKey = section.idx || `section-${section.index || sectionIndex}`
                                if (section.isMetaSection) {
                                    return (
                                        <MetaSection
                                            key={sectionKey}
                                            metaBlock={section}
                                            metaBlockIndex={sectionIndex}
                                            documentValues={documentValues}
                                            onValuesChange={onValuesChange}
                                            documentCheckboxValues={documentCheckboxValues}
                                            onCheckboxValueChange={onCheckboxValueChange}
                                            onAgencyModalOpen={onAgencyModalOpen}
                                            onNotariesModalOpen={onNotariesModalOpen}
                                            linesAdded={linesAdded}
                                            isContentEditable={isContentEditable}
                                            onSectionContentChanged={onSectionContentChanged}
                                            onSetDocumentAttachments={onSetDocumentAttachments}
                                            documentAttachments={documentAttachments}
                                            defaultAttachments={defaultAttachments}
                                            onRemoveRepetition={onRemoveRepetition}
                                            isSectionEditable={isSectionEditable}
                                            duplicateSection={duplicateSection}
                                            duplicatableValuesArray={duplicatableValuesArray}
                                            repetitionIndices={[...(repetitionIndices || []), repetitionIndex]}
                                            shouldShowDuplicateButton={shouldShowDuplicateButton}
                                            shouldShowDeleteDuplicationButton={shouldShowDeleteDuplicationButton}
                                            docId={docId}
                                        />
                                    )
                                }
                                return (
                                    <SingleDocumentVariablesSection
                                        key={sectionKey}
                                        values={documentValues}
                                        onValuesChange={onValuesChange}
                                        documentCheckboxValues={documentCheckboxValues}
                                        onCheckboxValueChange={onCheckboxValueChange}
                                        sections={section.sections}
                                        type={section.type}
                                        content={section.content}
                                        items={section.items}
                                        question={section.question}
                                        variable={section.variable}
                                        variables={section.variables}
                                        data_type={section.data_type}
                                        options={section.options}
                                        condition={section.condition}
                                        conditions={section.conditions}
                                        tooltip={section.tooltip}
                                        index={section.index}
                                        varIndex={section.idx}
                                        repeatableIds={metaBlock.repeatableIds}
                                        repetitionIndices={[...(repetitionIndices || []), repetitionIndex]}
                                        head={section.head}
                                        row={section.row}
                                        repeatableRowId={section.repeatableRowId}
                                        rows={section.rows}
                                        locked={section.locked}
                                        onAgencyModalOpen={onAgencyModalOpen}
                                        onNotariesModalOpen={onNotariesModalOpen}
                                        linesAdded={linesAdded}
                                        rowsLocked={section.rowsLocked}
                                        isContentEditable={isSectionEditable(section, isContentEditable)}
                                        onSectionContentChanged={onSectionContentChanged}
                                        sectionIdx={section.idx}
                                        onSetDocumentAttachments={onSetDocumentAttachments}
                                        documentAttachments={documentAttachments}
                                        defaultAttachments={defaultAttachments}
                                        display_name={section.display_name}
                                        docId={docId}
                                    />
                                )
                            })}

                            {shouldShowDeleteDuplicationButton(metaBlock, [...(repetitionIndices || []), repetitionIndex]) && (
                                <span className="u-cursor--pointer" onClick={() => onRemoveRepetition(metaBlock.repeatableIds, [...(repetitionIndices || []), repetitionIndex])}>
                                    <button className="delete-button button button--with-icon rounded-sm button--outline-danger"><Delete /> Supprimer </button>
                                </span>
                            )}
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default SingleDocumentVariables
