import React, { useCallback, useEffect, useRef } from 'react';

const Dropdown = ({ children, show, onChange, className, animate, classToCheck, dontHideCheck }) => {
  const dropdownEl = useRef();
  let dropdownClass = `dropdown-el ${className ? className : ''}`;

  if(animate) {
    dropdownClass += ' animate';
  }

  const handleClickOutside = useCallback((e) => {
    if(dontHideCheck) {
      if(show && (!e.target.classList.contains('dont-hide-dropdown') && !e.target.parentElement.classList.contains('dont-hide-dropdown')) && e.target.closest(classToCheck ? classToCheck : '.dropdown-el') !== dropdownEl.current) {
        onChange(false);
      }
    }else {
      if(show && e.target.closest(classToCheck ? classToCheck : '.dropdown-el') !== dropdownEl.current) {
        onChange(false);
      }
    }
  }, [show, onChange, dontHideCheck, classToCheck]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [handleClickOutside]);

  return(
    <div className={show ? dropdownClass + ' active' : dropdownClass} ref={dropdownEl}>
      {children}
    </div>
  );
}

export default Dropdown;