import React, { useState, useContext } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import HttpIcon from '@material-ui/icons/Http';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import FileUpload from '../../UI/FileUpload';
import ResponseLoader from '../../UI/ResponseLoader';
import Button from '../../UI/Button';
import CustomTooltip from '../../UI/CustomTooltip';
import { get_file } from '../../../services/firestore';
import { base64toBlob, blobToFile, availableOn } from '../../../utils';
import { validateURL } from '../../../helpers/validate';
import { NotificationContext } from '../../../context/notifications/notificationState';
import { DocumentsContext } from '../../../context/documents/documentsState';

const DocumentDetailSPAppendices = ({ onAddAttachment, attachmentsNames, docId }) => {
  const [errors, setErrors] = useState([]);
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const { copiedAttachments, attachmentsFrom, setCopiedAttachments, setAttachmentsFrom } = useContext(DocumentsContext);

  const onFileSubmitted = (file, fileType = '') => {
    if (file) {
      var reader = new FileReader();

      reader.onload = (e) => {
        let components = file.name.split('.')
        const format = components[components.length - 1]
        components.splice(components.length - 1, 1)
        const name = components.join('.')
        const type = file.type ? file.type : fileType;
        // addFile({ data: e.target.result, name: name, format: format })
       
        // fileInputRef.current.value = ""
        // console.log('add file', { data: e.target.result, name: name, format: format })
        onAddAttachment({ data: e.target.result, name: name, format: format, type: type, size: file.size })
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
      }
      reader.readAsDataURL(file);
    } else {
      // no files
    }
  }

  const changeHandler = (e) => {
    if(errors.length > 0) {
      setErrors([]);
    }
    const files = e.target.files || e.dataTransfer.files;
    Array.from(files).forEach(file => {
      if(attachmentsNames.includes(file.name)) {
        setErrors(prev => [...prev, `${file.name} existe déjà`]);
      }else if(file.size > 10485760){
        setNotification({ msg: 'Le poids du fichier est supérieur au poid autorisé (10MB)', type: 'danger' });
      }else {
        onFileSubmitted(file);
      }
    });
  }

  const uploadFileFromUrl = async (e) => {
    e.preventDefault();
    if(url.trim() === '') {
      return setNotification({ msg: 'URL cannot be empty', type: 'danger' });
    }
    if(!validateURL(url)) {
      return setNotification({ msg: 'URL is invalid', type: 'danger' });
    }
    setLoading(true);
    try {
      const res = await get_file({ url, getType: true });
      if(!res.success) {
        setLoading(false);
        setUrl('');
        if(res.type === 'file-too-big') {
          return setNotification({ msg: 'Le poids du fichier est supérieur au poid autorisé (10MB)', type: 'danger' });
        }
        return setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' });
      }
      const blob = base64toBlob(res.data);
      let fileName = `file-${Date.now()}`;
      let fileType = '';
      let fileExt = '';
      if(res.file_type) {
        fileExt = res.file_type.ext;
        fileName = `${fileName}.${fileExt}`;
        fileType = res.file_type.mime;
      }else {
        setLoading(false);
        return setNotification({ msg: 'File does not have extension', type: 'danger' });
      }
      const allowedTypes = ['pdf', 'docx', 'png', 'jpeg', 'jpg', 'gif'];
      if(!allowedTypes.includes(fileExt)) {
        setLoading(false);
        return setNotification({ msg: 'Invalid file type', type: 'danger' });
      }
      const file = blobToFile(blob, fileName);
      // console.log(file)
      onFileSubmitted(file, fileType);
      setLoading(false);
      setUrl('');
    } catch (err) {
      console.log(err);
      setLoading(false);
      setUrl('');
      return setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' });
    }
  }

  // Paste attachments
  const pasteAttachmentsHandler = () => {
    setLoading(true);
    const promises = [];
    const attachments = [...copiedAttachments];
    copiedAttachments.forEach(att => {
      if(att.url) {
        promises.push(get_file({ url: att.url }));
      }else {
        promises.push({ data: att.data.split(',')[1] });
      }
    });
    const errors = [];
    Promise.all(promises).then((data) => {
      data.forEach((item, i) => {
        const blob = base64toBlob(item.data);
        const fileName = `${attachments[i].name}.${attachments[i].format}`;
        if(attachmentsNames.includes(fileName)) {
          errors.push(fileName);
        }else {
          const file = blobToFile(blob, fileName);
          onFileSubmitted(file, attachments[i].type);
        }
      });
      setCopiedAttachments([]);
      setAttachmentsFrom(null);
      setLoading(false);
      if(errors.length > 0) {
        setNotification({ msg: `Files with this names already exist(${errors.join(',')})`, type: 'info' });
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' });
    });
  }

  return(
    <div className="document-detail-sp-appendices">
      <h4>Annexes <CustomTooltip content="Le poids des pièces jointes ne peut excéder 10MB" spaceSm><div className="icon"><InfoIcon /></div></CustomTooltip></h4>
      <form>
        <FileUpload 
          name="file"
          // fileName={Object.keys(formData.file.value).length > 0 && formData.file.value[0].name}
          onChange={changeHandler}
          btnText="Sélectionnez une pièce jointe sur votre ordinateur"
          icon={<SearchIcon />}
          dragAndDrop
          onDrop={changeHandler}
          multiple
          error={errors.join(', ')}
        />
        {/* {Object.keys(formData.file.value).length > 0 && formData.file.value[0].name && 
          <div className="button-wrapper">
            <Button type="submit" text="Télécharger" fullWidth medium primary />
          </div>
        } */}
      </form>
      {copiedAttachments.length > 0 && attachmentsFrom && attachmentsFrom !== docId && <div className="paste-attachments">
        <Button 
          icon={<i className="custom-icon-content-paste"></i>} 
          text={`Coller les annexes du presse-papier(${copiedAttachments.length})`} 
          onButtonClick={pasteAttachmentsHandler} 
          outlinePrimary 
          medium
          fullWidth
        />
      </div>}
      {<div className="add-attachment-from-url">
        {/* <div className="add-attachment-from-url__head">
          <HttpIcon />
          <p>Ajouter une annexe à partir d'une url</p>
        </div> */}
        <form onSubmit={uploadFileFromUrl} className={active ? 'active' : ''}>
          <div className="icon"><HttpIcon /></div>
          <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} onFocus={() => setActive(true)} onBlur={() => url === '' &&setActive(false)} placeholder={active ? "https://" : "Ajouter une annexe à partir d'une url"} />
          <Button type="submit" text="Ajouter" primary />
        </form>
      </div>}
      
      {loading && <ResponseLoader />}
    </div>
  );
} 

export default DocumentDetailSPAppendices;