import React, { useEffect } from 'react';

import Dropdown from './Dropdown';

const CustomPopover = ({ show, setShow, children }) => {
  useEffect(() => {
    setShow(show);
  }, [show, setShow]);

  return(
    <Dropdown show={show} onChange={setShow} className="custom-popover" >
      {children}
    </Dropdown>
  );
}

export default CustomPopover;