import { Modal, Input } from '../ui_new'

const ImportFromURL = ({ onClose, onSubmit, value, onChange }) => {

  // On modal close
  const handleClose = () => {
    onClose()
  }

  // On submit 
  const handleSubmit = async (e) => {
    e.preventDefault()
    onSubmit()
    // onClose
  }

  return (
    <Modal onClose={handleClose} className="modal-v2--medium-3">
      <div className="folder-modal-v2">
        <form onSubmit={handleSubmit} className="folder-modal-v2__form">
          <div className='folder-modal-v2__heading'>
            <h2>Importer un document</h2>
            <div className="folder-modal-v2__form_actions gap-2">
              <button className="btn btn--medium btn--transparent" onClick={handleClose}>Annuler</button>
              <button className="button height-40" onClick={handleSubmit}>
                Soumettre
              </button>
            </div>
          </div>
          <div className='folder-modal-v2__content'>
            <label htmlFor="name" className='label'>Saisissez l’URL </label>
            <Input
              name="name"
              value={value}
              onChange={onChange}
              placeholder="https://"
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ImportFromURL