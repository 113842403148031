
import { useEffect } from 'react';
import { Range } from 'react-range';

const RangeInput = ({value = 0, max = 0}) => {

    // const convertToMb = () => {
    //     return [Math.round(value / (1024*1024))]
    // }

    useEffect(() => {
        console.log({ value,  exceeded: value > max })
    }, [value, max])

    return (
        <Range
            step={10}
            min={0}
            max={max}
            values={[value > max ? max : value]}
            onChange={() => null}
            renderTrack={({ props, children }) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        height: '20px',
                        width: '200px',
                        backgroundColor: '#F0EBF9',
                        borderRadius: '50px',
                        overflow: 'hidden',
                        cursor: 'default'
                    }}
                >
                    {children}
                </div>
            )}
            renderThumb={({ props }) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        height: '20px',
                        width: '100%',
                        backgroundColor: value > max ? '#ca1818' : '#482982',
                        left: '-100px',
                        cursor: 'default'
                    }}
                />
            )}
        />
    )
}

export default RangeInput