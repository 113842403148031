const MailModalMenu = ({currentItem}) => {
    return (
        <ul className='registered-mail-send-modal-menu'>
            <li className={currentItem === 'sender' ? 'current' : ''}>
                <span>1</span>
                Expéditeur</li>
            <li className={currentItem === 'recipients' ? 'current' : ''}>
                <span>2</span>
                Destinataire(s)</li>
            <li className={currentItem === 'documents' ? 'current' : ''}>
                <span>3</span>
                Document(s)</li>
            <li className={currentItem === 'message' ? 'current' : ''}>
                <span>4</span>
                Message</li>
            <li className={currentItem === 'summary' ? 'current' : ''}>
                <span>5</span>
                Récapitulatif</li>
        </ul>
    )
}

export default MailModalMenu