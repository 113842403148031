import React, { useState } from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import IconButton from './IconButton';
import Dropdown from './Dropdown';
import Checkbox from './Checkbox';

const AttachmentRow = ({ att, attIndex, viewAttachment, deleteAttachment, editAttachmentClickHandler, onCheckboxChange, selectedAttachments }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return(
    <div className={[...selectedAttachments].filter(obj => obj.name === att.name && obj.format === att.format).length > 0 ? "dashboard-row attachment-row active" : "dashboard-row attachment-row"} key={`attachment_${attIndex}`}>
      <div className="dashboard-column col-1">
        <Checkbox 
          onChange={(e) => onCheckboxChange(att, e)} 
          checked={[...selectedAttachments].filter(obj => obj.name === att.name && obj.format === att.format).length > 0} 
        />
        <span className="icon"><InsertDriveFileIcon /></span>
        <div className="dashboard-column__main">
          <p className="title">{ `${att.name}.${att.format}` }</p>
        </div>
      </div>
      <div className="dashboard-column dashboard-column--actions">
        <a href="#/" onClick={viewAttachment}><RemoveRedEyeIcon /> <span className="tooltip">Aperçu</span></a>
        {/* <a href="/#"><EditIcon /> <span className="tooltip">Aperçu</span></a> */}
        <a href="#/" onClick={(e) => {e.preventDefault(); deleteAttachment()}} className="delete"><DeleteIcon /> <span className="tooltip">Supprimer</span></a>
        <div className="more-actions">
          <IconButton icon={<MoreVertIcon />} onButtonClick={() => setShowDropdown(!showDropdown)} />
          <Dropdown show={showDropdown} onChange={setShowDropdown} className="dropdown" animate>
            <ul>
              <li onClick={() => editAttachmentClickHandler(att, attIndex)}><EditIcon /> Renommer</li>
            </ul>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default AttachmentRow;