const Search = () => {
  return (
    <span className="svg-icon">
      <svg id="Group_964" data-name="Group 964" xmlns="http://www.w3.org/2000/svg" width="14.148" height="14.148" viewBox="0 0 14.148 14.148">
        <path id="Path_1472" data-name="Path 1472" d="M5.305,10.611a5.305,5.305,0,1,1,5.305-5.305A5.312,5.312,0,0,1,5.305,10.611Zm0-9.432A4.126,4.126,0,1,0,9.432,5.305,4.131,4.131,0,0,0,5.305,1.179Zm8.253,12.969a.588.588,0,0,1-.417-.173L8.426,9.259a.589.589,0,0,1,.834-.834l4.716,4.716a.589.589,0,0,1-.417,1.006Z"/>
      </svg>
    </span>
  )
}

export default Search