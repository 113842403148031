import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import Checkbox from '../../UI/Checkbox';
import Button from '../../UI/Button';
import Radio from '../../UI/Radio';
import Tooltip from '../../UI/Tooltip';
import CustomTooltip from '../../UI/CustomTooltip';

const VariablesInner = ({ title, choices, fields, choicesName, onValueChange, documentValues, multiselect, tooltip, variable, select, id, index, disabled = false }) => {
  const [isOpenedInner, setIsOpenedInner] = useState(false);
  const [isSelectedInner, setIsSelectedInner] = useState(false);
  const [isSelectedInner2, setIsSelectedInner2] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(documentValues[choicesName] ? documentValues[choicesName] : '');
  const [selectedText, setSelectedText] = useState('');
  const [isFieldsOpened, setIsFieldsOpened] = useState(false);
  const [isChoicesHidden, setIsChoicesHidden] = useState(false);
  // const [isManualSelected, setIsManualSelected] = useState(false)
  const variablesWrapEl = useRef();
  let innerClasses = 'variables doc-variable-el question-el';

  useEffect(() => {
    if(documentValues[choicesName]) {
      const choice = [...choices].filter(choice => choice.value === documentValues[choicesName]);
      if(choice.length > 0) {
        setSelectedText(choice[0].label);
      }
      setIsOpenedInner(true);
      setIsSelectedInner(true);
      if(documentValues[choicesName] !== 'non') {
        setIsFieldsOpened(true);
      }
    }
    // eslint-disable-next-lin
  }, [choices, choicesName, documentValues]);

  const headParagraphClickHandler = (e) => {
    if (isSelectedInner) {
      setIsSelectedInner2(!isSelectedInner2);
    } else {
      setIsOpenedInner(!isOpenedInner);
    }
  }

  const chooseButtonClickHandler = (e) => {
    setIsOpenedInner(!isOpenedInner);
  }

  const nonButtonClickHandler = async (e) => {
    setIsOpenedInner(true);
    setIsSelectedInner(true);
    await setIsFieldsOpened(false);
    setSelectedChoice('non');
    const input = variablesWrapEl.current.querySelector('input:checked');
    setSelectedText([...choices].filter(choice => choice.value === input.value)[0].label);
    onValueChange(input.value)
  }

  const resetClickHandler = (e) => {
    setIsOpenedInner(false);
    setIsSelectedInner(false);
    setIsSelectedInner2(false);
    setIsFieldsOpened(false);
    setIsChoicesHidden(false);
    setSelectedChoice('');
    onValueChange('');
    setSelectedText('');
    // setIsManualSelected(false)
  }

  const radioChangeHandler = (e) => {
    setSelectedChoice(e.target.value);
    setSelectedText(e.target.parentElement.querySelector('p').textContent);
    setIsChoicesHidden(true);
    setIsSelectedInner(true);
    onValueChange(e.target.value)
    if (e.target.value !== 'non') {
      setIsFieldsOpened(true);
    } else {
      setIsFieldsOpened(false);
    }
    if (isSelectedInner2) {
      setIsSelectedInner2(false);
    }
  }

  const btnChoiceClickHandler = (choice) => {
    setSelectedChoice(choice.value);
    setSelectedText(choice.label);
    onValueChange(choice.value);
    setIsSelectedInner(true);
  }

  if (isOpenedInner) {
    innerClasses += ' opened-2';
  }
  if (isSelectedInner) {
    innerClasses += ' selected';
  }
  if (isSelectedInner2) {
    innerClasses += ' selected-2';
  }

  return (
    <div className={innerClasses} ref={variablesWrapEl} id={id} data-id={index}>
      <div className={select ? "variables__head select" : "variables__head"}>
        <div className={choices.length > 3 ? "variables__head_text tooltip-wrapper" : "variables__head_text tooltip-wrapper cursor-default"} onClick={choices.length > 3 ? headParagraphClickHandler : null}>
          {tooltip && <Tooltip text={tooltip} nowrap />}
          <span className="radio"></span>
          <p>{title}</p>
          <p className="selected">{selectedText}</p>
        </div>
        <div className="variables__head_buttons">
          {choices.length < 4
            ? ( <div> 
              { choices.map((c, i) => (
                <Button disabled={disabled} key={i} text={c.label} outlinePink onButtonClick={() => btnChoiceClickHandler(c)} />
              )) }
              {/* <Button key={`choice_manual`} text="Vous souhaitez le compléter à la main" outlinePink onButtonClick={manualClickHandler} /> */}
            </div> 
            )
            : <Button disabled={disabled} text="Choisir..." outlinePink onButtonClick={chooseButtonClickHandler} />
          }
          
          {/* <Button text="Non" outlinePrimary onButtonClick={nonButtonClickHandler} /> */}
        </div>
        <div className="variables__head_selected">
          {/* <p>{selectedText}</p> */}
          <div className="tooltip-wrapper">
            <Tooltip text="Réinitialiser" nowrap />
            <Button disabled={disabled} icon={<MoreHorizIcon />} outlinePink onButtonClick={resetClickHandler} />
          </div>
        </div>
      </div>
      <div className={isFieldsOpened ? "variables__body fields-opened" : "variables__body"}>
        <div className={isChoicesHidden ? "choices hide" : "choices"}>
          <div className="choices__inner">
            {/* {multiselect ?
              choices.map((option, optionIndex) => {
                console.log(documentValues[variable]);
                return (
                  <div>
                    <Checkbox key={`option_${optionIndex}`} name={option.label} onChange={e => onMultiselectChanged(variable, documentValues[variable], option.value, e.target.checked)} checked={documentValues[variable] && documentValues[variable].includes(option.value)} />
                    <span style={{ marginLeft: 5 }}>{ option.label }</span>
                  </div>
                )
              }) 
              : */}
              <Radio
                choices={choices.map((i) => { return {...i, disabled: disabled} })}
                name={choicesName}
                selectedValue={selectedChoice}
                onChange={radioChangeHandler}
              />
            {/* } */}
          </div>
        </div>
        {/* {fields.length > 0 &&
          <div className="fields">
            {fields.map((field, index) => (
              <div className="fields__box" key={index}>
                <p>{field.label}: </p>
                <Input 
                  editMode 
                  name={field.name} 
                  value={documentValues[field.name] ? documentValues[field.name] : ''} 
                  onChange={(e) => {console.log(e);}} 
                />
              </div>
            ))}
          </div>
        } */}
      </div>
    </div>
  );
}

VariablesInner.propTypes = {
  title: PropTypes.string.isRequired,
  choicesName: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.arrayOf(PropTypes.object)
};

export default VariablesInner;