import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './style/index.scss';

import {
  LoaderState,
  DocumentsState,
  FolderState,
  SignatureState,
  NotificationState,
  DocumentsFoldersState,
  UserState,
  SectionsState,
  SignatureTemplatesState,
  HelpState,
  RegisteredMailState
} from './context'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import moment from 'moment'
import 'moment/locale/fr';
import AttachmentsState from './context/attachments/attachmentsState';
moment.locale('fr')

Bugsnag.start({
  apiKey: '176f89a0d4f4ff60a71e59423f1a060d',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <LoaderState>
        <NotificationState>
          <UserState>
            <DocumentsState>
              <FolderState>
                <SignatureState>
                    <DocumentsFoldersState>
                      <SectionsState>
                        <SignatureTemplatesState>
                          <HelpState>
                            <RegisteredMailState>
                              <AttachmentsState>
                                <App />
                              </AttachmentsState>
                            </RegisteredMailState>
                          </HelpState>
                        </SignatureTemplatesState>
                      </SectionsState>
                  </DocumentsFoldersState>
                </SignatureState>
              </FolderState>
            </DocumentsState>
          </UserState>
        </NotificationState>
      </LoaderState>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);