import { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { UserSolid, Building, BgImage } from '../assets/icons'
import { SettingsLayout } from '../layouts'
import { FileUploadDnD, Input } from '../components/ui_new'
import { UserContext } from '../context'
import { FEATURE, isFeatureEnabled } from '../utils'

const SettingsProfile = () => {
  const { user, partner } = useContext(UserContext)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [phone, setPhone] = useState('')
  const [mobile, setMobile] = useState('')

  useEffect(() => {
    if (user) {
      setFirstName(user.firstname || '')
      setLastName(user.lastname || '')
      setEmail(user.email || '')
      if (user.fonctions && user.fonctions[0]) {
        setRole(user.fonctions[0].name || '')
      }
      setPhone(user.phone || '')
      setMobile(user.mobile_phone || '')
    }
  }, [user])

  return (
    <SettingsLayout pageTitle="Profil négociateur">
      <div className="settings-profile">
        <div className='info-box mb-6'>
          Les informations affichées ici sont reprises de votre logiciel métier.
        </div>
        <div className="settings-profile__box">

          <div className="body">
            <div className="dnd-wrapper">
              <FileUploadDnD
                btnText="Changer"
                accept="image/png,image/jpg,image/jpeg"
                name="cover"
                imagePreview={user?.picture || null}
                noButtons={true}
                dropDisabled={true}
                previewStyle={{ width: '80px', height: '80px', borderRadius: '50%', objectFit: 'cover', border: 'none' }}
                title={"Photo de profil"}
                note={"Votre photo pourra être utilisée comme photo négociateur sur la page de garde de vos documents"}
              />
            </div>
            {user && Object.keys(user).length > 0
              ? (
                <div className="fields">
                  <div className="grid grid-cols-2 w-full gap-4">
                    <Input label="Prénom" disabled={true} className="field-name mb-6" value={firstName} displayNC={firstName.length === 0} />
                    <Input label="Nom" disabled={true} className="field-last_name mb-6" value={lastName} displayNC={lastName.length === 0} />
                  </div>
                  <div className="grid w-full">
                    <Input label="E-mail" disabled={true} className="field-email mb-6" value={email} displayNC={email.length === 0} />
                    <Input label="Fonctions" disabled={true} className="field-role mb-6" value={role} displayNC={role.length === 0} />
                  </div>
                  <div className="grid grid-cols-2 w-full gap-4">
                    <Input label="Téléphone" disabled={true} className="field-phone mb-2" value={phone} displayNC={phone.length === 0} />
                    <Input label="Téléphone mobile" disabled={true} className="field-mobile mb-2" value={mobile} displayNC={mobile.length === 0} />
                  </div>
                </div>

              ) : (
                <div className="loader-v2"></div>
              )}
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}

export default SettingsProfile