import { useContext } from 'react';
import { UserContext } from '../../context/user/userState'
import { isFeatureAuthorized } from '../../utils';

export const AdminFeatureWrapper = ({ children, altContent, rule, resource }) => {

  const { userClaims } = useContext(UserContext)
  
  let isAllowed = isFeatureAuthorized({ userClaims, rule, resource })

  if(isAllowed) {
    return (
      <>
        { children }
      </>
    )
  } else {
    if(altContent) {
      return (
        <>
         { altContent }
        </>
      )
    }
    return null
  }
}