import React from 'react'

import Button from '../../../UI/Button'

const UpdateTemplateSection = ({ didSignatureTemplateChanged, updateSignatureTemplateHandler, setShowSaveUpdatedTemplateModal }) => {
  const changes = didSignatureTemplateChanged.changes;
  const changesMade = [...changes.checkbox_positions, ...changes.signatures_box].length;
  let changesMadeText = '';
  if(changes.checkbox_positions.length > 0) {
    changesMadeText += `${changes.checkbox_positions.length} case à chocher modifiée(s)`;
  }
  if(changes.signatures_box.length > 0) {
    let text = `${changes.signatures_box.length} emplacement(s) de signature modifié(s)`;
    if(changesMadeText !== '') {
      changesMadeText += `, ${text}`;
    }else {
      changesMadeText += `${text}`;
    }
  }

  return (
    <div className="signature-modal-inner__section signature-modal__template">
      <p className="form__subtitle form__subtitle--3">{changesMade} emplacement a été ajouté au modèle de signature ({changesMadeText})</p>
      <div className="signature-modal__template_update">
        <Button text="Mettre à jour le modèle existant" primaryLight onButtonClick={updateSignatureTemplateHandler} />
        <Button text="Créer un nouveau modèle" primaryLight onButtonClick={() => setShowSaveUpdatedTemplateModal(true)} />
      </div>
    </div>
  );
}

export default UpdateTemplateSection