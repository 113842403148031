import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HistoryIcon from '@material-ui/icons/History';

import Modal from '../../UI/Modal';
import HistoryBox from '../../UI/HistoryBox';
import LineLoader from '../../UI/LineLoader';
import Message from '../../UI/Message';

import moment from 'moment'

const dayFormat = 'DD/MM/YYYY'

const HistoryModal = ({ onClose, loading, history, onRestore }) => {

  const [days, setDays] = useState([])

  useEffect(() => {
    // process history into an array of days
    let daysMap = {}
    let daysArray = []
    for(let key in history) {
      let version = history[key]
      let dayValue = moment(version.created).format(dayFormat)
      if(!daysMap[dayValue]) {
        daysMap[dayValue] = {
          dayValue: dayValue,
          versions: []
        }
      }
      daysMap[dayValue].versions.push(version)
    }
    for(let d in daysMap) {
      daysMap[d].versions.sort((a, b) => {
        if(a.created > b.created) return 1
        else if(a.created < b.created) return -1
        return 0
      })
      daysArray.push(daysMap[d])
    }
    daysArray.sort((a, b) => {
      if(moment(a.dayValue, dayFormat).valueOf() > moment(b.dayValue, dayFormat).valueOf()) return 1
      else if(moment(a.dayValue, dayFormat).valueOf() < moment(b.dayValue, dayFormat).valueOf()) return -1
      return 0
    })
    setDays([...daysArray])

  }, [history])

  const isToday = (dayValue) => {
    return moment().format(dayFormat) === dayValue
  }

  const dayTitle = (day) => {
    if(isToday(day)) {
      return 'Aujourd’hui'
    }
    return day
  }
  
  const renderTime = (time) => {
    return moment(time).format('HH[h]mm')
  }

  const versionVariablesArray = (variables) => {
    let varArr = []
    for(let key in variables) {
      varArr.push({ label: key, input: variables[key] })
    }
    return varArr
  }

  const renderDay = (day, dayIndex) => {
    return (
      <div key={`day_${dayIndex}`} className="history__section">
        <h3>{ dayTitle(day.dayValue) }</h3>
        { day.versions.map((version, versionIndex) => {
          return (
            <HistoryBox 
              key={`version_${dayIndex}_${versionIndex}`}
              time={renderTime(version.created)}
              progress={version.progress}
              numberOfEntries={Object.keys(version.values).length}
              onRestoreClick={() => onRestore(version) }
              inputsData={versionVariablesArray(version.values)}
            />
          )
        })}
      </div>
    )
  }

  return(
    <Modal onClose={onClose} bg2>
      <div className="history">
        <h2><HistoryIcon /> Historique du document</h2>

        { loading ? (
          <LineLoader />
        ) : (
          <div>
            { days.length > 0 ? days.map(renderDay) : <Message type="primary" msg="Il n'y a pas encore d'historique à afficher pour ce document" />}
          </div> ) }
      </div>
    </Modal>
  );
}

HistoryModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default HistoryModal;