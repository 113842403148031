import React, { useState, useEffect, useContext } from 'react'
import { Document, Page } from 'react-pdf'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { Plus, ChevronLeft } from '../../assets/icons'
import { fetch_document_data } from '../../helpers/documents'
import { UserContext, LoaderContext } from '../../context'
import { useSingleDocumentActions } from '../../hooks'
import { dateDisplayFormat } from '../../utils'

const TemplatePreviewMobile = ({ onClose, template, opened = false }) => {
  const { createDocumentFromTemplate } = useSingleDocumentActions()
  const { user, agency, partner } = useContext(UserContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dataReady, setDataReady] = useState(false)
  const [pages, setPages] = useState([])
  const [dataLoadFailed, setDataLoadFailed] = useState(false)
  const [updatedAt, setUpdatedAt] = useState('')
  const history = useHistory()

  // Set data 
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const docData = await fetch_document_data(template, { custom_cover: false, values: {}, name: template.name }, 'pdf', agency, user, {}, partner)
        setData(`data:application/pdf;base64,${docData}`)
      } catch (err) {
        console.log(err)
        setDataLoadFailed(true)
      }
      setLoading(false)
      setDataReady(true)
    }
    
    if(template && agency && user && !dataReady) {
      fetchData()
    }
  }, [template, agency, user, dataReady])

  // Set updatedAt
  useEffect(() => {
    if(template?.meta?.updated) {
      setUpdatedAt(moment(template.meta.updated).format(dateDisplayFormat))
    }
  }, [template])

  // On load success
  const handleLoadSuccess = ({ numPages }) => {
    const arr = []
    for(let i = 1; i < numPages + 1; i++) {
      arr.push(<Page pageNumber={i} width={870} renderTextLayer={false} loading="" key={i} />)
    }
    setPages(arr)
  }

  // On create document
  const handleCreateDocument = async () => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Création du document')
    try {
      const id = await createDocumentFromTemplate(template)
      history.push(`/documents/${id}`)
    } catch (err) {

    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // On close
  const handleClose = () => {
    onClose()
    setDataReady(false)
    setData(null)
    setPages([])
  }

  return (
    <div className={`template-preview-mobile ${opened ? 'opened' : ''}`}>
      <div className="template-preview-mobile__head">
        <div className="close" onClick={handleClose}><ChevronLeft /></div>
        <h2>{template?.name}</h2>
        <p>{updatedAt}</p>
      </div>
      <div className="template-preview-mobile__preview u-custom-scrollbar">
        {loading && <div className="loader-wrapper"><div className="loader-v2"></div></div>}
        {!loading && dataReady && data && (
          <Document file={data} onLoadSuccess={handleLoadSuccess} renderMode="canvas" loading="Chargement...">
            {pages}
          </Document>
        )}
        {!loading && dataReady && !dataLoadFailed && (
          <div className="template-preview-mobile__create-doc">
            <button className="icon-btn" onClick={handleCreateDocument}><Plus /></button>
          </div>
        )}
      </div>
    </div>
  )
}

export default TemplatePreviewMobile