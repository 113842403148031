import React, { useContext, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';

import SettingsSidebar from '../sections/settings/SettingsSidebar';
import Button from '../UI/Button';
import Input from '../UI/Input';
import Loader from '../UI/Loader';
import SupportMenu from '../sections/dashboard/SupportMenu'
import { UserContext } from '../../context/user/userState';
import firebase from '../../services/firebase';
import { LoaderContext } from '../../context';

const MyProfile = () => {
  const settingsSidebar = useRef();
  const { user, partner } = useContext(UserContext);
  const { setLoading } = useContext(LoaderContext);
  const history = useHistory()

  const settingsSidebarOpenHandler = (e) => {
    settingsSidebar.current.classList.add('opened');
  }

  const settingsSidebarCloseHandler = (e) => {
    settingsSidebar.current.classList.remove('opened');
  }

  const submitHandler = (e) => {
    e.preventDefault(); 
  }

  const renderUserFields = () => {
    return (
      <form className="form" onSubmit={submitHandler}>
        <div className="form__group form__group--profile">
          <Input 
            label="Prénom"
            value={user.firstname}
            formEl
            disabled
          />
          <Input 
            label="Nom de famille"
            value={user.lastname}
            formEl
            disabled
          />
        </div>
        <div className="form__group form__group--profile">
          <Input 
            label="Email"
            value={user.email}
            formEl
            disabled
          />
          <div className="input-wrapper">
            {user.fonctions && user.fonctions.length > 0 && user.fonctions.map((f, i) => (
              <Input 
                key={i}
                label="Fonctions"
                value={f.name}
                formEl
                disabled
              />
            ))}
          </div>
        </div>
        <div className="form__group form__group--profile">
          <Input 
            label="Téléphone"
            value={user.phone}
            formEl
            disabled
          />
          <Input 
            label="Téléphone mobile"
            value={user.mobile_phone}
            formEl
            disabled
          />
        </div>
        {/* <Button 
          text="Sauvegarder"
          type="submit"
          primary
          medium
          textUppercase
          fontSmall
          bold
          className="w-140"
        /> */}
      </form>
    );
  }

  const signOut = async () => {
    setLoading(true)
    await firebase.auth().signOut()
    history.push('/signin')
    setLoading(false)
  }

  return(
    <div className="my-profile">
      <div className="my-profile__left" ref={settingsSidebar}>
        <div className="my-profile__left_overlay" onClick={settingsSidebarCloseHandler}></div>
        <SettingsSidebar />
      </div>
      <div className="my-profile__right">
        <div className="my-profile__right_head">
          <h1><span onClick={settingsSidebarOpenHandler}><MenuIcon /></span> Mon Profil</h1>
          <SupportMenu />
          <Link className="username" to="/settings/my-profile">
            <PersonIcon />
          </Link>
        </div>
        <div className="my-profile__right_body">
          {user && Object.keys(user).length > 0 ? renderUserFields() : <Loader primary normal small />}
          { partner  && partner === 'alphabet' && <Button text="Déconnexion" onButtonClick={signOut} danger /> }
        </div>
        
      </div>
    </div>
  );
}

export default MyProfile;