import {
    SET_SELECTED_REGISTERED_MAIL_FILTER,
    SET_AR24_MAIL,
    SET_IDENTITY_VERIFICATION_REQUESTS,
    SET_AR24_SENDER_MODAL,
    SET_AR24_SENDER,
    SET_AR24_MAIL_MODAL,
    SET_AR24_SENDER_EVENTS
} from "../types";

const reducer = (state, action) => {
  switch(action.type) {
    case SET_SELECTED_REGISTERED_MAIL_FILTER:
      return {
        ...state,
        ar24MailFilter: action.payload
      }
    case SET_AR24_SENDER:
      return {
        ...state,
        ar24Sender: action.payload,
        ar24SenderFetched: true
      }
    case SET_AR24_MAIL:
      return {
        ...state,
        ar24Mail: action.payload,
        ar24MailFetched: true
      }
    case SET_IDENTITY_VERIFICATION_REQUESTS:
      return {
        ...state,
        identityVerificationRequests: action.payload,
        identityVerificationRequestsFetched: true
      }
    case SET_AR24_SENDER_MODAL:
      return {
        ...state,
        senderModal: action.payload
      }
    case SET_AR24_MAIL_MODAL:
      return {
        ...state,
        mailModal: action.payload
      }
    case SET_AR24_SENDER_EVENTS:
      return {
        ...state,
        ar24SenderEvents: action.payload,
        ar24SenderEventsFetched: true
      }
    default:
      return state;
  }
}

export default reducer
