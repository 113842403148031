export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const validateMobileNumber = number => {
  const re = /^[+]\d{6,}$/;
  return re.test(number);
}

export const validateURL = (url) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(url);
}

export default (fields, submit = false) => {
  let errors = {};

  for(let field in fields) {
    if(submit) {
      fields[field].touched = true;
    }

    const currentField = fields[field];

    if(currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[field] = currentField.requiredMessage ? currentField.requiredMessage : 'Ce champs est requis';
    }

    if(currentField.file && currentField.required && Object.keys(currentField.value).length === 0 && currentField.touched) {
      errors[field] = currentField.requiredMessage ? currentField.requiredMessage : 'Ce champs est requis';
    }
    
    if(!errors[field] && currentField.email && !validateEmail(currentField.value) && currentField.touched) {
      errors[field] = currentField.emailMessage ? currentField.emailMessage : 'Adresse électronique invalide !';
    }

    // if(!errors[field] && currentField.values && currentField.values.length > 0 && !validateEmail(currentField.value) && currentField.touched) {
    //   errors[field] = currentField.emailMessage ? currentField.emailMessage : 'Adresse électronique invalide !';
    // }

    if(!errors[field] && currentField.matchWith && currentField.value !== fields[currentField.matchWith].value && currentField.touched) {
      errors[field] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal';
    }

    if(!errors[field] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[field] = currentField.minLengthMessage ? currentField.minLengthMessage : ``;
    }

    if(!errors[field] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[field] = currentField.maxLengthMessage ? currentField.maxLengthMessage : ``;
    }

    if(!errors[field] && currentField.file && currentField.touched && Object.keys(currentField.value).length > 0 && currentField.allowedTypes && !currentField.allowedTypes.includes(currentField.value[0].type.split('/')[1])) {
      errors[field] = currentField.allowedTypesMessage ? currentField.allowedTypesMessage : 'Type de fichier non valide !';
    }

    if(!errors[field] && currentField.file && currentField.touched && Object.keys(currentField.value).length > 0 && currentField.maxFileSize && (currentField.maxFileSize * 1024) < Math.round(currentField.value[0].size)) {
      errors[field] = currentField.maxFileSizeMessage ? currentField.maxFileSizeMessage : `File is too large(${Math.round(currentField.value[0].size / 1024)}KB), it cannot be larger than ${currentField.maxFileSize}KB`;
    }
  }

  return errors;
}