import React, { Fragment, useState, useEffect, useContext } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LabelIcon from '@material-ui/icons/Label';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Modal from '../UI/Modal';
import IconButton from '../UI/IconButton';
import Button from '../UI/Button';
import Alert from '../UI/Alert';
import { NotificationContext } from '../../context/notifications/notificationState';
import { availableOn } from '../../utils'

const MoveToSectionModal = ({ onClose, onMove, sections, onCreateSection, selectedDocuments, loading, currentSection, getTemplateById, updateSection, deleteSection }) => {
  const [activeSection, setActiveSection] = useState(currentSection);
  const [showDeleteTemplateAlert, setShowDeleteTemplateAlert] = useState(false);
  const [showDeleteSectionAlert, setShowDeleteSectionAlert] = useState(false);
  const [sectionTemplates, setSectionTemplates] = useState([]);
  const [removeTemplateData, setRemoveTemplateData] = useState({});
  const [deleting, setDeleting] = useState(false);
  const { setNotification } = useContext(NotificationContext);

  useEffect(() => {
    if(currentSection) {
      setActiveSection(currentSection);
    }
  }, [currentSection, getTemplateById]);

  // Set active section and section templates
  const setActiveSectionHandler = (s) => {
    setActiveSection(s);
    const templates = [...s.templates];
    const activeTemplates = [];
    templates.forEach(t => {
      if(getTemplateById(t)) {
        activeTemplates.push(t);
      }
    });
    setSectionTemplates(activeTemplates);
  }

  // Remove template from section
  const removeTemplateClickHandler = (template, section) => {
    setShowDeleteTemplateAlert(true);
    setRemoveTemplateData({ template, section });
  }

  const removeTemplateHandler = () => {
    const { section, template } = removeTemplateData;
    let ids = [...section.templates];
    ids = ids.filter(t => t !== template);
    
    setDeleting(true);
    updateSection(section.id, { templates: ids }, (section) => {
      setNotification({ msg: `Modèle supprimé de la section sélectionnée !`});
      setDeleting(false);
      setShowDeleteTemplateAlert(false);
      setSectionTemplates(section.templates);
    }, (err) => {
      console.log(err);
      setDeleting(false);
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    });
  }

  // Delete section
  const deleteSectionHandler = () => {
    setDeleting(true);
    deleteSection(activeSection.id, () => {
      setNotification({ msg: `Section supprimée avec succès !`});
      setDeleting(false);
      setActiveSection(null);
      setShowDeleteSectionAlert(false);
    }, (err) => {
      setDeleting(false);
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    });
  }

  // Render breadcrumbs
  const renderBreadcrumbsContent = () => ( activeSection 
    ? (
      <Fragment>
        <li className="breadcrumbs__item" onClick={() => setActiveSection(null)}><p>Tous</p></li>
        <li className="breadcrumbs__item active"><ChevronRightIcon />  {activeSection.name}</li>
      </Fragment>
    )
    : <li className="breadcrumbs__item active">Tous</li>
  );

  // Render body
  const renderBody = () => (
    !activeSection
      ? sections.length > 0 
        ? [...sections].map((s, i) => (
          <div className="folder-inner" key={i} onClick={() => setActiveSectionHandler(s)}><AccountTreeIcon /> {s.name}</div>
        )) 
        : <div className="no-folders"><h3>Pas de catégories</h3></div> 
      : sectionTemplates.length > 0 
        ? <ul className="templates-list">
          {sectionTemplates.map((t, i) =>
            { return getTemplateById(t) && (
            <li key={i}>
              <DescriptionOutlinedIcon /> {getTemplateById(t).name} <span className="delete" onClick={() => removeTemplateClickHandler(t, activeSection)}><DeleteIcon /></span>
            </li> ) }
          )}
        </ul>
        : <div className="no-folders"><h3>Pas de modèles dans cette catégorie</h3></div>
  );
  

  return(
    <Modal onClose={onClose}>
      <div className="move-to-folder">
        <div className="move-to-folder__head">
          <h4>Déplacer <strong>{`${selectedDocuments.length} élément(s)`}</strong> vers...</h4>
        </div>
        <div className="move-to-folder__body">
          <div className="move-to-folder__body_top">
            <ul className="breadcrumbs">
              {renderBreadcrumbsContent()}
            </ul>
            <div>
              <IconButton 
                icon={activeSection ? <EditIcon /> : <AddBoxIcon />} 
                onButtonClick={() => onCreateSection(activeSection ? 'edit' : 'create', activeSection)} 
                medium
              />
              {activeSection && <IconButton icon={<DeleteForeverIcon />} medium onButtonClick={() => setShowDeleteSectionAlert(true)} />}
            </div>
          </div>
          <div className="move-to-folder__body_main">
            {renderBody()}
          </div>
        </div>
        <div className="move-to-folder__foot">
          <Button text="Annuler" onButtonClick={onClose} />
          <Button 
            text={loading ? "Déplacement..." : "Ajouter ici"}
            onButtonClick={() => onMove(activeSection)} 
            primary 
            disabled={loading || !activeSection}
          />
        </div>
      </div>

      {showDeleteTemplateAlert && <Alert 
        onClose={() => setShowDeleteTemplateAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer le modèle de cette catégorie ?"
        onSubmit={removeTemplateHandler}
        loading={deleting}
        deleteAlert
      />}
      {showDeleteSectionAlert && <Alert 
        onClose={() => setShowDeleteSectionAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer cette catégorie ?"
        onSubmit={deleteSectionHandler}
        loading={deleting}
        deleteAlert
      />}
    </Modal>
  );
}

export default MoveToSectionModal;