import React, { useContext, useState, useEffect } from 'react' 
import { useParams, useHistory } from 'react-router-dom'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'

import Loader from '../../UI/Loader'
import SupportPageLayout from '../../layouts/SupportPageLayout'
import CustomSelect from '../../UI/CustomSelect'
import { HelpContext } from '../../../context'
import { sortArrayOfObjects } from '../../../utils'
import { MainLayout } from '../../../layouts'

const HelpCategory = ({v2}) => {
  const { categories, categoriesFetched, articlesArr, articlesFetched } = useContext(HelpContext)
  const { id } = useParams()
  const history = useHistory()
  const [category, setCategory] = useState({})
  const [categoryArticles, setCategoryArticles] = useState([])
  const [categoryDoesNotExist, setCategoryDoesNotExist] = useState(false)
  const [sortOptions, setSortOptions] = useState([
    { value: 'title.en', label: `Trier de A à Z`, active: true },
    { value: 'readCount', label: `Trier par popularité`, active: false },
  ])
  const [sortedArticles, setSortedArticles] = useState([])

  // Set category from id
  useEffect(() => {
    if(categoriesFetched) {
      if(categories[id]) {
        setCategory(categories[id])
      }else {
        setCategoryDoesNotExist(true)
      }
    }
  }, [id, categories, categoriesFetched])

  // Set category articles
  useEffect(() => {
    if(articlesFetched) {
      let filtered = articlesArr.filter(article => article.category === id)
      filtered = sortArrayOfObjects(filtered, 'title.en', 'asc')
      setCategoryArticles(filtered)
      setSortedArticles(filtered)
    }
  }, [articlesFetched, articlesArr, id])

  // Article click handler
  const articleClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/help-article/${id}`)
  }

  // Sorting
  const sortArticlesChangeHandler = (opt) => {
    if(opt.value !== '') {
      let arr = sortArrayOfObjects([...categoryArticles], opt.value, opt.value === 'readCount' ? 'desc' : 'asc')
      setSortedArticles(arr)
    }else {
      setSortedArticles(categoryArticles)
    }
    setSortOptions(prev => prev.map((o) => o.value === opt.value ? {...o, active: true} : {...o, active: false}))
  }

  return (
    <MainLayout className="page-help" pageTitle="Nouveautés">
      <SupportPageLayout withSidebar showBackButton v2={v2}>
        {!articlesFetched && !categoriesFetched && <Loader normal small primary />}
        {Object.keys(category).length > 0 && 
          <div className="help-category">
            <div className="help-category__head">
              <h2>{category.title.en}</h2>
              <CustomSelect 
                options={sortOptions} 
                onChange={sortArticlesChangeHandler} 
                active={sortOptions.find(opt => opt.active)} 
                className="sort-select"
                formEl 
              />
              {category.description?.en && <div className="help-category__head_desc">
                <p>{category.description.en}</p>
              </div>}
            </div>
            <div className="help-category__body">
              <ul>
                {sortedArticles.map((article, idx) => (
                  <li key={idx}><a href="/#" onClick={(e) => articleClickHandler(e, article.id)}><DescriptionIcon /> {article.title.en}</a></li>
                ))}
              </ul>
            </div>
          </div>
        }
        {categoryDoesNotExist &&
          <div className="support-page__no-data">
            <p>{`La catégorie avec id(${id}) n'existe pas`}</p>
          </div>
        }
      </SupportPageLayout>
    </MainLayout>
  )
}

export default HelpCategory