import React, { memo } from 'react'
import { Edit, DeleteOutlined} from '@material-ui/icons'

import Button from '../../../UI/Button'
import IconButton from '../../../UI/IconButton'
import Input from '../../../UI/Input'
import Checkbox from '../../../UI/Checkbox'
import CustomTooltip from '../../../UI/CustomTooltip'

const CustomFields = memo(({ placeSignatureHandler, fieldsPositions, updateFieldRequiredProp, updateLabel, removeLabel, deleteCustomField, useCustomPlacementTool = false, handleEditCheckboxPosition }) => {
  return (
    <div className="signature-modal-inner__section signature-modal-inner__section--checkbox">
      <p className="form__subtitle form__subtitle--3">Cases a cocher</p>
      {useCustomPlacementTool ? (
        <div className="fields-wrapper fields-wrapper--2">
          <Button text={'Ajouter une case à cocher'} onButtonClick={(e) => placeSignatureHandler(e, `checkbox-${Date.now()}`, 'checkbox')} primaryLight />
          <div>
            {fieldsPositions.map((field, i) => (
              <div className="field-positions" key={i}>
                <div className="field-positions__page">{'Page'} {+field.page + 1}</div>
                <div className="checkbox-wrapper">
                  <div className="checkbox-wrapper__field">
                    <div className="checkbox-wrapper__field_fix">
                      <Checkbox 
                        onChange={(e) => updateFieldRequiredProp(e.target.checked, field.id)}
                        checked={field.required}
                      />
                    </div>
                    <p>{'Requis'}</p>
                  </div>
                  <CustomTooltip content={'Changer la position de la case à cocher'}>
                    <div className="eidt-btn-wrapper">
                      <IconButton icon={<Edit />} onButtonClick={() => handleEditCheckboxPosition(field)} light className="edit-btn" />
                    </div>
                  </CustomTooltip>
                </div>
                <div className="field-positions__divider"></div>
                {(!field.label || (field.label && Object.keys(field.label).length === 0)) && <Button 
                  text={'Ajouter un label'} 
                  // onButtonClick={(e) => placeSignatureHandler(e, Object.keys(field.label).length > 0 ? field.label.id : `label-${Date.now()}`, 'label', field.id)} 
                  onButtonClick={(e) => placeSignatureHandler(e, `label-${Date.now()}`, 'label', field.id)} 
                  className="label-btn" 
                  primaryLight 
                />}
                {field.label && Object.keys(field.label).length > 0 && 
                  <div className="checkbox-label">
                    <Input value={field.label.value} onChange={(e) => updateLabel(e, field.id, field.label.id)} className="label-input" placeholder={'Nommer le label'} />
                    <CustomTooltip content={'Changer la position du label'}>
                      <div className="edit-btn-wrapper">
                        <IconButton icon={<Edit />} onButtonClick={(e) => placeSignatureHandler(e, field.label.id, 'label', field.id)} light className="edit-btn" />
                      </div>
                    </CustomTooltip>
                    <CustomTooltip content={'Supprimer label'}>
                      <div>
                        <IconButton icon={<DeleteOutlined />} light onButtonClick={() => removeLabel(field.id)} className="delete-btn" />
                      </div>
                    </CustomTooltip>
                  </div>
                }
                {field.error && <p className="field-positions__error">{field.error}</p>}
                <CustomTooltip content={'Supprimer la case à cocher et son label'}>
                  <div className="field-positions__remove">
                    <IconButton icon={<DeleteOutlined />} onButtonClick={(e) => deleteCustomField(field.id)} className="delete-btn delete-btn--2" />
                  </div>
                </CustomTooltip>
              </div>
            ))}
          </div>
        </div>
      )
      :
      (
        <div className="fields-wrapper fields-wrapper--2">
          <Button 
            text="Ajouter une case à cocher" 
            onButtonClick={(e) => useCustomPlacementTool ? placeSignatureHandler(e, `checkbox-${Date.now()}`, 'checkbox') : placeSignatureHandler(e, null, null, 'checkbox')} 
            primaryLight 
          />
          <div>
            {fieldsPositions.map((field, i) => (
              <div className="field-positions" key={i}>
                <Input disabled value={field.PAGE} label="Page" />
                <div className="checkbox-wrapper">
                  <Checkbox 
                    onChange={(e) => updateFieldRequiredProp(e.target.checked, field.id)}
                    checked={field.REQUIS}
                  />
                  <p>Requis</p>
                </div>
                <Button 
                  text={field.label && Object.keys(field.label).length > 0 ? "Changer la position de l'étiquette" : "Ajouter un label"} 
                  onButtonClick={(e) => useCustomPlacementTool ? placeSignatureHandler(e, `label-${Date.now()}`, 'label', field.id) : placeSignatureHandler(e, null, null, 'label', field.id)} className="label-btn" 
                  primaryLight 
                />
                {field.label && Object.keys(field.label).length > 0 && 
                  <div className="checkbox-label">
                    <Input value={field.label.LIBELLE} onChange={(e) => updateLabel(e, field.id)} className="label-input" />
                    <CustomTooltip content="Supprimer l'étiquette">
                      <div style={{height: '36px'}}>
                        <IconButton icon={<DeleteOutlined />} onButtonClick={() => removeLabel(field.id)} className="delete-btn" />
                      </div>
                    </CustomTooltip>
                  </div>
                }
                {/* <Input disabled value={field.TOP} label="Top" />
                <Input disabled value={field.LEFT} label="Left" />
                <Input disabled value={field.WIDTH} label="Width" />
                <Input disabled value={field.HEIGHT} label="Height" /> */}
                {field.error && <p className="field-positions__error">{field.error}</p>}
                <CustomTooltip content="Supprimer la case à chocher">
                  <div className="delete-btn--2">
                    <IconButton icon={<DeleteOutlined />} onButtonClick={(e) => deleteCustomField(field.id)} light className="delete-btn delete-btn--2" />
                  </div>
                </CustomTooltip>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
})

export default CustomFields