import { urlSuffixForEnvironment } from '../utils'
import { get_anonymous, get_request, patch_request, post_request } from './firestore'
import config from '../config.json'
import { FUNCTIONS_BASE_URL, FUNCTIONS_BASE_URL_LOCAL } from '../constants'

const functionsBaseUrl = config.useLocalApi ? FUNCTIONS_BASE_URL_LOCAL : FUNCTIONS_BASE_URL

const baseUrl = `${functionsBaseUrl}/api${urlSuffixForEnvironment(config.environment)}`

const sendEmail = async (sender, recipient, subject, text, documentName, documentData, attachments) => {
  return await post_request(`${baseUrl}/send_email_v2`, {
    sender,
    recipient,
    subject,
    text,
    documentName,
    documentData,
    attachments
  })
}

const sendSignatureRequest = async (data) => {
  return await post_request(`${baseUrl}/signature_request`, data);
}

const cancelSignatureRequest = async (data) => {
  return await post_request(`${baseUrl}/cancel_signature_request`, data);
}

const getSignaturePreview = async (data, documentIndex) => {
  return await get_request(`${baseUrl}/signature_preview`, null, `folder_id=${data.folder_id}&document_index=${documentIndex || 0}`);
}

const getSignaturePreviewWithUrl = async (data) => {
  return await get_request(`${baseUrl}/signature_preview_with_url`, null, `folder_id=${data.folder_id}&document_uri=${data.document_url}&document_name=${data.document_name}`);
}

const getSignatureProofs = async (data) => {
  return await get_request(`${baseUrl}/signature_proofs`, null, `folder_id=${data.folder_id}`);
}

const getSignatureFiles = async (data) => {
  return await get_request(`${baseUrl}/signature_files`, null, `folder_id=${data.folder_id}`);
}

const getVialinkDocumentContent = async (data) => {
  return await get_request(`${baseUrl}/document_content`, null, `file_uri=${data.file_uri}`);
}

const createVialinkFile = async (data) => {
  return await post_request(`${baseUrl}/vialink/create_file`, data);
}

const updateActiveSignature = async ({ signatureId, data }) => {
  return await patch_request(`${baseUrl}/active_signature/${signatureId}`, data);
}

const getAttachmentPreview = async (data) => {
  return await get_request(`${baseUrl}/attachment_preview`, null, `attachment_url=${encodeURIComponent(data.attachment_url)}`);
}

const notifySignatureParticipant = async (data) => {
  return await post_request(`${baseUrl}/notify_signature_participant`, data)
}

const send_feedback = async (from, emailHtml, subject) => {
  return await post_request(`${baseUrl}/send_feedback`, { from, emailHtml, subject })
}

export {
  sendEmail,
  sendSignatureRequest,
  cancelSignatureRequest,
  getSignaturePreview,
  getSignaturePreviewWithUrl,
  getSignatureProofs,
  getVialinkDocumentContent,
  createVialinkFile,
  getAttachmentPreview,
  updateActiveSignature,
  notifySignatureParticipant,
  send_feedback,
  getSignatureFiles
} 