import React, { useState, Fragment, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ExpandMore } from '@material-ui/icons'

import ActionsDropdown from '../UI/ActionsDropdown'
import DashboardHeadMeta from '../sections/DashboardHeadMeta';
import DashboardBox from '../UI/DashboardBox';
import ActivityBox from '../UI/ActivityBox';
import DashboardView from '../sections/DashboardView';
import LineLoader from '../UI/LineLoader';
import Loader from '../UI/Loader';
import Alert from '../UI/Alert';
import Modal from '../UI/Modal';

import { DocumentsContext } from '../../context/documents/documentsState'
import { SignatureContext } from '../../context/signatures/signaturesState';
import { UserContext } from '../../context/user/userState';
import { sortedArrayFromObject, sorterWithPathAndOrder, signatureAvailable, saveSortingToLS, getSorting, sortArrayOfObjects, savePaginationDataToLS, getPaginationData, coverPageConfig } from '../../utils';
import { ACTION_LABELS, DOCUMENT_LIFESPAN_MILLISECONDS, STATUS_LABELS, perPageOptions } from '../../constants';

import { update_document, get_signature_status, get_signature_document, delete_signature, update_signature } from '../../services/firestore';
import { generate_document } from '../../services/lawstudioApi';

const Dashboard = ({ statusFetched, onStatusFetched }) => {
  const [movedBy, setMovedBy] = useState(0);
  const [activeStatus, setActiveStatus] = useState('')
  const [loading, setLoading] = useState(false);
  const slider = useRef();
  const [documentsArray, setDocumentsArray] = useState([])
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [search, setSearch] = useState('');
  const [removeSearchClose, setRemoveSearchClose] = useState(false);
  const sortLS = getSorting()
  const [columns, setColumns] = useState([
    { 
      label: 'Titre', 
      value: 'name', 
      order: sortLS?.documents?.value === 'name' ? sortLS.documents?.order || 'desc' : 'desc',
      cssClass: 'col-1', 
      all: true, 
      active: sortLS?.documents?.value === 'name'
    },
    { 
      label: 'Statut', 
      value: 'status', 
      order: sortLS?.documents?.value === 'status' ? sortLS.documents?.order || 'desc' : 'desc',
      cssClass: 'col-2', 
      all: false, 
      active: sortLS?.documents?.value === 'status'  
    },
    { 
      label: 'Créé le', 
      value: 'meta.created', 
      order: sortLS?.documents?.value === 'meta.created' ? sortLS.documents?.order || 'desc' : 'desc', 
      cssClass: 'col-3 date-col doc-date-col', 
      all: false, 
      active: sortLS?.documents?.value === 'meta.created'  
    },
    { 
      label: 'Modifié le', 
      value: 'meta.updated', 
      order: sortLS && sortLS.documents ? sortLS.documents.value === 'meta.updated' ? sortLS.documents.order || 'desc' : 'desc' : 'desc',
      cssClass: 'col-4 date-col doc-date-col', 
      all: false, 
      active: sortLS && sortLS.documents ? sortLS.documents.value === 'meta.updated' : true 
    },
  ]);
  const [signatureColumns, setSignatureColumns] = useState([
    { 
      label: 'Titre', 
      cssClass: 'col-1', 
      all: true, 
      value: 'title', 
      order: sortLS?.signatures?.value === 'recipients.email__arr' ? sortLS.signatures?.order || 'desc' : 'desc',   
      active: sortLS && sortLS.signatures ? sortLS.signatures.value === 'title' : true
    },
    { 
      label: 'Destinataires', 
      cssClass: 'col-2 signatures-col-2', 
      all: false, 
      value: 'recipients.email__arr', 
      order: sortLS?.signatures?.value === 'recipients.email__arr' ? sortLS.signatures?.order || 'desc' : 'desc',  
      active: sortLS?.signatures?.value === 'recipients.email__arr'  
    },
    { 
      label: 'Envoyé par', 
      cssClass: 'col-3 signatures-col-3', 
      all: false, 
      value: 'sentBy', 
      order: sortLS?.signatures?.value === 'sentBy' ? sortLS.signatures?.order || 'desc' : 'desc',
      active: sortLS?.signatures?.value === 'sentBy'  
    },
    { 
      label: 'Créé le', 
      cssClass: 'col-4 signatures-col-4', 
      all: false, 
      value: 'createdAt', 
      order: sortLS?.signatures?.value === 'createdAt' ? sortLS.signatures?.order || 'desc' : 'desc', 
      active: sortLS?.signatures?.value === 'createdAt'  
    },
    { 
      label: 'Statut', 
      cssClass: 'col-5 signatures-col-5', 
      all: false, 
      value: 'status', 
      order: sortLS?.signatures?.value === 'status' ? sortLS.signatures?.order || 'desc' : 'desc', 
      active: sortLS?.signatures?.value === 'status' 
    },
  ]);
  const { documents, setActiveFilterColumn, actions, fetchDocuments, getTemplateById, actionsFetched, documentsLoaded } = useContext(DocumentsContext)
  const { signatures, signaturesFetched, fetchSignatures, signaturesWithStatus, setSignaturesWithStatus, deleteSignature } = useContext(SignatureContext);
  const [signaturesStatus, setSignaturesStatus] = useState([]);
  const [data, setData] = useState([]);
  const [documentActionsArray, setDocumentActionsArray] = useState([])
  const [boxesLoading, setBoxesLoading] = useState(true);
  const [activitiesLoading, setActivitiesLoading] = useState(true);
  const [readyDocs, setReadyDocs] = useState([]);
  const [draftDocs, setDraftDocs] = useState([]);
  const [inProgressDocs, setInProgressDocs] = useState([]);
  const [closedDocs, setClosedDocs] = useState([]);
  const [cancelledDocs, setCancelledDocs] = useState([]);
  const [expiredDocs, setExpiredDocs] = useState([]);
  const [viewTitle, setViewTitle] = useState('Tous');
  const [showDeleteSignatureAlert, setShowDeleteSignatureAlert] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [signatureIdsToDelete, setSignatureIdsToDelete] = useState([]);
  const [showDeleteSingleSignatureAlert, setShowDeleteSingleSignatureAlert] = useState(false);
  const [signatureToDelete, setSignatureToDelete] = useState(null);
  const [showPreviewDocModal, setShowPreviewDocModal] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewError, setPreviewError] = useState('');
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const history = useHistory()
  const { agency, user, partner, canUseSignatures, siteConfigsLoaded } = useContext(UserContext);
  const [docsReady, setDocsReady] = useState(false);
  const [signaturesReady, setSignaturesReady] = useState(false);
  const [paginatedDocumentsData, setPaginatedDocumentsData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [allPages, setAllPages] = useState(1)
  const [documentsPaginationReady, setDocumentsPaginationReady] = useState(false)
  const [paginatedSignaturesData, setPaginatedSignaturesData] = useState([])
  const [perPageSignatures, setPerPageSignatures] = useState(10)
  const [currentPageSignatures, setCurrentPageSignatures] = useState(1)
  const [allPagesSignatures, setAllPagesSignatures] = useState(1)
  const [signaturesPaginationReady, setSignaturesPaginationReady] = useState(false)

  useEffect(() => {
    const paginationData = getPaginationData()
    if(paginationData && paginationData.documents) {
      paginationData.documents.items && setPerPage(paginationData.documents.items)
    }
    if(paginationData && paginationData.signatures) {
      paginationData.signatures.items && setPerPageSignatures(paginationData.signatures.items)
    }

    return () => {
      setActiveFilterColumn('');
      // onStatusFetched(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(documentsLoaded) {
      const copyOfDocuments = {...documents};
      const filteredDocuments = {};
      for(let key in copyOfDocuments) {
        if(!copyOfDocuments[key].archived && !copyOfDocuments[key].deleted) {
          filteredDocuments[key] = copyOfDocuments[key];
        }
      }
      let sort = 'name'
      let order = 'asc'
      if(sortLS && sortLS.documents) {
        sort = sortLS.documents.value 
        order = sortLS.documents.order
      }
      let docsArr = sortedArrayFromObject(filteredDocuments, sorterWithPathAndOrder(sort, order))
      setDocumentsArray(docsArr)
      setData(docsArr);
      setReadyDocs([...docsArr].filter(doc => doc.status === 'ready'));
      setDraftDocs([...docsArr].filter(doc => doc.status === 'draft'));
      setDocsReady(true);
    }
  }, [documents, documentsLoaded]);

  // Change paginated data arr when data arr changes
  useEffect(() => {
    if((activeStatus === 'draft' || activeStatus === 'ready')) {
      let current = currentPage
      let sliceFrom = current === 1 ? 0 : (current - 1) * perPage
      if(sliceFrom >= data.length) {
        sliceFrom = 0
        setCurrentPage(1)
      }
      let slicedData = data.slice(sliceFrom, sliceFrom + perPage)
      setPaginatedDocumentsData(slicedData)
      let all = Math.ceil(data.length / perPage)
      setAllPages(all === 0 ? 1 : all)
      setDocumentsPaginationReady(true)
    }
  }, [data, perPage, currentPage, allPages, activeStatus])

  // Change paginated data arr when filtered data changes - signatures
  useEffect(() => {
    if((activeStatus === 'closed' || activeStatus === 'in-progress')) {
      let current = currentPageSignatures
      let sliceFrom = current === 1 ? 0 : (current - 1) * perPageSignatures
      if(sliceFrom >= data.length) {
        sliceFrom = 0
        setCurrentPage(1)
      }
      let slicedData = data.slice(sliceFrom, sliceFrom + perPageSignatures)
      setPaginatedDocumentsData(slicedData)
      let all = Math.ceil(data.length / perPageSignatures)
      setAllPagesSignatures(all === 0 ? 1 : all)
      setSignaturesPaginationReady(true)
    }
  }, [data, perPageSignatures, currentPageSignatures, allPagesSignatures, activeStatus])

  useEffect(() => {
    if(canUseSignatures){
      if(signaturesFetched) {
        const signaturesArr = [...signatures];
        // let docsArr = sortedArrayFromObject(filteredDocuments)
        // setDocumentsArray(docsArr)
        // setData(docsArr);
        const closed = signaturesArr.filter(s => s.status === 'COMPLETED');
        const inProgress = signaturesArr.filter(s => s.status === 'SIGNING_PENDING');
        let sort = 'title'
        let order = 'asc'
        if(sortLS && sortLS.signatures) {
          sort = sortLS.signatures.value 
          order = sortLS.signatures.order
        }
        const closedArr = sortArrayOfObjects(closed, sort, order)
        const inProgressArr = sortArrayOfObjects(inProgress, sort, order)
        setClosedDocs(closedArr);
        setInProgressDocs(inProgressArr);
        setSignaturesReady(true);
      }
    }else {
      if(siteConfigsLoaded) {
        setSignaturesReady(true);
      }
    }
  }, [signatures, signaturesFetched, canUseSignatures, siteConfigsLoaded]);

  useEffect(() => {
    // if(signatureAvailable()) {
      if(documentsLoaded && signaturesFetched && docsReady && signaturesReady && siteConfigsLoaded) {
        setBoxesLoading(false);
      }
    // }
    // else {
    //   if(documentsLoaded && docsReady) {
    //     setBoxesLoading(false);
    //   }
    // }
  }, [documentsLoaded, signaturesFetched, docsReady, signaturesReady, siteConfigsLoaded]);

  useEffect(() => {
    // let actsArray = sortedArrayFromObject(actions)
    let docActsArray = []
    let documentActionsMap = {}
    for(let key in actions) {
      let action = actions[key]
      let documentId = action.document
      if(!documentActionsMap[documentId]) {
        documentActionsMap[documentId] = []
      }
      documentActionsMap[documentId].push(action)
    }
    for(let key in documentActionsMap) {
      docActsArray.push({
        document: key,
        actions: documentActionsMap[key]
      })
    }
    setDocumentActionsArray(docActsArray)
  }, [actions])

  useEffect(() => {
    if(actionsFetched) {
      setActivitiesLoading(false);
    }
  }, [actionsFetched]);

  const sliderPrevHandler = () => {
    if(movedBy > 0) {
      slider.current.style.transform = `translateX(${(movedBy - 1) * -203}px)`;
      setMovedBy(prev => prev - 1);
    }
  }

  const sliderNextHandler = () => {
    if((slider.current.children.length * 203 - 15) - movedBy * 203 > slider.current.offsetWidth) {
      slider.current.style.transform = `translateX(${(movedBy + 1) * -203}px)`;
      setMovedBy(prev => prev + 1);
    }
  }

  const dashboardBoxFilterClickHandler = (status) => {
    setCurrentPage(1)
    let sort = 'name'
    let order = 'desc'
    if(status === 'ready' || status === 'draft') {
      if(sortLS && sortLS.documents) {
        sort = sortLS.documents.value 
        order = sortLS.documents.order
      }
    }else if(status === 'in-progress' || status === 'closed') {
      sort = 'title'
      if(sortLS && sortLS.signatures) {
        sort = sortLS.signatures.value 
        order = sortLS.signatures.order
      }
    }
    if(status === 'ready') { 
      setData(sortArrayOfObjects(readyDocs, sort, order));
    }else if(status === 'draft') {
      setData(sortArrayOfObjects(draftDocs, sort, order));
    }else if(status === 'in-progress') {
      setData(sortArrayOfObjects(inProgressDocs, sort, order));
    }else if(status === 'closed') {
      setData(sortArrayOfObjects(closedDocs, sort, order));
    }else if(status === 'cancelled') {
      setData(cancelledDocs);
    }else if(status === 'expired') {
      setData(expiredDocs);
    }
    setActiveStatus(status)
    setSearch('');
    setViewTitle('Tous');
    setSelectedDocuments([]);
  }

  const documentsWithStatus = (status) => {
    let docs = []
    if(status === 'in-progress') {
      docs = inProgressDocs;
    }else if(status === 'closed') {
      docs = closedDocs;
    }else if(status === 'ready') {
      docs = readyDocs;
    }else if(status === 'draft') {
      docs = draftDocs;
    }else if(status === 'cancelled') {
      docs = cancelledDocs;
    }else if(status === 'expired') {
      docs = expiredDocs;
    }
    return docs
  }

  const statuses = canUseSignatures ? [
    'ready',
    'draft',
    'in-progress',
    'closed',
    // 'expired',
    // 'cancelled'
  ] : ['ready', 'draft']

  const renderStatusBox = (status, statusIndex) => {
    const docs = documentsWithStatus(status)
    let loading = false 
    if(status === 'ready' || status === 'draft') {
      loading = !documentsLoaded
    }else if(status === 'in-progress' || status === 'closed') {
      loading = !signaturesFetched
    }
    return (
      <DashboardBox 
        key={`status_${statusIndex}`}
        type={status} 
        title={STATUS_LABELS[status]}
        label={docs.length === 1 ? 'Document' : 'Documents'}
        number={docs.length} 
        active={activeStatus === status}
        onClick={() => dashboardBoxFilterClickHandler(status)}
        loading={loading}
      />
    )
  }

  const openDocumentDetail = (documentId) => {
    history.push({
      pathname: `/document-detail/${documentId}`
    })
  }

  const renderStatusBoxes = (slider) => {
    return statuses.map((status, statusIndex) => {
      if(slider) {
        return (
          <div key={`status_${slider ? 's' : ''}_${statusIndex}`} className="dashboard-slider__slide">
            { renderStatusBox(status, statusIndex) }
          </div>
        )
      } else {
        return renderStatusBox(status, statusIndex)
      }
    })
  }

  // Sort documents inside table
  const sortHandler = (value, order) => {
    // console.log(value, order, activeStatus)
    let copiedDocs = [...data] 
    let sortedArr = []
    if(activeStatus === 'ready' || activeStatus === 'draft') {
      sortedArr = sortArrayOfObjects(copiedDocs, value, order).filter(d => d.status === activeStatus)
    }else if(activeStatus === 'in-progress' || activeStatus === 'closed') {
      sortedArr = sortArrayOfObjects(copiedDocs, value, order)
    }
    setDocumentsArray(sortedArr)
    setData(sortedArr)
    // let copiedDocs = {...documents};
    // console.log(copiedDocs)
    // const docs = sortedArrayFromObject(copiedDocs, sorterWithPathAndOrder(value, order));
    // setDocumentsArray(docs);
    // setData(docs);
    if(activeStatus === 'ready' || activeStatus === 'draft') {
      setColumns(prev => [...prev].map(col => col.value === value ? { ...col, order, active: true} : {...col, active: false}));
      saveSortingToLS(value, order, 'documents')
    }else {
      setSignatureColumns(prev => [...prev].map(col => col.value === value ? { ...col, order, active: true} : {...col, active: false}));
      saveSortingToLS(value, order, 'signatures')
    }
  }

  // Search documents inside table
  const searchHandler = (e) => {
    e.preventDefault();
    let docs = [];
    if(activeStatus === 'in-progress') {
      docs = inProgressDocs;
    }else if(activeStatus === 'closed') {
      docs = closedDocs;
    }else if(activeStatus === 'ready') {
      docs = readyDocs;
    }else if(activeStatus === 'draft') {
      docs = draftDocs;
    }else if(activeStatus === 'cancelled') {
      docs = cancelledDocs;
    }else if(activeStatus === 'expired') {
      docs = expiredDocs;
    }
    const filteredArr = [];
    docs.forEach((doc) => {
      if(activeStatus === 'ready' || activeStatus === 'draft') {
        if(doc.name.toLowerCase().includes(search.trim().toLowerCase()) && !doc.archived && !doc.deleted) {
          filteredArr.push(doc);
        }
      }else if(activeStatus === 'in-progress' || activeStatus === 'closed') {
        if(doc.title.toLowerCase().includes(search.trim().toLowerCase())) {
          filteredArr.push(doc);
        }
      }
    })
    setData(filteredArr);
  }

  // Clear form
  const searchFormClearHandler = () => {
    let docs = [];
    if(activeStatus === 'in-progress') {
      docs = inProgressDocs;
    }else if(activeStatus === 'closed') {
      docs = closedDocs;
    }else if(activeStatus === 'ready') {
      docs = readyDocs;
    }else if(activeStatus === 'draft') {
      docs = draftDocs;
    }else if(activeStatus === 'cancelled') {
      docs = cancelledDocs;
    }else if(activeStatus === 'expired') {
      docs = expiredDocs;
    }
    setData(docs);
    setViewTitle('Tous');
  }

  const renderActivityBox = (documentActions, dai) => {
    let docId = documentActions.document
    if(!documents[docId]) {
      return null
    }
    let doc = documents[docId]
    let actions = []
    for(let i in documentActions.actions) {
      actions.push({
        name: documentActions.actions[i].author,
        status: ACTION_LABELS[documentActions.actions[i].action],
        date: moment(documentActions.actions[i].timestamp).format('DD MMMM, YYYY')
      })
    }
    return (
      <ActivityBox 
        key={`activitybox_${documentActions.document}`}
        type={doc.status} 
        status={STATUS_LABELS[doc.status]} 
        title={doc.name} 
        data={actions} 
        onClick={() => openDocumentDetail(docId)}
      />
    )
  }

  // Archive selected documents
  const archiveSelectedDocuments = async (e) => {
    e.preventDefault();
    setDashboardLoading(true);
    for(let i in selectedDocuments) {
      await update_document(selectedDocuments[i].id, { archived: true }, null);
    }
    await fetchDocuments();
    setDashboardLoading(false);
    setSelectedDocuments([]);
  }

  const fetchDocumentData = async (templateObject, document, extension) => {
    let cpConfig = coverPageConfig(document.custom_cover, agency, user, document.name, templateObject?.hasCoverPage, partner)
    let doc = await generate_document(templateObject, {...document.values, ...cpConfig.coverPageVariables}, extension, {...cpConfig.coverPageImages, ...(document.images || {})}, cpConfig.coverPageOptions)
    if(doc.error) {
      return null
    }
    return doc.data
  }

  // Download documents 
  const downloadSelectedDocuments = async (e) => {
    e.preventDefault();
    for(let i in selectedDocuments) {
      const templateObject = getTemplateById(selectedDocuments[i].template);
      let documentData = await fetchDocumentData(templateObject, selectedDocuments[i], 'pdf');
      var a = document.createElement("a"); //Create <a>
      a.href = `data:application/pdf;base64,${documentData}`; //Image Base64 Goes here
      a.download = `${selectedDocuments[i].name || 'document'}.pdf`; //File name Here
      a.click(); //Downloaded file
    }
    setSelectedDocuments([]);
  }

  // Delete documents
  const documentsDeleteHandler = async (e) => {
    e.preventDefault();
    setDashboardLoading(true);
    for(let i in selectedDocuments) {
      await update_document(selectedDocuments[i].id, { 
        deleted: true, 
        archived: false
      }, []);
    }
    await fetchDocuments();
    setDashboardLoading(false);
    setSelectedDocuments([]);
  }

  // Download signatures
  const downloadSignatureHandler = (e) => {
    e.preventDefault();
    const promises = [];
    selectedDocuments.forEach(doc => {
      promises.push(get_signature_document({ document_id: doc.document_id, package_id: doc.package_id }));
    });
    setDashboardLoading(true);
    Promise.all(promises)
      .then((data) => {
        setDashboardLoading(false);
        data.forEach((doc, i) => {
          if(doc.response.status) {
            const a = document.createElement("a");
            if(doc.response.data) {
              a.href = `data:application/pdf;base64,${doc.response.data}`;
            } else if(doc.response.url) {
              a.href = doc.response.url
            }
            a.download = `${selectedDocuments[i].title}.pdf`; 
            a.click(); 
          }
        });
      })
      .catch(err => console.log(err));
  }

  // Delete signatures
  const deleteSignaturesHandler = (e) => {
    e.preventDefault();

    const promises = [];
    const ids = [];
    selectedDocuments.forEach(doc => {
      promises.push(delete_signature(doc.id));
      ids.push(doc.id);
    });
    
    setDeleting(true);
    setSignatureIdsToDelete(ids);
    setDashboardLoading(true);
    Promise.all(promises)
      .then(() => {
        deleteSignature(ids);
        setSelectedDocuments([]);
      })
      .catch(err => {
        console.log(err);
        setDashboardLoading(false);
      });
  }

  // Download single signature doc
  const downloadSingleSignatureDocHandler = async (e, doc) => {
    e.preventDefault();
    
    setDashboardLoading(true);
    const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id });

    if(res.response.status) {
      const a = document.createElement("a");
      if(res.response.data) {
        a.href = `data:application/pdf;base64,${res.response.data}`;
      } else if(res.response.url) {
        a.href = res.response.url
      }
      a.download = `${doc.title}.pdf`; 
      a.click(); 
      setDashboardLoading(false);
    }
  }

  // Delete single signature link click handler
  const deleteSingleSignatureClickHandler = (e, doc) => {
    e.preventDefault();
    setShowDeleteSingleSignatureAlert(true);
    setSignatureToDelete(doc);
  }

  // Delete single signature
  const deleteSingleSignatureHandler = async () => {
    try {
      setDeleting(true);
      setDashboardLoading(true);
      setSignatureIdsToDelete([signatureToDelete.id]);
      await delete_signature(signatureToDelete.id);
      deleteSignature([signatureToDelete.id]);
    } catch (err) {
      console.log(err);
      setDashboardLoading(false);
    }
  }

  // Preview signature
  const previewSignatureHandler = async (doc) => {
    setPreviewLoading(true);
    setShowPreviewDocModal(true);
    try {
      const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id });
      if(res.response.status) {
        if(res.response.data) {
          setIframeSrc(`data:application/pdf;base64,${res.response.data}`);
        } else if(res.response.url) {
          setIframeSrc(res.response.url)
        }
      }else {
        setPreviewError(res.response.message.join(''));
      }
      setPreviewLoading(false);
    } catch (err) {
      setPreviewLoading(false);
      setPreviewError('Quelque chose a mal tourné. Veuillez réessayer.');
    }
  }

  // On prev button click 
  const prevButtonClickHandler = () => {
    setCurrentPage(currentPage - 1)
    let sliceFrom = currentPage - 1 === 1 ? 0 : (currentPage - 2) * perPage
    setPaginatedDocumentsData([...documentsArray].slice(sliceFrom, sliceFrom + perPage))
    setSelectedDocuments([])
  }

  // On next button click 
  const nextButtonClickHandler = () => {
    setCurrentPage(currentPage + 1)
    let sliceFrom = currentPage * perPage
    setPaginatedDocumentsData([...documentsArray].slice(sliceFrom, sliceFrom + perPage))
    setSelectedDocuments([])
  }

  // Per page change handler
  const perPageChangeHandler = (e, num) => {
    e.preventDefault()
    setPerPage(num)
    if(Math.ceil(data.length / num) < currentPage) {
      setCurrentPage(1)
    }
    window.document.body.click()
  }

  // On prev button click 
  const prevButtonClickHandlerSignatures = () => {
    setCurrentPageSignatures(currentPageSignatures - 1)
    let sliceFrom = currentPageSignatures - 1 === 1 ? 0 : (currentPageSignatures - 2) * perPageSignatures
    setPaginatedDocumentsData([...data].slice(sliceFrom, sliceFrom + perPage))
    setSelectedDocuments([])
  }

  // On next button click 
  const nextButtonClickHandlerSignatures = () => {
    setCurrentPageSignatures(currentPageSignatures + 1)
    let sliceFrom = currentPageSignatures * perPageSignatures
    setPaginatedDocumentsData([...data].slice(sliceFrom, sliceFrom + perPageSignatures))
    setSelectedDocuments([])
  }

  // Per page change handler
  const perPageChangeHandlerSignatures = (e, num) => {
    e.preventDefault()
    setPerPageSignatures(num)
    if(Math.ceil(data.length / num) < currentPageSignatures) {
      setCurrentPageSignatures(1)
    }
    window.document.body.click()
  }
  
  return(
    <div className="dashboard-page">
      {loading && <div className="overlay"></div>}
      {dashboardLoading && <div className="overlay"></div>}
      {dashboardLoading && <LineLoader />}
      <div className="dashboard-page__head">
        <h1>Tableau de bord</h1>
        <DashboardHeadMeta 
          hideSearch 
          onSearch={searchHandler} 
          onSetSearch={setSearch}
          searchValue={search}
          removeSearchClose={removeSearchClose}
          onSetRemoveSearchClose={setRemoveSearchClose}
          showSupportMenu
        />
        {loading && <LineLoader />}
      </div>
      <div className={!!activeStatus ? "dashboard-page__body filter" : "dashboard-page__body"}>
        {!activeStatus ? ( 
          <Fragment>
            <div className="dashboard-page__body_left">
              <h5>Vos documents</h5>
              <div className="dashboard-boxes-wrapper">
                <div className="dashboard-boxes-wrapper__inner">
                  { renderStatusBoxes() }
                  {/* { siteConfigsLoaded && canUseSignatures
                    ? boxesLoading || !signaturesFetched || !statusFetched ? <Loader primary small normal /> : renderStatusBoxes() 
                    : boxesLoading ? <Loader primary small normal /> : renderStatusBoxes() 
                  } */}
                  {/* { boxesLoading || !signaturesFetched ? <Loader primary small normal /> : renderStatusBoxes() } */}
                </div>
              </div>
            </div>
            {/* {partner !== 'jurisur' && <div className="dashboard-page__body_right">
              <h5>Activités</h5>
              { activitiesLoading ? <Loader normal small primary /> : documentActionsArray.map(renderActivityBox)}
            </div>} */}
          </Fragment>
        ) : (
          <Fragment>
            <div className="dashboard-slider-wrap">
              <div className="dashboard-slider">
                <div className="dashboard-slider__wrapper">
                  <div className="dashboard-slider__inner" ref={slider}>
                    { renderStatusBoxes(true) }
                  </div>
                </div>

                <div className="dashboard-slider__prev dashboard-slider__btn" onClick={sliderPrevHandler}><ChevronLeftIcon /></div>
                <div className="dashboard-slider__next dashboard-slider__btn" onClick={sliderNextHandler}><ChevronRightIcon /></div>
              </div>
            </div>

            {!loading && <Fragment>
              {(activeStatus === 'in-progress' || activeStatus === 'closed') 
                ? <>
                  <DashboardView 
                    data={paginatedDocumentsData}
                    columns={signatureColumns}
                    view="signatures"
                    selected={selectedDocuments}
                    onSetSelected={setSelectedDocuments}
                    onSort={sortHandler}
                    hideArchiveBtn
                    hideDuplicateBtn
                    hideMoveBtn
                    onDelete={() => setShowDeleteSignatureAlert(true)}
                    signatureStatuses={signaturesStatus}
                    onSearchFormClear={searchFormClearHandler}
                    onSearch={searchHandler} 
                    searchValue={search}
                    onSetSearch={setSearch}
                    onSetRemoveSearchClose={setRemoveSearchClose}
                    removeSearchClose={removeSearchClose}
                    onDownload={downloadSignatureHandler} 
                    onPreviewDoc={previewSignatureHandler}
                    onDownloadSingle={downloadSingleSignatureDocHandler} 
                    onDeleteSingle={deleteSingleSignatureClickHandler} 
                    hideCreateAndUser={true}
                    setViewTitle={setViewTitle}
                    viewTitle={viewTitle}
                    headerNotFixed
                    dashboardView
                    onStatusClick={() => {}}
                    showSupportMenu={false}
                  />

                  {signaturesPaginationReady && paginatedDocumentsData.length > 0 && <div className="pagination">
                    <ActionsDropdown
                      trigger={<button className="trigger">{perPageSignatures} <span><ExpandMore /></span></button>}
                      hideHeader
                      dropdownClass="pagination-dropdown"
                      width={120}
                    >
                      <ul>
                        {perPageOptions.map((num, i) => (
                          <li key={i}>
                            <div className="link">
                              <a href="/#" onClick={(e) => perPageChangeHandlerSignatures(e, num)}>{num}</a>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </ActionsDropdown>
                    <button onClick={prevButtonClickHandlerSignatures} disabled={currentPageSignatures === 1}><ChevronLeftIcon /></button>
                    <div className="pagination__body">page {currentPageSignatures} de {allPagesSignatures}</div>
                    <button onClick={nextButtonClickHandlerSignatures} disabled={currentPageSignatures === allPagesSignatures}><ChevronRightIcon /></button>
                  </div>}
                </>
                : <>
                  <DashboardView 
                    data={paginatedDocumentsData}
                    columns={columns}
                    view="documents"
                    hideCreateAndUser={true}
                    hideMoveBtn
                    selected={selectedDocuments}
                    onSetSelected={setSelectedDocuments}
                    onSort={sortHandler} 
                    onSearch={searchHandler}
                    onSetSearch={setSearch}
                    onSetRemoveSearchClose={setRemoveSearchClose}
                    removeSearchClose={removeSearchClose}
                    searchValue={search}
                    onSearchFormClear={searchFormClearHandler}
                    onArchive={archiveSelectedDocuments}
                    onDownload={downloadSelectedDocuments}
                    onDelete={documentsDeleteHandler}
                    signatureStatuses={signaturesStatus}
                    setViewTitle={setViewTitle}
                    viewTitle={viewTitle}
                    headerNotFixed
                    dashboardView
                    onStatusClick={() => {}}
                    showSupportMenu={false}
                  />

                  {documentsPaginationReady && paginatedDocumentsData.length > 0 && <div className="pagination">
                    <ActionsDropdown
                      trigger={<button className="trigger">{perPage} <span><ExpandMore /></span></button>}
                      hideHeader
                      dropdownClass="pagination-dropdown"
                      width={120}
                    >
                      <ul>
                        {perPageOptions.map((num, i) => (
                          <li key={i}>
                            <div className="link">
                              <a href="/#" onClick={(e) => perPageChangeHandler(e, num)}>{num}</a>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </ActionsDropdown>
                    <button onClick={prevButtonClickHandler} disabled={currentPage === 1}><ChevronLeftIcon /></button>
                    <div className="pagination__body">page {currentPage} de {allPages}</div>
                    <button onClick={nextButtonClickHandler} disabled={currentPage === allPages}><ChevronRightIcon /></button>
                  </div>}
                </>
              }
            </Fragment>}
          </Fragment>
        ) }
      </div>

      {showDeleteSignatureAlert && <Alert 
        onClose={() => setShowDeleteSignatureAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer les signatures sélectionnées ?"
        onSubmit={deleteSignaturesHandler}
        loading={deleting}
        deleteAlert
      />}
      {showDeleteSingleSignatureAlert && <Alert 
        onClose={() => setShowDeleteSingleSignatureAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer cette signature ?"
        onSubmit={deleteSingleSignatureHandler}
        loading={deleting}
        deleteAlert
      />}
      {showPreviewDocModal && <Modal onClose={() => setShowPreviewDocModal(false)} className="modal--padding-sm">
        {previewLoading ? <div className="loader-wrapper"><Loader primary normal /> </div>
          : iframeSrc ? <iframe title="Pdf" src={iframeSrc} className="iframe" /> : <p className="preview-error">{previewError}</p>
        }
      </Modal>}
    </div>
  );
}

export default Dashboard;