import React, { useState, useEffect } from 'react'
import { Check, Close } from '@material-ui/icons'

import Modal from '../../UI/Modal'
import Input from '../../UI/Input'
import CustomSelect from '../../UI/CustomSelect'
import Button from '../../UI/Button'

const AddEditNotary = ({ onClose, mode = 'add', notary }) => {
  const [roles, setRoles] = useState([
    { label: 'Maître', value: 'maitre', active: true }
  ])
  const [role, setRole] = useState('maitre')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [street, setStreet] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')

  // Prefill fields if notary set
  useEffect(() => {
    if(notary) {
      setRole(notary.role || '')
      setName(notary.name || '')
      setPhone(notary.phone || '')
      setEmail(notary.email || '')
      setStreet(notary.street || '')
      setZipCode(notary.postal_code || '')
      setCity(notary.city || '')
    }
  }, [notary])

  // On role change
  const rolesChangeHandler = (role) => {
    setRole(role)
    setRoles(prev => {
      const roles = [...prev]
      return roles.map((r) => r.value === role.value ? { ...r, active: true } : { ...r, active: false })
    });
  }

  // On form submit
  const submitHandler = (e) => {
    e.preventDefault()
    let data = { role, name, phone, email, street, zipCode, city } // keys may change, it depends if we will save notaries in our db or through immo api
    if(mode === 'add') {
      // create new notary
      console.log('create new notary', data)
    }else {
      // edit notary
      console.log('edit notary', data)
    }
  }

  return (
    <Modal onClose={onClose}>
      <div className="notaries-modal">
        <div className="notaries-modal__head notaries-modal__head--2">
          <h2>{mode === 'add' ? 'Ajouter un notaire' : 'Modifier un notaire'}</h2>
        </div>
        <div className="notaries-modal__body">
          <form className="form" onSubmit={submitHandler}>
            <div className="form__group">
              <CustomSelect 
                options={roles}
                onChange={rolesChangeHandler}
                active={roles.find(r => r.active)}
                formEl
              />
            </div>
            <div className="form__group">
              <Input value={name} onChange={(e) => setName(e.target.value)} dashboardModal label="Nom" />
              <Input value={phone} onChange={(e) => setPhone(e.target.value)} dashboardModal label="Téléphone" />
              <Input value={email} onChange={(e) => setEmail(e.target.value)} dashboardModal label="E-mail" />
            </div>
            <div className="form__group">
              <Input value={street} onChange={(e) => setStreet(e.target.value)} dashboardModal label="Rue" />
              <Input value={zipCode} onChange={(e) => setZipCode(e.target.value)} dashboardModal label="Code postal" />
              <Input value={city} onChange={(e) => setCity(e.target.value)} dashboardModal label="Ville" />
            </div>
            <div className="form__actions">
              {mode === 'add' && <Button icon={<Check />} primary text="Ajouter le notaire" medium type="submit" />}
              {mode === 'edit' && <Button icon={<Check />} primary text="Metter à jour le notaire" medium type="submit" />}
              <Button icon={<Close />} text="Annuler" danger onButtonClick={onClose} medium />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default AddEditNotary