import React, { useState, useContext } from 'react'

import { Archive, Download, Delete, Duplicate, Close, MoveToFolder, History } from '../../assets/icons'
import { useDocumentsActions, useSingleDocumentActions } from '../../hooks'
import { Alert, Checkbox, CustomTooltip } from '../ui_new'
import { MoveSelectedItemsToFolderModal } from './'
import { DocumentsFoldersContext, DocumentsContext, LoaderContext, RegisteredMailContext } from '../../context'
import { useHistory } from "react-router-dom";
import { MoreHoriz } from '@material-ui/icons'
import { Check, MoreVert } from '@mui/icons-material'

const DocumentsActions = ({ selectedDocuments, onSetSelectedDocuments, onSetAllChecked }) => {
  const { downloadDocuments, archiveDocuments, duplicateDocuments, moveDocumentsToFolder, moveDocumentsToDeletedFolder, restoreDocuments, permanentlyDeleteDocuments } = useDocumentsActions(selectedDocuments, onSetSelectedDocuments)
  const { docFolders } = useContext(DocumentsFoldersContext)
  const { docsSelectedFilters, currentFilter } = useContext(DocumentsContext)
  const { setLoading } = useContext(LoaderContext)
  const { setSendModalDefaultAttachments, setShowRegisteredMailSendModal } = useContext(RegisteredMailContext)
  const { getPreviewData } = useSingleDocumentActions()
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false)
  const [showPermanentlyDeleteAlert, setShowPremanentlyDeleteAlert] = useState(false)
  const [deleteDocumentsFrom, setDeleteDocumentsFrom] = useState('active')
  const [displayMore, setDisplayMore] = useState(false)

  const history = useHistory()

  // On documents duplicate
  const handleDocumentsDuplicate = (e) => {
    e.preventDefault()
    duplicateDocuments()
  }

  // On documents download
  const handleDocumentsDownload = async (e) => {

    e.preventDefault()
    // displayAlertModal()
    await downloadDocuments()
    // onSetAllChecked(false)
    // onSetSelectedDocuments()
  }

  // On documents archive
  const handleDocumentsArchive = (e) => {
    e.preventDefault()
    archiveDocuments()
  }

  // On documents delete
  const handleDocumentsDelete = (e) => {
    e.preventDefault()
    moveDocumentsToDeletedFolder(deleteDocumentsFrom)
  }

  // On actions close
  const handleCloseActions = () => {
    onSetSelectedDocuments([])
    onSetAllChecked(false)
  }

  // On open delete alert
  const handleOpenDeleteAlert = (e, from) => {
    e.preventDefault()
    setShowDeleteAlert(true)
    setDeleteDocumentsFrom(from)
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On open move to folder
  const handleOpenMoveToFolderModal = (e) => {
    e.preventDefault()
    setShowMoveToFolderModal(true)
  }

  // On close move to folder
  const handleCloseMoveToFolderModal = () => {
    setShowMoveToFolderModal(false)
  }

  // On documents move
  const handleDocumentsMove = (moveTo) => {
    moveDocumentsToFolder(moveTo)
    onSetAllChecked(false)
  }

  // On restore archived documents
  const handleRestoreArchivedDocs = (e) => {
    e.preventDefault()
    restoreDocuments('archived')
  }

  // On restore deleted documents
  const handleRestoreDeletedDocs = (e) => {
    e.preventDefault()
    restoreDocuments('deleted')
  }

  // On open permanently delete alert
  const handleOpenPermanentlyDeleteAlert = (e) => {
    e.preventDefault()
    setShowPremanentlyDeleteAlert(true)
  }

  // On close permanently delete alert
  const handleClosePermanentlyDeleteAlert = () => {
    setShowPremanentlyDeleteAlert(false)
  }

  // On delete permanently
  const handlePermanentlyDeleteDocuments = () => {
    permanentlyDeleteDocuments()
  }

  // On open signatures-modal
  const handleSendRegisteredMail = async (e) => {
    e.preventDefault()
    setLoading(true)
    const promises = []
    for (let doc of selectedDocuments) {
      promises.push(getPreviewData(doc))
    }
    const dataArray = await Promise.all(promises)
    const attachments = []
    for (let i in dataArray) {
      const previewData = dataArray[i]
      const buffer = Buffer.from(previewData, 'base64')
      attachments.push({ ...selectedDocuments[i], base64: previewData.split(',')[1], size: buffer.byteLength })
    }
    setSendModalDefaultAttachments(attachments)
    history.push("/registered-mail/tracking");
    setShowRegisteredMailSendModal(true)
    setLoading(false)
    // history.push("/registered-mail");
    // showRegisteredMailSendModal()
  }

  // On actions more
  const handleMoreActions = () => {
    setDisplayMore(true)
  }

  // On all checkbox click
  const handleAllChecked = () => {
    onSetAllChecked(false)
    onSetSelectedDocuments([])
  }
  
  return (
    <div className="dashboard-actions dashboard-actions--documents">
      <div className="dashboard-actions__selected" onClick={handleAllChecked}>
        <div className={`dashboard-document-box__check checked relative`} >
          <Checkbox label="" checked={true} />
        </div>
        <p>{selectedDocuments.length} Élément(s)
          <span>sélectionné(s)</span>
        </p>
      </div>
      {currentFilter === 'all' && (
        <>
          <div className={`dashboard-actions__buttons_overlay ${displayMore ? 'active' : ''}`} onClick={() => setDisplayMore(false)}></div>
          <div className={`dashboard-actions__buttons ${displayMore ? 'active' : ''}`}>
            <CustomTooltip content="Dupliquer">
              <a href="/" onClick={handleDocumentsDuplicate} className="icon-duplicate"><Duplicate /> Dupliquer</a>
            </CustomTooltip>
            <CustomTooltip content="Déplacer">
              <a href="/" onClick={handleOpenMoveToFolderModal} className="icon-move"><MoveToFolder /> Déplacer</a>
            </CustomTooltip>
            <CustomTooltip content="Télécharger">
              <a href="/" onClick={handleDocumentsDownload} className="download-icon"><Download /> Télécharger</a>
            </CustomTooltip>
            <CustomTooltip content="Archiver">
              <a href="/" onClick={handleDocumentsArchive} className="archive"><Archive /> Archiver</a>
            </CustomTooltip>
            <CustomTooltip content="Supprimer">
              <a href="/" onClick={(e) => handleOpenDeleteAlert(e, 'active')} className="icon-delete"><Delete /> Supprimer</a>
            </CustomTooltip>
            {/* <CustomTooltip content="Signatures">
            <a href="/" onClick={handleSendRegisteredMail} className="icon-signatures"><Signature /> Signatures</a>
          </CustomTooltip> */}
          </div>
        </>
      )}
      {currentFilter === 'archived' && (
        <>
          <div className={`dashboard-actions__buttons_overlay ${displayMore ? 'active' : ''}`} onClick={() => setDisplayMore(false)}></div>
          <div className={`dashboard-actions__buttons ${displayMore ? 'active' : ''}`}>
            <CustomTooltip content="Restaurer">
              <a href="/" onClick={handleRestoreArchivedDocs} className="icon-restore"><History /> Restaurer</a>
            </CustomTooltip>
            <CustomTooltip content="Supprimer">
              <a href="/" onClick={(e) => handleOpenDeleteAlert(e, 'archived')} className="icon-delete"><Delete /> Supprimer</a>
            </CustomTooltip>
          </div>
        </>

      )}
      {currentFilter === 'deleted' && (
        <>
          <div className={`dashboard-actions__buttons_overlay ${displayMore ? 'active' : ''}`} onClick={() => setDisplayMore(false)}></div>
          <div className={`dashboard-actions__buttons ${displayMore ? 'active' : ''}`}>
            <CustomTooltip content="Restaurer">
              <a href="/" onClick={handleRestoreDeletedDocs} className="icon-restore"><History /> Restaurer</a>
            </CustomTooltip>
            <CustomTooltip content="Supprimer définitivement">
              <a href="/" onClick={handleOpenPermanentlyDeleteAlert} className="icon-delete"><Delete /> Supprimer</a>
            </CustomTooltip>
          </div>
        </>

      )}
      <div className="dashboard-actions__more" onClick={handleMoreActions}>
        <MoreVert />
      </div>
      <div className="dashboard-actions__close" onClick={handleCloseActions}>
        <Close /> <span className="text">Fermer</span>
      </div>
      {showDeleteAlert && <Alert
        onClose={handleCloseDeleteAlert}
        text="Êtes-vous sûr de vouloir supprimer le(s) document(s) sélectionné(s) ?"
        onSubmit={handleDocumentsDelete}
        deleteAlert
      />}

      {showPermanentlyDeleteAlert && <Alert
        onClose={handleClosePermanentlyDeleteAlert}
        text="Êtes-vous sûr de vouloir supprimer définitivement le(s) document(s) sélectionné(s) ? "
        onSubmit={handlePermanentlyDeleteDocuments}
        deleteAlert
      />}

      {showMoveToFolderModal && (
        <MoveSelectedItemsToFolderModal
          onClose={handleCloseMoveToFolderModal}
          folders={docFolders}
          items={selectedDocuments}
          currentFolder={docsSelectedFilters.folder === 'all' ? null : docFolders.find(f => f.id === docsSelectedFilters.folder)}
          newFolderCreated={false} // remove this, improve
          onNewFolderCreated={() => { }} // remove this, improve
          onMove={handleDocumentsMove}
        />
      )}
    </div>
  )
}

export default DocumentsActions