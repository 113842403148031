import React from 'react';
import ReactDOM from 'react-dom';

const Overlay = ({ onClick = () => {}}) => {
  return ReactDOM.createPortal(
    <div className="overlay-el" onClick={onClick}></div>,
    document.getElementById('modal-root')
  );
}

export default Overlay;