import { memo, useCallback } from 'react'
import { bytesToSize } from '../../utils'

import { Checkbox } from '../ui_new'

const SingleDocumentSignAttachments = memo(({ attachments, attachmentChangeHandler, signaturePlaced, fileSize }) => {

  const getTotalSizeOfSelectedDocuments = useCallback(() => {
    let total = 1
    for(let i = 0; i < attachments.length; i++) {
      if(attachments[i].sign && attachments[i].size) {
        total += attachments[i].size
      }
    }
    total += fileSize
    return total 
  }, [attachments, fileSize])

  return (
    <div className="share-attachments-wrapper-v2">
      <div className="share-attachments-wrapper-v2__box">
        { attachments.map((att, i) => {
          return (
            <div className="share-attachments-wrapper-v2__box_item" key={i}>
              <Checkbox 
                onChange={(e) => attachmentChangeHandler(i, e.target.checked)} 
                checked={!!att.sign} 
                disabled={signaturePlaced}
                label={`${att.name} (${att.type})`}
              />
            </div>
          )
        })}
        {attachments.length === 0 && <p>Aucune annexe associée au document</p>}
      </div>
      {signaturePlaced && <p className="attachments-disabled-msg">Veuillez supprimer les demandes de signatures avant d'ajouter/supprimer des annexes</p>}
      <div className="attachments-size-wrapper">
        <p className="attachments-size-msg">Taille totale actuelle: <span>{bytesToSize(getTotalSizeOfSelectedDocuments())}</span></p>
        <p className="attachments-size-msg">Taille maximale autorisée: <span>16MB</span></p>
      </div>
    </div>
  )
})

export default SingleDocumentSignAttachments