import React, { createContext, useReducer, useRef, useState } from 'react';

import loaderReducer from './loaderReducer';
import { SET_LOADING } from '../types';
import Loader from '../../components/UI/Loader';

export const LoaderContext = createContext();

const LoaderState = ({ children }) => {
  const initialState = {
    loading: false
  }

  const [showDashboardOverlay, setShowDashboardOverlay] = useState(false);
  const [globalResponseLoader, setGlobalResponseLoader] = useState(false)
  const [globalResponseLoaderTimed, setGlobalResponseLoaderTimed] = useState(false)
  const [globalResponseLoaderText, setGlobalResponseLoaderText] = useState('')
  const [state, dispatch] = useReducer(loaderReducer, initialState);

  const globalLoaderTimeoutRef = useRef(null)

  const setShowGlobalResponseLoader = (showLoader, minimalDuration = 0) => {
    if (showLoader) {
      setGlobalResponseLoader(true)
      if(globalLoaderTimeoutRef.current) clearTimeout(globalLoaderTimeoutRef.current)
      if(minimalDuration > 0) {
        setGlobalResponseLoaderTimed(true)
        globalLoaderTimeoutRef.current = setTimeout(() => {
          setGlobalResponseLoaderTimed(false)
        }, minimalDuration)
      }
    } else {
      setGlobalResponseLoader(false)
    }    
  }

  const setLoading = (value) => {
    dispatch({
      type: SET_LOADING,
      payload: value
    });
  }

  return <LoaderContext.Provider value={{
    loading: state.loading,
    showDashboardOverlay,
    setLoading,
    setShowDashboardOverlay,
    showGlobalResponseLoader: globalResponseLoader || globalResponseLoaderTimed,
    setShowGlobalResponseLoader,
    globalResponseLoaderText,
    setGlobalResponseLoaderText,
  }}>
    {children}
    { state.loading && <div className='fullscreen-loading-wrapper'><Loader /></div> }
  </LoaderContext.Provider>
}

export default LoaderState;