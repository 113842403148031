const UpdateTemplateSection = ({ didSignatureTemplateChanged, updateSignatureTemplateHandler, setShowSaveUpdatedTemplateModal }) => {
  const changes = didSignatureTemplateChanged.changes
  const changesMade = [...changes.checkbox_positions, ...changes.signatures_box].length
  let changesMadeText = ''
  if(changes.checkbox_positions.length > 0) {
    changesMadeText += `${changes.checkbox_positions.length} case à chocher modifiée(s)`
  }
  if(changes.signatures_box.length > 0) {
    let text = `${changes.signatures_box.length} emplacement(s) de signature modifié(s)`
    if(changesMadeText !== '') {
      changesMadeText += `, ${text}`
    }else {
      changesMadeText += `${text}`
    }
  }

  return (
    <div className="signature-modal-v2-inner__section signature-modal-v2__template">
      <p className="signature-form__subtitle signature-form__subtitle--3">{changesMade} emplacement a été ajouté au modèle de signature ({changesMadeText})</p>
      <div className="signature-modal-v2__template_update">
        <button className="btn" type="button" onClick={updateSignatureTemplateHandler}>Mettre à jour le modèle existant</button>
        <button className="btn" type="button" onClick={() => setShowSaveUpdatedTemplateModal(true)}>Créer un nouveau modèle</button>
      </div>
    </div>
  );
}

export default UpdateTemplateSection