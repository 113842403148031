const FileOpen = () => {
  return (
    <span className="svg-icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3203 21.8145H4.32031C3.22031 21.8145 2.32031 20.9145 2.32031 19.8145V3.81445C2.32031 2.71445 3.22031 1.81445 4.32031 1.81445H12.3203L18.3203 7.81445V13.8145H16.3203V8.81445H11.3203V3.81445H4.32031V19.8145H13.3203V21.8145ZM17.3203 21.4745V19.2345L20.2703 22.1845L21.6803 20.7745L18.7303 17.8145H20.9703V15.8145H15.3203V21.4745H17.3203Z" fill="#482982" />
      </svg>
    </span>
  )
}

export default FileOpen