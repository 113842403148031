import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import BussinessIcon from '@material-ui/icons/Business';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum'
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import AppsIcon from '@material-ui/icons/Apps';
// import PalleteIcon from '@material-ui/icons/Palette';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
// import PeopleIcon from '@material-ui/icons/People';
// import SettingsIcon from '@material-ui/icons/Settings';
// import MessageIcon from '@material-ui/icons/Message';
import { UserContext } from '../../../context/user/userState';
import { FEATURE, isFeatureEnabled } from '../../../utils';

const SettingsSidebar = () => {

  const { user, partner } = useContext(UserContext);

  return(
    <div className="settings-sidebar">
      <h3>Paramètres</h3>
      <section>
        <h6>général</h6>
        <ul>
          <li><NavLink to="/settings/my-profile"><PersonIcon /> Profil</NavLink></li>
          <li><NavLink to="/settings/agency"><BussinessIcon /> Agences</NavLink></li>
          
          { isFeatureEnabled(FEATURE.COVER_PAGE_SETTINGS, [], partner, user) && <li><NavLink to="/settings/cover-page"><PhotoAlbumIcon /> Page de couverture</NavLink></li> }
          {/* <li><NavLink to="/settings/billing"><AccountBalanceWalletIcon /> Billing</NavLink></li>
          <li><NavLink to="/settings/workspaces"><AppsIcon /> Workspaces</NavLink></li> */}
        </ul>
      </section>
      {/* <section>
        <h6>Réglages</h6>
        <ul>
          <li><NavLink to="/settings/branding"><PalleteIcon /> Branding</NavLink></li>
          <li><NavLink to="/settings/payment-gateways"><MonetizationOnIcon /> Payment gateways</NavLink></li>
          <li><NavLink to="/settings/integrations"><SettingsEthernetIcon /> Integrations</NavLink></li>
          <li><NavLink to="/settings/team"><PeopleIcon /> Team</NavLink></li>
          <li><NavLink to="/settings" exact><SettingsIcon /> Settings</NavLink></li>
          <li><NavLink to="/settings/saved-messages"><MessageIcon /> Saved Messages</NavLink></li>
        </ul>
      </section> */}
    </div>
  );
}

export default SettingsSidebar;