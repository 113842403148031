import React, {
    useState,
    Fragment,
    useRef,
    useCallback,
    useEffect,
    useContext,
} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import PersonIcon from '@material-ui/icons/Person'
import CancelIcon from '@material-ui/icons/Cancel'

import Input from '../UI/Input'
import Button from '../UI/Button'
import DocumentCreateModal from './documents/DocumentCreateModal'
import CreateEditFolderModal from './CreateEditFolderModal'
import Alert from '../UI/Alert'
import Overlay from '../UI/Overlay'
import { FolderContext } from '../../context/folders/folderState'
import { LoaderContext } from '../../context/loader/loaderState'
import { SEARCH_HISTORY_LS_KEY } from '../../constants'
import SupportMenu from './dashboard/SupportMenu'
import { UserContext } from '../../context'

const DashboardHeadMeta = ({
    hideSearch,
    hideCreateAndUser,
    onSearch,
    searchValue,
    onSetSearch,
    removeSearchClose,
    onSetRemoveSearchClose,
    onSearchFormClear,
    setViewTitle,
    onSearchSuggestionsChange = () => {},
    showSupportMenu = true,
}) => {
    const [showSearchForm, setShowSearchForm] = useState(false)
    const [showCreateDropdown, setShowCreateDropdown] = useState(false)
    const [showDocumentCreateModal, setShowDocumentCreateModal] =
        useState(false)
    const {
        folders,
        foldersLoading,
        createFolder,
        updateFolder,
        deleteFolder,
        foldersFetched,
    } = useContext(FolderContext)
    const { setShowDashboardOverlay, showDashboardOverlay } =
        useContext(LoaderContext)
    const { partner } = useContext(UserContext)
    const [currentFolder, setCurrentFolder] = useState(null)
    const [mode, setMode] = useState('create')
    const [showFolderModal, setShowFolderModal] = useState(false)
    const [showDeleteFolderAlert, setShowDeleteFolderAlert] =
        useState(false)
    const [showFormClose, setShowFormClose] = useState(false)
    const [formWider, setFormWider] = useState(false)
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [suggestionsArr, setSuggestionsArr] = useState(
        localStorage.getItem(SEARCH_HISTORY_LS_KEY)
            ? JSON.parse(localStorage.getItem(SEARCH_HISTORY_LS_KEY))
            : []
    )
    const [showOverlay, setShowOverlay] = useState(false)
    const createDropdownEl = useRef()
    const formBtnEl = useRef()
    const formEl = useRef()

    // useEffect(() => {
    //   if(!foldersFetched) {
    //     fetchFolders();
    //   }
    // }, [foldersFetched, fetchFolders]);

    useEffect(() => {
        if (removeSearchClose) {
            setShowFormClose(false)
            onSetRemoveSearchClose(false)
            setFormWider(false)
        }
    }, [removeSearchClose, onSetRemoveSearchClose])

    useEffect(() => {
        if (!showDashboardOverlay) {
            setShowSuggestions(false)
            if (searchValue === '') {
                setFormWider(false)
            }
        }
    }, [showDashboardOverlay])

    const handleClickOutside = useCallback(
        (e) => {
            if (
                showCreateDropdown &&
                e.target.closest('.create ul') !== createDropdownEl.current
            ) {
                setShowCreateDropdown(!showCreateDropdown)
            }
            if (e.target.closest('.search-form-el') !== formEl.current) {
                if (!showSuggestions && searchValue === '') {
                    setFormWider(false)
                    setShowOverlay(false)
                }
            }
        },
        [
            showCreateDropdown,
            setShowCreateDropdown,
            searchValue,
            showSuggestions,
        ]
    )

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [handleClickOutside])

    useEffect(() => {
        onSearchSuggestionsChange(showSuggestions)
    }, [showSuggestions, onSearchSuggestionsChange])

    const onSearchInputChange = (e) => {
        onSetSearch(e.target.value)
        if (e.target.value !== '') {
            if (!showFormClose) {
                setShowFormClose(true)
            }
        } else {
            // setShowFormClose(false);
        }
    }

    const clearFormHandler = () => {
        onSetSearch('')
        setShowFormClose(false)
        setFormWider(false)
        onSearchFormClear()
        setShowSuggestions(false)
        setViewTitle('Tous')
        setShowDashboardOverlay(false)
        setShowOverlay(false)
    }

    const folderCreateClickHandler = async () => {
        setShowDocumentCreateModal(false)
        setMode('create')
        setCurrentFolder(null)
        setShowFolderModal(true)
    }

    const folderEditClickHandler = async (folder) => {
        setShowDocumentCreateModal(false)
        setCurrentFolder(folder)
        setMode('edit')
        setShowFolderModal(true)
    }

    const folderDeleteClickHandler = (folder) => {
        setShowDocumentCreateModal(false)
        setCurrentFolder(folder)
        setShowDeleteFolderAlert(true)
    }

    const createFolderHandler = async (data) => {
        await createFolder(data)
        setShowFolderModal(false)
    }

    const updateFolderHandler = async (data, id) => {
        await updateFolder(data, id)
        setShowFolderModal(false)
        setCurrentFolder(null)
    }

    const deleteFolderHandler = async () => {
        await deleteFolder(currentFolder.id)
        setShowDeleteFolderAlert(false)
        setCurrentFolder(null)
    }

    const searchBlurHandler = (e) => {
        e.target.placeholder = 'Rechercher'
    }

    const searchFocusHandler = (e) => {
        e.target.placeholder = ''
        setFormWider(true)
        if (formWider) {
            setShowSuggestions(true)
        } else {
            setTimeout(() => {
                setShowSuggestions(true)
                setShowDashboardOverlay(true)
                setShowOverlay(true)
            }, 300)
        }
    }

    const searchTermClick = (e, term) => {
        e.preventDefault()
        onSetSearch(term)
        setShowSuggestions(false)
        setTimeout(() => {
            formBtnEl.current.click()
        }, 50)
    }

    const searchFormSubmitHandler = (e) => {
        onSearch(e)
        const suggestions = localStorage.getItem(SEARCH_HISTORY_LS_KEY)
            ? JSON.parse(localStorage.getItem(SEARCH_HISTORY_LS_KEY))
            : []
        if (searchValue !== '') {
            if (!suggestions.includes(searchValue)) {
                suggestions.unshift(searchValue)
            } else {
                const index = suggestions.indexOf(searchValue)
                suggestions.splice(index, 1)
                suggestions.unshift(searchValue)
            }
            setViewTitle(`Résultats de recherche pour "${searchValue}"`)
            localStorage.setItem(
                SEARCH_HISTORY_LS_KEY,
                JSON.stringify(suggestions.slice(0, 6))
            )
        }
        setSuggestionsArr(suggestions)
        setShowOverlay(false)
        setShowSuggestions(false)
        setFormWider(false)
    }

    const deleteSearchHistory = (e) => {
        e.preventDefault()
        localStorage.removeItem(SEARCH_HISTORY_LS_KEY)
        setSuggestionsArr([])
    }

    return (
        <div
            className={
                hideCreateAndUser
                    ? 'dashboard-head-meta dashboard-head-meta--form'
                    : 'dashboard-head-meta'
            }
        >
            {showOverlay && (
                <div className="dashboard-head-meta__overlay"></div>
            )}
            {!hideSearch && (
                <div
                    className="search-small"
                    onClick={() => setShowSearchForm(true)}
                >
                    <SearchIcon />
                </div>
            )}
            {!hideSearch && showSearchForm && (
                <div className="search-wrap">
                    <form onSubmit={searchFormSubmitHandler}>
                        <Input
                            name="search"
                            placeholder="Rechercher"
                            value={searchValue}
                            onChange={onSearchInputChange}
                            iconEl={<SearchIcon />}
                        />
                        {formWider && (
                            <div
                                className="close"
                                onClick={clearFormHandler}
                            >
                                <CloseIcon />
                            </div>
                        )}
                    </form>
                    <span
                        className="search-wrap__close"
                        onClick={() => setShowSearchForm(false)}
                    >
                        <CloseIcon />
                    </span>
                </div>
            )}
            {!hideSearch && (
                <form
                    onSubmit={searchFormSubmitHandler}
                    className={
                        formWider
                            ? 'wider search-form-el'
                            : 'search-form-el'
                    }
                    ref={formEl}
                >
                    <Input
                        name="search"
                        placeholder="Rechercher"
                        value={searchValue}
                        onChange={onSearchInputChange}
                        iconEl={
                            <SearchIcon
                                className={showSuggestions ? 'hidden' : ''}
                            />
                        }
                        alternativeIcon={
                            <CancelIcon
                                className={!showSuggestions ? 'hidden' : ''}
                            />
                        }
                        onFocus={searchFocusHandler}
                        onBlur={searchBlurHandler}
                        label={false}
                        onIconLabelClick={() =>
                            formWider
                                ? setShowSuggestions(!showSuggestions)
                                : {}
                        }
                    />
                    {formWider && showSuggestions && (
                        <div className="suggestions">
                            {suggestionsArr.length > 0 && (
                                <p>
                                    Votre historique de recherche{' '}
                                    <a
                                        href="/#"
                                        onClick={deleteSearchHistory}
                                    >
                                        Effacer
                                    </a>
                                </p>
                            )}
                            {suggestionsArr.length > 0 ? (
                                <ul className="suggestions__list">
                                    {[...suggestionsArr]
                                        .filter((h, i) => i < 6)
                                        .map((h, i) => (
                                            <li key={i}>
                                                <SearchIcon />{' '}
                                                <a
                                                    href="/"
                                                    onClick={(e) =>
                                                        searchTermClick(
                                                            e,
                                                            h
                                                        )
                                                    }
                                                >
                                                    {h}
                                                </a>
                                            </li>
                                        ))}
                                </ul>
                            ) : (
                                <p>Pas d'historique de recherche</p>
                            )}
                        </div>
                    )}
                    {formWider && (
                        <div className="close" onClick={clearFormHandler}>
                            <CloseIcon />
                        </div>
                    )}
                    <button
                        style={{ display: 'none' }}
                        ref={formBtnEl}
                        type="submit"
                    ></button>
                </form>
            )}

            {/* Support menu */}
            {showSupportMenu && partner && !partner.includes('mls') && (
                <SupportMenu />
            )}

            {!hideCreateAndUser && (
                <Fragment>
                    <div className="create">
                        <Button
                            text="Nouveau Document"
                            icon={<AddIcon />}
                            primary
                            bold
                            onButtonClick={() =>
                                setShowDocumentCreateModal(true)
                            }
                            smallerIconMargin
                        />
                    </div>
                    {/* <Link className="user" to="/settings/my-profile">
            <PersonIcon />
          </Link> */}

                    {showDocumentCreateModal && (
                        <DocumentCreateModal
                            onClose={() =>
                                setShowDocumentCreateModal(false)
                            }
                            folders={folders}
                            onFolderCreate={folderCreateClickHandler}
                            onFolderDelete={folderDeleteClickHandler}
                            onFolderEdit={folderEditClickHandler}
                        />
                    )}
                </Fragment>
            )}

            {showFolderModal && (
                <CreateEditFolderModal
                    onClose={() => setShowFolderModal(false)}
                    onCreate={createFolderHandler}
                    onEdit={updateFolderHandler}
                    foldersLoading={foldersLoading}
                    currentFolder={currentFolder}
                    mode={mode}
                />
            )}
            {showDeleteFolderAlert && (
                <Alert
                    onClose={() => setShowDeleteFolderAlert(false)}
                    text="Etes-vous certain de vouloir supprimer ce dossier?"
                    onSubmit={deleteFolderHandler}
                    loading={foldersLoading}
                    deleteAlert
                />
            )}
        </div>
    )
}

DashboardHeadMeta.propTypes = {
    hideSearch: PropTypes.bool,
    hideCreateAndUser: PropTypes.bool,
    onSearch: PropTypes.func,
}

export default DashboardHeadMeta
