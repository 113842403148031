import React, { useRef } from 'react';
import moment from 'moment';

import CustomTooltip from './CustomTooltip';
import { isOverflown } from '../../utils';

const SPVariableListItem = ({ onItemClick, variable, value, activeVariable, isVarDate, displayName, idx, linesAddedTo }) => {
  const variableKeyEl = useRef(); 
  const variableTextEl = useRef(); 

  // console.log("displayName", displayName)

  return(
    <li onClick={() => onItemClick(idx)} className={idx === activeVariable ? 'active' : ''} data-id={idx}>
      <CustomTooltip content={displayName || variable} position="top-left" fixed hideTooltip={!isOverflown(variableKeyEl.current)}>
        <p ref={variableKeyEl}>{displayName || variable}</p>
      </CustomTooltip>
      {(value && isVarDate && moment(value, moment.ISO_8601, true).isValid()) 
        ? <p className="input-value">{moment(value).format('DD MMM YYYY') }</p>
        : Array.isArray(value) 
          ? <p className="input-value">
            {value.map((v, i) => <SPVariableListSpanEl key={i} value={v} /> ) }
            </p>
          : value 
            ? <CustomTooltip content={value} position="top-right" fixed hideTooltip={!isOverflown(variableTextEl.current)}>
                <p className="input-value" ref={variableTextEl}>{value ? linesAddedTo.find(v => v.variable === variable) ? ' ' : value : ' '}</p>
              </CustomTooltip>
            : <p className="input-value">{value ? linesAddedTo.find(v => v.variable === variable) ? ' ' : value : ' '}</p> }
    </li>
  );
}

const SPVariableListSpanEl = ({ value }) => {
  const spanEl = useRef();

  const output = <span ref={spanEl}>{value}</span>;

  return isOverflown(spanEl.current) ? <CustomTooltip content={value} fixed position="top-right">{output}</CustomTooltip> : output; 
}

export default SPVariableListItem;