import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/user/userState';
import Checkbox from '../../UI/Checkbox';
import FileUpload from '../../UI/FileUpload';

import Input from '../../UI/Input';
import DocumentSection from './DocumentSection';
import DocumentSectionCopy from './DocumentSectionCopy';
import Variables from './Variables';
import VariablesCopy from './VariablesCopy';
import config from '../../../config.json'
import { FEATURE, isCoverPageVariable, isFeatureEnabled } from '../../../utils';


const DocumentDetailVariables = ({ documentValues, onValuesChange, useCustomCover, onCustomCoverChange, templateData, type = '', onAgencyModalOpen, onNotariesModalOpen, linesAddedTo, onRemoveLines, onSetLinesAddedTo, documentCheckboxValues, onCheckboxValueChange, locked }) => {
  const { partner, agency, user } = useContext(UserContext)

  if(!templateData) {
    return null
  }

  const shouldShowCustomCoverToggle = () => {
    
    let hasCoverPage = false
    for(let section of templateData.sections) {
      if(section.variable) {
        if(isCoverPageVariable(section.variable)) {
          hasCoverPage = true
          break
        }
      } else if(section.variables) {
        for(let v of section.variables) {
          if(isCoverPageVariable(v.variable)) {
            hasCoverPage = true
            break
          }
        }
      }
      if(hasCoverPage) {
        break
      }
    }
    return hasCoverPage
  }

  return(
    <div className="document-detail-variables">
      {/* <div className="document-detail-variables__head">
        <div className="document-detail-variables__head_top">
          <h2>Politique d’utilisation des</h2>
          <Input 
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            name="title"
            editMode
          />
        </div>
        <h3>Entre les soussignés</h3>
      </div> */}

      { shouldShowCustomCoverToggle() ? (
        <div className="row v-centered mb-20">
          <Checkbox checked={useCustomCover} onChange={onCustomCoverChange} />
          <span className="ml-5">Utilisez la page de couverture personnalisée</span>
        </div> ) : null }

      <div className="document-detail-variables__body">
        { templateData.sections.map((section, sectionIndex) => {
          // console.log(section, section.variable);
          return <DocumentSectionCopy key={sectionIndex} values={documentValues} onValuesChange={onValuesChange} documentCheckboxValues={documentCheckboxValues} onCheckboxValueChange={onCheckboxValueChange} sections={section.sections} type={section.type} content={section.content} items={section.items} question={section.question} variable={section.variable} variables={section.variables} data_type={section.data_type} options={section.options} condition={section.condition} conditions={section.conditions} tooltip={section.tooltip} index={sectionIndex} varIndex={section.idx} repeatableSectionId={section.repeatable_section_id} head={section.head} row={section.row} repeatableRowId={section.repeatableRowId} locked={locked || section.locked} onAgencyModalOpen={onAgencyModalOpen} onNotariesModalOpen={onNotariesModalOpen} linesAddedTo={linesAddedTo} onRemoveLines={onRemoveLines} onSetLinesAddedTo={onSetLinesAddedTo} />
        })}
        {/* {type !== 'new' 
          ? <Variables documentValues={documentValues} onValuesChange={d => onValuesChange(d)} title={templateData.title} mainHeadTitle={templateData.mainHeadTitle} mainText={templateData.mainText} data={templateData.data} />
          : 
          templateData['sections'].map((data, index) => (
            <VariablesCopy 
              key={index} 
              documentValues={documentValues} 
              onValuesChange={(d, handle, val, sectionId, choicesName) => onValuesChange(d, handle, val, sectionId, choicesName)} 
              title={data.name} 
              mainHeadTitle={data.name} 
              data={data}
            />
          ))
          
          } */}
      
        {/* {dummyData.map((variable, index) => (
          <Variables documentValues={documentValues} onValuesChange={d => onValuesChange(d)} key={index} title={variable.title} mainHeadTitle={variable.mainHeadTitle} mainText={variable.mainText} data={variable.data} />
        ))} */}
      </div>
      {templateData.footer && <div className="document-detail-variables__foot">
        <h4><span>Pied de page</span></h4>
        <DocumentSectionCopy values={documentValues} onValuesChange={onValuesChange} documentCheckboxValues={documentCheckboxValues} onCheckboxValueChange={onCheckboxValueChange} sections={[templateData.footer]} type={templateData.footer.type || 'text'} content={templateData.footer.content} items={templateData.footer.items || []} question={templateData.footer.question} variable={templateData.footer.variable} variables={templateData.footer.variables} data_type={templateData.footer.data_type} options={templateData.footer.options} condition={templateData.footer.condition} conditions={templateData.footer.conditions} tooltip={templateData.footer.tooltip}  locked={locked || templateData.footer.locked} index={'footer'} varIndex={''} repeatableSectionId={templateData.footer.repeatable_section_id} onAgencyModalOpen={onAgencyModalOpen} onNotariesModalOpen={onNotariesModalOpen} linesAddedTo={linesAddedTo} onRemoveLines={onRemoveLines} onSetLinesAddedTo={onSetLinesAddedTo} />
      </div>}
    </div>
  );
}

export default DocumentDetailVariables;