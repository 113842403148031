import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';

import { SET_TEMPLATES, SET_DOCUMENTS, SET_FETCH_DOCUMENTS, SET_FILTERED_DOCUMENTS, SET_SHOW_FILTERED_DOCS, SET_CURRENT_FILTER, SET_ARCHIVED_DOCUMENTS, SET_DELETED_DOCUMENTS, SET_ACTIVE_FILTER_COLUMN, SET_DATE_FILTERS, SET_FILTERED_TEMPLATES, SET_TEMPLATES_LOADING, SET_ACTIVE_VARS_SECTION, SET_ACTIONS, SET_FETCH_ACTIONS, SET_SHOW_FILTERED_TMPLTS, SET_STANDARD_TEMPLATES, SET_STANDARD_TEMPLATES_LOADING } from '../types';
import documentsReducer from './documentsReducer';
import { update_template, fetch_templates, update_document, fetch_single_document, fetch_single_template, create_document, filter_documents, delete_template, create_standard_template, update_standard_template, delete_standard_template, fetch_documents, fetch_standard_templates } from '../../services/firestore';
import { getSorting } from '../../utils'
import { UserContext } from '../user/userState';
import { LoaderContext } from '../loader/loaderState';
import { NotificationContext } from '../notifications/notificationState';

export const DocumentsContext = createContext();

const DocumentsState = ({ children }) => {
  const initialState = {
    templates: {},
    documents: {},
    actions: {},
    actionsFetched: false,
    archivedDocuments: {},
    deletedDocuments: {},
    filteredDocuments: {},
    fetchActions: () => {},
    showFilteredDocs: false,
    currentFilter: 'all',
    activeFilterColumn: '',
    dateFilters: { after: '', before: '' },
    filteredTemplates: {},
    templatesLoading: false,
    activeVarsSection: '',
    templatesLoaded: false,
    documentsLoaded: false,
    showFilteredTemplates: false,
    standardTemplates: {},
    standardTemplatesLoading: false,
    standardTemplatesLoaded: false
  }

  const [lastReloadTime, setLastReloadTime] = useState(Date.now())
  const [copiedAttachments, setCopiedAttachments] = useState([]);
  const [attachmentsFrom, setAttachmentsFrom] = useState(null);
  const [docSearch, setDocSearch] = useState('')
  const [docsSelectedFilters, setDocsSelectedFilters] = useState({
    status: 'all',
    date_after: '',
    date_before: '',
    folder: 'all',
    search: '',
    owner: 'all',
    author: 'all',
  })
  const [templatesSelectedFilters, setTemplatesSelectedFilters] = useState({
    folder: 'all',
    date_after: '',
    date_before: '',
    search: ''
  })

  const [standardTemplatesSelectedFilters, setStandardTemplatesSelectedFilters] = useState({
    folder: 'all',
    date_after: '',
    date_before: '',
    search: ''
  })
  
  const [archivedDocsFetched, setArchivedDocsFetched] = useState(false)
  const [deletedDocsFetched, setDeletedDocsFetched] = useState(false)

  const [templatesActiveSort, setTemplatesActiveSort] = useState({ 'name': 'asc' , activeItem: 'name'})
  const [standardTemplatesActiveSort, setStandardTemplatesActiveSort] = useState({ 'name': 'asc' , activeItem: 'name'})
  const [documentsActiveSort, setDocumentsActiveSort] = useState({ 'meta.updated': 'desc' , activeItem: 'meta.updated'})

  const [state, dispatch] = useReducer(documentsReducer, initialState);
  
  const [dashboardSearch, setDashboardSearch] = useState('')

  const { userClaims } = useContext(UserContext)

  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)

  const setTemplates = (value) => {
    dispatch({
      type: SET_TEMPLATES,
      payload: value
    });
  }

  const setDocuments = (value) => {
    dispatch({
      type: SET_DOCUMENTS,
      payload: value
    });
  }

  const setStandardTemplates = (value) => {
    dispatch({
      type: SET_STANDARD_TEMPLATES,
      payload: value
    });
  }

  const fetchDocuments = async (exclude, doc) => {
    let t = await fetch_documents(exclude)
    if(!t || t.error) {
      console.log('fetch documents error', t?.error)
      return
    }
    setDocuments(doc ? { [doc.id]: doc, ...t } : t)
  }

  const fetchMoreDocuments = async () => {
    setShowGlobalResponseLoader(true)
    const oldestDocPerAgency = {}
    for(let id in state.documents) {
      const doc = state.documents[id]
      if(!oldestDocPerAgency[doc.owner]) {
        oldestDocPerAgency[doc.owner] = doc.meta.updated
      } else {
        if(doc.meta.updated < oldestDocPerAgency[doc.owner]) {
          oldestDocPerAgency[doc.owner] = doc.meta.updated
        }
      }
    }
    const oldestDocPerAgencyEncoded = Object.keys(oldestDocPerAgency).map(key => `${key}:${oldestDocPerAgency[key]}`).join(',')
    const t = await fetch_documents(null, oldestDocPerAgencyEncoded)
    if(t.error) {
      console.log('fetch documents error', t.error)
      setNotification({ type: 'danger', msg: 'Erreur lors du chargement des documents' })
      setShowGlobalResponseLoader(false)
      return
    }
    setDocuments({ ...state.documents, ...t })
    setShowGlobalResponseLoader(false)
  }

  const setActions = (value) => {
    dispatch({
      type: SET_ACTIONS,
      payload: value
    })
  }

  const setFetchActions = (value) => {
    dispatch({
      type: SET_FETCH_ACTIONS,
      payload: value
    })
  }

  const setArchivedDocuments = (value) => {
    dispatch({
      type: SET_ARCHIVED_DOCUMENTS,
      payload: value
    });
  }

  const setDeletedDocuments = (value) => {
    dispatch({
      type: SET_DELETED_DOCUMENTS,
      payload: value
    });
  }

  const setFilteredDocuments = (value) => {
    dispatch({
      type: SET_FILTERED_DOCUMENTS,
      payload: value
    });
  }

  const setShowFilteredDocs = (value) => {
    dispatch({
      type: SET_SHOW_FILTERED_DOCS,
      payload: value
    });
  }

  const setCurrentFilter = (filter) => {
    dispatch({
      type: SET_CURRENT_FILTER,
      payload: filter
    });
  }

  const setActiveFilterColumn = (filter) => {
    dispatch({
      type: SET_ACTIVE_FILTER_COLUMN,
      payload: filter
    });
  }

  const setDateFilters = (value) => {
    dispatch({
      type: SET_DATE_FILTERS,
      payload: value
    });
  }

  const setFilteredTemplates = (value) => {
    dispatch({
      type: SET_FILTERED_TEMPLATES,
      payload: value
    });
  }

  const setTemplatesLoading = (value) => {
    dispatch({
      type: SET_TEMPLATES_LOADING,
      payload: value
    });
  }

  const setStandardTemplatesLoading = (value) => {
    dispatch({
      type: SET_STANDARD_TEMPLATES_LOADING,
      payload: value
    });
  }

  const setShowFilteredTemplates = (value) => {
    dispatch({
      type: SET_SHOW_FILTERED_TMPLTS,
      payload: value
    });
  }

  // Get single document
  const getSingleDocument = async (id, set = false, cb = () => {}) => {
    try {
      const res = await fetch_single_document(id)
      if(set) {
        setDocuments({ ...state.documents, [id]: res })
        if(res.template) {
          const tmplRes = await fetch_single_template(res.template)
          setTemplates({...state.templates, [res.template]: tmplRes })
          cb(tmplRes)
        }
      }
      return res
    } catch (err) {
      console.log(err)
    }
  }

  // Get single template
  const getSingleTemplate = async (id, set = false) => {
    try {
      const res = await fetch_single_template(id)
      if(set) {
        setTemplates({ ...state.templates, [id]: res })
      }
      return res
    } catch (err) {
      console.log(err)
    }
  }

  // Get templates
  const getTemplates = async () => {
    setTemplatesLoading(true);
    try {
      const templates = await fetch_templates();
      setTemplatesLoading(false);
      setTemplates(templates);
    }catch(err) {
      console.log(err);
      setTemplatesLoading(false);
    }
  }

  // Update templates
  const updateTemplate = async (data, id, single = true, loadTemplates = true) => {
    setTemplatesLoading(true);
    try {
      await update_template(data, id);
      if(single) {
        const t = {...state.templates};
        t[id] = {...t[id], ...data};
        setTemplates(t);
      }
      // if(loadTemplates) {
      //   await getTemplates();
      // }
      setTemplatesLoading(false);
    }catch(err) {
      console.log(err); 
      setTemplatesLoading(false);
    }
  } 

  // Delete templates
  const deleteTemplate = async (id) => {
    setTemplatesLoading(true);
    try {
      await delete_template(id);
      const t = {...state.templates};
      delete t[id]
      setTemplates(t);
      setTemplatesLoading(false);
    }catch(err) {
      console.log(err); 
      setTemplatesLoading(false);
    }
  }

  const getStandardTemplates = async () => {
    setStandardTemplatesLoading(true);
    try {
      const templates = await fetch_standard_templates();
      setStandardTemplates(templates)
      setStandardTemplatesLoading(false);
    }catch(err) {
      console.log(err);
      setStandardTemplatesLoading(false);
    }
  }

  const updateStandardTemplate = async (data, id) => {
    try {
      await update_standard_template(id, data);
      if(!data.meta) data.meta = {}
      data.meta.updated = Date.now()
      const t = {...state.standardTemplates};
      t[id] = {...t[id], ...data};
      setStandardTemplates(t);
    }catch(err) {
      console.log(err.response?.data || err);
      console.log(err);
    }
  }

  const deleteStandardTemplate = async (id) => {
    try {
      await delete_standard_template(id);
      const t = {...state.standardTemplates};
      delete t[id]
      setStandardTemplates(t);
    }catch(err) {
      console.log(err);
    }
  }

  // Update multiple templates
  const updateMultipleTemplates = (arr) => {
    const t = {...state.templates};
    arr.forEach(tmpl => {
      t[tmpl.id] = {...t[tmpl.id], ...tmpl.data};
    });
    setTemplates(t);
  }

  // Set active vars section
  const setActiveVarsSection = (section) => {
    dispatch({
      type: SET_ACTIVE_VARS_SECTION,
      payload: section
    });
  }

  // Get template by id
  const getTemplateById = (id) => {
    let template = {};
    const templates = {...state.templates};
    const ids = Object.keys(templates);
    for(let i = 0; i < ids.length; i++) {
      if(ids[i] === id) {
        template = templates[id];
        break;
      }
    }
    return template;
  }

  // Update document
  const updateDocument = async (data, doc, single = true, fetch = true) => {
    try {
      const id = doc.id;
      if(!id) throw new Error('no id')
      await update_document(id, data, doc.attachments || []);
      if(single) {
        const docs = {...state.documents};
        docs[id] = {
          ...docs[id],
          ...data,
          meta: {
            ...(data.meta || docs[id].meta || {}),
            updated: Date.now()
          }
        };
        setDocuments(docs);
      }
      // if(fetch) {
      //   // fetch documents
      // }
    }catch(err) {
      console.log(err.response?.data || err); 
    }
  }

  // Update multiple documents
  const updateMultipleDocuments = (arr) => {
    const docs = {...state.documents};
    arr.forEach(doc => {
      docs[doc.id] = {...docs[doc.id], ...doc.data};
    });
    setDocuments(docs);
  }

  // Create document
  const createDocument = async (data, attachments = []) => {
    try {
      const res = await create_document(data, attachments)
      data.author = {
        id: userClaims.admin_id || '',
        firstname: userClaims.admin_firstname || '',
        lastname: userClaims.admin_lastname || ''
      }
      data.owner = `${userClaims.manufacturer_id}`
      if(res.success) {
        const doc = { ...data, meta: { created: Date.now(), updated: Date.now() } }
        setDocuments({ ...state.documents, [res.documentId]: doc })
        return res.documentId
      }else {
        throw new Error('something went wrong')
      }
    } catch (err) {
      console.log(err)
    }
  }

  // Fetch archived docs
  const fetchArchivedDocs = async () => {
    try {
      const res = await filter_documents({ filterBy: 'archived', filterComparison: '==', filterValue: true })
      let archived = { ...state.archivedDocuments, ...res.data }
      setArchivedDocuments(archived)
      setArchivedDocsFetched(true)
      return archived
    } catch (err) {
      console.log(err)
    }
  }

  // Fetch deleted docs
  const fetchDeletedDocs = async () => {
    try {
      const res = await filter_documents({ filterBy: 'deleted', filterComparison: '==', filterValue: true })
      let deleted = { ...state.deletedDocuments, ...res.data }
      setDeletedDocuments(deleted)
      setDeletedDocsFetched(true)
      return deleted
    } catch (err) {
      console.log(err)
    }
  }

  // Reset filters
  const resetDocsSelectedFilters = () => {
    setDocsSelectedFilters({
      status: 'all',
      date_after: '',
      date_before: '',
      folder: 'all',
      search: ''
    })
  }

  // Archive documents
  const archiveMultipleDocuments = (docs) => {
    const copyOfDocs = {...state.documents}
    const copyOfArchivedDocs = {...state.archivedDocuments}
    for(let doc of docs) {
      if(doc.id) {
        if(copyOfDocs[doc.id]) {
          delete copyOfDocs[doc.id]
        }
        copyOfArchivedDocs[doc.id] = {...doc, archived: true, meta: {...doc.meta, updated: Date.now()}}
      }
    }
    setDocuments(copyOfDocs)
    setArchivedDocuments(copyOfArchivedDocs)
  }

  // Restore documents
  const restoreMultipleDocuments = (docs, from = 'archived') => {
    const copyOfDocs = {...state.documents}
    const copyOfArchivedDocs = {...state.archivedDocuments}
    const copyOfDeletedDocs = {...state.deletedDocuments}
    for(let doc of docs) {
      if(doc.id) {
        if(from === 'archived' && copyOfArchivedDocs[doc.id]) {
          delete copyOfArchivedDocs[doc.id]
        }else if (from === 'deleted' && copyOfDeletedDocs[doc.id]) {
          delete copyOfDeletedDocs[doc.id]
        }
        copyOfDocs[doc.id] = {...doc, archived: false, deleted: false, meta: {...doc.meta, updated: Date.now()}}
      }
    }
    setDocuments(copyOfDocs)
    if(from === 'archived') {
      setArchivedDocuments(copyOfArchivedDocs)
    }else if(from === 'deleted') {
      setDeletedDocuments(copyOfDeletedDocs)
    }
  }

  // Temporarily delete multiple documents
  const temporarilyDeleteMultipleDocuments = (docs, from = 'active') => {
    const copyOfDocs = {...state.documents}
    const copyOfArchivedDocs = {...state.archivedDocuments}
    const copyOfDeletedDocs = {...state.deletedDocuments}
    for(let doc of docs) {
      if(doc.id) {
        if(from === 'active' && copyOfDocs[doc.id]) {
          delete copyOfDocs[doc.id]
        }else if (from === 'archived' && copyOfArchivedDocs[doc.id]) {
          delete copyOfArchivedDocs[doc.id]
        }
        copyOfDeletedDocs[doc.id] = {...doc, archived: false, deleted: true, meta: {...doc.meta, updated: Date.now()}}
      }
    }
    setDeletedDocuments(copyOfDeletedDocs)
    if(from === 'active') {
      setDocuments(copyOfDocs)
    }else if(from === 'archived') {
      setArchivedDocuments(copyOfArchivedDocs)
    }
  }

  // Permanently delete multiple documents
  const permanentlyDeleteMultipleDocuments = (docs) => {
    const copyOfDeletedDocs = {...state.deletedDocuments}
    for(let doc of docs) {
      if(doc.id) {
        delete copyOfDeletedDocs[doc.id]
      }
    }
    setDeletedDocuments(copyOfDeletedDocs)
  }

  const createStandardTemplate = async ({ document, template, values, checkboxValues, name, customCover, attachments, content_changes = [], content_editable = false }) => {
    const standardTemplateData = {
      template: document.template,
      values: values,
      checkboxValues: checkboxValues,
      owner: document.owner,
      name: name,
      custom_cover: customCover,
      attachments: attachments,
      folderId: [],
      content_changes: content_changes,
      content_editable: content_editable,
    }
    const response = await create_standard_template(standardTemplateData)
    console.log('createStandardTemplate response', response)
    if(!response.success) {
      console.error('createStandardTemplate error', response)
      return response
    }
    standardTemplateData.id = response.id
    setStandardTemplates({ ...state.standardTemplates, [response.id]: standardTemplateData })
    return response
  }

  const duplicateStandardTemplate = async (standardTemplateData) => {
    const response = await create_standard_template(standardTemplateData)
    console.log('duplicateStandardTemplate response', response)
    if(!response.success) {
      console.error('duplicateStandardTemplate error', response)
      return response
    }
    standardTemplateData.id = response.id
    setStandardTemplates({ ...state.standardTemplates, [response.id]: standardTemplateData })
    return response
  }

  const globalReload = async () => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Rechargement en cours...')
    const promises = []
    promises.push(fetchDocuments())
    promises.push(getTemplates())
    promises.push(getStandardTemplates())
    await Promise.all(promises)
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
    setLastReloadTime(Date.now())

  }

  return <DocumentsContext.Provider value={{
    templates: state.templates,
    documents: state.documents,
    actions: state.actions,
    fetchActions: state.fetchActions,
    filteredDocuments: state.filteredDocuments,
    archivedDocuments: state.archivedDocuments,
    deletedDocuments: state.deletedDocuments,
    showFilteredDocs: state.showFilteredDocs,
    currentFilter: state.currentFilter,
    activeFilterColumn: state.activeFilterColumn,
    dateFilters: state.dateFilters,
    filteredTemplates: state.filteredTemplates,
    templatesLoading: state.templatesLoading,
    activeVarsSection: state.activeVarsSection,
    actionsFetched: state.actionsFetched,
    templatesLoaded: state.templatesLoaded,
    documentsLoaded: state.documentsLoaded,
    showFilteredTemplates: state.showFilteredTemplates,
    standardTemplates: state.standardTemplates,
    standardTemplatesLoaded: state.standardTemplatesLoaded,
    copiedAttachments,
    attachmentsFrom,
    setTemplates,
    setDocuments,
    fetchDocuments,
    fetchMoreDocuments,
    setFilteredDocuments,
    setArchivedDocuments,
    setDeletedDocuments,
    setShowFilteredDocs,
    setCurrentFilter,
    setActiveFilterColumn,
    setDateFilters,
    setFilteredTemplates,
    setTemplatesLoading,
    getTemplates,
    updateTemplate,
    deleteTemplate,
    setActiveVarsSection,
    setActions,
    setFetchActions,
    getTemplateById,
    updateMultipleTemplates,
    updateDocument,
    updateMultipleDocuments,
    setShowFilteredTemplates,
    setCopiedAttachments,
    setAttachmentsFrom,
    getSingleDocument,
    getSingleTemplate,
    docsSelectedFilters,
    setDocsSelectedFilters,
    docSearch,
    setDocSearch,
    createDocument,
    templatesSelectedFilters,
    setTemplatesSelectedFilters,
    standardTemplatesSelectedFilters,
    setStandardTemplatesSelectedFilters,
    resetDocsSelectedFilters,
    archivedDocsFetched,
    setArchivedDocsFetched,
    deletedDocsFetched,
    setDeletedDocsFetched,
    fetchArchivedDocs,
    fetchDeletedDocs,
    archiveMultipleDocuments,
    restoreMultipleDocuments,
    temporarilyDeleteMultipleDocuments,
    permanentlyDeleteMultipleDocuments,
    getStandardTemplates,
    setStandardTemplates,
    createStandardTemplate,
    updateStandardTemplate,
    deleteStandardTemplate,
    duplicateStandardTemplate,
    globalReload,
    lastReloadTime,
    dashboardSearch,
    setDashboardSearch,
    templatesActiveSort,
    setTemplatesActiveSort,
    standardTemplatesActiveSort,
    setStandardTemplatesActiveSort,
    documentsActiveSort,
    setDocumentsActiveSort,
  }}>
    {children}
  </DocumentsContext.Provider>
}

export default DocumentsState;