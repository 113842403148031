import { useContext, useEffect } from 'react'
import { Check, Sort } from '../../assets/icons'
import { DocumentsTableFolderRow, DocumentsTableRow } from '../ui_new'
import { FEATURE, isFeatureEnabled } from '../../utils'
import { DocumentsContext } from '../../context'

const DocumentsTable = ({ selectedDocuments, onSetSelectedDocuments, allChecked, onSetAllChecked, filteredDocs, filteredFolders, onSelectFolder, onRequestSignature }) => {

  // On all checkbox click
  const handleAllChecked = () => {
    onSetAllChecked(!allChecked)
    if (allChecked) {
      onSetSelectedDocuments([])
    } else {
      onSetSelectedDocuments(filteredDocs)
    }
  }

  const { docsSelectedFilters } = useContext(DocumentsContext)

  return (
    <div className={`documents-table-parent ${selectedDocuments.length > 0 && 'active'}`}>
      <div className="documents-table">
        <div className="documents-table__head">
          <div className="documents-table-row">
            {/* <div className="documents-table-column documents-table-column__checkbox">
              <div className={`documents-table-column__check ${allChecked ? 'checked' : ''}`} onClick={handleAllChecked}><Check /></div>
            </div> */}
            <div className="documents-table-column documents-table-column__name">
              <p>Nom</p>
            </div>
            <div className="documents-table-column documents-table-column__status">
              <p>Statut</p>
            </div>
            <div className="documents-table-column documents-table-column__progress">
              <p>%</p>
            </div>
            {isFeatureEnabled(FEATURE.INTERAGENCY) &&
              <div className="documents-table-column documents-table-column__author a">
                <p>Créé par</p>
              </div>}
            {isFeatureEnabled(FEATURE.INTERAGENCY) &&
              <div className="documents-table-column documents-table-column__owner">
                <p>Agence</p>
              </div>}
            <div className="documents-table-column documents-table-column__date">
              <p>Date de<br /> création</p>
            </div>
            <div className="documents-table-column documents-table-column__date">
              <p>Date de<br /> modification</p>
            </div>
          </div>
        </div>
        <div className="documents-table__body">
          {filteredFolders.map((folder, fi) => {
            return (
              <DocumentsTableFolderRow
                key={fi}
                folder={folder}
                onSelectFolder={onSelectFolder}
              />
            )
          })}
          {filteredDocs.map((doc, idx) => {
            return (
              <DocumentsTableRow
                key={idx}
                id={doc.id}
                doc={doc}
                selectedDocuments={selectedDocuments}
                onSetSelectedDocuments={onSetSelectedDocuments}
                allChecked={allChecked}
                onRequestSignature={onRequestSignature}
              />
            )
          })}

          {filteredDocs.length === 0 && <span className='no-items'>Aucun élément</span>}
        </div>
      </div>
    </div>
  )
}

export default DocumentsTable