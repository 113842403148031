import RangeInput from "../../ui_new/RangeInput"
import { useContext, useState, forwardRef, useImperativeHandle } from "react";
import { NotificationContext } from "../../../context";
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Input, ResponseLoader } from "../../ui_new";
import Dropdown from '../../UI/Dropdown';
import { get_anonymous } from "../../../services/firestore";
import { FilePresent, UploadFile, AddLink, ChevronLeft } from "@mui/icons-material";
import { Attachment, Delete, Upload } from "../../../assets/icons";
import { ChevronRight } from "@material-ui/icons";
import ImportFromURL from "../ImportFromURL";
const MailModalContentDocuments = forwardRef(({ onReturn, onContinue, documents, setDocuments, attachmentTotalMaxSize }, ref) => {
  const [showDropdown, setShowDropdown] = useState(true);
  const [showImportURLModal, setShowImportURLModal] = useState(false)

  const onDrop = async acceptedFiles => {
    for await (let file of acceptedFiles) {
      await processAddAttachment(null, file, documents)
    }
  }



  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: true,
    noClick: true,
    maxSize: attachmentTotalMaxSize,
    accept: {
      'application/pdf': ['.pdf']
    }
  })


  const [loading, setLoading] = useState(false)

  const [urlInputValue, setUrlInputValue] = useState('')

  const { setNotification } = useContext(NotificationContext)

  const handleSubmit = () => {
    onContinue()
  }

  // const acceptedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'image/gif']
  const acceptedTypes = ['application/pdf']

  const handleUrlSubmit = async () => {
    setLoading(true)
    const fileBlob = await get_anonymous(urlInputValue)
    if (fileBlob.error) {
      setNotification({ msg: `Impossible de télécharger le fichier. ${fileBlob.error.message || ''}`, type: 'danger' })
      setLoading(false)
      return
    }
    const { size, type } = fileBlob
    if (!acceptedTypes.includes(type)) {
      setNotification({ msg: 'Type de document invalide. Seuls les documents de type PDF sont acceptés.', type: 'danger' })
      setLoading(false)
      return
    }
    let components = urlInputValue.split('?')[0].split('/')
    let name = components[components.length - 1].split('.')[0]
    let atts = [...documents]
    try {
      const base64 = await fileOrBlobToBase64(fileBlob)
      atts.push({
        size, type, data: base64, name, extension: type.split('/')[1]
      })
    } catch (err) { }
    setUrlInputValue('')
    setShowImportURLModal(false)
    setDocuments(atts)
    setLoading(false)
  }

  const processAddAttachment = async (blob, file, atts) => {
    if (blob) {
      const { size, type } = blob
      if (!acceptedTypes.includes(type)) {
        setNotification({ msg: 'Type de document invalide. Seuls les documents de type PDF sont acceptés.', type: 'danger' })
        setLoading(false)
        return
      }
      let components = urlInputValue.split('?')[0].split('/')
      let name = components[components.length - 1].split('.')[0]
      try {
        const base64 = await fileOrBlobToBase64(blob)
        atts.push({
          size, type, data: base64, name, extension: type.split('/')[1]
        })
      } catch (err) { }
      setUrlInputValue('')
      setShowImportURLModal(false)
      setDocuments([...atts])
    } else if (file) {
      let { size, type, name } = file
      if (!acceptedTypes.includes(type)) {
        setNotification({ msg: 'Type de document invalide. Seuls les documents de type PDF sont acceptés.', type: 'danger' })
        setLoading(false)
        return
      }
      name = name.split('.')[0]
      // const buffer = await file.arrayBuffer()
      try {
        const base64 = await fileOrBlobToBase64(file)
        atts.push({
          size, type, data: base64, name, extension: type.split('/')[1]
        })
      } catch (err) { }
      setUrlInputValue('')
      setShowImportURLModal(false)
      setDocuments([...atts])
    }

  }

  function fileOrBlobToBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1])
      reader.readAsDataURL(file);
      reader.onerror = (error) => {
        console.log('Error: ', error);
        reject(error)
      }
    })
  }

  const printSize = (bytes) => {
    if (bytes > 1000000) {
      return `${Math.round(bytes / 1000000).toFixed(0)}MB`
    } else if (bytes > 1000) {
      return `${Math.round(bytes / 1000).toFixed(0)}KB`
    }
  }

  // const printType = (mimetype) => {
  //     return mimetype.split('/')[1].toUpperCase()
  // }

  const calculateSize = () => {
    let size = 0
    for (let att of documents) {
      size += att.size
    }
    return size
  }

  useImperativeHandle(ref, () => ({
    handleSubmit,
    calculateSize
  }));

  // On close import URL modal
  const handleCloseImportURLModal = () => {
    setShowImportURLModal(false)
  }

  return (
    <div className='registered-mail-send-modal-annexes-container'>
      {loading && <ResponseLoader text={'Chargement'} className="response-loader--dark" />}


      <div className='flex flex-row modal-section-title'>
        {/* <FilePresent className="modal-section-title--icon" /> */}
        Sélectionnez les documents à joindre
      </div>
      <div className="table-responsive overflow-x-auto">
        <table className='registered-mail-send-modal-list-table mb-16'>
          <thead>
            <tr>
              <th style={{ width: '42px' }}></th>
              <th>Nom du document</th>
              <th>Taille</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((att, attIndex) =>
              <tr key={`attachment_${attIndex}`}>
                <td class="px-0 text-center">
                  <button
                    className='button button--transparent !p-2 btn-delete'
                    onClick={() => {
                      setDocuments(documents.filter((_, i) => i !== attIndex))
                    }}
                  >
                    <Delete color="error" />
                  </button>
                </td>
                <td>{att.name}</td>
                <td>{printSize(att.size)}</td>
                <td>{`.${att.extension.toUpperCase()}`}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div
        className='dropzone mt-5'
        {...getRootProps()}
      >
        <input {...getInputProps()} accept={acceptedTypes.join(", ")} />
        {
          isDragActive ?
            <p>Déposez le fichier ici ...</p> :
            <>
              <button class="button button--outline-primary-light rounded-sm btn-text upload-button">
                <span className="upload-icon"> <Upload /></span>
                Ajouter des documents
                <span className="rotate-90 chevron-icon"><ChevronRight /></span>
                <ul >
                  <li onClick={open}> <UploadFile />
                    Importer un document</li>
                  <li onClick={() => setShowImportURLModal(true)}> <AddLink />
                    Ajouter depuis une URL</li>
                </ul>
              </button>

              <p className="mr-auto">ou glisser-déposer les documents</p>
              {documents.length > 0 && <div className="flex flex-row justify-end align-center">
                <div className='range-container'>
                  <RangeInput value={calculateSize()} max={attachmentTotalMaxSize} />
                  <p className="text-right">
                    <b>{`Poids actuel: ${calculateSize() ? printSize(calculateSize()) : '0 MB'}`}</b>
                  </p>
                </div>
              </div>}
            </>
        }

      </div>

      <p className="queta">
        <span>Format uniquement supporté : .PDF</span>
        <span>Taille maximale : {`${printSize(attachmentTotalMaxSize)}`}</span>
      </p>
      {calculateSize() > attachmentTotalMaxSize && <div className='mb-20'>
        <p className='text-error' >Le poids total des documents joints dépasse {printSize(attachmentTotalMaxSize)}</p>
      </div>}

      {showImportURLModal && (
        <ImportFromURL
          onClose={handleCloseImportURLModal}
          onSubmit={handleUrlSubmit}
          value={urlInputValue}
          onChange={e => setUrlInputValue(e.target.value)}
        />
      )}
    </div>
  )
});

export default MailModalContentDocuments