import {
    useState,
    useRef,
    useEffect,
    memo,
    useContext,
    useCallback,
} from 'react'
import moment from 'moment'

import { CustomTooltip } from '../ui_new'
import { areSectionConditionsMet, isOverflown } from '../../utils'
import { CONTACT_ID_VARIABLES } from '../../constants'
import { UserContext } from '../../context'
import { useVariablesActions } from '../../hooks'
import { convertStringToValidId } from '../utils/convertStringToValidId'


// const excludedProgressVars = [
//     ...CONTACT_DATA_KEYS.mandat_seller_associated,
//     ...CONTACT_DATA_KEYS.compromis_buyer_associated,
//     ...CONTACT_DATA_KEYS.compromis_seller_associated
//   ]


const arrayStack = [-Infinity]
const SingleDocumentSummary = ({
    variableTypes,
    onItemClick,
    documentValues,
    activeVariable,
    scrollingPosition,
    onSectionClick,
    scrollingToItem,
    // templateMetaSections,
    summarySections
}) => {
    // const [filteredVariables, setFilteredVariables] = useState([])
    const spVariablesEl = useRef()
    const spVariablesBodyEl = useRef()
    const [variableSections, setVariableSections] = useState([])
    const [prevActiveEl, setPrevActiveEl] = useState(null)


    // useEffect(() => {
    //   const spActiveVarEl = spVariablesBodyEl.current.querySelector(`[data-id="${activeVariable}"]`);
    //   if(spActiveVarEl && spActiveVarEl !== prevActiveEl && !scrollingToItem) {
    //     const wrapperTopPosition = spVariablesBodyEl.current.getBoundingClientRect().top;
    //     const varPosition = spActiveVarEl.getBoundingClientRect();
    //     setPrevActiveEl(spActiveVarEl);

    //     if(varPosition.top > wrapperTopPosition) {
    //       spVariablesBodyEl.current.scrollTo({ top: varPosition.top + spVariablesBodyEl.current.scrollTop - wrapperTopPosition });
    //     }else if (varPosition.top < wrapperTopPosition) {
    //       spVariablesBodyEl.current.scrollTo({ top: varPosition.top + spVariablesBodyEl.current.scrollTop - wrapperTopPosition });
    //     }
    //   }
    //   // eslint-disable-next-line
    // }, [scrollingPosition])
    const { setIsScroll } = useContext(UserContext)

    const handleScroll = useCallback(() => {
        const element = document.getElementById('direction2')
        const { scrollTop } = element

        if (arrayStack[arrayStack.length - 1] < scrollTop) {
            arrayStack.push(scrollTop)
            setIsScroll(true)
        } else {
            arrayStack.length = 0
            arrayStack.push(scrollTop)
            setIsScroll(false)
        }
    }, [])

    return (
        <div
            id="direction2"
            className="summary__main summary-variables u-custom-scrollbar--3"
            onScroll={() => handleScroll()}
        >
            <div
                className="summary-variables__main"
                ref={spVariablesBodyEl}
            >
                {summarySections?.map((varSection, i) => {
                    return (
                        <SPVariableSection
                            key={i}
                            varSection={varSection}
                            onSectionClick={onSectionClick}
                            activeVariable={activeVariable}
                            documentValues={documentValues}
                            onItemClick={onItemClick}
                            variableTypes={variableTypes}
                            index={i + 1}
                        />
                    )
                })}
            </div>
            {/* {templateData && templateData.footer && <div className="summary-variables__foot">
        <div className="summary-variables__foot_title"><span>Pied de page</span></div>
        <SPVariableSection
          varSection={{fields: [templateData.footer], index: '', progress: 0}}
          onSectionClick={() => {}}
          activeVariable={''}
          documentValues={documentValues}
          onItemClick={onItemClick}
          variableTypes={variableTypes}
          openVarsDefault={true}
        />
      </div>} */}
        </div>
    )
}

const SPVariableSection = ({
    varSection,
    onSectionClick,
    activeVariable,
    documentValues,
    onItemClick,
    variableTypes,
    openVarsDefault = false,
    index,
}) => {
    const [openVars, setOpenVars] = useState(openVarsDefault)
    let sectionClass = 'summary-variables__section'

    const sectionClickHandler = (idx) => {
        onSectionClick(idx)
    }

    if (openVars) {
        sectionClass += ' summary-variables__section--active'
    }
    if (varSection.fields.length > 0) {
        sectionClass += ' summary-variables__section--opened'
    }

    return (
        <div className={sectionClass} data-index={varSection.index}>
            {varSection.title && (
                <SPVariableHeading
                    onSectionClick={() => sectionClickHandler(convertStringToValidId(varSection.title))}
                    varSection={varSection}
                    activeVariable={activeVariable}
                    index={varSection.index}
                    setOpenVars={setOpenVars}
                    openVars={openVars}
                    headingIndex={index}
                />
            )}
            {openVars && varSection.fields.length > 0 && (
                <ul>
                    {varSection.fields.map((f, i) => {
                        const output = []
                        
                        const {
                            variable,
                            display_name,
                            type,
                            question,
                            data_type,
                            options,
                            value,
                            repeatableIds,
                            repetitionIndices
                        } = f
                        let displayName =
                            type === 'question'
                                ? question
                                : display_name
                        output.push(
                            <SPVariableListItem
                                key={`${variable}-${i}`}
                                variable={variable}
                                value={data_type === 'select' ? options?.find(o => o.value === value)?.label || value : value}
                                onItemClick={onItemClick}
                                activeVariable={activeVariable}
                                isVarDate={
                                    variableTypes[variable] ===
                                    'date'
                                }
                                idx={f.idx}
                                displayName={displayName}
                                repeatableIds={repeatableIds}
                                repetitionIndices={repetitionIndices}
                            />
                        )
                        return output
                    })}
                </ul>
            )}
        </div>
    )
}

const SPVariableHeading = ({
    onSectionClick,
    varSection,
    activeVariable,
    index,
    headingIndex,
    setOpenVars,
    openVars,
}) => {
    const headingEl = useRef()
    let headingClass = ''

    if (index === activeVariable || openVars) {
        headingClass = 'active'
    }

    const handleClick = () => {
        onSectionClick(varSection.index)
        setOpenVars(!openVars)
    }

    const output = (
        <h4
            ref={headingEl}
            onClick={handleClick}
            className={headingClass}
            data-id={index}
        >
            <span className="text">{varSection.title}</span>
            {varSection.progress !== undefined ? (
                <span
                    className={`progress ${
                        varSection.progress === 0 ? 'empty' :
                        varSection.progress === 100 ? 'done' : ''
                    }`}
                >{`${varSection.progress}%`}</span>
            ) : null}
            {/* {varSection.progress ? <CustomTooltip content={!openVars ? 'Afficher' : 'Masquer'}><span className="icon" onClick={() => setOpenVars(!openVars)}><ChevronRightIcon /></span></CustomTooltip> : null} */}
        </h4>
    )

    // return isOverflown(headingEl.current) ? <CustomTooltip content={varSection.title} fixed>{output}</CustomTooltip> : output;
    return output
}

const SPVariableListItem = memo(
    ({
        onItemClick,
        variable,
        value,
        activeVariable,
        isVarDate,
        displayName,
        idx,
        repeatableIds,
        repetitionIndices,
    }) => {
        const variableKeyEl = useRef()
        const variableTextEl = useRef()
        
        
        const fullIdx = `${idx}${repeatableIds?.length > 0 ? `-${repeatableIds.map((repId, repIdIndex) => `${repId}-${repetitionIndices?.[repIdIndex]}`).join('-')}` : ''}`
        
        return (
            <li
                onClick={() => onItemClick(fullIdx)}
                className={fullIdx === activeVariable ? 'active' : ''}
                data-id={fullIdx}
            >
                <p>
                    <CustomTooltip
                        content={displayName || variable}
                        position="top-left"
                        fixed
                        hideTooltip={!isOverflown(variableKeyEl.current)}
                    >
                        <p ref={variableKeyEl} className='mb-1'>{displayName || variable}</p>
                    </CustomTooltip>
                    {value &&
                    isVarDate &&
                    moment(value, moment.ISO_8601, true).isValid() ? (
                        <p className="input-value">
                            {moment(value).format('DD MMM YYYY')}
                        </p>
                    ) : Array.isArray(value) ? (
                        <p className="input-value">
                            {value.map((v, i) => (
                                <SPVariableListSpanEl key={i} value={v} />
                            ))}
                        </p>
                    ) : value ? (
                        <CustomTooltip
                            content={value}
                            position="top-right"
                            fixed
                            hideTooltip={
                                !isOverflown(variableTextEl.current)
                            }
                        >
                            <p className="input-value" ref={variableTextEl}>
                                {value || ' '}
                            </p>
                        </CustomTooltip>
                    ) : (
                        <p className="input-value">
                            {value || ' '}
                        </p>
                    )}
                </p>
            </li>
        )
    },
    (prevProps, nextProps) => {
        if (prevProps.value === nextProps.value) {
            return true
        }
        return false
    }
)

const SPVariableListSpanEl = ({ value }) => {
    const spanEl = useRef()

    const output = <span ref={spanEl}>{value}</span>

    return isOverflown(spanEl.current) ? (
        <CustomTooltip content={value} fixed position="top-right">
            {output}
        </CustomTooltip>
    ) : (
        output
    )
}

export default SingleDocumentSummary
