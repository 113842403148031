import React, { useContext } from 'react'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'

import VerifiedContactDropdown from './VerifiedContactDropdown'
import Input from '../../../UI/Input'
import Button from '../../../UI/Button'
import IconButton from '../../../UI/IconButton'
import CustomTooltip from '../../../UI/CustomTooltip'
import { UserContext } from '../../../../context/user/userState'

const SignerFields = ({ signer, type, index, verifiedContactsDropdownOptions, verifiedContactsChangeHandler, changeValueHandler, editSignaturePositionHandler, deletePositionHandler, placeSignatureHandler, deleteUser, useCustomPlacementTool = false }) => {
  const { isNotSQHorCAIpartner } = useContext(UserContext)
  // console.log('render signer fields***')

  return (
    <div className="form__fields-group" key={index}>
      {type === 'customers' && signer.dropdown && <VerifiedContactDropdown 
        verifiedContactsDropdownOptions={verifiedContactsDropdownOptions}
        signer={signer}
        index={index}
        verifiedContactsChangeHandler={verifiedContactsChangeHandler}
      />}
      <div className="form__group form__group--signature-modal">
        <Input 
          name={signer.name.name}
          value={signer.name.value}
          onChange={(e) => changeValueHandler(e, signer.id, 'name', type)}
          label="Prenom*"
          formEl 
          disabled={isNotSQHorCAIpartner ? type === 'customers' : type === 'customers' || type === 'admins'}
        />
        <Input 
          name={signer.lastname.name}
          value={signer.lastname.value}
          onChange={(e) => changeValueHandler(e, signer.id, 'lastname', type)}
          label="Nom*"
          formEl 
          disabled={isNotSQHorCAIpartner ? type === 'customers' : type === 'customers' || type === 'admins'}
        />
      </div> 
      <div className="form__group form__group--signature-modal">
        <Input 
          name={signer.email.name}
          value={signer.email.value}
          onChange={(e) => changeValueHandler(e, signer.id, 'email', type)}
          label="Adresse mail*"
          formEl 
          disabled={isNotSQHorCAIpartner ? type === 'customers' : type === 'customers' || type === 'admins'}
        />
        {type === 'manual' || type === 'doc_customers' ? <Input 
            name={signer.mobile.name}
            value={signer.mobile.value}
            onChange={(e) => changeValueHandler(e, signer.id, 'mobile', type)}
            label="Mobile* (Le code pays est requis, ex. +33)"
            formEl 
          />
        : <Input 
            name={signer.mobile.name}
            value={signer.mobile.value || ''}
            onChange={(e) => changeValueHandler(e, signer.id, 'mobile', type)}
            label="Mobile* (Le code pays est requis, ex. +33)"
            formEl 
            disabled
          />
        }
        {signer.error && <div className="form__error"><p className="form-input__error">* {signer.error}</p></div>}
      </div>
      <div className="form__group_actions">
        {!useCustomPlacementTool && signer.positions && signer.positions.map((pos, i) => (
          <div className="signature-positions" key={i}>
            <Input disabled value={+pos.page + 1} label="Page" />
            <Button text="Voir l'emplacement de la signature" onButtonClick={() => editSignaturePositionHandler(signer, type, i)} className="edit-btn" outlinePrimary />
            <Input disabled value={pos.TOP} label="Top" />
            <Input disabled value={pos.LEFT} label="Left" />
            <Input disabled value={pos.WIDTH} label="Width" />
            <Input disabled value={pos.HEIGHT} label="Height" />
            <IconButton 
              icon={<DeleteIcon />} 
              onButtonClick={(e) => useCustomPlacementTool ? deletePositionHandler(e, signer, type, i) : deletePositionHandler(e, signer.id, type, i)} 
              className="delete-btn" 
            />
          </div>
        ))}
        <div className="form__group_actions-bottom">
          {!useCustomPlacementTool && <Button 
            text={signer.positions && signer.positions.length > 0 ? "Définir un nouvel emplacement de signature" : "Définir l'emplacement de la signature"}
            onButtonClick={(e) => useCustomPlacementTool ? placeSignatureHandler(e, `signature-${Date.now()}`, 'signature', signer.id, type) : placeSignatureHandler(e, signer.id, type)} 
            primaryLight 
            type="button"
          />}
          <CustomTooltip content="Supprimer l'utilisateur" position="left">
            <div className="align-right">
              <IconButton 
                onButtonClick={(e) => deleteUser(e, index, signer.email.value)}
                icon={<DeleteIcon />}
                light
                className="delete-btn"
              />
            </div>
          </CustomTooltip>
        </div>
      </div>
    </div>
  );
}

export default SignerFields