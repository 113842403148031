import React, { useState, useEffect, useContext, useMemo, useCallback, memo } from 'react';
import { PDFDocument } from 'pdf-lib';
import { ReactSortable } from "react-sortablejs";
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import Checkbox from '../../UI/Checkbox';
import Switch from '../../UI/Switch';
import Message from '../../UI/Message';
import ResponseLoader from '../../UI/ResponseLoader';
import Alert from '../../UI/Alert';
import Loader from '../../UI/Loader';
import { sendSignatureRequest } from '../../../services/functions';
import { convert_attachment, generate_document } from '../../../services/lawstudioApi';
import { get_file, update_document, fetch_customer_by_id, create_document, get_signatures_credits, fetch_customers } from '../../../services/firestore';
import { validateEmail, validateMobileNumber } from '../../../helpers/validate';
import { SignatureContext } from '../../../context/signatures/signaturesState';
import { UserContext } from '../../../context/user/userState';
import { NotificationContext } from '../../../context/notifications/notificationState';
import { DocumentsContext } from '../../../context/documents/documentsState';
import { SignatureTemplatesContext } from '../../../context/signature_templates/signatureTemplatesState';
import { readFileAsync, base64toBlob, sortArrayOfObjects, availableOn, bytesToSize, isMlsPartner } from '../../../utils';
import config from '../../../config.json';
import SignatureTemplatesModal from './SignatureTemplatesModal';
import SaveUpdatedSignatureTemplateModal from './SaveUpdatedSignatureTemplateModal';
import UpdateTemplateSection from './signatures/UpdateTemplateSection';
import ChooseTemplateSection from './signatures/ChooseTemplateSection';
import SaveTemplateSection from './signatures/SaveTemplateSection';
import Contacts from './signatures/Contacts';
import CustomFields from './signatures/CustomFields';
import PlaceSignatureInterfaceModalV2 from './PlaceSignatureInterfaceModalV2'
import { get_file_data } from '../../../services/storage'
import { SignaturesNotAvailable } from '../../misc';

const SIGNATURES_BOX_DEFAULT = { 
  page: 0, 
  top: 900, 
  left: 90, 
  width: 215,
  height: 100,
  boxes: [],
  active: false,
  boxW: 200,
  boxH: 100,
  mH: 15,
  mV: 15
}

let verifiedCustomersTimeout

const SignDocumentModal = ({ onClose, onFetchDocumentData, documentName, docId, docAttachments, docSignatureRecipients, documentValues, documentStatus, templateId, progress, onAddAttachment, attachmentNames, documentObject, docContacts, authToken, templateObject }) => {
  const { addSignature } = useContext(SignatureContext);
  const { admins, user, verified_customers, canUseSignatures, siteConfigsLoaded, authenticationToken, urlStore, isNotSQHorCAIpartner, signatureCredentials, partner } = useContext(UserContext);
  const { setNotification } = useContext(NotificationContext);
  const { updateDocument } = useContext(DocumentsContext);
  const { signatureTemplates, signatureTemplatesFetched, createSignatureTemplate, updateSignatureTemplate } = useContext(SignatureTemplatesContext);
  const [loading, setLoading] = useState(false);
  const [emmeteur, setEmmeteur] = useState(isMlsPartner(partner) ? documentValues.mls_sender || '' : user.email ? user.email : '');
  const [emmeteurError, setEmmeteurError] = useState('');
  const [customSigners, setCustomSigners] = useState([]);
  const [signers, setSigners] = useState([]);
  const [adminSigners, setAdminSigners] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [requestSent, setRequestSent] = useState(false);
  const [urlToDoc, setUrlToDoc] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [signatureErrors, setSignatureErrors] = useState([]);
  const [requestFailed, setRequestFailed] = useState(false);
  const [placementInterface, setPlacementInterface] = useState('');
  const [showCustomersDropdown, setShowCustomersDropdown] = useState(false);
  const [showAdminsDropdown, setShowAdminsDropdown] = useState(false);
  const [showDocCustomersDropdown, setShowDocCustomersDropdown] = useState(false);
  const [verifiedCustomers, setVerifiedCustomers] = useState([]);
  const [filteredVerfifiedCustomers, setFilteredVerifiedCustomers] = useState([]);
  const [siteAdmins, setSiteAdmins] = useState([]);
  const [filteredSiteAdmins, setFilteredSiteAdmins] = useState([]);
  const [docCustomers, setDocCustomers] = useState([]);
  const [filteredDocCustomers, setFilteredDocCustomers] = useState([]);
  const [generalError, setGeneralError] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [customersIds, setCustomersIds] = useState([]);
  const [adminsIds, setAdminsIds] = useState([]);
  const [searchCustomers, setSearchCustomers] = useState('');
  const [searchAdmins, setSearchAdmins] = useState('');
  const [searchDocCustomers, setSearchDocCustomers] = useState('');
  const [showPlaceSigntuareInterface, setShowPlaceSignatureInterface] = useState(false);
  const [signaturePlaced, setSignaturePlaced] = useState(false);
  const [mergedFilesBase64, setMergedFilesBase64] = useState(null);
  const [addSignaturePositionTo, setAddSignaturePositionTo] = useState(null);
  const [editSignaturePositionOn, setEditSignaturePositionOn] = useState(null);
  const [showResponseLoader, setShowResponseLoader] = useState(false);
  const [orderedSignatairesArr, setOrderedSignatairesArr] = useState(config.environment === 'development' ? [...customSigners] : []);
  const [sendInOrder, setSendInOrder] = useState(false);
  const [showOrderCustomersModal, setShowOrderCustomersModal] = useState(false);
  const [fieldsPositions, setFieldsPositions] = useState([]);
  const [showSendRequestAlert, setShowSendRequestAlert] = useState(false);
  const [orderRadioValue, setOrderRadioValue] = useState('no');
  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [signatureTemplateName, setSignatureTemplateName] = useState('');
  const [signatureTemplateNameError, setSignatureTemplateNameError] = useState('');
  const [signaturePositions, setSignaturePositions] = useState([]);
  const [showChooseTemplates, setShowChooseTemplates] = useState(false);
  const [signatureTemplatesOptions, setSignatureTemplatesOptions] = useState([
    { label: 'Choisissez un modèle', value: '' }
  ]);
  const [activeSignatureTemplate, setActiveSignatureTemplate] = useState('');
  const [showSignatureTemplatesModal, setShowSignatureTemplatesModal] = useState(false);
  const [showSaveUpdatedTemplateModal, setShowSaveUpdatedTemplateModal] = useState(false);
  const [verifiedContactsDropdownOptions, setVerifiedContactsDropdownOptions] = useState([
    { label: 'Sélectionnez un destinataire *', value: '' }
  ]);
  // const [creditsLeft, setCreditsLeft] = useState(0)
  // const [showCredits, setShowCredits] = useState(false)
  // const [creditsFetched, setCreditsFetched] = useState(false)
  const [boxes, setBoxes] = useState([])
  const [signaturesBox, setSignaturesBox] = useState(SIGNATURES_BOX_DEFAULT)
  const [activeBox, setActiveBox] = useState(null)
  const [activeTemplateFieldsPositions, setActiveTemplateFieldsPositions] = useState([])
  const [activeTemplateSignaturePositions, setActiveTemplateSignaturePositions] = useState([])
  const [activeTemplateSignaturesBox, setActiveTemplateSignaturesBox] = useState({})
  const [currentDocNumOfPages, setCurrentDocNumOfPages] = useState(0)
  const [loadingVerifiedCustomers, setLoadingVerifiedCustomers] = useState(false)
  const [verifiedCustomersSearched, setVerifiedCustomersSearched] = useState(false)
  const [attsUpdated, setAttsUpdated] = useState(false)
  const [fileSize, setFileSize] = useState(0)
  const history = useHistory();

  // Get credits for ac3 partners
  // useEffect(() => {
  //   if(partner === 'jurisur' && !creditsFetched && !requestSent && canUseSignatures) {
  //     const fetchCredits = async () => {
  //       setShowCredits(true)
  //       try {
  //         const res = await get_signatures_credits()
  //         // console.log(res)
  //         if(res.authorizations?.tokens && Array.isArray(res.authorizations.tokens) && res.authorizations.tokens.length > 0) {
  //           let credits = 0
  //           res.authorizations.tokens.forEach(item => {
  //             if(item.status === 'active') {
  //               credits += item.tokens
  //             }
  //           })
  //           setCreditsLeft(credits)
  //         }
  //       } catch (err) {
  //         console.log('get credits error', err)
  //       } finally {
  //         setCreditsFetched(true)
  //       }
  //     }

  //     fetchCredits()
  //   }else {
  //     setCreditsFetched(true)
  //   }
  // }, [partner, creditsFetched, requestSent, canUseSignatures])

  // Populate signature templates options for select/dropdown field
  useEffect(() => {
    if(signatureTemplatesFetched) {
      const options = signatureTemplates.map(tmpl => ({
        label: tmpl.name,
        value: tmpl.id
      }));
      setSignatureTemplatesOptions([{ label: 'Choisissez un modèle', value: '' }, ...options]);
    }
  }, [signatureTemplates, signatureTemplatesFetched]);

  // Show only pdf and docx attachments
  useEffect(() => {
    console.log('docAttachments', docAttachments)
    const getAttsSizes = async (atts) => {
      let promises = []
      for(let i = 0; i < atts.length; i++) {
        if(!atts[i].size) {
          promises.push(get_file_data(atts[i].url))
        }
      }
      const data = await Promise.all(promises)
      if(data.length > 0) {
        // console.log('get sizes and update doc')
        let updatedFilteredAtts = []
        for(let i = 0; i < atts.length; i++) {
          let foundInData = data.find(a => a.url === atts[i].url)
          if(data.find(a => a.url === atts[i].url)) {
            updatedFilteredAtts.push({...atts[i], size: foundInData.size})
          }else {
            updatedFilteredAtts.push(atts[i])
          }
        }
        setAttachments(updatedFilteredAtts)
        let updatedDocAtts = []
        for(let i = 0; i < docAttachments.length; i++) {
          let att = docAttachments[i]
          let found = updatedFilteredAtts.find(a => a.url === att.url)
          if(found) {
            updatedDocAtts.push({...att, size: found.size})
          }else {
            updatedDocAtts.push(att)
          }
        }
        await updateDocument({ attachments: updatedDocAtts }, { id: docId, attachments: updatedDocAtts })
      }
      setAttsUpdated(true)
    }

    if(docAttachments && !attsUpdated) {
      const filteredAttachments = 
        config.environment === 'development' 
          ? [...docAttachments].filter(att => (att.format === 'pdf' || att.format === 'docx') && att.url) 
          : [...docAttachments].filter(att => att.format === 'pdf' && att.url);
      setAttachments(filteredAttachments);
      getAttsSizes(filteredAttachments)
    }
  }, [docAttachments, attsUpdated]);

  const processedPhoneNumber = (number) => {
    if(!number) {
      return ''
    }
    return number.replace(/[^0-9+]/g, '')
  }

  // Prefill recipients(customers & admins) if previously saved to document
  useEffect(() => {
    if(docSignatureRecipients && docSignatureRecipients.length > 0) {
      const typeCustomers = docSignatureRecipients.filter(r => r.type === 'customer');
      const typeAdmins = docSignatureRecipients.filter(r => r.type === 'admin');
      let orderedArr = [];
      if(typeCustomers.length > 0 && !isNotSQHorCAIpartner) {
        const arr = [];
        const ids = [];
        typeCustomers.forEach(c => {
          arr.push({
            id: c.id,
            name: { name: `${c.id}-name`, value: c.name },
            lastname: { name: `${c.id}-lastname`, value: c.lastname },
            email: { name: `${c.id}-email`, value: c.email },
            mobile: { name: `${c.id}-mobile`, value: processedPhoneNumber(c.mobile) },
            error: '',
            type: 'customer',
          });
          ids.push(c.id);
        });
        setSigners(arr);
        setCustomersIds(ids);
        orderedArr = [...arr];
      }
      if(typeAdmins.length > 0) {
        const arr = [];
        const ids = [];
        typeAdmins.forEach(a => {
          arr.push({
            id: a.id,
            name: { name: `${a.id}-name`, value: a.name },
            lastname: { name: `${a.id}-lastname`, value: a.lastname },
            email: { name: `${a.id}-email`, value: a.email },
            mobile: { name: `${a.id}-mobile`, value: processedPhoneNumber(a.mobile) },
            error: '',
            type: 'admin',
          });
          ids.push(a.id);
        });
        setAdminSigners(arr);
        setAdminsIds(ids);
        orderedArr = [...orderedArr, ...arr];
      }
      // setOrderedSignatairesArr(prev => [...prev, ...orderedArr]);
    }
  }, [docSignatureRecipients]);

  // Add admins to siteAdmins state variable to use in dropdown
  useEffect(() => {
    if(admins.length > 0) {
      const adminsArr = [];
      admins.filter(a => a.status === 1).forEach(siteAdmin => {
        const id = siteAdmin.id;
        if(siteAdmin.email && siteAdmin.firstname && siteAdmin.lastname && !adminsIds.includes(id)) {
          adminsArr.push({
            id,
            email: { name: `${id}-email`, value: siteAdmin.email },
            name: { name: `${id}-name`, value: siteAdmin.firstname },
            lastname: { name: `${id}-lastname`, value: siteAdmin.lastname },
            mobile: { name: `${id}-mobile`, value: processedPhoneNumber(siteAdmin.mobile_phone ? siteAdmin.mobile_phone : '') },
            error: '',
            type: 'admin',
          });
        }
      });
      setSiteAdmins(adminsArr);
      setFilteredSiteAdmins(adminsArr);
    }
  }, [admins, adminsIds]);

  // Add customers to docCustomers state variable to use in dropdown - only for AC3 partners
  useEffect(() => {
    if(docContacts.length > 0) {
      const contactsArr = []
      docContacts.forEach(contact => {
        const id = uuidv4()
        if(contact.firstname !== '' || contact.lastname !== '') {
          contactsArr.push({
            id, 
            email: { name: `${id}-email`, value: contact.email_address || '' },
            name: { name: `${id}-name`, value: contact.firstname || '' },
            lastname: { name: `${id}-lastname`, value: contact.lastname || '' },
            mobile: { name: `${id}-mobile`, value: processedPhoneNumber(contact.telephone || '') },
            error: '',
            type: 'doc_customer',
          })
        }
      })
      setDocCustomers(contactsArr)
      setFilteredDocCustomers(contactsArr)
    }
  }, [docContacts])

  // On customers dropdown change
  useEffect(() => {
    if(!showCustomersDropdown && searchCustomers !== '') {
      setSearchCustomers('');
      setFilteredVerifiedCustomers(verifiedCustomers);
    }
  }, [showCustomersDropdown, searchCustomers, verifiedCustomers]);

  // On admins dropdown change
  useEffect(() => {
    if(!showAdminsDropdown && searchAdmins !== '') {
      setSearchAdmins('');
      setFilteredSiteAdmins(siteAdmins);
    }
  }, [showAdminsDropdown, searchAdmins, siteAdmins]);

  // On doc customers dropdown change
  useEffect(() => {
    if(!showDocCustomersDropdown && searchDocCustomers !== '') {
      setSearchDocCustomers('');
      setFilteredDocCustomers(docCustomers);
    }
  }, [showDocCustomersDropdown, docCustomers, searchDocCustomers]);

  // Get document size
  useEffect(() => {
    const getSize = async () => {
      const data = await generate_document(templateObject, documentValues)
      if(data && data.data) {
        const blob = base64toBlob(`${data.data}`)
        if(blob) {
          setFileSize(blob.size)
        }
      }
    }
    getSize()
    // eslint-disable-next-line
  }, [])

  // Change handler for signers objects input fields
  const changeValueHandlerForCustomSigners = useCallback((e, id, change, type) => {
    const value = e.target.value;
    let signersCopy = [...customSigners]
    const findSigner = signersCopy.find(s => s.id === id);
    findSigner[change].value = value;
    const arr = signersCopy.map(s => s.id === id ? findSigner : s);
    setCustomSigners(arr)
    setOrderedSignatairesArr(prev => prev.map(s => s.id === id ? findSigner : s))
  }, [customSigners])

  // Change handler for signers objects input fields
  const changeValueHandlerForSigners = useCallback((e, id, change, type) => {
    const value = e.target.value;
    let signersCopy = [...signers];
    const findSigner = signersCopy.find(s => s.id === id);
    findSigner[change].value = value;
    const arr = signersCopy.map(s => s.id === id ? findSigner : s);
    setSigners(arr);
    setOrderedSignatairesArr(prev => prev.map(s => s.id === id ? findSigner : s))
  }, [signers])

  // Change handler for signers objects input fields
  const changeValueHandlerForAdmins = useCallback((e, id, change, type) => {
    const value = e.target.value;
    let signersCopy = [...adminSigners];
    const findSigner = signersCopy.find(s => s.id === id);
    findSigner[change].value = value;
    const arr = signersCopy.map(s => s.id === id ? findSigner : s);
    setAdminSigners(arr);
    setOrderedSignatairesArr(prev => prev.map(s => s.id === id ? findSigner : s))
  }, [adminSigners])

  // Change handler for signers objects input fields
  const changeValueHandlerForDocCustomers = useCallback((e, id, change, type) => {
    const value = e.target.value;
    let signersCopy = [...customers]
    const findSigner = signersCopy.find(s => s.id === id);
    findSigner[change].value = value;
    const arr = signersCopy.map(s => s.id === id ? findSigner : s);
    setDocCustomers(arr)
    setOrderedSignatairesArr(prev => prev.map(s => s.id === id ? findSigner : s))
  }, [customers])

  const validateForm = (e) => {
    e.preventDefault();

    // Remove error message
    if(requestFailed) {
      setRequestFailed(false);
    }
    if(signatureErrors.length > 0) {
      setSignatureErrors([]);
    }

    // Validation
    let errors = {};

    if(emmeteur.trim() === '') {
      setEmmeteurError('Ce champ est obligatoire');
      errors.emmeteur = true;
    }else if(!validateEmail(emmeteur.trim())){
      setEmmeteurError('Adresse email non valide');
      errors.emmeteur = true;
    }else {
      setEmmeteurError('');
      delete errors.emmeteur;
    }
    let noUsersCheck = 
    isNotSQHorCAIpartner 
      ? customers.length === 0 && customSigners.length === 0 
      : signers.length === 0
  
    if(noUsersCheck) {
      errors.general = true;
      setGeneralError('Veuillez ajouter des signataires (contacts)');
    }else{
      setGeneralError('');
      if(errors.general) {
        delete errors.general;
      }
    }

    if(isNotSQHorCAIpartner) {
      const cS = [];
      customSigners.forEach((signer) => {
        // if(signer.email.value?.trim() === '' || signer.name.value?.trim() === '' || signer.lastname.value?.trim() === '') {
        if(signer.email.value?.trim() === '' || signer.name.value?.trim() === '' || signer.lastname.value?.trim() === '' || !signer.mobile.value?.trim()) {
          signer.error = 'Tous les champs sont obligatoires';
          errors[signer.id] = true;
        }else if(!validateEmail(signer.email.value.trim())) {
          signer.error = 'Adresse email non valide';
          errors[signer.id] = true;
        }
        else if(signer.mobile.value && signer.mobile.value.trim() !== '' && !validateMobileNumber(signer.mobile.value.trim())){
          signer.error = "Le numéro de téléphone est mal renseigné (code international manquant, présence d'espaces, de tirets ou de caractères non autorisés)";
          errors[signer.id] = true;
        }
        else {
          signer.error = '';
          delete errors[signer.id];
        }
        cS.push(signer);
      });
      setCustomSigners(cS);
    }

    if(isNotSQHorCAIpartner) {
      const csmrs = [];
      customers.forEach((signer) => {
        if(signer.email.value?.trim() === '' || signer.name.value?.trim() === '' || signer.lastname.value?.trim() === '' || !signer.mobile.value?.trim()) {
          signer.error = 'Tous les champs sont obligatoires';
          errors[signer.id] = true;
        }else if(!validateEmail(signer.email.value.trim())) {
          signer.error = 'Adresse email non valide';
          errors[signer.id] = true;
        }else if(signer.mobile.value && signer.mobile.value.trim() !== '' && !validateMobileNumber(signer.mobile.value.trim())){
          signer.error = "Le numéro de téléphone est mal renseigné (code international manquant, présence d'espaces, de tirets ou de caractères non autorisés)";
          errors[signer.id] = true;
        }else {
          signer.error = '';
          delete errors[signer.id];
        }
        csmrs.push(signer);
      });
      setCustomers(csmrs);
    }

    if(!isNotSQHorCAIpartner){
      const s = [];
      signers.forEach((signer) => {
        if(signer.email.value?.trim() === '' || signer.name.value?.trim() === '' || signer.lastname.value?.trim() === '' || !signer.mobile.value?.trim()) {
          signer.error = 'Tous les champs sont obligatoires';
          errors[signer.id] = true;
        }else if(!validateEmail(signer.email.value?.trim())) {
          signer.error = 'Adresse email non valide';
          errors[signer.id] = true;
        }else if(signer.mobile.value && signer.mobile.value.trim() !== '' && !validateMobileNumber(signer.mobile.value.trim())){
          signer.error = "Le numéro de téléphone est mal renseigné (code international manquant, présence d'espaces, de tirets ou de caractères non autorisés)";
          errors[signer.id] = true;
        }else {
          signer.error = '';
          delete errors[signer.id];
        }
        s.push(signer);
      });
      setSigners(s);
    }

    const sA = [];
    adminSigners.forEach((signer) => {
      if(signer.email.value?.trim() === '' || signer.name.value?.trim() === '' || signer.lastname.value?.trim() === '' || !signer.mobile.value?.trim()) {
        signer.error = 'Tous les champs sont obligatoires';
        errors[signer.id] = true;
      }else if(!validateEmail(signer.email.value?.trim())) {
        signer.error = 'Adresse email non valide';
        errors[signer.id] = true;
      }else if(signer.mobile.value && signer.mobile.value.trim() !== '' && !validateMobileNumber(signer.mobile.value.trim())){
        signer.error = "Le numéro de téléphone est mal renseigné (code international manquant, présence d'espaces, de tirets ou de caractères non autorisés)";
        errors[signer.id] = true;
      }else {
        signer.error = '';
        delete errors[signer.id];
      }
      sA.push(signer);
    });
    setAdminSigners(sA);

    fieldsPositions.forEach(f => {
      if(f.label && f.label.value === '') {
        f.error = 'Label cannot be empty';
        errors[f.id] = true;
      }else {
        f.error = '';
        delete errors[f.id];
      }
    })

    let totalSize = getTotalSizeOfSelectedDocuments()
    if(totalSize > 16777216) {
      errors.size = true
      return setNotification({ msg: 'La taille maximale autorisée des fichiers est atteinte. Veuillez vous assurer que la taille du document principal et de toutes les pièces jointes sélectionnées ne dépasse pas 16 MB.', type: 'danger' })
    }

    // If no errors open alert
    if(Object.keys(errors).length === 0) {
      setShowSendRequestAlert(true);
    }
  }
  
  // Submit form
  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSendRequestAlert(false);
    let boxesArr = []
    signaturesBox.boxes.forEach((b, idx) => {
      if(idx === 0) {
        boxesArr.push({
          PAGE: signaturesBox.page + 1,
          WIDTH: signaturesBox.boxW,
          HEIGHT: signaturesBox.boxH,
          TOP: signaturesBox.top,
          LEFT: signaturesBox.left,
          signerId: b.parentId
        })
      }else {
        // let numOfRows = Math.ceil(signaturesBox.boxes.length / 2)
        let perRow = 2
        let currentRow = Math.ceil((idx / perRow) + 0.1)
        // let posInRow = ((idx / perRow) - Math.floor(idx / perRow)) !== 0 ? 'right' : 'left'
        let posInRow = idx % 2 === 0 ? 'left' : 'right'
        boxesArr.push({
          PAGE: signaturesBox.page + 1,
          WIDTH: signaturesBox.boxW,
          HEIGHT: signaturesBox.boxH,
          TOP: currentRow === 1 ? signaturesBox.top : signaturesBox.top + (signaturesBox.boxH * (currentRow - 1)) + signaturesBox.mV * (currentRow - 1),
          LEFT: posInRow === 'left' ? signaturesBox.left : signaturesBox.left + signaturesBox.boxW + signaturesBox.mH,
          signerId: b.parentId
        })
      }
    })

    try {
      setLoading(true);
      setShowResponseLoader(true);
      let base64Merged;
      let attachmentsToSign = []
      let pdfBase64
      if(!mergedFilesBase64) {
        pdfBase64 = await onFetchDocumentData('pdf');
        const blob = base64toBlob(pdfBase64, 'application/pdf');
        const filesToMergeArr = [blob];

        // If attachments are selected merge pdfs
        if(attachments) {
          for(let i = 0; i < attachments.length; i++) {
            if(attachments[i].sign) {
              attachmentsToSign.push(attachments[i])
              let res;
              if(attachments[i].format === 'pdf') {
                res = await get_file({ url: attachments[i].url });
              }else {
                res = await convert_attachment(attachments[i].url);
              }
              // console.log('FILE RES', res.data);
              const blob = base64toBlob(res.data, 'application/pdf');
              filesToMergeArr.push(blob);
            }
          }
        }

        const mergedPdf = await PDFDocument.create();
        for(let i = 0; i < filesToMergeArr.length; i++) {
          const bytes = await readFileAsync(filesToMergeArr[i]);
          const pdf = await PDFDocument.load(bytes);
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => mergedPdf.addPage(page)); 
        }
        await mergedPdf.save();
        base64Merged = await mergedPdf.saveAsBase64();
      }
      const pdfDoc = await PDFDocument.load(mergedFilesBase64 ? mergedFilesBase64 : base64Merged);

      const fieldsArr = []; 
      fieldsPositions.forEach((f) => {
        if(f.label && Object.keys(f.label).length > 0) {
          fieldsArr.push({ 
            PAGE: +f.label.page + 1,
            TOP: Math.floor(+f.label.top),
            LEFT: Math.floor(+f.label.left),
            WIDTH: Math.floor(+f.label.width),
            HEIGHT: Math.floor(+f.label.height),
            TYPE: 'LABEL',
            LIBELLE: f.label.value
          });
        }

        if(f.type === 'checkbox') {
          fieldsArr.push({ 
            PAGE: +f.page + 1,
            TOP: Math.floor(+f.top),
            LEFT: Math.floor(+f.left),
            WIDTH: 20,
            HEIGHT: 20,
            TYPE: 'CHECKBOX',
            REQUIS: f.required
          });
        }
      });

      const defaultSignaturePosition = {
        PAGE: pdfDoc.getPageCount(),
        TOP: 600,
        LEFT: 90,
        HEIGHT: 44,
        WIDTH: 240
      };
      // const sArr = config.environment === 'development' ? [...customSigners, ...signers, ...adminSigners] : [...signers, ...adminSigners];
      const sArr = isNotSQHorCAIpartner ? [...customSigners, ...customers, ...adminSigners] :  [...signers, ...adminSigners];
      // console.log(orderedSignatairesArr)
      // const signataireArr = [...customSigners, ...signers, ...adminSigners].map(signer => ({
      const signataireArr = !sendInOrder ? sArr.map(signer => {
        const position = boxesArr.find(b => b.signerId === signer.id)
        let signaturePos = {
          PAGE: position.PAGE,
          WIDTH: Math.floor(position.WIDTH),
          HEIGHT: Math.floor(position.HEIGHT),
          TOP: Math.floor(position.TOP),
          LEFT: Math.floor(position.LEFT),
        }
        
        return {
          NOM: signer.name.value,
          PRENOM: signer.lastname.value,
          EMAIL: signer.email.value,
          MOBILE: signer.mobile.value,
          SIGNATURE: [signaturePos],
          type: signer.type,
          id: signer.id
      }}) : [...orderedSignatairesArr].map(signer => {
        const position = boxesArr.find(b => b.signerId === signer.id)
        let signaturePos = {
          PAGE: position.PAGE ,
          WIDTH: Math.floor(position.WIDTH),
          HEIGHT: Math.floor(position.HEIGHT),
          TOP: Math.floor(position.TOP),
          LEFT: Math.floor(position.LEFT),
        }
        return {
          NOM: signer.name.value,
          PRENOM: signer.lastname.value,
          EMAIL: signer.email.value,
          MOBILE: signer.mobile.value,
          SIGNATURE: [signaturePos],
          type: signer.type,
          id: signer.id
      }}); 
      const date_limit = moment().add(7, 'days').toISOString();
      console.log({
        EMETTEUR: emmeteur.trim(),
        PDF_NOM: `${documentName}.pdf`,
        // PDF_DATA: mergedFilesBase64 ? mergedFilesBase64 : base64Merged,
        attachmentsToSign,
        documentData: pdfBase64,
        SIGNATAIRE: signataireArr.map(s => ({ NOM: s.NOM, PRENOM: s.PRENOM, EMAIL: s.EMAIL, MOBILE: s.MOBILE, SIGNATURE: s.SIGNATURE })),
        SEQUENTIEL: sendInOrder,
        CHAMPS: fieldsArr,
        DATE_LIMITE: date_limit
      })
      if(!pdfBase64) {
        pdfBase64 = await onFetchDocumentData('pdf')
        // If attachments are selected send them with request
        if(attachments) {
          for(let i = 0; i < attachments.length; i++) {
            if(attachments[i].sign) {
              attachmentsToSign.push(attachments[i])
            }
          }
        }
      }
      const data = await sendSignatureRequest({
        EMETTEUR: emmeteur.trim(),
        PDF_NOM: `${documentName}.pdf`,
        // PDF_DATA: mergedFilesBase64 ? mergedFilesBase64 : base64Merged,
        documentData: pdfBase64,
        attachmentsToSign,
        SIGNATAIRE: signataireArr.map(s => ({ NOM: s.NOM, PRENOM: s.PRENOM, EMAIL: s.EMAIL, MOBILE: s.MOBILE, SIGNATURE: s.SIGNATURE })),
        SEQUENTIEL: sendInOrder,
        CHAMPS: fieldsArr,
        DATE_LIMITE: date_limit,
        documentId: docId
      });
      // console.log(data);
      if(data.success && data.response.status) {
        // Add signature to database
        const recipientsArr = [...signataireArr].map(s => ({ name: s.NOM, lastname: s.PRENOM, email: s.EMAIL, mobile: s.MOBILE, type: s.type, id: s.id }));
        const signatureData = {
          title: documentName,
          recipients: recipientsArr,
          sentBy: emmeteur.trim(),
          createdAt: Date.now(),
          package_id: data.response.data.package_id,
          document_id: data.response.data.document_id,
          members_links: data.response.data.members_links,
          doc_id: docId,
          status: 'SIGNING_PENDING',
          date: date_limit,
          status_updated_at: Date.now()
        };
        await addSignature(signatureData);
        // Save recipients to doc db to prefill data next time signature modal is opened
        if(docId) {
          // await update_document(docId, { signatureRecipients: recipientsArr, signature_submitted: true, status: 'in-progress' }, docAttachments);
          await updateDocument({ signatureRecipients: recipientsArr, signature_submitted: true, status: 'in-progress' }, {...documentObject, id: docId, attachments: docAttachments })
        }else {
          let documentUpdates = { values: documentValues, name: documentName, status: documentStatus, template: templateId, progress: progress(), folderId: [], signatureRecipients: recipientsArr, signature_submitted: true, status: 'in-progress' };
          let response = await create_document(documentUpdates, docAttachments);
          if(response.success) {
            history.replace(`/document-detail/${response.documentId}`);
          }
        }

        setLoading(false);
        setRequestSent(true);
        setUrlToDoc(data.response.data.members_links[0].url);
        setSuccessMessage('Document envoyé');
        setShowResponseLoader(false);
        setShowSendRequestAlert(false);
        // setEmmeteur('');
        // setSigner(signerData);
        // setSigners([]);
        // setAdminSigners([]);
      }else {
        // error
        setRequestFailed(true);
        if(data.response?.message && data.response?.message.includes('Validation Error - Invalid position. Object placed outside of document page boundary.')) {
          setSignatureErrors(['Erreur de validation : position invalide. Objet placé en dehors des limites de la page du document'])
        }else if(data.response?.message && data.response?.message.includes('This field was not expected')){
          setSignatureErrors(['Une erreur est survenue avec le modèle de signature'])
        }else {
          if(data.response) {
            if(Array.isArray(data.response.message)) {
              setSignatureErrors(data.response.message);
            }else {
              setSignatureErrors([data.response.message]);
            }
          } else if(data.error?.message) {
            if(data.error.message.toLowerCase().includes("login failed")) {
              setSignatureErrors(["L'API d'Immofacile a fourni des identifiants de signature invalides pour votre agence"])
            } else {
              setSignatureErrors([data.error.message])
            }
          } else {
            setSignatureErrors(['Quelque chose a mal tourné !'])
          }
        }
        setLoading(false);
        setShowResponseLoader(false);
        // console.log(data.response.data.message);
      }
    }catch(err) {
      console.log(err);
      setRequestFailed(true);
      setSignatureErrors(['Une erreur est survenue, merci de réessayer']);
      setLoading(false);
      setShowResponseLoader(false);
    }
  }

  // Load pdf 
  const loadPdf = useCallback(async (openModal = true) => {
    try {
      const pdfBase64 = await onFetchDocumentData('pdf')
      const blob = base64toBlob(pdfBase64, 'application/pdf')
      const filesToMergeArr = [blob]
      let base64Merged = pdfBase64

      let numOfPages
      
      // If attachments are selected merge pdfs
      if(attachments) {
        for(let i = 0; i < attachments.length; i++) {
          if(attachments[i].sign) {
            let res
            if(attachments[i].format === 'pdf') {
              res = await get_file({ url: attachments[i].url })
            }else {
              res = await convert_attachment(attachments[i].url)
            }
            const blob = base64toBlob(res.data, 'application/pdf')
            filesToMergeArr.push(blob)
          }
        }
        
        const mergedPdf = await PDFDocument.create()
        for(let i = 0; i < filesToMergeArr.length; i++) {
          const bytes = await readFileAsync(filesToMergeArr[i])
          const pdf = await PDFDocument.load(bytes)
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
          copiedPages.forEach((page) => mergedPdf.addPage(page))
          numOfPages = copiedPages.length
        }
        await mergedPdf.save()
        base64Merged = await mergedPdf.saveAsBase64()
        setMergedFilesBase64(base64Merged)
      } else {
        const pdf = await PDFDocument.load(base64Merged)
        const pages = await pdf.copyPages(pdf, pdf.getPageIndices())
        numOfPages = pages.length
      }
      
      if(openModal) {
        setShowPlaceSignatureInterface(true)
      }
      setShowResponseLoader(false)
      return { pages: numOfPages }
    } catch (err) {
      console.log(err)
      setShowResponseLoader(false)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    }
  }, [attachments, onFetchDocumentData, setNotification])

  // Recalculate signature boxes wrapper position when adding new signer
  const recalculateSignaturesBoxPosition = useCallback((newBox, numOfPages) => {
    const newBoxes = [...signaturesBox.boxes, newBox]
    let boxWidth = newBoxes.length === 1 ? signaturesBox.boxW + signaturesBox.mH : signaturesBox.boxW * 2 + signaturesBox.mH * 2
    let boxHeight = newBoxes.length < 3 ? signaturesBox.boxH + signaturesBox.mV : signaturesBox.boxH * Math.ceil(newBoxes.length / 2) + signaturesBox.mV * Math.ceil(newBoxes.length / 2)
    let newSBox = {
      page: signaturesBox.boxes.length === 0 ? (numOfPages || currentDocNumOfPages) - 1 : signaturesBox.page,
      boxes: newBoxes,
      width: boxWidth,
      height: boxHeight,
    }
    // Check if boxes wrapper should be moved after adding new box
    // 774 - is placement tool area width
    if(774 - signaturesBox.left < boxWidth) {
      newSBox.left = 774 - boxWidth + signaturesBox.mH
    }
    // 1094 - is placement tool area height
    if(1094 - signaturesBox.top < boxHeight) {
      newSBox.top = 1094 - boxHeight + signaturesBox.mV - 2
    }
    setSignaturesBox(prev => ({
      ...prev,
      ...newSBox
    }))
  }, [currentDocNumOfPages, signaturesBox])

  // Add customer to signers array and remove from verifiedCustomers array
  const addCustomerToSigners = useCallback(async (customer) => {
    if(signers.find(s => s.id === customer.id)) return setNotification({ msg: 'Le contact a déjà été ajouté', type: 'warning' })

    try {
      let res;
      let mobile = customer.mobile.value;
      if(!customer.fetched) {
        setShowResponseLoader(true);
        res = await fetch_customer_by_id(authenticationToken, customer.id, true);
        mobile = res.mobile_phone;
      }

      let numOfPages
      if(!mergedFilesBase64) {
        setShowResponseLoader(true)
        const { pages } = await loadPdf(false)
        setCurrentDocNumOfPages(pages)
        numOfPages = pages
      }

      const updatedSigners = [...signers, {...customer, type: 'customer', mobile: {...customer.mobile, value: mobile }, fetched: true, positions: [] }]
      if(generalError) {
        setGeneralError('');
      }
      setSigners(updatedSigners);
      setVerifiedCustomers(prevCustomers => [...prevCustomers].filter(c => c.id !== customer.id));
      setFilteredVerifiedCustomers(prevCustomers => [...prevCustomers].filter(c => c.id !== customer.id));
      setShowCustomersDropdown(false);
      setOrderedSignatairesArr(prev => [...prev, {...customer, type: 'customer', mobile: {...customer.mobile, value: mobile } }])
      const sortedArr = sortArrayOfObjects(verifiedCustomers.filter(c => c.id !== customer.id), 'lastname.value', 'asc')
      const options = sortedArr.map(c => {
        return { value: c.id, label: `${c.name.value} ${c.lastname.value}` }
      })
      setVerifiedContactsDropdownOptions(options)

      let newBox = {
        id: `signature-${uuidv4()}`,
        parentId: customer.id
      }
  
      recalculateSignaturesBoxPosition(newBox, numOfPages)
      setSignaturePlaced(true)

      setShowResponseLoader(false);
    } catch (err) {
      console.log(err);
      setShowResponseLoader(false);
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' });
    }
  }, [authenticationToken, generalError, setNotification, signers, verifiedCustomers, loadPdf, mergedFilesBase64, recalculateSignaturesBoxPosition])

  // Add empty customers
  const addEmptyCustomers = (showDropdown = true) => {
    const name = `signer-${uuidv4()}`;
    const data = {
      id: name,
      email: { name: `${name}-email`, value: '' },
      name: { name: `${name}-name`, value: '' },
      lastname: { name: `${name}-lastname`, value: '' },
      mobile: { name: `${name}-mobile`, value: '' },
      error: '',
      type: 'customer',
      positions: [],
    };
    if(showDropdown) {
      data.dropdown = true
    }
    return data
  }

  // Add customer to adminSigners array and remove from siteAdmins array
  const addAdminsToSigners = useCallback(async (siteAdmin) => {
    if(adminSigners.length > 0) {
      return setNotification({ msg: 'Un seul utilisateur par agence autorisé', type: 'danger' });
    }

    let numOfPages
    if(!mergedFilesBase64) {
      setShowResponseLoader(true)
      const { pages } = await loadPdf(false)
      setCurrentDocNumOfPages(pages)
      numOfPages = pages
    }

    const updatedAdminSigners = [...adminSigners, {...siteAdmin, type: 'admin', positions: []}];
    setAdminSigners(updatedAdminSigners);
    setSiteAdmins(prevAdmins => [...prevAdmins].filter(a => a.id !== siteAdmin.id));
    setFilteredSiteAdmins(prevAdmins => [...prevAdmins].filter(a => a.id !== siteAdmin.id));
    setShowAdminsDropdown(false);
    setOrderedSignatairesArr(prev => [...prev, {...siteAdmin, type: 'admin'}])
    
    let newBox = {
      id: `signature-${uuidv4()}`,
      parentId: siteAdmin.id
    }

    recalculateSignaturesBoxPosition(newBox, numOfPages)
    setSignaturePlaced(true)

    if(generalError) {
      setGeneralError('');
    }
  }, [adminSigners, generalError, setNotification, loadPdf, mergedFilesBase64, recalculateSignaturesBoxPosition])

  // Add customer to customers array and remove from docCustomers array
  const addDocCustomersToSigners = useCallback(async (docCustomer) => {
    let numOfPages
    if(!mergedFilesBase64) {
      setShowResponseLoader(true)
      const { pages } = await loadPdf(false)
      setCurrentDocNumOfPages(pages)
      numOfPages = pages
    }

    const updated = [...customers, {...docCustomer, type: 'doc_customer', positions: []}];
    setCustomers(updated);
    setDocCustomers(prev => [...prev].filter(c => c.id !== docCustomer.id));
    setFilteredDocCustomers(prev => [...prev].filter(c => c.id !== docCustomer.id));
    setShowDocCustomersDropdown(false);
    setOrderedSignatairesArr(prev => [...prev, {...docCustomer, type: 'doc_customer'}])

    let newBox = {
      id: `signature-${uuidv4()}`,
      parentId: docCustomer.id
    }

    recalculateSignaturesBoxPosition(newBox, numOfPages)
    setSignaturePlaced(true)

    if(generalError) {
      setGeneralError('');
    }
  }, [customers, generalError, loadPdf, mergedFilesBase64, recalculateSignaturesBoxPosition])

  // Add new user(new set of fields to form) when person icon is clicked
  const addNewUser = useCallback(async (e) => {
    e.preventDefault();
    let numOfPages
    if(!mergedFilesBase64) {
      setShowResponseLoader(true)
      const { pages } = await loadPdf(false)
      setCurrentDocNumOfPages(pages)
      numOfPages = pages
    }
    const name = `signer-${new Date().getTime()}`;
    const data = {
      id: name,
      email: { name: `${name}-email`, value: '' },
      name: { name: `${name}-name`, value: '' },
      lastname: { name: `${name}-lastname`, value: '' },
      mobile: { name: `${name}-mobile`, value: '' },
      error: '',
      positions: []
    };
    const arr = [...customSigners];
    arr.push(data);
    setCustomSigners(arr);

    let newBox = {
      id: `signature-${uuidv4()}`,
      parentId: data.id
    }

    recalculateSignaturesBoxPosition(newBox, numOfPages)
    setSignaturePlaced(true)

    setOrderedSignatairesArr(prev => [...prev, data])
    if(generalError) {
      setGeneralError('');
    }
  }, [customSigners, generalError, loadPdf, mergedFilesBase64, recalculateSignaturesBoxPosition])

  // Delete user(set of input fields) if trash icon is clicked
  const deleteCustomUser = useCallback((e, index, email) => {
    e.preventDefault();
    const arr = [...customSigners];
    // let boxesToDelete = []
    // if(arr[index] && arr[index].positions) {
    //   arr[index].positions.forEach(p => {
    //     boxesToDelete.push(p.boxId)
    //   })
    // }
    // if(boxesToDelete.length > 0) {
    //   setBoxes(boxes.filter(b => !boxesToDelete.includes(b.id)))
    // }
    const newArr = arr.filter((s, idx) => idx !== index);
    setCustomSigners(newArr);

    if(signaturesBox.boxes.find(b => b.parentId === arr[index].id)) {
      const newBoxes = signaturesBox.boxes.filter(b => b.parentId !== arr[index].id)
      // console.log(signaturesBox.boxes)
      // console.log(newBoxes)
      setSignaturesBox(prev => ({...prev, boxes: newBoxes}))
    }
    setOrderedSignatairesArr(prev => prev.filter((s) => s.id !== arr[index].id));
  }, [customSigners, signaturesBox.boxes])

  // Delete user(set of input fields) if trash icon is clicked
  const deleteUser = useCallback((e, index, email) => {
    e.preventDefault();
    const arr = [...signers];
    // let boxesToDelete = []
    // if(arr[index] && arr[index].positions) {
    //   arr[index].positions.forEach(p => {
    //     boxesToDelete.push(p.boxId)
    //   })
    // }
    // if(boxesToDelete.length > 0) {
    //   setBoxes(boxes.filter(b => !boxesToDelete.includes(b.id)))
    // }
    const newArr = arr.filter((s, idx) => idx !== index);
    const customerToDelete = arr[index];
    setSigners(newArr);
    if(customerToDelete.name.value) {
      setVerifiedCustomers(prevCustomers => {
        const arr = [...prevCustomers, customerToDelete];
        return sortArrayOfObjects(arr, 'lastname.value', 'asc');
      });
      setFilteredVerifiedCustomers(prevCustomers => {
        const arr = [...prevCustomers, customerToDelete];
        return sortArrayOfObjects(arr, 'lastname.value', 'asc');
      });
      setOrderedSignatairesArr(prev => prev.filter((s) => s.id !== arr[index].id));
      const sortedArr = sortArrayOfObjects([...verifiedCustomers, customerToDelete], 'lastname.value', 'asc')
      const options = sortedArr.map(c => {
        return { value: c.id, label: `${c.name.value} ${c.lastname.value}` }
      })
      setVerifiedContactsDropdownOptions(options)
    }

    if(signaturesBox.boxes.find(b => b.parentId === arr[index].id)) {
      const newBoxes = signaturesBox.boxes.filter(b => b.parentId !== arr[index].id)
      setSignaturesBox(prev => ({...prev, boxes: newBoxes}))
    }
  }, [signers, verifiedCustomers, signaturesBox.boxes])

  // Delete admin(set of input fields) if trash icon is clicked
  const deleteAdmin = useCallback((e, index, email) => {
    e.preventDefault();
    const arr = [...adminSigners];
    // let boxesToDelete = []
    // if(arr[index] && arr[index].positions) {
    //   arr[index].positions.forEach(p => {
    //     boxesToDelete.push(p.boxId)
    //   })
    // }
    // if(boxesToDelete.length > 0) {
    //   setBoxes(boxes.filter(b => !boxesToDelete.includes(b.id)))
    // }
    const newArr = arr.filter((s, idx) => idx !== index);
    const adminToDelete = arr[index];
    setAdminSigners(newArr);
    setFilteredSiteAdmins(prevAdmins => {
      const arr = [...prevAdmins, adminToDelete];
      return sortArrayOfObjects(arr, 'lastname.value', 'asc');
    });
    setSiteAdmins(prevAdmins => {
      const arr = [...prevAdmins, adminToDelete];
      return sortArrayOfObjects(arr, 'lastname.value', 'asc');
    });

    if(signaturesBox.boxes.find(b => b.parentId === arr[index].id)) {
      const newBoxes = signaturesBox.boxes.filter(b => b.parentId !== arr[index].id)
      setSignaturesBox(prev => ({...prev, boxes: newBoxes}))
    }
    setOrderedSignatairesArr(prev => prev.filter((s) => s.id !== arr[index].id));
  }, [adminSigners, signaturesBox.boxes])

  // Delete doc customer(set of input fields) if trash icon is clicked
  const deleteDocCustomer = useCallback((e, index, email) => {
    e.preventDefault();
    const arr = [...customers];
    // let boxesToDelete = []
    // if(arr[index] && arr[index].positions) {
    //   arr[index].positions.forEach(p => {
    //     boxesToDelete.push(p.boxId)
    //   })
    // }
    // if(boxesToDelete.length > 0) {
    //   setBoxes(boxes.filter(b => !boxesToDelete.includes(b.id)))
    // }
    const newArr = arr.filter((s, idx) => idx !== index);
    const toDelete = arr[index];
    setCustomers(newArr);
    setFilteredDocCustomers(prev => {
      const arr = [...prev, toDelete];
      return sortArrayOfObjects(arr, 'lastname.value', 'asc');
    });
    setDocCustomers(prev => {
      const arr = [...prev, toDelete];
      return sortArrayOfObjects(arr, 'lastname.value', 'asc');
    });

    if(signaturesBox.boxes.find(b => b.parentId === arr[index].id)) {
      const newBoxes = signaturesBox.boxes.filter(b => b.parentId !== arr[index].id)
      setSignaturesBox(prev => ({...prev, boxes: newBoxes}))
    }
    setOrderedSignatairesArr(prev => prev.filter((s) => s.id !== arr[index].id));
  }, [customers, signaturesBox.boxes])

  // Place signature
  const placeSignatureHandler = useCallback(async (e, boxId, type, parentId, contactType) => {
    e.preventDefault()
    if(!boxId) return
    // setPositionMode(mode)
    // setCheckboxToUpdate(checkboxId)
    // console.log(id, type)

    const findBox = boxes.find(box => box.id === boxId)
    if(findBox) {
      setActiveBox(findBox)
      setBoxes(boxes.map(b => b.id === findBox.id ? {...findBox, disabled: false} : {...b, disabled: true}))
    }else {
      let box
      if(type === 'signature') {
        box = { id: boxId, parentId, type, top: 300, left: 280, width: 200, height: 100, disabled: false, saved: false }
        setAddSignaturePositionTo({ id: parentId, type: contactType })
      }else if(type === 'checkbox') {
        box = { id: boxId, type, top: 100, left: 100, width: 20, height: 20, disabled: false, saved: false }
      }else if(type === 'label') {
        box = { id: boxId, parentId, type, top: 100, left: 100, width: 150, height: 50, disabled: false, saved: false }
      }
      setBoxes([...boxes.map(b => ({...b, disabled: true})), box])
    }
    
    if(!mergedFilesBase64) {
      setShowResponseLoader(true)
      loadPdf()
    }else {
      setShowPlaceSignatureInterface(true)
    }
  }, [boxes, loadPdf, mergedFilesBase64])

  // Attachment change handler
  const attachmentChangeHandler = useCallback((index, sign) => {
    let da = [...attachments]
    da[index].sign = sign
    setAttachments(da)
    setPlacementInterface('')
    if(mergedFilesBase64) {
      setMergedFilesBase64(null)
    }
  }, [attachments, mergedFilesBase64])

  // Search customers change handler
  const searchCustomersChangeHandler = useCallback((e) => {
    if(verifiedCustomersTimeout) {
      clearTimeout(verifiedCustomersTimeout)
    }
    const value = e.target.value
    if(value.trim() === '') {
      setFilteredVerifiedCustomers([])
      setLoadingVerifiedCustomers(false)
      setVerifiedCustomersSearched(false)
      // setFilteredVerifiedCustomers(verifiedCustomers);
    }else {
      // const arr = [...verifiedCustomers].filter(a => a.lastname.value.toLowerCase().includes(value.trim().toLowerCase()));
      // setFilteredVerifiedCustomers(arr);
      setLoadingVerifiedCustomers(true)
      setFilteredVerifiedCustomers([])
      verifiedCustomersTimeout = setTimeout(async () => {
        const res = await fetch_customers(authToken, value)
        if(res && Array.isArray(res)) {
          const customersArr = []
          res.forEach(customer => {
            const id = customer.id
            if(config.environment === 'development') {
              if(customer.email && customer.firstname && customer.lastname && !customersIds.includes(id)) {
                customersArr.push({
                  id,
                  email: { name: `${id}-email`, value: customer.email },
                  name: { name: `${id}-name`, value: customer.firstname },
                  lastname: { name: `${id}-lastname`, value: customer.lastname },
                  mobile: { name: `${id}-mobile`, value: processedPhoneNumber(customer.mobile_phone ? customer.mobile_phone : '') },
                  error: '',
                  type: 'customer',
                });
              }
            }else {
              if(customer.email && customer.firstname && customer.lastname && customer.certification === 1 && !customersIds.includes(id)) {
                customersArr.push({
                  id,
                  email: { name: `${id}-email`, value: customer.email },
                  name: { name: `${id}-name`, value: customer.firstname },
                  lastname: { name: `${id}-lastname`, value: customer.lastname },
                  mobile: { name: `${id}-mobile`, value: processedPhoneNumber(customer.mobile_phone ? customer.mobile_phone : '') },
                  error: '',
                  type: 'customer',
                })
              }
            }
          })
          const sortedArr = await sortArrayOfObjects(customersArr, 'lastname.value', 'asc')
          setFilteredVerifiedCustomers(sortedArr)
          setVerifiedCustomersSearched(true)
        }
        setLoadingVerifiedCustomers(false)
      }, 1000)
    }
    setSearchCustomers(value);
  }, [authToken, customersIds])

  // Search admins change handler
  const searchAdminsChangeHandler = useCallback((e) => {
    const value = e.target.value;
    if(value.trim() === '') {
      setFilteredSiteAdmins(siteAdmins);
    }else {
      const arr = [...siteAdmins].filter(a => a.lastname.value.toLowerCase().includes(value.trim().toLowerCase()));
      setFilteredSiteAdmins(arr);
    }
    setSearchAdmins(value);
  }, [siteAdmins])

  // Search doc customers change handler
  const searchDocCustomersChangeHandler = useCallback((e) => {
    const value = e.target.value;
    if(value.trim() === '') {
      setFilteredDocCustomers(docCustomers);
    }else {
      const arr = [...docCustomers].filter(a => a.lastname.value.toLowerCase().includes(value.trim().toLowerCase()));
      setFilteredDocCustomers(arr);
    }
    setSearchDocCustomers(value);
  }, [docCustomers])

  // Signature position save handler
  const signaturePositionSaveHandler = useCallback(async (box, mode = 'create') => {
    setActiveBox(null)
    setShowPlaceSignatureInterface(false)
    
    // const allSigners = [...customSigners, ...adminSigners, ...signers, ...customers]
    // const find = allSigners.find(s => s.id === box.parentId)
    // console.log(allSigners)
    // console.log(find, box)
    if(box.type === 'signature') {
      // let sArr = [...signaturePositions]
      // let newPos = {
      //   page: box.page,
      //   width: box.width,
      //   height: box.height,
      //   left: box.left,
      //   top: box.top,
      //   boxId: box.id,
      //   parentId: box.parentId
      // }
      // if(find) {
      //   let arr = []
      //   if(find.positions) {
      //     if(mode === 'create') {
      //       arr = [...find.positions, newPos]
      //       sArr.push(newPos)
      //     }else {
      //       arr = [...find.positions].map(p => p.boxId === box.id ? newPos : p) 
      //       sArr = sArr.map(p => p.boxId === box.id ? newPos : p)
      //     }
      //   }else {
      //     arr = [newPos]
      //     sArr.push(newPos)
      //   }
      //   find.positions = arr
      //   setSignaturePositions(sArr)        
      // }

      // if(mode === 'create') {
      //   setOrderedSignatairesArr(prev => setSignersAddPositionHelper(prev, addSignaturePositionTo.id, newPos))
      // }else {
      //   setOrderedSignatairesArr(prev => setSignersAddPositionHelper(prev, editSignaturePositionOn.id, newPos))
      // }
      // didSignatureTemplateChanged(sArr)

    }else if(box.type === 'checkbox') {
      if(mode === 'create') {
        let checkbox = {
          ...box,
          required: false,
          label: {}
        }
        setFieldsPositions([...fieldsPositions, checkbox])
      }else {
        setFieldsPositions([...fieldsPositions].map(f => f.id === box.id ? {...f, ...box} : f))
      }
    }else if(box.type === 'label') {
      const checkbox = fieldsPositions.find(f => f.id === box.parentId)
      if(checkbox) {
        if(mode === 'create') {
          checkbox.label = {
            ...box,
            value: ''
          }
        }else {
          checkbox.label = {
            ...box
          }
        }
      }
    }
  }, [fieldsPositions])

  // Set signers - delete position helper
  const setSignersDeletePositionHelper = useCallback((prev, signer, index) => {
    const findUser = [...prev].find(c => c.id === signer.id)
    if(!findUser) return 

    const copyOfUser = {...findUser}
    const position = [...copyOfUser.positions]
    if(position[index] && position[index].boxId) {
      setBoxes(boxes.filter(b => b.id !== position[index].boxId))
      setSignaturePositions(signaturePositions.filter(pos => pos.boxId !== position[index].boxId))
    }
    position.splice(index, 1)
    copyOfUser.positions = position

    return [...prev].map(c => c.id === copyOfUser.id ? copyOfUser : c);

    // const findUser = [...prev].find(c => c.id === id);
    // const copyOfUser = {...findUser};
    // if(findUser) {
    //   const position = [...copyOfUser.positions];
    //   if(position[index].boxId) {
    //     setBoxes(boxes.filter(b => b.id !== position[index].boxId))
    //   }
    //   position.splice(index, 1);
    //   copyOfUser.positions = position;
    //   let updatedSignaturePositions = signaturePositions.filter(pos => pos.id !== findUser.positions[index].id);
    //   setSignaturePositions(updatedSignaturePositions);
    //   didSignatureTemplateChanged(updatedSignaturePositions, fieldsPositions);
    // }
    // return [...prev].map(c => c.id === copyOfUser.id ? copyOfUser : c);
  }, [boxes, signaturePositions])

  // Check if signature position is set, if not enable attachments 
  useEffect(() => {
    const isPositionSet = () => {
      if(signaturesBox.boxes.length > 0) return true
      if(fieldsPositions.length > 0) return true
      return false
    }
    if(!isPositionSet()) {
      setSignaturePlaced(false);
    }
  }, [fieldsPositions, signaturesBox.boxes]);
  // }, [signer, customSigners, signers, adminSigners]);

  // Update field required prop
  const updateFieldRequiredProp = useCallback((checked, id) => {
    const arr = [...fieldsPositions]
    const field = arr.find(f => f.id === id)
    const updatedField = { ...field, required: checked }
    const updatedPositions = arr.map(f => f.id === id ? updatedField : f)
    setFieldsPositions(updatedPositions)
    setBoxes(boxes.map(box => box.id === id ? {...box, required: checked} : box))
    // didSignatureTemplateChanged(null, updatedPositions)
    // setFieldsPositions(prev => {
    //   const arr = [...prev];
    //   const field = arr.find(f => f.id === id);
    //   const updatedField = { ...field, REQUIS: checked };
    //   const updatedPositions = arr.map(f => f.id === id ? updatedField : f);
    //   didSignatureTemplateChanged(null, updatedPositions);
    //   return updatedPositions;
    // });
  }, [boxes, fieldsPositions])

  // Update field label text
  const updateLabel = useCallback((e, id, labelId) => {
    const arr = [...fieldsPositions]
    const field = fieldsPositions.find(f => f.id === id)
    const updatedField = { ...field, label: { ...field.label, value: e.target.value } }
    const updatedPositions = arr.map(f => f.id === id ? updatedField : f)
    setFieldsPositions(updatedPositions)
    setBoxes(boxes.map(box => box.id === labelId ? {...box, value: e.target.value} : box))
    // didSignatureTemplateChanged(null, updatedPositions)
    // setFieldsPositions(prev => {
    //   const arr = [...prev];
    //   const field = arr.find(f => f.id === id);
    //   const updatedField = { ...field, label: { ...field.label, LIBELLE: e.target.value } };
    //   const updatedPositions = arr.map(f => f.id === id ? updatedField : f);
    //   didSignatureTemplateChanged(null, updatedPositions);
    //   return updatedPositions;
    // });
  }, [boxes, fieldsPositions])

  // Delete custom field
  const deleteCustomField = useCallback((id) => {
    let updatedBoxes = [...boxes].filter(box => box.id !== id)
    let field = fieldsPositions.find(f => f.id === id)
    if(field && field.label) {
      updatedBoxes = updatedBoxes.filter(box => box.id !== field.label.id)
    }
    setBoxes(updatedBoxes)
    const updatedPositions = fieldsPositions.filter(f => f.id !== id)
    setFieldsPositions(updatedPositions)
    // didSignatureTemplateChanged(signaturePositions, updatedPositions)

    // const updatedPositions = fieldsPositions.filter(f => f.id !== id);
    // didSignatureTemplateChanged(signaturePositions, updatedPositions);
    // setFieldsPositions(updatedPositions);
  }, [boxes, fieldsPositions])

  // Remove label from custom field
  const removeLabel = useCallback((id) => {
    const arr = [...fieldsPositions]
    const field = arr.find(f => f.id === id)
    const boxId = field.label.id 
    setBoxes(boxes.filter(box => box.id !== boxId))
    const updatedField = { ...field, label: { } }
    if(updatedField.error === 'Label cannot be empty') {
      updatedField.error = ''
    }
    const updatedPositions = arr.map(f => f.id === id ? updatedField : f)
    setFieldsPositions(updatedPositions)
    // didSignatureTemplateChanged(signaturePositions, updatedPositions)
    // setFieldsPositions(prev => {
    //   const arr = [...prev];
    //   const field = arr.find(f => f.id === id);
    //   const updatedField = { ...field, label: { } };
    //   if(updatedField.error === 'Label cannot be empty') {
    //     updatedField.error = '';
    //   }
    //   const updatedPositions = arr.map(f => f.id === id ? updatedField : f);
    //   didSignatureTemplateChanged(signaturePositions, updatedPositions);
    //   return updatedPositions;
    // });
  }, [boxes, fieldsPositions])

  // Save signature template
  const saveSignatureTemplate = async (e) => {
    e.preventDefault();
    if(signatureTemplateName.trim() === '') {
      return setSignatureTemplateNameError('Le nom est obligatoire');
    }
    
    if(signatureTemplates.find(tmpl => tmpl.name === signatureTemplateName.trim())) {
      return setNotification({ msg: 'Le modèle portant ce nom existe déjà', type: 'danger' });
    }
    
    if(fieldsPositions.length === 0 && signaturesBox.boxes.length === 0) {
      return setNotification({ msg: 'Veuillez définir un emplacement de signatures ou de cases à cocher', type: 'danger' });
    }

    let signaturesBoxesArr = []
    signaturesBox.boxes.forEach(b => {
      signaturesBoxesArr.push({
        id: b.id
      })
    })

    try {
      const signatureTemplate = {
        name: signatureTemplateName,
        checkbox_positions: fieldsPositions.map(pos => {
          let obj = {
            PAGE: +pos.page + 1,
            TOP: +pos.top,
            LEFT: +pos.left,
            WIDTH: +pos.width,
            HEIGHT: +pos.height,
            TYPE: 'CHECKBOX',
            REQUIS: pos.required
          }
          if(pos.label && Object.keys(pos.label).length > 0) {
            obj.label = {
              PAGE: +pos.label.page + 1,
              TOP: +pos.label.top,
              LEFT: +pos.label.left,
              WIDTH: +pos.label.width,
              HEIGHT: +pos.label.height,
              TYPE: 'LABEL',
              LIBELLE: pos.label.value
            }
          }
          return obj 
        }),
        signatures_box: {
          box_height: signaturesBox.boxH,
          box_width: signaturesBox.boxW,
          page: signaturesBox.page,
          top: signaturesBox.top,
          left: signaturesBox.left,
          width: signaturesBox.width,
          height: signaturesBox.height,
          margin_horiz: signaturesBox.mH,
          margin_vert: signaturesBox.mV,
          boxes: signaturesBoxesArr
        },
      }
      setShowResponseLoader(true)
      const id = await createSignatureTemplate(signatureTemplate)
      setShowResponseLoader(false)
      setNotification({ msg: 'Modèle sauvegardé', type: 'success' })
      setShowChooseTemplates(true)
      setActiveSignatureTemplate(id)
      setActiveTemplateFieldsPositions(fieldsPositions)
      setActiveTemplateSignaturePositions(signaturePositions)
      return id
    } catch (err) { 
      console.log(err);
    }
  }

  // Update signature template
  const updateSignatureTemplateHandler = async () => {
    if(fieldsPositions.length === 0 && signaturesBox.boxes.length === 0) {
      return setNotification({ msg: 'Veuillez définir un emplacement de signatures ou de cases à cocher', type: 'danger' });
    }

    const foundSelectedTemplate = signatureTemplates.find(tmpl => tmpl.id === activeSignatureTemplate);
    if(foundSelectedTemplate) {
      setShowResponseLoader(true)
      try {
        let cPositions = fieldsPositions.map(pos => {
          let obj = {
            PAGE: +pos.page + 1,
            TOP: +pos.top,
            LEFT: +pos.left,
            WIDTH: +pos.width,
            HEIGHT: +pos.height,
            TYPE: 'CHECKBOX',
            REQUIS: pos.required
          }
          if(pos.label && Object.keys(pos.label).length > 0) {
            obj.label = {
              PAGE: +pos.label.page + 1,
              TOP: +pos.label.top,
              LEFT: +pos.label.left,
              WIDTH: +pos.label.width,
              HEIGHT: +pos.label.height,
              TYPE: 'LABEL',
              LIBELLE: pos.label.value
            }
          }
          return obj 
        })
        let signaturesBoxesArr = []
        signaturesBox.boxes.forEach(b => {
          signaturesBoxesArr.push({
            id: b.id
          })
        })
        let sBox = {
          box_height: signaturesBox.boxH,
          box_width: signaturesBox.boxW,
          page: signaturesBox.page,
          top: signaturesBox.top,
          left: signaturesBox.left,
          width: signaturesBox.width,
          height: signaturesBox.height,
          margin_horiz: signaturesBox.mH,
          margin_vert: signaturesBox.mV,
          boxes: signaturesBoxesArr
        }
        await updateSignatureTemplate(foundSelectedTemplate.id, { checkbox_positions: cPositions, signatures_box: sBox })
        setActiveTemplateFieldsPositions(fieldsPositions)
        setActiveTemplateSignaturesBox(signaturesBox)
        setNotification({ msg: 'Modèle mis à jour', type: 'success' });
      } catch (err) {
        setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' });
      }
      setShowResponseLoader(false);
    }else {
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' });
    }
  }
  
  // Template selected handler
  const templateSelectedHandler = (value) => {
    if(activeSignatureTemplate === value) return

    setActiveSignatureTemplate(value)
    let sArr = !isNotSQHorCAIpartner ? [...signers, ...adminSigners] : [...customSigners, ...customers, ...adminSigners]

    if(value === '') {
      setFieldsPositions([])
      setSignaturesBox(SIGNATURES_BOX_DEFAULT)
      setSignaturePlaced(false)
      return
    }

    const foundTemplate = signatureTemplates.find(tmpl => tmpl.id === value)
    if(foundTemplate) {
      let fPositions = [] 
      let boxesArr = []
      foundTemplate.checkbox_positions.forEach(pos => {
        let checkboxId = `checkbox-${uuidv4()}`
        let f = {
          page: pos.PAGE - 1,
          top: pos.TOP,
          left: pos.LEFT,
          width: pos.WIDTH,
          height: pos.HEIGHT,
          required: pos.REQUIS,
          id: checkboxId,
          type: 'checkbox'
        }
        let box = { id: checkboxId, type: 'checkbox', page: pos.PAGE - 1, top: pos.TOP, left: pos.LEFT, width: pos.WIDTH, height: pos.HEIGHT, disabled: true, saved: true }
        boxesArr.push(box)
        if(pos.label && Object.keys(pos.label).length > 0) {
          let labelId = `label-${uuidv4()}`
          f.label = {
            page: pos.label.PAGE - 1,
            top: pos.label.TOP,
            left: pos.label.LEFT,
            width: pos.label.WIDTH,
            height: pos.label.HEIGHT,
            value: pos.label.LIBELLE,
            parentId: checkboxId,
            id: labelId,
            type: 'label'
          }
          let box = { id: labelId, parentId: checkboxId, type: 'label', top: pos.label.TOP, page: pos.label.PAGE - 1, left: pos.label.LEFT, width: pos.label.WIDTH, height: pos.label.HEIGHT, disabled: true, saved: true, value: pos.label.LIBELLE, }
        boxesArr.push(box)
        }
        fPositions.push(f)
      })
      setFieldsPositions(fPositions)
      setActiveTemplateFieldsPositions(fPositions)

      let customersArr = []
      if(sArr.length < foundTemplate.signatures_box.boxes.length) {
        let diff = foundTemplate.signatures_box.boxes.length - sArr.length
        for(let i = 0; i < diff; i++) {
          let data = addEmptyCustomers(isNotSQHorCAIpartner ? false : true)
          customersArr.push(data)
        }
        sArr = [...sArr, ...customersArr]
      }
      if(isNotSQHorCAIpartner) {
        setCustomSigners(prev => [...prev, ...customersArr])
      }else {
        setSigners(prev => [...prev, ...customersArr])
      }

      const tmpltBox = foundTemplate.signatures_box
      let sBoxesArr = []
      for(let i = 0; i < sArr.length; i++) {
        if(tmpltBox.boxes[i]) {
          sBoxesArr.push({
            parentId: sArr[i].id,
            id: tmpltBox.boxes[i].id
          })
        }else {
          break
        }
      }

      let sBox = {
        page: tmpltBox.page, 
        top: tmpltBox.top, 
        left: tmpltBox.left, 
        width: tmpltBox.width,
        height: tmpltBox.height,
        boxes: sBoxesArr,
        active: false,
        boxW: tmpltBox.box_width,
        boxH: tmpltBox.box_height,
        mH: tmpltBox.margin_horiz,
        mV: tmpltBox.margin_vert
      }
      // console.log(sBox)
      setActiveTemplateSignaturesBox(sBox)
      setSignaturesBox(sBox)
      setBoxes(boxesArr)

      setSignatureTemplateName(foundTemplate.name)
      setSignaturePlaced(true)
    }
  }

  // Choose template switch change handler
  const chooseTemplateSwitchChangeHandler = (e) => {
    setShowChooseTemplates(e.target.checked)
    if(!e.target.checked) {
      setActiveSignatureTemplate('')
      setFieldsPositions([])
      setSignaturesBox(SIGNATURES_BOX_DEFAULT)
      setBoxes([])
      setSignaturePlaced(false)
    }
  }

  // Check if signature template changed
  const didSignatureTemplateChanged = useMemo(() => {
    const foundTemplate = signatureTemplates.find(tmpl => tmpl.id === activeSignatureTemplate)
    let changed = false
    let changes = {}
    if(foundTemplate) {
      const c_positions = activeTemplateFieldsPositions
      let changed_c_positions = []
      let cPositionsCheck = fieldsPositions
      // console.log(s_positions)
      // console.log(signaturePositions)
      cPositionsCheck.forEach(pos => {
        let foundPos = c_positions.find(p => p.id === pos.id);
        if(!foundPos) {
          changed = true;
          changed_c_positions.push({...pos, action: 'added'});
        }else {
          // check if updated
          let updated = false;
          for(let key in pos) {
            if(!updated) {
              if(key !== 'label' && pos[key] !== foundPos[key]) {
                changed = true;
                changed_c_positions.push({...pos, action: 'updated'});
                updated = true;
              }else if(key === 'label') {
                if(!updated && Object.keys(pos.label).length !== Object.keys(foundPos.label).length) {
                  changed = true;
                  changed_c_positions.push({...pos, action: 'updated'});
                  updated = true;
                }else {
                  for(let labelKey in pos.label) {
                    if(!updated && pos.label[labelKey] !== foundPos.label[labelKey]) {
                      changed = true;
                      changed_c_positions.push({...pos, action: 'updated'});
                      updated = true;
                    }
                  }
                }
              }
            }
          }
        }
      });
      c_positions.forEach(pos => {
        if(!cPositionsCheck.find(p => p.id === pos.id)) {
          changed = true;
          changed_c_positions.push({...pos, action: 'removed'});
        }
      });

      const s_box = activeTemplateSignaturesBox
      // console.log(s_box)
      // console.log(signaturesBox)
      let s_box_changes = []
      for(let key in s_box) {
        if((key !== 'boxes' && key !== 'active') && signaturesBox[key] !== s_box[key]) {
          changed = true
          s_box_changes.push({ action: 'updated', key })
        }else if(key === 'boxes') {
          if(s_box.boxes.length < signaturesBox.boxes.length) {
            changed = true
            s_box_changes.push({ action: 'added', key })
          }else if(s_box.boxes.length > signaturesBox.boxes.length) {
            changed = true
            s_box_changes.push({ action: 'removed', key })
          }else {
            // eslint-disable-next-line no-loop-func
            s_box.boxes.forEach(b => {
              if(!signaturesBox.boxes.find(box => box.id === b.id)) {
                changed = true
                s_box_changes.push({ action: 'added', key })
              }
            })
            // eslint-disable-next-line no-loop-func
            signaturesBox.boxes.forEach(b => {
              if(!s_box.boxes.find(box => box.id === b.id)) {
                changed = true
                s_box_changes.push({ action: 'removed', key })
              }
            })
          }
        }
      }

      changes.checkbox_positions = changed_c_positions
      changes.signatures_box = s_box_changes
    }

    // console.log(changes)

    return {
      changed,
      changes
    }
  }, [activeSignatureTemplate, activeTemplateFieldsPositions, fieldsPositions, signatureTemplates, activeTemplateSignaturesBox, signaturesBox])

  // On edit position
  const handleEditCheckboxPosition = useCallback(async (field) => {
    let box = boxes.find(b => b.id === field.id)
    if(!box) return console.log('no box found')
    setBoxes(boxes.map(b => b.id === box.id ? {...box, disabled: false} : {...b, disabled: true}))
    setActiveBox({...box, disabled: false})
    if(!mergedFilesBase64) {
      setShowResponseLoader(true)
      await loadPdf()
    }else {
      setShowPlaceSignatureInterface(true)
    }
  }, [boxes, loadPdf, mergedFilesBase64])

  // Verified contacts change handler
  const verifiedContactsChangeHandler = useCallback(async (option, signer, index) => {
    const signerCopy = {...signer}
    // return
    if(option.value === '') return
    try {
      let res;
      let id = signer.id;
      let name = signer.name.value;
      let lastname = signer.lastname.value;
      let mobile = signer.mobile.value;
      let email = signer.email.value;
      if(!signer.fetched || signer.id !== option.value) {
        setShowResponseLoader(true);
        res = await fetch_customer_by_id(authenticationToken, option.value, true);
        id = res.id;
        name = res.firstname;
        lastname = res.lastname;
        mobile = res.mobile_phone;
        email = res.email;
      }
      const foundSigner = signers.find(s => s.id === signer.id)
      foundSigner.id = id;
      foundSigner.name = {...foundSigner.name, value: name}
      foundSigner.lastname = {...foundSigner.lastname, value: lastname}
      foundSigner.email = {...foundSigner.email, value: email}
      foundSigner.mobile = {...foundSigner.mobile, value: mobile}
      foundSigner.fetched = true
      setSigners(prev => prev.map(s => s.id === foundSigner.id ? foundSigner : s))
      if(generalError) {
        setGeneralError('');
      }
      let verifiedCustomersCopy = [...verifiedCustomers]
      if(signerCopy.name.value) {
        verifiedCustomersCopy.push(signerCopy)
        verifiedCustomersCopy = sortArrayOfObjects(verifiedCustomersCopy, 'lastname.value', 'asc')
      }
      setVerifiedCustomers(verifiedCustomersCopy.filter(c => c.id !== option.value));
      setFilteredVerifiedCustomers(verifiedCustomersCopy.filter(c => c.id !== option.value));
      setOrderedSignatairesArr(prev => [...prev, {...signer, mobile: {...signer.mobile, value: mobile } }]);
      const options = verifiedCustomersCopy.map(c => {
        return { value: c.id, label: `${c.name.value} ${c.lastname.value}`, disabled: c.id === option.value }
      })
      setVerifiedContactsDropdownOptions(options)
      setShowResponseLoader(false);
    } catch (err) {
      console.log(err);
      setShowResponseLoader(false);
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' });
    }
  }, [authenticationToken, generalError, setNotification, signers, verifiedCustomers])

  // On place signature modal close
  const handleClosePlaceSignatureModal = useCallback(() => {
    setShowPlaceSignatureInterface(false)
    setActiveBox(null)
  }, [])

  // On place signature modal open
  const handleOpenPlaceSignatureModal = useCallback(async () => {
    if(!mergedFilesBase64) {
      setShowResponseLoader(true)
      await loadPdf(false)
    }
    setShowPlaceSignatureInterface(true)
    setSignaturesBox({ ...signaturesBox, active: true })
    setActiveBox(null)
    setBoxes(boxes.map(b => ({...b, disabled: true})))
  }, [signaturesBox, boxes, loadPdf, mergedFilesBase64])

  const getTotalSizeOfSelectedDocuments = useCallback(() => {
    let total = 1
    for(let i = 0; i < attachments.length; i++) {
      if(attachments[i].sign && attachments[i].size) {
        total += attachments[i].size
      }
    }
    total += fileSize
    return total 
  }, [attachments, fileSize])

  const saveTemplateAvailable = () => {
    if(isMlsPartner(partner)) return false
    return true
  }

  return(
    <Modal onClose={onClose} className="signature-modal">
      {!requestSent && canUseSignatures ? 
        <div className="signature-modal-inner">
          <h2>{documentName}</h2>
          <div className="signature-modal-inner__head">
            <ul>
              <li className="active no-cursor">Demande de signature</li>
            </ul>
          </div>
          <div className="signature-modal-inner__body">
            <form className="form" onSubmit={submitHandler}>
              {availableOn(['development', 'staging']) && signatureTemplates && signatureTemplates.length > 0 && 
              <ChooseTemplateSection 
                showChooseTemplates={showChooseTemplates}
                chooseTemplateSwitchChangeHandler={chooseTemplateSwitchChangeHandler}
                signatureTemplatesOptions={signatureTemplatesOptions}
                templateSelectedHandler={templateSelectedHandler}
                activeSignatureTemplate={activeSignatureTemplate}
                setShowSignatureTemplatesModal={setShowSignatureTemplatesModal} />}

              <div className="signature-modal-inner__section">
                <p className="form__subtitle">Émetteur</p>
                <div className="form__group">
                  <Input 
                    name="emmeteur"
                    value={emmeteur}
                    onChange={(e) => setEmmeteur(e.target.value)}
                    label="Email"
                    formEl 
                    error={emmeteurError}
                  />
                </div>
              </div>

              <div className="signature-modal-inner__section">
                <div className="form__subtitle">Envoyez les demandes de signature à :</div>
                {isNotSQHorCAIpartner && 
                  <Contacts 
                    title="Ajouter des contacts manuellement"
                    type="manual"
                    addNewUser={addNewUser}
                    signers={customSigners}
                    verifiedContactsDropdownOptions={verifiedContactsDropdownOptions}
                    verifiedContactsChangeHandler={verifiedContactsChangeHandler}
                    changeValueHandler={changeValueHandlerForCustomSigners}
                    // editSignaturePositionHandler={handleEditPosition}
                    // deletePositionHandler={deletePositionHandler}
                    // placeSignatureHandler={placeSignatureHandler}
                    deleteUser={deleteCustomUser}
                    useCustomPlacementTool={true}
                  />
                }

                {isNotSQHorCAIpartner &&
                  <Contacts 
                    title="Contacts complétés dans le document"
                    noDataText="Pas de contacts"
                    type="doc_customers"
                    setShowDropdown={setShowDocCustomersDropdown}
                    showDropdown={showDocCustomersDropdown}
                    search={searchDocCustomers}
                    searchChangeHandler={searchDocCustomersChangeHandler}
                    filteredContacts={filteredDocCustomers}
                    addContactsToSigners={addDocCustomersToSigners}
                    signers={customers}
                    changeValueHandler={changeValueHandlerForDocCustomers}
                    // editSignaturePositionHandler={handleEditPosition}
                    // deletePositionHandler={deletePositionHandler}
                    // placeSignatureHandler={placeSignatureHandler}
                    deleteUser={deleteDocCustomer}
                    useCustomPlacementTool={true}
                  />
                }

                {!isNotSQHorCAIpartner && <Contacts 
                  title="Contacts certifiés"
                  noDataText="Pas de contacts certifiés"
                  type="customers"
                  setShowDropdown={setShowCustomersDropdown}
                  showDropdown={showCustomersDropdown}
                  search={searchCustomers}
                  searchChangeHandler={searchCustomersChangeHandler}
                  filteredContacts={filteredVerfifiedCustomers}
                  addContactsToSigners={addCustomerToSigners}
                  signers={signers}
                  verifiedContactsDropdownOptions={verifiedContactsDropdownOptions}
                  verifiedContactsChangeHandler={verifiedContactsChangeHandler}
                  changeValueHandler={changeValueHandlerForSigners}
                  // editSignaturePositionHandler={handleEditPosition}
                  // deletePositionHandler={deletePositionHandler}
                  // placeSignatureHandler={placeSignatureHandler}
                  deleteUser={deleteUser}
                  useCustomPlacementTool={true}
                  loadingVerifiedCustomers={loadingVerifiedCustomers}
                  verifiedCustomersSearched={verifiedCustomersSearched}
                  onSetVerifiedCustomersSearched={setVerifiedCustomersSearched}
                />}

                <Contacts 
                  title="Utilisateur agence"
                  noDataText="Pas d'utilisateurs de l'agence"
                  type="admins"
                  setShowDropdown={setShowAdminsDropdown}
                  showDropdown={showAdminsDropdown}
                  search={searchAdmins}
                  searchChangeHandler={searchAdminsChangeHandler}
                  filteredContacts={filteredSiteAdmins}
                  addContactsToSigners={addAdminsToSigners}
                  signers={adminSigners}
                  changeValueHandler={changeValueHandlerForAdmins}
                  // editSignaturePositionHandler={handleEditPosition}
                  // deletePositionHandler={deletePositionHandler}
                  // placeSignatureHandler={placeSignatureHandler}
                  deleteUser={deleteAdmin}
                  useCustomPlacementTool={true}
                />

              </div>

              {signaturesBox.boxes.length > 0 && (
                <div className="signature-modal-inner__section">
                  <div className="signature-positions">
                    <p className="signature-positions__page">Page {signaturesBox.page + 1}</p>
                  </div>
                  <Button 
                    text={"Voir l'emplacement de la (des) signature(s)"}
                    onButtonClick={handleOpenPlaceSignatureModal} 
                    primaryLight 
                    type="button"
                  />
                </div>
              )}

              <div className="signature-modal-inner__section">
                <p className="form__subtitle form__subtitle--2">Sélectionner les annexes</p>
                <Attachments 
                  signaturePlaced={signaturePlaced} 
                  attachments={attachments} 
                  attachmentChangeHandler={attachmentChangeHandler} 
                  getTotalSizeOfSelectedDocuments={getTotalSizeOfSelectedDocuments}
                />
              </div>

              {(partner === 'squarehabitat' || partner === 'cai') && <CustomFields 
                placeSignatureHandler={placeSignatureHandler}
                fieldsPositions={fieldsPositions}
                updateFieldRequiredProp={updateFieldRequiredProp}
                updateLabel={updateLabel}
                removeLabel={removeLabel}
                deleteCustomField={deleteCustomField}
                useCustomPlacementTool={true}
                handleEditCheckboxPosition={handleEditCheckboxPosition}
              />}

              <div className="signature-modal-inner__section">
                <p className="form__subtitle form__subtitle--2">Ordre d’envoi</p>
                <div className="checkbox-wrapper">
                  <Switch checked={orderRadioValue === 'yes'} onChange={(e) => {
                    setSendInOrder(e.target.checked)
                    setOrderRadioValue(e.target.checked ? 'yes' : 'no')
                  }} />
                </div>
                {orderRadioValue === 'yes' && <Button 
                  text="Définir l'ordre d'envoi"
                  primary
                  disabled={isNotSQHorCAIpartner 
                    ? customers.length === 0 && customSigners.length === 0
                    : signers.length === 0
                  }
                  onButtonClick={() => setShowOrderCustomersModal(true)} 
                />}
              </div>

              {(availableOn(['development', 'staging']) && saveTemplateAvailable()) && activeSignatureTemplate === '' && <SaveTemplateSection showSaveTemplate={showSaveTemplate} setShowSaveTemplate={setShowSaveTemplate} signatureTemplateName={signatureTemplateName} signatureTemplateNameError={signatureTemplateNameError} setSignatureTemplateName={setSignatureTemplateName} setSignatureTemplateNameError={setSignatureTemplateNameError} saveSignatureTemplate={saveSignatureTemplate} />}

              {availableOn(['development', 'staging']) && didSignatureTemplateChanged.changed && <UpdateTemplateSection didSignatureTemplateChanged={didSignatureTemplateChanged} updateSignatureTemplateHandler={updateSignatureTemplateHandler} setShowSaveUpdatedTemplateModal={setShowSaveUpdatedTemplateModal} />}

              {generalError && <p className="u-error mb-20">{generalError}</p>}

              {requestFailed && <Message type="error" msg={signatureErrors.join('')} />}

              <div className="form__actions">
                <Button 
                  text="Annuler" 
                  transparent
                  onButtonClick={onClose}
                  medium
                />
                <Button 
                  text="Envoyer"
                  primary
                  onButtonClick={validateForm}
                  medium
                  // disabled={creditsFetched ? showCredits ? creditsLeft < 1 : false : true}
                />
                {/* {showCredits && <div className="credits-wrapper">Crédits restants: {creditsFetched ? creditsLeft : <Loader mini primary normal />}</div>} */}
              </div>
            </form>
          </div>
        </div>
        :
        !siteConfigsLoaded 
          ? <div className="loader-wrapper"><Loader primary normal /> </div>
          : !canUseSignatures && <SignaturesNotAvailable store={isNotSQHorCAIpartner ? urlStore : null} />
      }

      {requestSent && 
        <div className="signatures-success">
          <div className="signatures-success-top">
            <h2 className="">Signatures</h2>
          </div>
          <Message type="success" msg={successMessage} />
          <div className="buttons-wrapper">
            {/* <Button text="Retour" onButtonClick={() => {setRequestSent(false); setCreditsFetched(false)}} /> */}
            <Button text="Retour" onButtonClick={onClose} />
            <a href={urlToDoc} className="button" target="_blank" rel="noopener noreferrer">Vérifier le document</a>
          </div>
        </div>
      }

      {/* Signature placement tool - CUSTOM */}
      {showPlaceSigntuareInterface && <PlaceSignatureInterfaceModalV2 
        onClose={handleClosePlaceSignatureModal} 
        onSignaturePositionSave={signaturePositionSaveHandler}
        base64Data={mergedFilesBase64}
        boxes={boxes}
        onSetBoxes={setBoxes}
        currentBox={activeBox}
        signaturesBox={signaturesBox}
        onSetSignaturesBox={setSignaturesBox}
        allSigners={[...customSigners, ...signers, ...adminSigners, ...customers]}
      />}

      {showOrderCustomersModal && <Modal onClose={() => setShowOrderCustomersModal(false)} small>
        <div className="sortable-wrapper">
          <ReactSortable list={orderedSignatairesArr} setList={setOrderedSignatairesArr} animation={200} delayOnTouchStart={true} delay={2}>
            {orderedSignatairesArr.map((item, i) => (
              <div className="sortable-list-item" key={i}>{item.email.value}</div>
            ))}
          </ReactSortable>
          <Button text="Confirmer" fullWidth primary medium onButtonClick={() => setShowOrderCustomersModal(false)} className="mt-20" />
        </div>
      </Modal>}

      {showSendRequestAlert && <Alert 
        text="Confirmez-vous l'envoi des demandes de signatures ?"
        onClose={() => setShowSendRequestAlert(false)} 
        onSubmit={submitHandler}
      />}

      {showSignatureTemplatesModal && <SignatureTemplatesModal 
        onClose={() => setShowSignatureTemplatesModal(false)} 
        templates={signatureTemplates}
        doc={{...documentObject, id: docId}}
        activeTemplate={activeSignatureTemplate}
        onSetActiveTemplate={setActiveSignatureTemplate}
        customSigners={customSigners}
        signers={signers}
        adminSigners={adminSigners}
        onSetFieldsPositions={setFieldsPositions}
        onSetSignaturePositions={setSignaturePositions}
        onSetSignaturePlaced={setSignaturePlaced}
      />}

      {showSaveUpdatedTemplateModal && <SaveUpdatedSignatureTemplateModal 
        onClose={() => setShowSaveUpdatedTemplateModal(false)}
        onTemplateSave={saveSignatureTemplate}
        name={signatureTemplateName}
        onSetName={setSignatureTemplateName}
        onSetActiveTemplate={setActiveSignatureTemplate}
      />}

      {showResponseLoader && <ResponseLoader />}
    </Modal>
  );
}

const Attachments = memo(({ attachments, attachmentChangeHandler, signaturePlaced, getTotalSizeOfSelectedDocuments }) => {
  return (
    <div className="share-attachments-wrapper">
      <div className="share-attachments-wrapper__box">
        { attachments.map((att, i) => {
          return (
            <div className="share-attachments-wrapper__box_item" key={i}>
              <Checkbox 
                onChange={(e) => attachmentChangeHandler(i, e.target.checked)} 
                checked={!!att.sign} 
                disabled={signaturePlaced}
              />
              <span>{ `${att.name} (${att.type}) ${att.size ? `(${bytesToSize(att.size)})` : ''}` }</span>
            </div>
          )
        })}
        {attachments.length === 0 && <p>Aucune annexe associée au document</p>}
      </div>
      {signaturePlaced && <p className="attachments-disabled-msg">Veuillez supprimer les demandes de signatures avant d'ajouter/supprimer des annexes</p>}
      <div className="attachments-size-wrapper">
        <p className="attachments-size-msg">Taille totale actuelle: <span>{bytesToSize(getTotalSizeOfSelectedDocuments())}</span></p>
        <p className="attachments-size-msg">Taille maximale autorisée: <span>16MB</span></p>
      </div>
    </div>
  )
})

export default SignDocumentModal;