import { useContext } from 'react'

import { UserContext, LoaderContext } from '../context'
import { generate_document_with_template_url } from '../services/lawstudioApi'
import { upload_file, update_agency } from '../services/firestore'
import { agencyLogoUrl, manufacturerProperty, matchCoverPageVariables } from '../utils'
import config from '../config.json'

const useCoverPageActions = () => {
  const { user, getAgency } = useContext(UserContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)

  // cover page template url
  const coverPageTemplateUrl = () => {
    switch(config.environment) {
      case 'development':
      case 'staging':
      case 'production':
        return 'https://firebasestorage.googleapis.com/v0/b/lawstudio-api.appspot.com/o/templates%2F4tNHo01drbsthz66yW2w_template.docx?alt=media&token=64bd41f7-d022-45dd-a171-b987901d82d5'
      default:
        return ''
    }
  }

  // Fetch data
  const fetchDocumentData = async ({ useLogo, logoInput, agencyImages, coverImageInput, color, font, useAdminInfo, useAdminPhoto, useAgencyInfo, agency }) => {
    const images = {}
    const options = {}
    let coverPageVariables = {}
    if(useLogo) {
      if(logoInput === 'custom') {
        if(agencyImages.logo.data) {
          images.logo = agencyImages.logo.data
        } else if(agencyImages.logo.url) {
          images.logo = agencyImages.logo.url
        } else {
          images.logo = ''
        }
      } else {
        images.logo = agencyLogoUrl(agency)
      }
    } else {
      images.logo = ''
    }
    if(coverImageInput !== 'none') {
      if(coverImageInput === 'custom') {
        if(agencyImages.cover.data) {
          images.cover = agencyImages.cover.data
        } else if(agencyImages.cover.url) {
          images.cover = agencyImages.cover.url
        }
      }
    } else {
      images.cover = ''
    }
    if(color) {
      options.cover_color = color
      options.font_config = {
        heading_2: {
          color: color,
          borderColor: color,
        },
        heading_3: {
          color: "#ffffff",
          backgroundColor: color,
        },
        footer_title: {
          color: color,
        },
        cover_title: {
          color: color,
        }
      }
    }
    if(font) {
      if(!options.font_config) {
        options.font_config = {}
      }
      options.font_config.font_family = font
    }
    // options.use_watermark = true
    if(useAdminInfo && useAdminPhoto && user.picture) {
      images.cover_admin_photo = user.picture 
    } else {
      images.cover_admin_photo = ''
    }
    coverPageVariables = matchCoverPageVariables(user, useAgencyInfo, useAdminInfo)
       
    coverPageVariables.cover_page_title = 'Titre du document'
    const coverPageDefaultTemplateUrl = coverPageTemplateUrl()
    if(!coverPageDefaultTemplateUrl) {
      return null
    }
    let doc = await generate_document_with_template_url(coverPageDefaultTemplateUrl, coverPageVariables, 'pdf', images, options)
    if(doc.error) {
      return null
    }
    return doc.data
  }

  // Upload image
  const uploadImage = async (type, image, updateObject) => {
    let result = await upload_file(image.data, `${manufacturerProperty(user?.manufacturer, 'id')}_${type}`, image.extension, image.type, `agency_covers_${config.environment}`, false)
    if(result.error || !result.url) {
      return
    }
    updateObject[type] = result.url
  }

  // Save 
  const saveAgency = async ({ agencyImages, color, font, useAgencyInfo, useAdminInfo, useAdminPhoto, coverImageInput, useLogo, logoInput, colorInput }) => {
    let promises = []
    let updateObject = {}
    if(agencyImages.logo.data) {
      promises.push(uploadImage('logo', agencyImages.logo, updateObject))
    } else if(agencyImages.logo.url) {
      updateObject.logo = agencyImages.logo.url
    }
    if(agencyImages.cover.data) {
      promises.push(uploadImage('cover', agencyImages.cover, updateObject))
    } else if(agencyImages.cover.url) {
      updateObject.cover = agencyImages.cover.url
    }
    updateObject.cover_color = color
    updateObject.font_family = font
    updateObject.cover_agency_info = useAgencyInfo
    updateObject.cover_admin_info = useAdminInfo
    updateObject.cover_admin_photo = useAdminPhoto
    updateObject.cover_image_input = coverImageInput
    updateObject.cover_use_logo = useLogo
    updateObject.cover_logo_input = logoInput
    updateObject.cover_color_input = colorInput

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText("Mise à jour de l'agence")

    try {
      await Promise.all(promises)
      await update_agency(manufacturerProperty(user?.manufacturer, 'id'), updateObject)
      await getAgency()
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  return {
    fetchDocumentData,
    saveAgency,
  }
}

export default useCoverPageActions