import React, { memo } from 'react'
import { ClickAwayListener } from '@material-ui/core'

import { UserPlus } from '../../assets/icons'
import { CustomTooltip, Input, Loader } from '../ui_new'
import { SingleDocumentSignSignerFields } from './'

const SingleDocumentSignContacts = memo(({ title, noDataText, type, setShowDropdown = () => {}, showDropdown, search, searchChangeHandler, filteredContacts, addContactsToSigners, signers, verifiedContactsDropdownOptions, verifiedContactsChangeHandler, changeValueHandler, editSignaturePositionHandler, deletePositionHandler, placeSignatureHandler, deleteUser, addNewUser, loadingVerifiedCustomers, verifiedCustomersSearched, onSetVerifiedCustomersSearched }) => {
  // console.log('render contacts***')

  // On click away
  const handleClickAway = () => {
    setShowDropdown(false)
    onSetVerifiedCustomersSearched && onSetVerifiedCustomersSearched(false)
  }

  return (
    <>
      <div className="signature-form__subtitle">
        {title}
        {type === 'manual' 
          ?
            <CustomTooltip content="Ajouter un destinataire">
              <button className="icon-btn" onClick={addNewUser}><UserPlus /></button>
            </CustomTooltip>
          :
            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <CustomTooltip content="Ajouter un destinataire">
                  <button className="icon-btn" onClick={() => setShowDropdown(!showDropdown)} type="button"><UserPlus /></button>
                </CustomTooltip>
                {showDropdown && 
                  <div className="dropdown-el default-dropdown users-dropdown">
                    <Input value={search} onChange={(e) => searchChangeHandler(e)} />
                    {type === 'customers' && loadingVerifiedCustomers && (
                      <Loader mini className="mt-3" />
                    )}
                    {type !== 'customers' && filteredContacts.length === 0 && <p className="no-data">{noDataText}</p>}
                    {type === 'customers' && filteredContacts.length === 0 && !loadingVerifiedCustomers && <p className="no-data">{verifiedCustomersSearched ? noDataText : 'Veuillez effectuer une recherche'}</p>}
                    {type === 'customers' && filteredContacts.length > 199 && !loadingVerifiedCustomers && <p className="no-data">{`Veuillez affiner votre recherche`}</p>}
                    {filteredContacts.length > 0 && filteredContacts.map((a, i) => (
                      <p key={i} onClick={() => addContactsToSigners(a)}>{a.name.value} {a.lastname.value}</p>
                    )) }
                       {/* : <p className="no-data">{noDataText}</p>
                     } */}
                  </div>
                }
              </div>
            </ClickAwayListener>
        }
      </div>
      {signers.map((signer, index) => <SingleDocumentSignSignerFields 
        key={index} 
        type={type} 
        signer={signer} 
        index={index} 
        verifiedContactsDropdownOptions={verifiedContactsDropdownOptions} 
        verifiedContactsChangeHandler={verifiedContactsChangeHandler} 
        changeValueHandler={changeValueHandler} 
        editSignaturePositionHandler={editSignaturePositionHandler} 
        deletePositionHandler={deletePositionHandler} 
        placeSignatureHandler={placeSignatureHandler} 
        deleteUser={deleteUser} 
      />)}
    </>
  )
})

export default SingleDocumentSignContacts