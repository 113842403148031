import React, { useContext, useEffect, useState } from 'react'
import { Checkbox, CustomTooltip } from '../../ui_new'
import { InfoCircle } from '../../../assets/icons'
import { UserContext } from '../../../context'
import SenderForm from './shared/SenderForm'
import { manufacturerProperty } from '../../../utils'

const SenderModalContentCreate = ({ onReturn, onSubmit }) => {

  const [step, setStep] = useState('info')

  const [newSenderData, setNewSenderData] = useState({
    company: '',
    // gender: '',
    firstname: '',
    lastname: '',
    email: '',
    address1: '',
    zipcode: '',
    city: ''
  })

  const [termsAccepted, setTermsAccepted] = useState(false)
  const [newSenderDataErrorMessage, setNewSenderDataErrorMessage] = useState(null)
  const [formErrors, setFormErrors] = useState({})


  const { user } = useContext(UserContext)

  useEffect(() => {
    // prefill newSenderData with user data
    if (!user) {
      return
    }
    setNewSenderData({
      company: manufacturerProperty(user?.manufacturer, 'name'),
      // gender: user.gender,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      address1: manufacturerProperty(user?.manufacturer, 'address'),
      zipcode: manufacturerProperty(user?.manufacturer, 'zip'),
      city: manufacturerProperty(user?.manufacturer, 'city')
    })
  }, [user])

  useEffect(() => {
    setNewSenderDataErrorMessage(null)
    setFormErrors({})
  }, [step])

  const handleChangeNewSenderData = (e) => {
    const { name, value } = e.target
    setNewSenderData({ ...newSenderData, [name]: value })
  }

  const validateNewSenderData = () => {
    const generalError = 'Des informations sont manquantes ou mal renseignées'
    let errorMessage = null
    let errors = {}
    if (newSenderData.company === '') {
      // setNewSenderDataErrorMessage('Veuillez renseigner la dénomination sociale')
      errorMessage = generalError
      errors['company'] = 'Non renseigné'
    }
    // if(newSenderData.gender === '') {
    //   // setNewSenderDataErrorMessage('Veuillez renseigner la civilité')
    //   errorMessage = generalError
    //   errors['gender'] = 'Non renseigné'
    // }
    if (newSenderData.firstname === '') {
      // setNewSenderDataErrorMessage('Veuillez renseigner le prénom')
      errorMessage = generalError
      errors['firstname'] = 'Non renseigné'
    }
    if (newSenderData.lastname === '') {
      // setNewSenderDataErrorMessage('Veuillez renseigner le nom')
      errorMessage = generalError
      errors['lastname'] = 'Non renseigné'
    }
    if (newSenderData.email === '') {
      // setNewSenderDataErrorMessage('Veuillez renseigner l\'email')
      errorMessage = generalError
      errors['email'] = 'Non renseigné'
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(newSenderData.email)) {
        // setNewSenderDataErrorMessage('Veuillez renseigner un email valide')
        errorMessage = generalError
        errors['email'] = 'Non valide'
      }
    }
    if (newSenderData.address1 === '') {
      // setNewSenderDataErrorMessage('Veuillez renseigner la rue')
      errorMessage = generalError
      errors['address1'] = 'Non renseigné'
    }
    if (newSenderData.zipcode === '') {
      // setNewSenderDataErrorMessage('Veuillez renseigner le code postal')
      errorMessage = generalError
      errors['zipcode'] = 'Non renseigné'
    }
    if (newSenderData.city === '') {
      // setNewSenderDataErrorMessage('Veuillez renseigner la ville')
      errorMessage = generalError
      errors['city'] = 'Non renseigné'
    }
    if (!termsAccepted) {
      errors['terms'] = 'Non accepté'
      // setNewSenderDataErrorMessage('Veuillez accepter les conditions générales de vente d\'AR24')
      if (!errorMessage) {
        // errorMessage = 'Veuillez accepter les conditions générales de vente d\'AR24'
        errorMessage = generalError
      }
    }
    setFormErrors(errors)
    if (errorMessage) {
      setNewSenderDataErrorMessage(errorMessage)
      return false
    }
    setNewSenderDataErrorMessage(null)
    return true
  }

  const handleSubmitNewSenderData = (e) => {
    e.preventDefault()
    const isValid = validateNewSenderData()
    if (!isValid) {
      return
    }
    onSubmit(newSenderData)
  }


  return (
    <div className=''>
      {step === 'info' && <>
        <div className='folder-modal-v2__heading'>
          <h2 className='modal-title'>Création du compte expéditeur</h2>
          <ul className='flex flex-row justify-end gap-2'>
            <li>
              <button
                className='btn btn--medium btn--transparent btn--border-primary'
                onClick={onReturn}
              >
                Précédent
              </button>
            </li>
            <li>
              <button
                className='btn btn--large'
                onClick={() => setStep('data')}
              >
                Suivant
              </button>
            </li>
          </ul>
        </div>
        <div className='folder-modal-v2__content'>
          <ul className='modal-list'>
            <li className='modal-list-item'>
              <p>
                Pour <b>créer un compte</b>, vous devez au préalable commander un <b>identifiant LRE</b>.
              </p>
            </li>
            <li className='modal-list-item'>
              <p>
                La commande d'un identifiant LRE vous sera facturée <b>7,49€ HT</b>.
              </p>
            </li>
            <li className='modal-list-item'>
              <p>
                Votre identifiant LRE vous sera <b>envoyé par AR24</b> par <b>lettre recommandée papier sous 3 jours ouvrés</b>. Après réception et validation de votre identité, vous devrez renseigner la <b>clé OTP</b> associée à cet identifiant LRE sur Immo Docs (gestion du compte expéditeur) afin de pouvoir envoyer des lettres recommandées électroniques.
              </p>
            </li>
          </ul>

        </div>
      </>}
      {step === 'data' && <>
        <div className='folder-modal-v2__heading'>
          <h2 className='modal-title'>Création du compte expéditeur</h2>
          <ul className='flex flex-row justify-end gap-2'>
            <li>
              <button
                className='btn btn--medium btn--transparent btn--border-primary'
                onClick={() => setStep('info')}
              >
                Précédent
              </button>
            </li>

            <li>
              <button
                className='btn btn--large'
                onClick={handleSubmitNewSenderData}
              >
                Soumettre
              </button>
            </li>
          </ul>
        </div>
        <div className='folder-modal-v2__content'>

          <ul className='modal-list'>
            <li className='modal-list-item'>
              <p>
                Statut du compte : <span className='status-value pending'>En Création</span>
              </p>
            </li>
            <li className='modal-list-item'>
              <p>
                Complétez ci-dessous les informations du compte expéditeur qui seront associées à votre compte expéditeur de LRE.
              </p>
            </li>
            {/** Input fields on separate rows for
           * - Denomination sociale
           * - Civilité, Prénom, Nom, Email
           * - Rue, Code postal, Ville
           */}
            <li>
              <SenderForm data={newSenderData} editable={true} onDataChange={handleChangeNewSenderData} errors={formErrors} emailTooltip={"Attention, l'expéditeur sera amené à recevoir des e-mails de la part d'AR24. Cet email doit être valide et peut être différent de l'identifiant du logiciel métier."} />
            </li>
            <li className='modal-list-item'>
              <p>
                L'expéditeur recevra par voie postale sous 3 jours ouvrés une <b>clé OTP</b> inscrite sur l’<b>identifiant LRE</b>.<br /><br />
                Pour <b>valider</b> le compte, il sera nécessaire de renseigner la clé OTP reçue sur Immo Docs (Lettre recommandée &gt; Gestion du compte expéditeur).
              </p>
            </li>
            <li className='modal-list-item'>
              <p>
                Vous serez facturés <b>7,49€ HT</b> pour la commande d'un identifiant LRE.
              </p>
            </li>
            <li>
              <p>
                <Checkbox checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)} labelElement={(<p className={`ml-10 terms-label ${formErrors['terms'] ? 'invalid' : ''}`}>J'ai pris connaissance et j'accepte les conditions générales de vente d'AR24 (<a href="https://www.ar24.fr/cgu/" target='_blank' rel='noreferrer'>ar24.fr/cgu</a>).</p>)} />
              </p>
            </li>
            {newSenderDataErrorMessage && <li className='modal-list-item mt-20'>
              <p className='text-error'>
                {newSenderDataErrorMessage}
              </p>
            </li>}
          </ul>

        </div>

      </>}
    </div>
  )
}

export default SenderModalContentCreate