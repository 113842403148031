import React, { useContext } from 'react'
import { RegisteredMailContext } from '../../../context'
import { CheckCircle } from '../../../assets/icons'
import { InfoOutlined } from '@mui/icons-material'
import { ArrowBack } from '@mui/icons-material';


const SenderModalContentPromptIdentityVerification = ({ onReturn, onClose }) => {

  const { renewIdentityVerification } = useContext(RegisteredMailContext)

  const handleConfirm = async () => {
    await renewIdentityVerification()
  }

  return (
    <div className=''>
      <div className='folder-modal-v2__heading'>
        <div className='heading-with-return-btn'>
          <button className='button button--transparent return' onClick={onReturn}>
            <ArrowBack fontSize={'small'} />
            Retour
          </button>
          <h1 className='modal-title'>Nouvel identifiant LRE</h1>
        </div>
        <div className='buttons-row flex flex-row justify-end gap-2'>

          <button
            className='btn btn--medium btn--transparent'
            onClick={onClose}
          >
            Annuler
          </button>

          <div className='btn-separator'></div>

          <button
            className='btn btn--large action-btn'
            onClick={handleConfirm}>
            <span className='icon'> <CheckCircle />
            Soumettre
            </span>
          </button>
        </div>
      </div>
      <div className='folder-modal-v2__content'>
        <ul className='modal-list'>
          <li className='modal-list-item'>
            <p>
              L'expéditeur recevra par voie postale sous 3 jours ouvrés une <b>clé OTP</b> inscrite sur l’<b>identifiant LRE</b>.<br /><br />
              Pour <b>valider</b> le compte, il sera nécessaire de renseigner la clé OTP reçue sur Immo Docs (Lettre recommandée &gt; Gestion du compte expéditeur).
            </p>
          </li>
          <li className='modal-list-item '>
            <p>
              Dès soumission, <b>vous ne pourrez plus envoyer de LRE</b> tant que votre compte n'aura pas été <b>vérifié</b>.
            </p>
          </li>
          <li className='mb-10'>
          </li>
          <li className='modal-list-item'>
            <div className='attention'>
              <p>
                <InfoOutlined className="mr-2" />
                Vous serez facturés <b>7,49€ HT</b> pour la commande d'un identifiant LRE.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SenderModalContentPromptIdentityVerification