import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Loader from './Loader'

const DashboardBox = ({ title, label, number, type, onClick, active, loading }) => {
  let boxCssClasses = `dashboard-box ${active ? 'active' : null}`;

  if(type === 'ready') {
    boxCssClasses += ' dashboard-box--ready';
  }
  if(type === 'draft') {
    boxCssClasses += ' dashboard-box--draft';
  }
  if(type === 'in-progress') {
    boxCssClasses += ' dashboard-box--in-progress';
  }
  if(type === 'closed') {
    boxCssClasses += ' dashboard-box--closed';
  }
  if(type === 'expired') {
    boxCssClasses += ' dashboard-box--expired';
  }
  if(type === 'canceled') {
    boxCssClasses += ' dashboard-box--canceled';
  }

  return (
    <div className={boxCssClasses} onClick={onClick}>
      <div className="dashboard-box__title">
        <p>{title}</p>
      </div>
      {loading 
        ? <Loader small primary normal /> 
        : <Fragment>
          <p className="dashboard-box__number">{number}</p>
          <p className="dashboard-box__label">{label}</p>
        </Fragment>
      }
    </div>
  );
}

DashboardBox.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool
}

export default DashboardBox;