import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import Tooltip from '../../UI/Tooltip';
import CustomTooltip from '../../UI/CustomTooltip';

const DocumentDetailSidePanel = ({ onTriggerClick, children, onSetView, view, signatures, attachments, showBackToTop }) => {
  const viewSetHandler = (view, e) => {
    e.preventDefault();
    onSetView(view);
  }
  
  return(
    <div className="document-detail__body_side-panel side-panel">
      <div className="side-panel__menu">
        <div className="tooltip-wrapper">
          <CustomTooltip content="Masquer" position="left">
            <div className="trigger" onClick={onTriggerClick}>
              <i className="custom-icon-close-panel"></i>
            </div>
          </CustomTooltip>
        </div>

        <ul>
          <li>
            <div className="tooltip-wrapper">
              <CustomTooltip content="Variables" position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('variables', e)} 
                    className={view === 'variables' ? 'active' : ''}
                  >
                    <DescriptionOutlinedIcon />
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>
          <li>
            <div className="tooltip-wrapper">
              <CustomTooltip content="Annexes" position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('appendices', e)}
                    className={view === 'appendices' ? 'active' : ''}
                  >
                    <span className="badge-wrap">
                      <AttachFileIcon />
                      {attachments.length > 0 && <span className="badge">{attachments.length}</span>}
                    </span>
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>
          {/* <li>
            <div className="tooltip-wrapper">
              <Link 
                to="/" 
                onClick={(e) => e.preventDefault()}
                className={view === '4' ? 'active' : ''}
              >
                <span className="badge-wrap">
                  <i className="custom-icon-create"></i>
                  {signatures.length > 0 && <span className="badge">{signatures.length}</span>}
                </span>
              </Link>
              <Tooltip text="Signature(s)" position="left" />
            </div>
          </li>
          <li>
            <div className="tooltip-wrapper">
              <Link 
                to="/" 
                onClick={(e) => e.preventDefault()}
                className={view === '3' ? 'active' : ''}
              >
                <i className="custom-icon-mark-email-read"></i>
              </Link>
              <Tooltip text="Recommandé(s)" position="left" />
            </div>
          </li> */}
        </ul>

        <div className={showBackToTop ? "back-to-top active" : "back-to-top"} onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>
          <CustomTooltip content="Haut de page" position="left" fixed>
            <i className="custom-icon-arrow-circle-up"></i>
          </CustomTooltip>
        </div>
      </div>
      <div className="side-panel__main">
        {children}
      </div>
    </div>
  );
}

DocumentDetailSidePanel.propTypes = {
  onTriggerClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  onSetView: PropTypes.func,
  view: PropTypes.string
}

export default DocumentDetailSidePanel;