import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { STATUS_LABELS_SHORT } from '../../constants'
import { DocumentsContext } from '../../context'
import { FEATURE, timeDisplayFormat, dateOnlyDisplayFormat, isFeatureEnabled } from '../../utils'
import { Folder } from '@mui/icons-material'

const DocumentsTableFolderRow = ({ id, folder, onSelectFolder }) => {

  const [createdAt, setCreatedAt] = useState('')
  const [createdAtTime, setCreatedAtTime] = useState('')
  const [modifiedAt, setModifiedAt] = useState('')
  const [modifiedAtTime, setModifiedAtTime] = useState('')

  // Set metadata
  useEffect(() => {
    if (folder?.meta?.created) {
      setCreatedAt(moment(folder.meta.created).format(dateOnlyDisplayFormat))
      setCreatedAtTime(moment(folder.meta.created).format(timeDisplayFormat))
    } else if (folder.createdAt) {
      setCreatedAt(moment(folder.createdAt).format(dateOnlyDisplayFormat))
      setCreatedAtTime(moment(folder.createdAt).format(timeDisplayFormat))
    }
    if (folder?.meta?.updated) {
      setModifiedAt(moment(folder.meta.updated).format(dateOnlyDisplayFormat))
      setModifiedAtTime(moment(folder.meta.updated).format(timeDisplayFormat))
    } else if (folder.updatedAt) {
      setModifiedAt(moment(folder.updatedAt).format(dateOnlyDisplayFormat))
      setModifiedAtTime(moment(folder.updatedAt).format(timeDisplayFormat))
    }
  }, [folder])

  const handleClick = () => {
    onSelectFolder(folder.id)
  }

  return (
    <div className={`documents-table-row !pl-0`} >
      <div className="documents-table-column documents-table-column__name !pl-0 ">
        <div className="flex gap-2 px-3 cursor-pointer" onClick={handleClick}><Folder /></div>
        <p className='cursor-pointer' onClick={handleClick}>{folder.name}</p>
      </div>
      <div className="documents-table-column documents-table-column__status">
      </div>
      <div className="documents-table-column documents-table-column__progress">
      </div>
      <div className="documents-table-column documents-table-column__author">
      </div>
      <div className="documents-table-column documents-table-column__owner">
      </div>
      <div className="documents-table-column documents-table-column__date">
        <div className="w-full">
          <p className='date'>{createdAt}</p>
          <p className='time'>{createdAtTime}</p>
        </div>
      </div>
      <div className="documents-table-column documents-table-column__date">
        <div className="w-full">
          <p className='date'>{modifiedAt}</p>
          <p className='time'>{modifiedAtTime}</p>
        </div>
      </div>
    </div>
  )
}

export default DocumentsTableFolderRow