import { useContext } from 'react'

import { isCoverPageVariable } from '../utils'
import { CONTACT_DATA_KEYS, CONTACT_VARIABLE_PREFIX_FOR_TYPE } from '../constants'
import { UserContext } from '../context'

const useVariablesActions = ({ 
  variable,
  variables,
  bulletListItems,
  type,
  values,
  onValuesChange,
  linesAddedTo,
  onSetLinesAddedTo,
}) => {

  const { setContacts, contacts } = useContext(UserContext)

  // Check if cover page is section
  const isCoverPageSection = () => {
    if(variable && isCoverPageVariable(variable)) {
      return true
    } 
    if(variables) {
      for(let v of variables) {
        if(isCoverPageVariable(v.variable)) {
          return true
        }
      }
    }
    if(bulletListItems) {
      for(let item of bulletListItems) {
        if(item.variables) {
          for(let v of item.variables) {
            if(isCoverPageVariable(v.variable)) {
              return true
            }
          }
        }
      }
    }
    return false
  }

  // Check if handle is locked
  const isHandleLocked = (handle) => {
    return handle && handle.includes(':locked')
  }

  // Get key from handle
  const fieldKeyFromHandle = (handle) => {
    return handle.substring(3, handle.length - 1)
  }

  // Get checkbox id from handle
  const checkboxIdFromHandle = (handle) => {
    return handle.split('.')[1].split(':')[0]
  }

  // Variable with field
  const variableWithField = (handle, vrbls = variables) => {
    const components = handle.split('.')
    const variableName = components[components.length - 1]

    for(let i in vrbls) {
      if(vrbls[i].variable === variableName) {
        return vrbls[i]
      }
    }
    return null
  }

  // Check if repeatable block is handle
  const isRepeatableBlockHandle = (handle) => {
    // console.log("is repeatableBlock handle", handle)
    const sections = handle.split('.')
    for(let i = 0; i < sections.length; i++) {
      if(!sections[i].includes('[')) {
        return false
      }
    }
    return true
    // return ( handle === `${repId}[i]` || handle === `${repId}[i+1]` )
  }

  // Duplicate values array
  // const duplicatableValuesArray = (repId = repeatableSectionId) => {
  //   if(values[repId]) {
  //     return values[repId]
  //   } else {
  //     return [{}]
  //   }
  // }

    // Duplicate values array
  const duplicatableValuesArray = (repSectionIds, repIndices) => {
    // console.log("duplicatableValuesArray", { repSectionIds, repIndices, values })
    const repeatableSectionIds = repSectionIds ? [...repSectionIds] : []
    const repetitionIndices = repIndices ? [...repIndices] : []
    
    let vals = {...values}
    for(let i = 0; i < repeatableSectionIds.length; i++) {
      if (!vals[repeatableSectionIds[i]]) {
        vals[repeatableSectionIds[i]] = [{}]
      }
      vals = vals[repeatableSectionIds[i]]
      if(i < repSectionIds.length - 1 && repetitionIndices?.[i] !== undefined) {
        vals = vals[repetitionIndices[i]]
      }
    }
    // console.log("duplicatableValuesArray", { repSectionIds, repIndices, values, val: vals || [{}] })
    return vals || [{}]
  }

  // Get value
  const value = (variable, repSectionIds, repIndices) => {
    let val
    if(repSectionIds && repSectionIds.length > 0) {
      val = duplicatableValuesArray(repSectionIds, repIndices)?.[repIndices[repIndices.length - 1]]?.[variable] || ''
    } else {
      val = values[variable] || ''
    }
    // console.log("value", {variable, repSectionIds, repIndices, array: duplicatableValuesArray(repSectionIds, repIndices), val})
    return val
  }

  // Empty lines label
  const emptyLinesLabelForValue = (value) => {
    if(!value) {
      return ''
    }
    let amountComponents = value.split('_')
    let amount = parseInt(amountComponents[amountComponents.length - 1])
    let blocks = amount / 4 
    return blocks
  }

  // Get customers array 
  const customersArrayForType = (type) => {
    return contacts[type] || []
  }

  // Get data key array
  const dataKeyArrayForType = (type) => {
    return CONTACT_DATA_KEYS[type]
  }

  // Get customer key
  const customerKeyForType = (type, key = 'firstname') => {
    return `${CONTACT_VARIABLE_PREFIX_FOR_TYPE[type]}${key}`
  }

  // Remove repetition
  const removeRepetition = (repId) => () => {
  //   let vals = values[repId]
  //   vals.splice(repeatableIndex, 1)
  //   onValuesChange(repId, vals)
  //   if(linesAddedTo.find(v => v.belongs_to === repId)) { // remove block variables from linesAddedTo and replace index of others if needed
  //     let updated = [...linesAddedTo]
  //     updated = updated.filter(v => !(v.belongs_to === repId && v.index === repeatableIndex))
  //     updated = updated.map(v => v.belongs_to === repId && v.index > repeatableIndex ? {...v, index: v.index - 1} : v)
  //     onSetLinesAddedTo(updated)
  //   }
  }

  return {
    isCoverPageSection,
    isHandleLocked,
    fieldKeyFromHandle,
    checkboxIdFromHandle,
    variableWithField,
    isRepeatableBlockHandle,
    value,
    emptyLinesLabelForValue,
    customersArrayForType,
    dataKeyArrayForType,
    customerKeyForType,
    removeRepetition,
    duplicatableValuesArray
  }
}

export default useVariablesActions