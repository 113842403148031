import React, { useState } from 'react';

import VariablesInner from './VariablesInner';
import Input from '../../UI/Input';
import Radio from '../../UI/Radio';
import Checkbox from '../../UI/Checkbox'
import moment from 'moment'
// import Tooltip from '@material-ui/core/Tooltip'
import Tooltip from '../../UI/Tooltip';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { areSectionConditionsMet, dateValueFormat } from '../../../utils';

import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

const DocumentSection = ({ type, content, variable, variables, data_type, question, options, tooltip, condition, conditions, sections, values, onValuesChange }) => {
  
  let classes = 'variables opened'
  

  const renderHeading1 = () => {
    return (
      <div className="document-detail-variables__head">
        <div className="document-detail-variables__head_top">
          <h1>
            { renderContent() }
          </h1>
        </div>
      </div>
    )
  }

  const renderHeading2 = () => {
    return (
      <div className="document-detail-variables__head">
        <div className="document-detail-variables__head_top">
          <h2>
            { renderContent() }
          </h2>
        </div>
      </div>
    )
  }

  const renderHeading3 = () => {
    return (
      <div className="document-detail-variables__head">
        <h3>
          { renderContent() }
        </h3>
      </div>
    )
  }

  const renderQuestion = (fieldVariable) => {
    let q
    let o
    let v
    let dt
    let t
    if (fieldVariable) {
      t = fieldVariable.tooltip
      q = fieldVariable.tooltip
      o = fieldVariable.options
      v = fieldVariable.variable
      dt = fieldVariable.type
    } else {
      q = question
      o = options
      v = variable
      dt = data_type
      t = tooltip
    }
    return (
      <div className="tooltip-wrapper">
        <Tooltip text={t} />
        <div className="variables__head">
          <div className="variables__head_text">
            <div className="fields-opened">
            <p>{ `${q}:`} </p>
            { dt === 'select' ? (
              <div className="fields-box fields-opened">
                <Radio
                  choices={o}
                  name={v}
                  selectedValue={values[v]}
                  onChange={e => onValuesChange(v, e.target.value)}
                />
              </div>
            ) : dt === 'multiselect' ? (
              // <div className="fields-box fields-opened">
              <div className="select_input_wrapper multiselect" id={v}>
                { o?.map((option, optionIndex) => {
                  return (
                    <div>
                      <Checkbox key={`option_${optionIndex}`} name={option.label} onChange={e => onMultiselectChanged(v, values[v], option.value, e.target.checked)} checked={values[v] && values[v].includes(option.value)} />
                      <span style={{ marginLeft: 5 }}>{ option.label }</span>
                    </div>
                  )
                }) }
              </div>
            ) : (
              <Input 
                editMode 
                name={v} 
                value={values[v]} 
                onChange={e => onValuesChange(v, e.target.value)}
              />
            ) }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const fieldKeyFromHandle = (handle) => {
    return handle.substring(3, handle.length - 1)
  }

  const renderContent = () => {
    if(!content) {
      return null
    }
    let source = content
    let fieldRegex = /\{d\.([^}]*)\}/g
    let fields = source.match(fieldRegex)

    let items = []
    for(let i in fields) {
      if(!source) {
        continue
      }
      let components = source.split(fields[i])
      items.push(components[0])
      items.push(fields[i])
      source = components[1]
    }
    if(source) {
      items.push(source)
    }
    return items.map((item, i) => {
      if(item.match(fieldRegex)) {
        let field = (fieldKeyFromHandle(item) || '').split(':')[0]
        // input field
        let fieldVariable = variableWithField(field)
        if(!fieldVariable) {
          return item
        }
        if(fieldVariable.type === 'string' || fieldVariable.type === 'number') {
          return renderTextInput(fieldVariable)
        } else if(fieldVariable.type === 'select') {
          return renderSelectInput(fieldVariable.variable, fieldVariable.options)
        } else if(fieldVariable.type === 'date') {
          return renderDateInput(fieldVariable.variable)
        } else if(fieldVariable.type === 'multiselect') {
          return renderQuestion(fieldVariable)
        }
      } else {
        return item
      }
    })
  }

  const renderText = () => {
    return (
      <div className="variables__text">
        { renderContent() }
      </div>
    )
  }

  const variableWithField = (handle) => {
    for(let i in variables) {
      if(variables[i].variable === handle) {
        return variables[i]
      }
    }
    return null
  }

  const renderTextInput = (fieldVariable) => {
    const { variable, tooltip, type} = fieldVariable
    return (
      <div className="tooltip-wrapper">
        <Tooltip text={tooltip} />
        <div>
          <Input id={variable} value={values[variable]} type={type} editMode onChange={(e) => onValuesChange(variable, e.target.value) } /> 
        </div>
      </div>
    )
  }

  const renderSelectInput = (variable, options) => {
    return (
      <div className="tooltip-wrapper">
        <Tooltip text={tooltip} />
        <div className="select_input_wrapper" id={variable}>
          <select name="status" id="status" value={values[variable]} onChange={e => onValuesChange(variable, e.target.value)}>
            <option disabled selected value> Sélectionnez une option </option>
            { options.map((option, optionIndex) => {
              return (
                <option key={`option_${optionIndex}`} value={option.value}>{ option.label }</option>
              )
            }) }
          </select>
        </div>
      </div>
    )
  }

  const onMultiselectChanged = (variable, prevState, value, checked) => {
    let nextState = prevState
    if(!nextState) {
      nextState = []
    }
    if(checked && !nextState.includes(value)) {
      nextState.push(value)
    } else if(!checked && nextState.includes(value)) {
      nextState.splice(nextState.indexOf(value), 1)
    }
    onValuesChange(variable, nextState)
  }

  const renderDateInput = (variable) => {
    return (
      <div className="tooltip-wrapper">
        {tooltip && <Tooltip text={tooltip} />}
        <div className="date_picker">
          <DatePicker
            id={variable}
            selected={!values[variable] ? new Date() : new Date(moment(values[variable], dateValueFormat).valueOf())}
            onChange={date => onValuesChange(variable, moment(date.valueOf()).format(dateValueFormat))}
            peekNextMonth
            dateFormat={'eee dd/MM/yyyy'}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="fr"
          />
        </div>
      </div>
    )
  }

  const renderSection = () => {
    if(!areSectionConditionsMet({ condition, conditions }, values)) {
      return null
    }
    if(sections) {
      return sections.map((section, sectionIndex) => {
        return <DocumentSection values={values} onValuesChange={onValuesChange} sections={sections} type={section.type} content={section.content} question={section.question} variable={section.variable} variables={section.variables} data_type={section.data_type} options={section.options} condition={section.condition} conditions={section.conditions} tooltip={section.tooltip} />
      })
    }
    switch(type) {
      case 'heading1':
        return renderHeading1()
      case 'heading2':
        return renderHeading2()
      case 'heading3':
        return renderHeading3()
      case 'question':
        return renderQuestion()
      case 'text':
        return renderText()
      default:
        return null
    }
  }

  const TooltipWrapper = ({ children, title }) => {
    if(!title) {
      return (
        <>
          { children }
        </>
      )
    } else {
      return (
        <Tooltip disableFocusListener={true} title={title} placement={'top'} arrow>
          { children }
        </Tooltip>
      )
    }
  }

  return(
    <div className={classes}>
      <div className="variables__body">
        { renderSection() }
      </div>
    </div>
  );
}

export default DocumentSection;