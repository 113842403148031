import React, { useState, forwardRef, useEffect, useContext } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FolderIcon from '@material-ui/icons/Folder';
import DocumentIcon from '@material-ui/icons/DescriptionOutlined'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteIcon from '@material-ui/icons/Delete'

import Alert from '../UI/Alert';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import CreateEditSectionModal from './CreateEditSectionModal';
import { DocumentsContext } from '../../context/documents/documentsState';
import { SectionContext } from '../../context/sections/sectionsState';
import { NotificationContext } from '../../context/notifications/notificationState';
import { availableOn, SIGNATURE_MODE_FOR_TEMPLATE } from '../../utils'
import { Signature } from '../../assets/icons';
import { Checkbox } from '../ui_new';

import { environment } from '../../config.json'

const OPTIONS = [{
  value: 'automatic',
  label: 'Positionnement automatique'
}, {
  value: 'manual',
  label: 'Positionnement manuel'
}, {
  value: 'none',
  label: 'Signature non autorisée'
}]


const SignaturesPopup = forwardRef(({ onCancel, resource }, ref) => {
  const [selectedOption, setSelectedOption] = useState(resource.signature_mode || "");
  const { updateTemplate } = useContext(DocumentsContext);
  const { setNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);


  const optionSelectHandler = (value) => {
    setSelectedOption(value);
  }

  // Add template to section 
  const submitHandler = async () => {
    setLoading(true)
    try {
      await updateTemplate({ signature_mode: selectedOption }, resource.id);
      setNotification({ msg: `Paramètres de signature enregistrés`, type: 'success' });
    } catch (err) {
      console.log(err);
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    }
    setLoading(false)
    onCancel()
    // const ids = [...section.templates];
    // if(!ids.includes(template.id)) {
    //   setAdding(true);
    //   ids.push(template.id);
    //   updateSection(section.id, { templates: ids }, () => {
    //     setNotification({ msg: `Modèle ajouté à la catégorie "${section.name}"`, type: 'success' });
    //     setAdding(false);
    //     const templates = [...section.templates];
    //     const activeTemplates = [];
    //     templates.forEach(t => {
    //       if(getTemplateById(t)) {
    //         activeTemplates.push(t);
    //       }
    //     });
    //     activeTemplates.push(template.id);
    //     setSectionTemplates(activeTemplates);
    //     setSelectedSection({ ...section, templates: activeTemplates });
    //   }, (err) => {
    //     console.log(err);
    //     setAdding(false);
    //     setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    //   });
    // }else {
    //   setNotification({ msg: `Le modèle est déjà dans la catégorie "${section.name}"`, type: 'warning' });
    // }
  }

  return (
    <div className="folders-popup wide" ref={ref}>
      <div className={"folders-popup__head root"}>
        <div className="main">
          <span className="icon template-box">
            <Signature />
          </span>
          <h5>Paramètres signature électronique</h5>
        </div>
      </div>
      <div className="folders-popup__body">
        <ul className={"folders-popup__body_list root"}>
          {OPTIONS.map((option, i) => (
              <li key={i} className="root folder-list-item dont-hide-dropdown">
                <div className="root dont-hide-dropdown" onClick={() => optionSelectHandler(option.value)}>
                  <DocumentIcon className="dont-hide-dropdown" />
                  <p className="dont-hide-dropdown">{option.label}</p>
                  <span>
                    <Checkbox
                      disabled={SIGNATURE_MODE_FOR_TEMPLATE[environment]?.[resource.id] && SIGNATURE_MODE_FOR_TEMPLATE[environment]?.[resource.id] !== option.value}
                      checked={SIGNATURE_MODE_FOR_TEMPLATE[environment]?.[resource.id] ? SIGNATURE_MODE_FOR_TEMPLATE[environment]?.[resource.id] === option.value : selectedOption === option.value}
                      onChange={() => optionSelectHandler(option.value)}
                      value={option.value} />
                  </span>
                </div>
              </li>
            ))
          }
          {/* {!selectedSection && sections.length === 0 && <p className="no-data">Pas de catégories</p>}
          {selectedSection && sectionTemplates.length > 0 
            && sectionTemplates.filter(t => Object.keys(getTemplateById(t)).length > 0).map(template => (
            <li key={template} className="inner">
              <div className="root u-cursor--default">
                <DescriptionOutlinedIcon />
                <p>{getTemplateById(template).name}</p>
                <span className="delete-template" onClick={() => removeTemplateClickHandler(template, selectedSection)}><DeleteIcon /></span>
              </div>
            </li>
          ))}
          {selectedSection && sectionTemplates.length === 0 && <p className="no-data">Pas de modèles dans cette catégorie</p>} */}
        </ul>
      </div>
      <div className="folders-popup__foot">
        <Button text="Annuler" onButtonClick={onCancel} outlinePrimary className="cancel-btn" />
        <Button 
          text={!loading ? "Enregistrer" : <Loader mini normalWhite />} 
          onButtonClick={submitHandler}
          primary 
        />
      </div>
    </div>
  );
});

export default SignaturesPopup;