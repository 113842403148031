import { SET_USER, SET_PARTNER, SET_ADMINS, SET_VERIFIED_CUSTOMERS, SET_CAN_USE_SIGNATURES, SET_SITE_CONFIGS, SET_SITE_CONFIGS_LOADED, SET_IMMOCLOUD_CONFIG, SET_CONTACTS, SET_USER_CLAIMS, SET_AGENCY, SET_SIGNIN_RESULT } from '../types';

const userReducer = (state, action) => {
  switch(action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case SET_PARTNER:
      return {
        ...state,
        partner: action.payload
      }
    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.payload
      }
    case SET_VERIFIED_CUSTOMERS:
      return {
        ...state,
        verified_customers: action.payload
      }
    case SET_ADMINS:
      return {
        ...state,
        admins: action.payload
      }
    case SET_SITE_CONFIGS:
      return {
        ...state,
        site_configs: action.payload,
        siteConfigsLoaded: true,
      }
    case SET_SITE_CONFIGS_LOADED:
      return {
        ...state,
        siteConfigsLoaded: action.payload,
      }
    case SET_CAN_USE_SIGNATURES:
      return {
        ...state,
        canUseSignatures: action.payload
      }
    case SET_IMMOCLOUD_CONFIG:
      return {
        ...state,
        immocloudConfig: action.payload
      }
    case SET_USER_CLAIMS:
      return {
        ...state,
        userClaims: action.payload
      }
    case SET_AGENCY:
      return {
        ...state,
        agency: action.payload
      }
    case SET_SIGNIN_RESULT:
      return {
        ...state,
        signInResult: action.payload
      }
    default:
      return state;
  }
}

export default userReducer