import { CheckBox, CheckBoxOutlineBlankOutlined } from "@mui/icons-material"

const Checkbox = ({ id, name, onChange, checked, disabled, label, labelElement, className = '' }) => {
  return (
    <div className={`checkbox-v2 ${className} ${disabled ? 'disabled' : ''}`}>
      <label htmlFor={id}>
        <input type="checkbox" name={name} id={id} onChange={onChange} checked={checked} disabled={disabled} />
        <div className={checked ? "checkbox-v2__btn checked" : "checkbox-v2__btn"}>
          {checked ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </div>
        {label && <div className="checkbox-v2__label">{label}</div>}
        {labelElement && labelElement}
      </label>
    </div>
  )
}

export default Checkbox