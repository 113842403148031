import React from 'react';

import DashboardHeadMeta from '../sections/DashboardHeadMeta';

const Help = () => {
  return (
    <div className="dashboard-page">
      <div className="dashboard-page__head">
        <h1>Aide</h1>
        <DashboardHeadMeta 
          hideSearch 
          showSupportMenu
        />
      </div>
      <div className="dashboard-page__body dashboard-page__body--help">
        <iframe title="Aide" className="airtable-embed" src="https://airtable.com/embed/shraZOeMXqpjiE4on?backgroundColor=blue&layout=card" frameBorder="0"></iframe>
      </div>
    </div>
  );
}

export default Help;