import { Sort } from '../../assets/icons'

import { SignaturesTableRow } from '../ui_new'

const SignaturesTable = ({ selectedSignatures, onSetSelectedSignatures, allChecked, onSetAllChecked, filteredSignatures, onOpenStatusPanel, onTitleClick, onOpenSignatureDetail, onDownload }) => {

  // On all checkbox click
  // const handleAllChecked = () => {
  //   onSetAllChecked(!allChecked)
  //   if(allChecked) {
  //     onSetSelectedSignatures([])
  //   }else {
  //     onSetSelectedSignatures(filteredSignatures)
  //   }
  // }

  const isSignatureEditAllowed = (signature) => {
    if (signature.status !== "IN_PROGRESS") return false
    const signatoryStatusNotInProgress = signature.recipients?.some(signatory => signatory.status !== "IN_PROGRESS")
    if (signatoryStatusNotInProgress) return false
    return true
  }

  return (
    <div className="signatures-table-parent">
      <div className="signatures-table">
        <div className="signatures-table__head">
          <div className="signatures-table-row">
            {/* <div className="signatures-table-column zero-width"> */}
            {/* <div className={`signatures-table-column__check ${allChecked ? 'checked' : ''}`} onClick={handleAllChecked}><Check /></div> */}
            {/* </div> */}
            <div className="signatures-table-column align-left">
              <p>Nom</p>
            </div>
            <div className="signatures-table-column align-left">
              <p>Date d'envoi</p>
            </div>
            <div className="signatures-table-column align-left">
              <p>Destinataires</p>
            </div>
            <div className="signatures-table-column align-left">
              <p>Envoyé par</p>
            </div>
            <div className="signatures-table-column align-left">
              <p>Statut</p>
            </div>
            <div className="signatures-table-column align-left">
              <p>Date<br />d'expiration</p>
            </div>
            <div className="signatures-table-column align-left">
              <p>Action</p>
              {/* empty header for modification & LRE */}
              <p />
            </div>
          </div>
        </div>
        <div className="signatures-table__body">
          {filteredSignatures.map((signature, idx) => {

            return (
              <SignaturesTableRow
                key={idx}
                id={signature.id}
                signature={signature}
                selectedSignatures={selectedSignatures}
                onSetSelectedSignatures={onSetSelectedSignatures}
                allChecked={allChecked}
                onOpenStatusPanel={onOpenStatusPanel}
                onTitleClick={onTitleClick}
                onOpenSignatureDetail={onOpenSignatureDetail}
                onDownload={onDownload}
                isEditDisabled={!isSignatureEditAllowed(signature)}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SignaturesTable