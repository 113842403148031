import { useContext } from 'react'

import { DocumentsContext, NotificationContext } from '../context'
import { validateURL } from '../helpers/validate'
import { get_file } from '../services/firestore'
import { base64toBlob, blobToFile, getFileData } from '../utils'
import { compress_attachment } from '../services/lawstudioApi'

const useAttachmentActions = (attachment, attachments, docId) => {
  const { updateDocument, copiedAttachments, setCopiedAttachments, setAttachmentsFrom } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)

  // Preview
  const previewAttachment = (att = attachment) => {
    if(!att) return 

    if(att.data) {
      let data = att.data.split(',')[1]
      let mimeType = att.type
      var byteCharacters = atob(data)
      var byteNumbers = new Array(byteCharacters.length)
      for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      var byteArray = new Uint8Array(byteNumbers)
      var file = new Blob([byteArray], { type: mimeType + ';base64' })
      var fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    } else if(att.url) {
      window.open(att.url, '_blank')
    }
  }

  const downloadAttachment = (att = attachment) => {
    console.log("download attachment", att)
    if(!att) return 

    if(att.data) {
      let data = att.data.split(',')[1]
      let mimeType = att.type
      var byteCharacters = atob(data)
      var byteNumbers = new Array(byteCharacters.length)
      for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      var byteArray = new Uint8Array(byteNumbers)
      var file = new Blob([byteArray], { type: mimeType + ';base64' })
      var fileURL = URL.createObjectURL(file)
      var a = document.createElement('a')
      a.href = fileURL
      a.download = att.name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } else if(att.url) {
      window.open(att.url, '_blank')
    }
  }

  const addAttachment = async (file) => {
  }

  const addMultipleAttachments = async (files) => {
  }

  // Edit
  const editAttachment = async (name, att = attachment) => {
    if(!att || !attachments) return 
    const attCopy = {...att}
    attCopy.name = name 
    const updatedAttachments = attachments.map(a => a.id === attCopy.id ? attCopy : a)
    try {
      await updateDocument({ attachments: updatedAttachments }, { id: docId, attachments: updatedAttachments })
      return updatedAttachments
    } catch (err) {
      console.log(err)
    }
  } 

  // Delete
  const deleteAttachment = async (att = attachment) => {
    if(!att || !attachments) return 

    const updatedAttachments = attachments.filter(a => a.id !== att.id)
    if(att.data) {
      // attachment is not yet saved in the database
      return updatedAttachments
    }else {
      try {
        await updateDocument({ attachments: updatedAttachments }, { id: docId, attachments: updatedAttachments })
        return updatedAttachments
      } catch (err) {
        console.log(err)
      }
    }
  }

  // Delete multiple
  const deleteMultipleAttachments = async (attachmentsToDelete) => {
    if(!attachmentsToDelete || !attachments) return 
    const updatedAttachments = attachments.filter(a => !attachmentsToDelete.find(att => att.id === a.id))
    try {
      await updateDocument({ attachments: updatedAttachments }, { id: docId, attachments: updatedAttachments })
      return updatedAttachments
    } catch (err) {
      console.log(err)
    }
  }

  // Weight
  const weightAttachment = async () => {
    if (!attachment || !attachments) return;
    //fake response after two seconds
    return new Promise(resolve => setTimeout(resolve, 2000));
  }

  // Upload from url
  const uploadFromUrl = async (url) => {
    if(url === '') {
      return setNotification({ msg: "L'URL est obligatoire", type: 'danger' })
    }
    if(!validateURL(url)) {
      return setNotification({ msg: "L'URL est invalide", type: 'danger' })
    }

    try {
      const res = await get_file({ url, getType: true })
      if(!res.success) {
        if(res.type === 'file-too-big') {
          return setNotification({ msg: 'Le poids du fichier est supérieur au poid autorisé (10MB)', type: 'danger' })
        }
        return setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
      }
      const blob = base64toBlob(res.data)
      let fileName = `file-${Date.now()}`
      let fileType = ''
      let fileExt = ''
      if(res.file_type) {
        fileExt = res.file_type.ext
        fileName = `${fileName}.${fileExt}`
        fileType = res.file_type.mime
      }else {
        return setNotification({ msg: 'File does not have extension', type: 'danger' })
      }

      // const allowedTypes = ['pdf', 'docx', 'png', 'jpeg', 'jpg', 'gif', 'svg']
      const allowedTypes = ['pdf']
      if(!allowedTypes.includes(fileExt)) {
        return setNotification({ msg: 'Type de fichier non valide. Seuls les fichiers PDF sont autorisés', type: 'danger' })
      }
      const file = blobToFile(blob, fileName)
      const data = await getFileData(file, fileType)
      return data
    } catch (err) {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    }
  }

  // Paste attachments
  const pasteAttachments = async ({ attachmentsNames, setDocumentAttachments }) => {
    const promises = []
    const attachments = [...copiedAttachments]
    copiedAttachments.forEach(att => {
      if(att.url) {
        promises.push(get_file({ url: att.url }))
      }else {
        promises.push({ data: att.data.split(',')[1] })
      }
    });
    const errors = []
    Promise.all(promises).then(async (data) => {
      let attsPromises = []
      for(let i = 0; i < data.length; i++) {
        const item = data[i]
        const blob = base64toBlob(item.data)
        const fileName = `${attachments[i].name}.${attachments[i].format}`
        if(attachmentsNames.includes(fileName)) {
          errors.push(fileName)
        }else {
          const file = blobToFile(blob, fileName)
          attsPromises.push(getFileData(file, attachments[i].type))
        }
      }
      const attsData = await Promise.all(attsPromises)
      if(attsData.length > 0) {
        setDocumentAttachments(prev => [...prev, ...attsData])
      }
      setCopiedAttachments([])
      setAttachmentsFrom(null)
      if(errors.length > 0) {
        setNotification({ msg: `Les pièces jointes portant ce nom existent déjà(${errors.join(',')})`, type: 'info' })
      }
    }).catch(err => {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    })
  }

  const compressAttachment = async (attachment) => {
    if(attachment.data) {
      const base64 = attachment.data.split(',')[1]
      attachment.base64 = base64
    }
    const currentSize = attachment.size
    const response = await compress_attachment(attachment)
    if(response.error) {
      console.log("[compressAttachment] error", response.error)
      return { error: response.error }
    }
    const { base64 } = response
    const newSize = Buffer.byteLength(base64, 'base64')
    if(newSize >= currentSize) {
      return { error: "La compression a échoué. Nous n'avons pas pu compresser davantage le fichier." }
    }
    delete attachment.url
    attachment.data = `data:${attachment.type};base64,${base64}`
    attachment.size = Buffer.byteLength(base64, 'base64')
    attachment.compressed = true

    return {
      attachment,
      newSize
    }
  }

  return {
    previewAttachment,
    editAttachment,
    deleteAttachment,
    weightAttachment,
    uploadFromUrl,
    pasteAttachments,
    deleteMultipleAttachments,
    downloadAttachment,
    compressAttachment
  }
}

export default useAttachmentActions