import React, { useContext, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight, ExpandMore } from '@material-ui/icons'

import ActionsDropdown from '../../UI/ActionsDropdown'
import DashboardView from '../DashboardView';
import { sortedArrayFromObject, sorterWithPathAndOrder, sortArrayOfObjects, saveSortingToLS, getSorting, savePaginationDataToLS, getPaginationData } from '../../../utils'
import { DocumentsContext } from '../../../context/documents/documentsState';
import { DocumentsFoldersContext } from '../../../context/documentsFolders/documentsFoldersState';
import { perPageOptions } from '../../../constants'

const DocumentsView = ({ onSidebarOpen, viewTitle, selected, onSetSelected, onDelete, onSearch, searchValue, onSetSearch, onRestore, onArchive, removeSearchClose, onSetRemoveSearchClose, onSearchFormClear, onDownload, onSingleDocumentDuplicate, onSingleDocumentDelete, onSingleDocumentArchive, onSingleDocumentDownload, onSingleDocumentDownloadData, onSingleDocumentRestore, setViewTitle, onFolderCreate, onFolderEdit, onFolderDelete, onFolderMove, onFilterByFolders, onMoveSelectedDocuments, onFolderClick, onMoveDocumentToFolder, filteredFolders, searching, onSetSearching, onDeletePermanently, onInjectModalOpen, onSingleDocumentInject }) => {
  const { documents, filteredDocuments, setActiveFilterColumn, documentsLoaded, currentFilter, showFilteredDocs } = useContext(DocumentsContext)
  const { docFolderBreadcrumbs, docFoldersSelectedFilter, docFolders, docFoldersFetched } = useContext(DocumentsFoldersContext)
  const [documentsArray, setDocumentsArray] = useState([])
  const [filteredFoldersArr, setFilteredFoldersArr] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState({});
  const sortLS = getSorting()
  const [columns, setColumns] = useState([
    { 
      label: 'Titre', 
      value: 'name', 
      order: sortLS?.documents?.value === 'name' ? sortLS.documents?.order || 'desc' : 'desc', 
      cssClass: 'col-1', 
      all: true, 
      active: sortLS?.documents?.value === 'name' 
    },
    { 
      label: 'Statut', 
      value: 'status', 
      order: sortLS?.documents?.value === 'status' ? sortLS.documents?.order || 'desc' : 'desc', 
      cssClass: 'col-2', 
      all: false, 
      active: sortLS?.documents?.value === 'status' 
    },
    { 
      label: 'Créé le', 
      value: 'meta.created', 
      order: sortLS?.documents?.value === 'meta.created' ? sortLS.documents?.order || 'desc' : 'desc', 
      cssClass: 'col-3 date-col doc-date-col', 
      all: false, 
      active: sortLS?.documents?.value === 'meta.created' 
    },
    { 
      label: 'Modifié le', 
      value: 'meta.updated', 
      order: sortLS && sortLS.documents ? sortLS.documents.value === 'meta.updated' ? sortLS.documents.order || 'desc' : 'desc' : 'desc', 
      cssClass: 'col-4 date-col doc-date-col', 
      all: false, 
      active: sortLS && sortLS.documents ? sortLS.documents.value === 'meta.updated' : true
    },
  ]);
  const [dataReady, setDataReady] = useState(false);
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [allPages, setAllPages] = useState(1)
  const [paginatedData, setPaginatedData] = useState([])
  const [paginationReady, setPaginationReady] = useState(false)
  
  useEffect(() => {
    const paginationData = getPaginationData()
    if(paginationData && paginationData.documents) {
      paginationData.documents.items && setPerPage(paginationData.documents.items)
      paginationData.documents.current && setCurrentPage(paginationData.documents.current)
      paginationData.documents.all && setAllPages(paginationData.documents.all)
    }
  }, [])

  useEffect(() => {
    processDocuments();
    // eslint-disable-next-line
  }, [documents, filteredDocuments, docFolders]);

  useEffect(() => {
    if(searching) {
      setFilteredFoldersArr(filteredFolders);
      onSetSearching(false);
    }
  }, [filteredFolders, searching]);

  // useEffect(() => {
  //   if(updateView) {
  //     processDocuments();
  //     onUpdateView(false);
  //   }
  //   // eslint-disable-next-line
  // }, [updateView]);

  // Change paginated data arr when filtered arr changes
  useEffect(() => {
    let current = currentPage
    let sliceFrom = current === 1 ? 0 : (current - 1) * perPage
    if(sliceFrom >= documentsArray.length) {
      sliceFrom = 0
      setCurrentPage(1)
    }
    let slicedData = documentsArray.slice(sliceFrom, sliceFrom + perPage)
    setPaginatedData(slicedData)
    let all = Math.ceil(documentsArray.length / perPage)
    setAllPages(all === 0 ? 1 : all)
    setTimeout(() => setPaginationReady(true), 200)
  }, [documentsArray, perPage, currentPage, allPages])

  // Process documents
  const processDocuments = async () => {
    let docs = {...filteredDocuments};
    let filtered = {};
    if(currentFilter === 'all') {
      if(docFoldersSelectedFilter !== 'all') {
        for(let key in docs) {
          if(docs[key].folderId && docs[key].folderId.includes(docFoldersSelectedFilter)) {
            filtered[key] = docs[key];
          }
        }
      }else {
        for(let key in docs) {
          if(!docs[key].folderId || docs[key].folderId.length === 0) {
            filtered[key] = docs[key];
          }
        }
      }
    }else {
      filtered = {...docs};
    }
    setFilteredDocs(filtered);
    let sort = 'meta.updated'
    let order = 'desc'
    if(sortLS && sortLS.documents) {
      sort = sortLS.documents.value
      order = sortLS.documents.order
    }
    let dcmnts = sortedArrayFromObject(filtered, sorterWithPathAndOrder(sort, order))
    for(let i in dcmnts) {
      dcmnts[i].title = dcmnts[i].name
    }
    const checkWith = searching ? filteredFolders : docFolders;
    const filteredF = [...checkWith].filter(folder => {
      if(docFoldersSelectedFilter === 'all') {
        return folder.parentFolder === undefined || folder.parentFolder === null;
      }else {
        return folder.parentFolder === docFoldersSelectedFilter;
      }
    });
    let fldrs = sortArrayOfObjects(filteredF, sort, order)
    setFilteredFoldersArr(fldrs);
    let arr = [...fldrs, ...dcmnts]
    setDocumentsArray(arr)

    // Pagination
    setPaginatedData(arr.slice(0, perPage))
    setAllPages(Math.ceil(arr.length / perPage))

    if(documentsLoaded && showFilteredDocs && docFoldersFetched) {
      setDataReady(true);
    }
  }

  // Sort
  const sortHandler = (value, order) => {
    const filteredFolders = [...filteredFoldersArr];
    const sortedFolders = sortArrayOfObjects(filteredFolders, value, order);
    let copiedDocs = {...filteredDocs};
    const docs = sortedArrayFromObject(copiedDocs, sorterWithPathAndOrder(value, order));
    setDocumentsArray([...sortedFolders, ...docs]);
    setColumns(prev => [...prev].map(col => col.value === value ? { ...col, order: order, active: true} : {...col, active: false}));
    setActiveFilterColumn(value);
    saveSortingToLS(value, order, 'documents')
  }

  // On prev button click 
  const prevButtonClickHandler = () => {
    setCurrentPage(currentPage - 1)
    let sliceFrom = currentPage - 1 === 1 ? 0 : (currentPage - 2) * perPage
    setPaginatedData([...documentsArray].slice(sliceFrom, sliceFrom + perPage))
    onSetSelected([])
    savePaginationDataToLS(perPage, currentPage - 1, allPages, 'documents')
  }

  // On next button click 
  const nextButtonClickHandler = () => {
    setCurrentPage(currentPage + 1)
    let sliceFrom = currentPage * perPage
    setPaginatedData([...documentsArray].slice(sliceFrom, sliceFrom + perPage))
    onSetSelected([])
    savePaginationDataToLS(perPage, currentPage + 1, allPages, 'documents')
  }

  // Per page change handler
  const perPageChangeHandler = (e, num) => {
    e.preventDefault()
    setPerPage(num)
    if(Math.ceil(documentsArray.length / num) < currentPage) {
      setCurrentPage(1)
    }
    window.document.body.click()
    savePaginationDataToLS(num, currentPage, allPages, 'documents')
  }

  return(
    <>
      <DashboardView 
        data={paginatedData}
        columns={columns}
        view="documents"
        onSidebarOpen={onSidebarOpen}
        viewTitle={viewTitle}
        onSort={sortHandler}
        selected={selected}
        onSetSelected={onSetSelected}
        onDelete={onDelete}
        onSearch={onSearch}
        searchValue={searchValue}
        onSetSearch={onSetSearch}
        onRestore={onRestore}
        onArchive={onArchive}
        removeSearchClose={removeSearchClose}
        onSetRemoveSearchClose={onSetRemoveSearchClose}
        onSearchFormClear={onSearchFormClear}
        onDownload={onDownload}
        onSingleDocumentDuplicate={onSingleDocumentDuplicate}
        onSingleDocumentDelete={onSingleDocumentDelete}
        onSingleDocumentArchive={onSingleDocumentArchive}
        onSingleDocumentDownload={onSingleDocumentDownload}
        onSingleDocumentDownloadData={onSingleDocumentDownloadData}
        onSingleDocumentRestore={onSingleDocumentRestore}
        setViewTitle={setViewTitle}
        breadcrumbs={docFolderBreadcrumbs}
        onFolderCreate={onFolderCreate}
        onFolderEdit={onFolderEdit}
        onFolderDelete={onFolderDelete}
        onFolderMove={onFolderMove}
        onFilterTemplatesByFolders={onFilterByFolders}
        onMoveSelectedResources={onMoveSelectedDocuments}
        rowDraggable
        onFolderClick={onFolderClick}
        onMoveDocumentToFolder={onMoveDocumentToFolder}
        dataLoaded={documentsLoaded && docFoldersFetched && dataReady && paginationReady}
        onDeletePermanently={onDeletePermanently}
        onInjectModalOpen={onInjectModalOpen}
        onSingleDocumentInject={onSingleDocumentInject}
      />
      {dataReady && paginationReady && paginatedData.length > 0 && <div className="pagination">
        <ActionsDropdown
          trigger={<button className="trigger">{perPage} <span><ExpandMore /></span></button>}
          hideHeader
          dropdownClass="pagination-dropdown"
          width={120}
        >
          <ul>
            {perPageOptions.map((num, i) => (
              <li key={i}>
                <div className="link">
                  <a href="/#" onClick={(e) => perPageChangeHandler(e, num)}>{num}</a>
                </div>
              </li>
            ))}
          </ul>
        </ActionsDropdown>
        <button onClick={prevButtonClickHandler} disabled={currentPage === 1}><ChevronLeft /></button>
        <div className="pagination__body">page {currentPage} de {allPages}</div>
        <button onClick={nextButtonClickHandler} disabled={currentPage === allPages}><ChevronRight /></button>
      </div>}
    </>
  );
}

export default DocumentsView;