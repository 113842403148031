const Draw = () => {
  return (
    <span className="svg-icon">

      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.1 10.39L20.16 9.33C20.94 8.55 20.94 7.28 20.16 6.5L18.75 5.09C17.97 4.31 16.7 4.31 15.92 5.09L14.86 6.15L19.1 10.39ZM14.86 11.81L7.66 19H6.25V17.59L13.44 10.4L14.86 11.81ZM13.44 7.56L4.25 16.76V21H8.49L17.68 11.81L13.44 7.56ZM19.25 17.5C19.25 19.69 16.71 21 14.25 21C13.7 21 13.25 20.55 13.25 20C13.25 19.45 13.7 19 14.25 19C15.79 19 17.25 18.27 17.25 17.5C17.25 17.03 16.77 16.63 16.02 16.3L17.5 14.82C18.57 15.45 19.25 16.29 19.25 17.5ZM4.83 13.35C3.86 12.79 3.25 12.06 3.25 11C3.25 9.2 5.14 8.37 6.81 7.64C7.84 7.18 9.25 6.56 9.25 6C9.25 5.59 8.47 5 7.25 5C5.99 5 5.45 5.61 5.42 5.64C5.07 6.05 4.44 6.1 4.02 5.76C3.61 5.42 3.53 4.81 3.87 4.38C3.98 4.24 5.01 3 7.25 3C9.49 3 11.25 4.32 11.25 6C11.25 7.87 9.32 8.72 7.61 9.47C6.67 9.88 5.25 10.5 5.25 11C5.25 11.31 5.68 11.6 6.32 11.86L4.83 13.35Z" />
      </svg>

    </span>
  )
}

export default Draw