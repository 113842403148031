const Switch = ({ disabled, checked, onChange }) => {
  return (
    <div className="switch-el-v2">
      <label>
        <input type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
        <div className="switch-el-v2__btn"></div>
      </label>
    </div>
  )
}

export default Switch