import React, { useState } from 'react'
import HistoryIcon from '@material-ui/icons/History'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'

import Button from './Button'

const HistoryBox = ({ time, progress, numberOfEntries, onRestoreClick, inputsData, type = 'document', description }) => {
  const [showBody, setShowBody] = useState(false)

  let content = (
    <div className="history-box">
      <header>
        <div className="history-box__time">{time}</div>
        <div className="history-box__progress">
          <p className="main">Progression: {(progress * 100).toFixed(0)}%</p>
          <div className="progress-bar"><div style={{width: `${progress * 100}%`}}></div></div>
          <p className="number-of-entries">{numberOfEntries} données saisies</p>
        </div>
        <div className="history-box__actions">
          <Button text="Aperçu" icon={<RemoveRedEyeIcon />} primaryLight onButtonClick={() => setShowBody(!showBody)} />
          <Button text="Restaurer" icon={<HistoryIcon />} primaryLight onButtonClick={onRestoreClick} />
        </div>
      </header>
      {showBody && 
        <div className="history-box__body">
          {inputsData.map((item, index) => (
            <div className="group" key={index}>
              <p className="label">{item.label}</p>
              <p className="input">{item.input}</p>
            </div>
          ))}
        </div>
      }
    </div>
  )

  if(type === 'template') {
    content = (
      <div className="history-box history-box--template">
        <header>
          <div className="history-box__time">{time}</div>
          <div className="history-box__progress">
            <p className="main">{description}</p>
          </div>
        </header>
      </div>
    )
  }

  return content
}

export default HistoryBox