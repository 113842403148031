const SentimentVerySatisfied = () => {
    return (
        <span className="svg-icon">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.9859 3.3335C10.7859 3.3335 3.33594 10.7835 3.33594 20.0002C3.33594 29.2168 10.7859 36.6668 19.9859 36.6668C29.1859 36.6668 36.6693 29.2168 36.6693 20.0002C36.6693 10.7835 29.2026 3.3335 19.9859 3.3335ZM20.0026 33.3335C12.6359 33.3335 6.66927 27.3668 6.66927 20.0002C6.66927 12.6335 12.6359 6.66683 20.0026 6.66683C27.3693 6.66683 33.3359 12.6335 33.3359 20.0002C33.3359 27.3668 27.3693 33.3335 20.0026 33.3335ZM21.6693 16.5668L23.4359 18.3335L25.2026 16.5668L26.9693 18.3335L28.7359 16.5668L25.2026 13.0335L21.6693 16.5668ZM14.8026 16.5668L16.5693 18.3335L18.3359 16.5668L14.8026 13.0335L11.2693 16.5668L13.0359 18.3335L14.8026 16.5668ZM20.0026 29.1668C23.8859 29.1668 27.1859 26.7335 28.5193 23.3335H11.4859C12.8193 26.7335 16.1193 29.1668 20.0026 29.1668Z" />
            </svg>
        </span>
    )
}

export default SentimentVerySatisfied