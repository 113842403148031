import React from 'react'

import Switch from '../../../UI/Switch'
import Input from '../../../UI/Input'
import Button from '../../../UI/Button'

const SaveTemplateSection = ({ showSaveTemplate, setShowSaveTemplate, signatureTemplateName, signatureTemplateNameError, setSignatureTemplateName, setSignatureTemplateNameError, saveSignatureTemplate }) => {
  return (
    <div className="signature-modal-inner__section signature-modal__template">
      <p className="form__subtitle form__subtitle--2">Sauvegarder comme modèle d'emplacement de signature</p>
      <div className="checkbox-wrapper">
        <Switch checked={showSaveTemplate} onChange={(e) => setShowSaveTemplate(e.target.checked)} />
      </div>
      {showSaveTemplate && <div className="signature-modal__template_save">
        <Input 
          value={signatureTemplateName}
          onChange={(e) => {
            if(signatureTemplateNameError) setSignatureTemplateNameError('')
            setSignatureTemplateName(e.target.value)
          }}
          placeholder="Nom du modèle"
          formEl 
          error={signatureTemplateNameError}
        />
        <Button type="button" text="Sauvegarder" primaryLight onButtonClick={saveSignatureTemplate} />
      </div>}
    </div>
  );
}

export default SaveTemplateSection