import { memo } from 'react'

import { EllipsisH } from '../../assets/icons'
import { CustomTooltip } from '../ui_new'

const VariableEmptyLinesInput = ({ fieldVariable, handle, repeatableIds, repetitionIndices, val, emptyLinesLabelForValue, onInputChange }) => {
  const { variable } = fieldVariable
  let innerClasses = 'variables-v2 doc-variable-el question-el';

  if (val) {
    innerClasses += ' selected';
  }
  return (
    <div className={innerClasses}>
    <div className={"variables-v2__head select"}>
      <div className={"variables-v2__head_text tooltip-wrapper"} onClick={null}>
        <span className="radio"></span>
        <p>{ "Combien de blocs de 4 lignes voulez-vous ajouter?" }</p>
        <p className="selected">{emptyLinesLabelForValue(val)}</p>
      </div>
      <div className="variables-v2__head_buttons">
        <button className="variables-v2__btn" onClick={() => onInputChange(variable, 'empty_lines_4', repeatableIds, repetitionIndices)}>1</button>
        <button className="variables-v2__btn" onClick={() => onInputChange(variable, 'empty_lines_8', repeatableIds, repetitionIndices)}>2</button>
        <button className="variables-v2__btn" onClick={() => onInputChange(variable, 'empty_lines_12', repeatableIds, repetitionIndices)}>3</button>
        <button className="variables-v2__btn" onClick={() => onInputChange(variable, 'empty_lines_16', repeatableIds, repetitionIndices)}>4</button>
      </div>
      <div className="variables-v2__head_selected">
        <div className="tooltip-wrapper">
          <CustomTooltip content="Réinitialiser">
            <button className="variables-v2__btn" onClick={() => onInputChange(variable, '', repeatableIds, repetitionIndices)}><EllipsisH /></button>
          </CustomTooltip>
        </div>
      </div>
    </div>
    <EmptyLinesContent value={val} />
    </div>
  )
}

const EmptyLinesContent = ({ value }) => {
  if(!value) {
    return null
  }
  let amountComponents = value.split('_')
  let amount = parseInt(amountComponents[amountComponents.length - 1])
  let blocks = amount / 4
  return (
    <div>
      {[...Array(blocks)].map((elementInArray, bi) => ( 
        <div key={`lines_block_${bi}`} className="manual-lines-block">
          {[...Array(4)].map((e, li) => ( 
            <div key={`line_${bi}_${li}`} className="manual-line"></div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default memo(VariableEmptyLinesInput)