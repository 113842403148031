import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { FileAlt, File, Sync, Folder, Search } from '../../assets/icons';
import { Modal, Input } from '../ui_new';
import { useSingleDocumentActions } from '../../hooks';
import { FEATURE, isFeatureEnabled, sortArrayOfObjects } from '../../utils';
import { DocumentsContext, FolderContext, LoaderContext, SectionContext } from '../../context';

const SingleDocumentInject = ({ onClose, doc, template, documentValues }) => {
  const { allVariables, createDocumentFromTemplate } =
    useSingleDocumentActions();
  const { folders, foldersFetched } = useContext(FolderContext);
  const { templates, templatesLoaded } = useContext(DocumentsContext);
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext);
  const { sections, tagMatchings } = useContext(SectionContext);

  const [templatesArr, setTemplatesArr] = useState([]);
  const [filteredArr, setFilteredArr] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedFolder, setSelectedFolder] = useState('root');
  const [numOfTmpltsInFolder, setNumOfTmpltsInFolder] = useState({});
  const [isReady, setIsReady] = useState(false);
  const history = useHistory();

  // Set arrays
  useEffect(() => {
    if (Object.keys(templates).length > 0 && foldersFetched && !isReady) {
      const validTemplates = {}
      for (let key in templates) {
        if (templates[key].deleted || templates[key].archived || templates[key].hidden) {
          continue
        }
        validTemplates[key] = templates[key]
      }
      let arr = [];
      const allVars = allVariables(
        documentValues,
        JSON.parse(JSON.stringify(template)),
        false,
        true
      );
      const values = [...new Set([...allVars])];
      let tmpltsByFolders = {};

      folders.forEach((f) => {
        tmpltsByFolders[f.id] = 0;
      });

      for (let key in validTemplates) {
        const tmpl = validTemplates[key];
        let sameVars = [];
        let allDocVars = allVariables(
          documentValues,
          JSON.parse(JSON.stringify(tmpl)),
          false,
          true
        );
        const dValues = [...new Set(allDocVars)];
        for (let i = 0; i < dValues.length; i++) {
          const vKey = dValues[i];
          if (values.includes(vKey)) sameVars.push(vKey);
        }
        arr.push({ id: key, vars: sameVars, ...tmpl });
        if (tmpl.folderId && tmpl.folderId.length) {
          tmpltsByFolders[tmpl.folderId[0]] += 1;
        }
      }
      let filtered = arr.filter((d) => !d.folderId || d.folderId.length === 0);
      tmpltsByFolders['root'] = filtered.length;
      setNumOfTmpltsInFolder(tmpltsByFolders);
      setTemplatesArr(sortArrayOfObjects(arr, 'name'));
      setFilteredArr(sortArrayOfObjects(filtered, 'name'));
      setIsReady(true);
    }
  }, [
    templates,
    allVariables,
    template,
    isReady,
    templatesLoaded,
    foldersFetched,
    folders,
    documentValues,
  ]);

  // On search change
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    filterBySearch(value, templatesArr, selectedFolder);
  };

  // Filter by search
  const filterBySearch = (value, dcmntsArr, folder) => {
    let arr;
    let filtered;
    if (folder === 'root') {
      arr = dcmntsArr.filter((d) => !d.folderId || d.folderId.length === 0);
    } else {
      arr = dcmntsArr.filter((d) => d.folderId && d.folderId.includes(folder));
    }
    filtered = arr;
    if (value.trim() !== '') {
      filtered = arr.filter((d) =>
        d.name.toLowerCase().includes(value.trim().toLowerCase())
      );
    }
    setFilteredArr(filtered);
  };

  // On template click
  const handleTemplateClick = async (tmpl) => {
    let values = { ...doc.values };
    let tmplValues = {};

    let matching
    if(isFeatureEnabled(FEATURE.CROSS_CATEGORY_TAG_MATCHING)) {
      const sourceTemplateSections = sections.filter((s) => s.templates.includes(template.id)).map(s => s.id)
      const targetTemplateSections = sections.filter((s) => s.templates.includes(tmpl.id)).map(s => s.id)
      // example matching
      // {
      //   "categories": ["category1", "category2"],
      //   "matchings": [{
      //     "variables": ["admin_manufacturers_name", "admin_lastname"]
      //   }, {
      //     "variables": ["test_select1", "test_select2"],
      //     "values": {
      //       "test_select1_value1": "test_select2_value1",
      //       "test_select1_value2": "test_select2_value2",
      //       "test_select1_value3": "test_select2_value3",
      //       "test_select1_value4": "test_select2_value4"
      //     }
      //   }]
      // }
  
      matching = tagMatchings.find(m => sourceTemplateSections.includes(m.category1) && targetTemplateSections.includes(m.category2))
      if(!matching) {
        matching = tagMatchings.find(m => sourceTemplateSections.includes(m.category2) && targetTemplateSections.includes(m.category1))
        if(matching) {
          // switch matching direction
          matching = {
            ...matching,
            matchings: matching.matchings.map(m => {
              const flippedMatching = {
                variables: [m.variables[1], m.variables[0]]
              }
              if(m.values) {
                // flip direction of values object
                flippedMatching.values = {}
                for(let key in m.values) {
                  flippedMatching.values[m.values[key]] = key
                }
              }
              return flippedMatching
            })
          }
        }
      }
    }

    for (let key in values) {
      if(matching) {
        const matchingVar = matching.matchings.find(m => m.variables[0] === key)
        if(matchingVar) {
          if(matchingVar.values) {
            tmplValues[matchingVar.variables[1]] = matchingVar.values[values[key]]
          } else {
            tmplValues[matchingVar.variables[1]] = values[key]
          }
        } else {
          tmplValues[key] = values[key];
        }
      } else {
        tmplValues[key] = values[key];
      }
    }

    try {
      setShowGlobalResponseLoader(true, 2000);
      setGlobalResponseLoaderText("Transfert des données vers le nouveau document")
      const id = await createDocumentFromTemplate(tmpl, false, tmplValues);
      history.replace({ pathname: `/documents/${id}` });
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setShowGlobalResponseLoader(false);
    }
  };

  // On folder click
  const handleFolderClick = (f) => {
    if (f) {
      let filtered = templatesArr.filter(
        (d) => d.folderId && d.folderId.includes(f.id)
      );
      setSelectedFolder(f.id);
      filterBySearch(search, filtered, f.id);
      return;
    }
    let filtered = templatesArr.filter(
      (d) => !d.folderId || d.folderId.length === 0
    );
    setSelectedFolder('root');
    filterBySearch(search, filtered, 'root');
  };

  return (
    <Modal onClose={onClose}>
      <div className="inject-modal-v2">
        <div className="inject-modal-v2__head">
          <h2>
            <Sync /> Injecter les données dans un nouveau document
          </h2>
          <p className='mt-3 line-height-15'>
            {/* Réinjecter les données complétées dans ce document vers un autre modèle de la même famille<br/><br/>
            Exemple : d'un mandat vers un autre type de mandat ou vers un avenant au mandat, d'un compromis vers un autre type de
compromis ou vers un avenant au compromis, etc. */}
            Sélectionnez le document dans lequel envoyer les données :
          </p>
        </div>
        <div className="inject-modal-v2__search">
          <Input
            iconEl={<Search />}
            value={search}
            onChange={handleSearchChange}
            placeholder="Entrez les premières lettres d'un document"
          />
        </div>
        {!isReady ? (
          <div className="loader-wrapper loader-wrapper--2">
            <div className="loader-v2"></div>
          </div>
        ) : (
          <div className="inject-modal-v2__body">
            <div className="inject-modal-v2__body_left">
              <h6>DOSSIERS</h6>
              <ul>
                <li
                  className={`${selectedFolder === 'root' ? 'active' : ''}`}
                  onClick={() => handleFolderClick(null)}
                >
                  <div
                    className={`${
                      selectedFolder === 'root' ? 'main active' : 'main'
                    }`}
                  >
                    <Folder /> Dossier racine{' '}
                    <span className="num">{numOfTmpltsInFolder.root}</span>
                  </div>
                </li>
                {foldersFetched ? (
                  folders.map((f, i) =>
                    !f.parentFolder ? (
                      <FolderItem
                        key={i}
                        f={f}
                        selectedFolder={selectedFolder}
                        onFolderClick={handleFolderClick}
                        folders={folders}
                        numOfTmpltsInFolder={numOfTmpltsInFolder}
                      />
                    ) : null
                  )
                ) : (
                  <div className="loader-wrapper loader-wrapper--3">
                    <div className="loader-v2"></div>
                  </div>
                )}
              </ul>
            </div>
            <div className="inject-modal-v2__body_right">
              <ul className="documents-list">
                {filteredArr.map((tmpl, i) => (
                  <li key={i} onClick={() => handleTemplateClick(tmpl)}>
                    <span className="icon">
                      <File />
                    </span>
                    <span className="icon hover">
                      <FileAlt />
                    </span>
                    {tmpl.name}
                  </li>
                ))}
              </ul>
              {filteredArr.length === 0 && (
                <p className="no-data">Aucun document dans ce dossier</p>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const FolderItem = ({
  f,
  selectedFolder,
  onFolderClick,
  folders,
  numOfTmpltsInFolder,
}) => {
  const subfolders = [...folders].filter((fol) => fol.parentFolder === f.id);

  return (
    <li>
      <div
        className={`${selectedFolder === f.id ? 'main active' : 'main'}`}
        onClick={() => onFolderClick(f)}
      >
        <Folder /> {f.name}{' '}
        <span className="num">{numOfTmpltsInFolder[f.id]}</span>
      </div>
      {subfolders.length > 0 && (
        <ul className="subfolder">
          {subfolders.map((fol, j) => (
            <FolderItem
              key={j}
              f={fol}
              selectedFolder={selectedFolder}
              onFolderClick={onFolderClick}
              folders={folders}
              numOfTmpltsInFolder={numOfTmpltsInFolder}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default SingleDocumentInject;
