
const HistoryEventBox = ({ time, event }) => {

  const labelForEventType = (type) => {
    switch(type) {
      case 'download_pdf':
        return 'Téléchargement PDF'
      case 'download_docx':
        return 'Téléchargement DOCX'
      case 'signature_request_vialink':
        return 'Demande de signature Vialink'
      case 'email_share':
        return 'Partage par email'
      case 'lre_sent':
        return 'LRE envoyée'
      default:
        return `Type d'événement inconnu: ${type}`
    }
  }

  let content = (
    <div className="history-box-v2">
      <header>
        <div className="history-box-v2__time">{time}</div>
        <div className="history-box-v2__progress">
          <p className="main">{labelForEventType(event.type)}</p>
          <p className="number-of-entries">{`${event?.author?.firstname} ${event?.author?.lastname}`}</p>
        </div>
      </header>
    </div>
  )

  return content
}

export default HistoryEventBox
