const MoodBad = () => {
    return (
        <span className="svg-icon">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.9859 3.3335C10.7859 3.3335 3.33594 10.8002 3.33594 20.0002C3.33594 29.2002 10.7859 36.6668 19.9859 36.6668C29.2026 36.6668 36.6693 29.2002 36.6693 20.0002C36.6693 10.8002 29.2026 3.3335 19.9859 3.3335ZM20.0026 33.3335C12.6359 33.3335 6.66927 27.3668 6.66927 20.0002C6.66927 12.6335 12.6359 6.66683 20.0026 6.66683C27.3693 6.66683 33.3359 12.6335 33.3359 20.0002C33.3359 27.3668 27.3693 33.3335 20.0026 33.3335ZM25.8359 18.3335C27.2193 18.3335 28.3359 17.2168 28.3359 15.8335C28.3359 14.4502 27.2193 13.3335 25.8359 13.3335C24.4526 13.3335 23.3359 14.4502 23.3359 15.8335C23.3359 17.2168 24.4526 18.3335 25.8359 18.3335ZM14.1693 18.3335C15.5526 18.3335 16.6693 17.2168 16.6693 15.8335C16.6693 14.4502 15.5526 13.3335 14.1693 13.3335C12.7859 13.3335 11.6693 14.4502 11.6693 15.8335C11.6693 17.2168 12.7859 18.3335 14.1693 18.3335ZM20.0026 22.5002C16.1193 22.5002 12.8193 24.9335 11.4859 28.3335H28.5193C27.1859 24.9335 23.8859 22.5002 20.0026 22.5002Z" />
            </svg>
        </span>
    )
}

export default MoodBad