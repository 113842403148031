import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useContext, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { UserContext } from "../../../context";
import React from 'react'
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { Message } from "@material-ui/icons";
import draftToHtml from "draftjs-to-html";
import { adminProperty, manufacturerProperty } from "../../../utils";
import { Undo } from "@mui/icons-material";

const MailModalContentMessage = forwardRef(({ message, setMessage, onReturn, onSubmit }, ref) => {

  const { user, agency } = useContext(UserContext)

  const messageTemplate = (agencyName, agencyAddress, agencyZip, agencyCity) => {
    return `
        <p>
            <p> Bonjour,</p>
            <br/>
            <p>Veuillez trouver en pièce-jointe les documents.<br/>Bonne réception.</p>
            <br/>
            <p>Cordialement,<br/><b>${user.firstname} ${user.lastname.toUpperCase()}</b><br/>${adminProperty(user, 'email')} / ${adminProperty(user, 'phone')}</p>
            <br/>
            <p>${agencyName || ""}<br/>${agencyAddress || '&lt;Adresse de l’agence&gt;'} ${agencyZip || '&lt;Code postal de l’agence&gt;'} ${agencyCity || '&lt;Ville de l’agence&gt;'}
            </p>           
        </p>
    `
  }

  const blocksFromHTML = convertFromHTML(messageTemplate(manufacturerProperty(user?.manufacturer, 'name'), manufacturerProperty(user?.manufacturer, 'address'), manufacturerProperty(user?.manufacturer, 'zip'), manufacturerProperty(user?.manufacturer, 'city')))
  const [editorState, setEditorState] = useState(EditorState.createWithContent(
    ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
  ));

  useEffect(() => {
    if (!message) {
      return
    }
    const blocksFromHTML = convertFromHTML(message)
    setEditorState(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
    ))
  }, [message])

  useEffect(() => {
    console.log({ user, agency })
  }, [user, agency])

  const handleChangeEditorState = (state) => {
    setEditorState(state)
  }

  const handleContinue = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const messageHtml = draftToHtml(rawContentState)
    setMessage(messageHtml)
    onSubmit()
  }

  const handleReturn = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const messageHtml = draftToHtml(rawContentState)
    setMessage(messageHtml)
    onReturn()
  }

  useImperativeHandle(ref, () => ({
    handleContinue,
    handleReturn
  }));

  return (
    <div className='registered-mail-send-modal-message-container'>
      <div className='flex flex-row modal-section-title mb-20'>
        Transmettez le message suivant aux destinataires
      </div>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={handleChangeEditorState}
        toolbar={{
          options: [
              "history",
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "textAlign",
              "list",
              "remove"
          ],
          history: {
              undo: { icon: '/images/undo.png', className: "editor-icon" },
              redo: { icon: '/images/redo.png', className: "editor-icon" }
          },
          inline: {
              inDropdown: false,
              options: [
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "monospace",
              ],
              bold: { icon: "/images/editor-icons/bold.svg", className: "editor-icon" },
              italic: { icon: "/images/editor-icons/italic.svg", className: "editor-icon" },
              underline: { icon: "/images/editor-icons/underline.svg", className: "editor-icon" },
              strikethrough: { icon: "/images/editor-icons/strikethrough.svg", className: "editor-icon" },
              monospace: { icon: "/images/editor-icons/brackets.svg", className: "editor-icon" },
          },
          fontFamily: {
            options: [
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana"
            ]
          },
          textAlign: {
              inDropdown: false,
              options: ["left", "center", "right", "justify"],
              left: { icon: "/images/editor-icons/align-left.svg", className: "editor-icon" },
              center: { icon: "/images/editor-icons/align-center.svg", className: "editor-icon" },
              right: { icon: "/images/editor-icons/align-right.svg", className: "editor-icon" },
              justify: { icon: "/images/editor-icons/align-justify.svg", className: "editor-icon" },
          },
          blockType: {
              inDropdown: true,
              options: [
                  "Normal",
                  "H1",
                  "H2",
                  "H3",
                  "H4",
                  "H5",
                  "H6",
                  "Blockquote",
                  "Code"
              ],
              icon: "/images/editor-icons/font.svg",
          },
          list: {
              inDropdown: false,
              options: ["unordered", "ordered", "indent", "outdent"],
              unordered: { icon: "/images/editor-icons/bullet-list.svg", className: "editor-icon" },
              ordered: { icon: "/images/editor-icons/ordered-list.svg", className: "editor-icon" },
              indent: { icon: "/images/editor-icons/indent.svg", className: "editor-icon" },
              outdent: { icon: "/images/editor-icons/outdent.svg", className: "editor-icon" },
          },
          remove: { icon: "/images/editor-icons/clear-format.svg", className: "editor-icon" }
        }}
      />
    </div>
  )
});

export default MailModalContentMessage