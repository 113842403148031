const TemplateLayout1 = () => {
  return (
    <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="91" height="116" viewBox="0 0 91 116">
        <g id="Group_1168" data-name="Group 1168" transform="translate(0.431)">
          <rect id="Rectangle_1340" data-name="Rectangle 1340" width="86" height="112" rx="7" transform="translate(4.569 4)" fill="#cbcbcb"/>
          <rect id="Rectangle_1344" data-name="Rectangle 1344" width="87" height="112" rx="7" transform="translate(-0.431)" fill="#fff"/>
          <rect id="Rectangle_1358" data-name="Rectangle 1358" width="32" height="4" transform="translate(9.569 20)" fill="#3d9cfa"/>
          <rect id="Rectangle_1359" data-name="Rectangle 1359" width="32" height="4" transform="translate(44.569 20)" fill="#3d9cfa"/>
          <rect id="Rectangle_1360" data-name="Rectangle 1360" width="32" height="18" transform="translate(9.569 26)" fill="#61e786"/>
          <rect id="Rectangle_1361" data-name="Rectangle 1361" width="32" height="18" transform="translate(44.569 26)" fill="#61e786"/>
          <rect id="Rectangle_1362" data-name="Rectangle 1362" width="37" height="4" transform="translate(25.569 11)" fill="#61e786"/>
          <rect id="Rectangle_1391" data-name="Rectangle 1391" width="67" height="3" transform="translate(9.569 59)" fill="#3d9cfa"/>
          <rect id="Rectangle_1390" data-name="Rectangle 1390" width="67" height="3" transform="translate(9.569 65)" fill="#3d9cfa"/>
          <rect id="Rectangle_1397" data-name="Rectangle 1397" width="67" height="4" transform="translate(9.569 86)" fill="#3d9cfa"/>
          <rect id="Rectangle_1389" data-name="Rectangle 1389" width="67" height="3" transform="translate(9.569 70)" fill="#3d9cfa"/>
          <rect id="Rectangle_1396" data-name="Rectangle 1396" width="67" height="3" transform="translate(9.569 92)" fill="#3d9cfa"/>
          <rect id="Rectangle_1393" data-name="Rectangle 1393" width="23" height="4" transform="translate(9.569 75)" fill="#3d9cfa"/>
          <rect id="Rectangle_1395" data-name="Rectangle 1395" width="39" height="4" transform="translate(9.569 97)" fill="#3d9cfa"/>
          <rect id="Rectangle_1394" data-name="Rectangle 1394" width="67" height="3" transform="translate(9.569 48)" fill="#3d9cfa"/>
          <rect id="Rectangle_1392" data-name="Rectangle 1392" width="67" height="3" transform="translate(9.569 54)" fill="#3d9cfa"/>
        </g>
      </svg>
    </span>
  )
}

export default TemplateLayout1