import React, { useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { UserContext } from '../../context/user/userState';
import { availableOn } from '../../utils';

const SideMenuLong = ({ active, onShowLongMenu }) => {
  const { showSignatures } = useContext(UserContext);

  const menuIconClickHandler = (e) => {
    e.preventDefault();
    onShowLongMenu(false);
  }

  return(
    <div className={active ? "side-menu side-menu--long active" : "side-menu side-menu--long"}>
      <div className="side-menu__bg" onClick={() => onShowLongMenu(false)}></div>
      <ul className="side-menu__list">
        <li className="menu">
          <Link to="/" onClick={menuIconClickHandler}>
            <div className="icon">
              <div className="logo">JS</div>
            </div>
          </Link>
        </li>
        <li>
          <NavLink to="/" exact onClick={() => onShowLongMenu(false)}>
            <span className="icon"><i className="custom-icon-dashboard"></i></span>
            <span className="text">Tableau de bord</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/documents" exact onClick={() => onShowLongMenu(false)}>
            <span className="icon"><DescriptionIcon /></span>
            <span className="text">Documents</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/templates" onClick={() => onShowLongMenu(false)}>
            <span className="icon"><i className="custom-icon-template"></i></span>
            <span className="text">Modèles</span>
          </NavLink>
        </li>
        {/* {partner === 'jurisur' && <li>
          <NavLink to="/signatures" onClick={(e) => {onShowLongMenu(false)}}>
            <span className="icon"><i className="custom-icon-create"></i></span>
            <span className="text">Signatures</span>
          </NavLink>
        </li>} */}
        {showSignatures && <li>
          <NavLink to="/signatures" onClick={(e) => {onShowLongMenu(false)}}>
            <span className="icon"><i className="custom-icon-create"></i></span>
            <span className="text">Signatures</span>
          </NavLink>
        </li>}
        {/* <li>
          <NavLink to="/recommended" onClick={(e) => {e.preventDefault(); onShowLongMenu(false)}}>
            <span className="icon"><EmailOutlinedIcon /></span>
            <span className="text">Recommandés</span>
          </NavLink>
        </li> */}
      </ul>

      <div className="side-menu__bottom">
        {availableOn(['development', 'staging']) && <NavLink to="/help">
          <span className="icon"><HelpOutlineIcon /></span>
          <span className="text">Aide</span>
        </NavLink>}
        <NavLink to="/settings/my-profile">
          <span className="icon"><SettingsOutlinedIcon /></span>
          <span className="text">Paramètres</span>
        </NavLink>
      </div>
    </div>
  );
}

export default SideMenuLong;