import firebase from './firebase'
import config from '../config.json'

const firebaseEnvDoc = firebase.firestore().collection('environments').doc('production')

const fetch_signatures = async () => {
  // 1640991600 is 01/01/2022
  // add this rule to firebase rules
  /* 
    match /environments/{env}/signatures/{id} {
    	allow read: if request.auth.uid != null;
    }
  */
  try {
    const res = await firebaseEnvDoc.collection('signatures')
      .where('partner', '==', 'squarehabitat')
      .where('createdAt', '>=', 1640991600000).get()
    let result = []
    res.docs.forEach(doc => {
      result.push({ id: doc.id, ...doc.data() })
    })
    console.log(result)
    return result
  } catch (err) {
    console.log('fetching signatures for export error', err)
  }
}

export {
  fetch_signatures,
}