import { GET_SIGNATURE_TEMPLATES, SET_SIGNATURE_TEMPLATES } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_SIGNATURE_TEMPLATES:
      return {
        ...state,
        signatureTemplates: action.payload,
        signatureTemplatesFetched: true
      }
    case SET_SIGNATURE_TEMPLATES:
      return {
        ...state,
        signatureTemplates: action.payload
      }
    default:
      return state;
  }
}

export default reducer;