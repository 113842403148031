import React, { useContext } from 'react'
import { useHistory  } from 'react-router'
import moment from 'moment'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'

import Loader from '../../UI/Loader'
import SupportPageLayout from '../../layouts/SupportPageLayout'
import { HelpContext } from '../../../context'
import { MainLayout } from '../../../layouts'
import { NewReleasesOutlined } from '@material-ui/icons'

const WhatsNew = ({v2}) => {
  const { whatsNewArticles, articlesFetched } = useContext(HelpContext)
  const history = useHistory()
  

  // Category click handler
  const articleClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/what-is-new/${id}`)
  }
  

  return (
    <MainLayout className="page-help" pageTitle="Nouveautés" titleIcon={<NewReleasesOutlined />} fullHeader={true}>
      <SupportPageLayout title={`Nouveautés`} v2={v2}>
        <div className="help-category">
          <div className="help-category__body">
            <div className="support-page__no-data"></div>
            {/* {!articlesFetched 
              ? <div className="loader-wrapper loader-wrapper--2"><Loader normal small primary /></div>
              : whatsNewArticles.length 
                ? <ul>
                    {whatsNewArticles.map((article, idx) => (
                      <li key={idx}>
                        <a href="/#" onClick={(e) => articleClickHandler(e, article.id)}><DescriptionIcon /> {article.title.en}</a>
                        <span>{moment(article.date).format('DD MMM YYYY @ HH:mm')}</span>
                      </li>
                    ))}
                  </ul>
                : <div className="support-page__no-data"><p>{`Aucune nouveauté`}</p></div>
            } */}
          </div>
        </div>
      </SupportPageLayout>
    </MainLayout>
  )
}

export default WhatsNew