import React, { useState, useContext } from 'react';

import Dropdown from './Dropdown';
import { DocumentsContext } from '../../context/documents/documentsState';

const FilterListItem = ({ icon, text, dropdownValues, onItemSelect, time }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selected, setSelected] = useState(text);
  const { dateFilters } = useContext(DocumentsContext);

  const itemClickHandler = (value, text) => {
    setSelected(text);
    onItemSelect(value, time);
    setShowDropdown(false);
  }

  let selectedOutput = '';

  if(time && time === 'after') {
    if(dateFilters.after === '') {
      selectedOutput = text;
    }else {
      selectedOutput = selected;
    }
  }else if(time && time === 'before') {
    if(dateFilters.before === '') {
      selectedOutput = text;
    }else {
      selectedOutput = selected;
    }
  }else {
    selectedOutput = selected;
  }

  return(
    <li className="filters__list_item">
      <div onClick={() => setShowDropdown(!showDropdown)}><span className="icon">{icon}</span> {selectedOutput}</div>
      {dropdownValues && dropdownValues.length > 0 && 
        <Dropdown show={showDropdown} onChange={setShowDropdown} animate>
          <ul className="filters__list_item-menu">
            <li onClick={() => itemClickHandler('', text)}>{text}</li>
            {dropdownValues.map((value, index) => (
              <li key={index} onClick={() => itemClickHandler(time === 'after' ? value.start : value.end, value.label)}>{value.label}</li>
            ))}
          </ul>
        </Dropdown>
      }
    </li>
  );
}

export default FilterListItem;