const ArrowDropDown = () => {
  return (
    <span className="svg-icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6468 13.229L12 13.5814L12.3532 13.229L16.5896 9.00177L17.2929 9.70504L12 14.9979L6.70711 9.70504L7.41039 9.00177L11.6468 13.229Z" />
      </svg>
    </span>
  )
}

export default ArrowDropDown