import React from 'react';
import ReactDOM from 'react-dom';

import Loader from './Loader';

const ResponseLoader = ({ text, className }) => {
  const content = (
    <div className={`response-loader ${className}`}>
      <div className="response-loader__content">
        {text && <p className="response-loader__text">{text}</p>}
        <Loader normal primary />
      </div>
    </div>
  );

  return ReactDOM.createPortal(content, document.getElementById('page-loader'));
};

export default ResponseLoader;