import React, { createContext, useReducer, useState } from 'react'

import { fetch_help_articles, fetch_help_categories, fetch_features, update_feature } from '../../services/support'
import { GET_FEATURES, GET_HELP_ARTICLES, GET_HELP_CATEGORIES } from '../types'
import { sortArrayOfObjects } from '../../utils'

import reducer from './helpReducer'

export const HelpContext = createContext()

const HelpState = ({ children }) => {
  const initialState = {
    articles: {},
    articlesArr: [],
    whatsNewArticles: [],
    documentationArticles: [],
    articlesFetched: false,
    categories: {},
    categoriesArr: [],
    categoriesFetched: false,
    features: [],
    featuresFetched: false
  }

  const [selectedCategory, setSelectedCategory] = useState(null)
  const [state, dispatch] = useReducer(reducer, initialState)

  // Fetch articles
  const fetchHelpArticles = async () => {
    try {
      const res = await fetch_help_articles()
      const data = res.data
      // console.log('help articles', data)
      let arr = []
      let whatsNewArr = []
      let documentationArr = []
      for(let id in data) {
        if(data[id].category === 'whats_new') {
          whatsNewArr.push({ id, ...data[id] })
        }else if(data[id].category === 'documentation') {
          documentationArr.push({ id, ...data[id] })
        }else {
          arr.push({ id, ...data[id] })
        }
      }
      dispatch({
        type: GET_HELP_ARTICLES,
        payload: {
          obj: data,
          arr: sortArrayOfObjects(arr, 'title.en', 'asc'),
          whatsNewArr: sortArrayOfObjects(whatsNewArr, 'title.en', 'asc'),
          documentationArr: sortArrayOfObjects(documentationArr, 'title.en', 'asc')
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  // Fetch help categories
  const fetchHelpCategories = async () => {
    try {
      const res = await fetch_help_categories()
      const data = res.data
      let arr = []
      for(let id in data) {
        if(id !== 'whats_new' && id !== 'documentation') {
          arr.push({ id, ...data[id] })
        }
      }
      dispatch({
        type: GET_HELP_CATEGORIES,
        payload: {
          obj: data,
          arr
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  // Fetch features
  const fetchFeatures = async () => {
    try {
      const res = await fetch_features()
      const data = res.data
      // console.log('features', data)

      dispatch({
        type: GET_FEATURES,
        payload: data
      })
    } catch (err) {
      console.log(err)
    }
  }

  // Update feature
  const updateFeature = async (id, votes) => {
    try {
      await update_feature(id, { votes })
    } catch (err) {
      console.log('update feature err', err)
    }
  }

  return <HelpContext.Provider value={{
    articles: state.articles,
    articlesArr: state.articlesArr,
    whatsNewArticles: state.whatsNewArticles,
    documentationArticles: state.documentationArticles,
    articlesFetched: state.articlesFetched,
    categories: state.categories,
    categoriesArr: state.categoriesArr,
    categoriesFetched: state.categoriesFetched,
    features: state.features,
    featuresFetched: state.featuresFetched,
    fetchHelpArticles,
    fetchHelpCategories,
    selectedCategory,
    setSelectedCategory,
    fetchFeatures,
    updateFeature,
  }}>
    { children }
  </HelpContext.Provider>
}

export default HelpState