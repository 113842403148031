const Check = () => {
  return (
    <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="8.507" height="6.429" viewBox="0 0 8.507 6.429">
        <path id="Path_1655" data-name="Path 1655" d="M836.041-681.6l2.71,2.811,4.721-4.828" transform="translate(-835.501 684.144)" fill="none" stroke="#fff" strokeWidth="1.5"/>
      </svg>
    </span>
  )
}

export default Check