import { memo } from 'react'

import { CustomTooltip, Select, Option } from '../ui_new'

const VariableCustomerInput = ({ fieldVariable, customerType, handle, val, customersArrayForType, customerKeyForType, onCustomerChange }) => {
  const { variable, tooltip, type, idx} = fieldVariable
  const sourceArray = customersArrayForType(customerType).filter(c => c[customerKeyForType(customerType, 'firstname')] || c[customerKeyForType(customerType, 'firstname')])

  return (      
    <div className="tooltip-wrapper doc-variable-el" data-id={idx}>
      {tooltip ? (
        <CustomTooltip content={tooltip} elementType="select">
          <div style={{padding: '5px 0'}}>
            <Select
              onChange={(val) => onCustomerChange(val, customerType)}
              selected={val || ''}
              placeholder="Sélectionnez une option"
            >
              <Option value="">{''}</Option>
              { sourceArray.map((opt, oi) => {
                return (
                  <Option value={opt[customerKeyForType(customerType, 'id')]}>
                    { `${opt[customerKeyForType(customerType, 'firstname') || '']} ${opt[customerKeyForType(customerType, 'lastname')] || ''}` }
                  </Option>
                )
              })}
            </Select>
          </div>
        </CustomTooltip>
      ) : (
        <div style={{padding: '5px 0'}}>
          <Select
            onChange={(val) => onCustomerChange(val, customerType)}
            selected={val || ''}
            placeholder="Sélectionnez une option"
          >
            <Option value="">{''}</Option>
            { sourceArray.map((opt, oi) => {
              return (
                <Option value={opt[customerKeyForType(customerType, 'id')]}>
                  { `${opt[customerKeyForType(customerType, 'firstname')] || ''} ${opt[customerKeyForType(customerType, 'lastname')] || ''}` }
                </Option>
              )
            })}
          </Select>
        </div>
      )}
    </div>
  )
}

export default memo(VariableCustomerInput)