import React, { useEffect, useContext } from 'react';
// import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';

import { NotificationContext } from '../../context/notifications/notificationState';

let timeout;

const DEFAULT_DURATION = 4000;

const Notification = ({ msg }) => {
  const { notification, setNotification } = useContext(NotificationContext);

  useEffect(() => {
    if(timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setNotification({ msg: '', type: 'default' });
    }, notification?.duration || DEFAULT_DURATION);
    // eslint-disable-next-line
  }, [msg]);

  let notificationClass = 'notification';
  let icon = null;

  if(notification.type === 'success' || notification.type === 'default') {
    notificationClass += ' notification--success';
    icon = <CheckCircleIcon />;
  }else if(notification.type === 'danger') {
    notificationClass += ' notification--danger';
    icon = <ErrorOutlineIcon />;
  }else if(notification.type === 'warning') {
    notificationClass += ' notification--warning';
    icon = <ReportProblemIcon />;
  }else if(notification.type === 'info') {
    notificationClass += ' notification--info';
    icon = <InfoIcon />;
  }

  // const closeHandler = (e) => {
  //   e.preventDefault();
  //   clearTimeout(timeout);
  //   setNotification({ msg: '', type: 'default' });
  // }

  return(
    <div className={notificationClass}>
      {/* <a href="/#" className="notification__close" onClick={closeHandler}><CloseIcon /></a> */}
      <div className="icon">{icon}</div>
      <p>{notification.msg}</p>
    </div>
  );
}

export default Notification;