import firebase from 'firebase/app'
require("firebase/auth")
require("firebase/storage")
require("firebase/firestore")

const firebaseConfig = {
  apiKey: "AIzaSyAlcZcgqQDfRAHl-vBQhp1_ij_0-jvmt94",
  authDomain: "jurisur-be.firebaseapp.com",
  databaseURL: "https://jurisur-be.firebaseio.com",
  projectId: "jurisur-be",
  storageBucket: "jurisur-be.appspot.com",
  messagingSenderId: "676706450772",
  appId: "1:676706450772:web:670dabeb0bb2bf6d77dcc1",
  measurementId: "G-NJVEYEY7X8"
}

firebase.initializeApp(firebaseConfig)

export default firebase