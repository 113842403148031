const Archive = () => {
  return (
    <span className="svg-icon">

      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.04 5.23L19.65 3.55C19.38 3.21 18.97 3 18.5 3H6.5C6.03 3 5.62 3.21 5.34 3.55L3.96 5.23C3.67 5.57 3.5 6.02 3.5 6.5V19C3.5 20.1 4.4 21 5.5 21H19.5C20.6 21 21.5 20.1 21.5 19V6.5C21.5 6.02 21.33 5.57 21.04 5.23ZM6.74 5H18.26L19.07 5.97H5.94L6.74 5ZM5.5 19V8H19.5V19H5.5ZM13.95 10H11.05V13H8.5L12.5 17L16.5 13H13.95V10Z" fill="#482982" />
      </svg>

    </span>
  )
}

export default Archive