import React, { useRef, useState, useEffect, useContext } from 'react';

import TemplatesSidebar from '../sections/templates/TemplatesSidebar';
import TemplatesView from '../sections/templates/TemplatesView';
// import SelectedResources from '../sections/SelectedResources';
import CreateEditFolderModal from '../sections/CreateEditFolderModal';
import EditTemplateModal from '../sections/templates/EditTemplateModal';
import Alert from '../UI/Alert';
import MoveToFolderModal from '../sections/MoveToFolderModal';
import MoveMultipleDocsToFolderModal from '../sections/MoveMultipleDocsToFolderModal';
import MoveToSectionModal from '../sections/MoveToSectionModal';
import CreateEditSectionModal from '../sections/CreateEditSectionModal';

import { FolderContext } from '../../context/folders/folderState';
import { DocumentsContext } from '../../context/documents/documentsState';
import { NotificationContext } from '../../context/notifications/notificationState';
import { LoaderContext } from '../../context/loader/loaderState';
import { SectionContext } from '../../context/sections/sectionsState';
import { getAllParentFolders, folderHasSubfolders, folderHasTemplates, urlSuffixForEnvironment } from '../../utils';
import { delete_template } from '../../services/firestore';
import axios from 'axios';
import JSZip from 'jszip';
import config from '../../config.json';
import { UserContext } from '../../context';

const Templates = () => {
  const dashboardSidebar = useRef();
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);
  const [showDeleteFolderAlert, setShowDeleteFolderAlert] = useState(false);
  const [showDeleteTemplateAlert, setShowDeleteTemplateAlert] = useState(false);
  const [mode, setMode] = useState('create');
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [search, setSearch] = useState('');
  const [moved, setMoved] = useState(false);
  const [removeSearchClose, setRemoveSearchClose] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('Tous');
  const { fetchFolders, createFolder, foldersLoading, folders, updateFolder, deleteFolder, folderToMove, setParentFolder, setFolderBreadcrumbs, selectedFilter, setSelectedFilter, folderBreadcrumbs, updateMultipleFolders, setCurrentlyActiveFolder, currentlyActiveFolder } = useContext(FolderContext);
  const { templates, setFilteredTemplates, templatesLoading, updateTemplate, getTemplates, updateMultipleTemplates, setShowFilteredTemplates, getTemplateById } = useContext(DocumentsContext);
  const { user } = useContext(UserContext)
  const { setLoading, loading } = useContext(LoaderContext);
  const [showDeleteSelectedTemplatesAlert, setShowDeleteSelectedTemplatesAlert] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const [resetFolders, setResetFolders] = useState(false);
  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false);
  const [showMoveMultipleDocsToFolderModal, setShowMoveMultipleDocsToFolderModal] = useState(false);
  const [tmplFolderToMove, setTmplFolderToMove] = useState(null);
  const [folderCreated, setFolderCreated] = useState(false);
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [searching, setSearching] = useState(false);
  const [showMoveToSectionModal, setShowMoveToSectionModal] = useState(false);
  const [showCreateEditSectionModal, setShowCreateEditSectionModal] = useState(false);
  const [sectionMode, setSectionMode] = useState('create');
  const { sections, createSection, updateSection, deleteSection } = useContext(SectionContext);
  const [currentSection, setCurrentSection] = useState(null);
  const [sectionLoading, setSectionLoading] = useState(false);

  useEffect(() => {

    return () => {
      setFilteredTemplates({});
      setCurrentlyActiveFolder(null);
      setShowFilteredTemplates(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const templatesCopy = {...templates};
    const filtered = filterNonDeletedTemplates(templatesCopy);
    setFilteredTemplates(filtered);
    // eslint-disable-next-line
  }, [templates]);

  useEffect(() => {
    fetchFolders();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(moved) {
      setSelectedDocuments(prev => {
        const selected = [...prev];
        const templatesCopy = {...templates};
        const newSelected = selected.map(t => templatesCopy[t.id]);
        return newSelected;
      });
      setMoved(false);
    }
  }, [moved, templates]);

  const filterNonDeletedTemplates = (tmplts) => {
    const filtered = {};
    for(let key in tmplts) {
      if(!tmplts[key].deleted) {
        filtered[key] = tmplts[key];
      }
    }
    return filtered;
  }

  const sidebarOpenHandler = () => {
    dashboardSidebar.current.classList.add('opened');
  }

  const sidebarCloseHandler = () => {
    dashboardSidebar.current.classList.remove('opened');
  }

  const removeLastResourceHandler = () => {
    const copySelectedDocuments = [...selectedDocuments];
    copySelectedDocuments.pop();
    setSelectedDocuments(copySelectedDocuments);
  }

  // Create folder
  const folderCreateClickHandler = (e, folder = null) => {
    setMode('create');
    if(folder) {
      setParentFolder(folder);
    }else {
      setCurrentFolder(null);
    }
    setShowFolderModal(true);
  }

  const createFolderHandler = async (data) => {
    await createFolder(data);
    setShowFolderModal(false);
    setFolderCreated(true);
  }

  // Update folder
  const folderEditClickHandler = (folder) => {
    setCurrentFolder(folder);
    setMode('edit');
    setShowFolderModal(true);
  }

  const updateFolderHandler = async (data, id) => {
    await updateFolder(data, id);
    setShowFolderModal(false);
    setCurrentFolder(null);
    if(folderBreadcrumbs.length > 0) {
      const breadcrumbs = [...folderBreadcrumbs];
      const findBreadcrumb = breadcrumbs.find((b) => b.id === id);
      if(findBreadcrumb) {
        findBreadcrumb.name = data.name;
        setFolderBreadcrumbs(prev => [...prev].map(b => b.id === findBreadcrumb.id ? findBreadcrumb : b));
      }
    }
  }

  // Delete folder
  const folderDeleteClickHandler = (folder, reset = false) => {
    setCurrentFolder(folder);
    setShowDeleteFolderAlert(true);
    if(reset) {
      setResetFolders(true);
    }
  }

  const deleteFolderHandler = async () => {
    await deleteFolder(currentFolder.id);
    setNotification({ msg: `Dossier "${currentFolder.name}" supprimé !`, type: 'default' });
    setShowDeleteFolderAlert(false);
    setCurrentFolder(null);
    if(resetFolders) {
      setSelectedFilter('all');
      setSelectedTitle('Tous');
      setFolderBreadcrumbs([]);
      setResetFolders(false);
      const nonDeletedTemplates = filterNonDeletedTemplates({...templates});
      setFilteredTemplates(nonDeletedTemplates);
    }
  }

  const deleteTemplateClickHandler = (template) => {
    setCurrentTemplate(template);
    setShowDeleteTemplateAlert(true);
  }

  const deleteTemplateHandler = async () => {
    await delete_template(currentTemplate.id);
    await getTemplates();
    setShowDeleteTemplateAlert(false);
    setCurrentTemplate(null);
  }

  const toggleTemplateHiddenClickHandler = async (template) => {
    await updateTemplate({ hidden: !template.hidden }, template.id);
  }

  // Update
  const editTemplateClickHandler = (template) => {
    setCurrentTemplate(template);
    setShowEditTemplateModal(true);
  }

  const updateTemplateHandler = async (data, id) => {
    await updateTemplate(data, id);
    setShowEditTemplateModal(false);
    setCurrentTemplate(null);
  }

  // Download
  const downloadTemplatesClickHandler = async (e) => {
    e.preventDefault();
    const templatesToDownload = []
    // return 
    selectedDocuments.forEach((t, index) => {
      if(!t.folderRow)  {
        if(!templatesToDownload.includes(t.id)) {
          templatesToDownload.push(t.id)
        }
      } else {
        if(!templates) {
          return
        }
        for(let templateId in templates) {
          if(templates[templateId].folderId && templates[templateId].folderId.includes(t.id)) {
            if(!templatesToDownload.includes(templateId)) {
              templatesToDownload.push(templateId)
            }
          }
        }
      }
    });

    if(templatesToDownload.length === 1) {
      let template = templates[templatesToDownload[0]]
      let templateBlob = await downloadFile(template.templateUrl, 'blob')
      saveFile(templateBlob, `${template.name} (${template.id}).docx`)
      return
    }

    const zip = new JSZip()
    const downloadPromises = []
    for(let templateId of templatesToDownload) {
      downloadPromises.push(downloadAndZipTemplate(templateId, zip))
    }

    await Promise.all(downloadPromises)

    zip.generateAsync({ type: 'blob' }).then(blob => {
      saveFile(blob, 'templates.zip')
    })
  }

  const downloadFile = async (url, type) => {
    let response = await axios.get(url, { responseType: type })
    return response.data
  }

  const downloadAndZipTemplate = async (templateId, zip) => {
    let template = templates[templateId]
    let data = await downloadFile(template.templateUrl, 'arraybuffer')
    zip.file(`${template.name} (${templateId}).docx`, data, { binary: true })
  }

  const saveFile = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 10)
    }
  }

  // Move selected documents to folder
  const moveToFolderHandler = async (folder) => {
    selectedDocuments.forEach(async (doc) => {
      if(doc.folderId.length < 2 && !doc.folderId.includes(folder.id)) {
        const foldersArr = [...doc.folderId];
        foldersArr.push(folder.id);
        await updateTemplate({ folderId: foldersArr }, doc.id);
      }else if(doc.folderId.length === 2 && !doc.folderId.includes(folder.id)) {
        const foldersArr = [...doc.folderId];
        foldersArr.splice(0,1);
        foldersArr.push(folder.id);
        await updateTemplate({ folderId: foldersArr }, doc.id);
      }
      setMoved(true);
    });
  }

  // Move folder
  const moveFolderHandler = async (selectedFolder) => {
    const parentFolders = getAllParentFolders(folders, selectedFolder);
    if(selectedFolder) {
      if(folderToMove.id === selectedFolder.id) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${folderToMove.name}" vers le dossier "${selectedFolder.name}"`, type: 'warning' });
      if(folderToMove.parentFolder === selectedFolder.id) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${folderToMove.name}" vers le dossier "${selectedFolder.name}"`, type: 'warning' });  
      if(parentFolders.find(f => f.id === folderToMove.id)) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${folderToMove.name}" vers le dossier "${selectedFolder.name}"`, type: 'warning' }); 
      
      // console.log(`Move ${folderToMove.name} to ${selectedFolder.name}`);
      await updateFolder({ parentFolder: selectedFolder.id }, folderToMove.id);
      setNotification({ msg: `Dossier "${folderToMove.name}" déplacé dans le dossier "${selectedFolder.name}"`, type: 'default' }); 
      
    }else {
      if(folderToMove.parentFolder) {
        // console.log(`Move ${folderToMove.name} to root folder`);
        await updateFolder({ parentFolder: null }, folderToMove.id);
        setNotification({ msg: `Le dossier "${folderToMove.name}" a été déplacé vers le dossier racine`, type: 'default' });
      }else {
        setNotification({ msg: `Impossible de déplacer le dossier "${folderToMove.name}" vers le dossier racine`, type: 'warning' });
      }
    }
  }

  // Move template to folder
  const moveTemplateToFolderHandler = async (template, folder) => {
    if(folder) {
      // if(template.folderId.length < 2 && !template.folderId.includes(folder.id)) {
      if(!template.folderId.includes(folder.id)) {
        // const foldersArr = [...template.folderId];
        const foldersArr = [];
        foldersArr.push(folder.id);
        await updateTemplate({ folderId: foldersArr }, template.id);
        setNotification({ msg: `Le modèle "${template.name}" a été déplacé dans le dossier "${folder.name}"!`, type: 'default' });
      }
      // else if(template.folderId.length === 2 && !template.folderId.includes(folder.id)) {
      //   const foldersArr = [...template.folderId];
      //   const updatedFoldersArr = foldersArr.filter(fId => fId !== currentlyActiveFolder.id);
      //   updatedFoldersArr.push(folder.id);
      //   await updateTemplate({ folderId: updatedFoldersArr }, template.id);
      //   setNotification({ msg: `Le modèle "${template.name}" a été déplacé dans le dossier "${folder.name}"!`, type: 'default' });
      // }
      else {
        setNotification({ msg: `Le modèle "${template.name}" est déjà dans le dossier "${folder.name}"!`, type: 'warning' });
      }
      setMoved(true);
    }else if(template.folderId.length > 0){
      await updateTemplate({ folderId: [] }, template.id);
      setNotification({ msg: `Le modèle "${template.name}" a été déplacé dans le dossier racine!`, type: 'default' });
    }
  }

  // Delete
  const templatesDeleteHandler = async () => {
    setLoading(true); 
    const promises = [];
    let foldersDeleted = false;
    let templatesDeleted = false;
    for(let i = 0; i < selectedDocuments.length; i++) {
      if(selectedDocuments[i].folderRow) {
        if(folderHasSubfolders(folders, selectedDocuments[i])) continue;
        if(folderHasTemplates(templates, selectedDocuments[i])) continue;
        promises.push(deleteFolder(selectedDocuments[i].id, true));
        foldersDeleted = true;
      }else {
        promises.push(delete_template(selectedDocuments[i].id));
        templatesDeleted = true;
      }
    }
    Promise.all(promises)
      .then(async () => {
        if(templatesDeleted) {
          await getTemplates();
          templatesDeleted = false;
        }
        if(foldersDeleted) {
          await fetchFolders();
          foldersDeleted = false;
        }
        setLoading(false); 
        setSelectedDocuments([]);
        setShowDeleteSelectedTemplatesAlert(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false); 
        setSelectedDocuments([]);
        setShowDeleteSelectedTemplatesAlert(false);
      });
  }

  // Search
  const searchHandler = e => {
    e.preventDefault();
    let t = {...templates};
    const nonDeletedTemplates = filterNonDeletedTemplates(t);
    
    const filteredF = [...folders].filter(folder => {
      if(selectedFilter === 'all') {
        return folder.parentFolder === undefined || folder.parentFolder === null;
      }else {
        return folder.parentFolder === selectedFilter;
      }
    });

    if(search.trim() === '') {
      setFilteredTemplates(nonDeletedTemplates);
      setFilteredFolders(filteredF);
      return;
    }

    let filtered = {};
    let filteredFolArr = [];

    for(let key in nonDeletedTemplates) {
      const name = nonDeletedTemplates[key].name.toLowerCase();
      if(name.includes(search.trim().toLowerCase())) {
        filtered[key] = {...nonDeletedTemplates[key]};
      }
    }
    [...filteredF].forEach(fol => {
      if(fol.name.toLowerCase().includes(search.trim().toLowerCase())) {
        filteredFolArr.push(fol);
      }
    })
    setSearching(true);
    setFilteredTemplates(filtered);
    setFilteredFolders(filteredFolArr);
    setSelectedDocuments([]);
    // setSearch('');
    // setRemoveSearchClose(true);
  }

  // Clear form
  const searchFormClearHandler = () => {
    let t = {...templates};
    const nonDeletedTemplates = filterNonDeletedTemplates(t);
    setFilteredTemplates(nonDeletedTemplates);
  }

  // Filter templates by folders
  const filterByFoldersHandler = (folder) => {
    setCurrentlyActiveFolder(folder);
    const parentFolders = getAllParentFolders(folders, folder);
    const reverseParentFolders = parentFolders.reverse();
    if(parentFolders.length > 0) {
      setFolderBreadcrumbs([...reverseParentFolders, folder]);
    }else {
      if(folder) {
        setFolderBreadcrumbs([folder]);
      }else {
        setFolderBreadcrumbs([]);
      }
    }
    setSearch('');
    setRemoveSearchClose(true);
    const filtered = {};
    const nonDeletedTemplates = filterNonDeletedTemplates({...templates});
    if(folder) {
      for(let key in nonDeletedTemplates) {
        if(nonDeletedTemplates[key].folderId.includes(folder.id)) {
          filtered[key] = nonDeletedTemplates[key];
        }
      }
      setFilteredTemplates(filtered);
      setSelectedFilter(folder.id);
      setSelectedTitle(folder.name);
    }else {
      setFilteredTemplates(nonDeletedTemplates);
      setSelectedFilter('all');
      setSelectedTitle('Tous');
    }
    setSelectedDocuments([]);
  }

  // Single template download
  const singleTemplateDownload = (tmpl) => {
    const a = document.createElement("a"); 
    a.setAttribute('download', tmpl.name); 
    a.setAttribute('href', `${tmpl.templateUrl}`); 
    document.body.appendChild(a);
    a.click();
  }

  const shareTemplateClickHandler = (tmpl) => {
    window.open(`https://jurisur-document${urlSuffixForEnvironment(config.environment).replace('_', '-')}.web.app/document/${tmpl.id}?token=${user.clientAccessToken}`, '_blank');
  }

  // Move folder click handler
  const folderMoveClickHandler = (folder) => {
    setTmplFolderToMove(folder);
    setShowMoveToFolderModal(true);
    console.log(folder)
  }

  // Move folder
  const moveFolder = async (current, active) => {
    if(!current.parentFolder && !active) {
      return setNotification({ msg: `Le dossier "${current.name}" est déjà dans le dossier racine !`, type: 'warning' });
    }
    if(active && current.parentFolder === active.id) {
      return setNotification({ msg: `Le dossier "${current.name}" se trouve déjà dans le dossier "${active.name}"!`, type: 'warning' });
    }
    setLoading(true);
    if(active) {
      await updateFolder({ parentFolder: active.id }, current.id);
      setNotification({ msg: `Le dossier "${current.name}" déplacé dans le dossier "${active.name}"`, type: 'default' });
      setTmplFolderToMove(prev => ({...prev, parentFolder: active.id}));
    }else {
      await updateFolder({ parentFolder: null }, current.id);
      setNotification({ msg: `Le dossier "${current.name}" a été déplacé dans le dossier racine`, type: 'default' });
      setTmplFolderToMove(prev => ({...prev, parentFolder: null}));
    }

    const parentFolders = getAllParentFolders(folders, {...current, parentFolder: active ? active.id : null});
    const reverseParentFolders = parentFolders.reverse();
    if(parentFolders.length > 0) {
      setFolderBreadcrumbs([...reverseParentFolders, {...current, parentFolder: active ? active.id : null}]);
    }else {
      if(current) {
        setFolderBreadcrumbs([{...current, parentFolder: active ? active.id : null}]);
      }else {
        setFolderBreadcrumbs([]);
      }
    }

    setFolderCreated(true);
    setLoading(false);
  }

  const moveSelectedDocumentsClickHandler = (e) => {
    e.preventDefault();
    setShowMoveMultipleDocsToFolderModal(true);
  }

  const moveSelectedDocumentsHandler = async (moveTo, current) => {
    // console.log(selectedDocuments, moveTo);
    const promises = [];
    const templatesArr = [];
    let foldersArr = [];
    for(let i = 0; i < selectedDocuments.length; i++) {
      const doc = selectedDocuments[i];
      if(doc.folderRow) {
        if(moveTo) {
          const parentFolders = getAllParentFolders(folders, moveTo);

          if(moveTo.id === doc.parentFolder) continue;
          if(moveTo.id === doc.id) continue;
          if(moveTo.parentFolder === doc.id) continue;
          if(parentFolders.find(f => f.id === doc.id)) continue;

          promises.push(updateFolder({ parentFolder: moveTo.id }, doc.id, false));
          foldersArr.push({ data: { parentFolder: moveTo.id }, id: doc.id });
          // promises.push(doc);
        }else {
          if(doc.parentFolder === null || doc.parentFolder === undefined) continue;

          promises.push(updateFolder({ parentFolder: null }, doc.id, false));
          foldersArr.push({ data: { parentFolder: null }, id: doc.id });
          // promises.push(doc);
        }

      }else {
        if(moveTo) {
          // if(doc.folderId.length < 2 && !doc.folderId.includes(moveTo.id)) {
          if(!doc.folderId.includes(moveTo.id)) {
            let fArr = [...doc.folderId];
            fArr = fArr.filter(fId => current ? fId !== current.id : fId);
            fArr.push(moveTo.id);
            // foldersArr.push(moveTo.id);
            promises.push(updateTemplate({ folderId: fArr }, doc.id, false));
            templatesArr.push({ data: { folderId: fArr }, id: doc.id });
            // promises.push(doc);
          }
          // else if(doc.folderId.length === 2 && !doc.folderId.includes(moveTo.id)) {
          //   let fArr = [...doc.folderId];
          //   fArr = fArr.filter(fId => current ? fId !== current.id : fId);
          //   fArr.push(moveTo.id);
          //   foldersArr.push(moveTo.id);
          //   promises.push(updateTemplate({ folderId: fArr }, doc.id, false));
          //   templatesArr.push({ data: { folderId: fArr }, id: doc.id });
          //   // promises.push(doc);
          // }
        }else {
          // root folder
          // if(doc.folderId.length === 2) {
            let fArr = [...doc.folderId];
            fArr = fArr.filter(fId => current ? fId !== current.id : fId);
            // foldersArr = [...fArr];
            promises.push(updateTemplate({ folderId: fArr }, doc.id, false));
            templatesArr.push({ data: { folderId: fArr }, id: doc.id });
            // promises.push(doc);
          // }
        }
      }
    }

    if(promises.length === 0) return;

    setLoading(true);
    Promise.all(promises)
      .then(async () => {
        // await fetchFolders();
        updateMultipleFolders(foldersArr);
        updateMultipleTemplates(templatesArr);
        setLoading(false);
        setSelectedDocuments([]);
        setShowMoveMultipleDocsToFolderModal(false);
        setNotification({ msg: `${promises.length} élément(s) déplacé(s) vers le dossier ${moveTo ? moveTo.name : 'racine'} !`, type: 'default' });
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  // Open create section modal
  const createSectionClickHandler = (mode, activeSection) => {
    setSectionMode(mode);
    setCurrentSection(activeSection);
    setShowCreateEditSectionModal(true);
  }

  // Create section handler
  const createSectionHandler = async (data) => {
    setSectionLoading(true);
    await createSection(data, () => {
      setShowCreateEditSectionModal(false);
      setNotification({ msg: `Section "${data.name}" créé avec succès!` });
      setSectionLoading(false);
    }, (err) => {
      console.log(err)
      setSectionLoading(false);
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    });
  }

  // Edit section handler
  const editSectionHandler = async (data, id) => {
    setSectionLoading(true);
    await updateSection(id, data, () => {
      setShowCreateEditSectionModal(false);
      setNotification({ msg: `Section "${data.name}" mise à jour créé avec succès!` });
      setSectionLoading(false);
      setCurrentSection(prev => ({...prev, name: data.name}));
    }, (err) => {
      console.log(err)
      setSectionLoading(false);
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    });
  }

  // Move templates to section handler
  const moveTemplatesToSectionHandler = (section) => {
    const ids = [...section.templates];
    const itemsAdded = [];
    selectedDocuments.forEach(t => !t.folderRow && !ids.includes(t.id) && ids.push(t.id) && itemsAdded.push(t.id));
    if(itemsAdded.length > 0) {
      setLoading(true);
      updateSection(section.id, { templates: ids }, () => {
        setSelectedDocuments([]);
        setNotification({ msg: `${itemsAdded.length} modèle(s) ajoutés à la section "${section.name}"`});
        setLoading(false);
        setShowMoveToSectionModal(false);
      }, (err) => {
        console.log(err);
        setLoading(false);
        setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
      });
    }else {
      setNotification({ msg: `Impossible de déplacer les éléments sélectionnés vers la section`, type: 'warning' });
    }
  }

  return(
    <div className="dashboard-inner">
      <div className="dashboard-inner__left" ref={dashboardSidebar}>
        <div className="dashboard-inner__left_overlay" onClick={sidebarCloseHandler}></div>
        <div className="dashboard-inner__left_sidebar">
          <TemplatesSidebar 
            folders={folders} 
            onFolderEdit={folderEditClickHandler} 
            onFolderCreate={folderCreateClickHandler}
            onFolderDelete={folderDeleteClickHandler}
            onFolderFilter={filterByFoldersHandler}
            selectedFilter={selectedFilter}
            foldersLoading={foldersLoading}
            onOpenFolderModal={() => {
              setMode('create');
              setShowFolderModal(true);
            }}
            onMoveToFolder={moveFolderHandler}
            templatesLoading={templatesLoading}
            templates={templates}
            onMoveTemplateToFolder={moveTemplateToFolderHandler}
          />
        </div>
      </div>
      <div className="dashboard-inner__right">
        <TemplatesView 
          onSidebarOpen={sidebarOpenHandler} 
          selected={selectedDocuments} 
          onSetSelected={setSelectedDocuments} 
          onTemplateDelete={deleteTemplateClickHandler}
          onTemplateToggleHidden={toggleTemplateHiddenClickHandler}
          onTemplateEdit={editTemplateClickHandler}
          onTemplateDownload={singleTemplateDownload}
          onTemplateShare={shareTemplateClickHandler}
          onDownload={downloadTemplatesClickHandler}
          onDelete={() => setShowDeleteSelectedTemplatesAlert(true)}
          onSearch={searchHandler}
          onSetSearch={setSearch}
          searchValue={search}
          removeSearchClose={removeSearchClose}
          onSetRemoveSearchClose={setRemoveSearchClose}
          selectedTitle={selectedTitle}
          onSearchFormClear={searchFormClearHandler}
          setViewTitle={setSelectedTitle}
          onOpenFolderModal={() => {
            setMode('create');
            setShowFolderModal(true);
          }}
          onMoveToFolder={moveTemplateToFolderHandler}
          rowDraggable={true}
          onFilterByFolders={filterByFoldersHandler}
          onFolderCreate={folderCreateClickHandler}
          onFolderEdit={folderEditClickHandler}
          onFolderDelete={folderDeleteClickHandler}
          onFolderMove={folderMoveClickHandler}
          onFolderClick={filterByFoldersHandler}
          onMoveSelectedTemplates={moveSelectedDocumentsClickHandler}
          filteredFolders={filteredFolders}
          searching={searching}
          onSetSearching={setSearching}
          onSectionsOpen={(e) => {e.preventDefault(); setShowMoveToSectionModal(true)}}
        />
      </div>

      {showFolderModal && <CreateEditFolderModal 
        onClose={() => setShowFolderModal(false)} 
        onCreate={createFolderHandler}
        onEdit={updateFolderHandler}
        foldersLoading={foldersLoading}
        currentFolder={currentFolder}
        mode={mode}
      />}
      {showDeleteFolderAlert && <Alert 
        onClose={() => setShowDeleteFolderAlert(false)}
        text="Etes-vous certain de vouloir supprimer ce dossier?"
        onSubmit={deleteFolderHandler}
        loading={foldersLoading}
        deleteAlert
      />}
      {showDeleteTemplateAlert && <Alert 
        onClose={() => setShowDeleteTemplateAlert(false)}
        text="Etes-vous certain de vouloir supprimer ce modèle? Les documents dépendants de ce modèle ne pourront plus être générés."
        onSubmit={deleteTemplateHandler}
        loading={templatesLoading}
        deleteAlert
      />}
      {showDeleteSelectedTemplatesAlert && <Alert 
        onClose={() => setShowDeleteSelectedTemplatesAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer les modèles sélectionnés ?"
        onSubmit={templatesDeleteHandler}
        loading={loading}
        deleteAlert
      />}
      {showEditTemplateModal && <EditTemplateModal 
        onClose={() => setShowEditTemplateModal(false)}
        onEdit={updateTemplateHandler}
        templatesLoading={templatesLoading}
        currentTemplate={currentTemplate}
      />}
      {showMoveToFolderModal && <MoveToFolderModal 
        onClose={() => setShowMoveToFolderModal(false)} 
        folders={folders}
        currentFolder={tmplFolderToMove}
        onMove={moveFolder}
        onCreateNewFolder={folderCreateClickHandler}
        newFolderCreated={folderCreated}
        onNewFolderCreated={setFolderCreated}
        loading={loading}
      />}
      {showMoveMultipleDocsToFolderModal && <MoveMultipleDocsToFolderModal 
        onClose={() => setShowMoveMultipleDocsToFolderModal(false)} 
        folders={folders}
        currentFolder={selectedFilter === 'all' ? null : [...folders].find(f => f.id === selectedFilter)}
        onMove={moveSelectedDocumentsHandler}
        onCreateNewFolder={folderCreateClickHandler}
        newFolderCreated={folderCreated}
        onNewFolderCreated={setFolderCreated}
        selectedDocuments={selectedDocuments}
        loading={loading}
      />}
      {showMoveToSectionModal && <MoveToSectionModal 
        onClose={() => setShowMoveToSectionModal(false)} 
        sections={sections}
        onMove={moveTemplatesToSectionHandler}
        onCreateSection={createSectionClickHandler}
        selectedDocuments={selectedDocuments}
        loading={loading}
        currentSection={currentSection}
        getTemplateById={getTemplateById}
        updateSection={updateSection}
        deleteSection={deleteSection}
      />}
      {showCreateEditSectionModal && <CreateEditSectionModal 
        onClose={() => setShowCreateEditSectionModal(false)} 
        onCreate={createSectionHandler}
        onEdit={editSectionHandler}
        loading={sectionLoading}
        currentSection={currentSection}
        onSetCurrentSection={setCurrentSection}
        mode={sectionMode}
      />}
    </div>
  );
}

export default Templates;