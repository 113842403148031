import React, { useState, useContext, useEffect } from 'react';
import { ChevronLeft, ChevronRight, ExpandMore } from '@material-ui/icons'

import ActionsDropdown from '../../UI/ActionsDropdown'
import DashboardView from '../DashboardView';
import { sorterWithPathAndOrder, saveSortingToLS, getSorting, savePaginationDataToLS, getPaginationData } from '../../../utils';
import { SignatureContext } from '../../../context/signatures/signaturesState';
import { perPageOptions } from '../../../constants'

const SignaturesView = ({ onSidebarOpen, selected, onSetSelected, data, onDelete, statusData, statusLoading, onSearch, onSetSearch, searchValue, removeSearchClose, onSetRemoveSearchClose, onSearchFormClear, viewTitle, setViewTitle, onSetFilteredSignatures, onDownload, onPreviewDoc, onDownloadSingle, onDeleteSingle, dataReady, onStatusClick }) => {
  const sortLS = getSorting()
  const [columns, setColumns] = useState([
    { 
      label: 'Titre', 
      cssClass: 'col-1', 
      all: true, 
      value: 'title', 
      order: sortLS?.signatures?.value === 'title' ? sortLS.signatures?.order || 'asc' : 'asc',  
      active: sortLS && sortLS.signatures ? sortLS.signatures.value === 'title' : true 
    },
    { 
      label: 'Destinataires', 
      cssClass: 'col-2 signatures-col-2', 
      all: false, 
      value: 'recipients.email__arr', 
      order: sortLS?.signatures?.value === 'recipients.email__arr' ? sortLS.signatures?.order || 'desc' : 'desc', 
      active: sortLS?.signatures?.value === 'recipients.email__arr' 
    },
    { 
      label: 'Envoyé par', 
      cssClass: 'col-3 signatures-col-3', 
      all: false, 
      value: 'sentBy', 
      order: sortLS?.signatures?.value === 'sentBy' ? sortLS.signatures?.order || 'desc' : 'desc',  
      active: sortLS?.signatures?.value === 'sentBy' 
    },
    { 
      label: 'Créé le', 
      cssClass: 'col-4 signatures-col-4', 
      all: false, 
      value: 'createdAt', 
      order: sortLS?.signatures?.value === 'createdAt' ? sortLS.signatures?.order || 'desc' : 'desc',  
      active: sortLS?.signatures?.value === 'createdAt' 
    },
    { 
      label: 'Statut', 
      cssClass: 'col-5 signatures-col-5', 
      all: false, 
      value: 'status', 
      order: sortLS?.signatures?.value === 'status' ? sortLS.signatures?.order || 'desc' : 'desc', 
      active: sortLS?.signatures?.value === 'status' 
    },
  ]);
  const { signaturesFetched } = useContext(SignatureContext);
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [allPages, setAllPages] = useState(1)
  const [paginatedData, setPaginatedData] = useState([])
  const [paginationReady, setPaginationReady] = useState(false)
  
  useEffect(() => {
    const paginationData = getPaginationData()
    if(paginationData && paginationData.signatures) {
      paginationData.signatures.items && setPerPage(paginationData.signatures.items)
      paginationData.signatures.current && setCurrentPage(paginationData.signatures.current)
      paginationData.signatures.all && setAllPages(paginationData.signatures.all)
    }
  }, [])

  // Change paginated data arr when filtered arr changes
  useEffect(() => {
    let current = currentPage
    let sliceFrom = current === 1 ? 0 : (current - 1) * perPage
    if(sliceFrom >= data.length) {
      sliceFrom = 0
      setCurrentPage(1)
    }
    let slicedData = data.slice(sliceFrom, sliceFrom + perPage)
    setPaginatedData(slicedData)
    let all = Math.ceil(data.length / perPage)
    setAllPages(all === 0 ? 1 : all)
    setTimeout(() => setPaginationReady(true), 200)
  }, [data, perPage, currentPage, allPages])

  // Sort
  const sortHandler = (value, order) => {
    let copiedData = [...data];
    if(value === 'recipients.email__arr') {
      const signatures = copiedData.sort((a, b) => {
        if(a.recipients[0].email.toLowerCase() < b.recipients[0].email.toLowerCase()) { return -1 * (order === 'desc' ? -1 : 1) }
        else if(a.recipients[0].email.toLowerCase() > b.recipients[0].email.toLowerCase()) { return 1 * (order === 'desc' ? -1 : 1) }
        else return 0;
      });
      onSetFilteredSignatures(signatures);
    }else {
      const signatures = copiedData.sort(sorterWithPathAndOrder(value, order));
      onSetFilteredSignatures(signatures);
    }
    setColumns(prev => [...prev].map(col => col.value === value ? { ...col, order, active: true } : {...col, active: false}));
    saveSortingToLS(value, order, 'signatures')
  }

  // On prev button click 
  const prevButtonClickHandler = () => {
    setCurrentPage(currentPage - 1)
    let sliceFrom = currentPage - 1 === 1 ? 0 : (currentPage - 2) * perPage
    setPaginatedData([...data].slice(sliceFrom, sliceFrom + perPage))
    onSetSelected([])
    savePaginationDataToLS(perPage, currentPage - 1, allPages, 'signatures')
  }

  // On next button click 
  const nextButtonClickHandler = () => {
    setCurrentPage(currentPage + 1)
    let sliceFrom = currentPage * perPage
    setPaginatedData([...data].slice(sliceFrom, sliceFrom + perPage))
    onSetSelected([])
    savePaginationDataToLS(perPage, currentPage + 1, allPages, 'signatures')
  }

  // Per page change handler
  const perPageChangeHandler = (e, num) => {
    e.preventDefault()
    setPerPage(num)
    if(Math.ceil(data.length / num) < currentPage) {
      setCurrentPage(1)
    }
    window.document.body.click()
    savePaginationDataToLS(num, currentPage, allPages, 'signatures')
  }

  return(
    <>
      <DashboardView 
        data={paginatedData}
        columns={columns}
        view="signatures"
        onSidebarOpen={onSidebarOpen}
        selected={selected}
        onSetSelected={onSetSelected}
        onSort={sortHandler}
        hideArchiveBtn
        hideDuplicateBtn
        hideMoveBtn
        onDelete={onDelete}
        signatureStatuses={statusData}
        statusLoading={statusLoading}
        onSearchFormClear={onSearchFormClear}
        onSearch={onSearch}
        searchValue={searchValue}
        onSetSearch={onSetSearch}
        onSetRemoveSearchClose={onSetRemoveSearchClose}
        viewTitle={viewTitle}
        setViewTitle={setViewTitle}
        removeSearchClose={removeSearchClose}
        onDownload={onDownload}
        onPreviewDoc={onPreviewDoc}
        onDownloadSingle={onDownloadSingle}
        onDeleteSingle={onDeleteSingle}
        dataLoaded={signaturesFetched && dataReady && paginationReady}
        onStatusClick={onStatusClick}
      />

      {dataReady && paginationReady && paginatedData.length > 0 && <div className="pagination">
        <ActionsDropdown
          trigger={<button className="trigger">{perPage} <span><ExpandMore /></span></button>}
          hideHeader
          dropdownClass="pagination-dropdown"
          width={120}
        >
          <ul>
            {perPageOptions.map((num, i) => (
              <li key={i}>
                <div className="link">
                  <a href="/#" onClick={(e) => perPageChangeHandler(e, num)}>{num}</a>
                </div>
              </li>
            ))}
          </ul>
        </ActionsDropdown>
        <button onClick={prevButtonClickHandler} disabled={currentPage === 1}><ChevronLeft /></button>
        <div className="pagination__body">page {currentPage} de {allPages}</div>
        <button onClick={nextButtonClickHandler} disabled={currentPage === allPages}><ChevronRight /></button>
      </div>}
    </>
  );
}

export default SignaturesView;