const PlusCircle = () => {
  return (
    <span className="svg-icon">

      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 1.5C5.36 1.5 2 4.86 2 9C2 13.14 5.36 16.5 9.5 16.5C13.64 16.5 17 13.14 17 9C17 4.86 13.64 1.5 9.5 1.5ZM13.25 9.75H10.25V12.75H8.75V9.75H5.75V8.25H8.75V5.25H10.25V8.25H13.25V9.75Z" />
      </svg>
    </span>
  )
}

export default PlusCircle