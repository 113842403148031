import React, { useRef, useState, useContext, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { isMobile, isIOS } from 'react-device-detect';
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import SignaturesSidebar from '../sections/signatures/SignaturesSidebar';
import SignaturesView from '../sections/signatures/SignaturesView';
import Alert from '../UI/Alert';
import Modal from '../UI/Modal';
import Loader from '../UI/Loader';
import Status from '../UI/Status';
import DashboardSidePanel from '../UI/DashboardSidePanel';
import { LoaderContext } from '../../context/loader/loaderState';
import { SignatureContext } from '../../context/signatures/signaturesState';
import { NotificationContext } from '../../context/notifications/notificationState';
import { UserContext } from '../../context/user/userState';
import { get_signature_status, delete_signature, get_signature_document } from '../../services/firestore';
import { SIGNATURE_STATUS_TRANLSATIONS } from '../../constants';
import { base64toBlob, getSorting, sortArrayOfObjects } from '../../utils'
import PdfPreview from '../UI/PdfPreview';
import { SignaturePreviewModal, SignaturesNotAvailable } from '../misc';

const Signatures = () => {
  const dashboardSidebar = useRef();
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const { setLoading } = useContext(LoaderContext);
  const { signatures, deleteSignature, updateSignature } = useContext(SignatureContext);
  const { setNotification } = useContext(NotificationContext);
  const { canUseSignatures, siteConfigsLoaded, urlStore, isNotSQHorCAIpartner } = useContext(UserContext);
  const [signaturesStatus] = useState([]);
  const [statusLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [removeSearchClose, setRemoveSearchClose] = useState(false);
  const [showDeleteSignatureAlert, setShowDeleteSignatureAlert] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [filteredSignatures, setFilteredSignatures] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState('Tous');
  const [showPreviewDocModal, setShowPreviewDocModal] = useState(false);
  const [previewSrc, setPreviewSrc] = useState('');
  const [previewName, setPreviewName] = useState('')
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewError, setPreviewError] = useState('');
  const [showDeleteSingleSignatureAlert, setShowDeleteSingleSignatureAlert] = useState(false);
  const [signatureToDelete, setSignatureToDelete] = useState(null);
  const [dataReady, setDataReady] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [activeSignature, setActiveSignature] = useState(null);
  const [singleStatusLoading, setSingleStatusLoading] = useState(false);
  const history = useHistory();

  // Open sidebar
  const sidebarOpenHandler = () => {
    dashboardSidebar.current.classList.add('opened');
  }

  // Close sidebar
  const sidebarCloseHandler = () => {
    dashboardSidebar.current.classList.remove('opened');
  }

  // Get status when signatures array change
  useEffect(() => {
    const sortLS = getSorting()
    let sort = 'title'
    let order = 'asc'
    if(sortLS && sortLS.signatures) {
      sort = sortLS.signatures.value
      order = sortLS.signatures.order
    }
    setFilteredSignatures(sortArrayOfObjects(signatures, sort, order));
    setDataReady(true);
  }, [signatures]);

  // Delete signatures
  const deleteSignaturesHandler = (e) => {
    e.preventDefault();

    const promises = [];
    const ids = [];
    selectedDocuments.forEach(doc => {
      promises.push(delete_signature(doc.id));
      ids.push(doc.id);
    });
    
    setDeleting(true);
    setLoading(true);
    Promise.all(promises)
      .then(() => {
        deleteSignature(ids);
        setLoading(false);
        setDeleting(false);
        setShowDeleteSignatureAlert(false);
        setSelectedDocuments([]);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  // Filter signatures by input from search form
  const searchHandler = e => {
    e.preventDefault();
    let s = [...signatures];

    if(search.trim() === '') {
      return setFilteredSignatures(s);
    }

    let filtered = [];

    s.forEach((signature) => {
      const title = signature.title.toLowerCase();
      if(title.includes(search.trim().toLowerCase())) {
        filtered.push(signature);
      }
    });

    setFilteredSignatures(filtered);
    setSelectedDocuments([]);
  }

  // Clear form
  const searchFormClearHandler = () => {
    let s = [...signatures];
    setFilteredSignatures(s);
  }

  // Filter by status
  const filterByStatusHandler = (status, active) => {
    const s = [...signatures];

    if(active) {
      setSelectedTitle('Tous');
      return setFilteredSignatures(signatures);
    }

    let filtered = [];
    filtered = s.filter(s => s.status === status);

    setSelectedTitle(SIGNATURE_STATUS_TRANLSATIONS[status]);
    setFilteredSignatures(filtered);
    setSelectedDocuments([]);
  }

  // Download signatures
  const downloadSignatureHandler = async (e) => {
    e.preventDefault();

    setLoading(true)
    if(selectedDocuments.length === 1) {
      const doc = selectedDocuments[0]
      const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id })
      if(res.response.status) {
        if(res.response.data) {
          if(isIOS) {
            const blob = base64toBlob(res.response.data)
            const a = document.createElement('a')
            a.onclick = saveAs(blob, `${doc.title || 'document'}.pdf`)
          }else {
            const a = document.createElement("a");
            a.href = `data:application/pdf;base64,${res.response.data}`; 
            a.download = `${doc.title}.pdf`; 
            a.click(); 
          }
        } else if(res.response.url) {
          const a = document.createElement("a");
          a.href = res.response.url
          a.download = `${doc.title}.pdf`; 
          a.click(); 
        }
      }
    }else {
      const zip = new JSZip()
      let docNames = []
      for(let i in selectedDocuments) {
        const doc = selectedDocuments[i]
        const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id })
        if(res.response.status) {
          let name = `${doc.title}.pdf`
          zip.file(
            docNames.includes(name) ? `${doc.title}(${i}).pdf` : name, 
            res.response.data, 
            {base64: true}
          )
          docNames.push(`${doc.title}.pdf`)
        }
      }
      docNames = []

      zip.generateAsync({type:"blob"}).then(function(content) {
        saveAs(content, "signatures.zip")
      })
      // const promises = [];
      // selectedDocuments.forEach(doc => {
      //   promises.push(get_signature_document({ document_id: doc.document_id, package_id: doc.package_id }));
      // });
      // setLoading(true);
      // Promise.all(promises)
      //   .then((data) => {
      //     setLoading(false);
      //     data.forEach((doc, i) => {
      //       if(doc.response.status) {
      //         if(isIOS) {
      //           const blob = base64toBlob(doc.response.data)
      //           const a = document.createElement('a')
      //           a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`)
      //         }else {
      //           const a = document.createElement("a");
      //           a.href = `data:application/pdf;base64,${doc.response.data}`; 
      //           a.download = `${selectedDocuments[i].title}.pdf`; 
      //           a.click(); 
      //         }
      //       }
      //     });
      //   })
      //   .catch(err => console.log(err));
    }

    setSelectedDocuments([])
    setLoading(false)
  }

  // Download single signature doc
  const downloadSingleSignatureDocHandler = async (e, doc) => {
    if(e) {
      e.preventDefault();
    }
    
    const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id });

    if(res.response.status) {
      if(res.response.data) {
        if(isIOS) {
          const blob = base64toBlob(res.response.data)
          const a = document.createElement('a')
          a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`)
        }else {
          const a = document.createElement("a");
          a.href = `data:application/pdf;base64,${res.response.data}`; 
          a.download = `${doc.title}.pdf`; 
          a.click(); 
        }
      } else if(res.response.url) {
        const a = document.createElement("a");
        a.href = res.response.url
        a.download = `${doc.title}.pdf`; 
        a.click(); 
      }
    }
  }

  // Delete single signature link click handler
  const deleteSingleSignatureClickHandler = (e, doc) => {
    e.preventDefault();
    setShowDeleteSingleSignatureAlert(true);
    setSignatureToDelete(doc);
  }

  // Delete single signature
  const deleteSingleSignatureHandler = async () => {
    try {
      setDeleting(true);
      setLoading(true);
      await delete_signature(signatureToDelete.id);
      deleteSignature([signatureToDelete.id]);
      setLoading(false);
      setDeleting(false);
      setShowDeleteSingleSignatureAlert(false);
    } catch (err) {
      console.log(err);
    }

  }

  // Preview signature
  const previewSignatureHandler = async (doc) => {
    setPreviewLoading(true);
    if(!isMobile) {
      setShowPreviewDocModal(true);
      try {
        setPreviewName(doc.title || doc.name)
        const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id });
        if(res.response.status) {
          if(res.response.data) {
            setPreviewSrc(`data:application/pdf;base64,${res.response.data}`);
          } else if(res.response.url) {
            setPreviewSrc(res.response.url)
          } else {
            setPreviewError(res.response.message.join(''));
          }
        }else {
          setPreviewError(res.response.message.join(''));
        }
        setPreviewLoading(false);
      } catch (err) {
        setPreviewLoading(false);
        setPreviewError('Quelque chose a mal tourné. Veuillez réessayer.');
      }
    }else {
      downloadSingleSignatureDocHandler(null, doc);
    }
  }

  // On status click
  const statusClickHandler = async (signature) => {
    setShowSidePanel(true);
    setSingleStatusLoading(true);
    try {
      const res = await get_signature_status({ PACKAGE_ID: [signature.package_id] });
      if(res.response.status) {
        const s = {...signature};
        // s.users = res.response.data[0].users.filter(u => u.id !== 'qDL9mKBa8iQL'); // pierre-jean's id
        s.users = res.response.data[0].users.slice(1); // pierre-jean's id
        setActiveSignature(s);
        if(signature.status !== res.response.data[0].status_provider) {
          await updateSignature(s.id, { status: res.response.data[0].status_provider });
        }
      }else {
        throw new Error('');
      }
      setSingleStatusLoading(false);
    } catch (err) {
      console.log(err);
      setSingleStatusLoading(false);
      setActiveSignature(signature);
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' });
    }
  }

  return(
    <div className={siteConfigsLoaded && !canUseSignatures ? "dashboard-inner blured" : "dashboard-inner"}>
      <div className="dashboard-inner__left" ref={dashboardSidebar}>
        <div className="dashboard-inner__left_overlay" onClick={sidebarCloseHandler}></div>
        <div className="dashboard-inner__left_sidebar">
          <SignaturesSidebar onFilterByStatus={filterByStatusHandler} statusLoading={statusLoading} signatures={signatures} />
        </div>
      </div>
      <div className="dashboard-inner__right">
        <SignaturesView 
          onSidebarOpen={sidebarOpenHandler} 
          data={filteredSignatures} 
          onSetFilteredSignatures={setFilteredSignatures}
          selected={selectedDocuments}
          onSetSelected={setSelectedDocuments} 
          onDelete={() => setShowDeleteSignatureAlert(true)}
          statusData={signaturesStatus}
          statusLoading={statusLoading}
          onSearch={searchHandler}
          onSetSearch={setSearch}
          searchValue={search}
          removeSearchClose={removeSearchClose}
          onSetRemoveSearchClose={setRemoveSearchClose}
          onSearchFormClear={searchFormClearHandler}
          viewTitle={selectedTitle}
          setViewTitle={setSelectedTitle}
          onDownload={downloadSignatureHandler}
          onPreviewDoc={previewSignatureHandler}
          onDownloadSingle={downloadSingleSignatureDocHandler}
          onDeleteSingle={deleteSingleSignatureClickHandler}
          dataReady={dataReady}
          onStatusClick={statusClickHandler}
        />
      </div>

      {showDeleteSignatureAlert && <Alert 
        onClose={() => setShowDeleteSignatureAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer les signatures sélectionnées ?"
        onSubmit={deleteSignaturesHandler}
        loading={deleting}
        deleteAlert
      />}
      {showDeleteSingleSignatureAlert && <Alert 
        onClose={() => setShowDeleteSingleSignatureAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer cette signature ?"
        onSubmit={deleteSingleSignatureHandler}
        loading={deleting}
        deleteAlert
      />}
      {showPreviewDocModal && (
        <SignaturePreviewModal
          onClose={() => setShowPreviewDocModal(false)}
          previewError={previewError}
          previewLoading={previewLoading}
          iframeSrc={previewSrc}
        />
      )}
      <DashboardSidePanel 
        show={showSidePanel} 
        onClose={() => setShowSidePanel(false)} 
        afterClose={() => setActiveSignature(null)} 
        title="État des signatures"
      >
        {activeSignature && !singleStatusLoading ? 
          <Fragment>
            <p>{activeSignature.title}</p>
            <ul>
              {activeSignature.users?.map((user, i) => (
                <li key={i}>
                  <span>{user.email}</span>
                  <Status type={user.status_provider} />
                </li>
              ))}
            </ul>
          </Fragment>
          : <div className="loader-wrapper loader-wrapper--2"><Loader primary small normal /></div>
        }
      </DashboardSidePanel>

      {siteConfigsLoaded && !canUseSignatures && <Modal medium2 noBg onClose={() => history.push('/')}>
        <SignaturesNotAvailable store={isNotSQHorCAIpartner ? urlStore : null} />
      </Modal>}
    </div>
  );
}

export default Signatures;