import { GET_HELP_ARTICLES, GET_HELP_CATEGORIES, GET_FEATURES } from '../types'

const reducer = (state, action) => {
  switch(action.type) {
    case GET_HELP_ARTICLES:
      return {
        ...state,
        articles: action.payload.obj,
        articlesArr: action.payload.arr,
        whatsNewArticles: action.payload.whatsNewArr,
        documentationArticles: action.payload.documentationArr,
        articlesFetched: true
      }
    case GET_HELP_CATEGORIES:
      return {
        ...state,
        categories: action.payload.obj,
        categoriesArr: action.payload.arr,
        categoriesFetched: true
      }
    case GET_FEATURES:
      return {
        ...state,
        features: action.payload,
        featuresFetched: true
      }
    default:
      return state
  }
}

export default reducer