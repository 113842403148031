import React from 'react';

import Tooltip from './Tooltip';

const LetterCircle = ({ data }) => {
  if(!data) return null;

  const letter = data.slice(0, 1);

  let circleClass = 'letter-circle tooltip-wrapper';

  const set1 = ['a', 'h', 'o', 'v', 'e', 'i', 'p'];
  const set2 = ['b', 'f', 'j', 'q', 'x', 's', 'z'];
  const set3 = ['c', 'g', 'k', 'r', 'm', 't', 'u'];
  const set4 = ['d', 'w', 'l', 'y', 'n'];

  if(set1.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--1';
  }else if(set2.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--2';
  }else if(set3.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--3';
  }else if(set4.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--4';
  }

  return(
    <div className="tooltip-wrapper">
      <Tooltip text={data} position="top" nowrap />
      <div className={circleClass}>
        {letter}
      </div>
    </div>
  );
}

export default LetterCircle;