import React, { useContext, useState, Fragment, useRef, useEffect } from 'react';
import { FaRegAddressCard } from 'react-icons/fa'
import { Person, Cancel } from '@material-ui/icons'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LockIcon from '@material-ui/icons/Lock';

import VariablesInner from './VariablesInner';
import Input from '../../UI/Input';
import Radio from '../../UI/Radio';
import Checkbox from '../../UI/Checkbox'
import Textarea from '../../UI/Textarea'
import Button from '../../UI/Button'
import CustomTooltip from '../../UI/CustomTooltip'
import moment from 'moment'
// import Tooltip from '@material-ui/core/Tooltip'
import Tooltip from '../../UI/Tooltip';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { areSectionConditionsMet, dateValueFormat, isCoverPageVariable, availableOn, formulaResult, printAreaValue, parseAreaValue } from '../../../utils';

import fr from 'date-fns/locale/fr';
import Select from '@material-ui/core/Select';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { UserContext } from '../../../context/user/userState';
import { CONTACT_DATA_KEYS, CONTACT_ID_VARIABLES, CONTACT_TYPE_FOR_ID_VARIABLE, CONTACT_VARIABLE_PREFIX_FOR_TYPE, TEXT_LINES, DATE_LINES, TEXTAREA_LINES, NUMBER_LINES } from '../../../constants';
registerLocale('fr', fr);

const DocumentSectionContent = ({ type, content, bulletListItems, variable, variables, data_type, question, options, tooltip, condition, conditions, values, onValuesChange, index, varIndex, repeatableSectionId, repeatableIndex, head, row, repeatableRowId, onAgencyModalOpen = () => {}, onNotariesModalOpen = () => {}, linesAddedTo = [], onRemoveLines = () => {}, onSetLinesAddedTo,  documentCheckboxValues, onCheckboxValueChange, locked }) => {
  const { contacts } = useContext(UserContext);
  
  const isCoverPageSection = () => {
    if(variable && isCoverPageVariable(variable)) {
      return true
    } 
    if(variables) {
      for(let v of variables) {
        if(isCoverPageVariable(v.variable)) {
          return true
        }
      }
    }
    if(bulletListItems) {
      for(let item of bulletListItems) {
        if(item.variables) {
          for(let v of item.variables) {
            if(isCoverPageVariable(v.variable)) {
              return true
            }
          }
        }
      }
    }
    return false
  }

  const isHandleLocked = (handle) => {
    return locked || (handle && handle.includes(':locked'))
  }

  const renderHeading1 = () => {
    return (
      <div className="document-detail-variables__head">
        <div className="document-detail-variables__head_top">
          <h1>
            { renderContent() }
          </h1>
        </div>
      </div>
    )
  }

  const renderHeading2 = () => {
    return (
      <div className="document-detail-variables__head">
        <div className="document-detail-variables__head_top">
          <h2>
            { renderContent() }
          </h2>
        </div>
      </div>
    )
  }

  const renderHeading3 = () => {
    return (
      <div className="document-detail-variables__head">
        <h3>
          { renderContent() }
        </h3>
      </div>
    )
  }

  const renderQuestion = (fieldVariable, handle) => {
    let q
    let o
    let v
    let dt
    let t
    let idx
    let lckd
    if (fieldVariable) {
      t = fieldVariable.tooltip
      q = fieldVariable.tooltip
      o = fieldVariable.options || []
      v = fieldVariable.variable
      dt = fieldVariable.type
      idx = fieldVariable.idx
      lckd = isHandleLocked(handle)
    } else {
      q = question
      o = options || []
      v = variable
      dt = data_type
      t = tooltip
      idx = varIndex
      lckd = isHandleLocked(handle)
    }
    if(dt === 'empty_lines') {
      return renderEmptyLinesInput({ question: q, options: o, variable: v, data_type: dt, type: dt, varIndex: idx, tooltip: t}, handle)
    } else if(dt === 'select') {

      return <VariablesInner 
        title={q}
        choices={o}
        choicesName={v}
        documentValues={values}
        onValueChange={val => onValuesChange(v, val)}
        tooltip={t}
        variable={v}
        id={v}
        index={idx}
        disabled={lckd}
      />;
    } else {

      const val = value(v, handle)

      return (
        <div className="tooltip-wrapper doc-variable-el" data-id={idx}>
          <Tooltip text={t} nowrap />
          <div className="variables__head">
            <div className="variables__head_text">
              <div className="fields-opened">
                <p>{ `${q}:`} </p>
                <div className="select_input_wrapper multiselect" id={v}>
                  { o?.map((option, optionIndex) => {
                    return (
                      <div>
                        <Checkbox disabled={isHandleLocked(handle)} key={`option_${optionIndex}`} name={option.label} onChange={e => onMultiselectChanged(v, val, option.value, e.target.checked)} checked={val && val.includes(option.value)} />
                        <span style={{ marginLeft: 5 }}>{ option.label }</span>
                      </div>
                    )
                  }) }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const fieldKeyFromHandle = (handle) => {
    return handle.substring(3, handle.length - 1)
  }

  const renderContent = (cntnt = content, vrbls = variables, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    if(!cntnt) {
      return null
    }
    const regex = /\u000b/g
    let source = cntnt.replace(regex, '')
    // let fieldRegex = /\{d\.([^}]*)\}/g
    let variableRegex = /\{d\.([^}]*)\}/g
    let formulaRegex = /\{f\(([^}]*)\}/g
    let fieldRegex = /\{d\.([^}]*)\}|\{f\(([^}]*)\}|\{checkbox\.([^}]*)\}/g
    let checkboxRegex = /\{checkbox\.([^}]*)\}/
    let fields = source.match(fieldRegex)
    if(fields?.length > 0) {
      const selectVariables = vrbls ? [...vrbls].filter(v => v.type === 'select').map(v => (`{d.${v.variable}}`)) : [];
      fields.forEach(f => {
        if(selectVariables.includes(f)) {
          if(source.includes(`${f}.`)) {
            source = source.replace(`${f}.`, f);
          }
        }
      });
    }

    let items = []
    for(let i in fields) {
      if(!source) {
        continue
      }
      let components = source.split(fields[i])
      items.push(components[0])
      items.push(fields[i])
      components.splice(0, 1)
      source = components.join(fields[i])
    }
    if(source) {
      items.push(source)
    }
    return items.map((item, i) => {
      if(item.match(variableRegex)) {
        let field = (fieldKeyFromHandle(item) || '').split(':')[0]
        // input field
        let fieldVariable = variableWithField(field, vrbls)
        if(!fieldVariable) {
          if(isRepeatableBlockHandle(field)) {
            return null
          } else {
            return item
          }
        }
        if(CONTACT_ID_VARIABLES.includes(fieldVariable.variable)) {
          return renderCustomerInput(fieldVariable, CONTACT_TYPE_FOR_ID_VARIABLE[fieldVariable.variable], item, repId, repIndex)
        } else if(fieldVariable.type === 'string' || fieldVariable.type === 'number') {
          return renderTextInput(fieldVariable, item, repId, repIndex)
        } else if(fieldVariable.type === 'select') {
          return renderSelectInput(fieldVariable, item, repId, repIndex)
        } else if(fieldVariable.type === 'date') {
          return renderDateInput(fieldVariable, item, repId, repIndex)
        } else if(fieldVariable.type === 'multiselect') {
          return renderQuestion(fieldVariable, item, repId, repIndex)
        } else if(fieldVariable.type === 'textarea') {
          return renderTextarea(fieldVariable, item, repId, repIndex)
        } else if(fieldVariable.type === 'empty_lines') {
          return renderEmptyLinesInput(fieldVariable, item, repId, repIndex)
        } else if(fieldVariable.type === 'area') {
          return renderAreaInput(fieldVariable, item, repId, repIndex)
        }
      } else if(item.match(formulaRegex)) {
        return renderFormulaInput(item, repId, repIndex)
      } else if(item.match(checkboxRegex)) {
        return renderCheckboxInput(checkboxIdFromHandle(item), repId, repIndex)
      } else {
        return type.includes('heading') ? <span key={i} className="doc-variable-el" data-id={`section-${index}`}>{item}</span> : item;
      }
    })
  }

  const checkboxIdFromHandle = (handle) => {
    return handle.split('.')[1].split(':')[0]
  } 

  const renderText = () => {
    return (
      <div className="variables__text">
        { renderContent() }
      </div>
    )
  }

  const renderBulletList = () => {
    return (
      <ul className="variables__text">
        { bulletListItems.map(renderBulletListItem)}
      </ul>
    )
  }

  const renderBulletListItem = (item, index) => {
    return (
      <li className="bullet-list-item" style={{ marginLeft: `${1*((item.level + 1) || 1)}rem` }}>
        { renderContent(item.content, item.variables) }
      </li>
    )
  }

  const onTableRowCountChange = (newRowCount) => {
    if(newRowCount < 1) {
      return
    }
    let rowCount = 0
    if(!values[repeatableRowId]) {
      rowCount = 1
    } else {
      rowCount = values[repeatableRowId].length
    }
    if(newRowCount === rowCount) {
      return
    }
    let vals = values[repeatableRowId]
    if(!vals) {
      vals = [{}]
    }
    if(vals.length > newRowCount) {
      vals.splice(newRowCount, vals.length - newRowCount)
    } else {
      while(vals.length < newRowCount) {
        vals.push({})
      }
    }
    onValuesChange(repeatableRowId, vals)
  }

  const renderTable = () => {
    let rowCount = 0
    if(!repeatableRowId) {
      rowCount = 1
    } else if(!values[repeatableRowId]) {
      rowCount = 1
    } else {
      rowCount = values[repeatableRowId].length
    }
    let rowsObject = []
    for(let i = 0; i < rowCount; i++) {
      rowsObject.push(i)
    }
    return (
      <div>
        { repeatableRowId && <RowCountInput label="Nombre de lignes" onValueChange={onTableRowCountChange} value={!repeatableRowId ? 1 : !values[repeatableRowId] ? 1 : values[repeatableRowId].length} />}
        <table>
          {(head && head.length > 0) && <thead>
            <tr>
              { head.map((cell, ci) => <td className="variables__text">{ renderContent(cell.content, cell.variables) }</td> )}
            </tr>
          </thead> }
          {(row && row.length > 0) && <tbody>
            { rowsObject.map((r, ri) => <tr key={`table_row_${ri}`}>
              { row.map((cell, ci) => <td className="variables__text">{ renderContent(cell.content, cell.variables, repeatableRowId, ri) }</td>)}
            </tr> )}
          </tbody>}
        </table>
      </div>
    )
  }

  const RowCountInput = ({ label, value, onValueChange }) => {

    const onButtonClick = (diff) => {
      let newValue = value + diff
      if(newValue < 1) {
        newValue = 1
      }
      onValueChange(newValue)
    }

    // const onInputChange = (e) => {
    //   let value = e.target.value
    //   onValueChange(value)
    // }

    return (
      <div className="row-count-input-container">
        <p className="row-count-input-label">
          { label }
        </p>
        <Button primary className="row-count-button" text="-" small onButtonClick={() => onButtonClick(-1)} />
        {/* <Input className="row-count-input" type="number" value={value} onChange={onInputChange} /> */}
        <p className="row-count-input">{value}</p>
        <Button primary className="row-count-button" text="+" small onButtonClick={() => onButtonClick(1)} />
      </div>
    )
  }

  const variableWithField = (handle, vrbls = variables, repId = repeatableSectionId) => {
    let variableName
    if(repId) {
      let components = handle.split('.')
      variableName = components[components.length - 1]
    } else {
      variableName = handle
    }
    for(let i in vrbls) {
      if(vrbls[i].variable === variableName) {
        return vrbls[i]
      }
    }
    return null
  }

  const isRepeatableBlockHandle = (handle, repId = repeatableSectionId) => {
    return ( handle === `${repId}[i]` || handle === `${repId}[i+1]` )
  }

  const onInputChange = (variable, value, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    if(repId) {
      let val = duplicatableValuesArray(repId)
      // to enable updating multiple variables at once
      if(typeof variable === 'object' && Array.isArray(variable)) {
        for(let i in variable) {
          val[repIndex][variable[i]] = value[i]
        }
      } else {
        val[repIndex][variable] = value
      }
      onValuesChange(repId, val)
    } else {
      onValuesChange(variable, value)
    }
  }
  

  const value = (variable, handle, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    let val
    if(repId && repeatableIndex !== -1) {
      if(handle.includes(`${repId}[i]`) || type === 'table') {
        val = duplicatableValuesArray(repId)[repIndex][variable]
      } else { 
        if(values[variable]) {
          val = values[variable] // in case variable is inside repeatable block but not repeatable itself
        }else if(values[repId] && values[repId][repIndex] && values[repId][repIndex][variable]){
          val = values[repId][repIndex][variable]
        } else {
          val = ''
        }
      }
    } else if(!repId) {
      val = values[variable]
    }
    return val
  }

  const renderFormulaInput = (handle, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    // const val = value(variable, handle, repId, repIndex)
    const { value } = formulaResult(values, handle, repId, repIndex)
    let classes = "tooltip-wrapper doc-variable-el"
    
    return (
      <div className={classes} data-id={`${handle}${repId ? `-${repIndex}` : ''}`}>
        <div style={{padding: '2px 0'}}>
          <TextInput value={value} variable={variable} locked />
        </div>
      </div>
    )
  }

  const renderCheckboxInput = (id, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    return (
      <div className="checkbox-inline">
        <Checkbox onChange={e => onCheckboxValueChange(id, e.target.checked) } checked={documentCheckboxValues[id] || false} />
      </div>
    )
  }

  const renderAreaInput = (fieldVariable, handle, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    const { variable, tooltip, type, idx} = fieldVariable
    let val = value(variable, handle, repId, repIndex)
    let classes = "tooltip-wrapper doc-variable-el"
    let linesAdded
    if(repId && repIndex !== undefined && repIndex > -1) {
      linesAdded = linesAddedTo.find(v => v.variable === variable && v.belongs_to === repId && v.index === repIndex)
      if(!linesAdded && linesAddedTo.find(v => v.belongs_to === repId)) {
        if((type === 'string' && val === TEXT_LINES) || (type === 'number' && val === NUMBER_LINES)) {
          val = ''
        }
      }
    } else {
      linesAdded = linesAddedTo.find(v => v.variable === variable)
    }

    return (
      <div className={classes} data-id={`${idx}${repId ? `-${repIndex}` : ''}`}>
        <div style={{padding: '2px 0'}}>
          <TextInput value={val} variable={variable} locked={isHandleLocked(handle)} type={'text'} onBlur={() => onAreaInputBlur(variable, val, repId, repIndex)} onInputChange={(v, val) => onAreaInputChange(v, val, repId, repIndex)} tooltip={tooltip} linesAdded={linesAdded} onRemoveLines={() => onRemoveLines(variable, repId, repIndex)} />
        </div>
      </div>
    )
  }

  const onAreaInputBlur = (v, val, repId, repIndex) => {
    onInputChange(v, printAreaValue(parseAreaValue(val)), repId, repIndex)
  }

  const onAreaInputChange = (v, val, repId, repIndex) => {
    onInputChange(v, val, repId, repIndex)
  }

  const convertValueToNumber = (value) => {
    if(!value) return ''
    return parseFloat(value.replace(/[^0-9-.,]/g, '').replace(/,/g, '.'))
  }

  const renderTextInput = (fieldVariable, handle, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    const { variable, tooltip, type, idx} = fieldVariable
    let val = value(variable, handle, repId, repIndex)
    let classes = "tooltip-wrapper doc-variable-el"
    if(availableOn(['development']) && (variable === 'manufacturers_name' || variable === 'co_na_customers_lastname')) {
      classes += ' doc-variable-el--with-icon'
    }
    let linesAdded
    if(repId && repIndex !== undefined && repIndex > -1) {
      linesAdded = linesAddedTo.find(v => v.variable === variable && v.belongs_to === repId && v.index === repIndex)
      if(!linesAdded && linesAddedTo.find(v => v.belongs_to === repId)) {
        if((type === 'string' && val === TEXT_LINES) || (type === 'number' && val === NUMBER_LINES)) {
          val = ''
        }
      }
    }else {
      linesAdded = linesAddedTo.find(v => v.variable === variable)
    }
    if(type === 'number' && typeof val === 'string') {
      val = convertValueToNumber(val)
    }
    return (
      <div className={classes} data-id={`${idx}${repId ? `-${repIndex}` : ''}`}>
        <div style={{padding: '2px 0'}}>
          <TextInput value={val} variable={variable} locked={isHandleLocked(handle)} type={type} onInputChange={(v, val) => onInputChange(v, val, repId, repIndex)} tooltip={tooltip} linesAdded={linesAdded} onRemoveLines={() => onRemoveLines(variable, repId, repIndex)} />
        </div>
        {availableOn(['development']) && variable === 'manufacturers_name' && (
          <CustomTooltip content="Changer les coordonnées de l'agence">
            <span className="icon" onClick={onAgencyModalOpen}><FaRegAddressCard /></span>
          </CustomTooltip>
        )}
        {availableOn(['development']) && variable === 'co_na_customers_lastname' && (
          <CustomTooltip content="Annuaire des notaires">
            <span className="icon" onClick={onNotariesModalOpen}><Person /></span>
          </CustomTooltip>
        )}
      </div>
    )
  }

  const renderTextarea = (fieldVariable, handle) => {
    const { variable, tooltip, type, idx} = fieldVariable
    let val = value(variable, handle)
    let linesAdded 
    if(repeatableSectionId && repeatableIndex !== undefined && repeatableIndex > -1) {
      linesAdded = linesAddedTo.find(v => v.variable === variable && v.belongs_to === repeatableSectionId && v.index === repeatableIndex)
      if(!linesAdded && linesAddedTo.find(v => v.belongs_to === repeatableSectionId) && val === TEXTAREA_LINES) {
        val = ''
      }
    }else {
      linesAdded = linesAddedTo.find(v => v.variable === variable)
    }
    return (
      <div className="tooltip-wrapper textarea-el doc-variable-el" data-id={idx}>
        <Tooltip text={tooltip} nowrap />
        <div style={{padding: '2px 0', position: 'relative'}}>
          <Textarea id={variable} value={val || ''} type={type} editMode onChange={onTextAreaInputChange(variable)} className={val ? 'not-empty' : ''} disabled={linesAdded || isHandleLocked(handle)} />
          {linesAdded && <div className="textarea-lines" onClick={() => onRemoveLines(variable, repeatableSectionId, repeatableIndex)}></div>} 
        </div>
      </div>
    )
  }

  const onTextAreaInputChange = (variable) => (e) => {
    onInputChange(variable, e.target.value)
  }



  const manualResetClickHandler = () => {
    
  }

  const renderEmptyLinesInput = (fieldVariable, handle, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    const { variable } = fieldVariable
    let val = value(variable, handle, repId, repIndex)
    let innerClasses = 'variables doc-variable-el question-el';

    if (val) {
      innerClasses += ' selected';
    }
    return (
      <div className={innerClasses}>
      <div className={"variables__head select"}>
        <div className={"variables__head_text tooltip-wrapper"} onClick={null}>
          <span className="radio"></span>
          <p>{ "Combien de blocs de 4 lignes voulez-vous ajouter?" }</p>
          <p className="selected">{emptyLinesLabelForValue(val)}</p>
        </div>
        <div className="variables__head_buttons">
          <Button text={1} outlinePink onButtonClick={() => onInputChange(variable, 'empty_lines_4')} />
          <Button text={2} outlinePink onButtonClick={() => onInputChange(variable, 'empty_lines_8')} />
          <Button text={3} outlinePink onButtonClick={() => onInputChange(variable, 'empty_lines_12')} />
          <Button text={4} outlinePink onButtonClick={() => onInputChange(variable, 'empty_lines_16')} />
        </div>
        <div className="variables__head_selected">
          <div className="tooltip-wrapper">
            <Tooltip text="Réinitialiser" nowrap />
            <Button icon={<MoreHorizIcon />} outlinePink onButtonClick={() => onInputChange(variable, '')} />
          </div>
        </div>
      </div>
      { renderEmptyLinesContent(val) }
      </div>
    )
  }

  const emptyLinesLabelForValue = (value) => {
    if(!value) {
      return ''
    }
    let amountComponents = value.split('_')
    let amount = parseInt(amountComponents[amountComponents.length - 1])
    let blocks = amount / 4 
    return blocks
  }

  const renderEmptyLinesContent = (value) => {
    if(!value) {
      return null
    }
    let amountComponents = value.split('_')
    let amount = parseInt(amountComponents[amountComponents.length - 1])
    let blocks = amount / 4
    return (
      <div>
        {[...Array(blocks)].map((elementInArray, bi) => ( 
          <div key={`lines_block_${bi}`} className="manual-lines-block">
            {[...Array(4)].map((e, li) => ( 
              <div key={`line_${bi}_${li}`} className="manual-line"></div>
            ))}
          </div>
        ))}
      </div>
    )
  }

  const renderSelectInput = (fieldVariable, handle, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    return <VariablesInner 
      title={fieldVariable.tooltip}
      choices={fieldVariable.options}
      choicesName={fieldVariable.variable}
      documentValues={values}
      onValueChange={val => onInputChange(fieldVariable.variable, val)}
      tooltip={fieldVariable.tooltip}
      select={true}
      id={fieldVariable.variable}
      index={fieldVariable.idx}
      disabled={isHandleLocked(handle)}
    />;
  }

  const onMultiselectChanged = (variable, prevState, value, checked) => {
    let nextState = prevState
    if(!nextState) {
      nextState = []
    }
    if(checked && !nextState.includes(value)) {
      nextState.push(value)
    } else if(!checked && nextState.includes(value)) {
      nextState.splice(nextState.indexOf(value), 1)
    }
    onInputChange(variable, nextState)
  }

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  }

  const renderDateInput = (fieldVariable, handle, repId = repeatableSectionId, repIndex = repeatableIndex) => {

    const { variable, tooltip, idx} = fieldVariable

    let val = value(variable, handle, repId, repIndex)
    let linesAdded
    if(repId && repIndex !== undefined && repIndex > -1) {
      linesAdded = linesAddedTo.find(v => v.variable === variable && v.belongs_to === repId && v.index === repIndex)
      if(!linesAdded && linesAddedTo.find(v => v.belongs_to === repId) && val === DATE_LINES) {
        val = ''
      }
    }else {
      linesAdded = linesAddedTo.find(v => v.variable === variable)
    }

    const disabled = locked || linesAdded || isHandleLocked(handle)

    let dateValue
    
    dateValue = new Date(moment(val, dateValueFormat).valueOf())
    if(!isValidDate(dateValue)) {
      dateValue = new Date()
    }
    
    return (
      <div className="tooltip-wrapper doc-variable-el" data-id={idx}>
        {tooltip && <Tooltip text={tooltip} nowrap />}
        <div className={`${val ? 'date_picker not-empty' : 'date_picker'}${disabled ? ' disabled' : ''}`}>
          <DatePicker
            // id={variable}
            selected={!val || linesAdded || val === DATE_LINES ? null : dateValue}
            // selected={new Date()}
            onChange={date => date ? onInputChange(variable, moment(date.valueOf()).format(dateValueFormat)) : ''}
            peekNextMonth
            dateFormat={'dd/MM/yyyy'}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="fr"
            disabled={disabled}
            portalId="modal-root"
          />
          {val && <button className="clear-date-btn" onClick={() => onInputChange(variable, '')}><Cancel /></button>}
          {linesAdded && (
            <div className="date-lines" onClick={() => onRemoveLines(variable, repId, repIndex)}>{DATE_LINES}</div>
          )}
        </div>
      </div>
    )
  }

  const customersArrayForType = (type) => {
    return contacts[type] || []
  }

  const dataKeyArrayForType = (type) => {
    return CONTACT_DATA_KEYS[type]
  }

  const customerKeyForType = (type, key = 'firstname') => {
    return `${CONTACT_VARIABLE_PREFIX_FOR_TYPE[type]}${key}`
  }

  const onCustomerChange = (id, type) => {
    let selectedCustomer
    let sourceArray = customersArrayForType(type)
    for(let i in sourceArray) {
      if(sourceArray[i][customerKeyForType(type, 'id')] === id) {
        selectedCustomer = sourceArray[i]
        break
      }
    }
    if(selectedCustomer) {
      let keys = []
      let values = []
      let sourceKeys = dataKeyArrayForType(type)
      for(let i in sourceKeys) {
        // if(selectedCustomer[sourceKeys[i]]) {
          keys.push(sourceKeys[i])
          values.push(selectedCustomer[sourceKeys[i]] || '')
        // } 
      }
      onInputChange(keys, values)
    }
  }

  const renderCustomerInput = (fieldVariable, customerType, handle) => {
    const { variable, tooltip, type, idx} = fieldVariable
    const sourceArray = customersArrayForType(customerType)
    const val = value(variable, handle)
    return (      
      <div className="tooltip-wrapper doc-variable-el" data-id={idx}>
        <FormControl className="doc-variable-el__select">
          <InputLabel id={`customer-select-${variable}-label`}>{ tooltip }</InputLabel>
          <Select
            labelId={`customer-select-${variable}-label`}
            onChange={e => onCustomerChange(e.target.value, customerType)}
            value={val || ''}
            id={`customer-select-${variable}`}
          >
            { sourceArray.map((opt, oi) => {
              return (
                <MenuItem value={opt[customerKeyForType(customerType, 'id')]}>{ `${opt[customerKeyForType(customerType, 'firstname')]} ${opt[customerKeyForType(customerType, 'lastname')]}` }</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  const renderSection = () => {
    if(!areSectionConditionsMet({ condition, conditions }, values)) {
      return null
    }
    switch(type) {
      case 'heading1':
        return renderHeading1()
      case 'heading2':
        return renderHeading2()
      case 'heading3':
        return renderHeading3()
      case 'question':
        return renderQuestion()
      case 'text':
        return renderText()
      case 'bullet_list':
        return renderBulletList()
      case 'table':
        return renderTable()
      default:
        return null
    }
  }

  const duplicatableValuesArray = (repId = repeatableSectionId) => {
    if(values[repId]) {
      return values[repId]
    } else {
      return [{}]
    }
  }

  const removeRepetition = (repId = repeatableSectionId) => () => {
    let vals = values[repId]
    vals.splice(repeatableIndex, 1)
    onValuesChange(repId, vals)
    if(linesAddedTo.find(v => v.belongs_to === repId)) { // remove block variables from linesAddedTo and replace index of others if needed
      let updated = [...linesAddedTo]
      updated = updated.filter(v => !(v.belongs_to === repId && v.index === repeatableIndex))
      updated = updated.map(v => v.belongs_to === repId && v.index > repeatableIndex ? {...v, index: v.index - 1} : v)
      onSetLinesAddedTo(updated)
    }
  }

  return isCoverPageSection() ? null : (
    <div>
      { renderSection() }
      { repeatableSectionId && duplicatableValuesArray().length > 1 ? (
        <span className="u-cursor--pointer" onClick={locked ? () => {} : removeRepetition(repeatableSectionId)}>{ 'Supprimer' }</span>
      ) : null }      
    </div>
  )
}

const TextInput = ({ value, variable, locked, onInputChange, type, tooltip, linesAdded = false, onRemoveLines, onBlur }) => {
  const spanEl = useRef();
  let add = 24;
  let textInputClass = 'doc-variable-el__span';
  if(type === 'number') {
    textInputClass += ' doc-variable-el__span--number';
    add = 40;
  }
  const [style, setStyle] = useState(value 
    ? { width: spanEl.current?.scrollWidth + add || '140px', maxWidth: '100% !important' } 
    : { maxWidth: '100% !important' })
  

    useEffect(() => {
      let add = type === 'number' ? 40 : 24
      setStyle(value 
        ? { width:  spanEl.current?.scrollWidth + add || '140px', maxWidth: '100% !important' } 
        : { maxWidth: '100% !important' }
      )
  }, [value, type, tooltip])
  
  return (
    <Fragment>
      <span ref={spanEl} className={textInputClass}>{value}</span>
      <Input 
        disabled={locked || (linesAdded ? true : false)} 
        id={variable} 
        value={value || ''} 
        type={type} 
        editMode 
        onChange={(e) => onInputChange(variable, e.target.value) } 
        className={value ? 'not-empty' : ''} 
        tooltip={tooltip}
        inputStyle={style} 
        linesAdded={linesAdded}
        onRemoveLines={onRemoveLines}
        onBlur={onBlur}
      /> 
    </Fragment>
  );
}

export default DocumentSectionContent;