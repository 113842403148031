import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'

import SupportPageLayout from '../../layouts/SupportPageLayout'
import { HelpContext } from '../../../context'
import { MainLayout } from '../../../layouts'

const HelpSearchResults = ({v2}) => {
  const { articlesArr } = useContext(HelpContext)
  const [searchResults, setSearchResults] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const location = useLocation()
  const history = useHistory()

  // Get search query from url and set it to searchTerm state variable and get search results using this query
  useEffect(() => {
    const qsVariables = qs.parse(location.search, { ignoreQueryPrefix: true })
    const query = qsVariables.query
    if(query) {
      setSearchTerm(query)
      let arr = []
      // Check if search term is inside article title or content
      articlesArr.forEach(article => {
        let added = false
        if(article.title['en'].toLowerCase().includes(query.toLowerCase())) {
          arr.push(article)
          added = true
        }
        if(!added) {
          const blocks = article.content['en'].blocks
          for(let i = 0; i < blocks.length; i++) {
            if(blocks[i].text && blocks[i].text.toLowerCase().includes(query.toLowerCase())) {
              arr.push(article)
              break
            }
          }
        }
      })
      setSearchResults(arr)
    }
  }, [location, articlesArr])

  // Article click handler
  const articleClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/help-article/${id}`)
  }

  return (
    <MainLayout className="page-help" pageTitle="Nouveautés">
      <SupportPageLayout withSidebar hideCategories showBackButton v2={v2}>
        <div className="help-search-results">
          <div className="help-search-results__head">
            <h2>{`Résultats de la recherche pour`}: "<span>{searchTerm}</span>"</h2>
            <p>{searchResults.length} {searchResults.length === 1 ? 'article' : 'articles'} {`trouvé`}</p>
          </div>
          <div className="help-search-results__body">
            <ul>
              {searchResults.map((article, idx) => {
                return (
                  <li key={idx}>
                    <a href="/#" onClick={(e) => articleClickHandler(e, article.id)}><DescriptionIcon /> {article.title.en}</a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>        
      </SupportPageLayout>
    </MainLayout>
  )
}

export default HelpSearchResults