import { NavLink } from 'react-router-dom'
import { ManageAccounts, PlaylistAddCheck } from '../../assets/icons'

const RegisteredMailSidebar = ({ sidebarSection, onModifySender }) => {

  return (
    <div className="registered-mail-content__sidebar u-custom-scrollbar">
      <div className="registered-mail-content__sidebar_head">
        <h1><span>Lettres &nbsp; &nbsp; recommandées électroniques</span></h1>
      </div>
      <section className="registered-mail-content__nav">
        <ul>
          <li><NavLink to="/registered-mail/tracking"><PlaylistAddCheck />Suivi des LRE</NavLink></li>
          <li><NavLink to="/registered-mail/sender"><ManageAccounts />Gestion compte expéditeur</NavLink></li>
          {/* <li><NavLink to="/registered-mail/requests">Historique des commandes d'identifiant</NavLink></li> */}
          {/* <li><NavLink to="/registered-mail/teams">Gestion des équipes</NavLink></li> */}
        </ul>
      </section>
     
    </div>
  )
}

export default RegisteredMailSidebar