import React from 'react';

import Modal from './Modal';
import Button from './Button';

const Alert = ({ onClose, onOkClose, okBtnText = 'Ok', text, bodyText, onSubmit, loading, deleteAlert = false, showOk = false }) => {
  return(
    <Modal onClose={onClose} small>
      <div className="alert">
        <div className="alert__head">
          <h3>{text}</h3>
        </div>
        {bodyText && <div className="alert__body">
          <p>{bodyText}</p>  
        </div>}
        {!showOk ? <div className="alert__foot">
          <Button text="Annuler" transparent onButtonClick={onClose} />
          <Button text={loading ? "Supression..." : "Confirmer"} primary={!deleteAlert} danger={deleteAlert} onButtonClick={onSubmit} disabled={loading} />
        </div>
        : <div className="alert__foot">
          <Button text={okBtnText} primaryLight onButtonClick={onOkClose ? onOkClose : onClose} />
        </div>}
      </div>
    </Modal>
  );
}

export default Alert;