import React, { useContext, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'

import { FileAlt2, FolderOpen, Home, RegisteredMail, Signature, InfoCircle, Fave, FolderBorder, Download } from '../../assets/icons'
import { FEATURE, isFeatureEnabled, availableOn } from '../../utils'
import { UserContext } from '../../context'
import { Logo } from '../../assets/icons'
import LogoSmall from '../../assets/icons/LogoSmall'
import GiveFeedbackModal from '../sections/dashboard/GiveFeedbackModal'
import { NewReleasesOutlined } from '@material-ui/icons'
import { SettingsOutlined, StarRateOutlined } from '@mui/icons-material'

const Sidebar = ({ full = false }) => {

  let sidebarClass = 'main-sidebar u-custom-scrollbar'

  if (!full) {
    sidebarClass += ' main-sidebar--shrinked'
  }

  const isNavlinkActive = (match) => (...args) => {
    return args[1].pathname.substring(0, match.length) === match
  }

  const { showSignatures, siteConfigs } = useContext(UserContext)
  const [showGiveFeedbackModal, setShowGiveFeedbackModal] = useState(false)

  return (
    <div className={sidebarClass}>
      <div className="logo">
        <Link to="/" >{full ? <Logo /> : <LogoSmall />}</Link>
      </div>
      <ul>
        <li>
          <NavLink to="/" exact={true}>
            <span className="icon icon-documents"><Home /></span>
            <span className="text">Tableau de bord</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/documents" exact={true}>
            <span className="icon icon-documents"><FileAlt2 /></span>
            <span className="text">Mes documents</span>
          </NavLink>
        </li>
        {isFeatureEnabled(FEATURE.STANDARD_TEMPLATES, siteConfigs) && <li>
          <NavLink to="/standard-templates">
            <span className="icon icon-signatures"><Fave /></span>
            <span className="text">Mes modèles types</span>
          </NavLink>
        </li>}
        <li>
          <NavLink to="/templates">
            <span className="icon icon-templates"><FolderBorder /></span>
            <span className="text">Modèles</span>
          </NavLink>
        </li>
        {showSignatures && <li>
          <NavLink to="/signatures">
            <span className="icon icon-signatures"><Signature /></span>
            <span className="text">Signatures</span>
          </NavLink>
        </li>}
        {isFeatureEnabled(FEATURE.AR24, siteConfigs) && <li>
          <NavLink isActive={isNavlinkActive('/registered-mail')} to="/registered-mail/tracking">
            <span className="icon icon-signatures"><RegisteredMail /></span>
            <span className="text">Lettres recommandées</span>
          </NavLink>
        </li>}
        <li><div className='horizontal-line'></div></li>
        <li>
          <NavLink to="/what-is-new">
            <span className="icon icon-signatures"><NewReleasesOutlined /></span>
            <span className="text">Nouveautés</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/documentation">
            <span className="icon icon-signatures"><Download /></span>
            <span className="text">Documents utiles</span>
          </NavLink>
        </li>
        <li>
          <a href='#' onClick={(e) => { e.preventDefault(); setShowGiveFeedbackModal(true) }}>
            <span className="icon icon-signatures"><StarRateOutlined /></span>
            <span className="text">Donnez votre avis</span>
          </a>
        </li>
      </ul>
      {showGiveFeedbackModal && <GiveFeedbackModal onClose={() => setShowGiveFeedbackModal(false)} />}
      <div className="main-sidebar__bottom">
        <ul>
          <li>
            <NavLink isActive={isNavlinkActive('/settings')} to="/settings/profile">
              <span className="icon icon-settings"><SettingsOutlined /></span>
              <span className="text">Paramètres</span>
            </NavLink>
          </li>
        </ul>

      </div>
    </div>
  )
}

export default Sidebar