import { CustomTooltip } from './'

const Textarea = ({ label, name, id, value, onChange, placeholder, error, className, disabled, tooltip }) => {
  let cssClasses = 'textarea-v2'

  if(className) {
    cssClasses += ` ${className}`
  }

  return(
    <div className={cssClasses}>
      {label && <label htmlFor={id ? id : name} className="textarea-v2__label">{label}</label>}
      {tooltip ? (
        <CustomTooltip content={tooltip}>
          <textarea 
            name={name} 
            id={id ? id : name} 
            className="textarea-v2__field"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
          ></textarea>
        </CustomTooltip>
      ) : (
        <textarea 
          name={name} 
          id={id ? id : name} 
          className="textarea-v2__field"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        ></textarea>
      )}
      {error && <div className="textarea-v2__error">{error}</div>}
    </div>
  )
}

export default Textarea