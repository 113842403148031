import React, { useEffect } from 'react';

import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';

const CreateEditSectionModal = ({ onClose, mode = 'create', onCreate, onEdit, currentSection, loading, onSetCurrentSection }) => {
  const initialData = {
    name: {
      required: true,
      value: ''
    }
  };
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate);

  useEffect(() => {
    return () => {
      onSetCurrentSection && onSetCurrentSection(null);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(mode === 'edit') {
      setFormData(prev => ({
        ...prev,
        name: {
          ...prev.name,
          value: currentSection.name
        }
      }));
    }
    // eslint-disable-next-line
  }, [currentSection]);

  const submitHandler = async (e) => {
    e.preventDefault();
    
    const newErrors = validate(formData, true);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      if(mode === 'create') {
        const data = {
          name: formData.name.value.trim(),
          templates: [],
        }
        await onCreate(data);
      }else {
        const data = {
          name: formData.name.value.trim(),
        }
        onEdit(data, currentSection.id);
      }
    }
  }

  return(
    <Modal onClose={onClose} small>
      <div className="folder-modal">
        <h2>{mode === 'create' ? "Créer une catégorie" : "Modifier le catégorie"}</h2>
        <form onSubmit={submitHandler} className="form">
          <Input 
            name="name"
            value={formData.name.value}
            onChange={changeHandler}
            error={errors.name}
            placeholder="Indiquez le nom de la catégorie"
          />
          <div className="form__actions">
            <Button text="Annuler" transparent onButtonClick={onClose} />
            <Button 
              type="submit" 
              text={loading ? mode === 'create' ? "Création..." : "Mise à jour..." : "Sauvegarder"} 
              primary 
              disabled={loading} 
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default CreateEditSectionModal;