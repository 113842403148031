import React, { useState } from 'react'
import { CheckCircle } from '../../assets/icons';
import { NavLink, Link } from 'react-router-dom';
import { Modal } from '../ui_new'
import { Warning } from '@material-ui/icons';

const SignatureMailModalConfirm = ({ onClose, type, title, message, docId }) => {

  return (
    <Modal onClose={onClose} className="modal-v2--medium-2">
      <div className='folder-modal-v2'>
        <div className='folder-modal-v2__heading'>
          <h2>{title}</h2>
          <div className="buttons-wrapper">
            <button className='btn btn--medium btn--transparent' onClick={onClose}>Fermer</button>
            {docId && <a href={`/documents/${docId}`} className="btn" target="_blank" rel="noopener noreferrer">Vérifier le document</a>}
          </div>
        </div>
        {type === 'success' && <div className='folder-modal-v2__content'>
          <div className='sent-successful'>
            <CheckCircle />
            {message}
          </div>
        </div>}
        {type === 'error' && <div className='folder-modal-v2__content'>
          <div className='sent-error'>
            <Warning />
            {message}
          </div>
        </div>}

      </div>

    </Modal>
  )
}

export default SignatureMailModalConfirm