import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';

import Checkbox from '../../UI/Checkbox';
import EditAttachmentModal from './EditAttachmentModal';
import AttachmentRow from '../../UI/AttachmentRow';
import { DocumentsContext } from '../../../context/documents/documentsState';
import { NotificationContext } from '../../../context/notifications/notificationState';
import { availableOn } from '../../../utils';

const DocumentDetailAppendices = ({ documentAttachments, onDeleteAttachment, onEditAttachment, onDeleteAttachmentsByName, docId, attachmentsNames }) => {
  const [allChecked, setAllChecked] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState({});
  const [updating, setUpdating] = useState(false);
  const { documentId } = useParams();
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [sortedAttachments, setSortedAttachments] = useState([]);
  const [order, setOrder] = useState('asc');
  const { setCopiedAttachments, setAttachmentsFrom } = useContext(DocumentsContext);
  const { setNotification } = useContext(NotificationContext);
  // const [documents] = useState([
  //   { id: 1, title: 'Titre de la pièce jointe' },
  //   { id: 2, title: 'Titre de la pièce jointe' },
  // ]);

  useEffect(() => {
    const attachments = [...documentAttachments];
    const sorted = attachments.sort((a, b) => {
      if(a.name > b.name) return 1;
      else if(a.name < b.name) return 1;
      else return 0;
    });
    setSortedAttachments(sorted);
  }, [documentAttachments]);

  useEffect(() => {
    if(selectedAttachments.length === 0) {
      setAllChecked(false);
    }
  }, [selectedAttachments]);

  const allCheckedChangeHandler = (e) => {
    if(e.target.checked && selectedAttachments.length === 0) {
      setAllChecked(true);
      setSelectedAttachments(documentAttachments);
    } 
    
    if(!e.target.checked) {
      setAllChecked(false);
      setSelectedAttachments([]);
    }
  }

  const viewAttachment = (att, e) => {
    e.preventDefault();
    // console.log(att);
    if(att.data) {
      let data = att.data.split(',')[1]
      let mimeType = att.type
      var byteCharacters = atob(data);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: mimeType + ';base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else if(att.url) {
      window.open(att.url, '_blank')
    }
  }

  const editAttachmentClickHandler = (att, attIndex) => {
    setSelectedAttachment(att);
    setSelectedAttachmentIndex(attIndex);
    setShowEditModal(true);
  }

  const editAttachmentHandler = async (name, index) => {
    if(attachmentsNames.includes(`${name}.${selectedAttachment.format}`)) {
      return setNotification({ msg: 'File with this name already exists', type: 'danger' });
    }
    setUpdating(true);
    await onEditAttachment(documentId, name, index);
    setUpdating(false);
    setShowEditModal(false);
  }

  const attachmentChangeHandler = (att, e) => {
    if(e.target.checked && selectedAttachments.filter(obj => obj.name === att.name && obj.format === att.format).length === 0) {
      setSelectedAttachments(prev => [...prev, { ...att }]);
      if(selectedAttachments.length === 0) {
        setAllChecked(true);
      }
    }else if(!e.target.checked && selectedAttachments.filter(obj => obj.name === att.name && obj.format === att.format).length === 1){
      setSelectedAttachments(prev => [...prev].filter(obj => `${obj.name}.${obj.format}` !== `${att.name}.${att.format}`));
      if(selectedAttachments.length === 1) {
        setAllChecked(false);
      }
    }
  }

  const attachmentsDeleteHandler = (e) => {
    e.preventDefault();
    const namesArr = [...selectedAttachments].map(a => `${a.name}.${a.format}`);
    onDeleteAttachmentsByName(namesArr);
    setSelectedAttachments([]);
  }

  // Copy attachments
  const attachmentsCopyHandler = (e) => {
    e.preventDefault();
    setCopiedAttachments(selectedAttachments);
    setAttachmentsFrom(docId);
    setNotification({ msg: `Les annexes ont bien été copiés(${selectedAttachments.length})`, type: 'info' });
  }

  const sortHandler = () => {
    const attachments = [...documentAttachments];
    if(order === 'asc') {
      const sorted = attachments.sort((a, b) => {
        if(a.name > b.name) return 1;
        else if(a.name < b.name) return -1;
        else return 0;
      });
      setSortedAttachments(sorted);
      setOrder('desc');
    }else {
      const sorted = attachments.sort((a, b) => {
        if(a.name < b.name) return 1;
        else if(a.name > b.name) return -1;
        else return 0;
      });
      setSortedAttachments(sorted);
      setOrder('asc');
    }
  }

  return(
    <div className="document-detail-appendices">
      <div className="document-detail-appendices__head">
        <h2>Annexes</h2>
        {selectedAttachments.length === 0 
          ? <div className="dashboard-column col-1">
              <Checkbox checkedAll checked={allChecked} name="allChecked" onChange={allCheckedChangeHandler} />
              <div className="sort" onClick={sortHandler}>Titre <ArrowDropDownIcon /></div>
            </div>
          : <div className="document-checked">
              <div className="document-checked__left">
                <Checkbox checkedAll checked={allChecked} name="allChecked" onChange={allCheckedChangeHandler} />
                <p>{selectedAttachments.length} élément(s) sélectionné(s)</p>
              </div>
              <div className="document-checked__right">
                <a href="/#" onClick={attachmentsCopyHandler}><FileCopyIcon /> <span>Copier</span></a>
                <a href="/#" onClick={attachmentsDeleteHandler}><DeleteIcon /> <span>Supprimer</span></a>
              </div>
            </div>
        }
      </div>
      <div className="document-detail-appendices__body">
        {sortedAttachments.map((att, attIndex) => (
          <AttachmentRow 
            key={`attachment_${attIndex}`} 
            att={att} 
            attIndex={attIndex} 
            viewAttachment={(e) => viewAttachment(att, e)} 
            deleteAttachment={() => onDeleteAttachment(attIndex)} 
            editAttachmentClickHandler={(att, index) => editAttachmentClickHandler(att, index)} 
            showModal={setShowEditModal} 
            selectedAttachments={selectedAttachments}
            onCheckboxChange={attachmentChangeHandler}
          />
        ))}
      </div>
      {showEditModal && <EditAttachmentModal 
        onClose={() => setShowEditModal(false)} 
        loading={updating} 
        attachment={selectedAttachment} 
        attachmentIndex={selectedAttachmentIndex}
        onEdit={editAttachmentHandler}
      />}
    </div>
  );
}

export default DocumentDetailAppendices;