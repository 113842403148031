import React, { useState, useEffect } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ClickAwayListener } from '@material-ui/core'

import Dropdown from './Dropdown';
import Input from './Input';

const CustomSelect = ({ options, onChange, active, statusEl, formEl, showArrow, search, className, disabled }) => {

  const [selected, setSelected] = useState(active.label);
  const [selectedVal, setSelectedVal] = useState(active.value);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options)

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  useEffect(() => {
    setSelected(active.label);
    setSelectedVal(active.value);
  }, [active]);

  const optionChangeHandler = (option) => {
    setSelected(option.label);
    setSelectedVal(option.value);
    setShowDropdown(false);
    onChange(option);
  }

  const searchHandler = (e) => {
    const value = e.target.value;
    if(value.trim() === '') {
      setFilteredOptions(options);
    }else {
      const arr = options.filter(o => o.label.toLowerCase().includes(value.trim().toLowerCase()));
      setFilteredOptions(arr);
    }
    setSearchValue(value);
  }

  let selectClass = 'custom-select';

  if(formEl) {
    selectClass += ' custom-select--form';
  }
  if(className) {
    selectClass += ` ${className}`
  }

  return(
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div className={selectClass}>
        <div className={statusEl ? `custom-select__selected ${selectedVal}` : "custom-select__selected"} onClick={() => !disabled && setShowDropdown(!showDropdown)}>
          {statusEl && <span></span>}<div className="text">{selected}</div>
          {formEl && <div className="icon"><KeyboardArrowDownIcon /></div>}
          {showArrow && <div className="custom-select__selected_arrow"><ArrowDropDownIcon /></div>}
        </div>
        {showDropdown && <div className="select-dropdown">
          {search && <Input value={searchValue} onChange={(e) => searchHandler(e)} />}
          <ul>
            {filteredOptions.map((opt, i) => {
              let liClass = '';

              if(opt.active) {
                liClass = 'active';
              }
              if(statusEl) {
                liClass += ` ${opt.value}`;
              }
              if(opt.disabled) {
                liClass += ` disabled`
              }

              return <li key={i} onClick={() => optionChangeHandler(opt)} className={liClass}>{statusEl && <span></span>} {opt.label}</li>
            }
            )}
          </ul>
        </div>}
      </div>
    </ClickAwayListener>
  );
}

export default CustomSelect;