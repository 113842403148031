import React, { useState, useContext, useRef, useEffect } from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Dropdown from './Dropdown';
import FoldersPopup from '../sections/FoldersPopup';
import CustomPopup from './CustomPopup';

import { FolderContext } from '../../context/folders/folderState';
import { DocumentsFoldersContext } from '../../context/documentsFolders/documentsFoldersState';
import { NotificationContext } from '../../context/notifications/notificationState';
import { LoaderContext } from '../../context/loader/loaderState';
import { getAllParentFolders, folderHasSubfolders, folderHasTemplates } from '../../utils';
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper';


const FolderListItem = ({ folder, onCreateFolder, onEdit, onDelete, onFilter, selectedFilter, onOpenFolderModal, onMoveToFolder, folders, foldersLoading, templates, templatesLoading, onMoveTemplateToFolder, view = 'templates', onMoveDocumentToFolder }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [makeDraggable, setMakeDraggable] = useState(false);
  const { updateFolder, setFolderToMove } = useContext(FolderContext);
  const { updateDocFolder, setDocFolderToMove } = useContext(DocumentsFoldersContext);
  const [showPopup, setShowPopup] = useState(false);
  const [arrowActive, setArrowActive] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const hasSubFolders = folderHasSubfolders(folders, folder);
  const subfolders = [...folders].filter(f => f.parentFolder === folder.id);
  const popupEl = useRef();
  const [popupElPosition, setPopupElPosition] = useState(null);

  useEffect(() => {
    if(folder.id === selectedFilter && !arrowActive && hasSubFolders) {
      setArrowActive(true);
    }
    // eslint-disable-next-line
  }, [selectedFilter]);

  useEffect(() => {
    if(!hasSubFolders && arrowActive) {
      setArrowActive(false);
    }
    // eslint-disable-next-line
  }, [hasSubFolders]);

  // New folder
  const newFolderClickHandler = (e, folder) => {
    onCreateFolder(e, folder);
    setShowDropdown(false);
  }

  // Edit folder
  const editClickHandler = () => {
    onEdit(folder); 
    setShowDropdown(false);
  }

  // Deplacer text click - show folders popup
  const moveClickHandler = () => {
    setShowPopup(true);
    setShowDropdown(false);
    if(view === 'templates') {
      setFolderToMove(folder);
    }else {
      setDocFolderToMove(folder);
    }
  }

  // Delete
  const deleteClickHandler = () => {
    if(folderHasSubfolders(folders, folder)) {
      return setNotification({ msg: `Le dossier "${folder.name}" ne peut pas être supprimé car il n'est pas vide ! Il comporte des sous-dossiers.`, type: 'warning' });
    }
    if(folderHasTemplates(templates, folder)) {
      return setNotification({ msg: `Le dossier "${folder.name}" ne peut pas être supprimé car il n'est pas vide ! Il contient des modèles.`, type: 'warning' });
    }
    onDelete(folder, true); 
    setShowDropdown(false);
  }

  // Drag start
  const dragStartHandler = (e, folder) => {
    setShowPopup(false);
    const div = document.createElement('div');
    div.id = 'draggable-folder-el';
    div.className = 'draggable-ghost-el';
    div.innerText = `Déplacer le ${folder.name}`;
    document.getElementById('root').appendChild(div);
    e.dataTransfer.setData('folder', JSON.stringify(folder));
    e.dataTransfer.setDragImage(div, 0, 0);
  }
  
  // Drag over
  const dragOverHandler = (e) => {
    e.preventDefault();
    const folderBox = e.target.closest('.item-inner');
    if(!folderBox.classList.contains('hover')) {
      folderBox.classList.add('hover');
    }
    if(hasSubFolders && !arrowActive) {
      setArrowActive(true);
    }
  }

  // Drag leave
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    const folderBox = e.target.closest('.item-inner');
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }
  }
  
  // Drop 
  const dropHandler = async (e, folder) => {
    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null;

    const folderBox = e.target.closest('.item-inner');
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }
    
    if(data) {
      const parentFolders = getAllParentFolders(folders, folder);
      const folderBox = e.target.closest('.item-inner');
      if(folderBox.classList.contains('hover')) {
        folderBox.classList.remove('hover');
      }

      if(folder.id === data.id) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' });
      if(folder.parentFolder === data.id) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' });
      if(data.parentFolder === folder.id) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' });
      if(parentFolders.find(f => f.id === data.id)) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' });
  
      setLoading(true);
      // console.log(`Move ${data.name} to ${folder.name}`);
      if(view === 'templates') {
        await updateFolder({ parentFolder: folder.id }, data.id);
      }else {
        await updateDocFolder({ parentFolder: folder.id }, data.id);
      }
      setLoading(false);
      setNotification({ msg: `Dossier "${data.name}" déplacé dans le dossier "${folder.name}"`, type: 'default' }); 
      return;
    }

    const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null;

    if(templateData) {
      return onMoveTemplateToFolder(templateData, folder);
    }

    const documentData = e.dataTransfer.getData('document') ? JSON.parse(e.dataTransfer.getData('document')) : null;

    if(documentData) {
      onMoveDocumentToFolder(documentData, folder);
    }
  }

  // Drag end 
  const dragEndHandler = (e) => {
    document.getElementById('draggable-folder-el').remove();
  }

  // 3 dots mouse enter
  const handleMouseEnter = (e) => {
    setMakeDraggable(true);
  }

  // 3 dots mouse leave
  const handleMouseLeave = (e) => {
    setMakeDraggable(false);
  }

  // Arrow click - show subfolders menu
  const folderArrowClickHandler = () => {
    setArrowActive(!arrowActive);
  }

  const renderFoldersPopup = () => {
    return(
      <CustomPopup elPagePosition={popupElPosition} fixed>
        <div className="dropdown-wrapper">
          <Dropdown show={showPopup} onChange={setShowPopup} animate className="folders-dropdown" dontHideCheck>
            <FoldersPopup 
              ref={popupEl} 
              onCancel={() => setShowPopup(false)} 
              onOpenFolderModal={onOpenFolderModal} 
              onMoveToFolder={onMoveToFolder}
              folders={folders}
              foldersLoading={foldersLoading}
              loading={templatesLoading}
              data={templates}
              show={showPopup}
              onShow={setShowPopup}
              fromSidebar
              onMoveResourceToFolder={onMoveTemplateToFolder}
            />
          </Dropdown>
        </div>
      </CustomPopup>
    );
  }

  return(
    <li 
      className={selectedFilter === folder.id ? 'filters__list_item active' : 'filters__list_item'} 
    >
      <div 
        className={hasSubFolders ? "item-inner" : "item-inner item-inner--no-subfolders"}
        draggable={makeDraggable} 
        onDragStart={(e) => dragStartHandler(e, folder)}
        onDragOver={dragOverHandler}
        onDragLeave={dragLeaveHandler}
        onDrop={(e) => dropHandler(e, folder)}
        onDragEnd={dragEndHandler}
      >
        {hasSubFolders && <span className={arrowActive ? "icon-arrow active" : "icon-arrow"} onClick={folderArrowClickHandler}>
          <ArrowRightIcon className="right-icon" />
          <ArrowDropDownIcon className="drop-down-icon" />
        </span>}
        <p onClick={() => onFilter(folder)}><span className="icon-left"><FolderIcon /></span> {folder.name}</p>
        <AdminFeatureWrapper rule="any_admin">
          <span 
            className="icon-right"
            onClick={(e) => {
              setPopupElPosition(e.currentTarget.getBoundingClientRect())
              setShowDropdown(!showDropdown)
            }} 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <MoreHorizIcon />
          </span>
        </AdminFeatureWrapper>
        <AdminFeatureWrapper rule="any_admin">
          <Dropdown show={showDropdown} onChange={setShowDropdown} className="dropdown" animate>
            <p onClick={(e) => newFolderClickHandler(e, folder)} className="create"><span><CreateNewFolderIcon /></span> Nouveau dossier</p>
            <p onClick={editClickHandler}><span><EditIcon /></span> Renommer</p>
            <p onClick={moveClickHandler} className="move"><span><SubdirectoryArrowLeftIcon /></span> Déplacer</p>
            <p onClick={deleteClickHandler} className="delete"><span><DeleteForeverIcon /></span> Supprimer</p>
          </Dropdown>
        </AdminFeatureWrapper>
      </div>
      {renderFoldersPopup()}
      {subfolders.length > 0 && arrowActive && <ul className="subfolder">
        {subfolders.map((f, i) => (
          <FolderListItem
            key={i} 
            folder={f}
            onCreateFolder={onCreateFolder}
            onEdit={onEdit}
            onDelete={onDelete}
            onFilter={onFilter}
            selectedFilter={selectedFilter}
            onOpenFolderModal={onOpenFolderModal}
            onMoveToFolder={onMoveToFolder}
            folders={folders}
            foldersLoading={foldersLoading}
            templates={templates}
            templatesLoading={templatesLoading}
            onMoveTemplateToFolder={onMoveTemplateToFolder}
            view={view}
          />
        ))}
      </ul>}
      {arrowActive && subfolders.length === 0 && <p className="no-folders">Pas de dossiers</p>}
    </li>
  );
}

export default FolderListItem;