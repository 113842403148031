import { useState, useEffect } from 'react'

import { Check, Times } from '../../assets/icons'
import { Modal, Input, Select, Option } from '../ui_new'

const AddEditNotaryModal = ({ onClose, mode = 'add', notary }) => {
  const [role, setRole] = useState('maitre')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [street, setStreet] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')

  // Prefill fields if notary set
  useEffect(() => {
    if(notary) {
      setRole(notary.role || '')
      setName(notary.name || '')
      setPhone(notary.phone || '')
      setEmail(notary.email || '')
      setStreet(notary.street || '')
      setZipCode(notary.postal_code || '')
      setCity(notary.city || '')
    }
  }, [notary])

  // On role change
  const handleRoleChange = (value) => {
    setRole(value)
  }

  // On form submit
  const handleSubmit = (e) => {
    e.preventDefault()
    let data = { role, name, phone, email, street, zipCode, city } // keys may change, it depends if we will save notaries in our db or through immo api
    if(mode === 'add') {
      // create new notary
      console.log('create new notary', data)
    }else {
      // edit notary
      console.log('edit notary', data)
    }
  }

  return (
    <Modal onClose={onClose}>
      <div className="notaries-modal-v2">
        <div className="notaries-modal-v2__head notaries-modal-v2__head--2">
          <h2>{mode === 'add' ? 'Ajouter un notaire' : 'Modifier un notaire'}</h2>
        </div>
        <div className="notaries-modal-v2__body">
          <form className="notary-form" onSubmit={handleSubmit}>
            <div className="notary-form__group">
              <Select onChange={handleRoleChange} selected="maitre">
                <Option value="maitre">Maître</Option>
              </Select>
            </div>
            <div className="notary-form__group">
              <Input value={name} onChange={(e) => setName(e.target.value)} label="Nom" />
              <Input value={phone} onChange={(e) => setPhone(e.target.value)} label="Téléphone" />
              <Input value={email} onChange={(e) => setEmail(e.target.value)} label="E-mail" />
            </div>
            <div className="notary-form__group">
              <Input value={street} onChange={(e) => setStreet(e.target.value)} label="Rue" />
              <Input value={zipCode} onChange={(e) => setZipCode(e.target.value)} label="Code postal" />
              <Input value={city} onChange={(e) => setCity(e.target.value)} label="Ville" />
            </div>
            <div className="notary-form__actions">
              {mode === 'add' && <button className="btn btn--with-icon" type="submit"><Check /> Ajouter le notaire</button>}
              {mode === 'edit' && <button className="btn btn--with-icon" type="submit"><Check /> Metter à jour le notaire</button>}
              <button className="btn btn--with-icon btn--transparent" onClick={onClose}><Times /> Annuler</button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default AddEditNotaryModal