import React from 'react';

const DocumentDetailSkeleton = ({ opened }) => {
  return (
    <div className={opened ? "document-detail__body opened" : "document-detail__body"}>
      <div className="document-detail__body_inner">
        <div className="wrap">
          <div className="document-detail-variables">
            <div className="skeleton skeleton--title"></div>
            <div className="skeleton skeleton--text"></div>
            <div className="skeleton skeleton--text"></div>
            <div className="skeleton skeleton--text"></div>
            <div className="skeleton skeleton--text"></div>
            <div className="skeleton skeleton--text"></div>
            <div className="skeleton skeleton--text"></div>
            <div className="skeleton skeleton--text"></div>
            <div className="skeleton skeleton--text"></div>
          </div>
        </div>
      </div>
      <div className="document-detail__body_side-panel side-panel">
        <div className="side-panel__menu">
          <div className="trigger">
            <div className="skeleton skeleton--icon"></div>
          </div>

          <ul>
            <li><div className="skeleton skeleton--icon"></div></li>
            <li><div className="skeleton skeleton--icon"></div></li>
            <li><div className="skeleton skeleton--icon"></div></li>
            <li><div className="skeleton skeleton--icon"></div></li>
          </ul>
        </div>
        <div className="side-panel__main">
          <div className="document-detail-sp-variables">
            <div className="document-detail-sp-variables__head">
              <div className="skeleton skeleton--small"></div>
              <div className="skeleton skeleton--small"></div>
            </div>
            <div className="document-detail-sp-variables__search">
              <div className="skeleton skeleton--form"></div>
            </div>
            <div className="document-detail-sp-variables__body">
              <ul>
                <li><div className="skeleton skeleton--xs"></div><div className="skeleton skeleton--xs"></div></li>
                <li><div className="skeleton skeleton--xs"></div><div className="skeleton skeleton--xs"></div></li>
                <li><div className="skeleton skeleton--xs"></div><div className="skeleton skeleton--xs"></div></li>
                <li><div className="skeleton skeleton--xs"></div><div className="skeleton skeleton--xs"></div></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentDetailSkeleton;