import { memo } from 'react'
import moment from 'moment'

import { dateValueFormat } from '../../utils'
import {  EMPTY_LINES } from '../../constants'
import { DateInput } from '../ui_new'

const VariableDateInput = ({fieldVariable, handle, val, repeatableIds, repetitionIndices, linesAdded, isHandleLocked, onInputChange}) => {
  const { variable, tooltip, idx} = fieldVariable


  const disabled = isHandleLocked(handle)
  
  return (
    <div className="tooltip-wrapper doc-variable-el" data-id={idx}>
      <div className={`${val ? 'date_picker-v2 not-empty' : 'date_picker-v2'}${disabled ? ' disabled' : ''}`}>
        <DateInput 
          selected={!val ? null : new Date(moment(val, dateValueFormat).valueOf())}
          onChange={date => date ? onInputChange(variable, moment(date.valueOf()).format(dateValueFormat), repeatableIds, repetitionIndices) : ''}
          peekNextMonth={true}
          showMonthDropdown={true}
          showYearDropdown={true}
          dropdownMode="select"
          disabled={disabled}
          tooltip={tooltip}
          isClearable={val}
          onClearableClick={() => onInputChange(variable, '', repeatableIds, repetitionIndices)}
          placeholder={linesAdded ? EMPTY_LINES.date : ''}
        />
      </div>
    </div>
  )
}

export default memo(VariableDateInput)