import React, { useState, forwardRef, useEffect, useContext } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FolderIcon from '@material-ui/icons/Folder';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteIcon from '@material-ui/icons/Delete'

import Alert from '../UI/Alert';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import CreateEditSectionModal from './CreateEditSectionModal';
import { DocumentsContext } from '../../context/documents/documentsState';
import { SectionContext } from '../../context/sections/sectionsState';
import { NotificationContext } from '../../context/notifications/notificationState';
import { availableOn } from '../../utils'

const SectionsPopup = forwardRef(({ onCancel, resource }, ref) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [sectionTemplates, setSectionTemplates] = useState([]);
  const { getTemplateById } = useContext(DocumentsContext);
  const { sections, updateSection, createSection, deleteSection } = useContext(SectionContext);
  const { setNotification } = useContext(NotificationContext);
  const [adding, setAdding] = useState(false);
  const [showCreateSectionModal, setShowCreateSectionModal] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [removeTemplateData, setRemoveTemplateData] = useState({})
  const [showDeleteTemplateAlert, setShowDeleteTemplateAlert] = useState(false)
  const [showDeleteSectionAlert, setShowDeleteSectionAlert] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const openCreateSectionModalHandler = () => {
    // onCancel();
    setShowCreateSectionModal(true);
  }

  // On section click
  const sectionClickHandler = (section) => {
    setSelectedSection(section);
    const templates = [...section.templates];
    const activeTemplates = [];
    templates.forEach(t => {
      if(getTemplateById(t)) {
        activeTemplates.push(t);
      }
    });
    setSectionTemplates(activeTemplates);
  }

  // Add template to section 
  const addToSectionHandler = (template, section) => {
    const ids = [...section.templates];
    if(!ids.includes(template.id)) {
      setAdding(true);
      ids.push(template.id);
      updateSection(section.id, { templates: ids }, () => {
        setNotification({ msg: `Modèle ajouté à la catégorie "${section.name}"`, type: 'success' });
        setAdding(false);
        const templates = [...section.templates];
        const activeTemplates = [];
        templates.forEach(t => {
          if(getTemplateById(t)) {
            activeTemplates.push(t);
          }
        });
        activeTemplates.push(template.id);
        setSectionTemplates(activeTemplates);
        setSelectedSection({ ...section, templates: activeTemplates });
      }, (err) => {
        console.log(err);
        setAdding(false);
        setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
      });
    }else {
      setNotification({ msg: `Le modèle est déjà dans la catégorie "${section.name}"`, type: 'warning' });
    }
  }

  // Create section handler
  const createSectionHandler = async (data) => {
    setSectionLoading(true);
    await createSection(data, () => {
      setShowCreateSectionModal(false);
      setNotification({ msg: `Catégorie "${data.name}" créé avec succès!`, type: 'success' });
      setSectionLoading(false);
    }, (err) => {
      console.log(err)
      setSectionLoading(false);
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    });
  }

  // Remove template from section
  const removeTemplateClickHandler = (template, section) => {
    setShowDeleteTemplateAlert(true);
    setRemoveTemplateData({ template, section });
  }

  const removeTemplateHandler = () => {
    const { section, template } = removeTemplateData;
    let ids = [...section.templates];
    ids = ids.filter(t => t !== template);
    
    setDeleting(true);
    updateSection(section.id, { templates: ids }, (section) => {
      setNotification({ msg: `Modèle supprimé de la section sélectionnée !`, type: 'success'});
      setDeleting(false);
      setShowDeleteTemplateAlert(false);
      setSectionTemplates(section.templates);
      setSelectedSection(null)
    }, (err) => {
      console.log(err);
      setDeleting(false);
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    });
  }

  // Delete section
  const deleteSectionHandler = () => {
    setDeleting(true);
    deleteSection(selectedSection.id, () => {
      setNotification({ msg: `Section supprimée avec succès !`, type: 'success' });
      setDeleting(false);
      setSelectedSection(null);
      setShowDeleteSectionAlert(false);
    }, (err) => {
      setDeleting(false);
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    });
  }

  return (
    <div className="folders-popup" ref={ref}>
      <div className={!selectedSection ? "folders-popup__head root" : "folders-popup__head inner"}>
        <div className="main">
          <span className="icon">
            <HomeIcon />
          </span>
          <h5>Catégories</h5>
        </div>
        <div className="back">
          <span className="icon" onClick={() => setSelectedSection(null)}>
            <ArrowBackIcon  />  
          </span>
          {selectedSection && <h5>{selectedSection.name}</h5>}
          <span className="delete-category" onClick={() => setShowDeleteSectionAlert(true)}>
            <DeleteIcon />
          </span>
        </div>
      </div>
      <div className="folders-popup__body">
        <ul className={!selectedSection ? "folders-popup__body_list root" : "folders-popup__body_list inner"}>
          {!selectedSection && sections.length > 0 && sections.map((section, i) => (
              <li key={i} className="root folder-list-item dont-hide-dropdown">
                <div className="root dont-hide-dropdown" onClick={(e) => sectionClickHandler(section)}>
                  <FolderIcon className="dont-hide-dropdown" />
                  <p className="dont-hide-dropdown">{section.name}</p>
                  <span><KeyboardArrowRightIcon className="dont-hide-dropdown" /></span>
                </div>
              </li>
            ))
          }
          {!selectedSection && sections.length === 0 && <p className="no-data">Pas de catégories</p>}
          {selectedSection && sectionTemplates.length > 0 
            && sectionTemplates.filter(t => Object.keys(getTemplateById(t)).length > 0).map(template => (
            <li key={template} className="inner">
              <div className="root u-cursor--default">
                <DescriptionOutlinedIcon />
                <p>{getTemplateById(template).name}</p>
                <span className="delete-template" onClick={() => removeTemplateClickHandler(template, selectedSection)}><DeleteIcon /></span>
              </div>
            </li>
          ))}
          {selectedSection && sectionTemplates.length === 0 && <p className="no-data">Pas de modèles dans cette catégorie</p>}
        </ul>
      </div>
      <div className="folders-popup__foot">
        <div className="new" onClick={openCreateSectionModalHandler}>
          <CreateNewFolderIcon />
        </div>
        <Button text="Annuler" onButtonClick={onCancel} outlinePrimary className="cancel-btn" />
        <Button 
          text={!adding ? "Ajouter ici" : <Loader mini normalWhite />} 
          onButtonClick={() => addToSectionHandler(resource, selectedSection)} 
          disabled={!selectedSection}
          primary 
        />
      </div>

      {showCreateSectionModal && <CreateEditSectionModal 
        onClose={() => setShowCreateSectionModal(false)} 
        onCreate={createSectionHandler}
        loading={sectionLoading}
        currentSection={null}
        mode="create"
      />}
      {showDeleteTemplateAlert && <Alert 
        onClose={() => setShowDeleteTemplateAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer le modèle de cette catégorie ?"
        onSubmit={removeTemplateHandler}
        loading={deleting}
        deleteAlert
      />}
      {showDeleteSectionAlert && <Alert 
        onClose={() => setShowDeleteSectionAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer cette catégorie ?"
        onSubmit={deleteSectionHandler}
        loading={deleting}
        deleteAlert
      />}
    </div>
  );
});

export default SectionsPopup;