import React, { useState } from 'react'

import { Header, Footer, Sidebar } from '../components/shared'

const MainLayout = ({ className, children, pageTitle, hideTitleOnDesktop, fullSidebar = false, fullHeader = false, titleIcon = null, showSearch = false, searchIn = '' }) => {

  let mainClass = 'main-layout'

  if (className) {
    mainClass += ` ${className}`
  }
  if (!fullSidebar) {
    mainClass += ' main-layout--sidebar-shrinked'
  }



  return (
    <div className={mainClass}>
      {/* <div className="main-layout__fix"></div> */}
      <main className="main-layout__main">
        <Sidebar full={fullSidebar} />
        <div className="main-layout__body">
          <Header showSearch={showSearch} searchIn={searchIn} full={fullSidebar} fullHeader={fullHeader} titleIcon={titleIcon} pageTitle={pageTitle} hideTitleOnDesktop={hideTitleOnDesktop} />

          <div className="main-layout__body_content u-custom-scrollbar">
            {children}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default MainLayout