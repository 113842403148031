import React, { useContext, useEffect, Fragment } from 'react' 
import { useHistory } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import SearchForm from '../sections/support/Search'
import DashboardHeadMeta from '../sections/DashboardHeadMeta'
import { HelpContext, UserContext } from '../../context'
import { availableOn } from '../../utils'

const SupportPageLayout = ({ title = `Centre d'aide`, children, withSidebar, hideCategories, showBackButton, v2 }) => {
  const { categoriesArr, selectedCategory, setSelectedCategory, articlesFetched, fetchHelpArticles, categoriesFetched, fetchHelpCategories } = useContext(HelpContext)
  const { user } = useContext(UserContext)
  const history = useHistory()
  let body = null 

  // Fetch articles if they are not fetched yet
  useEffect(() => {
    if(Object.keys(user).length && !articlesFetched) {
      fetchHelpArticles()
    }
  }, [articlesFetched, fetchHelpArticles, user])

  // Fetch categories if they are not fetched yet
  useEffect(() => {
    if(Object.keys(user).length && !categoriesFetched) {
      fetchHelpCategories()
    }
  }, [categoriesFetched, fetchHelpCategories, user])

  // Category click handler
  const categoryClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/help-category/${id}`)
    setSelectedCategory(id)
  }

  // Back button click handler
  const backButtonClickHandler = e => {
    e.preventDefault()
    history.push('/help-center')
  }

  // Body 
  if(withSidebar) {
    body = (
      <div className="support-page__body support-page__body--flex">
        <aside>
          <SearchForm type="small" />
          {!hideCategories && 
            <Fragment>
              { availableOn(['development', 'staging']) && <h4 onClick={() => history.push('/help')}>{`Catégories`}</h4> }
              <ul>
                {categoriesArr.map((category, idx) => (
                  <li key={idx} className={selectedCategory === category.id ? 'active' : ''}>
                    <a href="/#" onClick={(e) => categoryClickHandler(e, category.id)}>{category.title?.en} <ChevronRightIcon /></a>
                  </li>
                ))}
              </ul>
            </Fragment>
          }
          {showBackButton && <a href="/#" onClick={backButtonClickHandler} className="back"><ChevronLeftIcon /> {`Retour au centre d'aide`}</a>}
        </aside>
        <main>
          { children }
        </main>
      </div>
    )
  }else {
    body = <div className="support-page__body">{children}</div>
  }

  return (
    <div className="dashboard-page">
      { !v2 && <div className="dashboard-page__head">
        <h1>Support</h1>
        <DashboardHeadMeta 
          hideSearch 
          showSupportMenu
        />
      </div> }
      <div className="dashboard-page__body">
        <div className="support-page">
          <div className="support-page__container">
            {/* <div className="support-page__head">
              <h1>{title}</h1>
            </div> */}
            {body}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportPageLayout