import React, { createContext, useContext, useReducer, useState } from 'react';
import {
  fetch_ar24_sender,
  update_ar24_sender,
  create_ar24_sender,
  send_ar24_mail,
  fetch_ar24_mail,
  request_ar24_sender_verification,
  verify_otp_key,
  associate_ar24_sender,
  resend_ar24_sender_confirmation,
  delete_ar24_sender,
  // resend_ar24_sender_invitation,
  renew_ar24_sender_verification,
  upload_ar24_attachment,
  fetch_ar24_single_mail,
  fetch_ar24_sender_events,
  add_document_event
} from '../../services/firestore'

import {
    SET_AR24_MAIL_MODAL,
    SET_SELECTED_REGISTERED_MAIL_FILTER,
    SET_AR24_SENDER,
    SET_AR24_MAIL,
    SET_AR24_SENDER_MODAL,
    SET_AR24_SENDER_EVENTS
} from "../types";
import registeredMailReducer from "./registeredMailReducer";
import SenderModal from '../../components/misc/RegisteredMail/SenderModal';
import { UserContext } from '../user/userState';
import MailModal from '../../components/misc/RegisteredMail/MailModal';
import { fileNameWithoutExtension, manufacturerProperty } from '../../utils';
import { getSignatureFiles, getSignaturePreviewWithUrl } from '../../services/functions';
import moment from 'moment';
import { useSingleDocumentActions } from '../../hooks';

export const RegisteredMailContext = createContext();

const RegisteredMailState = ({children}) => {
  const initialState = {
    ar24MailFilter: {},
    ar24Sender: null,
    ar24SenderFetched: false,
    ar24SenderEvents: [],
    ar24SenderEventsFetched: false,
    ar24Mail: [],
    ar24MailFetched: false,
    senderModal: {
      isOpen: false,
    },
    mailModal: {
      isOpen: false,
    }
  }
  const [state, dispatch] = useReducer(registeredMailReducer, initialState);

  const [senderLoadingMessage, setSenderLoadingMessage] = useState(null)
  const [senderResponseStatus, setSenderResponseStatus] = useState(null)
  const [senderErrorMessage, setSenderErrorMessage] = useState(null)

  const [mailLoadingMessage, setMailLoadingMessage] = useState(null)
  const [mailResponseStatus, setMailResponseStatus] = useState(null)
  const [mailErrorMessage, setMailErrorMessage] = useState(null)

  const { user, immocloudConfig, partner } = useContext(UserContext)
  const { getPreviewData } = useSingleDocumentActions()

  const requestAuthor = {
    admin_id: user.id,
    admin_name: `${user.firstname} ${user.lastname}`,
    agency_id: manufacturerProperty(user?.manufacturer, 'id'),
    agency_name: manufacturerProperty(user?.manufacturer, 'name'),
    site_id: immocloudConfig.site_id,
    partner: partner
  }

  const showAR24MailModal = (data) => {
    dispatch({
      type: SET_AR24_MAIL_MODAL,
      payload: {
        isOpen: true,
        ...data        
      }
    })
  }

  const closeMailModal = () => {
    setMailResponseStatus(null)
    dispatch({
      type: SET_AR24_MAIL_MODAL,
      payload: {
        isOpen: false
      }
    })
  }

  const setAr24MailFilter = (value) => {
    dispatch({
      type: SET_SELECTED_REGISTERED_MAIL_FILTER,
      payload: value
    })
  }

  const setAr24Sender = (value) => {
    dispatch({
      type: SET_AR24_SENDER,
      payload: value
    });
  }

  const setAr24SenderEvents = (value) => {
    dispatch({
      type: SET_AR24_SENDER_EVENTS,
      payload: value
    });
  }

  const addAr24SenderEvents = (sender, ...events) => {
    const updatedEvents = [...state.ar24SenderEvents, ...events].filter(event => event.sender_id === sender.id)
    updatedEvents.sort((a, b) => new Date(b.meta.created) - new Date(a.meta.created))
    setAr24SenderEvents(updatedEvents)
  }

  const fetchSender = async (token) => {
    try {
      const data = await fetch_ar24_sender(token)
      if(data.error || !data.success) {
        console.log(data)
        return
      }
      if(data.sender) {
        const events = []
        if(data.sender.creation_type === 'associate') {
          events.push({
            type: 'user-associate',
            meta: {
              created: data.sender.meta.created
            },
            created_by: data.sender.created_by
          })
        }
        fetchSenderEvents(data.sender.id, token, events)
      }
      setAr24Sender(data.sender || null)
    } catch (error) {
      console.log("fetch sender error", error)
      setAr24Sender(null)
    }
  }

  const fetchSenderEvents = async (senderId, token = '', currentEvents = []) => {
    try {
      const data = await fetch_ar24_sender_events(senderId, token)
      if(data.error || !data.success) {
        console.log(data)
        return
      }
      const events = [...currentEvents, ...(data.events || [])]
      events.sort((a, b) => new Date(b.meta.created) - new Date(a.meta.created))
      setAr24SenderEvents(events)
    } catch (error) {
      console.log("fetch sender events error", error)
      setAr24SenderEvents([])
    }
  }    

  const updateSender = async (userId, data) => {
    setSenderLoadingMessage("Chargement")
    data.createdBy = requestAuthor
    const response = await update_ar24_sender(userId, data)
    console.log(response)
    setSenderLoadingMessage(null)
    if(response.error || !response.success) {
      console.log(response)
      return { error: response.error || response}
    }
    const { info, events } = response
    if(events && events.length > 0) {
      addAr24SenderEvents(info, ...events)
    }
    setAr24Sender(info)
  }

  const deleteSender = async (user) => {
    const response = await delete_ar24_sender(user.id)
    if(response.error || !response.success) {
      console.log(response)
      return { error: response.error || response}
    }
    setAr24Sender(null)
  }

  const requestSenderVerification = async (userData) => {
    setSenderLoadingMessage("Chargement")
    const body = {
      createdBy: requestAuthor
    }
    const response = await request_ar24_sender_verification(userData.id, body)
    console.log(response)
    setSenderLoadingMessage(null)
    if(response.error || !response.success) {
      console.log( { error: response.error || response })
      // alert(response.error?.message || response.error || response)
      setSenderErrorMessage(response.error?.message || "Une erreur s'est produite")
      return { error: response.error || response }
    }

    const { events } = response

    
    const sender = {
      ...state.ar24Sender,
      ...response.data,
      id: state.ar24Sender.id
    }
    if(events && events.length > 0) {
      addAr24SenderEvents(sender, ...events)
    }
    setAr24Sender(sender)
    return response
  }

  const verifyOTPKey = async (key) => {
    setSenderLoadingMessage("Chargement")
    const response = await verify_otp_key(state.ar24Sender.id, key)
    setSenderLoadingMessage(null)
    console.log(response)
    if(response.error || !response.success) {
      return { error: response.error || response }
    }
    const sender = {
      ...state.ar24Sender,
      ...response.data
    }
    setAr24Sender(sender)
    
    return response.data
  }

  const fetchAr24Mail = async () => {
    const response = await fetch_ar24_mail()
    setAr24Mail([...state.ar24Mail, ...response.registered_mail])
  }

  const refreshAr24MailStatus = async (mailId) => {
    try {
      const response = await fetch_ar24_single_mail(mailId)
      if(response.error || !response.success) {
        return { error: response.error || response }
      }
      const mail = response.data
      let mailIndex = state.ar24Mail.findIndex(m => m.id === mailId)
      if(mailIndex !== -1) {
        let updatedMail = state.ar24Mail
        updatedMail[mailIndex] = mail
        setAr24Mail([...updatedMail])
      }
      return response
    } catch (error) {
      console.log(error)
      return { error }
    }
  }

  const sendAr24Mail = async ({ recipients, documents, message, documentName, documentId, source, documentValues, documentContentChanges }) => {
    
    setMailLoadingMessage("Chargement")

    const attachmentPromises = []
    for (let attachment of documents) {
      attachmentPromises.push(upload_ar24_attachment({
        base64: attachment.data || attachment.base64,
        url: attachment.url,
        name: attachment.name,
        extension: attachment.extension,
        senderId: state.ar24Sender.id
      }))
    }

    const attachmentResponses = await Promise.all(attachmentPromises)

    const body = {
      recipients: recipients.map(recipient => ({
        firstname: recipient.firstName,
        lastname: recipient.lastName,
        email: recipient.email,
        recipientStatus: recipient.type,
      })),
      attachments: attachmentResponses.filter(res => res.success).map(res => res.data.file_id),
      message: message,
      documentName: documentName,
      sender: { id: state.ar24Sender.id },
      isEidas: true,
      createdBy: requestAuthor
    }

    const response = await send_ar24_mail(body)
    setMailLoadingMessage(null)
    if(response.error || !response.success) {
      console.log(response)
      setMailErrorMessage(response.error?.message || response.error || response)
      return { error: response.error || response }
    }
    const event = {
      type: 'lre_sent',
      source: source,
    }
    if(documentValues) {
      event.values = documentValues
    }
    if(documentContentChanges) {
      event.content_changes = documentContentChanges
    }
    await add_document_event(documentId, event)
    const mailResults = response.results
    setAr24Mail([...state.ar24Mail, ...mailResults])
    setMailResponseStatus('success')
    
    return response
  }

  const setAr24Mail = (value) => {
    dispatch({
      type: SET_AR24_MAIL,
      payload: value
    })
  }

  const showSenderModal = (section) => {
    setSenderModal({
      isOpen: true,
      section
    })
  }

  const closeSenderModal = () => {
    handleClearSenderStatus()
    setSenderModal({
      isOpen: false
    })
  }

  const setSenderModal = (value) => {
    dispatch({
      type: SET_AR24_SENDER_MODAL,
      payload: value
    })
  }

  const createSender = async (body) => {
    body.createdBy = requestAuthor
    setSenderLoadingMessage("Chargement")
    const response = await create_ar24_sender(body)
    if(response.error || !response.success) {
      console.log('Create sender error', response)
      let errorMessage
      if(response.error?.code === 'ar24/user_already_exists') {
        errorMessage = "Un compte AR24 existe déjà avec cette adresse email. Veuillez utiliser une autre adresse e-mail ou mettre à jour votre compte AR24 existant avec cette adresse e-mail."
      } else {
        errorMessage = response.error?.message || response.error || response
      }
      setSenderErrorMessage(errorMessage)
      // alert(errorMessage)
      setSenderLoadingMessage(null)
      return { error: response.error || response}
    }
    const { data, events } = response
    if(events && events.length > 0) {
      addAr24SenderEvents(data, ...events)
    }
    setAr24Sender(data)
    setSenderLoadingMessage(null)
    setSenderModal({
      ...state.senderModal,
      section: 'create-success'
    })
    return
  }

  const associateSender = async (data) => {
    data.createdBy = requestAuthor
    setSenderLoadingMessage("Chargement")
    const response = await associate_ar24_sender(data)
    setSenderLoadingMessage(null)
    if(response.error || !response.success) {
      console.log("associate sender error", response)
      let errorMessage
      if(response.error?.code === 'ar24/user_not_found') {
        errorMessage = "Aucun compte AR24 n'a été trouvé avec cette adresse e-mail. Le compte n'existe peut-être pas ou ne dispose pas d'un accès autorisé à l'API."
      } else {
        errorMessage = response.error?.message || response.error || response
      }
      setSenderErrorMessage(errorMessage)
      return { error: response.error || response}
    }
    const event = {
      type: 'user-associate',
      meta: {
        created: moment().valueOf()
      },
      created_by: requestAuthor,
      sender_id: response.data.id
    }
    const sender = response.data

    addAr24SenderEvents(sender, event)

    setAr24Sender(sender)
    setSenderModal({
      ...state.senderModal,
      section: 'associate-success'
    })
    return
  }

  const handleClearSenderStatus = () => {
    setSenderResponseStatus(null)
    setSenderLoadingMessage(null)
  }

  const handleClearSenderError = () => {
    setSenderErrorMessage(null)
  }

  const handleClearMailError = () => {
    setMailErrorMessage(null)
  }

  const handleClearMailStatus = () => {
    setMailResponseStatus(null)
    setMailLoadingMessage(null)
  }

  // const handleResendBasedOnStatus = async () => {
  //   if(state.ar24Sender.status === 'invitation-pending') {
  //     return await resendSenderInvitation(state.ar24Sender)
  //   } else if(state.ar24Sender.status === 'confirmation-pending') {
  //     return await resendSenderConfirmation(state.ar24Sender)
  //   }
  // }

  const resendSenderConfirmation = async (sender) => {
    setSenderLoadingMessage("Chargement")
    const response = await resend_ar24_sender_confirmation(sender.id)
    console.log(response)
    setSenderLoadingMessage(null)
    if(response.error || !response.success) {
      console.log(response)
      alert(response.error?.message || response.error || response)
      return { error: response.error || response }
    }
    setSenderModal({
      ...state.senderModal,
      isOpen: true,
      section: 'resend-success'
    })
    return response
  }

  // const resendSenderInvitation = async (sender) => {
  //   setSenderLoadingMessage("Chargement")
  //   const response = await resend_ar24_sender_invitation(sender.id)
  //   console.log(response)
  //   setSenderLoadingMessage(null)
  //   if(response.error || !response.success) {
  //     console.log(response)
  //     alert(response.error?.message || response.error || response)
  //     return { error: response.error || response }
  //   }
  //   setSenderModal({
  //     ...state.senderModal,
  //     isOpen: true,
  //     section: 'resend-success'
  //   })
  //   return response
  // }

  const renewIdentityVerification = async () => {
    setSenderLoadingMessage("Chargement")
    const body = {
      createdBy: requestAuthor
    }

    const response = await renew_ar24_sender_verification(state.ar24Sender.id, body)
    
    console.log(response)

    setSenderLoadingMessage(null)
    if(response.error || !response.success) {
      console.log(response)
      return { error: response.error || response }
    }
    const { data, events } = response
    const sender = {
      ...state.ar24Sender,
      ...data,
      id: state.ar24Sender.id
    }
    setAr24Sender(sender)
    setSenderModal({
      ...state.senderModal,
      isOpen: true,
      section: 'identity-verification-success'
    })
    if(events && events.length > 0) {
      addAr24SenderEvents(sender, ...events)
    }
    return response
  }

  const createAr24MailRequestFromDocument = async ({document, documentValues, documentCheckboxValues, documentName, documentContacts}) => {
    showAR24MailModal({ documents: [], recipients: [], documentName: documentName, documentId: document.id, source: {
      id: document.id,
      type: 'document'
    }, documentValues: documentValues || {}, documentContentChanges: document.content_changes || []})
    setMailLoadingMessage("Chargement")
    const previewData = await getPreviewData({...document, values: documentValues, checkboxValues: documentCheckboxValues})
    const attachment = {
      data: previewData?.split(',')[1],
      size: Buffer.from(previewData, 'base64').byteLength,
      name: documentName,
      extension: 'pdf',
      type: 'application/pdf'
    }
    showAR24MailModal({
      documents: [attachment],
      documentName,
      documentId: document.id,
      recipients: documentContacts.length > 0 ? documentContacts.map(contact => ({
        firstName: contact.firstname,
        lastName: contact.lastname,
        email: contact.email_address,
        type: 'particulier',
        consent: false
      })) : [{
        firstName: '',
        lastName: '',
        email: '',
        type: 'particulier',
        consent: false
      }],
      source: {
        id: document.id,
        type: 'document'
      },
      documentValues: documentValues || {},
      documentContentChanges: document.content_changes || []
    })
    setMailLoadingMessage(null)
  }

  const createAr24MailRequestFromSignature = async (signature) => {

    showAR24MailModal({ documents: [], recipients: [], documentName: signature.title, documentId: signature.doc_id, source: {
      id: signature.id,
      type: 'signature'
    } })
    setMailLoadingMessage("Chargement")

    const signatureDocuments = await getSignatureFiles(signature)

    const documentPromises = [
      ...signatureDocuments.documents.map(async (document, index) => {
        const documentResponse = await getSignaturePreviewWithUrl({ folder_id: signature.folder_id, document_url: document._links.self.href, document_name: document.fileName })
        let documentData = {
          name: `${fileNameWithoutExtension(document.fileName)}.pdf`,
          extension: document.contentType.split('/')[1],
          size: document.contentLength,   
        }
        if(documentResponse.document) {
          documentData.data = documentResponse.document
        } else if(documentResponse.document_url) {
          documentData.url = documentResponse.document_url
        }
        return documentData
      })
    ]

    let documents = await Promise.all(documentPromises)
    documents = documents.filter(doc => !!doc)

    const recipients = signature.recipients.map(recipient => ({
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      email: recipient.email,
      type: 'particulier',
      consent: false
    }))

    setMailLoadingMessage(null)
    showAR24MailModal({ documents, recipients, documentName: signature.title, documentId: signature.doc_id, source: {
      id: signature.id,
      type: 'signature'
    } })
  }

  return <RegisteredMailContext.Provider value={{
      ar24MailFilter: state.ar24MailFilter,
      registeredMailSendModalDefaultAttachments: state.registeredMailSendModalDefaultAttachments,
      ar24Sender: state.ar24Sender,
      ar24SenderFetched: state.ar24SenderFetched,
      ar24Mail: state.ar24Mail,
      ar24MailFetched: state.ar24MailFetched,
      ar24SenderEvents: state.ar24SenderEvents,
      setAr24MailFilter,
      fetchAr24Sender: fetchSender,
      requestSenderVerification,
      verifyOTPKey,
      fetchAr24Mail,
      sendAr24Mail,
      showSenderModal,
      resendSenderConfirmation,
      // resendSenderInvitation,
      deleteSender,
      // handleResendBasedOnStatus,
      renewIdentityVerification,
      createAr24MailRequestFromSignature,
      createAr24MailRequestFromDocument,
      refreshAr24MailStatus,
  }}>
    {children}
    {state.senderModal.isOpen && (
      <SenderModal 
        onClose={closeSenderModal}
        onCreateSender={createSender}
        onAssociateSender={associateSender}
        loadingMessage={senderLoadingMessage}
        responseStatus={senderResponseStatus}
        errorMessage={senderErrorMessage}
        onClearStatus={handleClearSenderStatus}
        section={state.senderModal.section}
        onUpdateSender={updateSender}
        onRequestVerification={requestSenderVerification}
        onClearError={handleClearSenderError}
      />
    )}
    {state.mailModal.isOpen && (
      <MailModal
        onClose={closeMailModal}
        data={state.mailModal}
        loadingMessage={mailLoadingMessage}
        errorMessage={mailErrorMessage}
        onClearError={handleClearMailError}
        responseStatus={mailResponseStatus}
        onClearStatus={handleClearMailStatus}
        documentName={'state'}
      />
    )}
  </RegisteredMailContext.Provider>
}

export default RegisteredMailState