import { GET_SECTIONS, SET_SECTIONS, SET_TAG_MATCHINGS } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_SECTIONS:
      return {
        ...state,
        sections: action.payload,
        sectionsFetched: true
      }
    case SET_SECTIONS:
      return {
        ...state,
        sections: action.payload
      }
    case SET_TAG_MATCHINGS:
      return {
        ...state,
        tagMatchings: action.payload,
        tagMatchingsFetched: true
      }
    default:
      return state;
  }
}

export default reducer;