import React, { Fragment, useContext, useRef } from 'react';
import EventIcon from '@material-ui/icons/Event';
import EventNoteIcon from '@material-ui/icons/EventNote';

import { ESIGN_STATUS_FILTER_OPTIONS, SIGNATURE_STATUS_TRANLSATIONS, VIALINK_SIGNATURE_STATUS_FILTER_OPTIONS } from '../../../constants';
import { isMlsPartner } from '../../../utils';
import { UserContext } from '../../../context';

const SignaturesSidebar = ({ onFilterByStatus, statusLoading, signatures }) => {
  const { partner } = useContext(UserContext)
  const statusListEl = useRef();

  // Filter by status
  const filterClickHandler = (status, e) => {
    const liEl = e.target.closest('li');
    const hasActiveClass = liEl.classList.contains('active');
    onFilterByStatus(status, hasActiveClass);
    removeActiveClassFromStatus();
    if(hasActiveClass){
      e.target.closest('li').classList.remove('active');
    }else {
      e.target.closest('li').classList.add('active');
    }
  }

  // Remove active class
  const removeActiveClassFromStatus = () => {
    statusListEl.current.querySelectorAll('li').forEach(li => li.classList.contains('active') && li.classList.remove('active'));
  }

  return(
    <Fragment>
      <h3>Signatures</h3>
      <div style={{height: '17px'}}></div>
      {/* <section className="filters">
        <h6>filtres</h6>
        <ul>
          <li><span><EventIcon /></span> Créer après</li>
          <li><span><EventNoteIcon /></span> Créer avant</li>
        </ul>
      </section> */}
      <section className="statuses">
        <h6>statuts</h6>
        <ul ref={statusListEl}>
          {(isMlsPartner(partner) ? ESIGN_STATUS_FILTER_OPTIONS : VIALINK_SIGNATURE_STATUS_FILTER_OPTIONS).map((status, index) => (
            <li key={index} className={status} onClick={(e) => filterClickHandler(status, e)}><span></span> {SIGNATURE_STATUS_TRANLSATIONS[status]}</li>
          ))}
        </ul>
      </section>
    </Fragment>
  );
}

export default SignaturesSidebar;