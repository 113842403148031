import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import RestoreIcon from '@material-ui/icons/Restore';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import DownloadDataIcon from '@material-ui/icons/ListAlt'
import { ClickAwayListener } from '@material-ui/core'

import Checkbox from './Checkbox';
import IconButton from './IconButton';

import { LoaderContext } from '../../context/loader/loaderState'
import { DocumentsContext } from '../../context/documents/documentsState'
import moment from 'moment'
import { STATUS_LABELS } from '../../constants';
import { availableOn } from '../../utils'

const DocumentRow = ({ selectedDocuments, doc, onCheckboxChange, onDetailView, onSingleDocumentDuplicate, onSingleDocumentDelete, onSingleDocumentArchive, onSingleDocumentDownload, onSingleDocumentRestore, draggable, onSingleDocumentInject = () => {}, onSingleDocumentDownloadData }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { setLoading } = useContext(LoaderContext)
  const { fetchDocuments, currentFilter } = useContext(DocumentsContext)

  const printDate = (date) => {
    return moment(date).format('DD MMM YYYY')
  }

  const printTime = (date) => {
    return moment(date).format('HH:mm')
  }


  // Drag start
  const dragStartHandler = (e) => {
    const div = document.createElement('div');
    div.id = 'draggable-document-el';
    div.className = 'draggable-ghost-el';
    div.innerText = `Déplacer le ${doc.name}`;
    document.getElementById('root').appendChild(div);
    e.dataTransfer.setData('document', JSON.stringify(doc));
    e.dataTransfer.setDragImage(div, 0, 0);
  }

  // Drag end
  const dragEndHandler = e => {
    document.getElementById('draggable-document-el').remove();
  }

  return(
    <div 
      className={[...selectedDocuments].filter(obj => obj.id === doc.id).length > 0 ? "dashboard-row document-row active" : "dashboard-row document-row"}
      draggable={draggable}
      onDragStart={dragStartHandler}
      onDragEnd={dragEndHandler}
    >
      <div className="dashboard-column col-1">
        <Checkbox 
          onChange={onCheckboxChange} 
          checked={[...selectedDocuments].filter(obj => obj.id === doc.id).length > 0} 
        />
        <span className="icon"><DescriptionOutlinedIcon /></span>
        <div className="dashboard-column__main" onClick={onDetailView}>
          <p className="title">{doc.name}</p>
          {/* <p className="progress">Progression {(doc.progress * 100).toFixed(0)}%</p> */}
          <div className="progress-el"><div className="progress-el__inner" style={{width: (doc.progress * 100).toFixed(0) + 'px'}}></div></div>
        </div>
      </div>
      <div className="dashboard-column col-2" data-label="Statut:">
        <span className={`status-el status-el--${doc.status}`}>{ STATUS_LABELS[doc.status] }</span>
      </div>
      <div className="dashboard-column col-3 col-font-small date-col doc-date-col" data-label="Créé le:">{ printDate(doc.meta?.created) } <span>{printTime(doc.meta?.created)}</span> </div>
      <div className="dashboard-column col-4 col-font-small date-col doc-date-col" data-label="Modifié le:">{ printDate(doc.meta?.updated) } <span>{printTime(doc.meta?.updated)}</span> </div>
      <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
        <div className="more">
          <IconButton icon={<MoreVertIcon />} onButtonClick={() => setShowDropdown(!showDropdown)} />
          {showDropdown && <ul className="dropdown">
            {currentFilter !== 'deleted' && <li><a href="/#" onClick={(e) => onSingleDocumentDuplicate(e, doc)}><FileCopyIcon /> <span>Dupliquer</span></a></li>}
            {currentFilter === 'all' && <li><a href="/#" onClick={(e) => onSingleDocumentArchive(e, doc)}><ArchiveIcon /> <span>Archiver</span></a></li>}
            {currentFilter === 'all' && <li><a href="/#" onClick={(e) => onSingleDocumentDownload(e, doc)} className="rotate-180"><PublishIcon /> <span>Télécharger</span></a> </li>}
            {currentFilter !== 'deleted' && <li><a href="/#" onClick={(e) => onSingleDocumentDelete(e, doc)}><DeleteIcon /> <span>Supprimer</span></a> </li>}
            {currentFilter === 'deleted' && <li><a href="/#" onClick={(e) => onSingleDocumentRestore(e, doc)}><RestoreIcon /> <span>Restaurer</span></a> </li>}
            {currentFilter === 'all' && (
              <li><a href="/#" onClick={(e) => onSingleDocumentInject(e, doc)}><CachedOutlinedIcon /> <span>Injecter dans un modèle</span></a></li>
            )}
            <li><a href="/#" onClick={(e) => onSingleDocumentDownloadData(e, doc)}><DownloadDataIcon /> <span>Télécharger les données</span></a></li>
          </ul>}
        </div>
      </ClickAwayListener>
    </div>
  );
}

DocumentRow.propTypes = {
  selectedDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  doc: PropTypes.object.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onDetailView: PropTypes.func
}

export default DocumentRow;