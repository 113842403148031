import { useState, useRef, useEffect, useContext, useCallback } from 'react'
import { Plus, FolderZip, PlusCircleOutline } from '../../assets/icons'
import { FolderListItem, CreateEditFolderModal } from './'
import { useTemplatesActions, useFoldersActions } from '../../hooks'
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper'
import { Drag } from '../../assets/icons';
import { FolderContext, UserContext } from '../../context';
import { areFolderActionsAuthorized, folderItemsForMobileSidebar, isFeatureAuthorized } from '../../utils'
import { Add } from '@mui/icons-material'
import { Option, Select } from '../ui_new'
import CaretDownOutline from '../../assets/icons/CaretDownOutline'

const TemplatesContentSidebar = ({ onSetSelectedFilters, selectedFilters, rootFolders }) => {
  const { moveTemplateToFolder } = useTemplatesActions()
  const { moveToRootFolder } = useFoldersActions()
  const [activeFolder, setActiveFolder] = useState(selectedFilters.folder);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const { setCurrentlyActiveFolder, foldersOrderMap, updateFoldersOrder, currentlyActiveFolder, folders } = useContext(FolderContext)
  const { userClaims } = useContext(UserContext)

  // useEffect(() => {
  //   if (currentlyActiveFolder !== 'root' && activeFolder !== currentlyActiveFolder?.id) {
  //     setActiveFolder(selectedFilters?.folder || currentlyActiveFolder?.id)
  //   } else if (currentlyActiveFolder === 'root' && activeFolder !== 'all') {
  //     setActiveFolder('all')
  //   }
  // }, [currentlyActiveFolder, activeFolder, selectedFilters])

  useEffect(() => {
    if(currentlyActiveFolder !== 'root' && activeFolder !== currentlyActiveFolder?.id) {
      setActiveFolder(currentlyActiveFolder?.id || selectedFilters.folder)
    } else if(currentlyActiveFolder === 'root' && activeFolder !== 'all') {
      setActiveFolder('all')
    }
  }, [currentlyActiveFolder, activeFolder, selectedFilters])
  
  // On folders filter
  const handleFilterFolders = (id) => {
    setCurrentlyActiveFolder({ id: id });
    setActiveFolder(id)
    onSetSelectedFilters(prev => ({ ...prev, folder: id }))
  }

  // On all folders filter
  const handleFilterAllFolders = () => {
    setActiveFolder('all');
    setCurrentlyActiveFolder('root');
    onSetSelectedFilters(prev => ({ ...prev, folder: 'all' }))
  }

  // Drag over - all
  const handleDragOver = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.folder-list-item__main')
    if (!folderBox.classList.contains('active')) {
      folderBox.classList.add('active')
    }
  }

  // Drag leave - all
  const handleDragLeave = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.folder-list-item__main')
    if (folderBox.classList.contains('active')) {
      folderBox.classList.remove('active')
    }
  }

  // On drop
  const handleDrop = async (e) => {
    const folderBox = e.target.closest('.folder-list-item__main')
    if (folderBox.classList.contains('active')) {
      folderBox.classList.remove('active')
    }

    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null

    if (data) {
      moveToRootFolder(data, 'templates')
      return
    }

    const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null

    if (templateData) {
      moveTemplateToFolder({ folder: null, template: templateData })
    }
  }

  // On create folder icon click
  const handleOpenCreateFolderModal = () => {
    setShowCreateFolderModal(true)
  }

  // On close create folder modal
  const handleCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false)
  }

  // drag and drop to reorder folders
  const [orderedFolders, setOrderedFolders] = useState([]);
  const [draggedOverItem, setDraggedOverItem] = useState('');
  const [parent, setParent] = useState('');

  useEffect(() => {
    const rootOrder = foldersOrderMap?.templates?.root || [];
    let orderedFoldersCopy = rootOrder.map(id => rootFolders.find(folder => folder.id === id));
    // filter out undefined folders & add folders that are not in the order
    rootFolders.forEach(folder => {
      if (!orderedFoldersCopy.includes(folder)) {
        orderedFoldersCopy.push(folder);
      }
    });
    orderedFoldersCopy = orderedFoldersCopy.filter(folder => folder);
    setOrderedFolders(orderedFoldersCopy);
  }, [rootFolders, foldersOrderMap]);

  const dragFolder = useRef(0);
  const draggedOverFolder = useRef(0);

  function reorder() {
    const orderedFoldersClone = [...orderedFolders];
    const movedFolder = orderedFoldersClone.splice(dragFolder.current, 1)[0];
    orderedFoldersClone.splice(draggedOverFolder.current, 0, movedFolder);
    setOrderedFolders(orderedFoldersClone);

    const newFullOrder = { ...(foldersOrderMap?.templates || {}) }
    newFullOrder['root'] = orderedFoldersClone.map(folder => folder.id)
    updateFoldersOrder('templates', newFullOrder)

    setDraggedOverItem();
    setParent();
  }

  const foldersDropdownItems = useCallback(() => {
    const items = folderItemsForMobileSidebar(folders, rootFolders, activeFolder)
    const optionsMap = items.map((folder, idx) => {
      return (
        <Option key={`folders_dropdown_item_${idx}`} value={folder.id} preventHide={folder.hasChildren} label={folder.label}>
          <div className='flex flex-row justify-between'>{folder.label}{folder.hasChildren && <span className={`svg-icon ${!folder.isOpen ? 'rotate-270' : ''}`}><CaretDownOutline /></span>}</div>
        </Option>
      )
    })

    if (areFolderActionsAuthorized('templates', userClaims)) {
      optionsMap.push(
        <Option key={`folders_dropdown_item_create`} value={''} defaultAction={handleOpenCreateFolderModal} preventHide={false} label={'Créer un dossier'}>
          <div className='flex flex-row justify-between gap-2'><span className='svg-icon'><PlusCircleOutline /></span>Créer un dossier</div>
        </Option>
      )
    }

    return optionsMap
  }, [rootFolders, activeFolder, folders, userClaims])


  return (
    <div className="templates-content__sidebar u-custom-scrollbar">
      <div className="templates-content__sidebar_head">
        <h1><span>Modèles</span></h1>
      </div>
      <section className="sidebar-folders sm-d-none">
        <h3>Parcourir par catégorie</h3>
        <ul data-parent="root">
          <li>
            <div className="folder-list-item">
              <div
                className={`folder-list-item__main root ${activeFolder === 'all' ? 'active' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <p onClick={handleFilterAllFolders}><span className="text">  <FolderZip />Dossier racine</span></p>
                {areFolderActionsAuthorized('templates', userClaims) && <span className="cursor-pointer" onClick={handleOpenCreateFolderModal}><Add /></span>}
              </div>
              <div className="folder-list-item__body"></div>
            </div>
          </li>
          {orderedFolders?.map((folder, idx) => {
            return (
              <li key={`folder_root_${idx}`}
                className={`pl-2 ${idx === draggedOverItem ? 'dragged-over-item ' : ' '}
                 ${(parent === orderedFolders[draggedOverFolder.current]?.parentFolder && dragFolder.current > draggedOverFolder.current) ? 'top ' : ' '} 
                 ${(parent === orderedFolders[draggedOverFolder.current]?.parentFolder && dragFolder.current < draggedOverFolder.current) ? 'bottom ' : ' '}`}>
                <div className='flex'>
                  <FolderListItem
                    key={idx}
                    folder={folder}
                    onFilter={handleFilterFolders}
                    activeFolder={activeFolder}
                    view="templates"
                    level="first"
                    reorderDraggable={areFolderActionsAuthorized('templates', userClaims)}
                    reorderDragStart={() => { dragFolder.current = idx; setParent(folder.parentFolder) }}
                    reorderDragEnter={() => (draggedOverFolder.current = idx)}
                    reorderDragEnd={reorder}
                    reorderDragOver={(e) => { e.preventDefault(); setDraggedOverItem(idx) }}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      </section>
      <section className="sidebar-folders sm-d-block">
        <Select className="!h-8" onChange={(folderId) => handleFilterFolders(folderId)} selected={activeFolder} type="filled">
          {foldersDropdownItems()}
        </Select>
      </section>

      {showCreateFolderModal && (
        <CreateEditFolderModal
          onClose={handleCloseCreateFolderModal}
          view="templates"
        />
      )}
    </div>
  )
}

export default TemplatesContentSidebar