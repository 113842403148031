import { useContext } from 'react'

import { NotificationContext, FolderContext, DocumentsFoldersContext, DocumentsContext, LoaderContext } from '../context'
import { getAllParentFolders, folderHasSubfolders, folderHasTemplates } from '../utils'

const useFoldersActions = () => {
  const { setNotification } = useContext(NotificationContext)
  const {
    updateFolder,
    folders,
    createFolder: createTmpltFolder,
    deleteFolder: deleteTmpltFolder,
    standardTemplatesFolders,
    createStandardTemplatesFolder,
    updateStandardTemplatesFolder,
    deleteStandardTemplatesFolder
  } = useContext(FolderContext) // templates folders
  const { updateDocFolder, createDocFolder, docFolders, deleteDocFolder } = useContext(DocumentsFoldersContext) // documents folders
  const { documents, templates, standardTemplates } = useContext(DocumentsContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)

  // Move
  const moveFolder = async ({ data, folder, folders, view = 'documents' }) => {
    const parentFolders = getAllParentFolders(folders, folder)

    if(folder.id === data.id) 
      return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' })
    if(folder.parentFolder === data.id) 
      return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' })
    if(data.parentFolder === folder.id) 
      return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' })
    if(parentFolders.find(f => f.id === data.id)) 
      return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' })

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Déplacement du dossier')
    try {
      if(view === 'documents') {
        await updateDocFolder({ parentFolder: folder.id }, data.id)
      }else if(view === 'templates') {
        await updateFolder({ parentFolder: folder.id }, data.id)
      } else if(view === 'standard-templates') {
        await updateStandardTemplatesFolder({ parentFolder: folder.id }, data.id)
      } else {
        return setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
      }
      setNotification({ msg: `Dossier "${data.name}" déplacé dans le dossier "${folder.name}"`, type: 'default' })
    } catch (err) {
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Move to root folder
  const moveToRootFolder = async (data, view) => {
    if(!data.parentFolder) 
      return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier racine`, type: 'warning' })

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Déplacement du dossier')
    try {
      if(view === 'documents') {
        await updateDocFolder({ parentFolder: null }, data.id)
      } else if(view === 'templates') {
        await updateFolder({ parentFolder: null }, data.id)
      } else if(view === 'standard-templates') {
        await updateStandardTemplatesFolder({ parentFolder: null }, data.id)
      }
      setNotification({ msg: `Le dossier "${data.name}" a été déplacé vers le dossier racine`, type: 'default' })
    } catch (err) {
      
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Create folder
  const createFolder = async (data, view) => {
    if(data.name === '') {
      return setNotification({ msg: 'Le nom est obligatoire', type: 'danger' })
    }

    try {
      if(view === 'documents') {
        await createDocFolder(data)
      }else if(view === 'templates') {
        await createTmpltFolder(data)
      } else if(view === 'standard-templates') {
        await createStandardTemplatesFolder(data)
      }
      setNotification({ msg: `Dossier "${data.name}" créé avec succès!`, type: 'success' })
    } catch (err) {
      console.log(err)
    }
  }

  // Edit folder
  const editFolder = async (data, id, view) => {
    if(data.name === '') {
      return setNotification({ msg: 'Le nom est obligatoire', type: 'danger' })
    }

    try {
      if(view === 'documents') {
        await updateDocFolder(data, id)
      }else if(view === 'templates') {
        await updateFolder(data, id)
      } else if(view === 'standard-templates') {
        await updateStandardTemplatesFolder(data, id)
      }
      setNotification({ msg: `Dossier mis à jour avec succès!`, type: 'success' })
    } catch (err) {
      console.log(err)
    }
  }

  // Check if folder can be deleted
  const checkIfFolderCanBeDeleted = (folder, view) => {
    if(view === 'documents') {
      if(folderHasSubfolders(docFolders, folder)) {
        setNotification({ msg: `Le dossier "${folder.name}" ne peut pas être supprimé car il n'est pas vide ! Il comporte des sous-dossiers.`, type: 'warning' })
        return false
      }
      if(folderHasTemplates(documents, folder)) {
        setNotification({ msg: `Le dossier "${folder.name}" ne peut pas être supprimé car il n'est pas vide ! Il contient des documents.`, type: 'warning' })
        return false
      }
    }else if (view === 'templates') {
      if(folderHasSubfolders(folders, folder)) {
        setNotification({ msg: `Le dossier "${folder.name}" ne peut pas être supprimé car il n'est pas vide ! Il comporte des sous-dossiers.`, type: 'warning' })
        return false
      }
      if(folderHasTemplates(templates, folder)) {
        setNotification({ msg: `Le dossier "${folder.name}" ne peut pas être supprimé car il n'est pas vide ! Il contient des modèles.`, type: 'warning' })
        return false
      }
    } else if (view === 'standard-templates') {
      if(folderHasSubfolders(standardTemplatesFolders, folder)) {
        setNotification({ msg: `Le dossier "${folder.name}" ne peut pas être supprimé car il n'est pas vide ! Il comporte des sous-dossiers.`, type: 'warning' })
        return false
      }
      if(folderHasTemplates(standardTemplates, folder)) {
        setNotification({ msg: `Le dossier "${folder.name}" ne peut pas être supprimé car il n'est pas vide ! Il contient des modèles.`, type: 'warning' })
        return false
      }
    }


    return true 
  }

  // Delete folder
  const deleteFolder = async (folder, view) => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Suppression du dossier')
    try {
      if(view === 'documents') {
        await deleteDocFolder(folder.id)
      }else if (view === 'templates') {
        await deleteTmpltFolder(folder.id)
      } else if (view === 'standard-templates') {
        await deleteStandardTemplatesFolder(folder.id)
      }
    } catch (err) {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Get folder templates
  const getFolderTemplates = (folder, view) => {
    const source = view === 'templates' ? templates : 'standard-templates' ? standardTemplates : []
    const arr = []
    for(let key in source) {
      let tmplt = source[key]
      if(!tmplt.deleted && !tmplt.hidden && tmplt.folderId && Array.isArray(tmplt.folderId) && tmplt.folderId.includes(folder.id)) {
        arr.push({ ...tmplt, id: key })
      }
    }
    return arr
  }



const folderHasTemplates = (folder, view) => {
  const templates = getFolderTemplates(folder, view)
  return templates.length > 0
}

  return {
    moveFolder,
    moveToRootFolder,
    createFolder,
    editFolder,
    checkIfFolderCanBeDeleted,
    deleteFolder,
    getFolderTemplates,
    folderHasTemplates
  }
}

export default useFoldersActions