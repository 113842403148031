import { useContext } from 'react'

import { Delete } from '../../assets/icons'
import { CustomTooltip, Input, Select, Option } from '../ui_new'
import { UserContext } from '../../context'
import VerifiedContactDropdown from '../sections/document-detail/signatures/VerifiedContactDropdown'

const SingleDocumentSignSignerFields = ({ signer, type, index, verifiedContactsDropdownOptions, verifiedContactsChangeHandler, changeValueHandler, editSignaturePositionHandler, deletePositionHandler, placeSignatureHandler, deleteUser }) => {
  const { isNotSQHorCAIpartner } = useContext(UserContext)

  return (
    <div className="signature-form__field-groups" key={index}>
      {/* TODO - update with Select and Option components */}
      {type === 'customers' && signer.dropdown && <VerifiedContactDropdown 
        verifiedContactsDropdownOptions={verifiedContactsDropdownOptions}
        signer={signer}
        index={index}
        verifiedContactsChangeHandler={verifiedContactsChangeHandler}
      />}
      <div className="signature-form__field-group">
        <Input 
          value={signer.name.value}
          onChange={(e) => changeValueHandler(e, signer.id, 'name', type)}
          label="Prenom*"
          disabled={isNotSQHorCAIpartner ? type === 'customers' : type === 'customers' || type === 'admins'}
        />
        <Input 
          value={signer.lastname.value}
          onChange={(e) => changeValueHandler(e, signer.id, 'lastname', type)}
          label="Nom*"
          disabled={isNotSQHorCAIpartner ? type === 'customers' : type === 'customers' || type === 'admins'}
        />
      </div> 
      <div className="signature-form__field-group">
        <Input 
          value={signer.email.value}
          onChange={(e) => changeValueHandler(e, signer.id, 'email', type)}
          label="Adresse mail*"
          disabled={isNotSQHorCAIpartner ? type === 'customers' : type === 'customers' || type === 'admins'}
        />
        {type === 'manual' || type === 'doc_customers' ? <Input 
            value={signer.mobile.value}
            onChange={(e) => changeValueHandler(e, signer.id, 'mobile', type)}
            label="Mobile* (Le code pays est requis, ex. +33)"
          />
        : isNotSQHorCAIpartner 
          ? <Input 
            value={signer.mobile.value}
            onChange={(e) => changeValueHandler(e, signer.id, 'mobile', type)}
            label="Mobile* (Le code pays est requis, ex. +33)"
          />
          : signer.mobile.value && <Input 
            value={signer.mobile.value}
            onChange={(e) => changeValueHandler(e, signer.id, 'mobile', type)}
            label="Mobile* (Le code pays est requis, ex. +33)"
            disabled
          />
        }
        {signer.error && <div className="form__error"><p className="form-input__error">* {signer.error}</p></div>}
      </div>
      <div className="signature-form__group-actions">
        <CustomTooltip content="Supprimer l'utilisateur" position="left">
          <div className="align-right">
            <button className="icon-btn" onClick={(e) => deleteUser(e, index, signer.email.value)}><Delete /></button>
          </div>
        </CustomTooltip>
      </div>
    </div>
  )
}

export default SingleDocumentSignSignerFields