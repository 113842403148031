import React, { useState, useContext, useEffect } from 'react' 
import { useParams, Link } from 'react-router-dom'
import draftToHtml from 'draftjs-to-html'
import moment from 'moment'
import { AttachFile, ChevronLeft } from '@material-ui/icons'
import { saveAs } from 'file-saver'

import Loader from '../../UI/Loader'
import SupportPageLayout from '../../layouts/SupportPageLayout'
import { HelpContext } from '../../../context'
import { get_file } from '../../../services/firestore'
import { base64toBlob } from '../../../utils'
import { MainLayout } from '../../../layouts'

const DocumentationArticle = ({v2}) => {
  const { articles, articlesFetched } = useContext(HelpContext)
  const { id } = useParams()
  const [article, setArticle] = useState({})
  const [articleDoesNotExist, setArticleDoesNotExist] = useState(false)

  // Set article from id
  useEffect(() => {
    if(articlesFetched) {
      if(articles[id] && articles[id].category === 'documentation') {
        setArticle(articles[id])
        console.log(articles[id])
      }else {
        setArticleDoesNotExist(true)
      }
    }
  }, [id, articles, articlesFetched])

  // Download attachment handler
  const downloadAttachmentHandler = async (att) => {
    const res = await get_file({ url: att.url, getType: true });
    const blob = base64toBlob(res.data)
    const a = document.createElement('a')
    a.onclick = saveAs(blob, `${att.name}.${res.file_type.ext}`)
  }

  return (
    <MainLayout className="page-help" pageTitle="Documents utiles" fullHeader={true}>
      <SupportPageLayout title={`Documents utiles`} v2={v2}>
        {!articlesFetched 
          ? <div className="loader-wrapper loader-wrapper--2"><Loader normal small primary /></div>
          : Object.keys(article).length > 0 && 
          <div className="help-article">
            <div className="help-article__head">
              <div className="link-wrapper">
                <Link to="/documentation"><ChevronLeft />{`Retourner`}</Link>
              </div>
              <h2>{article.title?.en}</h2>
            </div>
            <div className="help-article__body">
              <div dangerouslySetInnerHTML={{ __html: draftToHtml(article.content.en) }}></div>
            </div>
            <div className="help-article__foot">
              {article.attachments?.length > 0 && <ul>
                {article.attachments.map((att, i) => (
                  <li key={i}><span onClick={() => downloadAttachmentHandler(att)} ><AttachFile /> {att.name}</span></li>
                ))}  
              </ul>}
            </div>
          </div>
        }
        {articleDoesNotExist &&
          <div className="support-page__no-data">
            <p>{`L'article avec l'id(${id}) n'existe pas.`}</p>
          </div>
        }
      </SupportPageLayout>
    </MainLayout>
  )
}

export default DocumentationArticle
