import { useState } from 'react'

import { Download, Delete, Close } from '../../assets/icons'
import { useSignaturesActions } from '../../hooks'
import { Alert, Checkbox, CustomTooltip } from '../ui_new'
import { MoreHoriz } from '@material-ui/icons'
import { Check, MoreVert } from '@mui/icons-material'


const SignaturesActions = ({ selectedSignatures, onSetSelectedSignatures, onSetAllChecked }) => {
  const { downloadSelectedSignatures, deleteSelectedSignatures } = useSignaturesActions()
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [displayMore, setDisplayMore] = useState(false)

  // On download
  const handleSignaturesDownload = (e) => {
    e.preventDefault()
    downloadSelectedSignatures({ selectedSignatures, setSelectedSignatures: onSetSelectedSignatures, setAllChecked: onSetAllChecked })
  }

  // On actions close
  const handleCloseActions = () => {
    onSetSelectedSignatures([])
    onSetAllChecked(false)
  }

  // On open delete alert
  const handleOpenDeleteAlert = (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete
  const handleSignaturesDelete = () => {
    deleteSelectedSignatures({ selectedSignatures, setSelectedSignatures: onSetSelectedSignatures, setAllChecked: onSetAllChecked })
  }

  // On actions more
  const handleMoreActions = () => {
    setDisplayMore(true)
  }


  return (
    <div className="dashboard-actions dashboard-actions--signatures">
      <div className="dashboard-actions__selected">
        <div className={`dashboard-document-box__check checked relative`}>
          <Checkbox label="" checked={true} />
        </div>
        <p>{selectedSignatures.length} Élément(s) sélectionné(s)</p>
      </div>
      <div className={`dashboard-actions__buttons_overlay ${displayMore ? 'active' : ''}`} onClick={() => setDisplayMore(false)}></div>
      <div className={`dashboard-actions__buttons ${displayMore ? 'active' : ''}`}>
        <CustomTooltip content="Télécharger">
          <a href="/" onClick={handleSignaturesDownload} className="download-icon"><Download /></a>
        </CustomTooltip>
        <CustomTooltip content="Supprimer">
          <a href="/" onClick={handleOpenDeleteAlert} className="icon-delete"><Delete /></a>
        </CustomTooltip>
      </div>
      <div className="dashboard-actions__more" onClick={handleMoreActions}>
        <MoreVert />
      </div>
      <div className="dashboard-actions__close" onClick={handleCloseActions}>
        <Close /> <span className="text">Fermer</span>
      </div>

      {showDeleteAlert && <Alert
        onClose={handleCloseDeleteAlert}
        text="Êtes-vous sûr de vouloir supprimer le(s) signature(s) sélectionné(s) ?"
        onSubmit={handleSignaturesDelete}
        deleteAlert
      />}
    </div>
  )
}

export default SignaturesActions