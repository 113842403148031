import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import moment from 'moment';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PublishIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import LabelIcon from '@material-ui/icons/Label';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ShareIcon from '@material-ui/icons/Share';

import Checkbox from './Checkbox';
import Button from './Button';
import IconButton from './IconButton';
import Dropdown from './Dropdown';
import FoldersPopup from '../sections/FoldersPopup';
import SectionsPopup from '../sections/SectionsPopup';
import ActionsDropdown from './ActionsDropdown'

import { FolderContext } from '../../context/folders/folderState';
import { DocumentsContext } from '../../context/documents/documentsState';
import { UserContext } from '../../context/user/userState'
import config from '../../config.json';
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper';
import { availableOn, canUseTemplateCategories, isMlsPartner } from '../../utils'
import { Visibility, VisibilityOff } from '@material-ui/icons';

const TemplateRow = ({ selectedDocuments, doc, onCheckboxChange, onDetailView, onButtonClick, onTemplateEdit, onTemplateDelete, onTemplateDownload, onOpenFolderModal, onMoveToFolder, draggable, onTemplateShare, onTemplateToggleHidden }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSectionsPopup, setShowSectionsPopup] = useState(false);
  const { folders, foldersLoading } = useContext(FolderContext);
  const { templates, templatesLoading } = useContext(DocumentsContext);
  const { partner } = useContext(UserContext)
  const popupEl = useRef();
  const sectionsPopupEl = useRef();

  // Drag start
  const dragStartHandler = (e) => {
    const div = document.createElement('div');
    div.id = 'draggable-template-el';
    div.className = 'draggable-ghost-el';
    div.innerText = `Déplacer le ${doc.name}`;
    document.getElementById('root').appendChild(div);
    e.dataTransfer.setData('template', JSON.stringify(doc));
    e.dataTransfer.setDragImage(div, 0, 0);
  }

  // Drag end
  const dragEndHandler = e => {
    document.getElementById('draggable-template-el').remove();
  }

  return(
    <div 
      className={[...selectedDocuments].filter(obj => obj.id === doc.id).length > 0 ? "dashboard-row template-row active" : "dashboard-row template-row"}
    >
      <div className="dashboard-column col-1"
        draggable={draggable}
        onDragStart={dragStartHandler}
        onDragEnd={dragEndHandler}
      >
        <AdminFeatureWrapper rule="any_admin" altContent={<span className="icon checkbox-placeholder"><DescriptionOutlinedIcon /></span>}>
          <Checkbox 
            onChange={onCheckboxChange} 
            checked={[...selectedDocuments].filter(obj => obj.id === doc.id).length > 0} 
          />
        </AdminFeatureWrapper>
        <span className="icon"><DescriptionOutlinedIcon /></span>
        <div className="dashboard-column__main" onClick={onDetailView}>
          <p className="title">{doc.title}</p>
        </div>
      </div>
      <div className="dashboard-column col-2 col-font-small center date-col" data-label="Créé le:">
        {moment(doc.meta.created).format('DD MMM')} <span>{moment(doc.meta.created).format('HH:mm')}</span>
      </div>
      <div className="dashboard-column col-3 col-font-small center date-col last" data-label="Modifié le:">
        {moment(doc.meta.updated).format('DD MMM')} <span>{moment(doc.meta.updated).format('HH:mm')}</span>
      </div>
      <div className="dashboard-column show-on-hover">
        <Button text="Créer un document" onButtonClick={onButtonClick} white />
        <AdminFeatureWrapper rule="any_admin">
          <ActionsDropdown
            trigger={<button className="icon-button" onClick={() => setShowDropdown(!showDropdown)}><MoreHorizIcon /></button>}
            hideHeader
            width={120}
            dropdownClass="actions-dropdown--no-padding"
          >
            <ul className="dashboard-column__dropdown">
              { isMlsPartner(partner) && <li onClick={() => onTemplateShare(doc)} className="edit"><span className="icon"><ShareIcon /></span>Partager</li> }
              <li onClick={() => onTemplateDownload(doc)}><span className="icon rotate-180"><PublishIcon /></span>Télécharger</li>
              <li onClick={() => onTemplateEdit(doc)} className="edit"><span className="icon"><EditIcon /></span>Renommer</li>
              {/* <li onClick={() => {setShowPopup(true); setShowDropdown(false)}}>
                <span className="icon"><SubdirectoryArrowLeftIcon /></span>Déplacer
              </li> */}
              <li>
                <ActionsDropdown
                  trigger={<div><span className="icon"><SubdirectoryArrowLeftIcon /></span>Déplacer</div>}
                  onTriggerClick={() => setShowPopup(true)}
                  hideHeader
                  dropdownClass="actions-dropdown--no-padding folders-popup-dropdown"
                  width={253}
                  onClose={() => setShowPopup(false)}
                  hide={!showPopup}
                  hideArrow={true}
                >
                  <FoldersPopup 
                    ref={popupEl} 
                    onCancel={() => setShowPopup(false)} 
                    onOpenFolderModal={onOpenFolderModal} 
                    onMoveToFolder={onMoveToFolder}
                    folders={folders}
                    foldersLoading={foldersLoading}
                    loading={templatesLoading}
                    data={templates}
                    resource={doc}
                  />
                </ActionsDropdown>
              </li>
              {/* {availableOn(['development', 'staging']) && canUseTemplateCategories(partner) && <li onClick={() => {setShowSectionsPopup(true); setShowDropdown(false)}}><span className="icon"><AccountTreeIcon /></span>Catégories</li>} */}
              {canUseTemplateCategories(partner) && 
              <li>
                <ActionsDropdown
                  trigger={<div><span className="icon"><AccountTreeIcon /></span>Catégories</div>}
                  onTriggerClick={() => setShowSectionsPopup(true)}
                  hideHeader
                  dropdownClass="actions-dropdown--no-padding folders-popup-dropdown"
                  width={253}
                  onClose={() => setShowSectionsPopup(false)}
                  hide={!showSectionsPopup}
                  hideArrow={true}

                >
                  <SectionsPopup 
                    ref={sectionsPopupEl} 
                    onCancel={() => setShowSectionsPopup(false)} 
                    resource={doc}
                  />
                </ActionsDropdown>
              </li>}
              <li onClick={() => onTemplateToggleHidden(doc)}><span className="icon">{doc.hidden ? <Visibility /> : <VisibilityOff />}</span>{doc.hidden ? 'Rendre visible' : 'Masquer'}</li>
              <li onClick={() => onTemplateDelete(doc)}><span className="icon"><DeleteForeverIcon /></span>Supprimer</li>
            </ul>
          </ActionsDropdown>
          {/* <IconButton icon={<MoreHorizIcon />} onButtonClick={() => setShowDropdown(!showDropdown)} />
          <Dropdown show={showDropdown} onChange={setShowDropdown} animate>
            <ul className="dashboard-column__dropdown">
              <li onClick={() => onTemplateDownload(doc)}><span className="icon rotate-180"><PublishIcon /></span>Télécharger</li>
              <li onClick={() => onTemplateEdit(doc)} className="edit"><span className="icon"><EditIcon /></span>Renommer</li>
              <li onClick={() => {setShowPopup(true); setShowDropdown(false)}}><span className="icon"><SubdirectoryArrowLeftIcon /></span>Déplacer</li>
              {availableOn(['development', 'staging']) && canUseTemplateCategories(partner) && <li onClick={() => {setShowSectionsPopup(true); setShowDropdown(false)}}><span className="icon"><AccountTreeIcon /></span>Catégories</li>}
              <li onClick={() => onTemplateDelete(doc)}><span className="icon"><DeleteForeverIcon /></span>Supprimer</li>
            </ul>
          </Dropdown> */}
        </AdminFeatureWrapper>
        {/* <Dropdown show={showPopup} onChange={setShowPopup} animate dontHideCheck>
          <FoldersPopup 
            ref={popupEl} 
            onCancel={() => setShowPopup(false)} 
            onOpenFolderModal={onOpenFolderModal} 
            onMoveToFolder={onMoveToFolder}
            folders={folders}
            foldersLoading={foldersLoading}
            loading={templatesLoading}
            data={templates}
            show={showPopup}
            onShow={setShowPopup}
            resource={doc}
          />
        </Dropdown> */}
        {/* <Dropdown show={showSectionsPopup} onChange={setShowSectionsPopup} animate dontHideCheck>
          <SectionsPopup 
            ref={sectionsPopupEl} 
            onCancel={() => setShowSectionsPopup(false)} 
            resource={doc}
          />
        </Dropdown> */}
      </div>
    </div>
  );
}

export default TemplateRow;