const SentimentVeryDissatisfied = () => {
    return (
        <span className="svg-icon">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0026 22.5002C16.1193 22.5002 12.8193 24.9335 11.4859 28.3335H28.5193C27.1859 24.9335 23.8859 22.5002 20.0026 22.5002ZM13.0359 20.0002L14.8026 18.2335L16.5693 20.0002L18.3359 18.2335L16.5693 16.4668L18.3359 14.7002L16.5693 12.9335L14.8026 14.7002L13.0359 12.9335L11.2693 14.7002L13.0359 16.4668L11.2693 18.2335L13.0359 20.0002ZM19.9859 3.3335C10.7859 3.3335 3.33594 10.7835 3.33594 20.0002C3.33594 29.2168 10.7859 36.6668 19.9859 36.6668C29.1859 36.6668 36.6693 29.2168 36.6693 20.0002C36.6693 10.7835 29.2026 3.3335 19.9859 3.3335ZM20.0026 33.3335C12.6359 33.3335 6.66927 27.3668 6.66927 20.0002C6.66927 12.6335 12.6359 6.66683 20.0026 6.66683C27.3693 6.66683 33.3359 12.6335 33.3359 20.0002C33.3359 27.3668 27.3693 33.3335 20.0026 33.3335ZM26.9693 12.9335L25.2026 14.7002L23.4359 12.9335L21.6693 14.7002L23.4359 16.4668L21.6693 18.2335L23.4359 20.0002L25.2026 18.2335L26.9693 20.0002L28.7359 18.2335L26.9693 16.4668L28.7359 14.7002L26.9693 12.9335Z" />
            </svg>
        </span>
    )
}

export default SentimentVeryDissatisfied