import { SET_DEFAULT_ATTACHMENTS, SET_ATTACHMENTS_ORDER } from '../types'

const reducer = (state, action) => {
  switch(action.type) {
    case SET_DEFAULT_ATTACHMENTS:
      return {
        ...state,
        defaultAttachments: action.payload
      }
    case SET_ATTACHMENTS_ORDER:
      return {
        ...state,
        defaultAttachmentsOrder: action.payload
      }
    default:
      return state;
  }
}

export default reducer
