import { useState } from 'react'

import { Archive, Download, Delete, Duplicate, Close } from '../../assets/icons'
import { useDocumentsActions } from '../../hooks'
import { Alert, Checkbox, CustomTooltip } from '../ui_new'
import { Check, MoreVert } from '@mui/icons-material'


const DashboardActions = ({ selectedDocuments, onSetSelectedDocuments }) => {
  const { downloadDocuments, archiveDocuments, duplicateDocuments, moveDocumentsToDeletedFolder } = useDocumentsActions(selectedDocuments, onSetSelectedDocuments)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [displayMore, setDisplayMore] = useState(false)


  // On documents duplicate
  const handleDocumentsDuplicate = (e) => {
    e.preventDefault()
    duplicateDocuments()
  }

  // On documents download
  const handleDocumentsDownload = (e) => {
    e.preventDefault()
    downloadDocuments()
  }

  // On documents archive
  const handleDocumentsArchive = (e) => {
    e.preventDefault()
    archiveDocuments()
  }

  // On documents delete
  const handleDocumentsDelete = (e) => {
    e.preventDefault()
    moveDocumentsToDeletedFolder()
  }

  // On actions close
  const handleCloseActions = () => {
    onSetSelectedDocuments([])
  }

  // On open delete alert
  const handleOpenDeleteAlert = (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On actions more
  const handleMoreActions = () => {
    setDisplayMore(true)
  }
  
  // On all checkbox click
  const handleAllChecked = () => {
    onSetSelectedDocuments([])
  }

  return (
    <div className="dashboard-actions">
      <div className="dashboard-actions__selected" onClick={handleAllChecked}>
        <div className={`dashboard-document-box__check checked relative`}>
          <Checkbox label="" checked={true} />
        </div>
        <p>{selectedDocuments.length} Élément(s) <span>sélectionné(s)</span></p>
      </div>
      <div className={`dashboard-actions__buttons_overlay ${displayMore ? 'active' : ''}`} onClick={() => setDisplayMore(false)}></div>
      <div className={`dashboard-actions__buttons ${displayMore ? 'active' : ''}`}>
        <a href="/" onClick={handleDocumentsDuplicate} className="icon-duplicate"><Duplicate /> Dupliquer</a>
        <a href="/" onClick={handleDocumentsDownload} className="download-icon"><Download /> Télécharger</a>
        <a href="/" onClick={handleDocumentsArchive} className="archive"><Archive /> Archiver</a>
        <a href="/" onClick={handleOpenDeleteAlert} className="icon-delete"><Delete /> Supprimer</a>
      </div>

      <div className={`dashboard-actions__more ${displayMore ? 'active' : ''}`} onClick={handleMoreActions}>
        <MoreVert />
      </div>
      <div className="dashboard-actions__close" onClick={handleCloseActions}>
        <Close /> <span className="text">Fermer</span>
      </div>

      {showDeleteAlert && <Alert
        onClose={handleCloseDeleteAlert}
        text="Êtes-vous sûr de vouloir supprimer le(s) document(s) sélectionné(s) ?"
        onSubmit={handleDocumentsDelete}
        deleteAlert
      />}
    </div>
  )
}

export default DashboardActions