import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

const FileUpload = ({ label, name, id, onChange, fileName, error, btnText, icon, dragAndDrop, onDragEnter, onDragLeave, onDragOver, onDrop, multiple, accept }) => {
  const fileInput = useRef();
  const dragAndDropEl = useRef();

  const fileBtnClickHandler = () => {
    fileInput.current.click();
  }

  const dragEnterHandler = (e) => {
    e.preventDefault();
  }
  
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    dragAndDropEl.current.classList.remove('file-upload__drag-drop--over');
  }
  
  const dragOverHandler = (e) => {
    e.preventDefault();
    dragAndDropEl.current.classList.add('file-upload__drag-drop--over');
    
  }

  const dropHandler = (e) => {
    e.preventDefault();
    console.log('drop', e.dataTransfer.files);
    onDrop(e);
    dragAndDropEl.current.classList.remove('file-upload__drag-drop--over');
  }

  const changeHandler = (e) => {
    onChange(e);
  }

  return(
    <div className="file-upload">
      {label && <label className="file-upload__label">{label}</label>}
      <input type="file" name={name} id={id ? id : name} onChange={changeHandler} className="file-upload__field" ref={fileInput} multiple={multiple} accept={accept || ''} />
      <div className="file-upload__group">
        <Button text={btnText} icon={icon} outlinePrimary onButtonClick={fileBtnClickHandler} withPaddingY />
      </div>
      {dragAndDrop &&
        <div 
          className="file-upload__drag-drop" 
          onClick={fileBtnClickHandler} 
          onDragEnter={dragEnterHandler}
          onDragLeave={dragLeaveHandler}
          onDragOver={dragOverHandler}
          onDrop={dropHandler}
          ref={dragAndDropEl}
        >
          <p><i className="custom-icon-content-copy"></i></p>
          <p>{fileName ? fileName : 'Vous pouvez aussi glisser-déposer une piece jointe ici'}</p>
        </div>
      }
      {fileName && !dragAndDrop && <div className="file-upload__name">{fileName}</div>}
      {error && <div className="file-upload__error">{error}</div>}
    </div>
  );
}

FileUpload.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fileName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  error: PropTypes.string,
  btnText: PropTypes.string,
  icon: PropTypes.node,
  dragAndDrop: PropTypes.bool,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDragOver: PropTypes.func,
  onDrop: PropTypes.func
}

export default FileUpload;