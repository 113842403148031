import { useEffect, useState, useContext } from 'react'
import { Document, Page } from 'react-pdf'
import { useHistory } from 'react-router-dom'

import { Plus } from '../../assets/icons'
import { Modal, CustomTooltip } from '../ui_new'
import { fetch_document_data } from '../../helpers/documents'
import { UserContext, LoaderContext } from '../../context'
import { useSingleDocumentActions } from '../../hooks'

const TemplatePreviewModal = ({ onClose, template }) => {
  const { createDocumentFromTemplate } = useSingleDocumentActions()
  const { user, agency, partner } = useContext(UserContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dataReady, setDataReady] = useState(false)
  const [pages, setPages] = useState([])
  const [dataLoadFailed, setDataLoadFailed] = useState(false)
  const history = useHistory()

  // Set data 
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const docData = await fetch_document_data(template, { custom_cover: false, values: {}, name: template.name }, 'pdf', agency, user, {}, partner)
        setData(`data:application/pdf;base64,${docData}`)
      } catch (err) {
        console.log(err)
        setDataLoadFailed(true)
      }
      setLoading(false)
      setDataReady(true)
    }
    if(template && agency && user && !dataReady) {
      fetchData()
    }
  }, [template, agency, user, dataReady])

  // On load success
  const handleLoadSuccess = ({ numPages }) => {
    const arr = []
    for(let i = 1; i < numPages + 1; i++) {
      arr.push(<Page pageNumber={i} width={870} renderTextLayer={false} loading="" key={i} />)
    }
    setPages(arr)
  }

  // On create document
  const handleCreateDocument = async () => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Création du document')
    try {
      const id = await createDocumentFromTemplate(template)
      history.push(`/documents/${id}`)
    } catch (err) {

    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  return (
    <Modal onClose={onClose}>
      <div className="template-preview-modal">
        <div className="template-preview-modal__preview">
          {loading && <div className="loader-wrapper"><div className="loader-v2"></div></div>}
          {!loading && dataReady && (
            <Document file={data} onLoadSuccess={handleLoadSuccess} renderMode="canvas" loading="Chargement...">
              {pages}
            </Document>
          )}
        </div>
        {/* {!loading && dataReady && !dataLoadFailed && (
          <div className="template-preview-modal__create-doc">
            <CustomTooltip content="Create document from this template">
              <button className="icon-btn" onClick={handleCreateDocument}><Plus /></button>
            </CustomTooltip>
          </div>
        )} */}
      </div>
    </Modal>
  )
}

export default TemplatePreviewModal