import firebase from './firebase'

const get_file_data = async (attUrl) => {
  const ref = firebase.storage().refFromURL(attUrl)
  const fileRef = firebase.storage().ref(ref.fullPath)
  try {
    const data = await fileRef.getMetadata()
    return {...data, url: attUrl}
  } catch (err) {
    return {}
  }
}

const upload_file_to_storage = async (base64, fileName, path, fileType) => {
  const storage = firebase.storage()
  const storageRef = storage.ref(`${path}/${fileName}`)
  const metadata = {
    contentType: fileType
  }

  const uploadResponse = await storageRef.putString(base64, 'base64', metadata)
  
  const downloadURL = await uploadResponse.ref.getDownloadURL()

  return {
    url: downloadURL,
    path: uploadResponse.ref.fullPath
  }
}


export {
  get_file_data,
  upload_file_to_storage
}