import { useEffect, useState } from 'react'

import { Modal, Input } from '../ui_new'

const EditAttachmentModal = ({ onClose, onEdit, attachment }) => {
  const [name, setName] = useState('')

  // Set name 
  useEffect(() => {
    if (attachment && attachment.name) {
      setName(attachment.name)
    }
  }, [attachment])

  // On submit
  const handleSubmit = (e) => {
    e.preventDefault()
    if (name.trim() === '') {
      return
    }

    onEdit(name.trim())
  }

  return (
    <Modal onClose={onClose} className="modal-v2--small">
      <div className="folder-modal-v2">
        <div className='folder-modal-v2__heading'>
          <h2>Modifier la pièce jointe</h2>
        </div>
        <div className='folder-modal-v2__content'>
          <form onSubmit={handleSubmit} className="folder-modal-v2__form">
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="Nom de la pièce jointe"
              className={'mb-6'}
            />
            <div className="folder-modal-v2__form_actions gap-2">
              <button className="btn btn--transparent" onClick={onClose}>Annuler</button>
              <button className="btn" onClick={handleSubmit} disabled={name.trim() === ''}>Sauvegarder</button>
            </div>
          </form>
        </div>

      </div>
    </Modal>
  )
}

export default EditAttachmentModal