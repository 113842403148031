import React, { useRef, useEffect } from 'react';

const DashboardSidePanel = ({ show, onClose, children, title, icon, afterClose }) => {
  const panelEl = useRef();
  const panelInnerEl = useRef();

  // when panel is visible 
  useEffect(() => {
    if(show) {
      document.body.style.overflow = 'hidden'
      panelEl.current.style.zIndex = 999;
      panelInnerEl.current.scrollTop = 0;
    }
  }, [show]);

  // On panel close
  const panelCloseHandler = () => {
    onClose();
    setTimeout(() => {
      document.body.style.overflow = null;
      panelEl.current.style.zIndex = -1;
      afterClose();
    }, 300);
  }

  return (
    <div className={show ? "dashboard-side-panel active" : "dashboard-side-panel"} ref={panelEl}>
      <div className="dashboard-side-panel__overlay" onClick={panelCloseHandler}></div>
      <div className="dashboard-side-panel__inner" ref={panelInnerEl}>
        {title && <div className="dashboard-side-panel__inner_head">
          {icon && icon}
          <h5>{title}</h5>
        </div>}
        <div className="dashboard-side-panel__inner_body">
          {children}
        </div>
      </div>
    </div>
  );
}

export default DashboardSidePanel;