import { useState, useContext } from 'react'

import { Delete, Pen, Save, Times } from '../../assets/icons'
import { Modal, Input, Alert } from '../ui_new'
import { NotificationContext, SignatureTemplatesContext, LoaderContext } from '../../context'
import config from '../../config.json'

const SignatureTemplatesModal = ({ onClose, templates = [], doc, activeTemplate, onSetActiveTemplate, customSigners, signers, adminSigners, onSetFieldsPositions, onSetSignaturePositions, onSetSignaturePlaced }) => {
  return (
    <Modal onClose={onClose} className="modal-v2--medium">
      <div className="signature-templates-modal-v2"> 
        <h3>Modèles de signature</h3>
        <ul>
          {templates.map(tmpl => (
            <TemplateItem 
              key={tmpl.id} 
              tmpl={tmpl} 
              activeTemplate={activeTemplate} 
              onSetActiveTemplate={onSetActiveTemplate} 
              customSigners={customSigners}
              signers={signers}
              adminSigners={adminSigners}
              onSetFieldsPositions={onSetFieldsPositions}
              onSetSignaturePositions={onSetSignaturePositions}
              onSetSignaturePlaced={onSetSignaturePlaced}
            />
          ))}
        </ul>
      </div>
    </Modal>
  )
}

const TemplateItem = ({ tmpl, activeTemplate, onSetActiveTemplate, customSigners, signers, adminSigners, onSetFieldsPositions, onSetSignaturePositions, onSetSignaturePlaced }) => {
  const { updateSignatureTemplate, deleteSignatureTemplate } = useContext(SignatureTemplatesContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const [name, setName] = useState('')
  const [mode, setMode] = useState('view')
  const [showDeleteTemplateAlert, setShowDeleteTemplateAlert] = useState(false)

  // Edit template - on edit icon click
  const editTemplate = () => {
    setMode('edit')
    setName(tmpl.name)
  }

  // Delete template
  const deleteTemplate = async () => {
    setShowGlobalResponseLoader(true)
    try {
      await deleteSignatureTemplate(tmpl.id)
      setNotification({ msg: 'Modèle supprimé', type: 'success' })
      if(activeTemplate === tmpl.id) {
        onSetActiveTemplate('')
        const sArr = config.environment === 'development' ? [...customSigners, ...signers, ...adminSigners] : [...signers, ...adminSigners]
  
        sArr.forEach(s => {
          if(s.position) s.position = []
        });
        onSetFieldsPositions([])
        onSetSignaturePositions([])
        onSetSignaturePlaced(false)
      }
    } catch (err) {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    }
    setShowGlobalResponseLoader(false)
  }

  // Update template
  const updateTemplate = async () => {
    if(name.trim() === '') {
      return setNotification({ msg: 'Le nom est obligatoire', type: 'danger' })
    }
    if(name.trim() === tmpl.name) {
      return setNotification({ msg: 'Veuillez mettre à jour le nom du modèle', type: 'warning' })
    }
    setShowGlobalResponseLoader(true)
    try {
      await updateSignatureTemplate(tmpl.id, { name })
      setNotification({ msg: 'Modèle mis à jour', type: 'success' })
      setMode('view')
    } catch (err) {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    }
    setShowGlobalResponseLoader(false)
  }

  // Back to view mode
  const backToViewMode = () => {
    setName('')
    setMode('view')
  }

  return (
    <li>
      <div className="name">
        {mode === 'view' ? 
          <p>{tmpl.name}</p> 
          : 
          <div className="input-wrapper">
            <Input value={name} onChange={(e) => setName(e.target.value)} formEl />
          </div>
        }                
      </div>
      {mode === 'view' ? 
        <div className="actions">
          <button className="icon-btn" onClick={editTemplate}><Pen /></button>
          <button className="icon-btn" onClick={() => setShowDeleteTemplateAlert(true)}><Delete /></button>
        </div>
        :
        <div className="actions actions--2">
          <button className="icon-btn" onClick={updateTemplate}><Save /></button>
          <button className="icon-btn" onClick={backToViewMode}><Times /></button>
        </div>
      }

      {showDeleteTemplateAlert && <Alert 
        onClose={() => setShowDeleteTemplateAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer ce modèle ?"
        onSubmit={deleteTemplate}
        deleteAlert
      />}
    </li>
  );
}

export default SignatureTemplatesModal