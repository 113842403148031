import React from 'react';
import PropTypes from 'prop-types';

const IconButton = ({ icon, onButtonClick, disabled, primaryLight, fixedSmall, rotate180, light, dark, round, medium, lightGray, className, type = 'button' }) => {
  let btnClass = 'icon-button';

  if(primaryLight) {
    btnClass += ' icon-button--primary-light';
  }
  if(light) {
    btnClass += ' icon-button--light';
  }
  if(dark) {
    btnClass += ' icon-button--dark';
  }
  if(fixedSmall) {
    btnClass += ' icon-button--fixed-small';
  }
  if(rotate180) {
    btnClass += ' icon-button--rotate-180';
  }
  if(round) {
    btnClass += ' icon-button--round';
  }
  if(medium) {
    btnClass += ' icon-button--medium';
  }
  if(lightGray) {
    btnClass += ' icon-button--light-gray';
  }
  if(className) {
    btnClass += ` ${className}`;
  }

  return(
    <button className={btnClass} onClick={onButtonClick} disabled={disabled} type={type}>{icon}</button>
  );
}

IconButton.propTypes = {
  icon: PropTypes.node,
  onButtonClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default IconButton;