import { Switch, Select, Option } from '../ui_new'

const ChooseTemplateSection = ({ showChooseTemplates, chooseTemplateSwitchChangeHandler, signatureTemplatesOptions, templateSelectedHandler, activeSignatureTemplate, setShowSignatureTemplatesModal }) => {
  return (
    <div className="signature-modal-v2-inner__section signature-modal-v2__template">
      <p className="signature-form__subtitle signature-form__subtitle--2">Modèle d'emplacement de signature</p>
      <div className="checkbox-wrapper">
        <Switch checked={showChooseTemplates} onChange={chooseTemplateSwitchChangeHandler} />
      </div>
      {showChooseTemplates && <div className="signature-modal-v2__template_choose">
        <Select onChange={(value) => templateSelectedHandler(value)}>
          {signatureTemplatesOptions.map((option, idx) => {
            return (
              <Option key={idx} value={option.value}>{option.label}</Option>
            )
          })}
        </Select>
        <a href="/#" onClick={(e) => { e.preventDefault(); setShowSignatureTemplatesModal(true)}}>Editer</a>
      </div>}
    </div>
  )
}

export default ChooseTemplateSection