import React, { useEffect, useContext, useState } from 'react';
import { ChevronLeft, ChevronRight, ExpandMore } from '@material-ui/icons'

import ActionsDropdown from '../../UI/ActionsDropdown'
import DashboardView from '../DashboardView';
import { sortedArrayFromObject, sorterWithPathAndOrder, sortArrayOfObjects, saveSortingToLS, getSorting, savePaginationDataToLS, getPaginationData } from '../../../utils'
import { DocumentsContext } from '../../../context/documents/documentsState';
import { FolderContext } from '../../../context/folders/folderState';
import { perPageOptions } from '../../../constants'

const TemplatesView = ({ onSidebarOpen, selected, onSetSelected, onTemplateDelete, onTemplateToggleHidden, onDownload, onMoveToFolder, onDelete, onTemplateEdit, onSearch, searchValue, onSetSearch, removeSearchClose, onSetRemoveSearchClose, selectedTitle, onSearchFormClear, onTemplateDownload, setViewTitle, onOpenFolderModal, rowDraggable, onFilterByFolders, onFolderCreate, onFolderEdit, onFolderDelete, onFolderMove, onFolderClick, onMoveSelectedTemplates, filteredFolders, searching, onSetSearching, onSectionsOpen, onTemplateShare }) => {
  const { templates, filteredTemplates, templatesLoaded, showFilteredTemplates, setFilteredTemplates } = useContext(DocumentsContext);
  const { folderBreadcrumbs, folders, selectedFilter, foldersFetched } = useContext(FolderContext);
  const [templatesArray, setTemplatesArray] = useState([]);
  const [filteredFoldersArr, setFilteredFoldersArr] = useState([]);
  const [filteredTmplts, setFilteredTmplts] = useState({});
  const sortLS = getSorting()
  const [columns, setColumns] = useState([
    { 
      label: 'Titre', 
      value: 'name', 
      order: sortLS?.templates?.value === 'name' ? sortLS.templates?.order || 'asc' : 'asc', 
      cssClass: 'col-1', 
      all: true, 
      active: sortLS && sortLS.templates ? sortLS.templates.value === 'name' : true 
    },
    { 
      label: 'Créé le', 
      value: 'meta.created', 
      order: sortLS?.templates?.value === 'meta.created' ? sortLS.templates?.order || 'desc' : 'desc', 
      cssClass: 'col-2', 
      all: false, 
      active: sortLS?.templates?.value === 'meta.created'   
    },
    { 
      label: 'Modifié le', 
      value: 'meta.updated', 
      order: sortLS?.templates?.value === 'meta.updated' ? sortLS.templates?.order || 'desc' : 'desc', 
      cssClass: 'col-3', 
      all: false, 
      active: sortLS?.templates?.value === 'meta.updated'   
    },
  ]);
  const [dataReady, setDataReady] = useState(false);
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [allPages, setAllPages] = useState(1)
  const [paginatedData, setPaginatedData] = useState([])
  const [paginationReady, setPaginationReady] = useState(false)
  
  useEffect(() => {
    const paginationData = getPaginationData()
    if(paginationData && paginationData.templates) {
      paginationData.templates.items && setPerPage(paginationData.templates.items)
      paginationData.templates.current && setCurrentPage(paginationData.templates.current)
      paginationData.templates.all && setAllPages(paginationData.templates.all)
    }
  }, [])

  useEffect(() => {
    processTemplates()
    // eslint-disable-next-line
  }, [templates, filteredTemplates, folders]);

  useEffect(() => {
    if(searching) {
      setFilteredFoldersArr(filteredFolders);
      onSetSearching(false);
    }
  }, [filteredFolders, searching]);

  // Change paginated data arr when filtered arr changes
  useEffect(() => {
    let current = currentPage
    let sliceFrom = current === 1 ? 0 : (current - 1) * perPage
    if(sliceFrom >= templatesArray.length) {
      sliceFrom = 0
      setCurrentPage(1)
    }
    let slicedData = templatesArray.slice(sliceFrom, sliceFrom + perPage)
    setPaginatedData(slicedData)
    let all = Math.ceil(templatesArray.length / perPage)
    setAllPages(all === 0 ? 1 : all)
    setTimeout(() => setPaginationReady(true), 200)
  }, [templatesArray, perPage, currentPage, allPages])

  const processTemplates = async () => {
    const t = {...filteredTemplates};
    let filtered = {};
    if(selectedFilter !== 'all') {
      for(let key in t) {
        if(t[key].folderId && t[key].folderId.includes(selectedFilter)) {
          filtered[key] = t[key];
        }
      }
    }else {
      for(let key in t) {
        if(!t[key].folderId || t[key].folderId.length === 0) {
          filtered[key] = t[key];
        }
      }
    }
    setFilteredTmplts(filtered)
    let sort = 'name'
    let order = 'asc'
    if(sortLS && sortLS.templates) {
      sort = sortLS.templates.value
      order = sortLS.templates.order
    }
    let tmplts = sortedArrayFromObject(filtered, sorterWithPathAndOrder(sort, order))
    for(let i in tmplts) {
      tmplts[i].title = tmplts[i].name
    }
    const checkWith = searching ? filteredFolders : folders;
    const filteredF = [...checkWith].filter(folder => {
      if(selectedFilter === 'all') {
        return folder.parentFolder === undefined || folder.parentFolder === null;
      }else {
        return folder.parentFolder === selectedFilter;
      }
    });
    let fldrs = sortArrayOfObjects(filteredF, sort, order)
    setFilteredFoldersArr(fldrs);
    let arr = [...fldrs, ...tmplts]
    setTemplatesArray(arr)

    // Pagination
    setPaginatedData(arr.slice(0, perPage))
    setAllPages(Math.ceil(arr.length / perPage))

    if(templatesLoaded && showFilteredTemplates && foldersFetched) {
      setDataReady(true);
    }
  }

  // Sort
  const sortHandler = (value, order) => {
    const filteredFolders = [...filteredFoldersArr];
    const sortedFolders = sortArrayOfObjects(filteredFolders, value, order);
    let copiedDocs = {...filteredTmplts};
    const docs = sortedArrayFromObject(copiedDocs, sorterWithPathAndOrder(value, order));
    setTemplatesArray([...sortedFolders, ...docs]);
    setColumns(prev => [...prev].map(col => col.value === value ? { ...col, order, active: true } : {...col, active: false}));
    saveSortingToLS(value, order, 'templates')
  }

  // On prev button click 
  const prevButtonClickHandler = () => {
    setCurrentPage(currentPage - 1)
    let sliceFrom = currentPage - 1 === 1 ? 0 : (currentPage - 2) * perPage
    setPaginatedData([...templatesArray].slice(sliceFrom, sliceFrom + perPage))
    onSetSelected([])
    savePaginationDataToLS(perPage, currentPage - 1, allPages, 'templates')
  }

  // On next button click 
  const nextButtonClickHandler = () => {
    setCurrentPage(currentPage + 1)
    let sliceFrom = currentPage * perPage
    setPaginatedData([...templatesArray].slice(sliceFrom, sliceFrom + perPage))
    onSetSelected([])
    savePaginationDataToLS(perPage, currentPage + 1, allPages, 'templates')
  }

  // Per page change handler
  const perPageChangeHandler = (e, num) => {
    e.preventDefault()
    setPerPage(num)
    if(Math.ceil(templatesArray.length / num) < currentPage) {
      setCurrentPage(1)
    }
    window.document.body.click()
    savePaginationDataToLS(num, currentPage, allPages, 'templates')
  }
  
  return(
    <>
      <DashboardView 
        data={paginatedData}
        columns={columns}
        view="templates"
        onSidebarOpen={onSidebarOpen}
        selected={selected}
        onSetSelected={onSetSelected}
        onSort={sortHandler}
        onTemplateDelete={onTemplateDelete}
        onTemplateEdit={onTemplateEdit}
        onTemplateToggleHidden={onTemplateToggleHidden}
        hideArchiveBtn
        hideDuplicateBtn
        onDownload={onDownload}
        onMoveToFolder={onMoveToFolder}
        onDelete={onDelete}
        onSearch={onSearch}
        onSetSearch={onSetSearch}
        searchValue={searchValue}
        removeSearchClose={removeSearchClose}
        onSetRemoveSearchClose={onSetRemoveSearchClose}
        viewTitle={selectedTitle}
        onSearchFormClear={onSearchFormClear}
        onTemplateDownload={onTemplateDownload}
        setViewTitle={setViewTitle}
        onOpenFolderModal={onOpenFolderModal}
        rowDraggable={rowDraggable}
        breadcrumbs={folderBreadcrumbs}
        onFilterTemplatesByFolders={onFilterByFolders}
        onFolderCreate={onFolderCreate}
        onFolderEdit={onFolderEdit}
        onFolderDelete={onFolderDelete}
        onFolderMove={onFolderMove}
        onFolderClick={onFolderClick}
        onMoveSelectedResources={onMoveSelectedTemplates}
        dataLoaded={templatesLoaded && foldersFetched && dataReady && paginationReady}
        showSectionsBtn={true}
        onSectionsOpen={onSectionsOpen}
        onlyAdminEdit={true}
        onTemplateShare={onTemplateShare}
      />

      {dataReady && paginationReady && paginatedData.length > 0 && <div className="pagination">
        <ActionsDropdown
          trigger={<button className="trigger">{perPage} <span><ExpandMore /></span></button>}
          hideHeader
          dropdownClass="pagination-dropdown"
          width={120}
        >
          <ul>
            {perPageOptions.map((num, i) => (
              <li key={i}>
                <div className="link">
                  <a href="/#" onClick={(e) => perPageChangeHandler(e, num)}>{num}</a>
                </div>
              </li>
            ))}
          </ul>
        </ActionsDropdown>
        <button onClick={prevButtonClickHandler} disabled={currentPage === 1}><ChevronLeft /></button>
        <div className="pagination__body">page {currentPage} de {allPages}</div>
        <button onClick={nextButtonClickHandler} disabled={currentPage === allPages}><ChevronRight /></button>
      </div>}
    </>
  );
}

export default TemplatesView;