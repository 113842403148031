const Duplicate = () => {
  return (
    <span className="svg-icon">
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 1H5C3.9 1 3 1.9 3 3V17H5V3H17V1ZM20 5H9C7.9 5 7 5.9 7 7V21C7 22.1 7.9 23 9 23H20C21.1 23 22 22.1 22 21V7C22 5.9 21.1 5 20 5ZM20 21H9V7H20V21Z" />
      </svg>
    </span>
  )
}

export default Duplicate