import { useRef } from 'react'

import { CustomTooltip } from '.'
import { NUMBER_LINES } from '../../constants'
import { Create } from '@mui/icons-material'

const Input = ({ label, type, placeholder, value, displayNC, displayPencilIcon, onChange, name, error, iconEl, id, disabled, disabledDefault, onBlur, labelBelow, onFocus, alternativeIcon, onIconLabelClick, multiple, className, onKeyDown, tooltip, inputStyle, linesAdded, maxLength = 524288 }) => {
  const inputRef = useRef()

  // On blur
  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e)
    }
  }

  // On focus
  const handleFocus = (e) => {
    if (onFocus) {
      onFocus(e)
    }
  }

  // Input class
  let inputClasses = 'input-v2'

  if (className) {
    inputClasses += ` ${className}`
  }

  if (iconEl) {
    inputClasses += ' with-icon'
  }

  if (disabled && !linesAdded) {
    inputClasses += ' disabled'
  }

  return (
    <div className={`${inputClasses}  ${error && 'error'} `}>
      <div className="input-v2__inner">
        {iconEl && <label className="input-v2__icon" onClick={onIconLabelClick}>{alternativeIcon}{iconEl}</label>}
        {label && <label htmlFor={id ? id : name} className="input-v2__label">{label}</label>
        }
        {tooltip ? (
          <CustomTooltip content={tooltip} className="custom-tooltip--top-input">
            <div>
              <input
                type={type ? type : "text"}
                className={`input-v2__field ${displayPencilIcon && 'more-right-padding'}`}
                placeholder={displayNC ? 'NC' : placeholder}
                name={name}
                id={id ? id : name}
                value={value}
                onChange={onChange}
                autoComplete="invalid"
                disabled={disabled || disabledDefault}
                onBlur={handleBlur}
                onFocus={handleFocus}
                multiple={multiple}
                onKeyDown={onKeyDown}
                style={{ ...inputStyle, maxWidth: '100%' }}
                ref={inputRef}
                maxLength={maxLength}
              />
              {displayPencilIcon && <span className='pencil'>
                <Create fontSize='small' />
              </span>}
            </div>
          </CustomTooltip>
        ) : (
          <>
            <input
              type={type ? type : "text"}
              className={`input-v2__field ${displayNC && 'display-nc'} ${displayPencilIcon && 'more-right-padding'}`}
              placeholder={displayNC ? 'NC' : placeholder}
              name={name}
              id={id ? id : name}
              value={value}
              onChange={onChange}
              autoComplete="invalid"
              disabled={disabled || disabledDefault}
              onBlur={handleBlur}
              onFocus={handleFocus}
              multiple={multiple}
              onKeyDown={onKeyDown}
              style={inputStyle}
              ref={inputRef}
              maxLength={maxLength}
            />
            {displayPencilIcon && <span className='pencil'>
              <Create fontSize='small' />
            </span>}
          </>
        )}
        {labelBelow && <label htmlFor={id} className="input-v2__label">{labelBelow}</label>}
      </div>
      {error && <div className="input-v2__error">{error}</div>}
    </div>
  )
}

export default Input