import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';

import CustomTooltip from './CustomTooltip';
import { NUMBER_LINES } from '../../constants'

const Input = ({ label, type, placeholder, value, onChange, name, error, iconEl, id, disabled, medium, colorLight, editMode, dashboardModal, editOnHover, onBlur, alignHoriz, small, labelBelow, profileMode, whiteBackground, iconSmall, sizeMedium, onFocus, alternativeIcon, onIconLabelClick, multiple, className, formEl, onKeyDown, tooltip, inputStyle, linesAdded, onRemoveLines }) => {
  const [inputActive, setInputActive] = useState(false);
  const inputRef = useRef()

  useEffect(() => {
    if(dashboardModal && value !== '') {
      setInputActive(true);
    }
  }, [value]);

  const inputBlurHandler = (e) => {
    if(dashboardModal && value === '') {
      setInputActive(false);
    }
    if(onBlur) {
      onBlur(e);
    }
  }

  const inputFocusHandler = (e) => {
    if(dashboardModal) {
      setInputActive(true);
    }
    if(onFocus) {
      onFocus(e);
    }
  }

  const labelClickHandler = () => {
    setInputActive(true)
    if(inputRef.current) {
      inputRef.current.focus()
    }
  }

  let inputClasses = 'form-input';

  if(error) {
    inputClasses += ' error';
  }

  if(iconEl) {
    inputClasses += ' with-icon';
  }

  if(medium) {
    inputClasses += ' form-input--medium';
  }

  if(colorLight) {
    inputClasses += ' form-input--color-light';
  }

  if(editMode) {
    inputClasses += ' form-input--edit-mode';
  }

  if(dashboardModal) {
    inputClasses += ' form-input--dashboard-modal';
  }

  if(editOnHover) {
    inputClasses += ' form-input--editable-on-hover';
  }

  if(small) {
    inputClasses += ' form-input--small';
  }

  if(profileMode) {
    inputClasses += ' form-input--profile-mode';
  }

  if(whiteBackground) {
    inputClasses += ' form-input--white-background';
  }

  if(iconSmall) {
    inputClasses += ' form-input--icon-small';
  }

  if(sizeMedium) {
    inputClasses += ' form-input--size-medium';
  }

  if(className) {
    inputClasses += ` ${className}`;
  }

  if(formEl) {
    inputClasses += ' form-input--form-el';
  }

  if(disabled && !linesAdded) {
    inputClasses += ' disabled'
  }

  return(
    <div className={inputClasses}>
      <div className={alignHoriz ? "form-input__inner form-input__inner--align-horiz" : "form-input__inner"}>
        {iconEl && <label className="form-input__icon" onClick={onIconLabelClick}>{alternativeIcon}{iconEl}</label>}
        {label && 
          dashboardModal 
            ? <label onClick={labelClickHandler} htmlFor={id ? id : name} className={inputActive ? "form-input__label active" : "form-input__label"}>{label}</label>
            : label && <label htmlFor={id ? id : name} className="form-input__label">{label}</label>
        }
        {tooltip ? 
        <CustomTooltip content={tooltip} className="custom-tooltip--top-input">
          <input 
            type={type ? type : "text"}
            className="form-input__field"
            placeholder={placeholder}
            name={name}
            id={id ? id : name}
            value={value}
            onChange={onChange}
            autoComplete="offf"
            disabled={disabled}
            onBlur={inputBlurHandler}
            onFocus={inputFocusHandler}
            multiple={multiple}
            onKeyDown={onKeyDown}
            style={inputStyle}
            ref={inputRef}
          />
        </CustomTooltip>
        :
        <input 
          type={type ? type : "text"}
          className="form-input__field"
          placeholder={placeholder}
          name={name}
          id={id ? id : name}
          value={value}
          onChange={onChange}
          autoComplete="offf"
          disabled={disabled}
          onBlur={inputBlurHandler}
          onFocus={inputFocusHandler}
          multiple={multiple}
          onKeyDown={onKeyDown}
          style={inputStyle}
          ref={inputRef}
        />
        }
        {labelBelow && <label htmlFor={id} className="form-input__label">{labelBelow}</label>}
        {linesAdded && (
          <CustomTooltip content={tooltip} className="custom-tooltip--top-input">
            <div className="input-lines" onClick={onRemoveLines}>{type === 'number' && <span>{NUMBER_LINES}</span>}</div>
          </CustomTooltip>
        )}
      </div>
      {error && <div className="form-input__error">{error}</div>}
    </div>
  );
}

// Input.propTypes = {
//   label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//   type: PropTypes.string,
//   placeholder: PropTypes.string,
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   onChange: PropTypes.func,
//   name: PropTypes.string,
//   error: PropTypes.string,
//   iconEl: PropTypes.node,
//   disabled: PropTypes.bool,
//   medium: PropTypes.bool,
//   colorLight: PropTypes.bool,
//   editMode: PropTypes.bool,
//   dashboardModal: PropTypes.bool,
//   editOnHover: PropTypes.bool,
//   onBlur: PropTypes.func,
//   onFocus: PropTypes.func,
//   alignHoriz: PropTypes.bool,
//   small: PropTypes.bool,
//   whiteBackground: PropTypes.bool,
//   iconSmall: PropTypes.bool,
//   sizeMedium: PropTypes.bool,
// }

export default Input;