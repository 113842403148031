import React, { createContext, useReducer } from 'react';

import sectionsReducer from './sectionsReducer';
import { fetch_sections, create_section, update_section, delete_section, fetch_tag_matchings } from '../../services/firestore';
import { GET_SECTIONS, SET_SECTIONS, SET_TAG_MATCHINGS } from '../types';

export const SectionContext = createContext();

const SectionsState = ({ children }) => {
  const initialState = {
    sections: [],
    sectionsFetched: false,
    tagMatchings: [],
    tagMatchingsFetched: false
  }

  const [state, dispatch] = useReducer(sectionsReducer, initialState);

  const fetchTagMatchings = async () => {
    try {
      const res = await fetch_tag_matchings();
      const arr = [];
      for(let id in res) {
        const tagMatching = {...res[id]};
        tagMatching.id = id;
        arr.push(tagMatching);
      }
      dispatch({
        type: SET_TAG_MATCHINGS,
        payload: arr
      });
    } catch (err) {
      console.log(err)
    }
  }

  const fetchSections = async () => {
    try {
      const res = await fetch_sections();
      const arr = [];
      for(let id in res) {
        const section = {...res[id]};
        section.id = id;
        arr.push(section);
      }
      dispatch({
        type: GET_SECTIONS,
        payload: arr
      });
    } catch (err) {
      console.log(err)
    }
  }

  // Create section
  const createSection = async (data, onSuccess = () => {}, onError = () => {}) => {
    try {
      const res = await create_section(data);
      const section = {...data, id: res.id};
      const sectionsArr = [...state.sections];
      sectionsArr.unshift(section);
      dispatch({
        type: SET_SECTIONS,
        payload: sectionsArr
      });
      onSuccess();
    } catch (err) {
      console.log(err);
      onError(err);
    }
  }

  // Update section
  const updateSection = async (id, data, onSuccess = () => {}, onError = () => {}) => {
    try {
      await update_section(data, id);
      const sections = [...state.sections];
      const findS = sections.find(s => s.id === id);
      const updatedS = {...findS, ...data};
      const updatedSections = sections.map(s => s.id === id ? updatedS : s);

      dispatch({
        type: SET_SECTIONS,
        payload: updatedSections
      });
      onSuccess(updatedS);
    } catch (err) {
      console.log(err);
      onError(err);
    }
  }

  // Delete section
  const deleteSection = async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      await delete_section(id);
      const sections = [...state.sections];
      const filtered = sections.filter(s => s.id !== id);
      dispatch({
        type: SET_SECTIONS,
        payload: filtered
      });
      onSuccess();
    } catch (err) {
      console.log(err);
      onError(err);
    }
  }

  return <SectionContext.Provider value={{
    sections: state.sections,
    sectionsFetched: state.sectionsFetched,
    tagMatchings: state.tagMatchings,
    tagMatchingsFetched: state.tagMatchingsFetched,
    fetchSections,
    createSection,
    updateSection,
    deleteSection,
    fetchTagMatchings,
  }}>
    {children}
  </SectionContext.Provider>
}

export default SectionsState;