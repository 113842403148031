import { GET_SIGNATURES, ADD_SIGNATURE, DELETE_SIGNATURE, SET_SIGNATURES } from '../types';

const signatureReducer = (state, action) => {
  switch(action.type) {
    case GET_SIGNATURES:
      return {
        ...state,
        signatures: action.payload,
        signaturesFetched: true
      }
    case SET_SIGNATURES:
      return {
        ...state,
        signatures: action.payload
      }
    case ADD_SIGNATURE:
      return {
        ...state,
        signatures: action.payload
      }
    case DELETE_SIGNATURE:
      return {
        ...state,
        signatures: action.payload
      }
    default:
      return state;
  }
}

export default signatureReducer;