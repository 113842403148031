import React from 'react'

const SenderModalContentAdd = ({ onCreate, onAssociate, onClose }) => {
  return (
    <div className=''>
      <div className='folder-modal-v2__heading'>
        <h2 className='modal-title flex-0'>Ajouter un compte expéditeur</h2>
        <ul className='flex flex-row justify-end gap-2 flex-wrap flex-1'>
          <li>
            <button
              className='btn btn--medium btn--transparent btn--border-primary'
              onClick={onClose}
            >
              Annuler
            </button>
          </li>
          <li>
            <button
              className='btn btn--large btn--primary'
              onClick={onCreate}
            >
              Créer un compte AR24
            </button>
          </li>
          <li>
            <button
              className='btn btn--large btn-primary'
              onClick={onAssociate}
            >
              Associer un compte AR24
            </button>
          </li>
        </ul>
      </div>
      <div className='folder-modal-v2__content'>
        <ul className='modal-list'>
          <li className='modal-list-item'>
            <p>
              Afin de pouvoir <b>envoyer des lettres recommandées électroniques</b> depuis Immo Docs, vous devez d'abord <b>ajouter un compte expéditeur AR24</b>.
            </p>
          </li>
          <li className='modal-list-item'>
            <p>
              Vous pouvez soit <b>créer un compte</b> expéditeur depuis Immo Docs, soit <b>associer un compte</b> expéditeur AR24 existant à Immo Docs.
            </p>
          </li>
        </ul>

      </div>
    </div>
  )
}

export default SenderModalContentAdd