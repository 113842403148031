import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

const Modal = ({ onClose, children, bg2, noPadding, small, className, large, xxLarge, medium, medium2, noBg }) => {
  const modalContent = useRef();
  let modalClasses = 'modal'; 

  if(className) {
    modalClasses += ` ${className}`;
  }

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    window.scrollTo(0,0);
    
    return () => {
      document.body.style.overflowY = null;
      // const modalRoot = document.getElementById('modal-root');
      // console.log(modalRoot)
      // if(modalRoot && modalRoot.children.length === 0) {
        // document.body.style.overflowY = null;
      // }
    }
  }, []);

  if(bg2) {
    modalClasses += ' modal--bg-2';
  }

  if(noPadding) {
    modalClasses += ' modal--no-padding';
  }

  if(small) {
    modalClasses += ' modal--small';
  }

  if(large) {
    modalClasses += ' modal--large';
  }

  if(xxLarge) {
    modalClasses += ' modal--xx-large';
  }

  if(medium) {
    modalClasses += ' modal--medium';
  }
  if(medium2) {
    modalClasses += ' modal--medium-2';
  }
  if(noBg) {
    modalClasses += ' modal--no-bg';
  }

  return ReactDOM.createPortal(
    <div className={modalClasses}>
      <div className="modal__inner">
        <div className="modal__content-wrapper">
          <div className="modal__bg" onClick={onClose}></div>
          <div className="modal__content" ref={modalContent}>
            {/* <div className="modal__content_close" onClick={onClose}><CloseIcon /></div> */}
            { children }
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  bg2: PropTypes.bool,
  noPadding: PropTypes.bool,
  small: PropTypes.bool,
}

export default Modal;