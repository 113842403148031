import { useState, memo } from 'react'
import { Document, Page } from 'react-pdf'
import { DoubleArrow } from '@material-ui/icons'

import { Close, Redo } from '../../assets/icons'
import { KeyboardDoubleArrowRight, Refresh } from '@mui/icons-material'

const SingleDocumentPreview = ({ data, loading, onPreviewRefresh, previewSettings = { size: 'normal', watermark: false }, onPreviewSettingsChange, hidePreviewInMobile, tabActive, onToggle }) => {
    const [docPages, setDocPages] = useState([])

    // On document load success
    const handleDocumentLoadSuccess = ({ numPages }) => {
        const arr = []
        for (let i = 1; i < numPages + 1; i++) {
            arr.push(<Page pageNumber={i} width={870} loading="" key={i} />)
        }

        setDocPages(arr)
    }

    // On document preview
    const handleRefreshPreview = () => {
        if (!onPreviewRefresh) return
        onPreviewRefresh()
    }

    return (
        <div className="single-document-preview">
            <div className="single-document-preview__head">
                <h2>
                    <span className="text">Aperçu</span>
                    <button className="ml-4 button button--with-icon button--primary-light rounded-sm refresh-btn" onClick={handleRefreshPreview} >
                        <Refresh fontSize='small' />
                        Actualiser
                    </button>
                    <button className="ml-auto button button--outline-primary-light rounded-sm fold-btn !px-3" onClick={onToggle}>
                        Replier
                        <KeyboardDoubleArrowRight fontSize={'small'} />
                    </button>
                    <button className="ml-auto fold-btn-mobile" onClick={onToggle}>
                        <Close />
                    </button>
                </h2>
                <button className="button button--primary-light rounded-sm refresh-btn-mobile" onClick={handleRefreshPreview} >
                    <Refresh fontSize='small' />
                    Actualiser
                </button>
            </div>
            <div className="single-document-preview__body">
                {loading ? (
                    <div className="single-document-preview__body_loader-wrapper">
                        <div className="loader-v2"></div>
                        <div className="mt-5">
                            <span>Création de l'aperçu en cours</span>
                        </div>
                    </div>
                ) : (
                    <Document
                        options={{}}
                        file={data}
                        onLoadSuccess={handleDocumentLoadSuccess}
                        renderMode="canvas"
                        loading="Chargement..."
                        noData="Une erreur est survenue, l'aperçu du document n'a pu être généré"
                    >
                        {docPages}
                    </Document>
                )}
            </div>
        </div>
    )
}

export default memo(SingleDocumentPreview)
