import React, { useState, useContext } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded';
import EditIcon from '@material-ui/icons/EditOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';

import Modal from '../../UI/Modal';
import IconButton from '../../UI/IconButton';
import Input from '../../UI/Input';
import ResponseLoader from '../../UI/ResponseLoader';
import Alert from '../../UI/Alert';
import { NotificationContext } from '../../../context/notifications/notificationState';
import { SignatureTemplatesContext } from '../../../context/signature_templates/signatureTemplatesState';
import config from '../../../config.json';

const SignatureTemplatesModal = ({ onClose, templates = [], doc, activeTemplate, onSetActiveTemplate, customSigners, signers, adminSigners, onSetFieldsPositions, onSetSignaturePositions, onSetSignaturePlaced }) => {
  return (
    <Modal onClose={onClose} medium>
      <div className="signature-templates-modal"> 
        <h3>Modèles de signature</h3>
        <ul>
          {templates.map(tmpl => (
            <TemplateItem 
              key={tmpl.id} 
              tmpl={tmpl} 
              activeTemplate={activeTemplate} 
              onSetActiveTemplate={onSetActiveTemplate} 
              customSigners={customSigners}
              signers={signers}
              adminSigners={adminSigners}
              onSetFieldsPositions={onSetFieldsPositions}
              onSetSignaturePositions={onSetSignaturePositions}
              onSetSignaturePlaced={onSetSignaturePlaced}
            />
          ))}
        </ul>
      </div>
    </Modal>
  );
}

const TemplateItem = ({ tmpl, activeTemplate, onSetActiveTemplate, customSigners, signers, adminSigners, onSetFieldsPositions, onSetSignaturePositions, onSetSignaturePlaced }) => {
  const { updateSignatureTemplate, deleteSignatureTemplate } = useContext(SignatureTemplatesContext);
  const { setNotification } = useContext(NotificationContext);
  const [name, setName] = useState('');
  const [mode, setMode] = useState('view');
  const [showResponseLoader, setShowResponseLoader] = useState(false);
  const [showDeleteTemplateAlert, setShowDeleteTemplateAlert] = useState(false);

  // Edit template - on edit icon click
  const editTemplate = () => {
    setMode('edit');
    setName(tmpl.name);
  }

  // Delete template
  const deleteTemplate = async () => {
    setShowResponseLoader(true);
    try {
      await deleteSignatureTemplate(tmpl.id);
      setNotification({ msg: 'Modèle supprimé', type: 'success' });
      if(activeTemplate === tmpl.id) {
        onSetActiveTemplate('');
        const sArr = config.environment === 'development' ? [...customSigners, ...signers, ...adminSigners] : [...signers, ...adminSigners];
  
        sArr.forEach(s => {
          if(s.position) s.position = [];
        });
        onSetFieldsPositions([]);
        onSetSignaturePositions([]);
        onSetSignaturePlaced(false);
      }
    } catch (err) {
      console.log(err);
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' });
    }
    setShowResponseLoader(false);
  }

  // Update template
  const updateTemplate = async () => {
    if(name.trim() === '') {
      return setNotification({ msg: 'Le nom est obligatoire', type: 'danger' });
    }
    if(name.trim() === tmpl.name) {
      return setNotification({ msg: 'Veuillez mettre à jour le nom du modèle', type: 'warning' });
    }
    setShowResponseLoader(true);
    try {
      await updateSignatureTemplate(tmpl.id, { name });
      setNotification({ msg: 'Modèle mis à jour', type: 'success' });
      setMode('view');
    } catch (err) {
      console.log(err);
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' });
    }
    setShowResponseLoader(false);
  }

  // Back to view mode
  const backToViewMode = () => {
    setName('');
    setMode('view');
  }

  return (
    <li>
      <div className="name">
        {mode === 'view' ? 
          <p>{tmpl.name}</p> 
          : 
          <div className="input-wrapper">
            <Input value={name} onChange={(e) => setName(e.target.value)} formEl />
          </div>
        }                
      </div>
      {mode === 'view' ? 
        <div className="actions">
          <IconButton icon={<EditIcon />} onButtonClick={editTemplate} primaryLight />
          <IconButton icon={<DeleteIcon />} onButtonClick={() => setShowDeleteTemplateAlert(true)} light className="delete-btn" />
        </div>
        :
        <div className="actions actions--2">
          <IconButton icon={<SaveIcon />} onButtonClick={updateTemplate} primaryLight />
          <IconButton icon={<CloseIcon />} onButtonClick={backToViewMode} light className="delete-btn" />
        </div>
      }

      {showDeleteTemplateAlert && <Alert 
        onClose={() => setShowDeleteTemplateAlert(false)}
        text="Êtes-vous sûr de vouloir supprimer ce modèle ?"
        onSubmit={deleteTemplate}
        deleteAlert
      />}
      {showResponseLoader && <ResponseLoader />}
    </li>
  );
}

export default SignatureTemplatesModal;