import React from 'react';
import PropTypes from 'prop-types';

const ActivityBox = ({ type, title, status, data, onClick }) => {
  let activityCssClass = 'activity-box';

  if(type === 'ready') {
    activityCssClass += ' ready';
  }
  if(type === 'draft') {
    activityCssClass += ' draft';
  }
  if(type === 'in-progress') {
    activityCssClass += ' in-progress';
  }
  if(type === 'closed') {
    activityCssClass += ' closed';
  }
  if(type === 'expired') {
    activityCssClass += ' expired';
  }
  if(type === 'canceled') {
    activityCssClass += ' canceled';
  }

  return(
    <div onClick={onClick} className={activityCssClass}>
      <p className="activity-box__title">{title}</p>
      <div className="activity-box__status">
        <span></span> {status}
      </div>
      <ul className="activity-box__list">
        {data.map((item, index) => (
          <li key={index}>
            <p>
              <span className="name">{item.name}</span>
              <span className="ac-status">{item.status}</span>
            </p>
            <p>
              <span className="date">{item.date}</span>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}

ActivityBox.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default ActivityBox;