import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import moment from 'moment'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'

import Loader from '../../UI/Loader'
import SupportPageLayout from '../../layouts/SupportPageLayout'
import { HelpContext } from '../../../context'
import { MainLayout } from '../../../layouts'
import { Eye, Download, Sort2 } from '../../../assets/icons'
import { ClickAwayListener } from '@mui/material'
import { ChevronLeft, ChevronRight, MoreVert } from '@mui/icons-material'
import { sortArrayOfObjects } from '../../../utils'
import DocumentationModal from '../../sections/support/DocumentaionModal'

const Documentation = ({ v2 }) => {
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const { documentationArticles, articlesFetched } = useContext(HelpContext)
  const [activeSort, setActiveSort] = useState({ 'title.fr': 'asc', activeItem: 'title.fr' });
  const [filteredDocs, setFilteredDocs] = useState([])
  const [showDocumentationModal, setShowDocumentationModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const history = useHistory()
  const [previewSrc, setPreviewSrc] = useState(null)

  // Category click handler
  const articleClickHandler = (e, article) => {
    e.preventDefault()
    setShowDocumentationModal(true);
    setSelectedArticle(article);
    if(article.attachments && article.attachments.length > 0) {
      setPreviewSrc(article.attachments[0].url)
    }
  }

  const sortOptions = {
    'title.fr': { label: 'Nom', asc: 'A > Z', desc: 'Z > A' },
    'created_at': { label: 'Date de création', asc: '+ ancienne', desc: ' + récente' },
  };

  // Handle sort
  const onSort = (sort_by, order) => {
    setActiveSort((prev) => ({ ...prev, [sort_by]: order, activeItem: sort_by }))
  }

  useEffect(() => {
    const docs = sortArrayOfObjects([...documentationArticles], activeSort.activeItem, activeSort[activeSort.activeItem]);
    setFilteredDocs(docs);
  }, [activeSort, documentationArticles])

  const hideDocumentationModal = () => {
    setShowDocumentationModal(false)
    setPreviewSrc(null)
  }

  return (

    <MainLayout className="page-help" pageTitle="Documents utiles" titleIcon={<Download />} fullHeader={true}>

      <SupportPageLayout title="Documents utiles" v2={v2}>
        <div className='filters-section-parent'>
          <ClickAwayListener onClickAway={() => {
            setShowSortDropdown(false)
          }
          }>
            <div className="relative ml-auto">
              <div
                className={`filters-section-sort !h-8 !max-w-full select-v2 select-v2--filled position-relative ml-auto ${showSortDropdown && 'active'}`}
              >
                <div className="flex md:px-3 h-8 items-center gap-2" onClick={() => setShowSortDropdown(!showSortDropdown)}>
                  <div className="flex gap-2">
                    <Sort2 />
                    <span className='sort-label sm-d-none'>
                      {sortOptions[activeSort.activeItem]?.label} {sortOptions[activeSort.activeItem]?.[activeSort[activeSort.activeItem]]}
                    </span>
                  </div>
                  <span className='chevron-icon' ><ChevronRight fontSize='small' /></span>
                </div>

              </div>
              {showSortDropdown && <div className={`sort-dropdown select-v2__body_dropdown align-right`}>
                {Object.entries(sortOptions).map((option, index) => (
                  <div key={index} className={`dropdown-item`} >
                    <p> {option[1].label} <ChevronRight fontSize={'small'} /> </p>
                    <ul>
                      <li
                        className={`dropdown-item ${activeSort[option[0]] === "asc" ? 'active' : ''}`}
                        onClick={() => {
                          onSort(option[0], 'asc')
                          setShowSortDropdown(false)
                        }}>
                        {option[1].asc}
                      </li>
                      <li
                        className={`dropdown-item ${activeSort[option[0]] === "desc" ? 'active' : ''}`}
                        onClick={() => {
                          onSort(option[0], 'desc')
                          setShowSortDropdown(false)
                        }}>
                        {option[1].desc}
                      </li>
                    </ul>
                  </div>
                ))}
              </div>}
            </div>
          </ClickAwayListener>
        </div >
        <div className="help-category">
          <div className="help-category__body">
            <ul className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              {filteredDocs.map((article, idx) => (
                <li key={idx}>
                  <a href="/#" onClick={(e) => articleClickHandler(e, article)} className="title mb-2"> {article.title.fr}</a>
                  <div className="description mb-2">{article.content?.fr?.blocks?.[0]?.text}</div>
                  {article.attachments && article.attachments.length > 0 && <div className="flex gap-1">
                    <button className="button button--outline-primary-light rounded-sm !px-2"
                      onClick={(e) => articleClickHandler(e, article)}>
                      <Eye />
                    </button>
                    <a className="button button--outline-primary-light rounded-sm !px-2" href={article.attachments[0].url} target="_blank" rel="noreferrer" download>
                      <Download /> Téléchargez
                    </a>
                  </div> }
                </li>
              ))}
            </ul>
            {filteredDocs.length === 0 && <div className="support-page__no-data"><p>{`Aucun document`}</p></div>}
          </div>
        </div>
        {showDocumentationModal && previewSrc && <DocumentationModal
          onClose={hideDocumentationModal}
          iframeSrc={previewSrc} />
        }
      </SupportPageLayout>
    </MainLayout>
  )
}

export default Documentation