import React, { useState } from 'react'
import { CheckCircle } from '../../../assets/icons';
import { NavLink, Link, useHistory } from 'react-router-dom';

const MailModalContentConfirmation = ({ onClose, data }) => {
  const [urlToDoc, setUrlToDoc] = useState('');
  const history = useHistory()
  console.log(data)
  // setUrlToDoc(data.response.data.members_links[0].url);

  const handleVerify = (e) => {
    e.preventDefault()
    history.push("/registered-mail/tracking")
  }

  return (
    <div className='registered-mail-send-modal-container'>
      <div className='registered-mail-send-modal-container__header'>
        <div className='folder-modal-v2__heading'>
          <h2>Envoi terminé</h2>
          <div className="buttons-wrapper">
            <button className='btn btn--medium btn--transparent btn--large' onClick={onClose}>Fermer</button>
            <a href="/" onClick={handleVerify} className="button height-40" target="_blank" rel="noopener noreferrer">Vérifier le document</a>
          </div>
        </div>
        <div className='folder-modal-v2__content'>
          <div className='sent-successful'>
            <CheckCircle />
            Le(s) document(s) ont bien été envoyé(s)
          </div>
          <p>Vous retrouverez le statut de chaque LRE envoyée :</p>
          <p>{" - en page d'accueil"}</p>
          <p>{" - dans la rubrique : Lettres recommandées > Suivi des LRE"}</p>
        </div>

      </div>

    </div>
  )
}

export default MailModalContentConfirmation