import React, { useEffect, useContext } from 'react';

import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';

import { FolderContext } from '../../context/folders/folderState';
import { DocumentsFoldersContext } from '../../context/documentsFolders/documentsFoldersState';
import { NotificationContext } from '../../context/notifications/notificationState';

const CreateFolderModal = ({ onClose, mode = 'create', onCreate, onEdit, currentFolder, foldersLoading, view = 'templates' }) => {
  const initialData = {
    name: {
      required: true,
      value: ''
    }
  };
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate);
  const { parentFolder, setParentFolder } = useContext(FolderContext);
  const { docParentFolder, setDocParentFolder } = useContext(DocumentsFoldersContext);
  const { setNotification } = useContext(NotificationContext);

  useEffect(() => {
    if(mode === 'edit') {
      setFormData(prev => ({
        ...prev,
        name: {
          ...prev.name,
          value: currentFolder.name
        }
      }));
    }
    // eslint-disable-next-line
  }, [currentFolder]);

  const submitHandler = async (e) => {
    e.preventDefault();
    
    const newErrors = validate(formData, true);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      const data = {
        name: formData.name.value.trim()
      }
      if(mode === 'create') {
        if(view === 'templates') {
          data.parentFolder = parentFolder ? parentFolder.id : null;
        }else{
          data.parentFolder = docParentFolder ? docParentFolder.id : null;
        }
        await onCreate(data);
        if(view === 'templates') {
          setParentFolder(null);
        } else {
          setDocParentFolder(null);
        } 
        setNotification({ msg: `Dossier "${data.name}" créé avec succès!`, type: 'success' });
      }else {
        onEdit(data, currentFolder.id);
      }
    }
  }

  return(
    <Modal onClose={() => { setParentFolder(null); onClose(); }} small>
      <div className="folder-modal">
        <h2>{mode === 'create' ? "Créer un dossier" : "Modifier le dossier"}</h2>
        <form onSubmit={submitHandler} className="form">
          <Input 
            name="name"
            value={formData.name.value}
            onChange={changeHandler}
            error={errors.name}
            placeholder="Indiquez le nom du dossier"
          />
          <div className="form__actions">
            <Button text="Annuler" transparent onButtonClick={() => { setParentFolder(null); onClose(); } } />
            <Button 
              type="submit" 
              text={foldersLoading ? mode === 'create' ? "Création..." : "Mise à jour..." : "Sauvegarder"} 
              primary 
              disabled={foldersLoading} 
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default CreateFolderModal;