import React from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const Button = ({ disabled, text, icon, dropArrow, primary, onButtonClick, type, outlinePrimary, outlinePrimaryLight, bold, small, xSmall, regular, medium, fullWidth, white, primaryLight, smallerIconMargin, transparent, style, secondaryLight, txtSmall, withPaddingY, danger, className, textUppercase, fontSmall, outlinePink }) => {
  let btnClasses = 'button';

  if(className) {
    btnClasses += ` ${className}`;
  }

  if(dropArrow) {
    btnClasses += ' button--drop-down';
  }
  if(primary) {
    btnClasses += ' button--primary';
  }
  if(outlinePrimary) {
    btnClasses += ' button--outline-primary';
  }
  if(outlinePrimaryLight) {
    btnClasses += ' button--outline-primary-light';
  }
  if(bold) {
    btnClasses += ' button--bold';
  }
  if(xSmall) {
    btnClasses += ' button--x-small';
  }
  if(small) {
    btnClasses += ' button--small';
  }
  if(regular) {
    btnClasses += ' button--regular';
  }
  if(medium) {
    btnClasses += ' button--medium';
  }
  if(fullWidth) {
    btnClasses += ' button--full-width';
  }
  if(white) {
    btnClasses += ' button--white';
  }
  if(primaryLight) {
    btnClasses += ' button--primary-light';
  }
  if(smallerIconMargin) {
    btnClasses += ' button--smaller-icon-margin';
  }
  if(transparent) {
    btnClasses += ' button--transparent';
  }
  if(secondaryLight) {
    btnClasses += ' button--secondary-light';
  }
  if(txtSmall) {
    btnClasses += ' button--txt-small';
  }
  if(withPaddingY) {
    btnClasses += ' button--with-padding-y';
  }
  if(danger) {
    btnClasses += ' button--danger';
  }
  if(textUppercase) {
    btnClasses += ' button--text-uppercase';
  }
  if(fontSmall) {
    btnClasses += ' button--font-small';
  }
  if(outlinePink) {
    btnClasses += ' button--outline-pink';
  }

  return(
    <button className={btnClasses} disabled={disabled} onClick={onButtonClick} type={type ? type : 'button'} style={style || {}}>
      {icon && <span className="icon">{icon}</span>}
      <span className="text">{text}</span>
      {dropArrow && <span className="arrow"><ArrowDropDownIcon /></span>}
    </button>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  dropArrow: PropTypes.bool,
  primary: PropTypes.bool,
  onButtonClick: PropTypes.func,
  type: PropTypes.string,
  outlinePrimary: PropTypes.bool,
  bold: PropTypes.bool,
  small: PropTypes.bool,
  regular: PropTypes.bool,
  medium: PropTypes.bool,
  fullWidth: PropTypes.bool,
  white: PropTypes.bool,
  primaryLight: PropTypes.bool,
  smallerIconMargin: PropTypes.bool,
  style: PropTypes.object
}

export default Button;