import React, { useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import CustomTooltip from '../UI/CustomTooltip';

import { UserContext } from '../../context/user/userState';
import { availableOn } from '../../utils';

const SideMenu = ({ onShowLongMenu }) => {
  const { showSignatures } = useContext(UserContext);

  const menuIconClickHandler = (e) => {
    e.preventDefault();
    onShowLongMenu(true);
  }

  return(
    <div className="side-menu">
      <ul className="side-menu__list">
        <li className="menu">
          <Link to="/" onClick={menuIconClickHandler}>
            <div className="logo">JS</div>  
          </Link>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content="Tableau de bord" position="right">
            <div>
              <NavLink to="/" exact><i className="custom-icon-dashboard"></i></NavLink>
            </div>
          </CustomTooltip>
          {/* <span className="tooltip">Tableau de bord</span> */}
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content="Documents" position="right">
            <div>
              <NavLink to="/documents" exact><DescriptionIcon /></NavLink>
            </div>
          </CustomTooltip>
          {/* <span className="tooltip">Documents</span> */}
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content="Modèles" position="right">
            <div>
              <NavLink to="/templates"><i className="custom-icon-template"></i></NavLink>
            </div>
          </CustomTooltip>
          {/* <span className="tooltip">Modèles</span> */}
        </li>
        {/* { signatureAvailable() && partner === 'jurisur' && <li className="tooltip-wrapper">
          <Tooltip text="Signatures" position="right" />
          <NavLink to="/signatures"><i className="custom-icon-create"></i></NavLink>
        </li>} */}
        {showSignatures && <li className="tooltip-wrapper">
          <CustomTooltip content="Signatures" position="right">
            <div>
              <NavLink to="/signatures"><i className="custom-icon-create"></i></NavLink>
            </div>
          </CustomTooltip>
        </li>}
        {/* <li className="tooltip-wrapper">
          <Tooltip text="Recommandés" position="right" />
          <NavLink to="/recommended" onClick={(e) => e.preventDefault()}><EmailOutlinedIcon /></NavLink>
        </li> */}
      </ul>

      <div className="side-menu__bottom">
        {availableOn(['development', 'staging']) && 
          <CustomTooltip content="Aide" position="right">
            <div>
              <NavLink to="/help" className="tooltip-wrapper">
                <HelpOutlineIcon />
              </NavLink>
            </div>
          </CustomTooltip>
        }
        <CustomTooltip content="Paramètres" position="right">
          <div>
            <NavLink to="/settings/my-profile" className="tooltip-wrapper">
              <SettingsOutlinedIcon />
              {/* <span className="tooltip">Paramètres</span> */}
            </NavLink>
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
}

export default SideMenu;