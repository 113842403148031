import React, { Fragment, useRef, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import PublishIcon from '@material-ui/icons/Publish';
import RestoreIcon from '@material-ui/icons/Restore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import LabelIcon from '@material-ui/icons/Label';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';

import Checkbox from '../UI/Checkbox';
import IconButton from '../UI/IconButton';
import DashboardHeadMeta from './DashboardHeadMeta';
import Dropdown from '../UI/Dropdown';
import CustomTooltip from '../UI/CustomTooltip';
import { create_document } from '../../services/firestore';
import { LoaderContext } from '../../context/loader/loaderState';
import { DocumentsContext } from '../../context/documents/documentsState';
import { NotificationContext } from '../../context/notifications/notificationState';
import { FolderContext } from '../../context/folders/folderState';
import { UserContext } from '../../context/user/userState';
import { folderHasSubfolders, folderHasTemplates, availableOn, canUseTemplateCategories } from '../../utils';
import config from '../../config.json';
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper';

const DashboardViewHead = ({ title, selectedDocuments, setSelectedDocuments, allChecked, onAllCheckedChange, showDropdown, onDropdownTrigger, setShowDropdown, columns, hideCreateAndUser, onSearch, onSidebarOpen, hideMoveBtn, hideDuplicateBtn, hideArchiveBtn, onSort, hideDownloadBtn, onMoveToFolder, onDownload, onDelete, searchValue, onSetSearch, onRestore, onArchive, removeSearchClose, onSetRemoveSearchClose, onSearchFormClear, onSetSelected, view, setViewTitle, breadcrumbs, onFilterTemplatesByFolders, onFolderCreate, onFolderEdit, onFolderDelete, onFolderMove, onMoveSelectedResources, onDeletePermanently, notFixed, dashboardView, showSectionsBtn, onSectionsOpen, onInjectModalOpen = () => {}, showSupportMenu }) => {
  const dropdownEl = useRef();
  const { setLoading } = useContext(LoaderContext)
  const { fetchDocuments, activeFilterColumn, currentFilter, templates } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { folders } = useContext(FolderContext)
  const { partner } = useContext(UserContext)
  const [searchActive, setSearchActive] = useState(false);
  const [showMoreFolders, setShowMoreFolders] = useState(false);
  const [showFolderActions, setShowFolderActions] = useState(false);
  const [shrinkHeader, setShrinkHeader] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', bodyScrollHandler);
    
    return () => {
      window.removeEventListener('scroll', bodyScrollHandler);
    }
  });

  const bodyScrollHandler = (e) => {
    if(notFixed) return
    
    if(window.scrollY > 200 && !shrinkHeader && !window.matchMedia("(max-width: 767px)").matches) {
      setShrinkHeader(true);
    }else if(window.scrollY <= 200 && shrinkHeader && !window.matchMedia("(max-width: 767px)").matches) {
      setShrinkHeader(false);
    }else if(window.matchMedia("(max-width: 767px)").matches && shrinkHeader){
      setShrinkHeader(false);
    }
  }

  // TODO - move to documents component
  const duplicateSelectedDocuments = async (e) => {
    e.preventDefault();
    setLoading(true)
    let fetchDocs = false;
    for(let i in selectedDocuments) {
      console.log('duplicate document', selectedDocuments[i])
      let doc = selectedDocuments[i]
      if(!doc.docFolder) {
        let docCopy = {
          name: `Copie de ${doc.name}`,
          progress: doc.progress,
          status: doc.status,
          template: doc.template,
          values: doc.values
        }
        await create_document(docCopy)
        fetchDocs = true;
      }
    }
    if(fetchDocs) {
      await fetchDocuments()
    }
    onSetSelected([])
    setLoading(false)
  }

  // Delete folder
  const deleteClickHandler = (folder) => {
    if(folderHasSubfolders(folders, folder)) {
      return setNotification({ msg: `Le dossier "${folder.name}" ne peut pas être supprimé car il n'est pas vide ! Il comporte des sous-dossiers.`, type: 'warning' });
    }
    if(folderHasTemplates(templates, folder)) {
      return setNotification({ msg: `Le dossier "${folder.name}" ne peut pas être supprimé car il n'est pas vide ! Il contient des modèles.`, type: 'warning' });
    }
    onFolderDelete(folder, true); 
  }

  let buttons = null;

  if(currentFilter === 'all') {
    buttons = (
      <Fragment>
        {/* inject data to template button */}
        {selectedDocuments.length === 1 && view === 'documents' &&
          <CustomTooltip content="Injecter dans un modèle" position="bottom">
            <a href="/#" onClick={(e) => {e.preventDefault(); onInjectModalOpen()}}>
              <CachedOutlinedIcon /> <span>Injecter</span>
            </a>
          </CustomTooltip>
        }

        {canUseTemplateCategories(partner) && showSectionsBtn && <a href="/#" onClick={onSectionsOpen}><AccountTreeIcon /> <span>Catégories</span></a>}
        {!hideDuplicateBtn && <a href="/#" onClick={duplicateSelectedDocuments}><FileCopyIcon /> <span>Dupliquer</span></a>}
        {!hideMoveBtn && <a href="/#" onClick={onMoveSelectedResources}>
          <i className="custom-icon-folder-move"><span className="path1"></span><span className="path2"></span></i> 
          <span>Déplacer</span>
        </a>}
        {!hideArchiveBtn && <a href="/#" onClick={onArchive}><ArchiveIcon /> <span>Archiver</span></a>}
        {!hideDownloadBtn && 
          <a href="/#" onClick={onDownload} className="rotate-180"><PublishIcon /> <span>Télécharger</span></a>
        }
        {view !== 'signatures' && <IconButton icon={<MoreVertIcon />} onButtonClick={onDropdownTrigger} />}
        {view !== 'signatures' && <Dropdown show={showDropdown} onChange={setShowDropdown} animate>
          <ul className="document-checked__right_dropdown" ref={dropdownEl}>
            <li onClick={onDelete}><DeleteIcon /> Supprimer</li>
          </ul>
        </Dropdown>}
      </Fragment>
    );
  }else if(currentFilter === 'archived'){
    buttons = (
      <Fragment>
        {/* {!hideDuplicateBtn && <a href="/#" onClick={duplicateSelectedDocuments}><FileCopyIcon /> <span>Dupliquer</span></a>} */}
        <a href="/#" onClick={onRestore}><RestoreIcon /> <span>Restaurer</span></a>
        <a href="/#" onClick={(e) => { e.preventDefault(); onDelete()}}><DeleteIcon /> <span>Supprimer</span></a>
      </Fragment>
    );
  }else if (currentFilter === 'deleted') {
    buttons = (
      <Fragment>
        <a href="/#" onClick={onDeletePermanently}><DeleteIcon /> <span>Supprimer définitivement</span></a>
        <a href="/#" onClick={onRestore}><RestoreIcon /> <span>Restaurer</span></a>
      </Fragment>
    );
  }

  let headerTopContent = null;

  if(breadcrumbs && breadcrumbs.length > 0) {
    if(breadcrumbs.length < 3) {
      headerTopContent = (
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item"><p onClick={() => onFilterTemplatesByFolders(null)}>Tous</p></li>
          {breadcrumbs.map((breadcrumb, i, arr) => {
            if( i < arr.length - 1 ) {
              return <li key={i} className="breadcrumbs__item">
                <ChevronRightIcon />
                <p onClick={() => onFilterTemplatesByFolders(breadcrumb)}>{breadcrumb.name}</p>
              </li>
            }else {
              return <li key={i} className="breadcrumbs__item breadcrumbs__item--last">
                <ChevronRightIcon /> 
                {breadcrumb.name} 
                <AdminFeatureWrapper rule="any_admin">
                  <IconButton icon={<ArrowDropDownIcon />} onButtonClick={() => setShowFolderActions(true)} dark />
                  <Dropdown show={showFolderActions} onChange={setShowFolderActions} className="more-actions" classToCheck="more-actions" animate>
                    <p onClick={(e) => onFolderCreate(e, breadcrumb)} className="create"><span><CreateNewFolderIcon /></span> Nouveau dossier</p>
                    <p onClick={() => onFolderEdit(breadcrumb)}><span><EditIcon /></span> Renommer</p>
                    <p onClick={() => onFolderMove(breadcrumb)} className="move"><span><SubdirectoryArrowLeftIcon /></span> Déplacer</p>
                    <p onClick={() => deleteClickHandler(breadcrumb)} className="delete"><span><DeleteForeverIcon /></span> Supprimer</p>
                  </Dropdown>
                </AdminFeatureWrapper>
              </li>
            }
          })}
        </ul>
      );
    }else {
      const lastTwoBreadcrumbs = breadcrumbs.slice(breadcrumbs.length - 2);
      const firstBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 2);
      
      headerTopContent = (
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item"><p onClick={() => onFilterTemplatesByFolders(null)}>Tous</p></li>
          <li className="breadcrumbs__item">
            <ChevronRightIcon />
            <IconButton icon={<MoreHorizIcon />} onButtonClick={() => setShowMoreFolders(true)} dark />
            <Dropdown show={showMoreFolders} onChange={setShowMoreFolders} animate className="more-folders" classToCheck="more-folders">
              <ul className="breadcrumbs__item_more">
                {firstBreadcrumbs.map((breadcrumb, i) => (
                  <li key={i} onClick={() => onFilterTemplatesByFolders(breadcrumb)}>{breadcrumb.name}</li>
                ))}
              </ul>
            </Dropdown>
          </li>
          {lastTwoBreadcrumbs.map((breadcrumb, i, arr) => {
            if( i < arr.length - 1 ) {
              return <li key={i} className="breadcrumbs__item">
                <ChevronRightIcon />
                <p onClick={() => onFilterTemplatesByFolders(breadcrumb)}>{breadcrumb.name}</p>
              </li>
            }else {
              return <li key={i} className="breadcrumbs__item breadcrumbs__item--last">
                <ChevronRightIcon /> 
                {breadcrumb.name} 
                <AdminFeatureWrapper rule="any_admin">
                  <IconButton icon={<ArrowDropDownIcon />} onButtonClick={() => setShowFolderActions(true)} dark />
                  <Dropdown show={showFolderActions} onChange={setShowFolderActions} className="more-actions" classToCheck="more-actions" animate>
                    <p onClick={(e) => onFolderCreate(e, breadcrumb)} className="create"><span><CreateNewFolderIcon /></span> Nouveau dossier</p>
                    <p onClick={() => onFolderEdit(breadcrumb)}><span><EditIcon /></span> Renommer</p>
                    <p onClick={() => onFolderMove(breadcrumb)} className="move"><span><SubdirectoryArrowLeftIcon /></span> Déplacer</p>
                    <p onClick={() => deleteClickHandler(breadcrumb)} className="delete"><span><DeleteForeverIcon /></span> Supprimer</p>
                  </Dropdown>
                </AdminFeatureWrapper>
              </li>
            }
          })}
        </ul>
      );
    }
  }else {
    headerTopContent = <Fragment>
      <span onClick={onSidebarOpen}><MenuIcon /></span> 
      {title === 'Archives' && 
        <Fragment>
          {title} 
          <CustomTooltip spaceSm position="bottom" content="Les documents archivés sont conservés pendant 30 jours, ils sont ensuite définitivement supprimés de Immo Docs." style={{maxWidth: '200px'}}><InfoIcon className="info" /></CustomTooltip>
        </Fragment>
      }
      {title === 'Supprimés' && 
        <Fragment>
          {title} 
          <CustomTooltip spaceSm position="bottom" content="Les documents supprimés sont conservés pendant 30 jours, ils sont ensuite définitivement supprimés de Immo Docs." style={{maxWidth: '200px'}}><InfoIcon className="info" /></CustomTooltip>
        </Fragment>
      }
      {(title !== 'Archives' && title !== 'Supprimés') && title}
    </Fragment>;
  }

  let headerClass = 'dashboard-view__head';
  if(searchActive) {
    headerClass += ' active';
  }
  if(notFixed) {
    headerClass += ' not-fixed';
  }

  return (
    <div className={headerClass}>
      <div className={shrinkHeader ? "dashboard-view__head_top-wrap shrink" : "dashboard-view__head_top-wrap"}>
        <div className="dashboard-view__head_top">
          <h4>{headerTopContent}</h4>
          <DashboardHeadMeta 
            hideCreateAndUser={hideCreateAndUser} 
            onSearch={onSearch} 
            searchValue={searchValue} 
            onSetSearch={onSetSearch} 
            removeSearchClose={removeSearchClose}
            onSetRemoveSearchClose={onSetRemoveSearchClose}
            onSearchFormClear={onSearchFormClear}
            setViewTitle={setViewTitle}
            onSearchSuggestionsChange={(val) => setSearchActive(val)}
            showSupportMenu={showSupportMenu}
          />
        </div>
      </div>

      <div className={`dashboard-view__head_bottom ${view !== 'templates' ? `fix-1 ${dashboardView ? 'fix-2' : ''}` : ''}`}>
        {
          selectedDocuments.length === 0
            ?
            <Fragment>
              {columns.map((column, index) => (
                <div key={index} className={`dashboard-column ${column.cssClass}`}>
                  <AdminFeatureWrapper rule="any_admin">
                    {column.all && <Checkbox checkedAll checked={allChecked} name="allChecked" onChange={onAllCheckedChange} />}
                  </AdminFeatureWrapper>
                  
                  <div className={column.active ? "sort active" : "sort"} onClick={() => onSort(column.value, column.order === 'desc' ? 'asc' : 'desc')}>
                    {column.label} {column.active ? column.order === 'desc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon /> : null}
                  </div>
                  
                </div>
              ))}
            </Fragment>
            :
            <div className="document-checked">
              <div className="document-checked__left">
                <AdminFeatureWrapper  rule="any_admin">
                  <Checkbox checkedAll checked={allChecked} name="allChecked" onChange={onAllCheckedChange} />
                </AdminFeatureWrapper>
                <p>{selectedDocuments.length} élément(s) sélectionné(s)</p>
              </div>
              <div className="document-checked__right">
                {buttons}
              </div>
            </div>
        }
      </div>
    </div>
  );
}

DashboardViewHead.propTypes = {
  title: PropTypes.string.isRequired,
  selectedDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  allChecked: PropTypes.bool.isRequired,
  onAllCheckedChange: PropTypes.func.isRequired,
  showDropdown: PropTypes.bool.isRequired,
  onDropdownTrigger: PropTypes.func.isRequired,
  setShowDropdown: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideCreateAndUser: PropTypes.bool,
  onSearch: PropTypes.func
}

export default DashboardViewHead;