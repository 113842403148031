import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

import Modal from '../../UI/Modal';
import ModelsRow from '../../UI/ModelsRow';
import TemplatesSidebar from '../templates/TemplatesSidebar';
import { sortedArrayFromObject, sorterWithPathAndOrder } from '../../../utils'
import { DocumentsContext } from '../../../context/documents/documentsState';

const DocumentCreateModal = ({ onClose, folders, onFolderCreate, onFolderDelete, onFolderEdit }) => {
  let history = useHistory();
  const { templates } = useContext(DocumentsContext);
  const [templatesArray, setTemplatesArray] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [selectedTitle, setSelectedTitle] = useState('Tous');
  const [filteredTemplates, setFilteredTemplates] = useState({});

  useEffect(() => {
    const filtered = filterNonDeletedTemplates(templates);
    setFilteredTemplates(filtered);
    processTemplates(filtered);
  }, [templates]);

  const processTemplates = async (t) => {
    let tmplts = sortedArrayFromObject(t, sorterWithPathAndOrder('name', 'desc'))
    for(let i in tmplts) {
      tmplts[i].title = tmplts[i].name
    }
    setTemplatesArray(tmplts)
  }

  const filterNonDeletedTemplates = (tmplts) => {
    const filtered = {};
    for(let key in tmplts) {
      if(!tmplts[key].deleted) {
        filtered[key] = tmplts[key];
      }
    }
    return filtered;
  }

  const createDocument = (template) => () => {
    history.push({
      pathname: `/template/${template.id}`,
      state: { templateId: template.id }
    })
    
  }

  // Filter templates by folders
  const filterByFoldersHandler = (folder) => {
    const t = {...filteredTemplates};
    const filtered = {};
    if(folder) {
      for(let key in t) {
        if(t[key].folderId.includes(folder.id)) {
          filtered[key] = t[key];
        }
      }
      processTemplates(filtered);
      setSelectedFilter(folder.id);
      setSelectedTitle(folder.name);
    }else {
      processTemplates(t);
      setSelectedFilter('all');
      setSelectedTitle('Tous');
    }
  }

  return(
    <Modal onClose={onClose} noPadding>
      <div className="document-create-modal">
        <div className="document-create-modal__left">
          <TemplatesSidebar 
            folders={folders}
            onFolderEdit={onFolderEdit} 
            onFolderCreate={onFolderCreate}
            onFolderDelete={onFolderDelete}
            onFolderFilter={filterByFoldersHandler}
            selectedFilter={selectedFilter}
          />
        </div>
        <div className="document-create-modal__right">
          <div className="dashboard-view">
            <div className="dashboard-view__head static">
              <div className="dashboard-view__head_top">
                <h4>{selectedTitle}</h4>
              </div>
            </div>

            <div className="dashboard-view__body no-padding-top">
              { templatesArray.map((template, templateIndex) => {
                return <ModelsRow 
                  key={`template_${template.id}`}
                  title={template.title} 
                  onDetailView={createDocument(template)}
                  onButtonClick={createDocument(template)}
                />
              })}              
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

DocumentCreateModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default DocumentCreateModal;