import React, { useState, useEffect } from 'react'
import { SearchOutlined, PersonAddOutlined, Home, Phone, AlternateEmail, Edit } from '@material-ui/icons'

import Modal from '../../UI/Modal'
import Input from '../../UI/Input'
import Loader from '../../UI/Loader'
import CustomTooltip from '../../UI/CustomTooltip'

const dummyData = [
  {
    prefix: 'Maître',
    name: 'Caroline MONIEZ-BENEZET',
    address: '10 rue Royale 75008 Paris',
    email: 'caroline.moniez.75053@paris.notaires.fr',
    phone: '+33142603037'
  },
  {
    prefix: 'Maître',
    name: 'Florence GAULT',
    address: '3 rue Rossini 75009 Paris',
    email: 'florence.gault.75008@paris.notaires.fr',
    phone: '+33144799260'
  },
  {
    prefix: 'Maître',
    name: 'Virginie VALENTE',
    address: '3 rue Rossini 75009 Paris',
    email: 'virginie.valente.75008@paris.notaires.fr',
    phone: '+33144799260'
  },
  {
    prefix: 'Maître',
    name: 'Amandine BARRE',
    address: '297 rue de Vaugirard 75015 Paris',
    email: 'etude.vaugirard297@paris.notaires.fr',
    phone: '+33153681810'
  },
]

const NotariesModal = ({ onClose, onOpenAddEditNotaryModal, onSetSelectedNotary }) => {
  const [search, setSearch] = useState('')
  const [notaries, setNotaries] = useState([])
  const [filteredNotaries, setFilteredNotaries] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 500)
  }, [])

  useEffect(() => {
    setNotaries(dummyData)
    setFilteredNotaries(dummyData)
  }, [])

  // On search
  const searchChangeHandler = (e) => {
    const { value } = e.target
    setSearch(value)
    let filtered = []
    if(value.trim() !== '') {
      const val = value.trim().toLowerCase()
      notaries.forEach(n => {
        if(n.name.toLowerCase().includes(val) || n.email.toLowerCase().includes(val)) {
          filtered.push(n)
        }
      })
      setFilteredNotaries(filtered)
      return 
    }
    setFilteredNotaries(notaries)
  }

  // On notary select
  const notarySelectHandler = (notary) => {
    console.log(notary)
  }

  // On edit button click
  const notaryEditClickHandler = (notary) => {
    onOpenAddEditNotaryModal('edit')
    onSetSelectedNotary(notary)
  }

  return (
    <Modal onClose={onClose}>
      <div className="notaries-modal">
        <div className="notaries-modal__head">
          <h2>Rechercher un notaire</h2>
        </div>
        <div className="notaries-modal__search">
          <Input iconEl={<SearchOutlined />} value={search} onChange={searchChangeHandler} placeholder="Saisir les premières lettres d'un notaire ou d'une adresse" formEl />
          <CustomTooltip content="Ajouter un notaire" position="bottom-right">
            <button className="icon-button" onClick={() => onOpenAddEditNotaryModal('add')}>
              <PersonAddOutlined />
            </button>
          </CustomTooltip>
        </div>
        {loading 
          ? <div className="loader-wrapper"><Loader small primary normal /></div>
          : 
          <div className="notaries-modal__body">
            <div className="notaries-modal__body_boxes">
              {filteredNotaries.map((notary, i) => {
                return (
                  <div className="notary-box" key={i}>
                    <h4>{notary.prefix} {notary.name}</h4>
                    <div className="address"><Home /> {notary.address}</div>
                    <div className="phone-email">
                      <p><Phone /> {notary.phone}</p>
                      <p><AlternateEmail /> {notary.email}</p>
                    </div>
                    <div className="overlay" onClick={() => notarySelectHandler(notary)}></div>
                    <CustomTooltip content="Modifier" position="left">
                      <button className="icon-button edit-btn" onClick={() => notaryEditClickHandler(notary)}>
                        <Edit />
                      </button>
                    </CustomTooltip>
                  </div>
                )
              })}
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}

export default NotariesModal