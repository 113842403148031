import { useContext } from 'react'

import { DocumentsContext, NotificationContext, LoaderContext } from '../context'

const useTemplatesActions = () => {
  const { updateTemplate, updateStandardTemplate } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  
  // Move template to folder
  const moveTemplateToFolder = async ({ folder, template, view = 'templates' }) => {
    let tmplt = {...template}
    if(!tmplt.folderId) {
      tmplt = {...template, folderId: []}
    }

    try {
      if(folder) {
        if(!tmplt.folderId.includes(folder.id)) {
          setShowGlobalResponseLoader(true)
          setGlobalResponseLoaderText('Déplacement du modèle')
          const foldersArr = []
          foldersArr.push(folder.id)
          if(view === 'templates') {
            await updateTemplate({ folderId: foldersArr }, tmplt.id)
          } else if(view === 'standard-templates') {
            await updateStandardTemplate({ folderId: foldersArr }, tmplt.id)
          }
          setNotification({ msg: `Le modèle "${tmplt.name}" a été déplacé dans le dossier "${folder.name}"!`, type: 'default' })
        } else {
          setNotification({ msg: `Le modèle "${tmplt.name}" est déjà dans le dossier "${folder.name}"!`, type: 'warning' })
        }
      }else {
        if(tmplt.folderId.length === 0) {
          return setNotification({ msg: `Impossible de déplacer le modèle "${template.name}" vers le dossier racine`, type: 'warning' })
        }
        setShowGlobalResponseLoader(true)
        setGlobalResponseLoaderText('Déplacement du modèle')
        if(view === 'templates') {
          await updateTemplate({ folderId: [] }, tmplt.id)
        } else if(view === 'standard-templates') {
          await updateStandardTemplate({ folderId: [] }, tmplt.id)
        }
        setNotification({ msg: `Le modèle "${tmplt.name}" a été déplacé dans le dossier racine!`, type: 'default' })
      }
    } catch (err) {
      console.log(err)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  return {
    moveTemplateToFolder,
  }
}

export default useTemplatesActions