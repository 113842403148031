import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxCheckedIcon from '@material-ui/icons/CheckBox';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckedIcon from '@material-ui/icons/IndeterminateCheckBox';

const Checkbox = ({ id, name, onChange, checked, checkedAll, disabled, label }) => {
  return(
    <div className="checkbox">
      <label htmlFor={id}>
        <input type="checkbox" name={name} id={id} onChange={onChange} checked={checked} disabled={disabled} />
        <div className={checked ? "checkbox__btn checked" : "checkbox__btn unchecked"}>
          {checked ? checkedAll ? <IndeterminateCheckedIcon /> : <CheckBoxCheckedIcon /> : <CheckBoxIcon />}
        </div>
        {label && <div className="checkbox__label">{label}</div>}
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  checkedAll: PropTypes.bool,
}

export default Checkbox;