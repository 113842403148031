import React from 'react'
import { CheckCircle } from '../../../assets/icons';

const SenderModalContentResendSuccess = ({ onClose }) => {

  return (
    <div className=''>
      <div className='folder-modal-v2__heading'>
        <h2 className='modal-title'>Modifier le compte expéditeur</h2>
        <ul className='flex flex-row justify-end gap-2'>
          <li>
            <button
              className='btn btn--medium btn--transparent btn--border-primary'
              onClick={onClose}
            >
              Fermer
            </button>
          </li>
        </ul>
      </div>
      <div className='folder-modal-v2__content'>
        <div className='sent-successful'>
          <CheckCircle />
          La demande a été transmise
        </div>
        <ul className='modal-list'>
          <li className='modal-list-item'>
            <p>
              Vous allez recevoir un e-mail de la part d'AR24 un e-mail vous invitant à <b>confirmer l'association de votre compte expéditeur</b> à Immo Docs.
            </p>
          </li>
          <li className='modal-list-item'>
            <p>
              Dès validation, et si votre compte expéditeur est vérifié, les utilisateurs liés à votre agence pourront envoyer des lettres recommandées électroniques depuis Immo Docs.
            </p>
          </li>
          <li className='modal-list-item'>
            <p>
              Il vous sera possible à tout moment de révoquer l'association de votre compte AR24 à Immo Docs sur le site <b><a target="_blank" without rel="noreferrer" href="https://ar24.fr">ar24.fr</a></b>.
            </p>
          </li>
        </ul>
      </div>

    </div>
  )
}

export default SenderModalContentResendSuccess