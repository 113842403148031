import { useContext } from 'react'
import { isIOS, isMobile } from 'react-device-detect';
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import { get_signature_status } from '../services/firestore'
import { SignatureContext, NotificationContext, LoaderContext, UserContext } from '../context'
import { get_signature_document, delete_signature, push_to_immocloud } from '../services/firestore'
import { base64toBlob } from '../utils'

const useSignaturesActions = () => {
  const { updateSignature, deleteSignature } = useContext(SignatureContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowDashboardOverlay, setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { immocloudConfig } = useContext(UserContext)

  // Get recipients status
  const getRecipientsStatus = async ({ signature, setActiveSignature, setSingleStatusLoading }) => {
    setActiveSignature(null)
    setSingleStatusLoading(true)
    try {
      const res = await get_signature_status({ PACKAGE_ID: [signature.package_id] })
      if(res.response.status) {
        const s = {...signature}
        s.users = res.response.data[0].users.slice(1) // remove first user, because pierre-jean(ac3 developer) is included in all responses
        setActiveSignature(s)
        if(signature.status !== res.response.data[0].status_provider) {
          await updateSignature(s.id, { status: res.response.data[0].status_provider })
        }
      }else {
        const message = res.response?.message && Array.isArray(res.response?.message) ? res.response.message[0] : res.response?.message || "Aucune donnée reçue"
        throw new Error(message)
      }
      setSingleStatusLoading(false)
    } catch (err) {
      console.log(err)
      setSingleStatusLoading(false)
      setActiveSignature(signature)
      const errorMessage = err.message || ""
      setNotification({ msg: `Erreur API Onespan${errorMessage ? ` : ${errorMessage}` : ''}`, type: 'danger' })
    }
  }

  // Refresh status
  const refreshStatus = async ({ signature, setRefreshing }) => {
    setRefreshing(true)
    setShowDashboardOverlay(true)
    try {
      const res = await get_signature_status({ PACKAGE_ID: [signature.package_id] })
      if(res.response.status) {
        const s = {...signature}
        await updateSignature(s.id, { status: res.response.data[0].status_provider, status_updated_at: Date.now() })
        setNotification({ msg: "L'état de la signature a été actualisé avec succès", type: "success" })
      }else {
        const message = res.response?.message && Array.isArray(res.response?.message) ? res.response?.message[0] : res.response?.message || "Aucune donnée reçue"
        throw new Error(message)
      }
      setRefreshing(false)
    } catch (err) {
      console.log(err)
      setRefreshing(false)
      const errorMessage = err.message || ""
      setNotification({ msg: `Erreur API Onespan${errorMessage ? ` : ${errorMessage}` : ''}`, type: 'danger' })
    } finally {
      setShowDashboardOverlay(false)
    }
  }

  // Download selected signatures
  const downloadSelectedSignatures = async ({ selectedSignatures, setSelectedSignatures, setAllChecked }) => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Préparation des signatures pour le téléchargement')
    if(selectedSignatures.length === 1) {
      const doc = selectedSignatures[0]
      const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id })
      if(res.response.status) {
        if(res.response.data) {
          if(isIOS) {
            const blob = base64toBlob(res.response.data)
            const a = document.createElement('a')
            a.onclick = saveAs(blob, `${doc.title || 'document'}.pdf`)
          }else {
            const a = document.createElement("a")
            a.href = `data:application/pdf;base64,${res.response.data}`
            a.download = `${doc.title}.pdf`
            a.click()
          }
        } else if(res.response.url) {
          const a = document.createElement("a")
          a.href = res.response.url
          a.click()
        }
      }
    }else {
      const zip = new JSZip()
      let docNames = []
      for(let i in selectedSignatures) {
        const doc = selectedSignatures[i]
        const res = await get_signature_document({ document_id: doc.document_id, package_id: doc.package_id })
        if(res.response.status) {
          if(res.response.data) {
            let name = `${doc.title}.pdf`
            zip.file(
              docNames.includes(name) ? `${doc.title}(${i}).pdf` : name, 
              res.response.data, 
              {base64: true}
            )
            docNames.push(`${doc.title}.pdf`)
          }
        }
      }
      docNames = []

      zip.generateAsync({type:"blob"}).then(function(content) {
        saveAs(content, "signatures.zip")
      })
    }

    setSelectedSignatures([])
    setAllChecked(false)
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
  }

  // Delete selected signatures
  const deleteSelectedSignatures = async ({ selectedSignatures, setSelectedSignatures, setAllChecked }) => {
    const promises = []
    const ids = []

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Suppression des signatures sélectionnées')

    selectedSignatures.forEach(doc => {
      promises.push(delete_signature(doc.id))
      ids.push(doc.id)
    })
    
    Promise.all(promises)
      .then(() => {
        deleteSignature(ids)
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
        setSelectedSignatures([])
        setAllChecked(false)
      })
      .catch(err => {
        console.log(err)
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
        
        const errorMessage = err.message || ""
        setNotification({ msg: `Erreur API Onespan${errorMessage ? ` : ${errorMessage}` : ''}`, type: 'danger' })
      })
  }

  // Preview signature
  const previewSignature = async ({ signature, setPreviewLoading, setShowPreviewDocModal, setPreviewError, setIframeSrc }) => {
    if(isMobile) return 
    
    setPreviewLoading(true)
    setShowPreviewDocModal(true)
    console.log('get signature preview', signature)
    try {
      const res = await get_signature_document({ document_id: signature.document_id, package_id: signature.package_id });
      if(res.response.status) {
        if(res.response.data) {
          setIframeSrc(`data:application/pdf;base64,${res.response.data}`);
        } else if(res.response.url) {
          setIframeSrc(res.response.url);
        }
      }else {
        setPreviewError(res.response.message.join(''));
      }
      setPreviewLoading(false);
    } catch (err) {
      setPreviewLoading(false);
      const errorMessage = err.message || ""
      setNotification({ msg: `Erreur API Onespan${errorMessage ? ` : ${errorMessage}` : ''}`, type: 'danger' })
    }
  }

  const fetchSignaturePreview = async (signature) => {
    try {
      const res = await get_signature_document({ document_id: signature.document_id, package_id: signature.package_id });
      console.log(res)
      if(res.response.status) {
        if(res.response.data) {
          return { base64: res.response.data }
        } else if(res.response.url) {
          return { url: res.response.url }
        }
      }else {
        return { error: res.response.message.join('') }
      }
    } catch (err) {
      return { error: err }
    }
  }

  // Upload to cloud
  const uploadToCloud = async ({ signature }) => {
    const res = await get_signature_document({ document_id: signature.document_id, package_id: signature.package_id })
    if(res.response && res.response.status) {
      const fileBase64 = res.response.data 
      const response = await push_to_immocloud(fileBase64, `${signature.title}.pdf`, immocloudConfig.site_id, immocloudConfig.handle, immocloudConfig.id)

      if(response.error) {
        let message = ''
        if(response.error.error && response.error.error.product_id) {
          message = 'Aucun bien lié à ce document. Le document doit être créé depuis le logiciel Immofacile'
        } else if(response.error.error) {
          for(let key in response.error.error) {
            if(typeof response.error.error[key] === 'object') {
              for(let i in response.error.error[key]) {
                if(message) {
                  message += '\n'
                }
                message += response.error.error[key][i]
              }
            } else if(typeof response.error.error[key] === 'string') {
              if(message) {
                message += '\n'
              }
              message += response.error.error[key]
            }
          }
        }
        setNotification({ msg: message, type: 'danger' })
      } else {
        setNotification({ msg: "Le document a été téléchargé avec succès", type: 'danger' })
      }
    }else {
      const errorMessage = res.response?.message && Array.isArray(res.response?.message) ? res.response.message[0] : res.response?.message || "Aucune donnée reçue"
      setNotification({ msg: `Erreur API Onespan${errorMessage ? ` : ${errorMessage}` : ''}`, type: 'danger' })
    }
  }

  return {
    getRecipientsStatus,
    refreshStatus,
    downloadSelectedSignatures,
    deleteSelectedSignatures,
    previewSignature,
    uploadToCloud,
    fetchSignaturePreview
  }
}

export default useSignaturesActions