import React, { useRef, useState } from 'react';

import RecommendedSidebar from '../sections/recommended/RecommendedSidebar';
import RecommendedView from '../sections/recommended/RecommendedView';

const Recommended = () => {
  const dashboardSidebar = useRef();
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const sidebarOpenHandler = () => {
    dashboardSidebar.current.classList.add('opened');
  }

  const sidebarCloseHandler = () => {
    dashboardSidebar.current.classList.remove('opened');
  }

  return(
    <div className="dashboard-inner">
      <div className="dashboard-inner__left" ref={dashboardSidebar}>
        <div className="dashboard-inner__left_overlay" onClick={sidebarCloseHandler}></div>
        <div className="dashboard-inner__left_sidebar">
          <RecommendedSidebar />
        </div>
      </div>
      <div className="dashboard-inner__right">
        <RecommendedView onSidebarOpen={sidebarOpenHandler} selected={selectedDocuments} onSetSelected={setSelectedDocuments} />
      </div>
    </div>
  );
}

export default Recommended;