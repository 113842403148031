import React from 'react' 

import { MainLayout } from '../layouts'

const Help = () => {
  return (
    <MainLayout className="page-help" pageTitle="Aide">
      <div className="dashboard-page__body dashboard-page__body--help">
        <iframe title="Aide" className="airtable-embed" src="https://airtable.com/embed/shraZOeMXqpjiE4on?backgroundColor=blue&layout=card" frameBorder="0"></iframe>
      </div>
    </MainLayout>
  )
}

export default Help 