import { Document, pdfjs } from 'react-pdf'
import { AngleRight, Redo, AngleLeft, Close } from '../../assets/icons'
import { DoubleArrow } from '@material-ui/icons'
import { KeyboardDoubleArrowRight, Refresh } from '@mui/icons-material'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CoverPagePreview = ({ opened = false, onToggle, previewPage, data, onDocumentLoadSuccess, loading, onPreviewRefresh, onClose }) => {

  // On refresh click
  const handleRefreshPreview = () => {
    if (!onPreviewRefresh) return
    onPreviewRefresh()
  }

  return (
    <div className={`cover-page-preview ${opened ? 'opened' : ''}`}>
      <div className="cover-page-preview__inner">
        <div className="cover-page-preview__head">
          <h2>
            <span className="text">Aperçu</span>
            <button className="ml-4 button button--with-icon button--primary-light rounded-sm refresh-btn" onClick={handleRefreshPreview} >
              <Refresh fontSize='small' />
              Actualiser
            </button>
            <button className="ml-auto button button--outline-primary-light rounded-sm fold-btn !px-3" onClick={onToggle}>
              Replier
              <KeyboardDoubleArrowRight fontSize={'small'} />
            </button>
            <button className="ml-auto fold-btn-mobile" onClick={onToggle}>
              <Close />
            </button>
          </h2>
          <button className="button button--primary-light rounded-sm refresh-btn-mobile" onClick={handleRefreshPreview} >
            <Refresh fontSize='small' />
            Actualiser
          </button>
        </div>
        <div className="cover-page-preview__body">
          {loading ? (
            <div className="loader-v2"></div>
          ) : (
            <Document file={data} onLoadSuccess={onDocumentLoadSuccess} renderMode="canvas" loading="Chargement...">
              {previewPage}
            </Document>
          )}
        </div>
      </div>
    </div>
  )
}

export default CoverPagePreview