import { useState } from 'react'
import { ClickAwayListener } from '@material-ui/core'
import { ArrowDropDown } from '../../assets/icons'
import CustomTooltip from './CustomTooltip'

const Multiselect = ({ label, valueToClear = 'all', onChange, values = [], placeholder, className, options = [] }) => {

  const [showDropdown, setShowDropdown] = useState(false)

  // On item click
  const handleItemClick = (val) => { 
    let value = values
    if(val === valueToClear) {
      value = []
    } else if(values.includes(val)) {
      value = values.filter(v => v !== val)
    } else {
      value.push(val)
    }
    onChange(value)
    if(val === valueToClear) setShowDropdown(false)
  }

  const limitTextLength = (text, limit) => {
    if(text.length > limit) {
      return text.substring(0, limit) + '...'
    }
    return text
  }

  // On click away
  const handleClickAway = () => {
    if(showDropdown) setShowDropdown(false)
  }

  // On toggle dropdown
  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  const labelMaxLength = 20

  const currentLabel = limitTextLength(options.filter(o => values.includes(o.value)).map(o => o.label).join(', '), labelMaxLength) || placeholder
  const currentLabelFull = options.filter(o => values.includes(o.value)).map(o => o.label).join(', ') || placeholder


  return (
    <div className={`select-v2 ${className ? className : ''} select-v2--default`}>
      {label && <div className="select-v2__label">{label}</div>}
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`select-v2__body ${showDropdown ? 'opened' : ''}`}>          
          <div className="select-v2__body_selected" onClick={handleToggleDropdown}>
            {currentLabel.length > labelMaxLength ? (
              <CustomTooltip content={currentLabelFull}><p>{currentLabel}</p></CustomTooltip>
            ) : (
              <p>{currentLabel}</p>
            )}
            <ArrowDropDown />
          </div>
          {showDropdown && (
            <div className={`select-v2__body_dropdown u-custom-scrollbar--3`}>
              {options.map((option, index) => (
                <Option key={index} value={option.value} selected={values.includes(option.value)} onClick={() => handleItemClick(option.value)}>{option.label}</Option>
              ))}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  )
}

export const Option = ({ children, ...props }) => {
  return (
    <div className={`dropdown-item ${children === '' ? 'dropdown-item--empty' : ''} ${props.selected ? 'selected' : ''}`} {...props}>
      <p>{children}</p>
    </div>
  )
}

export default Multiselect
