import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = ({ v2 }) => {
  return(
    <div className="not-found">
      <h1>Page non trouvée</h1>
      <p><Link to={v2 ? '/v2' : '/'} className="button button--primary"><span className="text">Retour au tableau de bord</span></Link></p>
    </div>
  );
}

export default NotFound;