import React from 'react';

import { SIGNATURE_STATUS_TRANLSATIONS } from '../../constants';

const Status = ({ type, onClick }) => {
  let statusClass = 'status';
  let statusTxt = SIGNATURE_STATUS_TRANLSATIONS[type];

  if(type === 'COMPLETED') {
    statusClass += ' status--signed';
  }else if(type === 'SIGNING_PENDING' || type === 'DELETED' || type === 'IN_PROGRESS') {
    statusClass += ' status--not-signed';
  }else {
    statusClass += ' ';
  }

  return(
    <div className={statusClass} onClick={onClick}>
      {statusTxt}
    </div>
  );
}

export default Status;