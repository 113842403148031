import { memo } from 'react'
import { Edit, DeleteOutlined } from '@material-ui/icons'

import { Pen, Delete } from '../../assets/icons'
import { Input, Checkbox, CustomTooltip } from '../ui_new'

const SingleDocumentSignCustomFields = memo(({ placeSignatureHandler, fieldsPositions, updateFieldRequiredProp, updateLabel, removeLabel, deleteCustomField, handleEditCheckboxPosition }) => {
  return (
    <div className="signature-modal-v2-inner__section signature-modal-v2-inner__section--checkbox">
      <p className="signature-form__subtitle signature-form__subtitle--3">Cases a cocher</p>
      <div className="fields-wrapper fields-wrapper--2">
        <button className="btn" type="button" onClick={(e) => placeSignatureHandler(e, `checkbox-${Date.now()}`, 'checkbox')}>Ajouter une case à cocher</button>
        {fieldsPositions.map((field, i) => (
          <div className="field-positions-v2" key={i}>
            <div className="field-positions-v2__page">{'Page'} {+field.page + 1}</div>
            <div className="checkbox-wrapper">
              <div className="checkbox-wrapper__field">
                <div className="checkbox-wrapper__field_fix">
                  <Checkbox 
                    onChange={(e) => updateFieldRequiredProp(e.target.checked, field.id)}
                    checked={field.required}
                    label="Requis"
                  />
                </div>
              </div>
              <CustomTooltip content={'Changer la position de la case à cocher'}>
                <div className="eidt-btn-wrapper">
                  <button className="icon-btn" type="button" onClick={() => handleEditCheckboxPosition(field)}><Pen /></button>
                </div>
              </CustomTooltip>
            </div>
            <div className="field-positions-v2__divider"></div>
            {(!field.label || (field.label && Object.keys(field.label).length === 0)) && (
              <button className="btn" type="button" onClick={(e) => placeSignatureHandler(e, `label-${Date.now()}`, 'label', field.id)}>Ajouter un label</button>
            )}
            {field.label && Object.keys(field.label).length > 0 && 
              <div className="checkbox-label">
                <Input value={field.label.value} onChange={(e) => updateLabel(e, field.id, field.label.id)} placeholder={'Nommer le label'} />
                <CustomTooltip content={'Changer la position du label'}>
                  <div className="edit-btn-wrapper">
                    <button className="icon-btn" type="button" onClick={(e) => placeSignatureHandler(e, field.label.id, 'label', field.id)}><Pen /></button>
                  </div>
                </CustomTooltip>
                <CustomTooltip content={'Supprimer label'}>
                  <button className="icon-btn" type="button" onClick={() => removeLabel(field.id)}><Delete /></button>
                </CustomTooltip>
              </div>
            }
            {field.error && <p className="field-positions-v2__error">{field.error}</p>}
            <CustomTooltip content={'Supprimer la case à cocher et son label'}>
              <div className="field-positions-v2__remove">
                <button className="icon-btn" type="button" onClick={() => deleteCustomField(field.id)}><Delete /></button>
              </div>
            </CustomTooltip>
          </div>
        ))}
      </div>
    </div>
  )
})

export default SingleDocumentSignCustomFields