import React, { createContext, useReducer, useState } from 'react'

import {
    SET_USER,
    SET_PARTNER,
    SET_ADMINS,
    SET_VERIFIED_CUSTOMERS,
    SET_SITE_CONFIGS,
    SET_CAN_USE_SIGNATURES,
    SET_SITE_CONFIGS_LOADED,
    SET_IMMOCLOUD_CONFIG,
    SET_CONTACTS,
    SET_USER_CLAIMS,
    SET_AGENCY,
    SET_SIGNIN_RESULT,
} from '../types'
import userReducer from './userReducer'
import { sortArrayOfObjects, availableOn, manufacturerProperty } from '../../utils'
import config from '../../config.json'
import { fetch_agency } from '../../services/firestore'

export const UserContext = createContext()

const UserState = ({ children }) => {
    const initialState = {
        user: {},
        partner: '',
        contacts: {},
        admins: [],
        verified_customers: [],
        site_configs: [],
        canUseSignatures: false,
        siteConfigsLoaded: false,
        immocloudConfig: {},
        userClaims: {},
        signInResult: null,
        agency: null,
    }
    const [authenticationToken, setAuthenticationToken] = useState(null)
    const [urlStore, setUrlStore] = useState(null)
    const [isNotSQHorCAIpartner, setIsNotSQHorCAIpartner] = useState(false)
    const [signatureCredentials, setSignatureCredentials] = useState(null)
    const [showSignatures, setShowSignatures] = useState(false)
    const [userAgencies, setUserAgencies] = useState([])
    const [userAgenciesFetched, setUserAgenciesFetched] = useState(false)
    const [state, dispatch] = useReducer(userReducer, initialState)
    const [isScroll, setIsScroll] = useState(false)
    const [hideActionBar, setHideActionBar] = useState(false)

    const setUser = (value) => {
        dispatch({
            type: SET_USER,
            payload: value,
        })
    }

    const setPartner = (value) => {
        dispatch({
            type: SET_PARTNER,
            payload: value,
        })
    }

    const setContacts = (value) => {
        // const sortedContacts = sortArrayOfObjects(value, 'co_customers_lastname', 'desc');
        dispatch({
            type: SET_CONTACTS,
            payload: value,
        })
    }

    const setVerifiedCustomers = (value) => {
        const sortedCustomers = sortArrayOfObjects(value, 'lastname', 'asc')
        dispatch({
            type: SET_VERIFIED_CUSTOMERS,
            payload: sortedCustomers,
        })
    }

    const setAdmins = (value) => {
        const sortedAdmins = sortArrayOfObjects(value, 'lastname', 'asc')
        dispatch({
            type: SET_ADMINS,
            payload: sortedAdmins,
        })
    }

    const setSiteConfigs = (value) => {
        dispatch({
            type: SET_SITE_CONFIGS,
            payload: value,
        })
        const storeEsignConnect = value.find(
            (i) => i.configuration_key === 'STORE_ESIGN_CONNECT'
        )
        // TODO put back
        // if (storeEsignConnect) {
        //     setCanUseSignatures(storeEsignConnect)
        // }
        setCanUseSignatures({configuration_value: 'Oui'})
    }

    const setCanUseSignatures = (value) => {
        if (availableOn(['development', 'staging'])) {
            console.log('can use signatures', value)
        }
        let canUse = false
        if (value.configuration_value !== 'Non') {
            canUse = true
        }
        dispatch({
            type: SET_CAN_USE_SIGNATURES,
            payload: config.environment === 'development' ? true : canUse
        })
    }

    const setCanUseSignaturesForMLSPartner = () => {
        dispatch({
            type: SET_CAN_USE_SIGNATURES,
            payload: true,
        })
    }

    const setSiteConfigsLoaded = (value) => {
        dispatch({
            type: SET_SITE_CONFIGS_LOADED,
            payload: value,
        })
    }

    const setImmocloudConfig = (value) => {
        dispatch({
            type: SET_IMMOCLOUD_CONFIG,
            payload: value,
        })
    }

    const setUserClaims = (value) => {
        dispatch({
            type: SET_USER_CLAIMS,
            payload: value,
        })
    }

    const getAgency = async () => {
        if (!state.user.manufacturer) {
            return
        }
        let data = await fetch_agency(manufacturerProperty(state.user?.manufacturer, 'id'))
        dispatch({
            type: SET_AGENCY,
            payload: data,
        })
    }

    const setAgency = (value) => {
        dispatch({
            type: SET_AGENCY,
            payload: value,
        })
    }

    const setSignInResult = (value) => {
        dispatch({
            type: SET_SIGNIN_RESULT,
            payload: value,
        })
    }

    return (
        <UserContext.Provider
            value={{
                user: state.user,
                partner: state.partner,
                contacts: state.contacts,
                admins: state.admins,
                agency: state.agency,
                verified_customers: state.verified_customers,
                siteConfigsLoaded: state.siteConfigsLoaded,
                siteConfigs: state.site_configs,
                canUseSignatures: state.canUseSignatures,
                immocloudConfig: state.immocloudConfig,
                authenticationToken,
                userClaims: state.userClaims,
                urlStore,
                isNotSQHorCAIpartner,
                signatureCredentials,
                showSignatures,
                signInResult: state.signInResult,
                userAgencies,
                userAgenciesFetched,
                isScroll,
                hideActionBar,
                setIsScroll,
                setHideActionBar,
                setUser,
                setPartner,
                setContacts,
                setAdmins,
                setVerifiedCustomers,
                setSiteConfigs,
                setSiteConfigsLoaded,
                setImmocloudConfig,
                setAuthenticationToken,
                setUserClaims,
                setUrlStore,
                setIsNotSQHorCAIpartner,
                setSignatureCredentials,
                setShowSignatures,
                getAgency,
                setAgency,
                setSignInResult,
                setUserAgencies,
                setUserAgenciesFetched,
                setCanUseSignaturesForMLSPartner,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export default UserState
