import React, { useEffect } from 'react';
import { Modal, Input } from '../../ui_new'
import useForm from '../../../hooks/useForm';
import validate from '../../../helpers/validate';

const EditTemplateModal = ({ onClose, onEdit, currentTemplate, templatesLoading }) => {
  const initialData = {
    name: {
      required: true,
      value: ''
    }
  };
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      name: {
        ...prev.name,
        value: currentTemplate.name
      }
    }));
    // eslint-disable-next-line
  }, [currentTemplate]);

  const submitHandler = (e) => {
    e.preventDefault();

    const newErrors = validate(formData, true);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const data = {
        name: formData.name.value.trim()
      }
      if (data.name !== '') {
        onEdit(data, currentTemplate.id);
      }
    }
  }

  return (
    <Modal onClose={onClose} className="modal-v2--medium-3">
      <div className="folder-modal-v2">
        <form onSubmit={submitHandler} className="form">
          <div className='folder-modal-v2__heading'>
            <h2>Renommer le modèle</h2>
            <div className="flex gap-2">
              <button className='btn btn--medium btn--transparent' onClick={onClose}>Annuler</button>
              <button className="button height-40" type="submit" disabled={templatesLoading}>
                {templatesLoading ? "Mise à jour..." : "Sauvegarder"}
              </button>
            </div>
          </div>
          <div className='folder-modal-v2__content'>
            <label for="name" class="label">Nom du document</label>
            <Input
              name="name"
              value={formData.name.value}
              onChange={changeHandler}
              error={errors.name}
              placeholder="Indiquez le titre du modèle"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default EditTemplateModal;