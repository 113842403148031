import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import SideMenu from '../sections/SideMenu';
import SideMenuLong from '../sections/SideMenuLong';
import { LoaderContext } from '../../context/loader/loaderState';
import LineLoader from '../UI/LineLoader';
import Footer from '../sections/Footer';

const DashboardLayout = ({ children }) => {
  const [showLongMenu, setShowLongMenu] = useState(false);
  const { loading, showDashboardOverlay, setShowDashboardOverlay } = useContext(LoaderContext);

  return( 
    <div className="dashboard">
      {loading && <LineLoader />}
      {loading && <div className="overlay"></div>}
      <div className="dashboard__content">
        <SideMenu onShowLongMenu={() => setShowLongMenu(true)} />
        <SideMenuLong onShowLongMenu={() => setShowLongMenu(false)} active={showLongMenu} />
        {/* {showDashboardOverlay && <div className="dashboard__content_overlay" onClick={() => setShowDashboardOverlay(false)}></div>} */}
        {children}
        <Footer />
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node
}

export default DashboardLayout;