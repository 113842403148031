import React, { useContext } from 'react'
import { UserContext } from '../../context/user/userState';

const Footer = () => {

  const { user } = useContext(UserContext)
  return (
    <div className="footer">
      <span className="footer--label">
        { `© 2005-${new Date().getFullYear()} `}
        <a href="https://www.ac3-groupe.com/" target="_blank" rel="noreferrer" className="footer--link">{ 'AC3 Groupe' }</a>
      </span>
      <span className="footer--label">
        { user.firstname ? `${user.firstname} ${user.lastname}` : ''}
      </span>
      <span className="footer--label">
        <a href="https://www.ac3-groupe.com/mentions-legales/" target="_blank" rel="noreferrer" className="footer--link">{ 'Mentions légales' }</a>
        { ' - ' }
        <a href="https://realestate.orisha.com/app/uploads/2024/01/Conditions-generales-de-vente.pdf" target="_blank" rel="noreferrer" className="footer--link">{ 'Conditions générales de ventes' }</a>
      </span>
    </div>
  )
}

export default Footer