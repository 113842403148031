import React, { useState, useEffect } from 'react'

import CustomSelect from '../../../UI/CustomSelect'

const VerifiedContactDropdown = ({ verifiedContactsDropdownOptions, signer, index, verifiedContactsChangeHandler }) => {
  const [options, setOptions] = useState(verifiedContactsDropdownOptions)
  const [active, setActive] = useState(verifiedContactsDropdownOptions[0])

  useEffect(() => {
    setOptions(verifiedContactsDropdownOptions)
    if(signer.name.value === '') {
      setActive({ label: 'Sélectionnez un destinataire *', value: '' })
    }else {
      setActive({ value: signer.id, label: `${signer.name.value} ${signer.lastname.value}` })
    }
    // eslint-disable-next-line
  }, [verifiedContactsDropdownOptions])

  const changeHandler = (option) => {
    setActive(option)
    verifiedContactsChangeHandler(option, signer, index)
  }

  return <div className="form__group form__group--signature-modal form__group--dropdown">
    <CustomSelect 
      options={options}
      active={active}
      onChange={(option) => changeHandler(option)}
      search
      formEl
    />  
  </div>
}

export default VerifiedContactDropdown