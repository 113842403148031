import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { MainLayout } from '../layouts'
import { DashboardActions, DashboardContentBox } from '../components/misc'
import { DocumentsContext, UserContext, SignatureContext, RegisteredMailContext } from '../context'
import { sortedArrayFromObject, sorterWithPathAndOrder, sortArrayOfObjects, availableOn, isFeatureEnabled, FEATURE, searchArrayWithProperties } from '../utils'
import { Alert } from '../components/ui_new'
import { Home } from '../assets/icons'

const TEMPLATE_INFORMATION_SHOWN_KEY = '_jurisur_template_info_23-02-2023'

const Dashboard = () => {
  const { documents, documentsLoaded, setDocsSelectedFilters, dashboardSearch } = useContext(DocumentsContext)
  const { partner, canUseSignatures, siteConfigsLoaded, siteConfigs, user } = useContext(UserContext)
  const { signatures, signaturesFetched, setSignaturesSelectedFilters } = useContext(SignatureContext)
  const { ar24MailFilter, setAr24MailFilter, ar24Mail, ar24MailFetched } = useContext(RegisteredMailContext)
  const [draftDocs, setDraftDocs] = useState([])
  const [readyDocs, setReadyDocs] = useState([])
  const [inProgressDocs, setInProgressDocs] = useState([])
  const [closedDocs, setClosedDocs] = useState([])
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [docsReady, setDocsReady] = useState(false)
  const [signaturesReady, setSignaturesReady] = useState(false)
  const date = moment()
  const history = useHistory()

  const [mailReady, setMailReady] = useState(false)
  const [sentMail, setSentMail] = useState([])
  const [acceptedMail, setAcceptedMail] = useState([])
  const [expiredMail, setExpiredMail] = useState([])

  // Set documents - draft and ready
  useEffect(() => {
    if (documentsLoaded) {
      const copyOfDocuments = { ...documents }
      const filteredDocuments = {}
      for (let key in copyOfDocuments) {
        filteredDocuments[key] = copyOfDocuments[key]
      }
      let sort = 'meta.created'
      let order = 'asc'
      let docsArr = sortedArrayFromObject(filteredDocuments, sorterWithPathAndOrder(sort, order))
      setDraftDocs(searchArrayWithProperties([...docsArr].filter(doc => doc.status === 'draft'), dashboardSearch, ['name']))
      setReadyDocs(searchArrayWithProperties([...docsArr].filter(doc => doc.status === 'ready'), dashboardSearch, ['name']))
      setDocsReady(true)
    }
    // eslint-disable-next-line
  }, [documents, documentsLoaded, dashboardSearch])

  // Set documents(signatures) - in progress and closed
  useEffect(() => {
    if (canUseSignatures) {
      if (signaturesFetched) {
        const signaturesArr = [...signatures]
        const closed = signaturesArr.filter(s => s.status === 'COMPLETED')
        const inProgress = signaturesArr.filter(s => s.status === 'IN_PROGRESS')
        let sort = 'title'
        let order = 'desc'
        const closedArr = sortArrayOfObjects(closed, sort, order)
        const inProgressArr = sortArrayOfObjects(inProgress, sort, order)

        setClosedDocs(searchArrayWithProperties(closedArr, dashboardSearch, ['title']))
        setInProgressDocs(searchArrayWithProperties(inProgressArr, dashboardSearch, ['title']))
        setSignaturesReady(true)
      }
    }
    // eslint-disable-next-line
  }, [signatures, signaturesFetched, canUseSignatures, siteConfigsLoaded, dashboardSearch])

  // Set mail - sent, received and expired
  useEffect(() => {
    if (ar24MailFetched) {
      let sort = 'meta.created'
      let order = 'asc'
      let docsArr = sortArrayOfObjects(ar24Mail, sort, order)
      const sentStatuses = ['waiting', 'dp', 'ev']
      const acceptedStatuses = ['AR']
      const expiredStatuses = ['refused', 'negligence', 'bounced', 'fail', 'cancelled_by_user', 'cancelled_after_7_days']
      setSentMail(searchArrayWithProperties([...docsArr].filter(doc => sentStatuses.includes(doc.status)), dashboardSearch, ['document_name', 'from_name', 'to_name']))
      setAcceptedMail(searchArrayWithProperties([...docsArr].filter(doc => acceptedStatuses.includes(doc.status)), dashboardSearch, ['document_name', 'from_name', 'to_name']))
      setExpiredMail(searchArrayWithProperties([...docsArr].filter(doc => expiredStatuses.includes(doc.status)), dashboardSearch, ['document_name', 'from_name', 'to_name']))
      setMailReady(true)
    }
    // eslint-disable-next-line
  }, [ar24Mail, ar24MailFetched, dashboardSearch])

  // On title click
  const handleTitleClick = (route, type, status) => {
    if (type === 'documents') {
      setDocsSelectedFilters(prev => ({ ...prev, status }))
    } else if (type === 'documents') {
      setDocsSelectedFilters(prev => ({ ...prev, status }))
    } else if (type === 'signatures') {
      setSignaturesSelectedFilters(prev => ({ ...prev, status }))
    } else if (type === 'signatures') {
      setSignaturesSelectedFilters(prev => ({ ...prev, status }))
    } else if (type === 'registered_mail') {
      setAr24MailFilter({ ...ar24MailFilter, status: status })
    }
    history.push(route)
  }

  return (
    <MainLayout className="page-dashboard" pageTitle={`Bonjour ${user.firstname} !`} fullSidebar={true} fullHeader={true} titleIcon={<Home />} showSearch={true} searchIn="dashboard">
      <div className="dashboard-content">
        {selectedDocuments.length > 0 && (
          <div className="dashboard-content__actions">
            <DashboardActions selectedDocuments={selectedDocuments} onSetSelectedDocuments={setSelectedDocuments} />
          </div>
        )}

        <div className={`dashboard-content__boxes-wrapper`}>
          <div className="dashboard-content__boxes">
            <div className='column-parent'>
              <div className="dashboard-content__stats">
                <div>
                  Contrats
                  <span>{draftDocs.length + readyDocs.length} documents</span>
                </div>
              </div>
              <div className='container-element'>
                {/* Draft */}
                <div className={`column ${selectedDocuments.length > 0 && 'less-height'}`}>

                  <DashboardContentBox
                    title="Brouillon"
                    documents={draftDocs}
                    onSetSelectedDocuments={setSelectedDocuments}
                    selectedDocuments={selectedDocuments}
                    loading={!docsReady}
                    onRedirect={() => handleTitleClick('/documents', 'documents', 'draft')}
                  />
                </div>

                {/* Ready to send */}
                <div className={`column ${selectedDocuments.length > 0 && 'less-height'}`}>
                  <DashboardContentBox
                    title="Complété"
                    documents={readyDocs}
                    onSetSelectedDocuments={setSelectedDocuments}
                    selectedDocuments={selectedDocuments}
                    loading={!docsReady}
                    onRedirect={() => handleTitleClick('/documents', 'documents', 'ready')}
                  />
                </div>
              </div>
            </div>
            <div className='column-parent'>
              <div className="dashboard-content__stats">
                <div>
                  Signatures
                  <span>{inProgressDocs.length + closedDocs.length} documents</span>
                </div>
              </div>
              <div className='container-element'>
                {/* In progress */}
                <div className={`column ${selectedDocuments.length > 0 && 'less-height'}`}>
                  <DashboardContentBox
                    title="Cérémonie en cours"
                    documents={inProgressDocs}
                    onSetSelectedDocuments={setSelectedDocuments}
                    selectedDocuments={selectedDocuments}
                    type="signatures"
                    loading={!signaturesReady}
                    onRedirect={() => handleTitleClick('/signatures', 'signatures', 'IN_PROGRESS')}
                  />
                </div>
                {/* Closed */}
                <div className={`column ${selectedDocuments.length > 0 && 'less-height'}`}>
                  <DashboardContentBox
                    title="Cérémonie clôturée"
                    documents={closedDocs}
                    onSetSelectedDocuments={setSelectedDocuments}
                    selectedDocuments={selectedDocuments}
                    type="signatures"
                    loading={!signaturesReady}
                    onRedirect={() => handleTitleClick('/signatures', 'signatures', 'COMPLETED')}
                  />
                </div>
              </div>
            </div>
            <div className='column-parent'>

              <div className="dashboard-content__stats">
                <div>
                  Recommandés
                  <span>{sentMail.length + acceptedMail.length + expiredMail.length} documents</span>
                </div>
              </div>
              <div className='container-element'>
                {isFeatureEnabled(FEATURE.AR24, siteConfigs) &&
                  <div className={`column ${selectedDocuments.length > 0 && 'less-height'}`}>
                    <DashboardContentBox
                      title="LRE envoyée/reçue"
                      documents={sentMail}
                      onSetSelectedDocuments={setSelectedDocuments}
                      selectedDocuments={selectedDocuments}
                      type="registered_mail"
                      loading={!mailReady}
                      onRedirect={() => handleTitleClick('/registered-mail/tracking', 'registered_mail', ['waiting', 'ev'])}
                    />
                  </div>
                }
                {isFeatureEnabled(FEATURE.AR24, siteConfigs) && <div className={`column ${selectedDocuments.length > 0 && 'less-height'}`}>
                  <DashboardContentBox
                    title="LRE Acceptées"
                    documents={acceptedMail}
                    onSetSelectedDocuments={setSelectedDocuments}
                    selectedDocuments={selectedDocuments}
                    type="registered_mail"
                    loading={!mailReady}
                    onRedirect={() => handleTitleClick('/registered-mail/tracking', 'registered_mail', ['AR'])}
                    halfHeight={true}

                  />
                  <DashboardContentBox
                    title="LRE négligée/refusée"
                    documents={expiredMail}
                    onSetSelectedDocuments={setSelectedDocuments}
                    selectedDocuments={selectedDocuments}
                    type="registered_mail"
                    loading={!mailReady}
                    onRedirect={() => handleTitleClick('/registered-mail/tracking', 'registered_mail', ['negligence', 'refused'])}
                    halfHeight={true}
                  />
                </div>}
              </div>
            </div>


          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Dashboard