import { useState, useEffect, useContext } from 'react'

import { Modal } from '../ui_new'
import { fetch_site_manufacturers } from '../../services/firestore'
import { UserContext } from '../../context'

const AgencyModal = ({ onClose, onSetDocValues, token }) => {
  const { user, setUser, userAgencies, userAgenciesFetched, setUserAgencies, setUserAgenciesFetched } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [agencies, setAgencies] = useState([])
  const [isReady, setIsReady] = useState(false) 

  // Fetch agencies
  useEffect(() => {
    if(userAgenciesFetched && !isReady) {
      setAgencies(userAgencies)
      setIsReady(true)
      setLoading(false)
    }else {
      if(!isReady) {
        const fetchData = async () => {
          try {
            const res = await fetch_site_manufacturers(token)
            if(res && Array.isArray(res)) {
              // console.log(res)
              setAgencies(res)
              setUserAgencies(res)
              setUserAgenciesFetched(true)
            }
          } catch (err) {
            console.log(err)
          }
          setLoading(false)
        }
        fetchData()
      }
    }
  }, [token, userAgenciesFetched, setUserAgenciesFetched, isReady, setUserAgencies, userAgencies]) 

  // On agency button click
  const onAgencySelect = (agency) => {
    setUser({
      ...user,
      manufacturer: agency
    })
    onSetDocValues(prev => ({
      ...prev,
      manufacturers_name: agency.name || '',
      // manufacturers_contact_firstname: '',
      // manufacturers_contact_name: '',
      adresse: agency.address || '',
      postal_code: agency.postal_code || '',
      city: agency.city || '',
      telephone: agency.phone || '',
      email: agency.email || '',
      manufacturers_description: agency.description || '',
      // admin_fonction: ''
    }))
    onClose()
  }

  return (
    <Modal onClose={onClose} className="modal-v2--no-padding">
      <div className="agency-modal-v2">
        <div className="agency-modal-v2__head">
          <h2>Changer les coordonnées de l'agence</h2>
        </div>
        <div className="agency-modal-v2__body">
          {loading 
            ? <div className="loader-wrapper"><div className="loader-v2"></div></div>
            : (
              <div className="agency-modal-v2__body_boxes">
                {agencies.map((agency, i) => {
                  return (
                    <div className="agency-box-v2" key={i}>
                      <div className="agency-box-v2__head">
                        <button className="btn" onClick={() => onAgencySelect(agency)}>Sélectionner</button>
                      </div>
                      <div className="agency-box-v2__body">
                        <p><strong>Agence:</strong> {agency.name}</p>
                        <p><strong>Adresse siège:</strong> {agency.address}</p>
                        <p><strong>Numéro de carte:</strong> </p>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  )
}

export default AgencyModal