import { useContext } from 'react'

import { Input, Modal } from '../ui_new'
import { NotificationContext } from '../../context'

const SaveUpdatedSignatureTemplateModal = ({ onClose, onTemplateSave, name, onSetName, onSetActiveTemplate }) => {
  const { setNotification } = useContext(NotificationContext)

  const submitHandler = async (e) => {
    if(name.trim() === '') {
      return setNotification({ msg: 'Le nom est obligatoire', type: 'danger' })
    }
    try {
      const id = await onTemplateSave(e)
      if(id) {
        onSetActiveTemplate(id)
        onClose()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal onClose={onClose} className="modal-v2--medium">
      <div className="signature-templates-modal-v2"> 
        <h3>Sauvegarder le modèle mis à jour</h3>
        <form onSubmit={submitHandler}>
          <Input value={name} onChange={(e) => onSetName(e.target.value)} formEl placeholder="Nom du modèle" />
          <button className="btn" type="submit">Sauvegarder</button>
        </form>
      </div>
    </Modal>
  );
}

export default SaveUpdatedSignatureTemplateModal