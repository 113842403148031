import React, { useState, useEffect, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import DeleteIcon from '@material-ui/icons/Delete'

import useForm from '../../../hooks/useForm'
import validate from '../../../helpers/validate'
import Modal from '../../UI/Modal'
import Input from '../../UI/Input'
import Button from '../../UI/Button'
import QRCode from 'qrcode.react'
import { sendEmail } from '../../../services/functions'
import { update_document } from '../../../services/firestore'
import LineLoader from '../../UI/LineLoader'
import Tooltip from '../../UI/Tooltip'
import { NotificationContext } from '../../../context/notifications/notificationState'
import { UserContext } from '../../../context/user/userState'
import { Editor } from '@tinymce/tinymce-react'
import Checkbox from '../../UI/Checkbox'
import { urlSuffixForEnvironment } from '../../../utils'

import config from '../../../config.json'

const SendByEmailModal = ({
    onClose,
    documentId,
    fetchDocumentData,
    documentName,
    attachments,
    onAttachmentShareUpdate,
    onAttachmentsShareSave,
    doc,
    fetchDocuments,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [sentEmailId] = useState(uuidv4())
    const initialData = {
        email: {
            value: '',
            email: true,
        },
        'recipientEmail-1': {
            value: '',
            email: true,
            name: 'recipientEmail-1',
        },
        subject: {
            value: '',
        },
        message: {
            value: `
        <p>Bonjour,</p>

        <p>Vous trouverez ci-dessous le lien pour télécharger le document "${documentName}" pour relecture.</p>
        
        <p class="link">https://immodocs-cloud.web.app/document_v2${urlSuffixForEnvironment(
            config.environment
        )}/${documentId}/${sentEmailId}</p>
        
        <p>Nous restons à votre écoute pour toutes corrections et/ou modifications éventuelles.</p>
        
        <p>Cordialement,</p>
      `,
        },
    }
    const { changeHandler, formData, setFormData, errors, setErrors } =
        useForm(initialData, validate)
    const [view, setView] = useState('email')
    const [recipientEmails, setRecipientEmails] = useState({})
    const { setNotification } = useContext(NotificationContext)
    const { partner } = useContext(UserContext)
    const [addWatermark, setAddWatermark] = useState(false)
    const [addWatermarkToLink, setAddWatermarkToLink] = useState(false)

    const handleEditorChange = (content, editor) => {
        setFormData((prevState) => ({
            ...prevState,
            message: {
                value: content,
                touched: true,
            },
        }))
    }

    useEffect(() => {
        const copyOfData = { ...formData }
        const recipients = {}
        for (let key in copyOfData) {
            if (key.includes('recipientEmail-')) {
                recipients[key] = copyOfData[key]
            }
        }
        setRecipientEmails(recipients)
    }, [formData])

    const submitHandler = async (e) => {
        e.preventDefault()
        const newErrors = validate(formData, true)
        setErrors(newErrors)
        if (Object.keys(newErrors).length === 0) {
            setIsLoading(true)
            // send email message
            try {
                console.log('sende email with data', formData)
                const documentData = await fetchDocumentData()
                const emails = Object.values({ ...recipientEmails }).map(
                    (email) => email.value
                )
                const message = formData.message.value
                await sendEmail(
                    formData.email.value,
                    emails,
                    formData.subject.value,
                    message,
                    documentName,
                    documentData,
                    []
                )
                const sentEmails = doc.sentEmails ? [...doc.sentEmails] : []
                const sharedAttachments = [...attachments].filter(
                    (att) => att.share
                )
                console.log(sharedAttachments)
                console.log(sentEmails)
                sentEmails.push({
                    id: sentEmailId,
                    sender: formData.email.value,
                    attachments: sharedAttachments,
                    watermark: addWatermark,
                })
                await update_document(
                    documentId,
                    { sentEmails },
                    attachments
                )
                await fetchDocuments()
                setIsLoading(false)
                onClose()
                setNotification({
                    msg: 'Message envoyé !',
                    type: 'success',
                })
            } catch (err) {
                setIsLoading(false)
                setNotification({
                    msg: 'Une erreur est survenue, merci de réessayer',
                    type: 'danger',
                })
            }
        }
    }

    const copyToClipboardHandler = (text) => {
        const input = document.createElement('textarea')
        input.innerHTML = text
        document.body.appendChild(input)
        input.select()
        const result = document.execCommand('copy')
        document.body.removeChild(input)
        return result
    }

    const addEmailHandler = (e) => {
        e.preventDefault()
        const copyOfData = { ...formData }
        const name = `recipientEmail-${new Date().getTime()}`
        copyOfData[name] = { value: '', email: true, name }
        setFormData(copyOfData)
    }

    const deleteEmailHandler = (name, e) => {
        e.preventDefault()
        const copyOfData = { ...formData }
        delete copyOfData[name]
        setFormData(copyOfData)
    }

    const renderAttachments = () => {
        return (
            <div className="attachments-wrapper">
                <span>Sélectionnez les pièces jointes à partager</span>
                <div className="attachments-wrapper__inner">
                    {attachments.map((att, i) => {
                        return (
                            <div
                                className="attachments-wrapper__inner_checkbox-wrapper"
                                key={i}
                            >
                                <Checkbox
                                    onChange={(e) => {
                                        onAttachmentShareUpdate(
                                            i,
                                            e.target.checked
                                        )
                                    }}
                                    checked={att.share}
                                />
                                <span>{`${att.name} (${att.type})`}</span>
                            </div>
                        )
                    })}
                </div>
                <Button
                    text="Sauvegardez les préférences de partage"
                    outlinePrimary
                    onButtonClick={onAttachmentsShareSave}
                />
            </div>
        )
    }

    return (
        <Modal onClose={onClose}>
            {isLoading ? <LineLoader /> : null}
            {renderAttachments()}
            <div className="send-by-email">
                <div className="send-by-email__head">
                    <ul>
                        <li
                            className={view === 'email' ? 'active' : null}
                            onClick={() => setView('email')}
                        >
                            Envoyer par email
                        </li>
                        <li
                            className={view === 'link' ? 'active' : null}
                            onClick={() => setView('link')}
                        >
                            Afficher le lien
                        </li>
                    </ul>
                </div>
                <div className="send-by-email__body">
                    {view === 'email' && (
                        <form className="form" onSubmit={submitHandler}>
                            <div className="form__group">
                                <Input
                                    name="email"
                                    value={formData.email.value}
                                    onChange={changeHandler}
                                    label="Votre email"
                                    error={errors.email}
                                    dashboardModal
                                />
                            </div>
                            {/* <div className="form__group">
                <Input 
                  name="recipientEmail"
                  value={formData.recipientEmail.value}
                  onChange={changeHandler}
                  placeholder="Adresse mail destinataire"
                  dashboardModal
                />
                <div className="icon">
                  <DeleteIcon />
                </div>
              </div> */}
                            {Object.values(recipientEmails).map(
                                (email, i) => (
                                    <div
                                        className="form__group"
                                        key={email.name}
                                    >
                                        <Input
                                            name={email.name}
                                            value={email.value}
                                            onChange={changeHandler}
                                            label="Adresse mail destinataire"
                                            dashboardModal
                                            error={errors[email.name]}
                                        />
                                        {i === 0 ? (
                                            <a
                                                href="/#"
                                                className="icon tooltip-wrapper"
                                                onClick={addEmailHandler}
                                            >
                                                <Tooltip text="Ajouter un destinataire" />
                                                <PersonAddIcon />
                                            </a>
                                        ) : (
                                            <a
                                                href="/#"
                                                className="icon tooltip-wrapper"
                                                onClick={(e) =>
                                                    deleteEmailHandler(
                                                        email.name,
                                                        e
                                                    )
                                                }
                                            >
                                                <Tooltip
                                                    text="Supprimer le destinataire"
                                                    position="left"
                                                />
                                                <DeleteIcon />
                                            </a>
                                        )}
                                    </div>
                                )
                            )}
                            <Input
                                name="subject"
                                value={formData.subject.value}
                                onChange={changeHandler}
                                label="Objet de l’email"
                                dashboardModal
                            />
                            {/* <Textarea 
                name="message"
                value={formData.message.value}
                onChange={changeHandler}
                dashboardModal
              /> */}
                            <Editor
                                initialValue={formData.message.value}
                                tagName="editor"
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount',
                                    ],
                                    toolbar:
                                        'bold italic  | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent',
                                }}
                                onEditorChange={handleEditorChange}
                            />
                            {partner === 'jurisur' &&
                                config.environment === 'development' && (
                                    <div className="checkbox-add-watermark">
                                        <Checkbox
                                            onChange={(e) =>
                                                setAddWatermark(
                                                    e.target.checked
                                                )
                                            }
                                            checked={addWatermark}
                                            label="Add watermark to shared document"
                                        />
                                    </div>
                                )}
                            <div className="form__actions">
                                <Button
                                    text="Annuler"
                                    outlinePrimary
                                    onButtonClick={onClose}
                                />
                                <Button
                                    text="Envoyer"
                                    type="submit"
                                    primary
                                />
                            </div>
                        </form>
                    )}

                    {view === 'link' && (
                        <div className="qr-code-section">
                            <div className="qr-code-section__inner">
                                <div className="qr-code-section__left">
                                    <p>
                                        {`https://immodocs-cloud.web.app/document${urlSuffixForEnvironment(
                                                  config.environment
                                              )}/${documentId}`}
                                    </p>
                                    <Button
                                        text="copier le lien dans le presse-papier"
                                        onButtonClick={() =>
                                            copyToClipboardHandler(`https://immodocs-cloud.web.app/document${urlSuffixForEnvironment(
                                                config.environment
                                            )}/${documentId}`)
                                        }
                                    />
                                </div>
                                <div className="qr-code-section__right">
                                    <p>
                                        Scanner ce QRCode depuis votre
                                        mobile ou tablette pour acceder au
                                        lien.
                                    </p>
                                    {/* <img src={qrCode} alt=""/> */}
                                    <QRCode
                                        value={`https://immodocs-cloud.web.app/document${urlSuffixForEnvironment(
                                                      config.environment
                                                  )}/${documentId}`}
                                    />
                                </div>
                            </div>
                            {partner === 'jurisur' &&
                                config.environment === 'development' && (
                                    <div className="checkbox-add-watermark">
                                        <Checkbox
                                            onChange={(e) =>
                                                setAddWatermarkToLink(
                                                    e.target.checked
                                                )
                                            }
                                            checked={addWatermarkToLink}
                                            label="Add watermark to shared document"
                                        />
                                    </div>
                                )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default SendByEmailModal
