import { useContext, useState, useEffect } from 'react'

import { Select, Option, DateInput, Checkbox } from '../ui_new'
import { STATUS_LABELS_SHORT } from '../../constants'
import { DocumentsContext, UserContext } from '../../context'
import { useFoldersActions, useSingleDocumentActions } from '../../hooks'
import { FEATURE, isFeatureAuthorized, isFeatureEnabled, manufacturerProperty } from '../../utils'
import { MoreVert, Person } from '@material-ui/icons'
import { Sort2, Check } from '../../assets/icons'
import { CalendarToday, Domain, ChevronLeft, RadioButtonChecked } from '@material-ui/icons'
import moment from 'moment'
import { ArrowBack, CalendarMonthOutlined, ChevronRight, PersonOutline } from '@mui/icons-material'
import { ClickAwayListener } from '@mui/material'

const DocumentsFilters = ({ onSetSelectedFilters, selectedFilters, onChangeCurrent, onSort, activeSort, allChecked, onSetAllChecked, onSetSelectedDocuments, filteredDocs }) => {
  const { moveToRootFolder } = useFoldersActions()
  const { moveDocToRootFolder } = useSingleDocumentActions()
  const { user, userClaims } = useContext(UserContext)
  const [activeFolder, setActiveFolder] = useState('all')
  const { currentFilter, documents } = useContext(DocumentsContext)

  // const [status, setStatus] = useState(selectedFilters.status)
  const [dateAfter, setDateAfter] = useState(selectedFilters.date_after)
  const [dateBefore, setDateBefore] = useState(selectedFilters.date_before)
  const [showFilters, setShowFilters] = useState(false)

  const [useAgencyInfo, setUseAgencyInfo] = useState(false)

  const [showSortDropdown, setShowSortDropdown] = useState(false)
  const [displayFilter, setDisplayFilter] = useState(-1);

  const [authors, setAuthors] = useState([])
  useEffect(() => {
    if (documents) {
      setAuthors(Object.values(documents).filter(doc => doc.author).filter((doc, index, docs) => {
        return docs.findIndex(d => d.author.id === doc.author.id) === index
      }).map(doc => doc.author))
    }
  }, [documents])

  // Set active folder
  useEffect(() => {
    setActiveFolder(selectedFilters.folder)
  }, [selectedFilters])

  // On status change
  const handleStatusChange = (value) => {
    // setStatus(value)
    onSetSelectedFilters(prev => ({
      ...prev,
      status: value
    }))
  }

  const handleOwnerChange = (value) => {
    onSetSelectedFilters(prev => ({
      ...prev,
      owner: value
    }))
  }

  const handleAuthorChange = (value) => {
    onSetSelectedFilters(prev => ({
      ...prev,
      author: value
    }))
  }

  // On date change - range
  const handleDateRangeChange = (date) => {
    const rangeStartDate = date[0]
    const rangeEndDate = date[1]
    if (!rangeStartDate || !rangeEndDate) {
      return;
    }
    setDateAfter(rangeStartDate)
    setDateBefore(rangeEndDate)
    onSetSelectedFilters(prev => ({
      ...prev,
      date_after: rangeStartDate,
      date_before: rangeEndDate
    }))
  }

  // On date change - after
  const handleDateAfterChange = (date) => {
    setDateAfter(date)
    onSetSelectedFilters(prev => ({
      ...prev,
      date_after: date
    }))
  }

  // On date change - before
  const handleDateBeforeChange = (date) => {
    setDateBefore(date)
    onSetSelectedFilters(prev => ({
      ...prev,
      date_before: date
    }))
  }

  // On date after clear
  const handleDateAfterClear = () => {
    setDateAfter('')
    setDateBefore('')
    onSetSelectedFilters(prev => ({ ...prev, date_after: '', date_before: '' }))
  }

  // On date before clear
  const handleDateBeforeClear = () => {
    setDateBefore('')
    onSetSelectedFilters(prev => ({ ...prev, date_before: '' }))
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  // On all checkbox click
  const handleAllChecked = () => {
    onSetAllChecked(!allChecked)
    if (allChecked) {
      onSetSelectedDocuments([])
    } else {
      onSetSelectedDocuments(filteredDocs)
    }
  }

  const displayDate = () => {
    if (dateAfter && dateBefore) {
      return moment(dateAfter).format('MM/YYYY') + ' - ' + moment(dateBefore).format('MM/YYYY');
    }
    return '-'
  }
  const displayOwner = (owner) => {
    const ownerObj = (user.agencies ? user.agencies : [user.agency]).find((item) => item.manufacturers_id === owner)
    if (ownerObj) {
      return `${ownerObj.manufacturers_name}`
    }
    return 'Toutes les agences'
  }
  const displayAuthor = (author) => {
    const authorObj = authors.find((item) => item.id === author)
    if (authorObj) {
      return `${authorObj.firstname} ${authorObj.lastname}`
    }
    return 'Tous'
  }

  const displayStatus = (status) => {
    if (STATUS_LABELS_SHORT[selectedFilters.status]) {
      return STATUS_LABELS_SHORT[selectedFilters.status]
    }
    return 'Tous les statuts'
  }

  const sortOptions = {
    'name': { label: 'Nom', asc: 'A > Z', desc: 'Z > A' },
    'status': { label: 'Statut', asc: 'A > Z', desc: 'Z > A' },
    'progress': { label: 'Pourcentage', asc: 'Croissant', desc: 'Décroissant' },
    'author.firstname': { label: 'Crée par', asc: 'Z > A', desc: 'A > Z' },
    'owner': { label: 'Agence', asc: 'Z > A', desc: 'A > Z' },
    'meta.created': { label: 'Date de création', asc: '+ ancienne', desc: ' + récente' },
    'meta.updated': { label: 'Date de modification', asc: '+ ancienne', desc: '+ récente' },
  };

  return (
    <>
      <div className='filters-section-parent'>
        <ClickAwayListener onClickAway={() => setShowFilters(false)}>
          <div>
            <button className='filters-section-toggle' onClick={toggleFilters}>
              <MoreVert />
            </button>
            <section className={`filters-section ${showFilters && 'show'}`}>
              {displayFilter !== -1 && <button onClick={() => { setDisplayFilter(-1) }} className='back-btn'>
                <ChevronLeft /> Retour
              </button>}
              {displayFilter === -1 && <ul className='mobile-filters-list'>
                <li onClick={() => { setDisplayFilter(0) }}>
                  <span><CalendarMonthOutlined /> {displayDate()} </span>
                  <ChevronRight />
                </li>
                {isFeatureEnabled(FEATURE.INTERAGENCY) && <li onClick={() => { setDisplayFilter(1) }}>
                  <span><Domain /> {displayOwner(selectedFilters.owner)}</span>
                  <ChevronRight />
                </li>}
                <li onClick={() => { setDisplayFilter(2) }}>
                  <span><RadioButtonChecked /> {displayStatus(selectedFilters.status)}</span>
                  <ChevronRight />
                </li>
                <li onClick={() => { setDisplayFilter(3) }}>
                  <span><PersonOutline fontSize='medium' /> {displayAuthor(selectedFilters.author)}</span>
                  <ChevronRight />
                </li>
              </ul>}
              <div className={`select-all`} >
                <Checkbox
                  label="Tout sélectionner"
                  checked={allChecked}
                  onChange={handleAllChecked}
                  className='cursor-pointer'
                />
              </div>
              <div className={`filters-section__item ${displayFilter === 0 ? 'show' : ''}`}>
                <DateInput
                  selectsRange={true}
                  placeholder="Créé après - Créé avant"
                  onChange={handleDateRangeChange}
                  onChangeRaw={(e) => e.preventDefault()}
                  isClearable={dateAfter && dateBefore}
                  onClearableClick={handleDateAfterClear}
                  // selected={[dateAfter, dateBefore]}
                  minDate={dateAfter}
                  maxDate={dateBefore}
                  expandable={true}
                />
              </div>
              {/* <div className="filters-section__item">
              <DateInput
                placeholder="Créé avant"
                onChange={handleDateBeforeChange}
                onChangeRaw={(e) => e.preventDefault()}
                isClearable={dateBefore}
                onClearableClick={handleDateBeforeClear}
                selected={dateBefore}
                minDate={dateAfter}
              />
            </div> */}

              {isFeatureEnabled(FEATURE.INTERAGENCY) && <div className={`filters-section__item ${displayFilter === 1 ? 'show' : ''}`}>
                <Select label="" onChange={handleOwnerChange} selected={selectedFilters.owner} className={'!h-10'}>
                  <Option value="all">Toutes les agences</Option>
                  {(user.agencies ? user.agencies : [user.agency]).map((agency, index) => (
                    <Option key={index} value={manufacturerProperty(agency, 'id')}>{manufacturerProperty(agency, 'name')}</Option>
                  ))}
                </Select>
              </div>}
              <div className={`filters-section__item ${displayFilter === 2 ? 'show' : ''}`}>
                <Select label="" onChange={handleStatusChange} selected={selectedFilters.status} className={'!h-10'}>
                  <Option value="all">Tous les statuts</Option>
                  {Object.entries(STATUS_LABELS_SHORT).map((status, index) => (
                    <Option key={index} value={status[0]}>{status[1]}</Option>
                  ))}
                </Select>
              </div>
              {isFeatureEnabled(FEATURE.INTERAGENCY) &&
                <div className={`filters-section__item ${displayFilter === 3 ? 'show' : ''}`} >
                  <Select label="Créé par " onChange={handleAuthorChange} selected={selectedFilters.author} className={'!h-10'}>
                    <Option value="all">Tous</Option>
                    {authors.map((author, index) => (
                      <Option key={index} value={author.id}>
                        {`${(author.firstname === user.firstname && author.lastname === user.lastname) ? 'Moi' : `${author.firstname} ${author.lastname}`} `}
                      </Option>
                    ))}
                  </Select>
                </div>}
            </section>
          </div>
        </ClickAwayListener>

        <ClickAwayListener onClickAway={() => {
          console.log("sorter click away")
          setShowSortDropdown(false)
        }
        }>
          <div className="relative">
            <div
              className={`filters-section-sort !h-8 !max-w-full select-v2 select-v2--filled position-relative ${showSortDropdown && 'active'}`}
            >
              <div className="flex md:px-3 h-8 items-center gap-2" onClick={() => setShowSortDropdown(!showSortDropdown)}>
                <div className="flex gap-2">
                  <Sort2 />
                  <span className='sort-label sm-d-none'>
                    {sortOptions[activeSort.activeItem]?.label} {sortOptions[activeSort.activeItem]?.[activeSort[activeSort.activeItem]]}
                  </span>
                </div>
                <span className='chevron-icon' ><ChevronRight fontSize='small' /></span>
              </div>
            </div>
            {showSortDropdown && <div className={`sort-dropdown select-v2__body_dropdown align-right`}>
              {Object.entries(sortOptions).map((option, index) => (
                <div key={index} className={`dropdown-item`} >
                  <p> {option[1].label} <ChevronRight fontSize={'small'} /> </p>
                  <ul>
                    <li
                      className={`dropdown-item ${activeSort[option[0]] === "asc" ? 'active' : ''}`}
                      onClick={() => {
                        onSort(option[0], 'asc')
                        setShowSortDropdown(false)
                      }}>
                      {option[1].asc}
                    </li>
                    <li
                      className={`dropdown-item ${activeSort[option[0]] === "desc" ? 'active' : ''}`}
                      onClick={() => {
                        onSort(option[0], 'desc')
                        setShowSortDropdown(false)
                      }}>
                      {option[1].desc}
                    </li>
                  </ul>
                </div>
              ))}
            </div>}
          </div>
        </ClickAwayListener>
      </div >
    </>

  )
}

export default DocumentsFilters