import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Page } from 'react-pdf'

import { UserSolid, Building, BgImage, Eye, CheckCircle } from '../assets/icons'
import { SettingsLayout } from '../layouts'
import { CoverPagePreview } from '../components/misc'
import { Select, Option, Checkbox, RadioGroup, RadioEl, FileUploadDnD } from '../components/ui_new'
import { NotificationContext, UserContext } from '../context'
import { useCoverPageActions } from '../hooks'
import ColorPicker from "../components/UI/ColorPicker"
import { FEATURE, agencyLogoUrl, isFeatureEnabled } from '../utils'
import { KeyboardDoubleArrowLeft, Visibility } from '@mui/icons-material'

const SettingsCoverPage = () => {
  const { fetchDocumentData, saveAgency } = useCoverPageActions()
  const { agency, user, partner, siteConfigs } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const [color, setColor] = useState('')
  const [font, setFont] = useState('')
  const [useAgencyInfo, setUseAgencyInfo] = useState(false)
  const [useAdminInfo, setUseAdminInfo] = useState(false)
  const [useAdminPhoto, setUseAdminPhoto] = useState(false)
  const [useLogo, setUseLogo] = useState(false)
  const [coverImageInput, setCoverImageInput] = useState('default')
  const [logoInput, setLogoInput] = useState('agency')
  const [colorInput, setColorInput] = useState('agency')
  const [showPreview, setShowPreview] = useState(false)
  const [agencyImages, setAgencyImages] = useState({
    logo: { url: '', data: '', name: '', type: '', extension: '' },
    cover: { url: '', data: '', name: '', type: '', extension: '' }
  })
  const [pdfPreviewData, setPdfPreviewData] = useState(null)
  const [previewPage, setPreviewPage] = useState(null)
  const [loadingPreview, setLoadingPreview] = useState(false)
  const fontOptions = [
    { value: 'Inter', label: 'Inter' },
    { value: 'Open Sans', label: 'Open Sans' },
    { value: 'Caladea', label: 'Caladea' },
    { value: 'Carlito', label: 'Carlito' },
    { value: 'DejaVu Sans', label: 'DejaVu Sans' },
    { value: 'Gentium Basic', label: 'Gentium Basic' },
    { value: 'Liberation Sans', label: 'Liberation Sans' },
    { value: 'Linux Biolinum G', label: 'Linux Biolinum G' },
    { value: 'Rubik', label: 'Rubik' },
    // { value: 'CroissantOne', label: 'CroissantOne' },
    // { value: 'Croissant One', label: 'Croissant One' }
  ]

  // Set values after agency is fetched
  useEffect(() => {
    if (!agency) {
      return
    }
    setAgencyImages({
      logo: { url: agency.logo || '' },
      cover: { url: agency.cover || '' }
    })
    setColor(agency.cover_color || '')
    setFont(agency.font_family || 'Inter')
    setUseAgencyInfo(agency.cover_agency_info || false)
    setUseAdminInfo(agency.cover_admin_info || false)
    setUseAdminPhoto(agency.cover_admin_photo || false)
    setCoverImageInput((agency.cover_image_input !== 'none' ? agency.cover_image_input : 'default') || 'default')
    setLogoInput(agency.cover_logo_input || 'agency')
    setColorInput(agency.cover_color_input || 'agency')
    setUseLogo(agency.cover_use_logo || false)
  }, [agency])

  // On color change 
  const handleColorChange = (value) => {
    setColor(value.replace('#', ''))
  }

  // On font change
  const handleFontChange = (value) => {
    setFont(value)
  }

  // To base 64
  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  // On file change
  const handleFileChange = async (files, name) => {
    if (files && files.length > 0) {
      const file = files[0]
      if (!file.type.startsWith('image/')) { return }

      const data = await toBase64(file)
      let components = file.name.split('.')
      let extension = components.splice(components.length - 1, 1)[0]
      setAgencyImages({
        ...agencyImages,
        [name]: {
          url: agencyImages[name].url,
          data: data,
          name: file.name,
          type: file.type,
          extension
        }
      })
    }
  }

  // On image remove 
  const handleImageRemove = (name) => {
    setAgencyImages({
      ...agencyImages,
      [name]: {
        url: '',
        data: '',
        name: '',
        type: '',
        extension: ''
      }
    })
  }

  // Check if custom cover image is disabled
  const isCustomCoverImageDisabled = () => {
    return !agencyImages.cover.url && !agencyImages.cover.data
  }

  // On document load success
  const onDocumentLoadSuccess = ({ numPages }) => {
    const arr = []
    for (let i = 1; i < numPages + 1; i++) {
      arr.push(<Page pageNumber={i} height={window.innerHeight ? window.innerHeight * 0.75 : 450} loading="" />)
    }
    setPreviewPage(arr[0])
  }

  const agencyDefaultColor = () => {
    let colorSiteConfig = siteConfigs.find(c => c.key === 'DEFAULT_EMAIL_COLOR')
    if (colorSiteConfig) {
      return colorSiteConfig.value
    }
    return ''
  }

  // On preview
  const handlePreview = async () => {
    setLoadingPreview(true)
    setPdfPreviewData(null)

    let coverColor = color
    if (colorInput === 'agency') {
      let colorSiteConfig = siteConfigs.find(c => c.key === 'DEFAULT_EMAIL_COLOR')
      if (colorSiteConfig) {
        coverColor = colorSiteConfig.value
      }
    }

    let documentData = await fetchDocumentData({ useLogo, agencyImages, coverImageInput, color: coverColor, font, useAdminInfo, useAdminPhoto, useAgencyInfo, logoInput, agency: user?.manufacturer })
    setLoadingPreview(false)
    if (!documentData) {
      setNotification({ msg: "Une erreur s'est produite lors de la création de l'aperçu", type: 'danger' })
      return
    }

    setPdfPreviewData(`data:application/pdf;base64,${documentData}`)
    // const a = document.createElement("a")
    // a.href = `data:application/pdf;base64,${documentData}`
    // a.download = "preview.pdf"
    // a.click()
  }

  // On toggle preview
  const handleTogglePreview = () => {
    setShowPreview(!showPreview)
    if (!showPreview) {
      handlePreview()
    }
  }

  // On close preview
  const handleClosePreview = () => {
    setShowPreview(false)
  }

  // On open preview
  const handleOpenPreview = () => {
    setShowPreview(true)
    handlePreview()
  }

  // On save
  const handleSave = async () => {
    let coverColor = color
    if (colorInput === 'agency') {
      let colorSiteConfig = siteConfigs.find(c => c.key === 'DEFAULT_EMAIL_COLOR')
      if (colorSiteConfig) {
        coverColor = colorSiteConfig.value.replace('#', '')
      }
    }
    const images = agencyImages
    let coverLogo = agencyImages.logo
    if (logoInput === 'agency') {
      coverLogo = { url: agencyLogoUrl(user.manufacturer) }
    }
    if (!coverLogo) {
      coverLogo = ''
    }
    images.logo = coverLogo

    await saveAgency({ agencyImages: images, color: coverColor, font, useAgencyInfo, useAdminInfo, useAdminPhoto, coverImageInput, useLogo, logoInput, colorInput })
  }

  return (
    <SettingsLayout pageTitle="Mise en page des documents">
      <div className={`settings-profile cover`}>
        <div className={`cover-page-v2__foot ${showPreview ? 'show-preview' : ''}`}>
          <button className="button ml-auto button--outline-primary-light rounded-sm btn-text !px-3"
            onClick={handleOpenPreview}>
            <Eye /> <span className=''>Aperçu</span>
          </button>
          <div className='btn-separator'></div>
          <button className="button action-btn" onClick={handleSave}>
            <span>
              <CheckCircle />
              Sauvegarder
            </span>
          </button>
        </div>
        <div className={`settings-profile-parent ${showPreview ? 'show-preview' : ''}`}>

          <div className={`settings-profile__box medium ${showPreview ? 'show-preview' : ''}`}>
            <div className="head">
              <h3>Personnalisation</h3>
              <span>Éléments de mise en page</span>
            </div>
            <div className="body">
              <section className="cover-page-v2__section">
                <h2 className="inner-heading">Couleur principale</h2>
                <div className={`color-wrapper ${colorInput !== 'custom' ? "disabled" : ''}`}>
                  <span className='setting-cover-page-text'>Choisissez la couleur de la page de garde et des titres : </span>
                  <ColorPicker color={colorInput === 'custom' ? `#${color}` : agencyDefaultColor()} onChange={handleColorChange} disabled={colorInput !== 'custom'} />
                </div>
                <RadioGroup
                  name="color"
                  selected={colorInput}
                  onChange={(e) => setColorInput(e.target.value)}
                >
                  <RadioEl value="agency">Couleur de l’agence</RadioEl>
                  <RadioEl value="custom" className="ml-4">Couleur personnalisée</RadioEl>
                </RadioGroup>
                <div className="hr"></div>
                <h2 className="inner-heading">Police</h2>
                <div className="color-wrapper">
                  <span className='setting-cover-page-text'>Sélectionnez la police de caractères souhaitée :  </span>
                  <Select onChange={handleFontChange} selected={font} className="!h-8">
                    {fontOptions.map((font, idx) => {
                      return (
                        <Option key={idx} value={font.value}>{font.label}</Option>
                      )
                    })}
                  </Select>
                </div>
              </section>
              <div className="hr"></div>
              <h2 className="inner-heading">Logo</h2>
              <span className='setting-cover-page-text mb-4'>Ajoutez un logo pour la page de garde :</span>

              <section className="cover-page-v2__section">
                <RadioGroup
                  name="logo"
                  selected={logoInput}
                  onChange={(e) => setLogoInput(e.target.value)}
                  label=""
                >
                  <RadioEl value="agency">Utiliser le logo de l'agence</RadioEl>
                  <RadioEl value="custom">Logo personnalisé</RadioEl>
                </RadioGroup>
                <div className="dnd-wrapper !border-b-0 !mb-0">
                  <FileUploadDnD
                    disabled={logoInput === 'agency'}
                    btnText="Changer"
                    accept="image/png,image/jpg,image/jpeg"
                    name="logo"
                    imagePreview={logoInput === 'agency' ? agencyLogoUrl(user.manufacturer) : agencyImages.logo.data || agencyImages.logo.url}
                    onChange={handleFileChange}
                    onImageRemove={handleImageRemove}
                    minimumImageDimensions={{ width: 250, height: 250 }}
                    title={"Votre logo*"}
                    note={"Largeur minimale recommandée : 250px"}
                    description={"*Si l’image du logo n’est pas carrée, une bordure blanche peut apparaître"}
                    multiple={false}

                  />
                  <div className="hr"></div>
                  <h2 className="inner-heading">Couverture</h2>
                  <span className='setting-cover-page-text mb-4'>Ajoutez une photo de couverture pour la page de garde :</span>
                  <RadioGroup
                    name="cover_image"
                    selected={coverImageInput}
                    onChange={(e) => setCoverImageInput(e.target.value)}
                  >
                    <RadioEl value="default">Couverture par défaut</RadioEl>
                    <RadioEl value="custom" disabled={isCustomCoverImageDisabled()}>Couverture personnalisée</RadioEl>
                  </RadioGroup>

                  <FileUploadDnD
                    btnText="Changer"
                    accept="image/png,image/jpg,image/jpeg"
                    name="cover"
                    imagePreview={agencyImages.cover.data || agencyImages.cover.url}
                    onChange={handleFileChange}
                    onImageRemove={handleImageRemove}
                    minimumImageDimensions={{ width: 1000, height: 1000 }}
                    title={"Photo de couverture"}
                    note={"Largeur minimale recommandée : 1000px"}
                  />
                </div>
              </section>
            </div>

          </div>
          <div className={`settings-profile__box small`}>
            <div className="head">
              <h3>Informations</h3>
              <span>Éléments sur la page de garde</span>
            </div>
            <div className="body">
              <h2 className="inner-heading">Choix des informations</h2>
              <span className='setting-cover-page-text mb-4'>Cochez les informations que vous souhaitez afficher sur la page de garde de vos documents :</span>
              <section className="cover-page-v2__section">
                <Checkbox
                  label="Informations de l’agence"
                  checked={useAgencyInfo}
                  onChange={(e) => setUseAgencyInfo(e.target.checked)}
                  className='!mb-4'
                />
                <Checkbox
                  label="Informations du négociateur"
                  checked={useAdminInfo}
                  onChange={(e) => setUseAdminInfo(e.target.checked)}
                  className='!mb-4'
                />
                <Checkbox
                  label="Photo du négociateur"
                  checked={useAdminPhoto}
                  onChange={(e) => setUseAdminPhoto(e.target.checked)}
                  className='!mb-4'
                />
                <Checkbox
                  label="Logo"
                  checked={useLogo}
                  onChange={(e) => setUseLogo(e.target.checked)}
                />
              </section>
            </div>

          </div>
        </div>

      </div>
      <div className={`single-document-side-narrow-panel`}>
        <button className="eye-button" onClick={handleTogglePreview}>
          <Visibility fontSize='medium' />
        </button>
        <button className="button button--outline-primary-light rounded-sm fold-btn" onClick={handleTogglePreview}>
          <KeyboardDoubleArrowLeft fontSize={'small'} />
        </button>
      </div>
      <CoverPagePreview
        opened={showPreview}
        onToggle={handleTogglePreview}
        data={pdfPreviewData}
        previewPage={previewPage}
        loading={loadingPreview}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        onPreviewRefresh={handlePreview}
        onClose={handleClosePreview}
      />
    </SettingsLayout>
  )
}

export default SettingsCoverPage