const ManageAccounts = () => {
  return (
    <span className="svg-icon">

      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.08594 17.5V16.85C4.08594 16.51 4.24594 16.19 4.49594 16.04C6.18594 15.03 8.11594 14.5 10.0859 14.5C10.1159 14.5 10.1359 14.5 10.1659 14.51C10.2659 13.81 10.4659 13.14 10.7559 12.53C10.5359 12.51 10.3159 12.5 10.0859 12.5C7.66594 12.5 5.40594 13.17 3.47594 14.32C2.59594 14.84 2.08594 15.82 2.08594 16.85V19.5H11.3459C10.9259 18.9 10.5959 18.22 10.3759 17.5H4.08594Z" fill="#482982" />
        <path d="M10.0859 11.5C12.2959 11.5 14.0859 9.71 14.0859 7.5C14.0859 5.29 12.2959 3.5 10.0859 3.5C7.87594 3.5 6.08594 5.29 6.08594 7.5C6.08594 9.71 7.87594 11.5 10.0859 11.5ZM10.0859 5.5C11.1859 5.5 12.0859 6.4 12.0859 7.5C12.0859 8.6 11.1859 9.5 10.0859 9.5C8.98594 9.5 8.08594 8.6 8.08594 7.5C8.08594 6.4 8.98594 5.5 10.0859 5.5Z" fill="#482982" />
        <path d="M20.8359 15.5C20.8359 15.28 20.8059 15.08 20.7759 14.87L21.9159 13.86L20.9159 12.13L19.4659 12.62C19.1459 12.35 18.7859 12.14 18.3859 11.99L18.0859 10.5H16.0859L15.7859 11.99C15.3859 12.14 15.0259 12.35 14.7059 12.62L13.2559 12.13L12.2559 13.86L13.3959 14.87C13.3659 15.08 13.3359 15.28 13.3359 15.5C13.3359 15.72 13.3659 15.92 13.3959 16.13L12.2559 17.14L13.2559 18.87L14.7059 18.38C15.0259 18.65 15.3859 18.86 15.7859 19.01L16.0859 20.5H18.0859L18.3859 19.01C18.7859 18.86 19.1459 18.65 19.4659 18.38L20.9159 18.87L21.9159 17.14L20.7759 16.13C20.8059 15.92 20.8359 15.72 20.8359 15.5ZM17.0859 17.5C15.9859 17.5 15.0859 16.6 15.0859 15.5C15.0859 14.4 15.9859 13.5 17.0859 13.5C18.1859 13.5 19.0859 14.4 19.0859 15.5C19.0859 16.6 18.1859 17.5 17.0859 17.5Z" fill="#482982" />
      </svg>


    </span>
  )
}

export default ManageAccounts