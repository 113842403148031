import React, { useContext } from 'react';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Input from '../../UI/Input';
import { NotificationContext } from '../../../context/notifications/notificationState';

const SaveUpdatedSignatureTemplateModal = ({ onClose, onTemplateSave, name, onSetName, onSetActiveTemplate }) => {
  const { setNotification } = useContext(NotificationContext);

  const submitHandler = async (e) => {
    if(name.trim() === '') {
      return setNotification({ msg: 'Le nom est obligatoire', type: 'danger' });
    }
    try {
      const id = await onTemplateSave(e);
      if(id) {
        onSetActiveTemplate(id);
        onClose();
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal onClose={onClose} medium>
      <div className="signature-templates-modal"> 
        <h3>Sauvegarder le modèle mis à jour</h3>
        <form onSubmit={submitHandler}>
          <Input value={name} onChange={(e) => onSetName(e.target.value)} formEl placeholder="Nom du modèle" />
          <Button type="submit" text="Sauvegarder" medium primary />
        </form>
      </div>
    </Modal>
  );
}

export default SaveUpdatedSignatureTemplateModal;