import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';

import SettingsSidebar from '../sections/settings/SettingsSidebar';
import Button from '../UI/Button';
import Input from '../UI/Input';
import Loader from '../UI/Loader';
import { UserContext } from '../../context/user/userState';
import FileUpload from '../UI/FileUpload';
import PublishIcon from '@material-ui/icons/Publish';
import { update_agency, upload_file } from '../../services/firestore';
import { LoaderContext } from '../../context/loader/loaderState';
import config from '../../config.json'
import { colors, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import Checkbox from '../UI/Checkbox';
import SupportMenu from '../sections/dashboard/SupportMenu'

import { Document, Page, pdfjs } from 'react-pdf';
import { manufacturerProperty, matchCoverPageVariables } from '../../utils';
import { generate_document, generate_document_with_template_url } from '../../services/lawstudioApi';
import { DocumentsContext, NotificationContext } from '../../context';
import Radio from '../UI/Radio';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AgencyCoverPage = () => {
  const settingsSidebar = useRef();
  const { user, agency, getAgency } = useContext(UserContext);
  const { templates } = useContext(DocumentsContext)
  const { setLoading } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const [agencyImages, setAgencyImages] = useState({
    logo: { url: '', data: '', name: '', type: '', extension: '' },
    cover: { url: '', data: '', name: '', type: '', extension: '' }
  })
  const [color, setColor] = useState('')
  const [useAgencyInfo, setUseAgencyInfo] = useState(false)
  const [useAdminInfo, setUseAdminInfo] = useState(false)
  const [useAdminPhoto, setUseAdminPhoto] = useState(false)
  const [useLogo, setUseLogo] = useState(false)
  // const [useCoverImage, setUseCoverImage] = useState(false)
  // const [useDefaultImage, setUseDefaultImage] = useState(false)
  const [coverImageInput, setCoverImageInput] = useState('none')
  const colorOptions = [
    { value: 'bd544a', color: '#bd544a' },
    { value: 'd9c7b1', color: '#d9c7b1' },
    { value: 'f4ba34', color: '#f4ba34' },
    { value: 'd79060', color: '#d79060' },
    { value: 'ea4026', color: '#ea4026' },
    { value: 'e7d882', color: '#e7d882' },
    { value: 'bfcfda', color: '#bfcfda' },
    { value: '52b6d8', color: '#52b6d8' },
    { value: '2a7bb5', color: '#2a7bb5' },
    { value: '2bb5b5', color: '#2bb5b5' }
  ]

  const [pdfPreviewData, setPdfPreviewData] = useState(null);
  const [previewPage, setPreviewPage] = useState(null)
  const [loadingPreview, setLoadingPreview] = useState(false)

  useEffect(() => {
    if(!agency) {
      return
    }
    setAgencyImages({
      logo: { url: agency.logo || ''},
      cover: { url: agency.cover || ''}
    })
    setColor(agency.cover_color || '')
    setUseAgencyInfo(agency.cover_agency_info)
    setUseAdminInfo(agency.cover_admin_info)
    setUseAdminPhoto(agency.cover_admin_photo)
    setCoverImageInput(agency.cover_image_input || 'none')
    setUseLogo(agency.cover_use_logo)
  }, [agency])

  const settingsSidebarOpenHandler = (e) => {
    settingsSidebar.current.classList.add('opened');
  }

  const settingsSidebarCloseHandler = (e) => {
    settingsSidebar.current.classList.remove('opened');
  }

  const coverImagesChangeHandler = (imageName) => async (e) => {
    const files = e.target.files || e.dataTransfer.files;
    if(files && files.length > 0) {
      const file = files[0];
  
      if (!file.type.startsWith('image/')) { return }
      const data = await toBase64(file)
      let components = file.name.split('.')
      let extension = components.splice(components.length - 1, 1)[0]
      setAgencyImages({
        ...agencyImages,
        [imageName]: {
          url: agencyImages[imageName].url,
          data: data,
          name: file.name,
          type: file.type,
          extension
        }
      })
    }
  }

  const generatePreview = async () => {
    setLoadingPreview(true)
    setPdfPreviewData(null)

    let documentData = await fetchDocumentData()
    setLoadingPreview(false)
    if(!documentData) {
      setNotification({ msg: "Une erreur s'est produite lors de la création de l'aperçu", type: 'danger' })
      return
    }
   
    setPdfPreviewData(`data:application/pdf;base64,${documentData}`);
  }

  // cover page template url
  const coverPageTemplateUrl = () => {
    switch(config.environment) {
      case 'development':
      case 'staging':
      case 'production':
        return 'https://firebasestorage.googleapis.com/v0/b/lawstudio-api.appspot.com/o/templates%2F4tNHo01drbsthz66yW2w_template.docx?alt=media&token=64bd41f7-d022-45dd-a171-b987901d82d5'
      default:
        return ''
    }
  }

  const fetchDocumentData = async () => {
    const images = {}
    const options = {}
    let coverPageVariables = {}
    if(useLogo) {
      if(agencyImages.logo.data) {
        images.logo = agencyImages.logo.data
      } else if(agencyImages.logo.url) {
        images.logo = agencyImages.logo.url
      } else {
        images.logo = ''
      }
    } else {
      images.logo = ''
    }
    if(coverImageInput !== 'none') {
      if(coverImageInput === 'custom') {
        if(agencyImages.cover.data) {
          images.cover = agencyImages.cover.data
        } else if(agencyImages.cover.url) {
          images.cover = agencyImages.cover.url
        }
      }
    } else {
      images.cover = ''
    }
    if(color) {
      options.cover_color = color
    }
    if(useAdminInfo && useAdminPhoto && user.picture) {
      images.cover_admin_photo = user.picture 
    } else {
      images.cover_admin_photo = ''
    }
    coverPageVariables = matchCoverPageVariables(user, useAgencyInfo, useAdminInfo)
       
    coverPageVariables.cover_page_title = 'Titre du document'
    const coverPageDefaultTemplateUrl = coverPageTemplateUrl()
    if(!coverPageDefaultTemplateUrl) {
      return null
    }
    let doc = await generate_document_with_template_url(coverPageDefaultTemplateUrl, coverPageVariables, 'pdf', images, options)
    if(doc.error) {
      return null
    }
    return doc.data
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    const arr = [];
    for(let i = 1; i < numPages + 1; i++) {
      arr.push(<Page pageNumber={i} width={300} renderTextLayer={true} loading="" />);
    }
    setPreviewPage(arr[0]);
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  
  // const configChanged = () => {
  //   return (
  //     agencyImages.logo.data || 
  //     agencyImages.cover.data || 
  //     (agency && agency.cover_color !== color) || 
  //     (agency && agency.cover_agency_info !== useAgencyInfo) ||
  //     (agency && agency.cover_admin_info !== useAdminInfo) ||
  //     (agency && agency.cover_admin_photo !== useAdminPhoto) ||
  //     (agency && agency.cover_image_input !== coverImageInput) || 
  //     (agency && agency.cover_use_logo !== useLogo)
  //   )
  // }

  const saveChanges = async () => {
    setLoading(true)
    let promises = []
    let updateObject = {}
    if(agencyImages.logo.data) {
      promises.push(uploadImage('logo', agencyImages.logo, updateObject))
    }
    if(agencyImages.cover.data) {
      promises.push(uploadImage('cover', agencyImages.cover, updateObject))
    }
    updateObject.cover_color = color
    updateObject.cover_agency_info = useAgencyInfo
    updateObject.cover_admin_info = useAdminInfo
    updateObject.cover_admin_photo = useAdminPhoto
    updateObject.cover_image_input = coverImageInput
    updateObject.cover_use_logo = useLogo
    await Promise.all(promises)
    await update_agency(manufacturerProperty(user?.manufacturer, 'id'), updateObject)
    await getAgency()
    setLoading(false)
  }

  const uploadImage = async (type, image, updateObject) => {
    let result = await upload_file(image.data, `${manufacturerProperty(user?.manufacturer, 'id')}_${type}`, image.extension, image.type, `agency_covers_${config.environment}`, false)
    if(result.error || !result.url) {
      return
    }
    updateObject[type] = result.url
  }

  const isCustomCoverImageDisabled = () => {
    return !agencyImages.cover.url && !agencyImages.cover.data
  }

  const renderAgencyImagesInput = () => {
    return (
      <Grid container direction="row" justify="space-between">
        <Grid item sm={6}>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Schéma de couleur</InputLabel>
            <Select
              className="color-select"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={color}
              label="Schéma de couleur"
              onChange={(e) => setColor(e.target.value)}
            >
              { colorOptions.map((col, ci) => {
                return (
                  <MenuItem key={`color_${ci}`} value={col.value}><div style={{ backgroundColor: `#${col.value}` }} className={`color-option-box`}></div><span className="color-option-label">{col.color }</span></MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <div className="row v-centered c-gap-10 mt-3">
            <Checkbox checked={useAgencyInfo} onChange={(e) => setUseAgencyInfo(e.target.checked)} />
            <span className="ml-5">Afficher les informations de l'agence</span>
          </div>
          <div className="row v-centered c-gap-10 mt-3">
            <Checkbox checked={useAdminInfo} onChange={(e) => setUseAdminInfo(e.target.checked)} />
            <span className="ml-5">Afficher les informations du négociateur</span>
          </div>
          <div className="row v-centered c-gap-10 mt-3">
            <Checkbox checked={useAdminPhoto} onChange={(e) => setUseAdminPhoto(e.target.checked)} />
            <span className="ml-5">Afficher la photo du négociateur</span>
          </div>
          <div className="row v-centered c-gap-10 mt-3">
            <Checkbox checked={useLogo} onChange={(e) => setUseLogo(e.target.checked)} />
            <span className="ml-5">Afficher le logo</span>
          </div>
          <div className="radio-container">
            <span className="radio-title">Image de couverture</span>
            <Radio
              choices={[{ value: 'none', label: 'Pas d\'image de couverture' }, { value: 'default', label: 'Image de couverture par défaut' }, { value: 'custom', label: 'Image de couverture personnalisée', disabled: isCustomCoverImageDisabled() }]}
              name={'Cover image'}
              selectedValue={coverImageInput}
              onChange={e => { setCoverImageInput(e.target.value) }}
            />
          </div>
          {/* <div className="row v-centered c-gap-10 mt-3">
            <Checkbox checked={useCoverImage} onChange={(e) => setUseCoverImage(e.target.checked)} />
            <span className="ml-5">Utilisez l'image de couverture</span>
          </div>
          <div className="row v-centered c-gap-10 mt-3">
            <Checkbox checked={useDefaultImage} onChange={(e) => setUseDefaultImage(e.target.checked)} />
            <span className="ml-5">Utilisez l'image de couverture par défaut</span>
          </div> */}
          <div className="row c-gap-50 mt-20">
            <div className="agency-image-container">
              { (agencyImages.logo.data || agencyImages.logo.url) ? <img src={`${agencyImages.logo.data || agencyImages.logo.url}`} className="agency-image" alt="Agency Logo" /> : null }
              <FileUpload
                name="logo_image"
                fileName={agencyImages.logo.name || ''}
                onChange={coverImagesChangeHandler('logo')}
                accept="image/png,image/jpg,image/jpeg"
                btnText={agencyImages.logo.url ? "Mettre à jour le logo" : "Sélectionnez le logo"}
                icon={<PublishIcon />}
                />
            </div>
            <div className="agency-image-container">
              { (agencyImages.cover.data || agencyImages.cover.url) ? <img src={`${agencyImages.cover.data || agencyImages.cover.url}`} className="agency-image" alt="Agency Cover" /> : null }
              <FileUpload
                name="cover_image"
                fileName={agencyImages.cover.name || ''}
                onChange={coverImagesChangeHandler('cover')}
                accept="image/png,image/jpg,image/jpeg"
                btnText={agencyImages.cover.url ? "Mettre à jour l'image de couverture personnalisée" : "Sélectionnez l'image de couverture personnalisée"}
                icon={<PublishIcon />}
                />
            </div>
          </div>
          <Button className="mt-20" text="Sauvegarder" onButtonClick={saveChanges} primary />
        </Grid>
        <Grid item sm={5}>
          <div className="preview-container">
            <h4>Aperçu</h4>
            <Button className="mt-3" onButtonClick={generatePreview} text="Générer l'aperçu de la page de couverture" primary />
            { pdfPreviewData && <div className="mt-3">
              <Document file={pdfPreviewData} onLoadSuccess={onDocumentLoadSuccess} renderMode="canvas" loading="Chargement...">
                {previewPage}
              </Document>
            </div> }
            { loadingPreview && <div className="mt-3 preview-loading-container">
              <Loader small primary />
            </div> }
          </div>
        </Grid>
      </Grid>
    )
  }

  return(
    <div className="my-profile cover-page">
      <div className="my-profile__left" ref={settingsSidebar}>
        <div className="my-profile__left_overlay" onClick={settingsSidebarCloseHandler}></div>
        <SettingsSidebar />
      </div>
      <div className="my-profile__right">
        <div className="my-profile__right_head">
          <h1><span onClick={settingsSidebarOpenHandler}><MenuIcon /></span> Page de couverture</h1>
          <SupportMenu />
        </div>
        <div className="my-profile__right_body">
          {user && user && Object.keys(user).length > 0 && user.manufacturer ? renderAgencyImagesInput() : <Loader primary normal small /> }
        </div>
      </div>
    </div>
  );
}

export default AgencyCoverPage