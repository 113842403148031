import React from 'react'

const SenderModalContentCreateSuccess = ({ onCreate, onAssociate, onClose }) => {
  return (
    <div className=''>
      <div className='folder-modal-v2__heading'>
        <h2 className='modal-title'>Création du compte expéditeur</h2>
        <ul className='flex flex-row justify-end gap-2'>
          <li>
            <button
              className='btn btn--medium btn--transparent'
              onClick={onClose}
            >
              Fermer
            </button>
          </li>
        </ul>
      </div>
      <div className='folder-modal-v2__content'>
        <ul className='modal-list'>
          <li className='modal-list-item'>
            <p className='text-success'>
              Votre compte a bien été créé.
            </p>
          </li>
          <li className='modal-list-item'>
            <p>
            Vous recevrez sous 3 jours ouvrés votre <b>identifiant LRE</b> contenant votre <b>clé OTP</b>.
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SenderModalContentCreateSuccess