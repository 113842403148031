import { Switch, Input } from '../ui_new'

const SaveTemplateSection = ({ showSaveTemplate, setShowSaveTemplate, signatureTemplateName, signatureTemplateNameError, setSignatureTemplateName, setSignatureTemplateNameError, saveSignatureTemplate }) => {
  return (
    <div className="signature-modal-v2-inner__section signature-modal-v2__template">
      <p className="signature-form__subtitle signature-form__subtitle--2">Sauvegarder comme modèle d'emplacement de signature</p>
      <div className="checkbox-wrapper">
        <Switch checked={showSaveTemplate} onChange={(e) => setShowSaveTemplate(e.target.checked)} />
      </div>
      {showSaveTemplate && <div className="signature-modal-v2__template_save">
        <Input 
          value={signatureTemplateName}
          onChange={(e) => {
            if(signatureTemplateNameError) setSignatureTemplateNameError('')
            setSignatureTemplateName(e.target.value)
          }}
          placeholder="Nom du modèle"
          error={signatureTemplateNameError}
        />
        <button className="btn" type="button" onClick={saveSignatureTemplate}>Sauvegarder</button>
      </div>}
    </div>
  );
}

export default SaveTemplateSection