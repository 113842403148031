import { Link, useLocation } from 'react-router-dom'

import { AddNewDocument, SearchBar } from '../misc'
import { Home, Juridoc, Redo, Download, Search2 } from '../../assets/icons'
import { useContext, useState, useEffect } from 'react'
import { DocumentsContext, UserContext } from '../../context'
import SupportMenu from '../sections/dashboard/SupportMenu'
import { MobileMenu } from '../../components/shared'
import moment from 'moment'
import { MenuRounded } from '@mui/icons-material'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import GiveFeedbackModal from '../sections/dashboard/GiveFeedbackModal'
import { AttachFile } from '@material-ui/icons';


const Header = ({ showSearch, searchIn, full, fullHeader, pageTitle, hideTitleOnDesktop, titleIcon = null }) => {
  const { isMobile } = useWindowDimensions()

  const location = useLocation();
  const { pathname } = location;
  const { globalReload, lastReloadTime } = useContext(DocumentsContext)

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [showGiveFeedbackModal, setShowGiveFeedbackModal] = useState(false)
  const [isAttachmentsView, setIsAttachmentsView] = useState(false)

  // On hamburger menu click
  const handleToggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  // On dropdown toggle
  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  const handleGlobalReload = () => {
    globalReload()
  }

  useEffect(() => {
    setIsAttachmentsView(window.location.href.includes('attachments'))
  }, [window.location, window.location.href])

  return (
    <header className={`header ${full ? 'header--full' : ''} ${fullHeader && 'header--full-width'}`}>
      {showSearch && ((!isAttachmentsView && !isMobile) || isMobile) && <SearchBar hideInHomepageDesktop={pathname === '/'} searchIn={searchIn} searchBarOpen={searchBarOpen} setSearchBarOpen={setSearchBarOpen} />}
      {(isAttachmentsView && !isMobile) && <div className='header__left '>
        <span className='header__left__icon'> <AttachFile /></span>
        Documents annexés
      </div>}
      {!isAttachmentsView && <div className="header__right">
        <div className={`update ${pathname === '/' && 'homepage'}`}>
          <div className='cursor-pointer' onClick={handleGlobalReload}>
            <Redo />
          </div>
          <span className='text'>Mise à jour </span>
          <span className='date'>{moment(lastReloadTime).format('DD/MM/YYYY')}</span>
        </div>
        {pathname === '/' && <SearchBar searchIn={searchIn} searchBarOpen={searchBarOpen} setSearchBarOpen={setSearchBarOpen} expandable={true} />}
        {/* {pathname === '/' && <button className="search-trigger" onClick={() => setSearchBarOpen(true)}>
          <Search2 />
        </button>} */}
        {!isMobile && <AddNewDocument showDropdown={showDropdown} setShowDropdown={setShowDropdown} handleToggleDropdown={handleToggleDropdown} />}
        {/* <a href="/" className="link" onClick={toggleSupportMenu}><Juridoc /></a> */}
        {/* Support menu */}
        {/* {partner && !partner.includes('mls') && (
          <div className='support-menu'>
            <SupportMenu />
          </div>
        )} */}
      </div>}
      <div className="main-layout__body_mobile">

        <p className={`highlighted ${hideTitleOnDesktop && 'highlighted--hide'}`}>
          {!hideTitleOnDesktop && titleIcon && <div className='header__left__icon'>{titleIcon}</div>}
          <span>{pageTitle}</span>
        </p>
        <button className="search-trigger mobile" onClick={() => setSearchBarOpen(true)}>
          <Search2 />
        </button>
        <div className={"hamburger-menu"} onClick={handleToggleMobileMenu}>
          <MenuRounded />
        </div>

        {showMobileMenu && <MobileMenu showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          handleToggleMobileMenu={handleToggleMobileMenu}
          handleToggleDropdown={handleToggleDropdown}
          setShowGiveFeedbackModal={setShowGiveFeedbackModal}
        />}
        {showGiveFeedbackModal && <GiveFeedbackModal onClose={() => setShowGiveFeedbackModal(false)} />}

      </div>

    </header>
  )
}

export default Header