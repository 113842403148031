const Fave = () => {
  return (
    <span className="svg-icon">


      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 2.82422C14.76 2.82422 13.09 3.63422 12 4.91422C10.91 3.63422 9.24 2.82422 7.5 2.82422C4.42 2.82422 2 5.24422 2 8.32422C2 12.1042 5.4 15.1842 10.55 19.8642L12 21.1742L13.45 19.8542C18.6 15.1842 22 12.1042 22 8.32422C22 5.24422 19.58 2.82422 16.5 2.82422ZM12.1 18.3742L12 18.4742L11.9 18.3742C7.14 14.0642 4 11.2142 4 8.32422C4 6.32422 5.5 4.82422 7.5 4.82422C9.04 4.82422 10.54 5.81422 11.07 7.18422H12.94C13.46 5.81422 14.96 4.82422 16.5 4.82422C18.5 4.82422 20 6.32422 20 8.32422C20 11.2142 16.86 14.0642 12.1 18.3742Z" />
      </svg>


    </span>
  )
}

export default Fave