import { baseUrl, get_request, patch_request } from './firestore'

const fetch_help_articles = async () => {
  return await get_request(`${baseUrl}/support_articles`)
}

const fetch_help_categories = async () => {
  return await get_request(`${baseUrl}/support_categories`)
}

const fetch_features = async () => {
  return await get_request(`${baseUrl}/support_features`)
}

const update_feature = async (id, data) => {
  return await patch_request(`${baseUrl}/support_features/${id}`, data)
}

const increment_article_read_count = async (id) => {
  return patch_request(`${baseUrl}/increment_article_read_count/${id}`, {})
}

export {
  fetch_help_articles,
  fetch_help_categories,
  fetch_features,
  update_feature,
  increment_article_read_count,
}