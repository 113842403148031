import React, { useEffect, useState, useCallback, useRef, useMemo, useContext } from 'react'
import { Document, Page } from 'react-pdf'
import { ArrowRight, ArrowLeft, FirstPage, LastPage } from '@material-ui/icons'
import { Slider } from '@material-ui/core'

import Button from '../../UI/Button'
import IconButton from '../../UI/IconButton'
import Modal from '../../UI/Modal'
import { isMlsPartner } from '../../../utils'
import { UserContext } from '../../../context/user/userState'

const PlaceSignatureInterfaceModalV2 = ({ onClose, onSignaturePositionSave, base64Data, boxes, onSetBoxes, currentBox = null, signaturesBox, onSetSignaturesBox, allSigners }) => {
  const [docPages, setDocPages] = useState([])
  const [selectedPage, setSelectedPage] = useState(0)
  const [activeBox, setActiveBox] = useState(null)
  const [shouldMove, setShouldMove] = useState(false)
  const [moving, setMoving] = useState(false)
  const [activeBoxOffsetX, setActiveBoxOffsetX] = useState(null)
  const [activeBoxOffsetY, setActiveBoxOffsetY] = useState(null)
  const [actionType, setActionType] = useState('move')
  const [widthValue, setWidthValue] = useState(200)
  const [heightValue, setHeightValue] = useState(100)
  const [horizMarginValue, setHorizMarginValue] = useState(15)
  const [vertMarginValue, setVertMarginValue] = useState(15)
  const [sBoxSet, setSBoxSet] = useState(false)
  const [signaturesBoxCopy, setSignaturesBoxCopy] = useState({})
  const [boxesCopy, setBoxesCopy] = useState(JSON.parse(JSON.stringify(boxes)))
  const overlayRef = useRef()
  const signaturesBoxRef = useRef()

  const { partner } = useContext(UserContext)

  // Set active box from outside
  useEffect(() => {
    if(currentBox) {
      setActiveBox(currentBox)
      setSelectedPage(currentBox.page)
    }
  }, [currentBox])

  // Watch for signatureBox changes 
  useEffect(() => {
    setSignaturesBoxCopy(JSON.parse(JSON.stringify(signaturesBox)))
  }, [signaturesBox])

  // Set sliders values if signatures box active
  useEffect(() => {
    if(signaturesBoxCopy.active && !sBoxSet) {
      setSelectedPage(signaturesBoxCopy.page)
      let diff = 300 - 100
      let current = signaturesBoxCopy.boxW
      let sliderVal = (current - 100) / diff 
      setWidthValue(sliderVal * 100)
      let diffH = 150 - 50
      let currentH = signaturesBoxCopy.boxH
      let sliderValH = (currentH - 50) / diffH
      setHeightValue(sliderValH * 100)
      let diffMH = 60 - 15
      let currentMH = signaturesBoxCopy.mH
      let sliderValMH = (currentMH - 15) / diffMH
      setHorizMarginValue(sliderValMH * 100)
      let diffMV = 60 - 15
      let currentMV = signaturesBoxCopy.mV
      let sliderValMV = (currentMV - 15) / diffMV
      setVertMarginValue(sliderValMV * 100)
      setSBoxSet(true)
    }
  }, [signaturesBoxCopy, sBoxSet])

  // Close handler
  const closeHandler = () => {
    if(boxesCopy.find(box => !box.saved)) {
      onSetBoxes(boxesCopy.filter(box => box.saved))
    }
    if(signaturesBoxCopy.active) {
      onSetSignaturesBox({...signaturesBoxCopy, active: false})
    }
    onClose()
  }

  // Save handler 
  const saveHandler = () => {
    if(activeBox) {
      let active = {...activeBox, page: selectedPage} 
      // active = boxes.find(b => !b.disabled)
      // active = {...active, page: selectedPage}
      onSetBoxes(boxesCopy.map(box => box.id === active.id ? {...active, saved: true} : box))
      onSignaturePositionSave({...active, saved: true}, currentBox ? 'edit' : 'create')
    }
    if(signaturesBoxCopy.active) {
      onSetSignaturesBox({...signaturesBoxCopy, page: selectedPage, active: false})
      onClose()
    }
  }

  // On document load success
  const handleDocumentLoadSuccess = (numPages) => {
    let arr = []
    for(let i = 1; i < numPages + 1; i++) {
      arr.push(<Page key={i} pageNumber={i} width={774} height={1094} className={`page-${i}`} renderTextLayer={true} loading={'Chargement...'} />)
    }
    setDocPages(arr)
  }

  // On show first page
  const handleShowFirstPage = () => {
    setSelectedPage(0)
  }

  // On show prev page
  const handleShowPrevPage = () => {
    setSelectedPage(selectedPage - 1)
  }

  // On show next page
  const handleShowNextPage = () => {
    setSelectedPage(selectedPage + 1)
  }

  // On show first page
  const handleShowLastPage = () => {
    setSelectedPage(docPages.length - 1)
  }

  // On page change
  const handleSelectPageChange = (e) => {
    setSelectedPage(+e.target.value)
  }

  // On mouse move - only when there is active resizable column
  const handleMouseMove = useCallback((e) => {
    if(activeBox !== null && shouldMove) {
      const el = overlayRef.current
      if(el) {
        const { left, top, width, height } = el.getBoundingClientRect()
        const current = boxesCopy.find(item => item.id === activeBox.id)
        if(actionType === 'move') { // when moving box
          if(current) {
            // top position
            let topPos
            if(e.pageY - top - activeBoxOffsetY - window.scrollY < 0) topPos = 0
            else if(e.pageY - top - activeBoxOffsetY - window.scrollY > height - activeBox.height - 2) topPos = height - activeBox.height - 2
            else topPos = e.pageY - top - activeBoxOffsetY - window.scrollY
            current.top = topPos
  
            // left position
            let leftPos
            if(e.pageX - left - activeBoxOffsetX - window.scrollX < 0) leftPos = 0
            else if(e.pageX - left - activeBoxOffsetX - window.scrollX > width - activeBox.width) leftPos = width - activeBox.width
            else leftPos = e.pageX - left - activeBoxOffsetX - window.scrollX
            current.left = leftPos
  
            // set page
            current.page = selectedPage
          }
        } else if(actionType === 'change-w') { // when changing width
          if(current) {
            // width
            let boxWidth
            let cWidth = current.width
            if(e.pageX - left - window.scrollX <= current.left + current.width){ 
              boxWidth = cWidth - ((current.left + current.width) - (e.pageX - left - window.scrollX))
              if(boxWidth < 100) {
                boxWidth = 100
              }
            }else if(e.pageX - left - window.scrollX > current.left + current.width) {
              boxWidth = cWidth + ((e.pageX - left - window.scrollX) - (current.left + current.width))
            }
            current.width = boxWidth
          }
        } else if(actionType === 'change-h') { // when changing height
          if(current) {
            // height
            let boxHeight
            let cHeight = current.height
            if(e.pageY - top - window.scrollY <= current.top + current.height){ 
              boxHeight = cHeight - ((current.top + current.height) - (e.pageY - top - window.scrollY))
              if(boxHeight < 50) {
                boxHeight = 50
              }
            }else if(e.pageY - top - window.scrollY > current.top + current.height) {
              boxHeight = cHeight + ((e.pageY - top - window.scrollY) - (current.top + current.height))
            }
            current.height = boxHeight
          }
        }
        setActiveBox(current)
        const updatedItems = boxesCopy.map(item => item.id === activeBox.id ? current : item)
        setBoxesCopy(updatedItems)
      }
    }else if(signaturesBoxCopy.active && shouldMove) {
      const el = overlayRef.current
      if(el) {
        const { left, top, width, height } = el.getBoundingClientRect()
        const sWidth = signaturesBoxCopy.width 
        const sHeight = signaturesBoxCopy.height
        
        let current = {...signaturesBoxCopy}
        // top position
        let topPos
        if(e.pageY - top - activeBoxOffsetY - window.scrollY < 0) topPos = 0
        else if(e.pageY - top - activeBoxOffsetY - window.scrollY > height - sHeight - 2 + signaturesBoxCopy.mV) topPos = height - sHeight - 2 + signaturesBoxCopy.mV
        else topPos = e.pageY - top - activeBoxOffsetY - window.scrollY
        current.top = topPos

        // left position
        let leftPos
        if(e.pageX - left - activeBoxOffsetX - window.scrollX < 0) leftPos = 0
        else if(e.pageX - left - activeBoxOffsetX - window.scrollX > width - sWidth + signaturesBoxCopy.mH) leftPos = width - sWidth + signaturesBoxCopy.mH
        else leftPos = e.pageX - left - activeBoxOffsetX - window.scrollX
        current.left = leftPos

        // set page
        current.page = selectedPage
        setSignaturesBoxCopy(current)
      }
    }
  }, [activeBox, shouldMove, activeBoxOffsetX, activeBoxOffsetY, selectedPage, actionType, signaturesBoxCopy, boxesCopy])

  // On mouse up - reset active resizable column
  const handleMouseUp = () => {
    setShouldMove(false)
    setMoving(false)
  }

  // On mouse down - set active resizable column
  const handleMouseDown = (e, box, idx, type = 'move') => {
    // x and y position where the click happened within a box - for example in top left corner of a box position is 0,0
    setActiveBoxOffsetX(e.nativeEvent.offsetX > 0 ? e.nativeEvent.offsetX : 0)
    setActiveBoxOffsetY(e.nativeEvent.offsetY > 0 ? e.nativeEvent.offsetY : 0)

    setActionType(type)
    setShouldMove(true)
    setMoving(true)
    setActiveBox({ ...box, index: idx })
  }

  // On mouse down - signatures box
  const handleSBoxMouseDown = (e) => {
    setActiveBoxOffsetX(e.nativeEvent.offsetX > 0 ? e.nativeEvent.offsetX : 0)
    setActiveBoxOffsetY(e.nativeEvent.offsetY > 0 ? e.nativeEvent.offsetY : 0)

    setShouldMove(true)
    setMoving(true)
  }
  
  // Check for boundaries
  const checkForBoundaries = useCallback(() => {
    const el = signaturesBoxRef.current
    const overlayEl = overlayRef.current
    let leftPos = null
    let topPos = null
    if(el && overlayEl) {
      const { left: elLeft, width: elWidth, top: elTop, height: elHeight } = el.getBoundingClientRect()
      const { left: overlayElLeft, width: overlayElWidth, top: overlayElTop, height: overlayElHeight } = overlayEl.getBoundingClientRect()
      if((elLeft - overlayElLeft - window.scrollX > overlayElWidth - elWidth + signaturesBoxCopy.mH)) {
        leftPos = overlayElWidth - elWidth + signaturesBoxCopy.mH
      }
      if(elTop - overlayElTop - window.scrollY > overlayElHeight - elHeight - 2 + signaturesBoxCopy.mV) {
        topPos = overlayElHeight - elHeight - 2 + signaturesBoxCopy.mV
      }
    }
    return { leftPos, topPos }
  }, [signaturesBoxCopy.mH, signaturesBoxCopy.mV])

  // On slider change 
  const handleSliderChange = (e, newVal, type) => {
    if(type === 'width') {
      setWidthValue(newVal)
      let min = 100
      let max = 300
      let diff = max - min
      let current = diff * (newVal / 100) + min 
      let box = {...signaturesBoxCopy}
      box.boxW = current 
      box.width = box.boxes.length === 1 ? current + box.mH : current * 2 + box.mH * 2
      const { leftPos } = checkForBoundaries()
      if(leftPos) {
        box.left = leftPos
      }
      setSignaturesBoxCopy(box)
    }else if(type === 'height') {
      setHeightValue(newVal)
      let min = 50
      let max = 150
      let diff = max - min
      let current = diff * (newVal / 100) + min 
      let box = {...signaturesBoxCopy}
      box.boxH = current 
      box.height = box.boxes.length < 3 ? box.boxH + box.mV : box.boxH * Math.ceil(box.boxes.length / 2) + box.mV * Math.ceil(box.boxes.length / 2)
      const { topPos } = checkForBoundaries()
      if(topPos) {
        box.top = topPos
      }
      setSignaturesBoxCopy(box)
    }else if(type === 'marginH') {
      setHorizMarginValue(newVal)
      let min = 15
      let max = 60
      let diff = max - min
      let current = diff * (newVal / 100) + min 
      let box = {...signaturesBoxCopy}
      box.mH = current 
      box.width = box.boxes.length === 1 ? box.boxW + current : box.boxW * 2 + current * 2
      const { leftPos } = checkForBoundaries()
      if(leftPos) {
        box.left = leftPos
      }
      setSignaturesBoxCopy(box)
    }else if(type === 'marginV') {
      setVertMarginValue(newVal)
      let min = 15
      let max = 60
      let diff = max - min
      let current = diff * (newVal / 100) + min 
      let box = {...signaturesBoxCopy}
      box.mV = current 
      box.height = box.boxes.length < 3 ? box.boxH + current : box.boxH * Math.ceil(box.boxes.length / 2) + current * Math.ceil(box.boxes.length / 2)
      const { topPos } = checkForBoundaries()
      if(topPos) {
        box.top = topPos
      }
      setSignaturesBoxCopy(box)
    }
  }
  
  return (
    <Modal onClose={closeHandler} className="onespan-placement-modal">
      <div className="choose-signature-position">
        <div className="choose-signature-position__head">
          <IconButton primaryLight medium icon={<FirstPage />} onButtonClick={handleShowFirstPage} disabled={selectedPage === 0} />
          <IconButton primaryLight medium icon={<ArrowLeft />} onButtonClick={handleShowPrevPage} disabled={selectedPage === 0} />
          <select onChange={handleSelectPageChange} value={selectedPage}>
            {Array.from(Array(docPages.length).keys()).map((_, idx) => (
              <option key={idx} value={idx}>{idx + 1}</option>
            ))}
          </select>
          <IconButton primaryLight medium icon={<ArrowRight />} onButtonClick={handleShowNextPage} disabled={selectedPage === docPages.length - 1} />
          <IconButton primaryLight medium icon={<LastPage />} onButtonClick={handleShowLastPage} disabled={selectedPage === docPages.length - 1} />
        </div>
        <div className="choose-signature-position__body">
          <div className="doc-preview">
            <Document 
              file={`data:application/pdf;base64,${base64Data}`} 
              onLoadSuccess={({ numPages }) => handleDocumentLoadSuccess(numPages)} 
              renderMode="canvas" 
              loading={'Chargement...'}
            >
              {docPages[selectedPage]}
            </Document>
          </div>
          <div className="doc-overlay" ref={overlayRef} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
            {signaturesBoxCopy.boxes?.length > 0 && (
              <>
              {signaturesBoxCopy.page === selectedPage && !signaturesBoxCopy.active && (
                <div 
                  className={"boxes-wrapper"}
                  style={{top: signaturesBoxCopy.top, left: signaturesBoxCopy.left, width: signaturesBoxCopy.width, height: signaturesBoxCopy.height}}
                  ref={signaturesBoxRef}
                >
                  {signaturesBoxCopy.boxes.map(box => (
                    <div key={box.id} style={{width: signaturesBoxCopy.boxW, height: signaturesBoxCopy.boxH, marginRight: signaturesBoxCopy.mH, marginBottom: signaturesBoxCopy.mV}} className={"s-box disabled"}>
                      {allSigners.find(s => s.id === box.parentId) 
                        ? allSigners.find(s => s.id === box.parentId).email.value && <span>{allSigners.find(s => s.id === box.parentId).email.value}</span> 
                        : ''
                      }
                    </div>
                  ))}
                </div>
              )}
              {signaturesBoxCopy.active && (
                <div 
                  className={moving ? "boxes-wrapper moving" : "boxes-wrapper"}
                  style={{top: signaturesBoxCopy.top, left: signaturesBoxCopy.left, width: signaturesBoxCopy.width, height: signaturesBoxCopy.height}}
                  onMouseDown={(e) => handleSBoxMouseDown(e)}
                  ref={signaturesBoxRef}
                >
                  {signaturesBoxCopy.boxes.map(box => (
                    <div key={box.id} style={{width: signaturesBoxCopy.boxW, height: signaturesBoxCopy.boxH, marginRight: signaturesBoxCopy.mH, marginBottom: signaturesBoxCopy.mV}} className={"s-box"}>
                      {allSigners.find(s => s.id === box.parentId) 
                        ? allSigners.find(s => s.id === box.parentId).email.value && <span>{allSigners.find(s => s.id === box.parentId).email.value}</span> 
                        : ''
                      }
                    </div>
                  ))}
                </div>
              )}
              </>
            )}
            {boxesCopy.map((box, idx) => {
              let boxStyle = {
                top: box.top, 
                left: box.left, 
                width: box.width, 
                height: box.height, 
                zIndex: box.disabled ? 5 : 10
              }
              if(box.disabled) {
                if(+box.page === +selectedPage) {
                  return (
                    <div 
                      key={box.id} 
                      className={"box disabled"} 
                      style={boxStyle} 
                    >
                    </div>
                  )
                }else {
                  return null
                }
              }else {
                return (
                  <div 
                    key={box.id} 
                    className={"box"} 
                    style={boxStyle} 
                    // onMouseDown={(e) => handleMouseDown(e, box, idx)}
                  >
                    <div className={moving ? "box__move moving" : "box__move"} onMouseDown={(e) => handleMouseDown(e, box, idx)}></div>
                    {box.type !== 'checkbox' && (
                      <>
                        <div className="box__expand-width" onMouseDown={(e) => handleMouseDown(e, box, idx, 'change-w')}></div>
                        <div className="box__expand-height" onMouseDown={(e) => handleMouseDown(e, box, idx, 'change-h')}></div>
                      </>
                    )}
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
      {signaturesBoxCopy.active && <div className="placement-options-wrapper">
        <div className="placement-options">
          <div className="placement-options__row">
            <p>Largeur :</p>
            <div className="slider-wrapper">
              <Slider 
                value={widthValue} 
                onChange={(e, newVal) => handleSliderChange(e, newVal, 'width')} 
                aria-labelledby="continuous-slider" 
              />
            </div>
          </div>
          <div className="placement-options__row">
            <p>Hauteur :</p>
            <div className="slider-wrapper">
              <Slider 
                value={heightValue} 
                onChange={(e, newVal) => handleSliderChange(e, newVal, 'height')} 
                aria-labelledby="continuous-slider" 
              />
            </div>
          </div>
          <div className="placement-options__row">
            <p>Marge horizontale :</p>
            <div className="slider-wrapper">
              <Slider 
                value={horizMarginValue} 
                onChange={(e, newVal) => handleSliderChange(e, newVal, 'marginH')} 
                aria-labelledby="continuous-slider" 
              />
            </div>
          </div>
          <div className="placement-options__row">
            <p>Marge verticale :</p>
            <div className="slider-wrapper">
              <Slider 
                value={vertMarginValue} 
                onChange={(e, newVal) => handleSliderChange(e, newVal, 'marginV')} 
                aria-labelledby="continuous-slider" 
              />
            </div>
          </div>
        </div>
      </div>}
      <div className="buttons-wrapper">
        <Button text={isMlsPartner(partner) ? 'Soumettre' : 'Sauvegarder'} onButtonClick={saveHandler} primary medium />
      </div>
    </Modal>
  )
}

export default PlaceSignatureInterfaceModalV2