import { useState, useEffect, useContext } from 'react'
import moment from 'moment'

import { Redo, LongArrowAltRight, Pen, Leading } from '../../assets/icons'
import { CustomTooltip } from './'
import { FEATURE, getFirstLetter, isFeatureEnabled, timeDisplayFormat } from '../../utils'
import { DocumentsContext, RegisteredMailContext, UserContext } from "../../context"
import useVialinkActions from '../../hooks/useVialinkActions'
import { Download } from '../../assets/icons'
import { SIGNATURE_STATUS_TRANLSATIONS } from '../../constants'
import { Person } from '@material-ui/icons'
import { ManageSearch } from '@mui/icons-material'

const SignaturesTableRow = ({ id, signature, selectedSignatures, onSetSelectedSignatures, allChecked, onOpenStatusPanel, onTitleClick = () => { }, onOpenSignatureDetail, onDownload, isEditDisabled }) => {
  const { refreshStatus } = useVialinkActions()
  const { documents } = useContext(DocumentsContext)
  const { createAr24MailRequestFromSignature } = useContext(RegisteredMailContext)
  const { siteConfigs } = useContext(UserContext)
  // const { fetchSignaturePreview } = useSignaturesActions()
  const [checked, setChecked] = useState(false)
  const [updatedAt, setUpdatedAt] = useState('')
  const [refreshing, setRefreshing] = useState(false)

  // Set checked to false if selectedSignatures length is zero
  useEffect(() => {
    if (checked && selectedSignatures.length === 0) {
      setChecked(false)
    }
  }, [selectedSignatures, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if (allChecked) {
      setChecked(true)
    }
  }, [allChecked])

  // Set updatedAt
  useEffect(() => {
    if (signature?.meta?.updated) {
      setUpdatedAt(moment(signature.meta.updated).format('D MMM YYYY HH:mm'))
    } else if (signature?.meta?.created) {
      setUpdatedAt(moment(signature.meta.created).format('D MMM YYYY HH:mm'))
    }
  }, [signature])

  // On signature select
  const handleSelectSignature = () => {
    setChecked(!checked)
    if (checked) {
      onSetSelectedSignatures(prev => prev.filter(s => s.id !== id))
    } else {
      onSetSelectedSignatures(prev => [...prev, signature])
    }
  }

  // Get recipients as array of object with short value(first letter) and full value
  const getRecipients = (mobile = false) => {
    const recipients = [...signature.sort_recipients]
    let showNumberCircle = false
    let number = 0
    if (!mobile && recipients.length > 4) {
      showNumberCircle = true
      number = recipients.length - 3
    } else if (mobile && recipients.length > 2) {
      showNumberCircle = true
      number = recipients.length - 1
    }
    let arr = []
    let iterationNum = !mobile ? 3 : 1;
    if (showNumberCircle) {
      for (let i = 0; i < iterationNum; i++) {
        arr.push({ short_value: getFirstLetter(recipients[i]), value: recipients[i] })
      }
      arr.push({ short_value: `+${number}`, value: recipients.slice(iterationNum, recipients.length).join(','), num: true })
    } else {
      for (let i = 0; i < recipients.length; i++) {
        arr.push({ short_value: getFirstLetter(recipients[i]), value: recipients[i] })
      }
    }
    return arr
  }

  // On status click
  const handleStatusClick = () => {
    if (signature.status === 'IN_PROGRESS') {
      onOpenStatusPanel(signature)
    }
  }

  // On status refresh click
  const handleStatusRefreshClick = async () => {
    refreshStatus({ signature, setRefreshing })
  }

  // On title click
  const handleTitleClick = () => {
    onTitleClick(signature)
  }

  const handleSendRegisteredMail = () => {
    createAr24MailRequestFromSignature(signature)
  }

  const translatedStatus = SIGNATURE_STATUS_TRANLSATIONS[signature.status] || signature.status;

  const download = (signature) => {
    onDownload(signature)
  }

  return (
    <div className={`signatures-table-row ${checked ? 'selected' : ''}`}>
      {/* <div className="signatures-table-column zero-width"> */}
      {/* <div className={`signatures-table-column__check ${checked ? 'checked' : ''}`} onClick={handleSelectSignature}>
          <Check />
        </div> */}
      {/* </div> */}
      <div className="signatures-table-column align-left !pl-2">
        <div className='flex mx-2 gap-2'>
          <CustomTooltip content={`Modifier`} position='top-left'>
            <button className='icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded' disabled={isEditDisabled} onClick={() => onOpenSignatureDetail(signature)}>
              <Pen />
            </button>
          </CustomTooltip>
          <CustomTooltip content={`Télécharger l'ensemble des documents`} position='top-left'>
            <button className='icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded' disabled={signature.status !== "COMPLETED"} onClick={() => download(signature)}><Download /></button>
          </CustomTooltip>

        </div>
        <CustomTooltip content={signature.title}>
          <p className='signature-title line-clamp-2' onClick={handleTitleClick}>{`${signature.title}`}</p>
        </CustomTooltip>
      </div>
      <div className="signatures-table-column align-left date-column">
        <div>
          <p>{moment(signature.meta?.created).format('DD/MM/YYYY')}</p>
          <p className='time mt-1'>{moment(signature.meta?.created).format(timeDisplayFormat)}</p>
        </div>
      </div>
      <div className="signatures-table-column align-left recipients-column">
        <p>
          <div className='recipients-tooltip'>
            <b><Person /> Destinataires</b>
            {signature.recipients.map((r, idx) => {
              return (
                <span className={``}>{r.firstName} {r.lastName} ({r.email});</span>
              )
            })}
          </div>
          <span className={`name`}>
            {signature.recipients.map((r, idx) => {
              if (idx === 0) {
                return <>{r.firstName} {r.lastName}</>
              }
              if (idx === 1) {
                return <>, {r.firstName[0]}...</>
              }
              return null;
            })}
          </span>
          {signature?.recipients?.length > 1 && <span className={`number`}>+{signature?.recipients?.length - 1}</span>}
        </p>
      </div>
      <div className="signatures-table-column align-left recipients-column">
        <p>
          <div className='w-full'>
            <div className='recipients-tooltip'>
              <b><Person /> Envoyé par</b>
              <span className={``}>{signature.sender.firstName} {signature.sender.lastName} ({signature.sender.email})</span>
            </div>
            <span className="truncate block w-full">{signature.sender.firstname} {signature.sender.lastname}</span>
          </div>
        </p>
      </div>
      <div className="signatures-table-column align-left small-text-column status-column">

        {/* {signature.status === "IN_PROGRESS" &&
          <CustomTooltip content={`Dernier statut vérifié le ${moment(signature.status_updated_at).format('DD MMM YYYY à HH[h]mm')}`} >
            <button className={`icon-btn icon-btn--transparent refresh-status ${refreshing ? 'refreshing' : ''}`} onClick={handleStatusRefreshClick}><Redo /></button>
          </CustomTooltip>
        } */}
        <p className={`${signature.status}`} onClick={handleStatusClick}>
          <span >{translatedStatus}</span>
        </p>
        {signature.status === "IN_PROGRESS" &&
          <button className={`icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded ml-1`} onClick={handleStatusClick}><ManageSearch /></button>
        }
      </div>
      <div className="signatures-table-column align-left date-column">
        <p>{moment(signature.expires, "YYYY-MM-DD").format("DD/MM/YYYY")}</p>
      </div>
      <div className="signatures-table-column">
        {isFeatureEnabled(FEATURE.AR24, siteConfigs) && <button className='button button--with-icon button--outline-primary-light rounded-sm whitespace-nowrap'
          disabled={signature.status !== "COMPLETED"} onClick={handleSendRegisteredMail}>
          <Leading />
          Envoyer par LRE
        </button>}
      </div>
    </div>
  )
}

export default SignaturesTableRow