import React, { Fragment, useState, useCallback, useEffect, useRef, useContext } from 'react';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PersonIcon from '@material-ui/icons/Person';
import EventIcon from '@material-ui/icons/Event';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolderOutlined';

import FilterListItem from '../../UI/FilterListItem';
import FolderListItem from '../../UI/FolderListItem';

import { STATUS_LABELS_SHORT } from '../../../constants';
import { getAllParentFolders } from '../../../utils';
import { NotificationContext } from '../../../context/notifications/notificationState';
import { LoaderContext } from '../../../context/loader/loaderState';
import { DocumentsFoldersContext } from '../../../context/documentsFolders/documentsFoldersState';
import { DocumentsContext } from '../../../context/documents/documentsState';
import Dropdown from '../../UI/Dropdown';
import { UserContext } from '../../../context/user/userState';

const DocumentsSidebar = ({ onFilter, onGetAll, onFilterByStatus, months, onFilterByDate, restored, onSetRestored, selected, onSetSelected, onSetCurrentFilter, documents, folders, onFolderCreate, onFolderDelete, onFolderEdit, onFolderFilter, selectedFilter, onOpenFolderModal, onMoveToFolder, foldersLoading, documentsLoading, onMoveTemplateToFolder, onMoveDocumentToFolder }) => {
  const { setNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const { updateDocFolder, currentlyActiveDocFolder } = useContext(DocumentsFoldersContext);
  const { updateDocument, currentFilter } = useContext(DocumentsContext);
  const { partner } = useContext(UserContext)
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownChoices = [{ label: 'Tous', value: 'all'}, { label: 'Archives', value: 'archived' }, { label: 'Supprimés', value: 'deleted' }];
  const dropdownEl = useRef();
  const statusListEl = useRef();

  const handleClickOutside = useCallback((e) => {
    if(showDropdown && e.target.closest('.selected-documents__dropdown') !== dropdownEl.current) {
      setShowDropdown(!showDropdown);
    }
  }, [showDropdown, setShowDropdown]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [handleClickOutside]);

  const dropdownItemChangeHandler = (choice) => {
    const { value, label } = choice;
    onSetSelected(choice);
    setShowDropdown(false);
    if(selected.value !== value) {
      if(value !== 'all') {
        onFilter({ filterBy: value, filterComparison: '==', filterValue: true }, label);
      }else if(value === 'all' && restored){
        onFilter(null, label);
        onSetRestored(false);
        onSetCurrentFilter('all');
      }else {
        onGetAll(label);
      }
    }
  }

  const filterClickHandler = (status, e) => {
    const liEl = e.target.closest('li');
    const hasActiveClass = liEl.classList.contains('active');
    onFilterByStatus(status, hasActiveClass);
    removeActiveClassFromStatus();
    if(hasActiveClass){
      e.target.closest('li').classList.remove('active');
    }else {
      e.target.closest('li').classList.add('active');
    }
  }

  const removeActiveClassFromStatus = () => {
    statusListEl.current.querySelectorAll('li').forEach(li => li.classList.contains('active') && li.classList.remove('active'));
  }

  // Drag over - all
  const dragOverHandler = (e) => {
    e.preventDefault();
    const folderBox = e.target.closest('.item-inner');
    if(!folderBox.classList.contains('hover')) {
      folderBox.classList.add('hover');
    }
  }

  // Drag leave - all
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    const folderBox = e.target.closest('.item-inner');
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }
  }

  // On drop - all
  const dropHandler = async(e) => {
    const folderBox = e.target.closest('.item-inner');
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }

    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null;
    
    if(data) {
      if(!data.parentFolder) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier racine`, type: 'warning' });
  
      setLoading(true);
      await updateDocFolder({ parentFolder: null }, data.id);
      setLoading(false);
      setNotification({ msg: `Le dossier "${data.name}" a été déplacé vers le dossier racine`, type: 'default' }); 
      return;
    }

    const documentData = e.dataTransfer.getData('document') ? JSON.parse(e.dataTransfer.getData('document')) : null;
    
    if(documentData) {
      let doc = {...documentData};
      if(!doc.folderId) {
        doc = {...documentData, folderId: []};
      }

      if(doc.folderId.length === 0)
        return setNotification({ msg: `Impossible de déplacer le document "${doc.name}" vers le dossier racine`, type: 'warning' });

      const foldersArr = [...doc.folderId];
      if(currentlyActiveDocFolder && foldersArr.includes(currentlyActiveDocFolder.id)) {
        const updatedFoldersArr = foldersArr.filter(fId => fId !== currentlyActiveDocFolder.id);
        setLoading(true);
        await updateDocument({ folderId: updatedFoldersArr }, doc);
        setLoading(false);
        setNotification({ msg: `Le document "${doc.name}" a été déplacé dans le dossier racine!`, type: 'default' });
      }    
    }
  }

  return(
    <Fragment>
      <h3>Documents</h3>
      <div className="selected-documents">
        <div className="selected-documents__selected">
          <span className="icon"><FolderOutlinedIcon /></span>
          <span className="text">{selected.label}</span>
          <span className="arrow" onClick={() => setShowDropdown(!showDropdown)}><ArrowDropDownIcon /></span>
        </div>
        {/* {showDropdown && <ul className="selected-documents__dropdown">
          {dropdownChoices.map((choice, index) => (
            <li key={index} onClick={() => dropdownItemChangeHandler(choice)}>{choice.label}</li>
          ))}
        </ul>} */}
        <Dropdown show={showDropdown} onChange={setShowDropdown} animate className="selected-documents__dropdown">
          <ul className="selected-documents__dropdown">
            {dropdownChoices.map((choice, index) => (
              <li key={index} onClick={() => dropdownItemChangeHandler(choice)}>{choice.label}</li>
            ))}
          </ul>
        </Dropdown>
      </div>
      <section className="filters" style={{position: 'relative', zIndex: '1'}}>
        <h6>filtres</h6>
        <ul className="filters__list">
          <FilterListItem icon={<PersonIcon />} text="Utilisateurs" />
          <FilterListItem 
            icon={<EventIcon />} 
            text="Créer après" 
            dropdownValues={months} 
            onItemSelect={onFilterByDate} 
            time="after" 
            />
          <FilterListItem 
            icon={<EventNoteIcon />} 
            text="Créer avant"
            dropdownValues={months} 
            onItemSelect={onFilterByDate} 
            time="before" 
          />
        </ul>
      </section>

      {currentFilter === 'all' && 
      <Fragment>
        <section className="filters">
          <h6>Dossiers</h6>
          <ul className="filters__list folders">
            <li className={selectedFilter === 'all' ? 'filters__list_item active' : 'filters__list_item'}>
              <div className="item-inner" onDrop={dropHandler} onDragOver={dragOverHandler} onDragLeave={dragLeaveHandler}>
                <p onClick={() => onFolderFilter(null)}>Tous les Dossiers</p> 
                <span className="icon-right" onClick={(e) => onFolderCreate(e)}><CreateNewFolderIcon /></span>
              </div>
            </li>
            {folders.map(folder => {
              if(!folder.parentFolder) {
                return <FolderListItem 
                  key={folder.id} 
                  folder={folder} 
                  onCreateFolder={onFolderCreate}
                  onEdit={onFolderEdit} 
                  onDelete={onFolderDelete} 
                  onFilter={onFolderFilter}
                  selectedFilter={selectedFilter} 
                  onOpenFolderModal={onOpenFolderModal}
                  onMoveToFolder={onMoveToFolder}
                  folders={folders}
                  foldersLoading={foldersLoading}
                  templates={documents}
                  templatesLoading={documentsLoading}
                  onMoveTemplateToFolder={onMoveTemplateToFolder}
                  view="documents"
                  onMoveDocumentToFolder={onMoveDocumentToFolder}
                />;
              }
              return null;
            })}
          </ul>
        </section>

        <section className="statuses">
          <h6>statuts</h6>
          <ul ref={statusListEl}>
            {Object.entries(STATUS_LABELS_SHORT).map((status, index) => (
              <li key={index} className={status[0]} onClick={(e) => filterClickHandler(status[0], e)}><span></span> {status[1]}</li>
            ))}
          </ul>
        </section>
      </Fragment>
      }
    </Fragment>
  );
}

export default DocumentsSidebar;