import React, { useEffect, useState, useContext } from 'react'
import { Modal } from '../../ui_new'
import { Close } from '@material-ui/icons'

const DocumentationModal = ({ onClose, previewLoading, previewError, iframeSrc }) => {
  return (
    <Modal onClose={onClose} className="modal--padding-sm">
      {previewLoading
        ? <div className="loader-wrapper"><div className="loader-v2"></div></div>
        : iframeSrc
          ? <div>
            <div className='mb-5 folder-modal-v2__heading'>
              <div>
                <span>Si le document ne s’affiche pas,&nbsp;&nbsp;</span>
                <a className='button' href={iframeSrc} target="_blank" rel="noreferrer" download="signature-preview">téléchargez-le ici</a>
              </div>
              <button className="btn btn--transparent" onClick={onClose}><Close /></button>
            </div>
            <iframe title="Pdf" src={iframeSrc} className="iframe" />
          </div>
          : <p className="preview-error">{previewError}</p>
      }
    </Modal>
  );
}

export default DocumentationModal