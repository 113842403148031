const ResourceNotFound = ({ title, text }) => {
  return (
    <div className="resource-not-found">
      <div className="resource-not-found__head">
        <h2>{title}</h2>
      </div>
      {text && <div className="resource-not-found__body">
        <p>{text}</p>
      </div>}
    </div>
  )
}

export default ResourceNotFound